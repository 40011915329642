import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import { green, grey } from '@material-ui/core/colors';

import TableAddIconButton from '../Buttons/TableAddIconButton';

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: grey[300],
  },
  submitButton: {
    color: green[300],
  },
}));

export default function AddSlaDialog(props) {
  const { projectId, header, slas, onClick, type } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [moduleId, setModuleId] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setModuleId('');
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onClick({ variables: { projectId, moduleId, type } });
    setModuleId('');
    setOpen(false);
  };

  const uniqueSlas = [];
  slas.forEach((item) => {
    const i = uniqueSlas.findIndex((x) => x.moduleId === item.moduleId);
    if (i <= -1) {
      uniqueSlas.push({
        _id: item._id,
        moduleId: item.moduleId,
        moduleName: item.moduleName,
        templateName: item.templateName,
      });
    }
  });

  return (
    <div>
      <TableAddIconButton onClick={handleClickOpen} />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Select
              value={moduleId}
              onChange={(e) => setModuleId(e.target.value)}
              fullWidth
              inputProps={{
                name: 'moduleId',
                id: 'moduleId',
              }}
            >
              {/* <MenuItem value={0}>
                <em>-</em>
              </MenuItem> */}
              {uniqueSlas.map((sla) => (
                <MenuItem key={sla._id} value={sla.moduleId}>
                  {`${sla.templateName} - ${sla.moduleName}`}
                </MenuItem>
              ))}
            </Select>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={handleClose}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            className={classes.submitButton}
            onClick={handleSubmit}
            color="primary"
            autoFocus
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AddSlaDialog.propTypes = {
  onClick: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  slas: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projectId: PropTypes.string.isRequired,
  type: PropTypes.string,
};
