import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import AddDialog from '../Dialogs/AddDialog';
import EditDialog from '../Dialogs/EditDialog';
import DeleteDialog from '../Dialogs/DeleteDialog';
import TableUpButton from '../Buttons/TableUpButton';
import TableDownButton from '../Buttons/TableDownButton';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function DatabaseReferencesTable(props) {
  const {
    references,
    projects,
    addDatabaseReference,
    updateDatabaseReference,
    deleteDatabaseReference,
    selectReference,
    selectProject,
    updateInformationNumber,
    selectedProject,
    selectTemplate,
    type,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'number',
      label: '#',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { width: '50px' } }),
        customBodyRender: (value, tableMeta) => (
          <div>
            {value !== 1 && (
              <TableUpButton
                onClick={() => updateInformationNumber({ variables: { projectId: projects._id, informationId: tableMeta.rowData[0], newNumber: tableMeta.rowData[1] - 1 } })}
              />
            )}
            {value}
            {value
              !== references.length && (
                <TableDownButton
                  onClick={() => updateInformationNumber({ variables: { projectId: projects._id, informationId: tableMeta.rowData[0], newNumber: tableMeta.rowData[1] + 1 } })}
                />
            )}
          </div>
        ),
      },
    },
    {
      name: 'question',
      label: 'Frage',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'answer',
      label: 'Antwort',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <div>
            <EditDialog
              informationId={tableMeta.rowData[0]}
              projectId={projects._id}
              oldData={tableMeta.rowData[2]}
              header="Referenzfrage bearbeiten"
              text="Frage"
              field="question"
              onClick={updateDatabaseReference}
            />
            <DeleteDialog
              header="Referenzfrage löschen"
              text="Möchten Sie die Referenzfrage wirklich löschen?"
              onClick={() => deleteDatabaseReference({
                variables: { projectId: projects._id, informationId: tableMeta.rowData[0] },
              })}
            />
          </div>
        ),
      },
    },
  ];

  const data = [];

  references.forEach((reference) => {
    data.push({
      _id: reference._id,
      number: reference.number,
      question: reference.question,
      answer: 'Wird vom Anbieter beantwortet.',
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectReference('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/database"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectReference('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/database"
                onClick={() => selectReference('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Referenzen</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions()}
      />
      <AddDialog
        projectId={selectedProject}
        type={type}
        field="question"
        header="Referenzfrage hinzufügen"
        text="Frage"
        onClick={addDatabaseReference}
      />
    </div>
  );
}

export default DatabaseReferencesTable;

DatabaseReferencesTable.propTypes = {
  references: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
  addDatabaseReference: PropTypes.func.isRequired,
  updateDatabaseReference: PropTypes.func.isRequired,
  updateInformationNumber: PropTypes.func.isRequired,
  deleteDatabaseReference: PropTypes.func.isRequired,
  selectReference: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
