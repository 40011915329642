import gql from 'graphql-tag';

export const GET_USERS = gql`
  query getUsers {
    users {
      _id
      admin
      active
      email
      company
      updated
      name
      position
      role
      admin
      verified
      created
      lastSeen
      projects
    }
  }
`;

export const GET_PROJECT_COMMENTS = gql`
  query getComments($projectId: ID!) {
    comments(projectId: $projectId) {
      _id
      text
      author
      authorId {
        _id
      }
      authorLetters
      created
    }
  }
`;

export const GET_PROJECT = gql`
  query project($projectId: ID!) {
    project(projectId: $projectId) {
      _id
      active
      name
      published
      deadline
      attachments {
        filename
        _id
      }
      roles {
        type
        user {
          email
          _id
        }
      }
      changes {
        date
        user {
          firstname
          lastname
        }
        type
      }
      templates {
        name
      }
      company
      description
      sendIn
      duration
      phase
      dissenses
      dissensesSla
      authorization
      originProject {
        _id
        name
        description
      }
    }
  }
`;

export const GET_PROJECTS = gql`
  query projects($type: String!) {
    projects(type: $type) {
      _id
      active
      name
      published
      deadline
      attachments {
        filename
        _id
      }
      roles {
        type
        user {
          email
          _id
        }
      }
      changes {
        date
        user {
          firstname
          lastname
        }
        type
      }
      templates {
        name
      }
      company
      description
      sendIn
      phase
      dissenses
      dissensesSla
      type
      authorization
      duration
      originProject {
        _id
        name
        description
      }
      fixedPrices
      piecePrices
      tAndMs
      ratecards {
        profile
        dailyrate
      }
      information {
        cluster
        type
      }
    }
  }
`;

export const GET_PROJECTS_POPULATED_MODULES = gql`
  query projects($type: String!) {
    projects(type: $type) {
      _id
      active
      name
      published
      deadline
      attachments {
        filename
        _id
      }
      roles {
        user {
          email
          _id
        }
      }
      changes {
        date
        user {
          firstname
          lastname
        }
        type
      }
      templates {
        _id
        name
        active
        attachments {
          filename
          _id
        }
        modules {
          _id
          name
          active
        }
      }
      company
      description
      sendIn
      phase
      dissenses
      dissensesSla
      type
    }
  }
`;

export const GET_TEMPLATES = gql`
  query templates($projectId: ID!) {
    templates(projectId: $projectId) {
      _id
      active
      attachments {
        filename
        _id
      }
      name
      number
      changes {
        date
        user {
          firstname
          lastname
        }
        type
      }
      dissenses
    }
  }
`;

export const GET_MODULES = gql`
  query modules($projectId: ID!, $templateId: ID!) {
    modules(projectId: $projectId, templateId: $templateId) {
      _id
      active
      name
      number
      dissenses
      heading
      changes {
        date
        type
      }
      servicelevels {
        name
      }
      statements {
        _id
        description
        suggestion
        result
        compliance
        number
        changes {
          date
          type
        }
      }
      prices {
        type
      }
    }
  }
`;

export const GET_STATEMENTS = gql`
  query statements($projectId: ID!, $moduleId: ID!) {
    statements(projectId: $projectId, moduleId: $moduleId) {
      _id
      description
      suggestion
      result
      compliance
      number
      changes {
        date
        type
      }
    }
  }
`;

export const GET_ADDITIONAL_HEADERS = gql`
  query additionalHeaders($projectId: ID!) {
    additionalHeaders(projectId: $projectId)
  }
`;

export const GET_RATECARDS = gql`
  query ratecards($projectId: ID!) {
    ratecards(projectId: $projectId) {
      _id
      profile
      description
      dailyrate
      travelallowance
      changes {
        date
        type
      }
    }
  }
`;

export const GET_PRICES = gql`
query pricesByType($projectId: ID!, $type: String!) {
  pricesByType(projectId: $projectId, type: $type) {
    _id
    type
    price
    profile {
      _id
      profile
      description
      dailyrate
      travelallowance
    }
    days
    pricePerPiece
    amount
    unit
    calculation
    moduleName
    moduleId
    templateName
    changes {
      date
      type
    }
  }
}
`;

export const GET_INFORMATIONS = gql`
query informations($projectId: ID!, $type: String, $cluster: String) {
  informations(projectId: $projectId, type: $type, cluster: $cluster) {
    _id
    number
    type
    cluster
    question
    answer
    attachments {
      filename
      _id
    }
    changes {
      date
      type
    }
  }
}
`;

export const GET_QANDAS = gql`
query qandas($projectId: ID!) {
  qandas(projectId: $projectId) {
    _id
    number
    question
    answer
    published
    ownQuestion
    attachments {
      filename
      _id
    }
    changes {
      date
      type
    }
  }
}
`;

export const GET_SERVICELEVELS = gql`
query servicelevels($projectId: ID!) {
  servicelevels(projectId: $projectId) {
    _id
    name
    value
    servicelevel
    description
    penalty
    penaltyreference
    measure
    extra
    moduleName
    moduleId
    templateName
    compliance
    status
    suggestion
    changes {
      date
      type
    }
  }
}
`;
