import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import TableEditIconButton from '../Buttons/TableEditIconButton';

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: grey[300],
  },
  submitButton: {
    color: blue[300],
  },
}));

export default function EditDialog(props) {
  const { templateId, projectId, moduleId, statementId, informationId, field, type, oldData, header, text, onClick } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const inputRef = React.useRef();

  const formik = useFormik({
    initialValues: {
      input: oldData,
    },
    validationSchema: Yup.object({
      input: Yup.string().required('Darf nicht leer sein'),
    }),
    onSubmit: (values) => {
      const dynamicVariable = {
        [field]: values.input,
      };
      onClick({
        variables: {
          projectId,
          templateId,
          statementId,
          informationId,
          moduleId,
          type,
          ...(field === 'newCluster' ? { oldCluster: oldData } : {}),
          ...dynamicVariable,
        },
      });
      setOpen(false);
    },
  });

  const handleClickOpen = () => {
    formik.values.input = oldData;
    setOpen(true);
    // timeout to select textfield
    const timeout = setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <TableEditIconButton onClick={handleClickOpen} />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              inputRef={inputRef}
              id="input"
              name="input"
              type={header.includes('Passwort') ? 'password' : 'text'}
              label={text}
              multiline
              fullWidth
              margin="normal"
              value={formik.values.input}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!(formik.touched.input && formik.errors.input)}
              helperText={formik.touched.input && formik.errors.input}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={handleClose}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            className={classes.submitButton}
            onClick={formik.handleSubmit}
            color="primary"
            autoFocus
          >
            Ändern
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditDialog.propTypes = {
  templateId: PropTypes.string,
  moduleId: PropTypes.string,
  statementId: PropTypes.string,
  informationId: PropTypes.string,
  type: PropTypes.string,
  field: PropTypes.string,
  projectId: PropTypes.string.isRequired,
  oldData: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
