import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography } from '@material-ui/core';
import ResponsiveDiv from '../Elements/ResponsiveDiv';
import Footer from '../Elements/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '80%',
    margin: 'auto',
    paddingTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    color: theme.palette.text.secondary,
  },
}));

function ImprintPage() {
  const classes = useStyles();
  return (
    <ResponsiveDiv>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper variant="outlined" className={classes.paper}>
              <Typography variant="h5">Impressum</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper variant="outlined" className={classes.paper}>
              <Typography variant="h6">pelo IT GmbH</Typography>
              <Typography variant="body1">Bahnhofstraße 1</Typography>
              <Typography variant="body1">30159 Hannover</Typography>
              <Typography variant="body1">T: 0511 84897730</Typography>
              <Typography variant="body1">E-Mail: info@pelo-it.com</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Paper variant="outlined" className={classes.paper}>
              <Typography variant="h6">Handelsregister:</Typography>
              <Typography variant="body1">Amtsgericht Hannover HRB 1783</Typography>
              <Typography variant="h6">Geschäftsführer:</Typography>
              <Typography variant="body1">Dominik Dietrich, Feodora Dietrich, Sascha Dietrich, Peter Pohl</Typography>
              <Typography variant="h6">Umsatzsteuer-Identifikationsnummer:</Typography>
              <Typography variant="body1">DE115658552</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Paper variant="outlined" className={classes.paper}>
              <Typography variant="h6">Inhalt:</Typography>
              <Typography variant="body1">
                Wir übernehmen keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten
                Informationen. Haftungsansprüche gegen uns, welche sich auf Schäden materieller oder ideeller Art beziehen, die durch die
                Nutzung oder Nichtnutzung der dargebotenen Informationen bzw.
                durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden sind grundsätzlich ausgeschlossen,
                sofern kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind freibleibend und
                unverbindlich. Wir behalten es uns ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne Ankündigung zu verändern oder einzustellen.
              </Typography>
              <Typography variant="h6">Verweise:</Typography>
              <Typography variant="body1">
                Bei direkten oder indirekten Verweisen auf fremde Internetseiten („Links“),
                die außerhalb unseres Verantwortungsbereiches liegen, würde eine Haftungsverpflichtung ausschließlich
                in dem Fall in Kraft treten, in dem wir von den Inhalten Kenntnis haben und es uns technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Wir erklären daher ausdrücklich,
                dass zum Zeitpunkt der Linksetzung die entsprechenden verlinkten Seiten frei von illegalen Inhalten waren.
                Wir haben keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der gelinkten /
                verknüpften Seiten. Deshalb distanzieren wir uns hiermit ausdrücklich von allen Inhalten aller gelinkten /
                verknüpften Seiten, die nach der Linksetzung verändert wurden. Für illegale, fehlerhafte oder unvollständige
                Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen
                entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde.
              </Typography>
              <Typography variant="h6">Urheber- und Kennzeichenrecht:</Typography>
              <Typography variant="body1">
                Wir sind bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken und Texte zu beachten, selbst erstellte Grafiken und Texte zu nutzen oder auf lizenzfreie Grafiken und Texte zurückzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind! Das Copyright für selbst erstellte Objekte bleibt allein bei uns. Eine Vervielfältigung oder Verwendung solcher Grafiken, Fotos und Texte in anderen Publikationen ist ohne unsere ausdrückliche Zustimmung nicht gestattet.
              </Typography>
              <Typography variant="h6">Rechtswirksamkeit:</Typography>
              <Typography variant="body1">
                Dieser Haftungsausschluss ist Teil des Internetangebotes. Sofern Teile oder Formulierungen dieses Textes der
                geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen, bleiben die übrigen Teile des Dokumentes
                in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
              </Typography>

              <Typography variant="h6">Gestaltung und Realisierung</Typography>
              <Typography variant="body1">
                es//media solutions · www.esmedia.de
              </Typography>

              <Typography variant="h6">
                Quellenangaben für die verwendeten Bilder:
              </Typography>
              <Typography variant="body1">
                Fotografie: www.ps-art.de,
                Stock: www.adobestock.com · www.freepik.com
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </ResponsiveDiv>
  );
}

export default ImprintPage;

ImprintPage.propTypes = {};
