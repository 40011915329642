import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import EditDialog from '../Dialogs/EditDialog';
import UploadHandler from '../Elements/UploadHandler';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function OfferingInformationTable(props) {
  const {
    information,
    projects,
    updateOfferingInformation,
    selectInformation,
    selectProject,
    // selectedProject,
    selectTemplate,
    // user,
    // uploadOfferingInformationFile,
    // removeOfferingInformationUpload,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'number',
      label: '#',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { width: '50px' } }),
      },
    },
    {
      name: 'question',
      label: 'Frage',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'answer',
      label: 'Antwort',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'attachment',
      label: 'Anhänge',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <UploadHandler
            projectId={projects._id}
            referenceId={tableMeta.rowData[0]}
            url="/api/v1/upload/"
            referenceType="information"
            attachments={value}
            disabled={projects.published || projects.authorization === 'read'}
          />
        ),
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        display: !projects.published && projects.authorization !== 'read',
        viewColumns: !projects.published && projects.authorization !== 'read',
        customBodyRender: (value, tableMeta) => (
          <div>
            <EditDialog
              projectId={projects._id}
              informationId={tableMeta.rowData[0]}
              oldData={tableMeta.rowData[3]}
              header="Informationsfrage beantworten"
              text={tableMeta.rowData[2]}
              field="answer"
              onClick={updateOfferingInformation}
            />
          </div>
        ),
      },
    },
  ];

  const data = [];

  information.forEach((info) => {
    data.push({
      _id: info._id,
      number: info.number,
      question: info.question,
      answer: info.answer,
      attachment: info.attachments,
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectInformation('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectInformation('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => selectInformation('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Informationen</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({ tableBodyMaxHeight: 'calc(100vh - 129px)' })}
      />
    </div>
  );
}

export default OfferingInformationTable;

OfferingInformationTable.propTypes = {
  information: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
  updateOfferingInformation: PropTypes.func.isRequired,
  selectInformation: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  // selectedProject: PropTypes.string.isRequired,
  // user: PropTypes.shape(Object).isRequired,
  // uploadOfferingInformationFile: PropTypes.func.isRequired,
  // removeOfferingInformationUpload: PropTypes.func.isRequired,
};
