import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
// import { useSubscription } from '@apollo/react-hooks';
// import {
//   GET_PROJECTS,
//   GET_TEMPLATES,
//   GET_MODULES,
//   GET_RATECARDS,
//   // GET_STATEMENTS,
//   GET_PRICES,
// } from '../../graphql/queries';
// import {
//   PROJECT_ADDED,
//   PROJECT_DELETED,
//   PROJECT_UPDATED,
//   TEMPLATE_ADDED,
//   TEMPLATES_UPDATED,
//   MODULE_ADDED,
//   MODULES_UPDATED,
//   RATECARD_ADDED,
//   RATECARD_UPDATED,
//   RATECARD_DELETED,
//   // STATEMENT_ADDED,
//   // STATEMENTS_UPDATED,
//   PRICE_ADDED,
//   PRICE_UPDATED,
//   PRICE_DELETED,
// } from '../../graphql/subscriptions';

import {
  selectProject,
  selectTemplate,
  selectModule,
  selectSla,
  selectRatecard,
  selectTAndM,
  selectFixedPrice,
  selectPiecePrice,
  selectInformation,
  selectReference,
  selectSubcontractor,
} from '../../redux/actionCreators/databaseAction';
import DatabasePage from '../../components/DatabaseManagement/DatabasePage';
import CommentsContainer from '../Comment/CommentsContainer';

function DatabaseContainer(props) {
  const {
    admin,
    history,
    selectedProject,
    selectedTemplate,
    selectedModule,
    selectedSla,
    selectedRatecard,
    selectedTAndM,
    selectedFixedPrice,
    selectedPiecePrice,
    selectedInformation,
    selectedReference,
    selectedSubcontractor,
    selectProject,
    selectTemplate,
    selectModule,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectSubcontractor,
  } = props;
  // const projectType = 'database';
  // useSubscription(PROJECT_ADDED, {
  //   variables: { type: projectType },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { projects } = client.cache.readQuery({
  //       query: GET_PROJECTS,
  //       variables: { type: projectType },
  //     });
  //     const update = projects.concat(subscriptionData.data.projectAdded);
  //     client.writeQuery({
  //       query: GET_PROJECTS,
  //       variables: { type: projectType },
  //       data: { projects: update },
  //     });
  //   },
  // });

  // useSubscription(PROJECT_DELETED, {
  //   variables: { type: projectType },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { projects } = client.cache.readQuery({
  //       query: GET_PROJECTS,
  //       variables: { type: projectType },
  //     });
  //     const update = projects.filter(
  //       (project) => project._id !== subscriptionData.data.projectDeleted._id,
  //     );
  //     client.writeQuery({
  //       query: GET_PROJECTS,
  //       variables: { type: projectType },
  //       data: { projects: update },
  //     });
  //   },
  // });

  // useSubscription(PROJECT_UPDATED, {
  //   variables: { type: projectType },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { projects } = client.cache.readQuery({
  //       query: GET_PROJECTS,
  //       variables: { type: projectType },
  //     });
  //     const foundIndex = projects.findIndex((x) => x._id === subscriptionData.data.projectUpdated._id);
  //     projects[foundIndex] = subscriptionData.data.projectUpdated;
  //     client.writeQuery({
  //       query: GET_PROJECTS,
  //       variables: { type: projectType },
  //       data: { projects },
  //     });
  //   },
  // });

  // useSubscription(TEMPLATE_ADDED, {
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { templates } = client.cache.readQuery({
  //       query: GET_TEMPLATES,
  //       variables: { projectId: selectedProject },
  //     });
  //     const update = templates.concat([subscriptionData.data.templateAdded]);
  //     client.writeQuery({
  //       query: GET_TEMPLATES,
  //       variables: { projectId: selectedProject },
  //       data: { templates: update },
  //     });
  //   },
  // });

  // useSubscription(TEMPLATES_UPDATED, {
  //   variables: { projectId: selectedProject },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { templates } = client.cache.readQuery({
  //       query: GET_TEMPLATES,
  //       variables: { projectId: selectedProject },
  //     });
  //     let deletedId;
  //     subscriptionData.data.templatesUpdated.forEach((element) => {
  //       if (element.deleted) {
  //         deletedId = element._id;
  //       } else {
  //         const foundIndex = templates.findIndex((x) => x._id === element._id);
  //         templates[foundIndex] = element;
  //       }
  //     });
  //     const update = templates.filter((t) => t._id !== deletedId);
  //     client.writeQuery({
  //       query: GET_TEMPLATES,
  //       variables: { projectId: selectedProject },
  //       data: { templates: update.sort((a, b) => a.number - b.number) },
  //     });
  //   },
  // });
  // useSubscription(MODULE_ADDED, {
  //   variables: { projectId: selectedProject, templateId: selectedTemplate },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { modules } = client.cache.readQuery({
  //       query: GET_MODULES,
  //       variables: { projectId: selectedProject, templateId: selectedTemplate },
  //     });
  //     const update = modules.concat([subscriptionData.data.moduleAdded]);
  //     client.writeQuery({
  //       query: GET_MODULES,
  //       variables: { projectId: selectedProject, templateId: selectedTemplate },
  //       data: { modules: update },
  //     });
  //   },
  // });

  // useSubscription(MODULES_UPDATED, {
  //   variables: { projectId: selectedProject },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { modules } = client.cache.readQuery({
  //       query: GET_MODULES,
  //       variables: { projectId: selectedProject, templateId: selectedTemplate },
  //     });
  //     let deletedId;
  //     subscriptionData.data.modulesUpdated.forEach((element) => {
  //       if (element.deleted) {
  //         deletedId = element._id;
  //       } else {
  //         const foundIndex = modules.findIndex((x) => x._id === element._id);
  //         modules[foundIndex] = element;
  //       }
  //     });
  //     const update = modules.filter((t) => t._id !== deletedId);
  //     client.writeQuery({
  //       query: GET_MODULES,
  //       variables: { projectId: selectedProject, templateId: selectedTemplate },
  //       data: { modules: update.sort((a, b) => a.number - b.number) },
  //     });
  //   },
  // });

  // useSubscription(RATECARD_ADDED, {
  //   variables: { projectId: selectedProject },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { ratecards } = client.cache.readQuery({
  //       query: GET_RATECARDS,
  //       variables: { projectId: selectedProject },
  //     });
  //     const update = ratecards.concat([subscriptionData.data.ratecardAdded]);
  //     client.writeQuery({
  //       query: GET_RATECARDS,
  //       variables: { projectId: selectedProject },
  //       data: { ratecards: update },
  //     });
  //   },
  // });

  // useSubscription(RATECARD_UPDATED, {
  //   variables: { projectId: selectedProject },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { ratecards } = client.cache.readQuery({
  //       query: GET_RATECARDS,
  //       variables: { projectId: selectedProject },
  //     });
  //     const foundIndex = ratecards.findIndex((x) => x._id === subscriptionData.data.ratecardUpdated._id);
  //     ratecards[foundIndex] = subscriptionData.data.ratecardUpdated;
  //     client.writeQuery({
  //       query: GET_RATECARDS,
  //       variables: { projectId: selectedProject },
  //       data: { ratecards },
  //     });
  //   },
  // });

  // useSubscription(RATECARD_DELETED, {
  //   variables: { projectId: selectedProject },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { ratecards } = client.cache.readQuery({
  //       query: GET_RATECARDS,
  //       variables: { projectId: selectedProject },
  //     });
  //     const update = ratecards.filter(
  //       (ratecard) => ratecard._id !== subscriptionData.data.ratecardDeleted._id,
  //     );
  //     client.writeQuery({
  //       query: GET_RATECARDS,
  //       variables: { projectId: selectedProject },
  //       data: { ratecards: update },
  //     });
  //   },
  // });
  // const priceType = 'T&M';
  // useSubscription(PRICE_ADDED, {
  //   variables: { projectId: selectedProject },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { prices } = client.cache.readQuery({
  //       query: GET_PRICES,
  //       variables: { projectId: selectedProject, type: priceType },
  //     });
  //     const update = prices.concat([subscriptionData.data.priceAdded]);
  //     client.writeQuery({
  //       query: GET_PRICES,
  //       variables: { projectId: selectedProject, type: priceType },
  //       data: { prices: update },
  //     });
  //   },
  // });

  // useSubscription(PRICE_UPDATED, {
  //   variables: { projectId: selectedProject },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { prices } = client.cache.readQuery({
  //       query: GET_PRICES,
  //       variables: { projectId: selectedProject, type: priceType },
  //     });
  //     const foundIndex = prices.findIndex((x) => x._id === subscriptionData.data.priceUpdated._id);
  //     prices[foundIndex] = subscriptionData.data.priceUpdated;
  //     client.writeQuery({
  //       query: GET_PRICES,
  //       variables: { projectId: selectedProject, type: priceType },
  //       data: { prices },
  //     });
  //   },
  // });

  // useSubscription(PRICE_DELETED, {
  //   variables: { projectId: selectedProject },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { prices } = client.cache.readQuery({
  //       query: GET_PRICES,
  //       variables: { projectId: selectedProject, type: priceType },
  //     });
  //     const update = prices.filter(
  //       (price) => price._id !== subscriptionData.data.priceDeleted._id,
  //     );
  //     client.writeQuery({
  //       query: GET_PRICES,
  //       variables: { projectId: selectedProject, type: priceType },
  //       data: { prices: update },
  //     });
  //   },
  // });

  // useSubscription(STATEMENT_ADDED, {
  //   variables: { projectId: selectedProject, moduleId: selectedModule },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { statements } = client.cache.readQuery({
  //       query: GET_STATEMENTS,
  //       variables: { projectId: selectedProject, moduleId: selectedModule },
  //     });
  //     const update = statements.concat([subscriptionData.data.statementAdded]);
  //     client.writeQuery({
  //       query: GET_STATEMENTS,
  //       variables: { projectId: selectedProject, moduleId: selectedModule },
  //       data: { statements: update },
  //     });
  //   },
  // });

  // useSubscription(STATEMENTS_UPDATED, {
  //   variables: { projectId: selectedProject, moduleId: selectedModule },
  //   onSubscriptionData: ({ client, subscriptionData }) => {
  //     const { statements } = client.cache.readQuery({
  //       query: GET_STATEMENTS,
  //       variables: { projectId: selectedProject, moduleId: selectedModule },
  //     });
  //     let deletedId;
  //     subscriptionData.data.statementsUpdated.forEach((element) => {
  //       if (element.deleted) {
  //         deletedId = element._id;
  //       } else {
  //         const foundIndex = statements.findIndex((x) => x._id === element._id);
  //         statements[foundIndex] = element;
  //       }
  //     });
  //     const update = statements.filter((t) => t._id !== deletedId);
  //     client.writeQuery({
  //       query: GET_STATEMENTS,
  //       variables: { projectId: selectedProject, moduleId: selectedModule },
  //       data: { statements: update.sort((a, b) => a.number - b.number) },
  //     });
  //   },
  // });
  useEffect(() => {
    if (!admin) {
      history.push('/main');
    }
    return () => {
      selectProject('');
      selectTemplate('');
      selectModule('');
      selectSla('');
      selectRatecard('');
      selectTAndM('');
      selectFixedPrice('');
      selectPiecePrice('');
      selectInformation('');
      selectReference('');
      selectSubcontractor('');
    };
  }, [
    admin,
    history,
    selectProject,
    selectTemplate,
    selectModule,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectSubcontractor,
  ]);

  return (
    <>
      <DatabasePage
        selectedProject={selectedProject}
        selectedTemplate={selectedTemplate}
        selectedModule={selectedModule}
        selectedSla={selectedSla}
        selectedRatecard={selectedRatecard}
        selectedTAndM={selectedTAndM}
        selectedFixedPrice={selectedFixedPrice}
        selectedPiecePrice={selectedPiecePrice}
        selectedInformation={selectedInformation}
        selectedReference={selectedReference}
        selectedSubcontractor={selectedSubcontractor}
      />
      {selectedProject ? (
        <CommentsContainer selectedProject={selectedProject} />
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  admin: state.auth.user.admin,
  selectedProject: state.database.selectedProject,
  selectedTemplate: state.database.selectedTemplate,
  selectedModule: state.database.selectedModule,
  selectedSla: state.database.selectedSla,
  selectedRatecard: state.database.selectedRatecard,
  selectedTAndM: state.database.selectedTAndM,
  selectedFixedPrice: state.database.selectedFixedPrice,
  selectedPiecePrice: state.database.selectedPiecePrice,
  selectedInformation: state.database.selectedInformation,
  selectedReference: state.database.selectedReference,
  selectedSubcontractor: state.database.selectedSubcontractor,
  loading: state.database.loading,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectModule: (id) => dispatch(selectModule(id)),
  selectSla: (id) => dispatch(selectSla(id)),
  selectRatecard: (id) => dispatch(selectRatecard(id)),
  selectTAndM: (id) => dispatch(selectTAndM(id)),
  selectFixedPrice: (id) => dispatch(selectFixedPrice(id)),
  selectPiecePrice: (id) => dispatch(selectPiecePrice(id)),
  selectInformation: (id) => dispatch(selectInformation(id)),
  selectReference: (id) => dispatch(selectReference(id)),
  selectSubcontractor: (id) => dispatch(selectSubcontractor(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DatabaseContainer),
);

DatabaseContainer.propTypes = {
  admin: PropTypes.bool.isRequired,
  history: PropTypes.shape(Object).isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  selectedModule: PropTypes.string.isRequired,
  selectedSla: PropTypes.string.isRequired,
  selectedRatecard: PropTypes.string.isRequired,
  selectedTAndM: PropTypes.string.isRequired,
  selectedFixedPrice: PropTypes.string.isRequired,
  selectedPiecePrice: PropTypes.string.isRequired,
  selectedInformation: PropTypes.string.isRequired,
  selectedReference: PropTypes.string.isRequired,
  selectedSubcontractor: PropTypes.string.isRequired,
  selectProject: PropTypes.func,
  selectTemplate: PropTypes.func,
  selectModule: PropTypes.func,
  selectSla: PropTypes.func,
  selectRatecard: PropTypes.func,
  selectTAndM: PropTypes.func,
  selectFixedPrice: PropTypes.func,
  selectPiecePrice: PropTypes.func,
  selectInformation: PropTypes.func,
  selectReference: PropTypes.func,
  selectSubcontractor: PropTypes.func,
};
