import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_RATECARDS, GET_PROJECT } from '../../graphql/queries';
import {
  ADD_RATECARD,
  UPDATE_RATECARD,
  DELETE_RATECARD,
} from '../../graphql/mutations';

import {
  // fetchProjectRatecard,
  // addRatecard,
  // updateRatecard,
  // deleteRatecard,
  selectRatecard,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/projectAction';
import ProjectRatecardTable from '../../components/ProjectManagement/ProjectRatecardTable';
import Loading from '../../components/Elements/Loading';

function ProjectRatecardContainer(props) {
  const {
    // ratecards,
    // projects,
    // fetchProjectRatecard,
    // addRatecard,
    // updateRatecard,
    // deleteRatecard,
    selectRatecard,
    selectProject,
    selectTemplate,
    selectedProject,
    // loading,
    // user,
  } = props;
  // useEffect(() => {
  //   fetchProjectRatecard(selectedProject);
  // }, [fetchProjectRatecard, selectedProject]);

  const { loading, error, data } = useQuery(GET_RATECARDS, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [addRatecard] = useMutation(ADD_RATECARD, {
    refetchQueries: [{
      query: GET_RATECARDS,
      variables: { projectId: selectedProject },
    }],
  });
  const [updateRatecard] = useMutation(UPDATE_RATECARD, {
    refetchQueries: [{
      query: GET_RATECARDS,
      variables: { projectId: selectedProject },
    }],
  });
  const [deleteRatecard] = useMutation(DELETE_RATECARD, {
    refetchQueries: [{
      query: GET_RATECARDS,
      variables: { projectId: selectedProject },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }

  return (
    <ProjectRatecardTable
      ratecards={data.ratecards}
      projects={data1.project}
      addRatecard={addRatecard}
      updateRatecard={updateRatecard}
      deleteRatecard={deleteRatecard}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectRatecard={selectRatecard}
      loading={loading}
      // user={user}
    />
  );
}

const mapStateToProps = (state) => ({
  ratecards: state.project.ratecards,
  projects: state.project.projects,
  selectedProject: state.project.selectedProject,
  loading: state.project.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchProjectRatecard: (id) => dispatch(fetchProjectRatecard(id)),
  // addRatecard: (projectId, profile, description) =>
  //   dispatch(addRatecard(projectId, profile, description)),
  // updateRatecard: (ratecardId, profile, description, projectId) =>
  //   dispatch(updateRatecard(ratecardId, profile, description, projectId)),
  // deleteRatecard: (id, projectId) => dispatch(deleteRatecard(id, projectId)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectRatecard: (id) => dispatch(selectRatecard(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectRatecardContainer),
);

ProjectRatecardContainer.propTypes = {
  // fetchProjectRatecard: PropTypes.func.isRequired,
  // addRatecard: PropTypes.func.isRequired,
  // updateRatecard: PropTypes.func.isRequired,
  // deleteRatecard: PropTypes.func.isRequired,
  selectRatecard: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // ratecards: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  // user: PropTypes.shape(Object).isRequired,
};
