// import axios from 'axios';
import {
  SELECT_DATABASE_INFORMATION,
  SELECT_DATABASE_REFERENCE,
  SELECT_DATABASE_SUBCONTRACTOR,
  SELECT_FIXEDPRICE,
  SELECT_MODULE,
  SELECT_PIECEPRICE,
  SELECT_PROJECT,
  SELECT_RATECARD,
  SELECT_SLA,
  SELECT_STATEMENT,
  SELECT_TANDM,
  SELECT_TEMPLATE,
} from '../actionTypes/actionTypes';
// import { enqueueSnackbar } from './notifierAction';

export const selectSubcontractor = (subcontractor) => ({
  type: SELECT_DATABASE_SUBCONTRACTOR,
  payload: { subcontractor },
});

export const selectInformation = (id) => ({
  type: SELECT_DATABASE_INFORMATION,
  payload: { id },
});

export const selectPiecePrice = (piecePrice) => ({
  type: SELECT_PIECEPRICE,
  payload: { piecePrice },
});

export const selectTAndM = (tandm) => ({
  type: SELECT_TANDM,
  payload: { tandm },
});

export const selectFixedPrice = (fixedPrice) => ({
  type: SELECT_FIXEDPRICE,
  payload: { fixedPrice },
});

export const selectSla = (sla) => ({
  type: SELECT_SLA,
  payload: { sla },
});

export const selectRatecard = (id) => ({
  type: SELECT_RATECARD,
  payload: { id },
});

export const selectProject = (project) => ({
  type: SELECT_PROJECT,
  payload: { project },
});

export const selectTemplate = (template) => ({
  type: SELECT_TEMPLATE,
  payload: { template },
});

export const selectModule = (module) => ({
  type: SELECT_MODULE,
  payload: { module },
});

export const selectStatement = (statement) => ({
  type: SELECT_STATEMENT,
  payload: { statement },
});

export const selectReference = (reference) => ({
  type: SELECT_DATABASE_REFERENCE,
  payload: { reference },
});
