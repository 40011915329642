import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Button, TextField } from '@material-ui/core/';
import { orange } from '@material-ui/core/colors/';

const useStyles = makeStyles(() => ({
  textField: {
    width: '100%',
  },
  submitButton: {
    color: orange[300],
    float: 'right',
  },
}));

function CommentInput(props) {
  const { selectedProject, postComment } = props;
  const classes = useStyles();

  const [input, setInput] = useState('');

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          postComment({
            variables: { text: input, projectId: selectedProject },
          });
          setInput('');
        }}
      >
        <TextField
          className={classes.textField}
          id="input"
          name="input"
          label="Nachricht"
          margin="normal"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          autoComplete="off"
        />
      </form>
      <Button
        className={classes.submitButton}
        color="primary"
        onClick={() => {
          postComment({
            variables: { text: input, projectId: selectedProject },
          });
          setInput('');
        }}
      >
        Absenden
      </Button>
    </>
  );
}

export default CommentInput;

CommentInput.propTypes = {
  selectedProject: PropTypes.string.isRequired,
  postComment: PropTypes.func.isRequired,
};
