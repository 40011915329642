import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { DeleteOutline } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  tableDeleteIconButton: {
    color: red[300],
  },
}));

function TableDeleteIconButton(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <Tooltip title="Löschen">
      <IconButton className={classes.tableDeleteIconButton} onClick={onClick}>
        <DeleteOutline />
      </IconButton>
    </Tooltip>
  );
}

export default TableDeleteIconButton;

TableDeleteIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
