import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import { ZoomIn } from '@material-ui/icons';
import TableButton from '../Buttons/TableButton';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function NegotiationSubcontractorsCompaniesTable(props) {
  const {
    selectSubcontractor,
    selectNegotiationSubcontractorCompany,
    selectProject,
    selectTemplate,
    subcontractors,
    projects,
  } = props;

  const columns = [
    {
      name: 'company',
      label: 'Unternehmen',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <TableButton
            text={value}
            icon={<ZoomIn />}
            onClick={() =>
              selectNegotiationSubcontractorCompany(tableMeta.rowData[0])}
          />
        ),
      },
    },
  ];

  // * Important: use this to filter arrays for template movement and copying
  const onlyUnique = (value, index, self) => self.indexOf(value) === index;

  const subcontractorCompanies = subcontractors
    .filter((subcontractor) => subcontractor.cluster !== '')
    .map((subcontractor) => subcontractor.cluster)
    .filter(onlyUnique);

  const data = [];

  subcontractorCompanies.forEach((subcontractor) => {
    data.push({
      company: subcontractor,
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectSubcontractor('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/managenegotiation"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectSubcontractor('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/managenegotiation"
                onClick={() => selectSubcontractor('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Subkontraktoren</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions()}
      />
    </div>
  );
}

export default NegotiationSubcontractorsCompaniesTable;

NegotiationSubcontractorsCompaniesTable.propTypes = {
  selectSubcontractor: PropTypes.func.isRequired,
  selectNegotiationSubcontractorCompany: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  subcontractors: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
};
