import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { changePassword } from '../../redux/actionCreators/userAction';

import ProfilePage from '../../components/Profile/ProfilePage';

function ProfileContainer(props) {
  const { user, isAuthenticated, changePassword, history } = props;
  useEffect(() => {
    if (!isAuthenticated) {
      history.push('/');
    }
  }, [isAuthenticated, history]);

  return <ProfilePage user={user} changePassword={changePassword} />;
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
});

const mapDispatchToProps = (dispatch) => ({
  changePassword: (password, oldpassword) =>
    dispatch(changePassword(password, oldpassword)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProfileContainer),
);

ProfileContainer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  changePassword: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
  user: PropTypes.shape(Object).isRequired,
};
