import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      user {
        id
        username
        email
      }
      token
    }
  }
`;

export const UPDATE_USER = gql`
mutation updateUser($id: ID!, $active: Boolean, $admin: Boolean, $role: String) {
  updateUser(id: $id, active: $active, admin: $admin, role: $role)
}
`;

export const DELETE_USER = gql`
mutation deleteUser($userId: ID!) {
  deleteUser(userId: $userId)
}
`;

export const POST_COMMENT = gql`
  mutation postComment($text: String!, $projectId: ID!) {
    postComment(text: $text, projectId: $projectId) {
      _id
      text
      author
      authorLetters
      created
    }
  }
`;

export const DELETE_COMMENT = gql`
  mutation deleteComment($_id: ID!) {
    deleteComment(_id: $_id) {
      _id
    }
  }
`;

export const ADD_EMPTY_PROJECT = gql`
  mutation addEmptyProject($name: String!, $type: String!) {
    addEmptyProject(name: $name, type: $type)
  }
`;

export const ADD_PROJECT_WITH_CONTENT = gql`
  mutation addProjectWithContent($referenceId: [ID]!, $name: String!, $targetType: String!, $description: String!, $duration: Int!, $includedTemplates: [ID]!, $includedModules: [ID]!) {
    addProjectWithContent(name: $name, targetType: $targetType, referenceId: $referenceId, description: $description, duration: $duration, includedTemplates: $includedTemplates, includedModules: $includedModules)
  }
`;

export const COPY_PROJECT_TO_DATABASE = gql`
  mutation copyProjectToDatabase($referenceId: [ID]!) {
    copyProjectToDatabase(referenceId: $referenceId)
  }
`;

export const PUBLISH_PROJECT = gql`
  mutation publishProject($projectId: ID!, $deadline: String!) {
    publishProject(projectId: $projectId, deadline: $deadline)
  }
`;

export const PUBLISH_OFFER = gql`
  mutation publishOffer($projectId: ID!) {
    publishOffer(projectId: $projectId)
  }
`;

export const PUBLISH_NEGOTIATION = gql`
  mutation publishNegotiation($projectId: ID!, $deadline: String!) {
    publishNegotiation(projectId: $projectId, deadline: $deadline)
  }
`;

export const DELETE_PROJECT = gql`
  mutation deleteProject($projectId: ID!) {
    deleteProject(projectId: $projectId)
  }
`;

export const UPDATE_PROJECT = gql`
  mutation updateProject($projectId: ID!, $name: String, $description: String, $active: Boolean, $duration: String) {
    updateProject(projectId: $projectId, name: $name, description: $description, active: $active, duration: $duration)
  }
`;

export const UPDATE_ROLES = gql`
  mutation updateRoles($projectId: ID!, $type: String!, $action: String!, $email: String!) {
    updateRoles(projectId: $projectId, type: $type, action: $action, email: $email)
  }
`;

export const ADD_TEMPLATE = gql`
  mutation addTemplate($projectId: ID!, $name: String!) {
    addTemplate(projectId: $projectId, name: $name)
  }
`;

export const DELETE_TEMPLATE = gql`
  mutation deleteTemplate($projectId: ID!, $templateId: ID!) {
    deleteTemplate(projectId: $projectId, templateId: $templateId)
  }
`;

export const UPDATE_TEMPLATE = gql`
  mutation updateTemplate($projectId: ID!, $templateId: ID!, $name: String, $active: Boolean) {
    updateTemplate(projectId: $projectId, templateId: $templateId, name: $name,  active: $active)
  }
`;

export const TEMPLATE_NUMBER_UP = gql`
  mutation templateNumberUp($projectId: ID!, $templateId: ID!) {
    templateNumberUp(projectId: $projectId, templateId: $templateId)
  }
`;

export const TEMPLATE_NUMBER_DOWN = gql`
  mutation templateNumberDown($projectId: ID!, $templateId: ID!) {
    templateNumberDown(projectId: $projectId, templateId: $templateId)
  }
`;

export const ADD_MODULE = gql`
  mutation addModule($projectId: ID!, $templateId: ID!, $name: String!, $heading: String) {
    addModule(projectId: $projectId, templateId: $templateId, name: $name, heading: $heading)
  }
`;

export const DELETE_MODULE = gql`
  mutation deleteModule($projectId: ID!, $templateId: ID!, $moduleId: ID!) {
    deleteModule(projectId: $projectId, templateId: $templateId, moduleId: $moduleId)
  }
`;

export const UPDATE_MODULE = gql`
  mutation updateModule($projectId: ID!, $moduleId: ID!, $name: String, $heading: String, $active: Boolean) {
    updateModule(projectId: $projectId, moduleId: $moduleId, name: $name, heading: $heading, active: $active)
  }
`;

export const UPDATE_MODULE_PRICING = gql`
  mutation updateModulePricing($projectId: ID!, $moduleId: ID!, $type: String!) {
    updateModulePricing(projectId: $projectId, moduleId: $moduleId, type: $type)
  }
`;

export const UPDATE_MODULE_SLA = gql`
  mutation updateModuleSla($projectId: ID!, $moduleId: ID!, $toggleSla: Boolean!) {
    updateModuleSla(projectId: $projectId, moduleId: $moduleId, toggleSla: $toggleSla)
  }
`;

export const MODULE_NUMBER_UP = gql`
  mutation moduleNumberUp($projectId: ID!, $templateId: ID!, $moduleId: ID!) {
    moduleNumberUp(projectId: $projectId, templateId: $templateId, moduleId: $moduleId)
  }
`;

export const MODULE_NUMBER_DOWN = gql`
  mutation moduleNumberDown($projectId: ID!, $templateId: ID!, $moduleId: ID!) {
    moduleNumberDown(projectId: $projectId, templateId: $templateId, moduleId: $moduleId)
  }
`;

export const ADD_STATEMENT = gql`
  mutation addStatement($projectId: ID!, $moduleId: ID!, $name: String!) {
    addStatement(projectId: $projectId, moduleId: $moduleId, name: $name)
  }
`;

export const DELETE_STATEMENT = gql`
  mutation deleteStatement($projectId: ID!, $moduleId: ID!, $templateId: ID!, $statementId: ID!) {
    deleteStatement(projectId: $projectId, moduleId: $moduleId, templateId: $templateId, statementId: $statementId)
  }
`;

export const UPDATE_STATEMENT = gql`
  mutation updateStatement($projectId: ID!, $templateId: ID, $moduleId: ID, $statementId: ID!, $name: String, $suggestion: String, $result: String, $compliance: Boolean) {
    updateStatement(projectId: $projectId, templateId: $templateId, moduleId: $moduleId, statementId: $statementId, name: $name, suggestion: $suggestion, result: $result, compliance: $compliance)
  }
`;

export const STATEMENT_NUMBER_UP = gql`
  mutation statementNumberUp($projectId: ID!, $moduleId: ID!, $statementId: ID!) {
    statementNumberUp(projectId: $projectId, moduleId: $moduleId, statementId: $statementId)
  }
`;

export const STATEMENT_NUMBER_DOWN = gql`
  mutation statementNumberDown($projectId: ID!, $moduleId: ID!, $statementId: ID!) {
    statementNumberDown(projectId: $projectId, moduleId: $moduleId, statementId: $statementId)
  }
`;

export const ADD_RATECARD = gql`
  mutation addRatecard($projectId: ID!, $profile: String!, $description: String!) {
    addRatecard(projectId: $projectId, profile: $profile, description: $description)
  }
`;

export const DELETE_RATECARD = gql`
  mutation deleteRatecard($projectId: ID!, $ratecardId: ID!) {
    deleteRatecard(projectId: $projectId, ratecardId: $ratecardId)
  }
`;

export const UPDATE_RATECARD = gql`
  mutation updateRatecard($projectId: ID!, $ratecardId: ID!, $profile: String, $description: String, $dailyrate: Float, $travelallowance: Float) {
    updateRatecard(projectId: $projectId, ratecardId: $ratecardId, profile: $profile, description: $description, dailyrate: $dailyrate, travelallowance: $travelallowance)
  }
`;

export const ADD_PRICE = gql`
  mutation addPrice($projectId: ID!, $moduleId: ID!, $type: String!) {
    addPrice(projectId: $projectId, moduleId: $moduleId, type: $type)
  }
`;

export const DELETE_PRICE = gql`
  mutation deletePrice($projectId: ID!, $moduleId: ID!, $priceId: ID!) {
    deletePrice(projectId: $projectId, moduleId: $moduleId, priceId: $priceId)
  }
`;

export const UPDATE_PRICE = gql`
  mutation updatePrice($projectId: ID!, $priceId: ID!, $amount: Float!, $unit: String!) {
    updatePrice(projectId: $projectId, priceId: $priceId, amount: $amount, unit: $unit)
  }
`;

export const ANSWER_PRICE = gql`
  mutation answerPrice($projectId: ID!, $priceId: ID!, $profile: ID, $price: Float, $days: Float, $pricePerPiece: Float) {
    answerPrice(projectId: $projectId, priceId: $priceId, profile: $profile, price: $price, days: $days, pricePerPiece: $pricePerPiece)
  }
`;

export const ADD_INFORMATION = gql`
  mutation addInformation($projectId: ID!, $type: String!, $question: String!) {
    addInformation(projectId: $projectId, type: $type, question: $question)
  }
`;

export const DELETE_INFORMATION = gql`
  mutation deleteInformation($projectId: ID!, $informationId: ID!) {
    deleteInformation(projectId: $projectId, informationId: $informationId)
  }
`;

export const UPDATE_INFORMATION = gql`
  mutation updateInformation($projectId: ID!, $informationId: ID!, $question: String, $answer: String) {
    updateInformation(projectId: $projectId, informationId: $informationId, question: $question, answer: $answer)
  }
`;

export const UPDATE_INFORMATION_NUMBER = gql`
  mutation updateInformationNumber($projectId: ID!, $informationId: ID!, $newNumber: Int!) {
    updateInformationNumber(projectId: $projectId, informationId: $informationId, newNumber: $newNumber)
  }
`;

export const ADD_INFORMATION_CLUSTER = gql`
  mutation addInformationCluster($projectId: ID!, $cluster: String!, $type: String!) {
    addInformationCluster(projectId: $projectId, cluster: $cluster, type: $type)
  }
`;

export const DELETE_INFORMATION_CLUSTER = gql`
  mutation deleteInformationCluster($projectId: ID!, $cluster: String!, $type: String!) {
    deleteInformationCluster(projectId: $projectId, cluster: $cluster, type: $type)
  }
`;

export const UPDATE_INFORMATION_CLUSTER = gql`
  mutation updateInformationCluster($projectId: ID!, $oldCluster: String!, $newCluster: String!, $type: String!) {
    updateInformationCluster(projectId: $projectId, oldCluster: $oldCluster, newCluster: $newCluster, type: $type)
  }
`;

export const ADD_SERVICELEVEL = gql`
  mutation addServiceLevel($projectId: ID!, $moduleId: ID!) {
    addServiceLevel(projectId: $projectId, moduleId: $moduleId)
  }
`;

export const DELETE_SERVICELEVEL = gql`
  mutation deleteServiceLevel($projectId: ID!, $moduleId: ID!, $servicelevelId: ID!) {
    deleteServiceLevel(projectId: $projectId, moduleId: $moduleId, servicelevelId: $servicelevelId)
  }
`;

export const UPDATE_SERVICELEVEL = gql`
  mutation updateServiceLevel($projectId: ID!, $servicelevelId: ID!, $name: String, $servicelevel: String, $value: String, $measure: String, $description: String, $penalty: String, $penaltyreference: String, $suggestion: String, $compliance: Boolean) {
    updateServiceLevel(projectId: $projectId, servicelevelId: $servicelevelId, name: $name, servicelevel: $servicelevel, value: $value, measure: $measure, description: $description, penalty: $penalty, penaltyreference: $penaltyreference, suggestion: $suggestion, compliance: $compliance)
  }
`;

export const ADD_QANDA = gql`
  mutation addQAndA($projectId: ID!, $question: String!) {
    addQAndA(projectId: $projectId, question: $question)
  }
`;

export const DELETE_QANDA = gql`
  mutation deleteQAndA($projectId: ID!, $qandaId: ID!) {
    deleteQAndA(projectId: $projectId, qandaId: $qandaId)
  }
`;

export const UPDATE_QANDA = gql`
  mutation updateQAndA($projectId: ID!, $qandaId: ID!, $question: String!, $answer: String!) {
    updateQAndA(projectId: $projectId, qandaId: $qandaId, question: $question, answer: $answer)
  }
`;

export const PUBLISH_QANDA = gql`
  mutation toggleQAndAPublished($projectId: ID!, $qandaId: ID!) {
    toggleQAndAPublished(projectId: $projectId, qandaId: $qandaId)
  }
`;
