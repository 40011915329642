import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_INFORMATIONS, GET_PROJECT } from '../../graphql/queries';
import {
  ADD_INFORMATION,
  UPDATE_INFORMATION,
  DELETE_INFORMATION,
  UPDATE_INFORMATION_NUMBER,
} from '../../graphql/mutations';

import {
  // fetchDatabaseReferences,
  // addDatabaseReference,
  // updateDatabaseReference,
  // deleteDatabaseReference,
  selectReference,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/databaseAction';
import DatabaseReferencesTable from '../../components/DatabaseManagement/DatabaseReferencesTable';
import Loading from '../../components/Elements/Loading';

function DatabaseReferencesContainer(props) {
  const {
    // references,
    // projects,
    // fetchDatabaseReferences,
    // addDatabaseReference,
    // updateDatabaseReference,
    // deleteDatabaseReference,
    selectReference,
    selectProject,
    selectTemplate,
    selectedProject,
    // loading,
  } = props;
  // useEffect(() => {
  //   fetchDatabaseReferences(selectedProject);
  // }, [fetchDatabaseReferences, selectedProject]);

  const informationType = 'Referenz';
  const cluster = '';
  const { loading, error, data } = useQuery(GET_INFORMATIONS, {
    variables: { projectId: selectedProject, type: informationType, cluster },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [addInformation] = useMutation(ADD_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [updateInformation] = useMutation(UPDATE_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [deleteInformation] = useMutation(DELETE_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [updateInformationNumber] = useMutation(UPDATE_INFORMATION_NUMBER, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  return (
    <DatabaseReferencesTable
      references={data.informations}
      projects={data1.project}
      addDatabaseReference={addInformation}
      updateDatabaseReference={updateInformation}
      deleteDatabaseReference={deleteInformation}
      updateInformationNumber={updateInformationNumber}
      selectReference={selectReference}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      loading={loading}
      type={informationType}
      cluster={cluster}
    />
  );
}

const mapStateToProps = (state) => ({
  references: state.database.references,
  projects: state.database.projects,
  selectedProject: state.database.selectedProject,
  loading: state.database.loading,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchDatabaseReferences: (id) => dispatch(fetchDatabaseReferences(id)),
  // addDatabaseReference: (projectId, question) =>
  //   dispatch(addDatabaseReference(projectId, question)),
  // updateDatabaseReference: (id, question) =>
  //   dispatch(updateDatabaseReference(id, question)),
  // deleteDatabaseReference: (id) => dispatch(deleteDatabaseReference(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectReference: (id) => dispatch(selectReference(id)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DatabaseReferencesContainer),
);

DatabaseReferencesContainer.propTypes = {
  // fetchDatabaseReferences: PropTypes.func.isRequired,
  // addDatabaseReference: PropTypes.func.isRequired,
  // updateDatabaseReference: PropTypes.func.isRequired,
  // deleteDatabaseReference: PropTypes.func.isRequired,
  selectReference: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // references: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
};
