function FullsizeTableOptions(props) {
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    rowHover: false,
    pagination: false,
    download: false,
    print: false,
    fixedHeader: true,
    elevation: 0,
    tableBodyMaxHeight: 'calc(100vh - 209px)',
    textLabels: {
      body: {
        noMatch: 'Keine passenden Daten gefunden',
      },
      toolbar: {
        search: 'Suche',
        viewColumns: 'Spalten',
        filterTable: 'Filter',
      },
      filter: {
        all: 'Alle',
        title: 'FILTER',
        reset: 'Zurücksetzen',
      },
      viewColumns: {
        title: 'Spalten anzeigen',
        titleAria: 'Spalten anzeigen/verbergen',
      },
    },
  };

  if (props) {
    Object.entries(props).map(([key, value]) => options[key] = value);
  }

  return options;
}
export default FullsizeTableOptions;
