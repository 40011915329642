import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tableButton: {
    textTransform: 'none',
    textAlign: 'start',
    justifyContent: 'left',
  },
}));

function TableButton(props) {
  const classes = useStyles();
  const { text, onClick, icon } = props;
  return (
    <Button className={classes.tableButton} onClick={onClick}>
      {icon && icon}
      {text}
    </Button>
  );
}

export default TableButton;

TableButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.shape(Object),
};
