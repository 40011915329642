import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  selectProject,
  selectTemplate,
  selectModule,
  selectSla,
  selectRatecard,
  selectTAndM,
  selectFixedPrice,
  selectPiecePrice,
  selectInformation,
  selectReference,
  selectSubcontractor,
  selectQAndA,
} from '../../redux/actionCreators/projectAction';
import ProjectPage from '../../components/ProjectManagement/ProjectPage';
import CommentsContainer from '../Comment/CommentsContainer';

function ProjectContainer(props) {
  const {
    history,
    selectedProject,
    selectedTemplate,
    selectedModule,
    selectedSla,
    selectedRatecard,
    selectedTAndM,
    selectedFixedPrice,
    selectedPiecePrice,
    selectedInformation,
    selectedReference,
    selectedSubcontractor,
    selectedQAndA,
    user,
    selectProject,
    selectTemplate,
    selectModule,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectSubcontractor,
    selectQAndA,
  } = props;
  useEffect(() => {
    if (!(user.role === 'AG' || user.role === 'ITAG')) {
      history.push('/main');
    }
    return () => {
      // selectProject('');
      selectTemplate('');
      selectModule('');
      selectSla('');
      selectRatecard('');
      selectTAndM('');
      selectFixedPrice('');
      selectPiecePrice('');
      selectInformation('');
      selectReference('');
      selectSubcontractor('');
      selectQAndA('');
    };
  }, [
    user,
    history,
    selectProject,
    selectTemplate,
    selectModule,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectSubcontractor,
    selectQAndA,
  ]);

  return (
    <>
      <ProjectPage
        selectedProject={selectedProject}
        selectedTemplate={selectedTemplate}
        selectedModule={selectedModule}
        selectedSla={selectedSla}
        selectedRatecard={selectedRatecard}
        selectedTAndM={selectedTAndM}
        selectedFixedPrice={selectedFixedPrice}
        selectedPiecePrice={selectedPiecePrice}
        selectedInformation={selectedInformation}
        selectedReference={selectedReference}
        selectedSubcontractor={selectedSubcontractor}
        selectedQAndA={selectedQAndA}
      />
      {selectedProject ? (
        <CommentsContainer selectedProject={selectedProject} />
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  selectedProject: state.project.selectedProject,
  selectedTemplate: state.project.selectedTemplate,
  selectedModule: state.project.selectedModule,
  selectedSla: state.project.selectedSla,
  selectedRatecard: state.project.selectedRatecard,
  selectedTAndM: state.project.selectedTAndM,
  selectedFixedPrice: state.project.selectedFixedPrice,
  selectedPiecePrice: state.project.selectedPiecePrice,
  selectedInformation: state.project.selectedInformation,
  selectedReference: state.project.selectedReference,
  selectedSubcontractor: state.project.selectedSubcontractor,
  selectedQAndA: state.project.selectedQAndA,
  loading: state.project.loading,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectModule: (id) => dispatch(selectModule(id)),
  selectSla: (id) => dispatch(selectSla(id)),
  selectRatecard: (id) => dispatch(selectRatecard(id)),
  selectTAndM: (id) => dispatch(selectTAndM(id)),
  selectFixedPrice: (id) => dispatch(selectFixedPrice(id)),
  selectPiecePrice: (id) => dispatch(selectPiecePrice(id)),
  selectInformation: (id) => dispatch(selectInformation(id)),
  selectReference: (id) => dispatch(selectReference(id)),
  selectSubcontractor: (id) => dispatch(selectSubcontractor(id)),
  selectQAndA: (id) => dispatch(selectQAndA(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectContainer),
);

ProjectContainer.propTypes = {
  user: PropTypes.shape(Object).isRequired,
  history: PropTypes.shape(Object).isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  selectedModule: PropTypes.string.isRequired,
  selectedSla: PropTypes.string.isRequired,
  selectedRatecard: PropTypes.string.isRequired,
  selectedTAndM: PropTypes.string.isRequired,
  selectedFixedPrice: PropTypes.string.isRequired,
  selectedPiecePrice: PropTypes.string.isRequired,
  selectedInformation: PropTypes.string.isRequired,
  selectedReference: PropTypes.string.isRequired,
  selectedSubcontractor: PropTypes.string.isRequired,
  selectedQAndA: PropTypes.string.isRequired,
  selectProject: PropTypes.func,
  selectTemplate: PropTypes.func,
  selectModule: PropTypes.func,
  selectSla: PropTypes.func,
  selectRatecard: PropTypes.func,
  selectTAndM: PropTypes.func,
  selectFixedPrice: PropTypes.func,
  selectPiecePrice: PropTypes.func,
  selectInformation: PropTypes.func,
  selectReference: PropTypes.func,
  selectSubcontractor: PropTypes.func,
  selectQAndA: PropTypes.func,
};
