import React from 'react';
import PropTypes from 'prop-types';
import { Switch, FormControlLabel, FormGroup } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  switchBase: {
    color: red[300],
    '&$checked': {
      color: green[300],
    },
    '&$checked + $track': {
      backgroundColor: green[300],
    },
  },
  checked: {},
  track: {},
}));

function TableSwitch(props) {
  const { value, onChange, disabled, label } = props;
  const classes = useStyles();
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            disabled={disabled}
            checked={value}
            value={value}
            classes={{
              switchBase: classes.switchBase,
              checked: classes.checked,
              track: classes.track,
            }}
            onChange={onChange}
          />
      }
        label={label || ''}
      />
    </FormGroup>
  );
}

export default TableSwitch;

TableSwitch.propTypes = {
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};
