import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';

const useStyles = makeStyles({
  rightAlignedHeader: {
    '& span': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      flexDirection: 'row-reverse',
      padding: 0,
    },
    '& button': {
      margin: 0,
      padding: '0.5em',
    },
    '&>*:nth-child(1)': {
      paddingRight: '1.5em',
    },
  },
  rightAlignedCell: {
    textAlign: 'right',
    '& span': {
      paddingRight: '2em',
    },
  },
  rowHidden: {
    height: '5px',
    // visibility: 'collapse',
    '& td': {
      // visibility: 'collapse',
      height: '5px',
      // display: 'none',
      padding: 0,
    },
    '& td *': {
      visibility: 'collapse',
      display: 'none',
      padding: 0,
    },
  },
  rowVisible: {
    visibility: 'visible',
  },
  check: {
    color: green[300],
  },
  clear: {
    color: red[300],
  },
});

export { useStyles as default };
