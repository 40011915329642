import React /* ,  { useEffect }  */from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PROJECT, GET_TEMPLATES, GET_MODULES, GET_SERVICELEVELS } from '../../graphql/queries';
import {
  ADD_MODULE,
  DELETE_MODULE,
  UPDATE_MODULE,
  MODULE_NUMBER_DOWN,
  MODULE_NUMBER_UP,
  UPDATE_MODULE_PRICING,
  UPDATE_MODULE_SLA,
  ADD_STATEMENT,
  DELETE_STATEMENT,
  UPDATE_STATEMENT,
  STATEMENT_NUMBER_DOWN,
  STATEMENT_NUMBER_UP } from '../../graphql/mutations';

import {
  selectProject,
  selectTemplate,
  selectModule,
} from '../../redux/actionCreators/databaseAction';
import DatabaseModulesTable from '../../components/DatabaseManagement/DatabaseModulesTable';
import Loading from '../../components/Elements/Loading';

function DatabaseModulesContainer(props) {
  const {
    selectProject,
    selectedProject,
    selectTemplate,
    selectedTemplate,
    selectModule,
  } = props;

  const { loading, error, data } = useQuery(GET_MODULES, {
    variables: { projectId: selectedProject, templateId: selectedTemplate },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });
  const { loading: loading2, error: error2, data: data2 } = useQuery(GET_TEMPLATES, {
    variables: { projectId: selectedProject },
  });

  const [addModule] = useMutation(ADD_MODULE, {
    refetchQueries: [{
      query: GET_MODULES,
      variables: { projectId: selectedProject, templateId: selectedTemplate },
    }],
  });
  const [deleteModule] = useMutation(DELETE_MODULE, {
    refetchQueries: [{
      query: GET_MODULES,
      variables: { projectId: selectedProject, templateId: selectedTemplate },
    }],
  });
  const [updateModule] = useMutation(UPDATE_MODULE, {
    refetchQueries: [{
      query: GET_MODULES,
      variables: { projectId: selectedProject, templateId: selectedTemplate },
    }],
  });
  const [updateModulePricing] = useMutation(UPDATE_MODULE_PRICING, {
    refetchQueries: [{
      query: GET_MODULES,
      variables: { projectId: selectedProject, templateId: selectedTemplate },
    }],
  });
  const [updateModuleSla] = useMutation(UPDATE_MODULE_SLA, {
    refetchQueries: [{
      query: GET_MODULES,
      variables: { projectId: selectedProject, templateId: selectedTemplate },
    },
    {
      query: GET_SERVICELEVELS,
      variables: { projectId: selectedProject },
    }],
  });
  const [moduleNumberDown] = useMutation(MODULE_NUMBER_DOWN, {
    refetchQueries: [{
      query: GET_MODULES,
      variables: { projectId: selectedProject, templateId: selectedTemplate },
    }],
  });
  const [moduleNumberUp] = useMutation(MODULE_NUMBER_UP, {
    refetchQueries: [{
      query: GET_MODULES,
      variables: { projectId: selectedProject, templateId: selectedTemplate },
    }],
  });
  const [addStatement] = useMutation(ADD_STATEMENT, {
    refetchQueries: [{
      query: GET_MODULES,
      variables: { projectId: selectedProject, templateId: selectedTemplate },
    }],
  });
  const [deleteStatement] = useMutation(DELETE_STATEMENT, {
    refetchQueries: [{
      query: GET_MODULES,
      variables: { projectId: selectedProject, templateId: selectedTemplate },
    }],
  });
  const [updateStatement] = useMutation(UPDATE_STATEMENT, {
    refetchQueries: [{
      query: GET_MODULES,
      variables: { projectId: selectedProject, templateId: selectedTemplate },
    }],
  });
  const [statementNumberDown] = useMutation(STATEMENT_NUMBER_DOWN, {
    refetchQueries: [{
      query: GET_MODULES,
      variables: { projectId: selectedProject, templateId: selectedTemplate },
    }],
  });
  const [statementNumberUp] = useMutation(STATEMENT_NUMBER_UP, {
    refetchQueries: [{
      query: GET_MODULES,
      variables: { projectId: selectedProject, templateId: selectedTemplate },
    }],
  });

  if (loading || loading1 || loading2) return <Loading />;
  if (error || error1 || error2) {
    return (
      <p>
        Error :$
        {error + error1 + error2}
      </p>
    );
  }

  return (
    <DatabaseModulesTable
      projects={data1.project}
      templates={data2.templates}
      modules={data.modules}
      selectModule={selectModule}
      selectTemplate={selectTemplate}
      selectedTemplate={selectedTemplate}
      selectProject={selectProject}
      selectedProject={selectedProject}
      addModule={addModule}
      updateModule={updateModule}
      deleteModule={deleteModule}
      loading={loading}
      updateModuleSla={updateModuleSla}
      updateModulePricing={updateModulePricing}
      moduleNumberDown={moduleNumberDown}
      moduleNumberUp={moduleNumberUp}
      addStatement={addStatement}
      updateStatement={updateStatement}
      deleteStatement={deleteStatement}
      statementNumberDown={statementNumberDown}
      statementNumberUp={statementNumberUp}
    />
  );
}

const mapStateToProps = (state) => ({
  projects: state.database.projects,
  templates: state.database.templates,
  modules: state.database.modules,
  selectedProject: state.database.selectedProject,
  selectedTemplate: state.database.selectedTemplate,
  loading: state.database.loading,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchModules: (templateId) => dispatch(fetchModules(templateId)),
  // addModule: (id, name, heading) => dispatch(addModule(id, name, heading)),
  // updateModule: (id, name, heading) =>
  //   dispatch(updateModule(id, name, heading)),
  // deleteModule: (id) => dispatch(deleteModule(id)),
  // changeActiveModule: (id) => dispatch(changeActiveModule(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectModule: (id) => dispatch(selectModule(id)),
  // uploadDatabaseModuleFile: (formData, config) =>
  //   dispatch(uploadDatabaseModuleFile(formData, config)),
  // removeDatabaseModuleUpload: (id) => dispatch(removeDatabaseModuleUpload(id)),
  // updateModuleSla: (id) => dispatch(updateModuleSla(id)),
  // updateModulePricing: (id, pricing) =>
  //   dispatch(updateModulePricing(id, pricing)),
  // moduleNumberDown: (id) => dispatch(moduleNumberDown(id)),
  // moduleNumberUp: (id) => dispatch(moduleNumberUp(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DatabaseModulesContainer),
);

DatabaseModulesContainer.propTypes = {
  // fetchModules: PropTypes.func.isRequired,
  // addModule: PropTypes.func.isRequired,
  // updateModule: PropTypes.func.isRequired,
  // deleteModule: PropTypes.func.isRequired,
  // changeActiveModule: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectModule: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // modules: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  // uploadDatabaseModuleFile: PropTypes.func.isRequired,
  // removeDatabaseModuleUpload: PropTypes.func.isRequired,
  // updateModuleSla: PropTypes.func.isRequired,
  // updateModulePricing: PropTypes.func.isRequired,
  // moduleNumberDown: PropTypes.func.isRequired,
  // moduleNumberUp: PropTypes.func.isRequired,
};
