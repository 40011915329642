import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_QANDAS, GET_PROJECT } from '../../graphql/queries';
import {
  ADD_QANDA,
  UPDATE_QANDA,
  DELETE_QANDA,
  PUBLISH_QANDA,
} from '../../graphql/mutations';

import {
  // fetchProjectQAndAs,
  // addProjectQAndA,
  // updateProjectQAndA,
  // deleteProjectQAndA,
  selectQAndA,
  // uploadProjectQAndAFile,
  // removeProjectQAndAUpload,
  // toggleQAndAPublished,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/projectAction';
import ProjectQAndAsTable from '../../components/ProjectManagement/ProjectQAndAsTable';
import Loading from '../../components/Elements/Loading';

function ProjectQAndAsContainer(props) {
  const {
    // qandas,
    selectedProject,
    // projects,
    // fetchProjectQAndAs,
    // addProjectQAndA,
    // updateProjectQAndA,
    // deleteProjectQAndA,
    selectQAndA,
    selectProject,
    selectTemplate,
    // uploadProjectQAndAFile,
    // removeProjectQAndAUpload,
    // toggleQAndAPublished,
    // user,
    // loading,
  } = props;
  // useEffect(() => {
  //   fetchProjectQAndAs(selectedProject);
  // }, [fetchProjectQAndAs, selectedProject]);

  const { loading, error, data } = useQuery(GET_QANDAS, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [addQAndA] = useMutation(ADD_QANDA, {
    refetchQueries: [{
      query: GET_QANDAS,
      variables: { projectId: selectedProject },
    }],
  });
  const [updateQAndA] = useMutation(UPDATE_QANDA, {
    refetchQueries: [{
      query: GET_QANDAS,
      variables: { projectId: selectedProject },
    }],
  });
  const [deleteQAndA] = useMutation(DELETE_QANDA, {
    refetchQueries: [{
      query: GET_QANDAS,
      variables: { projectId: selectedProject },
    }],
  });
  const [toggleQAndAPublished] = useMutation(PUBLISH_QANDA, {
    refetchQueries: [{
      query: GET_QANDAS,
      variables: { projectId: selectedProject },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }

  return (
    <ProjectQAndAsTable
      qandas={data.qandas}
      projects={data1.project}
      addProjectQAndA={addQAndA}
      updateProjectQAndA={updateQAndA}
      deleteProjectQAndA={deleteQAndA}
      // selectedProject={selectedProject}
      selectProject={selectProject}
      selectTemplate={selectTemplate}
      selectQAndA={selectQAndA}
      // uploadProjectQAndAFile={uploadProjectQAndAFile}
      // removeProjectQAndAUpload={removeProjectQAndAUpload}
      toggleQAndAPublished={toggleQAndAPublished}
      // user={user}
      // loading={loadng}
    />
  );
}

const mapStateToProps = (state) => ({
  qandas: state.project.qandas,
  selectedProject: state.project.selectedProject,
  projects: state.project.projects,
  loading: state.project.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchProjectQAndAs: (id) => dispatch(fetchProjectQAndAs(id)),
  // addProjectQAndA: (id, question) => dispatch(addProjectQAndA(id, question)),
  // updateProjectQAndA: (id, question, answer, projectId) =>
  //   dispatch(updateProjectQAndA(id, question, answer, projectId)),
  // deleteProjectQAndA: (id) => dispatch(deleteProjectQAndA(id)),
  // uploadProjectQAndAFile: (formData, config) =>
  //   dispatch(uploadProjectQAndAFile(formData, config)),
  // removeProjectQAndAUpload: (id) => dispatch(removeProjectQAndAUpload(id)),
  // toggleQAndAPublished: (id) => dispatch(toggleQAndAPublished(id)),
  selectQAndA: (id) => dispatch(selectQAndA(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectQAndAsContainer),
);

ProjectQAndAsContainer.propTypes = {
  // fetchProjectQAndAs: PropTypes.func.isRequired,
  // updateProjectQAndA: PropTypes.func.isRequired,
  // deleteProjectQAndA: PropTypes.func.isRequired,
  selectQAndA: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  // loading: PropTypes.bool.isRequired,
  // user: PropTypes.shape(Object).isRequired,
  // qandas: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  // uploadProjectQAndAFile: PropTypes.func.isRequired,
  // removeProjectQAndAUpload: PropTypes.func.isRequired,
  // toggleQAndAPublished: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // addProjectQAndA: PropTypes.func.isRequired,
};
