import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  updateEditors,
  updateViewers,
  fetchOfferingEditors,
  fetchOfferingViewers,
} from '../../redux/actionCreators/offeringAction';
import ChangeOfferingUsersDialog from '../../components/Dialogs/ChangeOfferingUsersDialog';

function ChangeUsersContainer(props) {
  const [open, setOpen] = useState(false);
  const {
    projectId,
    roles,
    userEmail,
    updateRoles,
  } = props;

  return (
    <ChangeOfferingUsersDialog
      projectId={projectId}
      open={open}
      setOpen={setOpen}
      projectEditors={roles.filter((role) => role.type === 'editor')}
      projectViewers={roles.filter((role) => role.type === 'viewer')}
      userEmail={userEmail}
      updateRoles={updateRoles}
    />
  );
}

const mapStateToProps = (state) => ({
  projectEditors: state.offering.editors,
  projectViewers: state.offering.viewers,
  userEmail: state.auth.user.email,
});

const mapDispatchToProps = (dispatch) => ({
  updateEditors: (projectId, email, option) =>
    dispatch(updateEditors(projectId, email, option)),
  updateViewers: (projectId, email, option) =>
    dispatch(updateViewers(projectId, email, option)),
  fetchOfferingEditors: (id) => dispatch(fetchOfferingEditors(id)),
  fetchOfferingViewers: (id) => dispatch(fetchOfferingViewers(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ChangeUsersContainer),
);

ChangeUsersContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  userEmail: PropTypes.string.isRequired,
  updateRoles: PropTypes.func.isRequired,
};
