import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery } from '@apollo/react-hooks';
import { GET_PROJECTS } from '../../graphql/queries';

import {
  selectProject, selectTemplate,
} from '../../redux/actionCreators/projectAction';
import { selectProject as selectOffer } from '../../redux/actionCreators/viewofferingAction';
import { selectProject as selectNegotiation } from '../../redux/actionCreators/negotiationAction';
import { logoutUser } from '../../redux/actionCreators/authenticationAction';
import { toggleDarkMode } from '../../redux/actionCreators/settingsAction';

import ButtonAppBar from '../../components/Elements/ButtonAppBar';
import Loading from '../../components/Elements/Loading';

function ButtonAppBarContainer(props) {
  const {
    selectProject,
    selectNegotiation,
    selectOffer,
    selectTemplate,
    selectedProject,
    darkModeEnabled,
    user,
    history,
    isAuthenticated,
    logoutUser,
    toggleDarkMode,
  } = props;
  if (isAuthenticated) {
    const projectType = 'project';
    const { loading, error, data } = useQuery(GET_PROJECTS, {
      variables: { type: projectType },
      pollInterval: 1000,
    });
    if (loading) return <Loading />;
    if (error) {
      return (
        <p>
          Error :$
          {error.message}
        </p>
      );
    }

    return (
      <ButtonAppBar
        projects={data.projects.sort((a, b) => Math.max(...b.changes.map((o) => o.date)) - Math.max(...a.changes.map((o) => o.date)))}
        selectProject={selectProject}
        selectTemplate={selectTemplate}
        selectOffer={selectOffer}
        selectNegotiation={selectNegotiation}
        selectedProject={selectedProject}
        darkModeEnabled={darkModeEnabled}
        user={user}
        history={history}
        isAuthenticated={isAuthenticated}
        logoutUser={logoutUser}
        toggleDarkMode={toggleDarkMode}
      />
    );
  }
  return (
    <ButtonAppBar
      darkModeEnabled={darkModeEnabled}
      user={user}
      history={history}
      isAuthenticated={isAuthenticated}
      logoutUser={logoutUser}
      toggleDarkMode={toggleDarkMode}
    />
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  darkModeEnabled: state.settings.darkModeEnabled,
  selectedProject: state.project.selectedProject,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: (history) => dispatch(logoutUser(history)),
  toggleDarkMode: () => dispatch(toggleDarkMode()),
  selectProject: (id) => dispatch(selectProject(id)),
  selectOffer: (id) => dispatch(selectOffer(id)),
  selectNegotiation: (id) => dispatch(selectNegotiation(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ButtonAppBarContainer),
);

ButtonAppBarContainer.propTypes = {
  selectProject: PropTypes.func,
  selectOffer: PropTypes.func,
  selectNegotiation: PropTypes.func,
  selectTemplate: PropTypes.func,
  selectedProject: PropTypes.string.isRequired,
  darkModeEnabled: PropTypes.bool.isRequired,
  user: PropTypes.shape(Object),
  history: PropTypes.shape(Object).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  logoutUser: PropTypes.func.isRequired,
  toggleDarkMode: PropTypes.func.isRequired,
};
