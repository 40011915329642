import axios from 'axios';
import {
  CREATE_OFFERING_BEGIN,
  CREATE_OFFERING_SUCCESS,
  CREATE_OFFERING_FAILURE,
  OFFERING_FETCH_PROJECTS_BEGIN,
  OFFERING_FETCH_PROJECTS_SUCCESS,
  OFFERING_FETCH_PROJECTS_FAILURE,
  OFFERING_FETCH_TEMPLATES_BEGIN,
  OFFERING_FETCH_TEMPLATES_SUCCESS,
  OFFERING_FETCH_TEMPLATES_FAILURE,
  OFFERING_FETCH_MODULES_BEGIN,
  OFFERING_FETCH_MODULES_SUCCESS,
  OFFERING_FETCH_MODULES_FAILURE,
  OFFERING_FETCH_STATEMENTS_BEGIN,
  OFFERING_FETCH_STATEMENTS_SUCCESS,
  OFFERING_FETCH_STATEMENTS_FAILURE,
  OFFERING_CLEAR_PROJECTS_ARRAY,
  OFFERING_CLEAR_TEMPLATES_ARRAY,
  OFFERING_CLEAR_MODULES_ARRAY,
  OFFERING_CLEAR_STATEMENTS_ARRAY,
  OFFERING_SELECT_PROJECT,
  OFFERING_SELECT_TEMPLATE,
  OFFERING_SELECT_MODULE,
  OFFERING_SELECT_STATEMENT,
  UPDATE_OFFERING_STATEMENT_COMPLIANCE_BEGIN,
  UPDATE_OFFERING_STATEMENT_COMPLIANCE_SUCCESS,
  UPDATE_OFFERING_STATEMENT_COMPLIANCE_FAILURE,
  UPDATE_SUGGESTION_BEGIN,
  UPDATE_SUGGESTION_SUCCESS,
  UPDATE_SUGGESTION_FAILURE,
  DELETE_OFFERING_BEGIN,
  DELETE_OFFERING_SUCCESS,
  DELETE_OFFERING_FAILURE,
  PUBLISH_OFFERING_BEGIN,
  PUBLISH_OFFERING_SUCCESS,
  PUBLISH_OFFERING_FAILURE,
  SELECT_ASSUMPTION,
  SELECT_PROVISION,
  FETCH_ASSUMPTIONS_BEGIN,
  FETCH_ASSUMPTIONS_SUCCESS,
  FETCH_ASSUMPTIONS_FAILURE,
  FETCH_PROVISIONS_BEGIN,
  FETCH_PROVISIONS_SUCCESS,
  FETCH_PROVISIONS_FAILURE,
  DELETE_ASSUMPTIONS_BEGIN,
  DELETE_ASSUMPTIONS_SUCCESS,
  DELETE_ASSUMPTIONS_FAILURE,
  DELETE_PROVISIONS_BEGIN,
  DELETE_PROVISIONS_SUCCESS,
  DELETE_PROVISIONS_FAILURE,
  ADD_ASSUMPTION_BEGIN,
  ADD_ASSUMPTION_SUCCESS,
  ADD_ASSUMPTION_FAILURE,
  ADD_PROVISION_BEGIN,
  ADD_PROVISION_SUCCESS,
  ADD_PROVISION_FAILURE,
  FETCH_OFFERING_SLAS_BEGIN,
  FETCH_OFFERING_SLAS_SUCCESS,
  FETCH_OFFERING_SLAS_FAILURE,
  CLEAR_OFFERING_SLAS_ARRAY,
  UPDATE_OFFERING_SLA_BEGIN,
  UPDATE_OFFERING_SLA_SUCCESS,
  UPDATE_OFFERING_SLA_FAILURE,
  SELECT_OFFERING_SLA,
  FETCH_OFFERING_RATECARDS_BEGIN,
  FETCH_OFFERING_RATECARDS_SUCCESS,
  FETCH_OFFERING_RATECARDS_FAILURE,
  FETCH_OFFERING_FIXEDPRICES_BEGIN,
  FETCH_OFFERING_FIXEDPRICES_SUCCESS,
  FETCH_OFFERING_FIXEDPRICES_FAILURE,
  FETCH_OFFERING_PIECEPRICES_BEGIN,
  FETCH_OFFERING_PIECEPRICES_SUCCESS,
  FETCH_OFFERING_PIECEPRICES_FAILURE,
  FETCH_OFFERING_TANDMS_BEGIN,
  FETCH_OFFERING_TANDMS_SUCCESS,
  FETCH_OFFERING_TANDMS_FAILURE,
  UPDATE_OFFERING_PIECEPRICE_BEGIN,
  UPDATE_OFFERING_PIECEPRICE_SUCCESS,
  UPDATE_OFFERING_PIECEPRICE_FAILURE,
  SELECT_OFFERING_RATECARD,
  SELECT_OFFERING_FIXEDPRICE,
  SELECT_OFFERING_PIECEPRICE,
  SELECT_OFFERING_TANDM,
  SELECT_OFFERING_REFERENCE,
  UPDATE_OFFERING_RATECARD_BEGIN,
  UPDATE_OFFERING_RATECARD_SUCCESS,
  UPDATE_OFFERING_RATECARD_FAILURE,
  UPDATE_OFFERING_SLA_COMPLIANCE_BEGIN,
  UPDATE_OFFERING_SLA_COMPLIANCE_SUCCESS,
  UPDATE_OFFERING_SLA_COMPLIANCE_FAILURE,
  UPLOAD_OFFERING_PROJECT_FILE_BEGIN,
  UPLOAD_OFFERING_PROJECT_FILE_SUCCESS,
  UPLOAD_OFFERING_PROJECT_FILE_FAILURE,
  REMOVE_OFFERING_PROJECT_UPLOADS_BEGIN,
  REMOVE_OFFERING_PROJECT_UPLOADS_SUCCESS,
  REMOVE_OFFERING_PROJECT_UPLOADS_FAILURE,
  UPDATE_TANDM_BEGIN,
  UPDATE_TANDM_SUCCESS,
  UPDATE_TANDM_FAILURE,
  UPDATE_FIXEDPRICE_BEGIN,
  UPDATE_FIXEDPRICE_SUCCESS,
  UPDATE_FIXEDPRICE_FAILURE,
  ADD_TANDM_BEGIN,
  ADD_TANDM_SUCCESS,
  ADD_TANDM_FAILURE,
  DELETE_TANDM_BEGIN,
  DELETE_TANDM_SUCCESS,
  DELETE_TANDM_FAILURE,
  SELECT_OFFERING_INFORMATION,
  FETCH_OFFERING_INFORMATION_BEGIN,
  FETCH_OFFERING_INFORMATION_SUCCESS,
  FETCH_OFFERING_INFORMATION_FAILURE,
  UPDATE_OFFERING_INFORMATION_BEGIN,
  UPDATE_OFFERING_INFORMATION_SUCCESS,
  UPDATE_OFFERING_INFORMATION_FAILURE,
  FETCH_OFFERING_EDITORS_BEGIN,
  FETCH_OFFERING_EDITORS_SUCCESS,
  FETCH_OFFERING_EDITORS_FAILURE,
  UPDATE_OFFERING_EDITORS_BEGIN,
  UPDATE_OFFERING_EDITORS_SUCCESS,
  UPDATE_OFFERING_EDITORS_FAILURE,
  FETCH_OFFERING_VIEWERS_BEGIN,
  FETCH_OFFERING_VIEWERS_SUCCESS,
  FETCH_OFFERING_VIEWERS_FAILURE,
  UPDATE_OFFERING_VIEWERS_BEGIN,
  UPDATE_OFFERING_VIEWERS_SUCCESS,
  UPDATE_OFFERING_VIEWERS_FAILURE,
  FETCH_OFFERING_REFERENCES_BEGIN,
  FETCH_OFFERING_REFERENCES_SUCCESS,
  FETCH_OFFERING_REFERENCES_FAILURE,
  ADD_OFFERING_REFERENCE_COMPANY_BEGIN,
  ADD_OFFERING_REFERENCE_COMPANY_SUCCESS,
  ADD_OFFERING_REFERENCE_COMPANY_FAILURE,
  SELECT_OFFERING_REFERENCE_COMPANY,
  DELETE_OFFERING_REFERENCE_COMPANY_BEGIN,
  DELETE_OFFERING_REFERENCE_COMPANY_SUCCESS,
  DELETE_OFFERING_REFERENCE_COMPANY_FAILURE,
  UPDATE_OFFERING_REFERENCE_COMPANY_BEGIN,
  UPDATE_OFFERING_REFERENCE_COMPANY_SUCCESS,
  UPDATE_OFFERING_REFERENCE_COMPANY_FAILURE,
  UPDATE_OFFERING_REFERENCE_BEGIN,
  UPDATE_OFFERING_REFERENCE_SUCCESS,
  UPDATE_OFFERING_REFERENCE_FAILURE,
  SELECT_OFFERING_SUBCONTRACTOR,
  FETCH_OFFERING_SUBCONTRACTORS_BEGIN,
  FETCH_OFFERING_SUBCONTRACTORS_SUCCESS,
  FETCH_OFFERING_SUBCONTRACTORS_FAILURE,
  ADD_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN,
  ADD_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS,
  ADD_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE,
  SELECT_OFFERING_SUBCONTRACTOR_COMPANY,
  DELETE_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN,
  DELETE_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS,
  DELETE_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE,
  UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN,
  UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS,
  UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE,
  UPDATE_OFFERING_SUBCONTRACTOR_BEGIN,
  UPDATE_OFFERING_SUBCONTRACTOR_SUCCESS,
  UPDATE_OFFERING_SUBCONTRACTOR_FAILURE,
  FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_BEGIN,
  FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_SUCCESS,
  FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_FAILURE,
  SELECT_OFFERING_QANDA,
  FETCH_OFFERING_QANDAS_BEGIN,
  FETCH_OFFERING_QANDAS_SUCCESS,
  FETCH_OFFERING_QANDAS_FAILURE,
  ADD_OFFERING_QANDA_BEGIN,
  ADD_OFFERING_QANDA_SUCCESS,
  ADD_OFFERING_QANDA_FAILURE,
  UPDATE_OFFERING_QANDA_BEGIN,
  UPDATE_OFFERING_QANDA_SUCCESS,
  UPDATE_OFFERING_QANDA_FAILURE,
  DELETE_OFFERING_QANDA_BEGIN,
  DELETE_OFFERING_QANDA_SUCCESS,
  DELETE_OFFERING_QANDA_FAILURE,
  UPLOAD_OFFERING_INFORMATION_FILE_BEGIN,
  UPLOAD_OFFERING_INFORMATION_FILE_SUCCESS,
  UPLOAD_OFFERING_INFORMATION_FILE_FAILURE,
  REMOVE_OFFERING_INFORMATION_UPLOADS_BEGIN,
  REMOVE_OFFERING_INFORMATION_UPLOADS_SUCCESS,
  REMOVE_OFFERING_INFORMATION_UPLOADS_FAILURE,
} from '../actionTypes/actionTypes';

export const fetchOfferingQAndAsBegin = () => ({
  type: FETCH_OFFERING_QANDAS_BEGIN,
});

export const fetchOfferingQAndAsSuccess = (qandas) => ({
  type: FETCH_OFFERING_QANDAS_SUCCESS,
  payload: { qandas },
});

export const fetchOfferingQAndAsFailure = (error) => ({
  type: FETCH_OFFERING_QANDAS_FAILURE,
  payload: { error },
});

export const addOfferingQAndABegin = () => ({
  type: ADD_OFFERING_QANDA_BEGIN,
});

export const addOfferingQAndASuccess = () => ({
  type: ADD_OFFERING_QANDA_SUCCESS,
});

export const addOfferingQAndAFailure = (error) => ({
  type: ADD_OFFERING_QANDA_FAILURE,
  payload: { error },
});

export const updateOfferingQAndABegin = () => ({
  type: UPDATE_OFFERING_QANDA_BEGIN,
});

export const updateOfferingQAndASuccess = () => ({
  type: UPDATE_OFFERING_QANDA_SUCCESS,
});

export const updateOfferingQAndAFailure = (error) => ({
  type: UPDATE_OFFERING_QANDA_FAILURE,
  payload: { error },
});

export const deleteOfferingQAndABegin = () => ({
  type: DELETE_OFFERING_QANDA_BEGIN,
});

export const deleteOfferingQAndASuccess = (qanda) => ({
  type: DELETE_OFFERING_QANDA_SUCCESS,
  payload: { qanda },
});

export const deleteOfferingQAndAFailure = (error) => ({
  type: DELETE_OFFERING_QANDA_FAILURE,
  payload: { error },
});

export const fetchAdditionalFilesIsShowingBegin = () => ({
  type: FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_BEGIN,
});

export const fetchAdditionalFilesIsShowingSuccess = (isShowing) => ({
  type: FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_SUCCESS,
  payload: { isShowing },
});

export const fetchAdditionalFilesIsShowingFailure = (error) => ({
  type: FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_FAILURE,
  payload: { error },
});

export const updateOfferingSubcontractorBegin = () => ({
  type: UPDATE_OFFERING_SUBCONTRACTOR_BEGIN,
});

export const updateOfferingSubcontractorSuccess = (subcontractor) => ({
  type: UPDATE_OFFERING_SUBCONTRACTOR_SUCCESS,
  payload: { subcontractor },
});

export const updateOfferingSubcontractorFailure = (error) => ({
  type: UPDATE_OFFERING_SUBCONTRACTOR_FAILURE,
  payload: { error },
});

export const updateOfferingSubcontractorCompanyBegin = () => ({
  type: UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN,
});

export const updateOfferingSubcontractorCompanySuccess = (subcontractors) => ({
  type: UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS,
  payload: { subcontractors },
});

export const updateOfferingSubcontractorCompanyFailure = (error) => ({
  type: UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE,
  payload: { error },
});

export const deleteOfferingSubcontractorCompanyBegin = () => ({
  type: DELETE_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN,
});

export const deleteOfferingSubcontractorCompanySuccess = (subcontractors) => ({
  type: DELETE_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS,
  payload: { subcontractors },
});

export const deleteOfferingSubcontractorCompanyFailure = (error) => ({
  type: DELETE_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE,
  payload: { error },
});

export const selectOfferingSubcontractorCompany = (company) => ({
  type: SELECT_OFFERING_SUBCONTRACTOR_COMPANY,
  payload: { company },
});

export const addOfferingSubcontractorCompanyBegin = () => ({
  type: ADD_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN,
});

export const addOfferingSubcontractorCompanySuccess = (subcontractors) => ({
  type: ADD_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS,
  payload: { subcontractors },
});

export const addOfferingSubcontractorCompanyFailure = (error) => ({
  type: ADD_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE,
  payload: { error },
});

export const fetchOfferingSubcontractorsBegin = () => ({
  type: FETCH_OFFERING_SUBCONTRACTORS_BEGIN,
});

export const fetchOfferingSubcontractorsSuccess = (subcontractors) => ({
  type: FETCH_OFFERING_SUBCONTRACTORS_SUCCESS,
  payload: { subcontractors },
});

export const fetchOfferingSubcontractorsFailure = (error) => ({
  type: FETCH_OFFERING_SUBCONTRACTORS_FAILURE,
  payload: { error },
});

export const updateOfferingReferenceBegin = () => ({
  type: UPDATE_OFFERING_REFERENCE_BEGIN,
});

export const updateOfferingReferenceSuccess = (reference) => ({
  type: UPDATE_OFFERING_REFERENCE_SUCCESS,
  payload: { reference },
});

export const updateOfferingReferenceFailure = (error) => ({
  type: UPDATE_OFFERING_REFERENCE_FAILURE,
  payload: { error },
});

export const updateOfferingReferenceCompanyBegin = () => ({
  type: UPDATE_OFFERING_REFERENCE_COMPANY_BEGIN,
});

export const updateOfferingReferenceCompanySuccess = (references) => ({
  type: UPDATE_OFFERING_REFERENCE_COMPANY_SUCCESS,
  payload: { references },
});

export const updateOfferingReferenceCompanyFailure = (error) => ({
  type: UPDATE_OFFERING_REFERENCE_COMPANY_FAILURE,
  payload: { error },
});

export const deleteOfferingReferenceCompanyBegin = () => ({
  type: DELETE_OFFERING_REFERENCE_COMPANY_BEGIN,
});

export const deleteOfferingReferenceCompanySuccess = (references) => ({
  type: DELETE_OFFERING_REFERENCE_COMPANY_SUCCESS,
  payload: { references },
});

export const deleteOfferingReferenceCompanyFailure = (error) => ({
  type: DELETE_OFFERING_REFERENCE_COMPANY_FAILURE,
  payload: { error },
});

export const selectOfferingReferenceCompany = (company) => ({
  type: SELECT_OFFERING_REFERENCE_COMPANY,
  payload: { company },
});

export const addOfferingReferenceCompanyBegin = () => ({
  type: ADD_OFFERING_REFERENCE_COMPANY_BEGIN,
});

export const addOfferingReferenceCompanySuccess = (references) => ({
  type: ADD_OFFERING_REFERENCE_COMPANY_SUCCESS,
  payload: { references },
});

export const addOfferingReferenceCompanyFailure = (error) => ({
  type: ADD_OFFERING_REFERENCE_COMPANY_FAILURE,
  payload: { error },
});

export const fetchOfferingReferencesBegin = () => ({
  type: FETCH_OFFERING_REFERENCES_BEGIN,
});

export const fetchOfferingReferencesSuccess = (references) => ({
  type: FETCH_OFFERING_REFERENCES_SUCCESS,
  payload: { references },
});

export const fetchOfferingReferencesFailure = (error) => ({
  type: FETCH_OFFERING_REFERENCES_FAILURE,
  payload: { error },
});

export const updateOfferingInformationBegin = () => ({
  type: UPDATE_OFFERING_INFORMATION_BEGIN,
});

export const updateOfferingInformationSuccess = () => ({
  type: UPDATE_OFFERING_INFORMATION_SUCCESS,
});

export const updateOfferingInformationFailure = (error) => ({
  type: UPDATE_OFFERING_INFORMATION_FAILURE,
  payload: { error },
});

export const selectInformation = (id) => ({
  type: SELECT_OFFERING_INFORMATION,
  payload: { id },
});

export const fetchOfferingInformationBegin = () => ({
  type: FETCH_OFFERING_INFORMATION_BEGIN,
});

export const fetchOfferingInformationSuccess = (information) => ({
  type: FETCH_OFFERING_INFORMATION_SUCCESS,
  payload: { information },
});

export const fetchOfferingInformationFailure = (error) => ({
  type: FETCH_OFFERING_INFORMATION_FAILURE,
  payload: { error },
});

export const deleteTAndMBegin = () => ({
  type: DELETE_TANDM_BEGIN,
});

export const deleteTAndMSuccess = () => ({
  type: DELETE_TANDM_SUCCESS,
});

export const deleteTAndMFailure = (error) => ({
  type: DELETE_TANDM_FAILURE,
  payload: { error },
});

export const addTAndMBegin = () => ({
  type: ADD_TANDM_BEGIN,
});

export const addTAndMSuccess = () => ({
  type: ADD_TANDM_SUCCESS,
});

export const addTAndMFailure = (error) => ({
  type: ADD_TANDM_FAILURE,
  payload: { error },
});

export const updateFixedPriceBegin = () => ({
  type: UPDATE_FIXEDPRICE_BEGIN,
});

export const updateFixedPriceSuccess = () => ({
  type: UPDATE_FIXEDPRICE_SUCCESS,
});

export const updateFixedPriceFailure = (error) => ({
  type: UPDATE_FIXEDPRICE_FAILURE,
  payload: { error },
});

export const updateTAndMBegin = () => ({
  type: UPDATE_TANDM_BEGIN,
});

export const updateTAndMSuccess = () => ({
  type: UPDATE_TANDM_SUCCESS,
});

export const updateTAndMFailure = () => ({
  type: UPDATE_TANDM_FAILURE,
});

export const updateRatecardBegin = () => ({
  type: UPDATE_OFFERING_RATECARD_BEGIN,
});

export const updateRatecardSuccess = () => ({
  type: UPDATE_OFFERING_RATECARD_SUCCESS,
});

export const updateRatecardFailure = (error) => ({
  type: UPDATE_OFFERING_RATECARD_FAILURE,
  payload: { error },
});

export const selectRatecard = (id) => ({
  type: SELECT_OFFERING_RATECARD,
  payload: { id },
});

export const selectFixedPrice = (id) => ({
  type: SELECT_OFFERING_FIXEDPRICE,
  payload: { id },
});

export const selectPiecePrice = (id) => ({
  type: SELECT_OFFERING_PIECEPRICE,
  payload: { id },
});

export const selectTAndM = (id) => ({
  type: SELECT_OFFERING_TANDM,
  payload: { id },
});

export const selectReference = (id) => ({
  type: SELECT_OFFERING_REFERENCE,
  payload: { id },
});

export const selectSubcontractor = (id) => ({
  type: SELECT_OFFERING_SUBCONTRACTOR,
  payload: { id },
});

export const updatePiecePriceBegin = () => ({
  type: UPDATE_OFFERING_PIECEPRICE_BEGIN,
});

export const updatePiecePriceSuccess = () => ({
  type: UPDATE_OFFERING_PIECEPRICE_SUCCESS,
});

export const updatePiecePriceFailure = (error) => ({
  type: UPDATE_OFFERING_PIECEPRICE_FAILURE,
  payload: { error },
});

export const fetchTAndMsBegin = () => ({
  type: FETCH_OFFERING_TANDMS_BEGIN,
});

export const fetchTAndMsSuccess = (tandms) => ({
  type: FETCH_OFFERING_TANDMS_SUCCESS,
  payload: { tandms },
});

export const fetchTAndMsFailure = (error) => ({
  type: FETCH_OFFERING_TANDMS_FAILURE,
  payload: { error },
});

export const fetchPiecePricesBegin = () => ({
  type: FETCH_OFFERING_PIECEPRICES_BEGIN,
});

export const fetchPiecePricesSuccess = (piecePrices) => ({
  type: FETCH_OFFERING_PIECEPRICES_SUCCESS,
  payload: { piecePrices },
});

export const fetchPiecePricesFailure = (error) => ({
  type: FETCH_OFFERING_PIECEPRICES_FAILURE,
  payload: { error },
});

export const fetchFixedPricesBegin = () => ({
  type: FETCH_OFFERING_FIXEDPRICES_BEGIN,
});

export const fetchFixedPricesSuccess = (fixedPrices) => ({
  type: FETCH_OFFERING_FIXEDPRICES_SUCCESS,
  payload: { fixedPrices },
});

export const fetchFixedPricesFailure = (error) => ({
  type: FETCH_OFFERING_FIXEDPRICES_FAILURE,
  payload: { error },
});

export const fetchRatecardsBegin = () => ({
  type: FETCH_OFFERING_RATECARDS_BEGIN,
});

export const fetchRatecardsSuccess = (ratecards) => ({
  type: FETCH_OFFERING_RATECARDS_SUCCESS,
  payload: { ratecards },
});

export const fetchRatecardsFailure = (error) => ({
  type: FETCH_OFFERING_RATECARDS_FAILURE,
  payload: { error },
});

export const selectSla = (id) => ({
  type: SELECT_OFFERING_SLA,
  payload: { id },
});

export const updateSlaBegin = () => ({
  type: UPDATE_OFFERING_SLA_BEGIN,
});

export const updateSlaSuccess = () => ({
  type: UPDATE_OFFERING_SLA_SUCCESS,
});

export const updateSlaFailure = (error) => ({
  type: UPDATE_OFFERING_SLA_FAILURE,
  payload: { error },
});

export const clearSlas = () => ({
  type: CLEAR_OFFERING_SLAS_ARRAY,
});

export const fetchSlasBegin = () => ({
  type: FETCH_OFFERING_SLAS_BEGIN,
});

export const fetchSlasSuccess = (slas) => ({
  type: FETCH_OFFERING_SLAS_SUCCESS,
  payload: { slas },
});

export const fetchSlasFailure = (error) => ({
  type: FETCH_OFFERING_SLAS_FAILURE,
  payload: { error },
});

export const addAssumptionBegin = () => ({
  type: ADD_ASSUMPTION_BEGIN,
});

export const addAssumptionSuccess = () => ({
  type: ADD_ASSUMPTION_SUCCESS,
});

export const addAssumptionFailure = (error) => ({
  type: ADD_ASSUMPTION_FAILURE,
  payload: { error },
});

export const addProvisionBegin = () => ({
  type: ADD_PROVISION_BEGIN,
});

export const addProvisionSuccess = () => ({
  type: ADD_PROVISION_SUCCESS,
});

export const addProvisionFailure = (error) => ({
  type: ADD_PROVISION_FAILURE,
  payload: { error },
});

export const deleteProvisionsBegin = () => ({
  type: DELETE_PROVISIONS_BEGIN,
});

export const deleteProvisionsSuccess = () => ({
  type: DELETE_PROVISIONS_SUCCESS,
});

export const deleteProvisionsFailure = (error) => ({
  type: DELETE_PROVISIONS_FAILURE,
  payload: { error },
});

export const deleteAssumptionsBegin = () => ({
  type: DELETE_ASSUMPTIONS_BEGIN,
});

export const deleteAssumptionsSuccess = () => ({
  type: DELETE_ASSUMPTIONS_SUCCESS,
});

export const deleteAssumptionsFailure = (error) => ({
  type: DELETE_ASSUMPTIONS_FAILURE,
  payload: { error },
});

export const fetchAssumptionsBegin = () => ({
  type: FETCH_ASSUMPTIONS_BEGIN,
});

export const fetchAssumptionsSuccess = (assumptions) => ({
  type: FETCH_ASSUMPTIONS_SUCCESS,
  payload: { assumptions },
});

export const fetchAssumptionsFailure = (error) => ({
  type: FETCH_ASSUMPTIONS_FAILURE,
  payload: { error },
});

export const fetchProvisionsBegin = () => ({
  type: FETCH_PROVISIONS_BEGIN,
});

export const fetchProvisionsSuccess = (provisions) => ({
  type: FETCH_PROVISIONS_SUCCESS,
  payload: { provisions },
});

export const fetchProvisionsFailure = (error) => ({
  type: FETCH_PROVISIONS_FAILURE,
  payload: { error },
});

export const selectAssumption = (id) => ({
  type: SELECT_ASSUMPTION,
  payload: { id },
});

export const selectProvision = (id) => ({
  type: SELECT_PROVISION,
  payload: { id },
});

export const publishOfferingBegin = () => ({
  type: PUBLISH_OFFERING_BEGIN,
});

export const publishOfferingSuccess = () => ({
  type: PUBLISH_OFFERING_SUCCESS,
});

export const publishOfferingFailure = (error) => ({
  type: PUBLISH_OFFERING_FAILURE,
  payload: { error },
});

export const deleteOfferingBegin = () => ({
  type: DELETE_OFFERING_BEGIN,
});

export const deleteOfferingSuccess = () => ({
  type: DELETE_OFFERING_SUCCESS,
});

export const deleteOfferingFailure = (error) => ({
  type: DELETE_OFFERING_FAILURE,
  payload: { error },
});

export const updateSuggestionBegin = () => ({
  type: UPDATE_SUGGESTION_BEGIN,
});

export const updateSuggestionSuccess = () => ({
  type: UPDATE_SUGGESTION_SUCCESS,
});

export const updateSuggestionFailure = (error) => ({
  type: UPDATE_SUGGESTION_FAILURE,
  payload: { error },
});

export const updateOfferingStatementComplainceBegin = () => ({
  type: UPDATE_OFFERING_STATEMENT_COMPLIANCE_BEGIN,
});

export const updateOfferingStatementComplainceSuccess = () => ({
  type: UPDATE_OFFERING_STATEMENT_COMPLIANCE_SUCCESS,
});

export const updateOfferingStatementComplainceFailure = (error) => ({
  type: UPDATE_OFFERING_STATEMENT_COMPLIANCE_FAILURE,
  payload: { error },
});

export const updateOfferingSlaComplianceBegin = () => ({
  type: UPDATE_OFFERING_SLA_COMPLIANCE_BEGIN,
});

export const updateOfferingSlaComplianceSuccess = () => ({
  type: UPDATE_OFFERING_SLA_COMPLIANCE_SUCCESS,
});

export const updateOfferingSlaComplianceFailure = (error) => ({
  type: UPDATE_OFFERING_SLA_COMPLIANCE_FAILURE,
  payload: { error },
});

export const selectProject = (project) => ({
  type: OFFERING_SELECT_PROJECT,
  payload: { project },
});

export const selectTemplate = (template) => ({
  type: OFFERING_SELECT_TEMPLATE,
  payload: { template },
});

export const selectModule = (module) => ({
  type: OFFERING_SELECT_MODULE,
  payload: { module },
});

export const selectStatement = (statement) => ({
  type: OFFERING_SELECT_STATEMENT,
  payload: { statement },
});

export const clearProjects = () => ({
  type: OFFERING_CLEAR_PROJECTS_ARRAY,
});

export const clearTemplates = () => ({
  type: OFFERING_CLEAR_TEMPLATES_ARRAY,
});

export const clearModules = () => ({
  type: OFFERING_CLEAR_MODULES_ARRAY,
});

export const clearStatements = () => ({
  type: OFFERING_CLEAR_STATEMENTS_ARRAY,
});

export const fetchProjectsBegin = () => ({
  type: OFFERING_FETCH_PROJECTS_BEGIN,
});

export const fetchProjectsSuccess = (projects) => ({
  type: OFFERING_FETCH_PROJECTS_SUCCESS,
  payload: { projects },
});

export const fetchProjectsFailure = (error) => ({
  type: OFFERING_FETCH_PROJECTS_FAILURE,
  payload: { error },
});

export const fetchTemplatesBegin = () => ({
  type: OFFERING_FETCH_TEMPLATES_BEGIN,
});

export const fetchTemplatesSuccess = (templates) => ({
  type: OFFERING_FETCH_TEMPLATES_SUCCESS,
  payload: { templates },
});

export const fetchTemplatesFailure = (error) => ({
  type: OFFERING_FETCH_TEMPLATES_FAILURE,
  payload: { error },
});

export const fetchModulesBegin = () => ({
  type: OFFERING_FETCH_MODULES_BEGIN,
});

export const fetchModulesSuccess = (modules) => ({
  type: OFFERING_FETCH_MODULES_SUCCESS,
  payload: { modules },
});

export const fetchModulesFailure = (error) => ({
  type: OFFERING_FETCH_MODULES_FAILURE,
  payload: { error },
});

export const fetchStatementsBegin = () => ({
  type: OFFERING_FETCH_STATEMENTS_BEGIN,
});

export const fetchStatementsSuccess = (statements) => ({
  type: OFFERING_FETCH_STATEMENTS_SUCCESS,
  payload: { statements },
});

export const fetchStatementsFailure = (error) => ({
  type: OFFERING_FETCH_STATEMENTS_FAILURE,
  payload: { error },
});

export const createOfferingBegin = () => ({
  type: CREATE_OFFERING_BEGIN,
});

export const createOfferingSuccess = () => ({
  type: CREATE_OFFERING_SUCCESS,
});

export const createOfferingFailure = (error) => ({
  type: CREATE_OFFERING_FAILURE,
  payload: { error },
});

export const uploadOfferingProjectFileBegin = () => ({
  type: UPLOAD_OFFERING_PROJECT_FILE_BEGIN,
});

export const uploadOfferingProjectFileSuccess = () => ({
  type: UPLOAD_OFFERING_PROJECT_FILE_SUCCESS,
});

export const uploadOfferingProjectFileFailure = (error) => ({
  type: UPLOAD_OFFERING_PROJECT_FILE_FAILURE,
  payload: { error },
});

export const removeOfferingProjectUploadBegin = () => ({
  type: REMOVE_OFFERING_PROJECT_UPLOADS_BEGIN,
});

export const removeOfferingProjectUploadSuccess = () => ({
  type: REMOVE_OFFERING_PROJECT_UPLOADS_SUCCESS,
});

export const removeOfferingProjectUploadFailure = (error) => ({
  type: REMOVE_OFFERING_PROJECT_UPLOADS_FAILURE,
  payload: { error },
});

export const fetchOfferingViewersBegin = () => ({
  type: FETCH_OFFERING_VIEWERS_BEGIN,
});

export const fetchOfferingViewersSuccess = (viewers) => ({
  type: FETCH_OFFERING_VIEWERS_SUCCESS,
  payload: { viewers },
});

export const fetchOfferingViewersFailure = (error) => ({
  type: FETCH_OFFERING_VIEWERS_FAILURE,
  payload: { error },
});

export const updateViewersBegin = () => ({
  type: UPDATE_OFFERING_VIEWERS_BEGIN,
});

export const updateViewersSuccess = () => ({
  type: UPDATE_OFFERING_VIEWERS_SUCCESS,
});

export const updateViewersFailure = (error) => ({
  type: UPDATE_OFFERING_VIEWERS_FAILURE,
  payload: { error },
});

export const fetchOfferingEditorsBegin = () => ({
  type: FETCH_OFFERING_EDITORS_BEGIN,
});

export const fetchOfferingEditorsSuccess = (editors) => ({
  type: FETCH_OFFERING_EDITORS_SUCCESS,
  payload: { editors },
});

export const fetchOfferingEditorsFailure = (error) => ({
  type: FETCH_OFFERING_EDITORS_FAILURE,
  payload: { error },
});

export const updateEditorsBegin = () => ({
  type: UPDATE_OFFERING_EDITORS_BEGIN,
});

export const updateEditorsSuccess = () => ({
  type: UPDATE_OFFERING_EDITORS_SUCCESS,
});

export const updateEditorsFailure = (error) => ({
  type: UPDATE_OFFERING_EDITORS_FAILURE,
  payload: { error },
});

export const selectQAndA = (qanda) => ({
  type: SELECT_OFFERING_QANDA,
  payload: { qanda },
});

export const uploadOfferingInformationFileBegin = () => ({
  type: UPLOAD_OFFERING_INFORMATION_FILE_BEGIN,
});

export const uploadOfferingInformationFileSuccess = () => ({
  type: UPLOAD_OFFERING_INFORMATION_FILE_SUCCESS,
});

export const uploadOfferingInformationFileFailure = (error) => ({
  type: UPLOAD_OFFERING_INFORMATION_FILE_FAILURE,
  payload: { error },
});

export const removeOfferingInformationUploadBegin = () => ({
  type: REMOVE_OFFERING_INFORMATION_UPLOADS_BEGIN,
});

export const removeOfferingInformationUploadSuccess = () => ({
  type: REMOVE_OFFERING_INFORMATION_UPLOADS_SUCCESS,
});

export const removeOfferingInformationUploadFailure = (error) => ({
  type: REMOVE_OFFERING_INFORMATION_UPLOADS_FAILURE,
  payload: { error },
});

export const fetchOfferingQAndAs = (projectId) => (dispatch) => {
  dispatch(fetchOfferingQAndAsBegin());
  return axios
    .post('/graphql', {
      query: `{
          qandas(project: "${projectId}") {
            _id,
            question, 
            answer,
            attachment,
            updated,
            ownQuestion
          }
        }`,
    })
    .then((res) => {
      const decodedQAndAs = res.data.data.qandas;
      decodedQAndAs.forEach((qanda) => {
        if (qanda.answer === null) {
          qanda.answer = '';
        } else {
          qanda.answer = decodeURIComponent(qanda.answer);
        }
        qanda.question = decodeURIComponent(qanda.question);
      });
      dispatch(fetchOfferingQAndAsSuccess(decodedQAndAs));
      return decodedQAndAs;
    })
    .catch((error) => dispatch(fetchOfferingQAndAsFailure(error)));
};

export const addOfferingQAndA = (projectId, question) => (dispatch) => {
  dispatch(addOfferingQAndABegin());
  const encodedQuestion = encodeURIComponent(question);
  return axios
    .post('/graphql', {
      query: `
        mutation {
          addQAndA(projectId: "${projectId}", question: "${encodedQuestion}") {
            _id,
            question, 
            answer
          }
        }
      `,
    })
    .then(() => {
      dispatch(addOfferingQAndASuccess());
      // fetching Templates after success
      dispatch(fetchOfferingQAndAs(projectId));
    })
    .catch((error) => dispatch(addOfferingQAndAFailure(error)));
};

export const updateOfferingQAndA = (quandaId, question) => (dispatch) => {
  dispatch(updateOfferingQAndABegin());
  const encodedQuestion = encodeURIComponent(question);
  return axios
    .post('/graphql', {
      query: `
        mutation {
          updateQAndA(_id: "${quandaId}", question: "${encodedQuestion}", answer: "") {
            _id,
            question, 
            answer, 
            project {
              _id
            },
          }
        }
      `,
    })
    .then((res) => {
      dispatch(updateOfferingQAndASuccess());
      dispatch(
        fetchOfferingQAndAs(res.data.data.updateQAndA.project._id),
      );
    })
    .catch((error) => dispatch(updateOfferingQAndAFailure(error)));
};

export const deleteOfferingQAndA = (qandaId) => (dispatch) => {
  dispatch(deleteOfferingQAndABegin());
  return axios
    .post('/graphql', {
      query: `
          mutation {
            deleteQAndA(_id: "${qandaId}") {
              _id
            }
          }
        `,
    })
    .then(() => {
      dispatch(deleteOfferingQAndASuccess(qandaId));
    })
    .catch((error) => {
      dispatch(deleteOfferingQAndAFailure(error));
    });
};

// fetching projects from database
export const fetchProjects = () => (dispatch) => {
  dispatch(fetchProjectsBegin());
  return axios('/api/v1/offering/projects')
    .then((res) => {
      const projects = res.data;
      dispatch(fetchProjectsSuccess(projects));
      return projects;
    })
    .catch((error) => dispatch(fetchProjectsFailure(error)));
};

// fetching templates from database
export const fetchTemplates = (projectId) => (dispatch) => {
  dispatch(fetchTemplatesBegin());
  return axios(`/api/v1/offering/project/${projectId}/templates`)
    .then((res) => {
      const templates = res.data;
      dispatch(fetchTemplatesSuccess(templates));
      return templates;
    })
    .catch((error) => dispatch(fetchTemplatesFailure(error)));
};

// fetching modules from datatbase
export const fetchModules = (templateId) => (dispatch) => {
  dispatch(fetchModulesBegin());
  return axios(`/api/v1/offering/template/${templateId}/modules`)
    .then((res) => {
      const modules = res.data;
      dispatch(fetchModulesSuccess(modules));
      return modules;
    })
    .catch((error) => dispatch(fetchModulesFailure(error)));
};

// fetching statements from database
export const fetchStatements = (moduleId) => (dispatch) => {
  dispatch(fetchStatementsBegin());
  return axios(`/api/v1/offering/module/${moduleId}/statements`)
    .then((res) => {
      const statements = res.data;
      dispatch(fetchStatementsSuccess(statements));
      return statements;
    })
    .catch((error) => dispatch(fetchStatementsFailure(error)));
};

export const createOffering = (projectId, history) => (dispatch) => {
  dispatch(createOfferingBegin());
  return axios
    .post('/api/v1/offering/projects', {
      projectId,
    })
    .then((res) => {
      const projects = res.data;
      dispatch(createOfferingSuccess(projects));
      history.push('/manageoffering');
      return projects;
    })
    .catch((error) => dispatch(createOfferingFailure(error)));
};

export const updateOfferingStatementComplaince = (id) => (dispatch) => {
  dispatch(updateOfferingStatementComplainceBegin());
  return axios
    .put(`/api/v1/offering/statements/${id}/compliance`, {})
    .then((res) => {
      dispatch(updateOfferingStatementComplainceSuccess());
      dispatch(fetchStatements(res.data.module));
      return res;
    })
    .catch((error) =>
      dispatch(updateOfferingStatementComplainceFailure(error)));
};

export const updateSuggestion = (id, suggestion) => (dispatch) => {
  dispatch(updateSuggestionBegin());
  return axios
    .put(`/api/v1/offering/statements/${id}/suggestion`, {
      suggestion,
    })
    .then((res) => {
      dispatch(updateSuggestionSuccess());
      dispatch(fetchStatements(res.data.module));
      return res;
    })
    .catch((error) => dispatch(updateSuggestionFailure(error)));
};

export const deleteOffering = (id) => (dispatch) => {
  dispatch(deleteOfferingBegin());
  return axios
    .delete(`/api/v1/offering/${id}`, {})
    .then((res) => {
      dispatch(deleteOfferingSuccess());
      dispatch(fetchProjects());
      return res;
    })
    .catch((error) => dispatch(deleteOfferingFailure(error)));
};

export const publishOffering = (id) => (dispatch) => {
  dispatch(publishOfferingBegin());
  return axios
    .post('/api/v1/negotiation/projects', {
      projectId: id,
    })
    .then((res) => {
      dispatch(publishOfferingSuccess());
      dispatch(fetchProjects());
      return res;
    })
    .catch((error) => dispatch(publishOfferingFailure(error)));
};

export const fetchAssumptions = (projectId) => (dispatch) => {
  dispatch(fetchAssumptionsBegin());
  return axios
    .get(`/api/v1/offering/projects/${projectId}/assumptions`, {})
    .then((res) => {
      const assumptions = res.data;
      dispatch(fetchAssumptionsSuccess(assumptions));
    })
    .catch((error) => dispatch(fetchAssumptionsFailure(error)));
};

export const fetchProvisions = (projectId) => (dispatch) => {
  dispatch(fetchProvisionsBegin());
  return axios
    .get(`/api/v1/offering/projects/${projectId}/provisions`, {})
    .then((res) => {
      const provisions = res.data;
      dispatch(fetchProvisionsSuccess(provisions));
    })
    .catch((error) => dispatch(fetchProvisionsFailure(error)));
};

export const deleteAssumptions = (id, projectId) => (dispatch) => {
  dispatch(deleteAssumptionsBegin());
  return axios
    .delete(`/api/v1/offering/assumptions/${id}`, {})
    .then(() => {
      dispatch(deleteAssumptionsSuccess());
      dispatch(fetchAssumptions(projectId));
    })
    .catch((error) => dispatch(deleteAssumptionsFailure(error)));
};

export const deleteProvisions = (id, projectId) => (dispatch) => {
  dispatch(deleteProvisionsBegin());
  return axios
    .delete(`/api/v1/offering/provisions/${id}`, {})
    .then(() => {
      dispatch(deleteProvisionsSuccess());
      dispatch(fetchProvisions(projectId));
    })
    .catch((error) => dispatch(deleteProvisionsFailure(error)));
};

export const addAssumption = (reference, assumption, projectId) => (
  dispatch,
) => {
  dispatch(addAssumptionBegin());
  return axios
    .post('/api/v1/offering/assumptions', {
      reference,
      assumption,
      projectId,
    })
    .then(() => {
      dispatch(addAssumptionSuccess());
      dispatch(fetchAssumptions(projectId));
    })
    .catch((error) => dispatch(addAssumptionFailure(error)));
};

export const addProvision = (reference, provision, projectId) => (dispatch) => {
  dispatch(addProvisionBegin());
  return axios
    .post('/api/v1/offering/provisions', {
      reference,
      provision,
      projectId,
    })
    .then(() => {
      dispatch(addProvisionSuccess());
      dispatch(fetchProvisions(projectId));
    })
    .catch((error) => dispatch(addProvisionFailure(error)));
};

export const fetchSlas = (projectId) => (dispatch) => {
  dispatch(fetchSlasBegin());
  return axios
    .get(`/api/v1/offering/projects/${projectId}/slas`, {})
    .then((res) => {
      dispatch(fetchSlasSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchSlasFailure(error)));
};

export const updateOfferingSlaCompliance = (id) => (dispatch) => {
  dispatch(updateOfferingSlaComplianceBegin());
  return axios
    .put(`/api/v1/offering/slas/${id}/compliance`, {})
    .then((res) => {
      dispatch(updateOfferingSlaComplianceSuccess());
      dispatch(fetchSlas(res.data.project));
      dispatch(fetchProjects());
      return res;
    })
    .catch((error) => dispatch(updateOfferingSlaComplianceFailure(error)));
};

export const fetchFixedPrices = (projectId) => (dispatch) => {
  dispatch(fetchFixedPricesBegin());
  return axios
    .get(`/api/v1/offering/projects/${projectId}/fixedprices`, {})
    .then((res) => {
      dispatch(fetchFixedPricesSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchFixedPricesFailure(error)));
};

export const fetchPiecePrices = (projectId) => (dispatch) => {
  dispatch(fetchPiecePricesBegin());
  return axios
    .get(`/api/v1/offering/projects/${projectId}/pieceprices`, {})
    .then((res) => {
      dispatch(fetchPiecePricesSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchPiecePricesFailure(error)));
};

export const fetchTAndMs = (projectId) => (dispatch) => {
  dispatch(fetchTAndMsBegin());
  return axios
    .get(`/api/v1/offering/projects/${projectId}/tandms`, {})
    .then((res) => {
      dispatch(fetchTAndMsSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchTAndMsFailure(error)));
};

export const updateTAndM = (id, profile, profileName, days, price) => (
  dispatch,
) => {
  dispatch(updateTAndMBegin());
  return axios
    .put(`/api/v1/offering/tandms/${id}`, {
      profile,
      profileName,
      days,
      price,
    })
    .then((res) => {
      dispatch(updateTAndMSuccess());
      dispatch(fetchTAndMs(res.data.project));
      return res;
    })
    .catch((error) => dispatch(updateTAndMFailure(error)));
};

export const fetchRatecards = (projectId) => (dispatch) => {
  dispatch(fetchRatecardsBegin());
  return axios
    .get(`/api/v1/offering/projects/${projectId}/ratecards`, {})
    .then((res) => {
      dispatch(fetchRatecardsSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchRatecardsFailure(error)));
};

export const updateRatecard = (
  ratecardId,
  profile,
  description,
  dailyrate,
  travelallowance,
  projectId,
) => (dispatch) => {
  dispatch(updateRatecardBegin());
  return axios
    .put(`/api/v1/offering/ratecards/${ratecardId}`, {
      profile,
      description,
      dailyrate,
      travelallowance,
    })
    .then((res) => {
      dispatch(updateRatecardSuccess());
      dispatch(fetchRatecards(projectId));
      dispatch(fetchTAndMs(projectId));
      return res;
    })
    .catch((error) => dispatch(updateRatecardFailure(error)));
};

export const updateSla = (slaId, suggestion) => (dispatch) => {
  dispatch(updateSlaBegin());
  return axios
    .put(`/api/v1/offering/slas/${slaId}`, {
      suggestion,
    })
    .then((res) => {
      dispatch(updateSlaSuccess());
      dispatch(fetchSlas(res.data.project));
      return res;
    })
    .catch((error) => dispatch(updateSlaFailure(error)));
};

export const updatePiecePrice = (piecePriceId, pricePerPiece) => (dispatch) => {
  dispatch(updatePiecePriceBegin());
  return axios
    .put(`/api/v1/offering/pieceprices/${piecePriceId}`, {
      pricePerPiece,
    })
    .then((res) => {
      dispatch(updatePiecePriceSuccess());
      dispatch(fetchPiecePrices(res.data.project));
      return res;
    })
    .catch((error) => dispatch(updatePiecePriceFailure(error)));
};

export const updateFixedPrice = (fixedPriceId, price) => (dispatch) => {
  dispatch(updateFixedPriceBegin());
  return axios
    .put(`/api/v1/offering/fixedprices/${fixedPriceId}`, {
      price,
    })
    .then((res) => {
      dispatch(updateFixedPriceSuccess());
      dispatch(fetchFixedPrices(res.data.project));
      return res;
    })
    .catch((error) => dispatch(updateFixedPriceFailure(error)));
};

export const uploadOfferingProjectFile = (formData, config) => (dispatch) => {
  dispatch(uploadOfferingProjectFileBegin());
  return axios
    .post('/api/v1/upload/offering/', formData, config)
    .then((res) => {
      dispatch(uploadOfferingProjectFileSuccess());
      dispatch(fetchProjects());
      return res;
    })
    .catch((error) => dispatch(uploadOfferingProjectFileFailure(error)));
};

export const removeOfferingProjectUpload = (id) => (dispatch) => {
  dispatch(removeOfferingProjectUploadBegin());
  return axios
    .delete(`/api/v1/upload/offering/project/${id}`)
    .then((res) => {
      dispatch(removeOfferingProjectUploadSuccess());
      dispatch(fetchProjects());
      return res;
    })
    .catch((error) => dispatch(removeOfferingProjectUploadFailure(error)));
};

export const addTAndM = (projectId, moduleId) => (dispatch) => {
  dispatch(addTAndMBegin());
  return axios
    .post('/api/v1/offering/tandms', {
      projectId,
      moduleId,
    })
    .then((res) => {
      dispatch(addTAndMSuccess());
      dispatch(fetchTAndMs(projectId));
      return res;
    })
    .catch((error) => dispatch(addTAndMFailure(error)));
};

export const deleteTAndM = (tandmId) => (dispatch) => {
  dispatch(deleteTAndMBegin());
  return axios
    .delete(`/api/v1/offering/tandms/${tandmId}`, {})
    .then((res) => {
      dispatch(deleteTAndMSuccess());
      dispatch(fetchTAndMs(res.data.project));
      return res;
    })
    .catch((error) => dispatch(deleteTAndMFailure(error)));
};

export const fetchOfferingInformation = (projectId) => (dispatch) => {
  dispatch(fetchOfferingInformationBegin());
  return axios(`/api/v1/offering/projects/${projectId}/information`)
    .then((res) => {
      const information = res.data;
      dispatch(fetchOfferingInformationSuccess(information));
      return information;
    })
    .catch((error) => dispatch(fetchOfferingInformationFailure(error)));
};

export const updateOfferingInformation = (id, answer) => (dispatch) => {
  dispatch(updateOfferingInformationBegin());
  return axios
    .put(`/api/v1/offering/information/${id}`, {
      answer,
    })
    .then((res) => {
      dispatch(updateOfferingInformationSuccess());
      // fetching project information after success
      dispatch(fetchOfferingInformation(res.data.project));
    })
    .catch((error) => dispatch(updateOfferingInformationFailure(error)));
};

export const fetchOfferingEditors = (projectId) => (dispatch) => {
  dispatch(fetchOfferingEditorsBegin());
  return axios(`api/v1/offering/editors/${projectId}`)
    .then((res) => {
      const editors = res.data;
      dispatch(fetchOfferingEditorsSuccess(editors));
      return editors;
    })
    .catch((error) => dispatch(fetchOfferingEditorsFailure(error)));
};

export const fetchOfferingViewers = (projectId) => (dispatch) => {
  dispatch(fetchOfferingViewersBegin());
  return axios(`api/v1/offering/viewers/${projectId}`)
    .then((res) => {
      const viewers = res.data;
      dispatch(fetchOfferingViewersSuccess(viewers));
      return viewers;
    })
    .catch((error) => dispatch(fetchOfferingViewersFailure(error)));
};

export const updateEditors = (projectId, email, option) => (dispatch) => {
  dispatch(updateEditorsBegin());
  return axios
    .put(`/api/v1/offering/editors/${projectId}`, {
      email,
      option,
    })
    .then(() => {
      dispatch(updateEditorsSuccess());
      dispatch(fetchOfferingEditors(projectId));
    })
    .catch((error) => dispatch(updateEditorsFailure(error)));
};

export const updateViewers = (projectId, email, option) => (dispatch) => {
  dispatch(updateViewersBegin());
  return axios
    .put(`/api/v1/offering/viewers/${projectId}`, {
      email,
      option,
    })
    .then(() => {
      dispatch(updateViewersSuccess());
      dispatch(fetchOfferingViewers(projectId));
    })
    .catch((error) => dispatch(updateViewersFailure(error)));
};

export const fetchOfferingReferences = (projectId) => (dispatch) => {
  dispatch(fetchOfferingReferencesBegin());
  return axios
    .post('/graphql', {
      query: `{
          offeringReferences(projectId: "${projectId}") {
            _id,
            number,
            question,
            answer,
            company,
            project {
              _id
            },
          }
        }`,
    })
    .then((res) => {
      const references = res.data.data.offeringReferences;
      dispatch(fetchOfferingReferencesSuccess(references));
      return references;
    })
    .catch((error) => dispatch(fetchOfferingReferencesFailure(error)));
};

export const addOfferingReferenceCompany = (projectId, company) => (
  dispatch,
) => {
  dispatch(addOfferingReferenceCompanyBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation{
            addReferenceCompany(projectId: "${projectId}", company: "${company}") {
              _id,
              number,
              question,
              answer,
              company,
              project {
                _id
              },
            }
          }`,
    })
    .then((res) => {
      const references = res.data.data.addReferenceCompany;
      dispatch(addOfferingReferenceCompanySuccess(references));
      return references;
    })
    .catch((error) => dispatch(addOfferingReferenceCompanyFailure(error)));
};

export const deleteOfferingReferenceCompany = (projectId, company) => (
  dispatch,
) => {
  dispatch(deleteOfferingReferenceCompanyBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation{
            removeReferenceCompany(projectId: "${projectId}", company: "${company}") {
              _id,
              number,
              question,
              answer,
              company,
              project {
                _id
              },
            }
          }`,
    })
    .then((res) => {
      const references = res.data.data.removeReferenceCompany;
      dispatch(deleteOfferingReferenceCompanySuccess(references));
      return references;
    })
    .catch((error) => dispatch(deleteOfferingReferenceCompanyFailure(error)));
};

export const updateOfferingReferenceCompany = (
  projectId,
  company,
  newCompany,
) => (dispatch) => {
  dispatch(updateOfferingReferenceCompanyBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation{
            updateReferenceCompany(projectId: "${projectId}", company: "${company}", newCompany: "${newCompany}") {
              _id,
              number,
              question,
              answer,
              company,
              project {
                _id
              },
            }
          }`,
    })
    .then((res) => {
      const references = res.data.data.updateReferenceCompany;
      dispatch(updateOfferingReferenceCompanySuccess(references));
      return references;
    })
    .catch((error) => dispatch(updateOfferingReferenceCompanyFailure(error)));
};

export const updateOfferingReference = (projectId, answer) => (dispatch) => {
  dispatch(updateOfferingReferenceBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation{
            updateOfferingReference(_id: "${projectId}", answer: "${answer}") {
              _id,
              number,
              question,
              answer,
              company,
              project {
                _id
              },
            }
          }`,
    })
    .then((res) => {
      const reference = res.data.data.updateOfferingReference;
      dispatch(updateOfferingReferenceSuccess(reference));
      return reference;
    })
    .catch((error) => dispatch(updateOfferingReferenceFailure(error)));
};

export const fetchOfferingSubcontractors = (projectId) => (dispatch) => {
  dispatch(fetchOfferingSubcontractorsBegin());
  return axios
    .post('/graphql', {
      query: `{
          offeringSubcontractors(projectId: "${projectId}") {
            _id,
            number,
            question,
            answer,
            company,
            project {
              _id
            },
          }
        }`,
    })
    .then((res) => {
      const subcontractors = res.data.data.offeringSubcontractors;
      dispatch(fetchOfferingSubcontractorsSuccess(subcontractors));
      return subcontractors;
    })
    .catch((error) => dispatch(fetchOfferingSubcontractorsFailure(error)));
};

export const addOfferingSubcontractorCompany = (projectId, company) => (
  dispatch,
) => {
  dispatch(addOfferingSubcontractorCompanyBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation{
            addSubcontractorCompany(projectId: "${projectId}", company: "${company}") {
              _id,
              number,
              question,
              answer,
              company,
              project {
                _id
              },
            }
          }`,
    })
    .then((res) => {
      const subcontractors = res.data.data.addSubcontractorCompany;
      dispatch(addOfferingSubcontractorCompanySuccess(subcontractors));
      return subcontractors;
    })
    .catch((error) => dispatch(addOfferingSubcontractorCompanyFailure(error)));
};

export const deleteOfferingSubcontractorCompany = (projectId, company) => (
  dispatch,
) => {
  dispatch(deleteOfferingSubcontractorCompanyBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation{
            removeSubcontractorCompany(projectId: "${projectId}", company: "${company}") {
              _id,
              number,
              question,
              answer,
              company,
              project {
                _id
              },
            }
          }`,
    })
    .then((res) => {
      const subcontractors = res.data.data.removeSubcontractorCompany;
      dispatch(deleteOfferingSubcontractorCompanySuccess(subcontractors));
      return subcontractors;
    })
    .catch((error) =>
      dispatch(deleteOfferingSubcontractorCompanyFailure(error)));
};

export const updateOfferingSubcontractorCompany = (
  projectId,
  company,
  newCompany,
) => (dispatch) => {
  dispatch(updateOfferingSubcontractorCompanyBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation{
            updateSubcontractorCompany(projectId: "${projectId}", company: "${company}", newCompany: "${newCompany}") {
              _id,
              number,
              question,
              answer,
              company,
              project {
                _id
              },
            }
          }`,
    })
    .then((res) => {
      const subcontractors = res.data.data.updateSubcontractorCompany;
      dispatch(updateOfferingSubcontractorCompanySuccess(subcontractors));
      return subcontractors;
    })
    .catch((error) =>
      dispatch(updateOfferingSubcontractorCompanyFailure(error)));
};

export const updateOfferingSubcontractor = (projectId, answer) => (
  dispatch,
) => {
  dispatch(updateOfferingSubcontractorBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation{
            updateOfferingSubcontractor(_id: "${projectId}", answer: "${answer}") {
              _id,
              number,
              question,
              answer,
              company,
              project {
                _id
              },
            }
          }`,
    })
    .then((res) => {
      const subcontractor = res.data.data.updateOfferingSubcontractor;
      dispatch(updateOfferingSubcontractorSuccess(subcontractor));
      return subcontractor;
    })
    .catch((error) => dispatch(updateOfferingSubcontractorFailure(error)));
};

export const fetchAdditionalFilesIsShowing = (projectId) => (dispatch) => {
  dispatch(fetchAdditionalFilesIsShowingBegin());
  return axios(`/api/v1/offering/projects/${projectId}/additionalfiles`)
    .then((res) => {
      const isShowing = res.data;
      dispatch(fetchAdditionalFilesIsShowingSuccess(isShowing));
    })
    .catch((error) => {
      dispatch(fetchAdditionalFilesIsShowingFailure(error));
    });
};

export const uploadOfferingInformationFile = (formData, config) => (
  dispatch,
) => {
  dispatch(uploadOfferingInformationFileBegin());
  return axios
    .post('/api/v1/upload/offering/', formData, config)
    .then((res) => {
      dispatch(uploadOfferingInformationFileSuccess());
      dispatch(fetchOfferingInformation(res.data.project));
      return res;
    })
    .catch((error) => dispatch(uploadOfferingInformationFileFailure(error)));
};

export const removeOfferingInformationUpload = (id) => (dispatch) => {
  dispatch(removeOfferingInformationUploadBegin());
  return axios
    .delete(`/api/v1/upload/offering/information/${id}`)
    .then((res) => {
      dispatch(removeOfferingInformationUploadSuccess());
      dispatch(fetchOfferingInformation(res.data.project));
      return res;
    })
    .catch((error) => dispatch(removeOfferingInformationUploadFailure(error)));
};
