import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  selectProject,
  selectTemplate,
  selectModule,
  selectSla,
  selectRatecard,
  selectTAndM,
  selectFixedPrice,
  selectPiecePrice,
  selectInformation,
  selectReference,
  selectViewOfferingReferenceCompany,
  selectSubcontractor,
  selectViewOfferingSubcontractorCompany,
  selectProjectOverview,
} from '../../redux/actionCreators/viewofferingAction';
import ViewOfferingPage from '../../components/ViewOffering/ViewOfferingPage';
import CommentsContainer from '../Comment/CommentsContainer';

function ViewOfferingContainer(props) {
  const {
    history,
    selectedProjectOverview,
    selectedProject,
    selectedProjectOrigin,
    selectedTemplate,
    selectedModule,
    selectedSla,
    selectedRatecard,
    selectedTAndM,
    selectedFixedPrice,
    selectedPiecePrice,
    selectedInformation,
    selectedReference,
    selectedReferenceCompany,
    selectedSubcontractor,
    selectedSubcontractorCompany,
    user,
    selectProject,
    selectTemplate,
    selectModule,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectViewOfferingReferenceCompany,
    selectSubcontractor,
    selectViewOfferingSubcontractorCompany,
    selectProjectOverview,
  } = props;
  useEffect(() => {
    if (!(user.role === 'AG' || user.role === 'ITAG')) {
      history.push('/main');
    }
    return () => {
      selectProject('');
      selectTemplate('');
      selectModule('');
      selectSla('');
      selectRatecard('');
      selectTAndM('');
      selectFixedPrice('');
      selectPiecePrice('');
      selectInformation('');
      selectReference('');
      selectViewOfferingReferenceCompany('');
      selectSubcontractor('');
      selectViewOfferingSubcontractorCompany('');
      selectProjectOverview('');
    };
  }, [
    user,
    history,
    selectProject,
    selectTemplate,
    selectModule,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectViewOfferingReferenceCompany,
    selectSubcontractor,
    selectViewOfferingSubcontractorCompany,
    selectProjectOverview,
  ]);

  return (
    <>
      <ViewOfferingPage
        selectedProjectOverview={selectedProjectOverview}
        selectedProject={selectedProject}
        selectedProjectOrigin={selectedProjectOrigin}
        selectedTemplate={selectedTemplate}
        selectedModule={selectedModule}
        selectedSla={selectedSla}
        selectedRatecard={selectedRatecard}
        selectedTAndM={selectedTAndM}
        selectedFixedPrice={selectedFixedPrice}
        selectedPiecePrice={selectedPiecePrice}
        selectedInformation={selectedInformation}
        selectedReference={selectedReference}
        selectedReferenceCompany={selectedReferenceCompany}
        selectedSubcontractor={selectedSubcontractor}
        selectedSubcontractorCompany={selectedSubcontractorCompany}
      />
      {selectedProject ? (
        <CommentsContainer selectedProject={selectedProject} />
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  selectedProjectOverview: state.viewoffering.selectedProjectOverview,
  selectedProject: state.viewoffering.selectedProject,
  selectedProjectOrigin: state.project.selectedProject,
  selectedTemplate: state.viewoffering.selectedTemplate,
  selectedModule: state.viewoffering.selectedModule,
  selectedSla: state.viewoffering.selectedSla,
  selectedRatecard: state.viewoffering.selectedRatecard,
  selectedTAndM: state.viewoffering.selectedTAndM,
  selectedFixedPrice: state.viewoffering.selectedFixedPrice,
  selectedPiecePrice: state.viewoffering.selectedPiecePrice,
  selectedInformation: state.viewoffering.selectedInformation,
  selectedReference: state.viewoffering.selectedReference,
  selectedReferenceCompany: state.viewoffering.selectedReferenceCompany,
  selectedSubcontractor: state.viewoffering.selectedSubcontractor,
  selectedSubcontractorCompany: state.viewoffering.selectedSubcontractorCompany,
  loading: state.viewoffering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectModule: (id) => dispatch(selectModule(id)),
  selectSla: (id) => dispatch(selectSla(id)),
  selectRatecard: (id) => dispatch(selectRatecard(id)),
  selectTAndM: (id) => dispatch(selectTAndM(id)),
  selectFixedPrice: (id) => dispatch(selectFixedPrice(id)),
  selectPiecePrice: (id) => dispatch(selectPiecePrice(id)),
  selectInformation: (id) => dispatch(selectInformation(id)),
  selectReference: (id) => dispatch(selectReference(id)),
  selectViewOfferingReferenceCompany: (id) =>
    dispatch(selectViewOfferingReferenceCompany(id)),
  selectSubcontractor: (id) => dispatch(selectSubcontractor(id)),
  selectViewOfferingSubcontractorCompany: (id) =>
    dispatch(selectViewOfferingSubcontractorCompany(id)),
  selectProjectOverview: (id) => dispatch(selectProjectOverview(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewOfferingContainer),
);

ViewOfferingContainer.propTypes = {
  history: PropTypes.shape(Object).isRequired,
  selectedProjectOverview: PropTypes.string.isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectedProjectOrigin: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  selectedModule: PropTypes.string.isRequired,
  selectedSla: PropTypes.string.isRequired,
  selectedRatecard: PropTypes.string.isRequired,
  selectedTAndM: PropTypes.string.isRequired,
  selectedFixedPrice: PropTypes.string.isRequired,
  selectedPiecePrice: PropTypes.string.isRequired,
  selectedInformation: PropTypes.string.isRequired,
  selectedReference: PropTypes.string.isRequired,
  selectedReferenceCompany: PropTypes.string.isRequired,
  selectedSubcontractor: PropTypes.string.isRequired,
  selectedSubcontractorCompany: PropTypes.string.isRequired,
  user: PropTypes.shape(Object).isRequired,
  selectProject: PropTypes.func,
  selectTemplate: PropTypes.func,
  selectModule: PropTypes.func,
  selectSla: PropTypes.func,
  selectRatecard: PropTypes.func,
  selectTAndM: PropTypes.func,
  selectFixedPrice: PropTypes.func,
  selectPiecePrice: PropTypes.func,
  selectInformation: PropTypes.func,
  selectReference: PropTypes.func,
  selectViewOfferingReferenceCompany: PropTypes.func,
  selectSubcontractor: PropTypes.func,
  selectViewOfferingSubcontractorCompany: PropTypes.func,
  selectProjectOverview: PropTypes.func,
};
