import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { green, grey } from '@material-ui/core/colors';

import TableAddIconButton from '../Buttons/TableAddIconButton';

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: grey[300],
  },
  submitButton: {
    color: green[300],
  },
}));

export default function AddRatecardDialog(props) {
  const { projectId, header, onClick } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [profile, setProfile] = useState('');
  const [description, setDescription] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onClick({ variables: { projectId, profile, description } });
    setProfile('');
    setDescription('');
    setOpen(false);
  };

  return (
    <div>
      <TableAddIconButton onClick={handleClickOpen} />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <TextField
              id="profile"
              label="Profilname"
              fullWidth
              margin="normal"
              value={profile}
              onChange={(e) => setProfile(e.target.value)}
            />
            <TextField
              id="description"
              label="Profilbeschreibung"
              fullWidth
              multiline
              rows={4}
              margin="normal"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={handleClose}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            className={classes.submitButton}
            onClick={handleSubmit}
            color="primary"
            autoFocus
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AddRatecardDialog.propTypes = {
  onClick: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  projectId: PropTypes.string.isRequired,
};
