import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core/';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import { green, grey } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: grey[300],
  },
  submitButton: {
    color: green[300],
  },
  button: {
    textTransform: 'none',
  },
}));

export default function PublishProjectDialog(props) {
  const { id, onClick, type, published } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedTime, setSelectedTime] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleTimeChange = (time) => {
    setSelectedTime(time);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    selectedDate.setHours(selectedTime.getHours());
    selectedDate.setMinutes(selectedTime.getMinutes());
    if (type === 'project') {
      onClick({ variables: { projectId: id, deadline: selectedDate } });
    } else {
      onClick({ variables: { projectId: id } });
    }
    setOpen(false);
  };
  let buttonText = 'Veröffentlichen';
  if (type === 'project' && published) buttonText = 'Deadline verschieben';
  if (type !== 'project') buttonText = 'Einreichen';
  return (
    <div>
      <Button
        size="small"
        variant="outlined"
        className={classes.button}
        onClick={handleClickOpen}
      >
        {buttonText}
      </Button>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {type === 'project' ? (
          <DialogTitle id="alert-dialog-title">
            Projekt veröffentlichen
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title">Angebot einreichen</DialogTitle>
        )}
        <DialogContent>
          <Grid container>
            <Grid>
              {type === 'project' ? (
                <Typography variant="body2">
                  Haben Sie alle wichtigen Informationen des Projekts
                  vollständig eingetragen und die potentiellen Anbieter
                  hinzugefügt?
                </Typography>
              ) : (
                <Typography variant="body2">
                  Haben Sie alle wichtigen Informationen für Ihr Angebot
                  vollständig eingetragen?
                </Typography>
              )}
            </Grid>
            {type === 'project' && (
              <Grid>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    disableToolbar
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    id="date-picker-inline"
                    label="Tag auswählen"
                    value={selectedDate}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                  />
                  <KeyboardTimePicker
                    ampm={false}
                    margin="normal"
                    id="time-picker"
                    label="Uhrzeit auswählen"
                    value={selectedTime}
                    onChange={handleTimeChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={handleClose}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            className={classes.submitButton}
            onClick={handleSubmit}
            color="primary"
            autoFocus
          >
            {buttonText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PublishProjectDialog.propTypes = {
  id: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  published: PropTypes.bool,
};
