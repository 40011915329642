import React from 'react';
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Link, ListItem, ListItemIcon, List, ListItemText } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
// import { Formik, Form, Field } from 'formik';
// import * as Yup from 'yup';
import {
//  TextField,
} from 'formik-material-ui';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// import axios from 'axios';
import LogoImage from '../Elements/logo.png';
import RingeIcon from '../Elements/icon_ringe.png';
import BriefIcon from '../Elements/icon_brief.png';
import GraphIcon from '../Elements/icon_graph.png';
import HandshakeIcon from '../Elements/icon_handshake.png';
import KreiseIcon from '../Elements/icon_kreise.png';
import LupeGrafikIcon from '../Elements/icon_lupe_grafik.png';
import MenschPunkteIcon from '../Elements/icon_mensch_punkte.png';
import VertragIcon from '../Elements/icon_vertrag.png';
import inditangoImage from '../Elements/logo_peloIT.png';

// const contactUrl = process.env.REACT_APP_AZ_FUNC;
const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 50,
    height: '100vh',
    background: '#fafafa',
  },
  image: {
    marginTop: 20,
    marginBottom: 20,
    maxWidth: 1000,
  },
  sectionOne: {
    padding: 20,
    minHeight: '100%',
    display: 'flex',
    flexFlow: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    background: '#fff',
  },
  sectionIcons: {
    background: '#fff',
    padding: 20,
    maxWidth: 1200,
    '& div': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
    },
    '& img': {
      maxHeight: '80px',
      marginBottom: 20,
    },
    '& p': {
      textAlign: 'center',
    },
  },
  sectionTwo: {
    background: '#006da2',
    minHeight: '30%',
    display: 'flex',
    alignContent: 'space-around',
    justifyContent: 'space-evenly',
  },
  sectionTwoContainer: {
    maxWidth: 1200,
    padding: 20,
    display: 'flex',
    alignContent: 'space-around',
    justifyContent: 'space-evenly',
    '& li': {
      padding: 0,
    },
    '& .MuiListItemIcon-root': {
      minWidth: 30,
    },
  },
  sectionPricing: {
    background: 'white',
    minHeight: '60%',
    display: 'flex',
    alignContent: 'space-around',
    justifyContent: 'space-evenly',
  },
  sectionPricingContainer: {
    maxWidth: 1200,
    padding: 20,
    display: 'flex',
    alignContent: 'space-around',
    justifyContent: 'space-evenly',
  },
  itagLogo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
    paddingBottom: 15,
    '& img': {
      display: 'block',
      maxWidht: 300,
      maxHeight: 40,
      width: 'auto',
      height: 'auto',
      marginRight: 20,
    },

  },
  sectionThree: {
    minHeight: '30%',
    display: 'flex',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#fff',
    padding: 20,
    '& input': {
      minWidth: 300,
    },
    '& form': {
      display: 'flex',
      alignItems: 'baseline',
    },
  },
  sectionFour: {
    background: '#006da2',
    minHeight: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pPadding: {
    paddingBottom: 10,
  },
  sectionFourContainer: {
    maxWidth: 1200,
    margin: 'auto',
    padding: 32,
  },
  priceContainer: {
    minHeight: 200,
  },

}));

function HomePage() {
  // const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const classes = useStyles();
  const matches375 = useMediaQuery('(min-width:375px)');
  ReactGA.pageview('home');
  return (
    <div className={classes.root}>
      <Grid container className={classes.sectionOne}>
        <Grid item xs={12} style={{ paddingBottom: '20px' }}>

          {matches375 && (
            <Typography variant="h1" align="center">
              <img src={LogoImage} alt="althigo" width="200px" className={classes.image} />
            </Typography>
          )}
          {matches375 && (
            <Typography variant="h5" align="center">
              IT-Ausschreibungen – Mit Struktur und Inhalten.
            </Typography>
          )}

          {!matches375 && (
            <Typography variant="h2" align="center">
              <img
                src={LogoImage}
                alt="Sourcing-Pilot"
                width="100px"
                className={classes.image}
              />
            </Typography>
          )}
          {!matches375 && (
            <Typography variant="h6" align="center">
              IT-Ausschreibungen. Mit Struktur und Inhalten.
            </Typography>
          )}

        </Grid>
        <Grid container className={classes.sectionIcons} spacing={3}>
          <Grid item xs={6} md={3}>
            <img src={HandshakeIcon} alt="Verhandlung" />
            <Typography variant="body1">Strukturierte und fokussierte Verhandlungen</Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={VertragIcon} alt="Inhalte" />
            <Typography variant="body1">Vorhandene Inhalte für diverse    IT-Ausschreibungen</Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={GraphIcon} alt="Auswertung" />
            <Typography variant="body1">Inhaltliche und kommerzielle Auswertung auf Knopfdruck</Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={LupeGrafikIcon} alt="Vergleichbarkeit" />
            <Typography variant="body1">Vergleichbare Angebote durch Vorgabe der Verträge</Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={KreiseIcon} alt="Modularer Aufbau" />
            <Typography variant="body1">Modularer Aufbau für effiziente Ausschreibungen</Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={BriefIcon} alt="Bieterfragen" />
            <Typography variant="body1">Beantwortung von Bieterfragen in Echtzeit</Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={RingeIcon} alt="Struktur" />
            <Typography variant="body1">Erprobte und eindeutige Struktur von Ausschreibungen</Typography>
          </Grid>
          <Grid item xs={6} md={3}>
            <img src={MenschPunkteIcon} alt="Kollaboration" />
            <Typography variant="body1">Einfache Kollaboration in der Erstellung</Typography>
          </Grid>
        </Grid>
      </Grid>
      <div className={classes.sectionTwo}>
        <Grid container className={classes.sectionTwoContainer} spacing={0} style={{ alignItems: 'center' }}>
          <Grid item xs={12} md={4} style={{ paddingRight: 40, paddingLeft: 12 }}>
            <Typography variant="h6" color="primary">Sourcing-Pilot unterstützt Ihre IT-Ausschreibung von der Erstellung der Anforderungen bis zur Verhandlung von Leistungen und Preisen.</Typography>
          </Grid>
          <Grid item xs={5} md={2}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Typography variant="h6" color="primary" style={{ textAlign: 'center' }}>1.</Typography>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: 'primary', variant: 'body2' }} primary="Erstellung der Anforderungen in vertragstauglicher Form" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={2} md={1} style={{ textAlign: 'center' }}><ArrowForwardIos color="primary" /></Grid>
          <Grid item xs={5} md={2}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Typography variant="h6" color="primary" style={{ textAlign: 'center' }}>2.</Typography>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: 'primary', variant: 'body2' }} primary="Angebote der Anbieter mit fachlichen Dissensen, Preisen und weiteren Informationen" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={2} md={1} style={{ textAlign: 'center' }}>
            <ArrowForwardIos color="primary" />
          </Grid>
          <Grid item xs={10} md={2}>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Typography variant="h6" color="primary" style={{ textAlign: 'center' }}>3.</Typography>
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{ color: 'primary', variant: 'body2' }} primary="Fokus der Verhandlungen auf Dissense und Preise" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div>
      {/*
      <div className={classes.sectionPricing}>
        <Grid container className={classes.sectionPricingContainer} spacing={3}>
          <Grid item xs={12} sm={12} md={4} className={classes.priceContainer}>
            <Divider style={{
              backgroundColor: '#00AAAA',
              height: 4
            }} />
            <Typography variant="h6">Nutzung für eine Ausschreibung</Typography>
            <Typography variant="body2">inkludiert:</Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ListItemText primary="Unbegrenzte Anzahl Benutzer und Anbieter" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ListItemText primary="Standard-Leistungsbeschreibungen für den Projektkontext" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Euro />
                </ListItemIcon>
                <ListItemText primary="8.000 € pro Ausschreibung" />
              </ListItem>
            </List>
          </Grid>

          <Grid item xs={12} sm={12} md={4} className={classes.priceContainer}>
            <Divider style={{
              backgroundColor: '#C232BC',
              height: 4
            }} />
            <Typography variant="h6">Flatrate</Typography>
            <Typography variant="body2">zusätzlich:</Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ListItemText primary="10 Ausschreibungen pro Jahr inkludiert" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ListItemText primary="Zugriff auf alle vorhandenen Leistungsbeschreibungen" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ListItemText primary="Erstellung eigener Templates für die Verwendung in Ausschreibungen" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Euro />
                </ListItemIcon>
                <ListItemText primary="auf Anfrage" />
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} sm={12} md={4} className={classes.priceContainer}>
            <Divider style={{
              backgroundColor: '#2829A8',
              height: 4
            }} />
            <Typography variant="h6">Enterprise</Typography>
            <Typography variant="body2">zusätzlich:</Typography>
            <List>
              <ListItem>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ListItemText primary="unbegrenzte Anzahl Ausschreibungen inkludiert" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ListItemText primary="Methodenschulungen durch inditango" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ListItemText primary="Standard Rahmenvertrag für IT-Services von LUTZ | ABEL" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Check />
                </ListItemIcon>
                <ListItemText primary="Kontingent für Rechtsberatung durch LUTZ | ABEL" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Euro />
                </ListItemIcon>
                <ListItemText primary="auf Anfrage" />
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </div> */}

      <Grid container className={classes.sectionThree}>
        <Grid item xs={12}>
          <Typography variant="body1">
            Gerne können Sie sich bei Interesse am Sourcing-Pilot an die folgende E-Mail-Adresse wenden: info@pelo-it.com. Wir werden uns umgehend bei Ihnen melden.
          </Typography>

        </Grid>
      </Grid>
      <div className={classes.sectionFour}>
        <Grid container className={classes.sectionFourContainer}>
          <Grid item xs={12} className={classes.itagLogo}>
            <div>
              <Link color="inherit" href="https://www.pelo-it.com/it_sourcing/" target="_blank" rel="noopener">
                <img src={inditangoImage} alt="althigo" style={{ maxHeight: '50px' }} />
              </Link>
            </div>
          </Grid>
          <Grid item xs={12} sm={8} md={6}>
            <Typography variant="h6" color="primary" className={classes.pPadding}>Beratung auf Augenhöhe.</Typography>
            <Typography variant="body2" color="primary" className={classes.pPadding} />
            <Typography variant="body2" color="primary" className={classes.pPadding}>
              Sourcing-Pilot ist eine Lösung der pelo IT GmbH und basiert auf jahrelanger Erfahrung in IT-Ausschreibungen.
            </Typography>
            <Typography variant="body2" color="primary" style={{ whiteSpace: 'pre-line' }} className={classes.pPadding}>
              Als IT-Sourcing Experten beraten wir unsere Kunden zu allen relevanten Themen und Fragestellungen des Sourcings.
              Wir unterstützen von der Sourcing-Strategie und der Analyse der Wirtschaftlichkeit von Sourcing-Vorhaben über
              die Begleitung von konkreten Ausschreibungen bis hin zur Integration und Steuerung der Dienstleister.
            </Typography>

          </Grid>
          <Grid item xs={12} sm={1} md={4} />
          <Grid item xs={12} sm={3} md={2}>
            <Typography variant="h6" color="primary" className={classes.pPadding}>Kontakt</Typography>
            <Typography variant="body2" color="primary" className={classes.pPadding} />
            <Typography variant="body2" color="primary">pelo IT GmbH</Typography>
            <Typography variant="body2" color="primary">Bahnhofstraße 1</Typography>
            <Typography variant="body2" color="primary" className={classes.pPadding}>D-30159 Hannover</Typography>
            <Typography variant="body2" color="primary">+49 511 / 8449 77 30</Typography>
            <Typography variant="body2" color="primary">info@sourcing-pilot.com</Typography>

          </Grid>
          <Grid container>
            <Grid item xs={12} sm={8} md={6}>
              <Typography variant="body2" color="primary">
                ©
                {' '}
                {` ${new Date().getFullYear()} `}
                <Link color="inherit" href="https://www.pelo-it.com/it_sourcing" target="_blank" rel="noopener">
                  pelo IT GmbH
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={1} md={4} />

            <Grid item xs={12} sm={3} md={2}>
              <Typography variant="body2" color="primary">
                <Link color="inherit" href="/imprint">
                  Impressum
                </Link>
                {' . '}
                <Link color="inherit" href="/privacy">
                  Datenschutz
                </Link>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default HomePage;
