import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { useQuery,
  // useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import {
  // fetchFixedPrices,
  selectFixedPrice,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/databaseAction';
import DatabaseFixedPricesTable from '../../components/DatabaseManagement/DatabaseFixedPricesTable';
import Loading from '../../components/Elements/Loading';
// GraphQL
import { GET_PRICES, GET_PROJECT } from '../../graphql/queries';

function DatabaseFixedPricesContainer(props) {
  const {
    // fixedPrices,
    // projects,
    templates,
    // fetchFixedPrices,
    selectFixedPrice,
    selectProject,
    selectTemplate,
    selectedProject,
    // loading,
  } = props;

  const priceType = 'Festpreis';
  const { loading, error, data } = useQuery(GET_PRICES, {
    variables: { projectId: selectedProject, type: priceType },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }

  return (
    <DatabaseFixedPricesTable
      fixedPrices={data.pricesByType}
      projects={data1.project}
      templates={templates}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectFixedPrice={selectFixedPrice}
      loading={loading}
    />
  );
}

const mapStateToProps = (state) => ({
  fixedPrices: state.database.fixedPrices,
  projects: state.database.projects,
  templates: state.database.templates,
  selectedProject: state.database.selectedProject,
  loading: state.database.loading,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchFixedPrices: (id) => dispatch(fetchFixedPrices(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectFixedPrice: (id) => dispatch(selectFixedPrice(id)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DatabaseFixedPricesContainer),
);

DatabaseFixedPricesContainer.propTypes = {
  // fetchFixedPrices: PropTypes.func.isRequired,
  selectFixedPrice: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // fixedPrices: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
};
