import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import AddDialog from '../Dialogs/AddDialog';
import EditDialog from '../Dialogs/EditDialog';
import DeleteDialog from '../Dialogs/DeleteDialog';
import TableUpButton from '../Buttons/TableUpButton';
import TableDownButton from '../Buttons/TableDownButton';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function DatabaseSubcontractorsTable(props) {
  const {
    subcontractors,
    projects,
    addDatabaseSubcontractor,
    updateDatabaseSubcontractor,
    updateInformationNumber,
    deleteDatabaseSubcontractor,
    selectSubcontractor,
    selectProject,
    selectedProject,
    selectTemplate,
    type,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'number',
      label: '#',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { width: '50px' } }),
        customBodyRender: (value, tableMeta) => (
          <div>
            {value !== 1 && (
              <TableUpButton
                onClick={() => updateInformationNumber({ variables: { projectId: projects._id, informationId: tableMeta.rowData[0], newNumber: tableMeta.rowData[1] - 1 } })}
              />
            )}
            {value}
            {value
              !== subcontractors.length && (
                <TableDownButton
                  onClick={() => updateInformationNumber({ variables: { projectId: projects._id, informationId: tableMeta.rowData[0], newNumber: tableMeta.rowData[1] + 1 } })}
                />
            )}
          </div>
        ),
      },
    },
    {
      name: 'question',
      label: 'Frage',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'answer',
      label: 'Antwort',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <div>
            <EditDialog
              informationId={tableMeta.rowData[0]}
              projectId={projects._id}
              oldData={tableMeta.rowData[2]}
              header="Subkontraktorfrage bearbeiten"
              text="Frage"
              field="question"
              onClick={updateDatabaseSubcontractor}
            />
            <DeleteDialog
              header="Subkontraktorfrage löschen"
              text="Möchten Sie die Subkontraktorfrage wirklich löschen?"
              onClick={() => deleteDatabaseSubcontractor({
                variables: { projectId: projects._id, informationId: tableMeta.rowData[0] },
              })}
            />
          </div>
        ),
      },
    },
  ];

  const data = [];

  subcontractors.forEach((Subcontractor) => {
    data.push({
      _id: Subcontractor._id,
      number: Subcontractor.number,
      question: Subcontractor.question,
      answer: 'Wird vom Anbieter beantwortet.',
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectSubcontractor('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/database"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectSubcontractor('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/database"
                onClick={() => selectSubcontractor('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Subkontraktoren</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions()}
      />
      <AddDialog
        projectId={selectedProject}
        type={type}
        field="question"
        header="Subkontraktorfrage hinzufügen"
        text="Frage"
        onClick={addDatabaseSubcontractor}
      />
    </div>
  );
}

export default DatabaseSubcontractorsTable;

DatabaseSubcontractorsTable.propTypes = {
  subcontractors: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
  addDatabaseSubcontractor: PropTypes.func.isRequired,
  updateDatabaseSubcontractor: PropTypes.func.isRequired,
  updateInformationNumber: PropTypes.func.isRequired,
  deleteDatabaseSubcontractor: PropTypes.func.isRequired,
  selectSubcontractor: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};
