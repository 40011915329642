import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  button: {
    marginLeft: '-0.6em',
  },
}));

function TableDownButton(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <IconButton size="small" className={classes.button} onClick={onClick}>
      <KeyboardArrowDown />
    </IconButton>
  );
}

export default TableDownButton;

TableDownButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
