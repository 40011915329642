import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_INFORMATIONS, GET_PROJECT } from '../../graphql/queries';
import {
  ADD_INFORMATION,
  UPDATE_INFORMATION,
  DELETE_INFORMATION,
  UPDATE_INFORMATION_NUMBER,
} from '../../graphql/mutations';
import {
  // fetchDatabaseInformation,
  // addDatabaseInformation,
  // updateDatabaseInformation,
  // deleteDatabaseInformation,
  selectInformation,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/databaseAction';
import DatabaseInformationTable from '../../components/DatabaseManagement/DatabaseInformationTable';
import Loading from '../../components/Elements/Loading';

function DatabaseInformationContainer(props) {
  const {
    // information,
    // projects,
    // fetchDatabaseInformation,
    // addDatabaseInformation,
    // updateDatabaseInformation,
    // deleteDatabaseInformation,
    selectInformation,
    selectProject,
    selectTemplate,
    selectedProject,
    // loading,
  } = props;

  const informationType = 'Information';
  const cluster = '';
  const { loading, error, data } = useQuery(GET_INFORMATIONS, {
    variables: { projectId: selectedProject, type: informationType, cluster },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [addInformation] = useMutation(ADD_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [updateInformation] = useMutation(UPDATE_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [deleteInformation] = useMutation(DELETE_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [updateInformationNumber] = useMutation(UPDATE_INFORMATION_NUMBER, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }

  return (
    <DatabaseInformationTable
      information={data.informations}
      projects={data1.project}
      addDatabaseInformation={addInformation}
      updateDatabaseInformation={updateInformation}
      deleteDatabaseInformation={deleteInformation}
      updateInformationNumber={updateInformationNumber}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectInformation={selectInformation}
      loading={loading}
      type={informationType}
      cluster={cluster}
    />
  );
}

const mapStateToProps = (state) => ({
  information: state.database.information,
  projects: state.database.projects,
  selectedProject: state.database.selectedProject,
  loading: state.database.loading,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchDatabaseInformation: (projectId) =>
  //   dispatch(fetchDatabaseInformation(projectId)),
  // addDatabaseInformation: (projectId, question) =>
  //   dispatch(addDatabaseInformation(projectId, question)),
  // updateDatabaseInformation: (id, question) =>
  //   dispatch(updateDatabaseInformation(id, question)),
  // deleteDatabaseInformation: (id) => dispatch(deleteDatabaseInformation(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectInformation: (id) => dispatch(selectInformation(id)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DatabaseInformationContainer),
);

DatabaseInformationContainer.propTypes = {
  // fetchDatabaseInformation: PropTypes.func.isRequired,
  // addDatabaseInformation: PropTypes.func.isRequired,
  // updateDatabaseInformation: PropTypes.func.isRequired,
  // deleteDatabaseInformation: PropTypes.func.isRequired,
  selectInformation: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // information: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
};
