import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_RATECARDS, GET_PROJECT } from '../../graphql/queries';
import {
  UPDATE_RATECARD,
} from '../../graphql/mutations';

import {
  // fetchRatecards,
  // updateRatecard,
  selectRatecard,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/offeringAction';
import OfferingRatecardTable from '../../components/OfferingManagement/OfferingRatecardTable';
import Loading from '../../components/Elements/Loading';

function OfferingRatecardContainer(props) {
  const {
    // ratecards,
    // projects,
    // fetchRatecards,
    // updateRatecard,
    selectRatecard,
    selectProject,
    selectTemplate,
    selectedProject,
    // loading,
    // user,
  } = props;
  // useEffect(() => {
  //   fetchRatecards(selectedProject);
  // }, [fetchRatecards, selectedProject]);

  const { loading, error, data } = useQuery(GET_RATECARDS, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [updateRatecard] = useMutation(UPDATE_RATECARD, {
    refetchQueries: [{
      query: GET_RATECARDS,
      variables: { projectId: selectedProject },
    }],
  });
  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }

  return (
    <OfferingRatecardTable
      ratecards={data.ratecards}
      projects={data1.project}
      updateRatecard={updateRatecard}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectRatecard={selectRatecard}
      // loading={loading}
      // user={user}
    />
  );
}

const mapStateToProps = (state) => ({
  ratecards: state.offering.ratecards,
  projects: state.offering.projects,
  selectedProject: state.offering.selectedProject,
  loading: state.offering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchRatecards: (id) => dispatch(fetchRatecards(id)),
  // updateRatecard: (
  //   ratecardId,
  //   profile,
  //   description,
  //   dailyrate,
  //   travelallowance,
  //   projectId,
  // ) =>
  //   dispatch(
  //     updateRatecard(
  //       ratecardId,
  //       profile,
  //       description,
  //       dailyrate,
  //       travelallowance,
  //       projectId,
  //     ),
  //   ),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectRatecard: (id) => dispatch(selectRatecard(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferingRatecardContainer),
);

OfferingRatecardContainer.propTypes = {
  // fetchRatecards: PropTypes.func.isRequired,
  // updateRatecard: PropTypes.func.isRequired,
  selectRatecard: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // ratecards: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  // user: PropTypes.shape(Object).isRequired,
};
