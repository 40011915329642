import {
  NEGOTIATION_FETCH_PROJECTS_BEGIN,
  NEGOTIATION_FETCH_PROJECTS_SUCCESS,
  NEGOTIATION_FETCH_PROJECTS_FAILURE,
  NEGOTIATION_FETCH_TEMPLATES_BEGIN,
  NEGOTIATION_FETCH_TEMPLATES_SUCCESS,
  NEGOTIATION_FETCH_TEMPLATES_FAILURE,
  NEGOTIATION_FETCH_MODULES_BEGIN,
  NEGOTIATION_FETCH_MODULES_SUCCESS,
  NEGOTIATION_FETCH_MODULES_FAILURE,
  NEGOTIATION_FETCH_STATEMENTS_BEGIN,
  NEGOTIATION_FETCH_STATEMENTS_SUCCESS,
  NEGOTIATION_FETCH_STATEMENTS_FAILURE,
  NEGOTIATION_CLEAR_PROJECTS_ARRAY,
  NEGOTIATION_CLEAR_TEMPLATES_ARRAY,
  NEGOTIATION_CLEAR_MODULES_ARRAY,
  NEGOTIATION_CLEAR_STATEMENTS_ARRAY,
  NEGOTIATION_SELECT_PROJECT,
  NEGOTIATION_SELECT_TEMPLATE,
  NEGOTIATION_SELECT_MODULE,
  NEGOTIATION_SELECT_STATEMENT,
  PUBLISH_NEGOTIATION_BEGIN,
  PUBLISH_NEGOTIATION_SUCCESS,
  PUBLISH_NEGOTIATION_FAILURE,
  SELECT_NEGOTIATION_ASSUMPTION,
  SELECT_NEGOTIATION_PROVISION,
  FETCH_NEGOTIATION_ASSUMPTIONS_BEGIN,
  FETCH_NEGOTIATION_ASSUMPTIONS_SUCCESS,
  FETCH_NEGOTIATION_ASSUMPTIONS_FAILURE,
  FETCH_NEGOTIATION_PROVISIONS_BEGIN,
  FETCH_NEGOTIATION_PROVISIONS_SUCCESS,
  FETCH_NEGOTIATION_PROVISIONS_FAILURE,
  FETCH_NEGOTIATION_SLAS_BEGIN,
  FETCH_NEGOTIATION_SLAS_SUCCESS,
  FETCH_NEGOTIATION_SLAS_FAILURE,
  CLEAR_NEGOTIATION_SLAS_ARRAY,
  SELECT_NEGOTIATION_SLA,
  FETCH_NEGOTIATION_RATECARDS_BEGIN,
  FETCH_NEGOTIATION_RATECARDS_SUCCESS,
  FETCH_NEGOTIATION_RATECARDS_FAILURE,
  FETCH_NEGOTIATION_FIXEDPRICES_BEGIN,
  FETCH_NEGOTIATION_FIXEDPRICES_SUCCESS,
  FETCH_NEGOTIATION_FIXEDPRICES_FAILURE,
  FETCH_NEGOTIATION_PIECEPRICES_BEGIN,
  FETCH_NEGOTIATION_PIECEPRICES_SUCCESS,
  FETCH_NEGOTIATION_PIECEPRICES_FAILURE,
  FETCH_NEGOTIATION_TANDMS_BEGIN,
  FETCH_NEGOTIATION_TANDMS_SUCCESS,
  FETCH_NEGOTIATION_TANDMS_FAILURE,
  SELECT_NEGOTIATION_RATECARD,
  SELECT_NEGOTIATION_FIXEDPRICE,
  SELECT_NEGOTIATION_PIECEPRICE,
  SELECT_NEGOTIATION_TANDM,
  SELECT_NEGOTIATION_REFERENCE,
  SELECT_NEGOTIATION_SUBCONTRACTOR,
  DELETE_NEGOTIATION_PROJECT_BEGIN,
  DELETE_NEGOTIATION_PROJECT_SUCCESS,
  DELETE_NEGOTIATION_PROJECT_FAILURE,
  UPDATE_NEGOTIATION_STATEMENT_BEGIN,
  UPDATE_NEGOTIATION_STATEMENT_SUCCESS,
  UPDATE_NEGOTIATION_STATEMENT_FAILURE,
  MAKE_CONTRACT_BEGIN,
  MAKE_CONTRACT_SUCCESS,
  MAKE_CONTRACT_FAILURE,
  FETCH_CONTRACT_TEMPLATES_BEGIN,
  FETCH_CONTRACT_TEMPLATES_SUCCESS,
  FETCH_CONTRACT_TEMPLATES_FAILURE,
  FETCH_CONTRACT_MODULES_BEGIN,
  FETCH_CONTRACT_MODULES_SUCCESS,
  FETCH_CONTRACT_MODULES_FAILURE,
  FETCH_CONTRACT_STATEMENTS_BEGIN,
  FETCH_CONTRACT_STATEMENTS_SUCCESS,
  FETCH_CONTRACT_STATEMENTS_FAILURE,
  SELECT_NEGOTIATION_INFORMATION,
  FETCH_NEGOTIATION_INFORMATION_BEGIN,
  FETCH_NEGOTIATION_INFORMATION_SUCCESS,
  FETCH_NEGOTIATION_INFORMATION_FAILURE,
  FETCH_NEGOTIATION_REFERENCES_BEGIN,
  FETCH_NEGOTIATION_REFERENCES_SUCCESS,
  FETCH_NEGOTIATION_REFERENCES_FAILURE,
  SELECT_NEGOTIATION_REFERENCE_COMPANY,
  FETCH_NEGOTIATION_SUBCONTRACTORS_BEGIN,
  FETCH_NEGOTIATION_SUBCONTRACTORS_SUCCESS,
  FETCH_NEGOTIATION_SUBCONTRACTORS_FAILURE,
  SELECT_NEGOTIATION_SUBCONTRACTOR_COMPANY,
  ADD_NEGOTIATION_TEMPLATE_BEGIN,
  ADD_NEGOTIATION_TEMPLATE_SUCCESS,
  ADD_NEGOTIATION_TEMPLATE_FAILURE,
  UPDATE_NEGOTIATION_TEMPLATE_BEGIN,
  UPDATE_NEGOTIATION_TEMPLATE_SUCCESS,
  UPDATE_NEGOTIATION_TEMPLATE_FAILURE,
  DELETE_NEGOTIATION_TEMPLATE_BEGIN,
  DELETE_NEGOTIATION_TEMPLATE_SUCCESS,
  DELETE_NEGOTIATION_TEMPLATE_FAILURE,
  UPDATE_NEGOTIATION_PIECEPRICE_BEGIN,
  UPDATE_NEGOTIATION_PIECEPRICE_SUCCESS,
  UPDATE_NEGOTIATION_PIECEPRICE_FAILURE,
  ADD_NEGOTIATION_SLA_BEGIN,
  ADD_NEGOTIATION_SLA_SUCCESS,
  ADD_NEGOTIATION_SLA_FAILURE,
  UPDATE_NEGOTIATION_SLA_BEGIN,
  UPDATE_NEGOTIATION_SLA_SUCCESS,
  UPDATE_NEGOTIATION_SLA_FAILURE,
  DELETE_NEGOTIATION_SLA_BEGIN,
  DELETE_NEGOTIATION_SLA_SUCCESS,
  DELETE_NEGOTIATION_SLA_FAILURE,
  ADD_NEGOTIATION_RATECARD_BEGIN,
  ADD_NEGOTIATION_RATECARD_SUCCESS,
  ADD_NEGOTIATION_RATECARD_FAILURE,
  UPDATE_NEGOTIATION_RATECARD_BEGIN,
  UPDATE_NEGOTIATION_RATECARD_SUCCESS,
  UPDATE_NEGOTIATION_RATECARD_FAILURE,
  DELETE_NEGOTIATION_RATECARD_BEGIN,
  DELETE_NEGOTIATION_RATECARD_SUCCESS,
  DELETE_NEGOTIATION_RATECARD_FAILURE,
  ADD_NEGOTIATION_STATEMENT_BEGIN,
  ADD_NEGOTIATION_STATEMENT_SUCCESS,
  ADD_NEGOTIATION_STATEMENT_FAILURE,
  DELETE_NEGOTIATION_STATEMENT_BEGIN,
  DELETE_NEGOTIATION_STATEMENT_SUCCESS,
  DELETE_NEGOTIATION_STATEMENT_FAILURE,
  UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_BEGIN,
  UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_SUCCESS,
  UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_FAILURE,
  FETCH_NEGOTIATION_EDITORS_BEGIN,
  FETCH_NEGOTIATION_EDITORS_SUCCESS,
  FETCH_NEGOTIATION_EDITORS_FAILURE,
  FETCH_NEGOTIATION_VIEWERS_BEGIN,
  FETCH_NEGOTIATION_VIEWERS_SUCCESS,
  FETCH_NEGOTIATION_VIEWERS_FAILURE,
  UPDATE_NEGOTIATION_EDITORS_BEGIN,
  UPDATE_NEGOTIATION_EDITORS_SUCCESS,
  UPDATE_NEGOTIATION_EDITORS_FAILURE,
  UPDATE_NEGOTIATION_VIEWERS_BEGIN,
  UPDATE_NEGOTIATION_VIEWERS_SUCCESS,
  UPDATE_NEGOTIATION_VIEWERS_FAILURE,
  NEGOTIATION_TEMPLATE_NUMBER_DOWN_BEGIN,
  NEGOTIATION_TEMPLATE_NUMBER_DOWN_SUCCESS,
  NEGOTIATION_TEMPLATE_NUMBER_DOWN_FAILURE,
  NEGOTIATION_TEMPLATE_NUMBER_UP_BEGIN,
  NEGOTIATION_TEMPLATE_NUMBER_UP_SUCCESS,
  NEGOTIATION_TEMPLATE_NUMBER_UP_FAILURE,
  NEGOTIATION_MODULE_NUMBER_DOWN_BEGIN,
  NEGOTIATION_MODULE_NUMBER_DOWN_SUCCESS,
  NEGOTIATION_MODULE_NUMBER_DOWN_FAILURE,
  NEGOTIATION_MODULE_NUMBER_UP_BEGIN,
  NEGOTIATION_MODULE_NUMBER_UP_SUCCESS,
  NEGOTIATION_MODULE_NUMBER_UP_FAILURE,
  NEGOTIATION_STATEMENT_NUMBER_DOWN_BEGIN,
  NEGOTIATION_STATEMENT_NUMBER_DOWN_SUCCESS,
  NEGOTIATION_STATEMENT_NUMBER_DOWN_FAILURE,
  NEGOTIATION_STATEMENT_NUMBER_UP_BEGIN,
  NEGOTIATION_STATEMENT_NUMBER_UP_SUCCESS,
  NEGOTIATION_STATEMENT_NUMBER_UP_FAILURE,
  FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_BEGIN,
  FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_SUCCESS,
  FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_FAILURE,
  UPLOAD_NEGOTIATION_PROJECT_FILE_BEGIN,
  UPLOAD_NEGOTIATION_PROJECT_FILE_SUCCESS,
  UPLOAD_NEGOTIATION_PROJECT_FILE_FAILURE,
  REMOVE_NEGOTIATION_PROJECT_UPLOADS_BEGIN,
  REMOVE_NEGOTIATION_PROJECT_UPLOADS_SUCCESS,
  REMOVE_NEGOTIATION_PROJECT_UPLOADS_FAILURE,
  UPLOAD_NEGOTIATION_TEMPLATE_FILE_BEGIN,
  UPLOAD_NEGOTIATION_TEMPLATE_FILE_SUCCESS,
  UPLOAD_NEGOTIATION_TEMPLATE_FILE_FAILURE,
  REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_BEGIN,
  REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_SUCCESS,
  REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_FAILURE,
  UPLOAD_NEGOTIATION_MODULE_FILE_BEGIN,
  UPLOAD_NEGOTIATION_MODULE_FILE_SUCCESS,
  UPLOAD_NEGOTIATION_MODULE_FILE_FAILURE,
  REMOVE_NEGOTIATION_MODULE_UPLOADS_BEGIN,
  REMOVE_NEGOTIATION_MODULE_UPLOADS_SUCCESS,
  REMOVE_NEGOTIATION_MODULE_UPLOADS_FAILURE,
  UPLOAD_NEGOTIATION_STATEMENT_FILE_BEGIN,
  UPLOAD_NEGOTIATION_STATEMENT_FILE_SUCCESS,
  UPLOAD_NEGOTIATION_STATEMENT_FILE_FAILURE,
  REMOVE_NEGOTIATION_STATEMENT_UPLOADS_BEGIN,
  REMOVE_NEGOTIATION_STATEMENT_UPLOADS_SUCCESS,
  REMOVE_NEGOTIATION_STATEMENT_UPLOADS_FAILURE,
} from '../actionTypes/actionTypes';

const initialstate = {
  publishedProjects: [],
  projects: [],
  templates: [],
  modules: [],
  statements: [],
  contractTemplates: [],
  contractModules: [],
  contractStatements: [],
  assumptions: [],
  provisions: [],
  slas: [],
  ratecards: [],
  fixedPrices: [],
  piecePrices: [],
  tandms: [],
  information: [],
  references: [],
  subcontractors: [],
  editors: [],
  viewers: [],
  selectedProject: '',
  selectedTemplate: '',
  selectedModule: '',
  selectedStatement: '',
  selectedAssumption: '',
  selectedProvision: '',
  selectedSla: '',
  selectedRatecard: '',
  selectedFixedPrice: '',
  selectedPiecePrice: '',
  selectedTAndM: '',
  selectedReference: '',
  selectedReferenceCompany: '',
  selectedSubcontractorCompany: '',
  selectedSubcontractor: '',
  selectedInformation: '',
  loading: false,
  error: null,
  isShowing: [],
};

export default (state = initialstate, action) => {
  switch (action.type) {
    case NEGOTIATION_STATEMENT_NUMBER_DOWN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case NEGOTIATION_STATEMENT_NUMBER_DOWN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case NEGOTIATION_STATEMENT_NUMBER_DOWN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case NEGOTIATION_STATEMENT_NUMBER_UP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case NEGOTIATION_STATEMENT_NUMBER_UP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case NEGOTIATION_STATEMENT_NUMBER_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case NEGOTIATION_MODULE_NUMBER_DOWN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case NEGOTIATION_MODULE_NUMBER_DOWN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case NEGOTIATION_MODULE_NUMBER_DOWN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case NEGOTIATION_MODULE_NUMBER_UP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case NEGOTIATION_MODULE_NUMBER_UP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case NEGOTIATION_MODULE_NUMBER_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case NEGOTIATION_TEMPLATE_NUMBER_DOWN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case NEGOTIATION_TEMPLATE_NUMBER_DOWN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case NEGOTIATION_TEMPLATE_NUMBER_DOWN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case NEGOTIATION_TEMPLATE_NUMBER_UP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case NEGOTIATION_TEMPLATE_NUMBER_UP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case NEGOTIATION_TEMPLATE_NUMBER_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_NEGOTIATION_EDITORS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEGOTIATION_EDITORS_SUCCESS:
      return {
        ...state,
        loading: false,
        editors: action.payload.editors,
      };
    case FETCH_NEGOTIATION_EDITORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_NEGOTIATION_EDITORS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_NEGOTIATION_EDITORS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_NEGOTIATION_EDITORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_NEGOTIATION_VIEWERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEGOTIATION_VIEWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        viewers: action.payload.viewers,
      };
    case FETCH_NEGOTIATION_VIEWERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_NEGOTIATION_VIEWERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_NEGOTIATION_VIEWERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_NEGOTIATION_VIEWERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_NEGOTIATION_STATEMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_NEGOTIATION_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_NEGOTIATION_STATEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_NEGOTIATION_STATEMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_NEGOTIATION_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_NEGOTIATION_STATEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_NEGOTIATION_RATECARD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_NEGOTIATION_RATECARD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_NEGOTIATION_RATECARD_FAILURE:
      return {
        ...state,
        lodaing: false,
        error: action.payload.error,
      };
    case UPDATE_NEGOTIATION_RATECARD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_NEGOTIATION_RATECARD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_NEGOTIATION_RATECARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_NEGOTIATION_RATECARD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_NEGOTIATION_RATECARD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_NEGOTIATION_RATECARD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }
    case ADD_NEGOTIATION_SLA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_NEGOTIATION_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_NEGOTIATION_SLA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_NEGOTIATION_SLA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_NEGOTIATION_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_NEGOTIATION_SLA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_NEGOTIATION_SLA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_NEGOTIATION_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_NEGOTIATION_SLA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_NEGOTIATION_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_NEGOTIATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_NEGOTIATION_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_NEGOTIATION_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_NEGOTIATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_NEGOTIATION_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_NEGOTIATION_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_NEGOTIATION_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_NEGOTIATION_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SELECT_NEGOTIATION_SUBCONTRACTOR_COMPANY:
      return {
        ...state,
        selectedSubcontractorCompany: action.payload.company,
      };
    case FETCH_NEGOTIATION_SUBCONTRACTORS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEGOTIATION_SUBCONTRACTORS_SUCCESS:
      return {
        ...state,
        subcontractors: action.payload.subcontractors,
        loading: false,
      };
    case FETCH_NEGOTIATION_SUBCONTRACTORS_FAILURE:
      return {
        ...state,
        loading: false,
        subcontractors: [],
        error: action.payload.error,
      };
    case SELECT_NEGOTIATION_REFERENCE_COMPANY:
      return {
        ...state,
        selectedReferenceCompany: action.payload.company,
      };
    case FETCH_NEGOTIATION_REFERENCES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEGOTIATION_REFERENCES_SUCCESS:
      return {
        ...state,
        references: action.payload.references,
        loading: false,
      };
    case FETCH_NEGOTIATION_REFERENCES_FAILURE:
      return {
        ...state,
        loading: false,
        references: [],
        error: action.payload.error,
      };
    case SELECT_NEGOTIATION_INFORMATION:
      return {
        ...state,
        selectedInformation: action.payload.id,
      };
    case FETCH_NEGOTIATION_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEGOTIATION_INFORMATION_SUCCESS:
      return {
        ...state,
        information: action.payload.information,
        loading: false,
      };
    case FETCH_NEGOTIATION_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        information: [],
        error: action.payload.error,
      };
    case FETCH_CONTRACT_TEMPLATES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CONTRACT_TEMPLATES_SUCCESS:
      return {
        ...state,
        contractTemplates: action.payload.templates,
        loading: false,
      };
    case FETCH_CONTRACT_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        contractTemplates: [],
        error: action.payload.error,
      };
    case FETCH_CONTRACT_MODULES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CONTRACT_MODULES_SUCCESS:
      return {
        ...state,
        contractModules: action.payload.modules,
        loading: false,
      };
    case FETCH_CONTRACT_MODULES_FAILURE:
      return {
        ...state,
        loading: false,
        contractModules: [],
        error: action.payload.error,
      };
    case FETCH_CONTRACT_STATEMENTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CONTRACT_STATEMENTS_SUCCESS:
      return {
        ...state,
        contractStatements: action.payload.statements,
        loading: false,
      };
    case FETCH_CONTRACT_STATEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        contractStatements: [],
        error: action.payload.error,
      };
    case MAKE_CONTRACT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MAKE_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MAKE_CONTRACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_NEGOTIATION_STATEMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_NEGOTIATION_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_NEGOTIATION_STATEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_NEGOTIATION_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_NEGOTIATION_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: [
          ...state.projects.filter(
            (project) => project._id !== action.payload.id,
          ),
        ],
      };
    case DELETE_NEGOTIATION_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case SELECT_NEGOTIATION_RATECARD:
      return {
        ...state,
        selectedRatecard: action.payload.id,
      };
    case SELECT_NEGOTIATION_FIXEDPRICE:
      return {
        ...state,
        selectedFixedPrice: action.payload.id,
      };
    case SELECT_NEGOTIATION_PIECEPRICE:
      return {
        ...state,
        selectedPiecePrice: action.payload.id,
      };
    case SELECT_NEGOTIATION_TANDM:
      return {
        ...state,
        selectedTAndM: action.payload.id,
      };
    case SELECT_NEGOTIATION_REFERENCE:
      return {
        ...state,
        selectedReference: action.payload.id,
      };
    case SELECT_NEGOTIATION_SUBCONTRACTOR:
      return {
        ...state,
        selectedSubcontractor: action.payload.id,
      };
    case FETCH_NEGOTIATION_RATECARDS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEGOTIATION_RATECARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        ratecards: action.payload.ratecards,
      };
    case FETCH_NEGOTIATION_RATECARDS_FAILURE:
      return {
        ...state,
        loading: false,
        ratecards: [],
        error: action.payload.error,
      };
    case FETCH_NEGOTIATION_FIXEDPRICES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEGOTIATION_FIXEDPRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        fixedPrices: action.payload.fixedPrices,
      };
    case FETCH_NEGOTIATION_FIXEDPRICES_FAILURE:
      return {
        ...state,
        loading: false,
        fixedPrices: [],
        error: action.payload.error,
      };
    case FETCH_NEGOTIATION_PIECEPRICES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEGOTIATION_PIECEPRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        piecePrices: action.payload.piecePrices,
      };
    case FETCH_NEGOTIATION_PIECEPRICES_FAILURE:
      return {
        ...state,
        loading: false,
        piecePrices: [],
        error: action.payload.error,
      };
    case FETCH_NEGOTIATION_TANDMS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEGOTIATION_TANDMS_SUCCESS:
      return {
        ...state,
        loading: false,
        tandms: action.payload.tandms,
      };
    case FETCH_NEGOTIATION_TANDMS_FAILURE:
      return {
        ...state,
        loading: false,
        tandms: [],
        error: action.payload.error,
      };
    case SELECT_NEGOTIATION_SLA:
      return {
        ...state,
        selectedSla: action.payload.id,
      };
    case CLEAR_NEGOTIATION_SLAS_ARRAY:
      return {
        ...state,
        slas: [],
      };
    case FETCH_NEGOTIATION_SLAS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEGOTIATION_SLAS_SUCCESS:
      return {
        ...state,
        loading: false,
        slas: action.payload.slas,
      };
    case FETCH_NEGOTIATION_SLAS_FAILURE:
      return {
        ...state,
        loading: false,
        slas: [],
        error: action.payload.error,
      };
    case FETCH_NEGOTIATION_ASSUMPTIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEGOTIATION_ASSUMPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        assumptions: action.payload.assumptions,
      };
    case FETCH_NEGOTIATION_ASSUMPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_NEGOTIATION_PROVISIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEGOTIATION_PROVISIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        provisions: action.payload.provisions,
      };
    case FETCH_NEGOTIATION_PROVISIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SELECT_NEGOTIATION_ASSUMPTION:
      return {
        ...state,
        selectedAssumption: action.payload.id,
      };
    case SELECT_NEGOTIATION_PROVISION:
      return {
        ...state,
        selectedProvision: action.payload.id,
      };
    case PUBLISH_NEGOTIATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PUBLISH_NEGOTIATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PUBLISH_NEGOTIATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case NEGOTIATION_FETCH_PROJECTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case NEGOTIATION_FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: action.payload.projects,
      };
    case NEGOTIATION_FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        projects: [],
      };
    case NEGOTIATION_FETCH_TEMPLATES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case NEGOTIATION_FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload.templates,
      };
    case NEGOTIATION_FETCH_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        statements: [],
      };
    case NEGOTIATION_FETCH_MODULES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case NEGOTIATION_FETCH_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        modules: action.payload.modules,
      };
    case NEGOTIATION_FETCH_MODULES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        modules: [],
      };
    case NEGOTIATION_FETCH_STATEMENTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case NEGOTIATION_FETCH_STATEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        statements: action.payload.statements,
      };
    case NEGOTIATION_FETCH_STATEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        statements: [],
      };
    case NEGOTIATION_CLEAR_STATEMENTS_ARRAY:
      return {
        ...state,
        statements: [],
      };
    case NEGOTIATION_CLEAR_MODULES_ARRAY:
      return {
        ...state,
        modules: [],
      };
    case NEGOTIATION_CLEAR_TEMPLATES_ARRAY:
      return {
        ...state,
        templates: [],
      };
    case NEGOTIATION_CLEAR_PROJECTS_ARRAY:
      return {
        ...state,
        projects: [],
      };
    case NEGOTIATION_SELECT_PROJECT:
      return {
        ...state,
        selectedProject: action.payload.project,
      };
    case NEGOTIATION_SELECT_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload.template,
      };
    case NEGOTIATION_SELECT_MODULE:
      return {
        ...state,
        selectedModule: action.payload.module,
      };
    case NEGOTIATION_SELECT_STATEMENT:
      return {
        ...state,
        selectedStatement: action.payload.statement,
      };
    case UPDATE_NEGOTIATION_PIECEPRICE_BEGIN:
      return {
        ...state,
        lodaing: true,
        error: null,
      };
    case UPDATE_NEGOTIATION_PIECEPRICE_SUCCESS:
      return {
        ...state,
        lodaing: false,
      };
    case UPDATE_NEGOTIATION_PIECEPRICE_FAILURE:
      return {
        ...state,
        lodaing: false,
        error: action.payload.error,
      };
    case FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_SUCCESS:
      return {
        ...state,
        loading: false,
        isShowing: action.payload.isShowing,
      };
    case FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        isShowing: [true, true, true, true, true, true, true, true],
      };
    case UPLOAD_NEGOTIATION_PROJECT_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_NEGOTIATION_PROJECT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_NEGOTIATION_PROJECT_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_NEGOTIATION_PROJECT_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_NEGOTIATION_PROJECT_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_NEGOTIATION_PROJECT_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPLOAD_NEGOTIATION_TEMPLATE_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_NEGOTIATION_TEMPLATE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_NEGOTIATION_TEMPLATE_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPLOAD_NEGOTIATION_MODULE_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_NEGOTIATION_MODULE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_NEGOTIATION_MODULE_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_NEGOTIATION_MODULE_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_NEGOTIATION_MODULE_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_NEGOTIATION_MODULE_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPLOAD_NEGOTIATION_STATEMENT_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_NEGOTIATION_STATEMENT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_NEGOTIATION_STATEMENT_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_NEGOTIATION_STATEMENT_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_NEGOTIATION_STATEMENT_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_NEGOTIATION_STATEMENT_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
