import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { Brightness4, Brightness7 } from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import StorageIcon from '@material-ui/icons/Storage';
import ListIcon from '@material-ui/icons/List';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import PersonIcon from '@material-ui/icons/Person';
import HelpIcon from '@material-ui/icons/Help';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import Tooltip from '@material-ui/core/Tooltip';
import { Box,
  Select,
  MenuItem,
} from '@material-ui/core';
import WhiteLogoImage from './logo_white.png';
import BlackLogoImage from './logo_black.png';

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  title: {
    // flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxShadow: 'none',
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  projectSelect: {
    display: 'flex',
    minWidth: '200px',
    color: 'inherit',
    margin: '0 10px 0 20px',
    '&:svg': {
      color: 'inherit !important',
    },
  },
  spacer: {
    display: 'flex',
    flexGrow: 1,
  },
  colorInherit: {
    color: 'inherit !important',
  },
  select: {
    '&:before, &:after': {
      borderColor: 'inherit !important',
      color: 'inherit !important',
    },
    '& svg': {
      color: 'inherit !important',
    },
  },
  toolbarBottom: {
    position: 'absolute',
    bottom: '10px',
    width: '100%',
  },
  bottomLinks: {
    color: 'inherit',
    textDecoration: 'inherit',
    fontSize: '80%',
    margin: 'auto',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  appBarButton: {
    marginLeft: 10,
    marginRight: 10,
  },
}));

export default function ButtonAppBar(props) {
  const {
    darkModeEnabled,
    user,
    history,
    isAuthenticated,
    logoutUser,
    toggleDarkMode,
    projects,
    selectProject,
    selectTemplate,
    selectOffer,
    selectNegotiation,
    selectedProject,
  } = props;

  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery('(min-width:600px)');
  if (selectedProject === '' && projects.length > 0) selectProject(projects[0]._id);
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const changeSelectedProject = (value) => {
    selectProject(value);
    selectTemplate('');
    selectOffer('');
    selectNegotiation('');
  };

  return (
    <div className={classes.root}>
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          {isAuthenticated && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
                [classes.hide]: open,
              })}
            >
              <MenuIcon />
            </IconButton>
          )}

          <div className={classes.title}>
            {darkModeEnabled && (
              <img
                src={BlackLogoImage}
                alt="althigo"
                width="70px"
                className={classes.image}
              />
            )}
            {!isAuthenticated && !darkModeEnabled && (
              <Button component={Link} to="/" color="inherit">
                <img
                  src={WhiteLogoImage}
                  alt="althigo"
                  width="70px"
                  className={classes.image}
                />
              </Button>
            )}
            {isAuthenticated && !darkModeEnabled && (
              <img
                src={WhiteLogoImage}
                alt="althigo"
                width="70px"
                className={classes.image}
              />
            )}
          </div>
          {(user.role === 'AG' || user.role === 'ITAG') && (
            <div className={classes.projectSelect} color="inherit">
              <Select
                labelid="selectLabel"
                fullWidth
                onChange={(e) => changeSelectedProject(e.target.value)}
                value={selectedProject}
                className={[classes.select, classes.colorInherit].join(' ')}
              >
                {projects.length > 0 && projects.map((project) => (
                  <MenuItem key={project} value={project._id}>
                    {project.name}
                  </MenuItem>
                ))}
              </Select>
            </div>
          )}
          <div className={classes.spacer} />
          {!isAuthenticated ? (
            <>
              {matches && (
                <Button
                  className={classes.appBarButton}
                  component={Link}
                  to="/signup"
                  color="inherit"
                >
                  Registrieren
                </Button>
              )}
              <Button
                className={classes.appBarButton}
                style={{ color: 'white', background: '#EFB466' }}
                component={Link}
                to="/signin"
              >
                Anmelden
              </Button>
            </>
          ) : (
            <>
              {darkModeEnabled ? (
                <IconButton onClick={() => toggleDarkMode()} color="inherit">
                  <Brightness7 />
                </IconButton>
              ) : (
                <IconButton onClick={() => toggleDarkMode()} color="inherit">
                  <Brightness4 />
                </IconButton>
              )}
              {matches && <Typography>{user.email}</Typography>}
              {matches ? (
                <Button onClick={() => logoutUser(history)} color="inherit">
                  Abmelden
                </Button>
              ) : (
                <IconButton onClick={() => logoutUser(history)} color="inherit">
                  <ExitToAppIcon />
                </IconButton>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
      {isAuthenticated && (
        <Drawer
          variant={matches ? 'permanent' : 'temporary'}
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
          open={open}
          onClick={handleDrawerClose}
        >
          <div className={classes.toolbar}>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </div>
          {user.admin && (
            <>
              <List>
                <Tooltip title="Benutzer verwalten" placement="right">
                  <ListItem
                    button
                    component={Link}
                    to="/users"
                    selected={window.location.pathname === '/users'}
                  >
                    <ListItemIcon>
                      <PeopleIcon />
                    </ListItemIcon>
                    <ListItemText primary="Benutzer" />
                  </ListItem>
                </Tooltip>
                <Tooltip title="Datenbank verwalten" placement="right">
                  <ListItem
                    button
                    component={Link}
                    to="/database"
                    selected={window.location.pathname === '/database'}
                  >
                    <ListItemIcon>
                      <StorageIcon />
                    </ListItemIcon>
                    <ListItemText primary="Datenbank" />
                  </ListItem>
                </Tooltip>
                <Tooltip title="Projekt erstellen" placement="right">
                  <ListItem
                    button
                    component={Link}
                    to="/createproject"
                    selected={window.location.pathname === '/createproject'}
                  >
                    <ListItemIcon>
                      <CreateNewFolderIcon />
                    </ListItemIcon>
                    <ListItemText primary="Projekt erstellen" />
                  </ListItem>
                </Tooltip>
              </List>
              <Divider />
            </>
          )}
          {(user.role === 'AG' || user.role === 'ITAG') && (
            <>
              <List>
                <Tooltip title="Projekt bearbeiten" placement="right">
                  <ListItem
                    button
                    component={Link}
                    to="/manageproject"
                    selected={window.location.pathname === '/manageproject'}
                  >
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Projekt bearbeiten" />
                  </ListItem>
                </Tooltip>
                <Tooltip title="Erhaltene Angebote" placement="right">
                  <ListItem
                    button
                    component={Link}
                    to="/viewoffering"
                    selected={window.location.pathname === '/viewoffering'}
                  >
                    <ListItemIcon>
                      <CallReceivedIcon />
                    </ListItemIcon>
                    <ListItemText primary="Erhaltene Angebote" />
                  </ListItem>
                </Tooltip>
                <Tooltip title="Verhandlungen" placement="right">
                  <ListItem
                    button
                    component={Link}
                    to="/managenegotiation"
                    selected={window.location.pathname === '/managenegotiation'}
                  >
                    <ListItemIcon>
                      <PeopleOutlineIcon />
                    </ListItemIcon>
                    <ListItemText primary="Verhandlungen" />
                  </ListItem>
                </Tooltip>
              </List>
              <Divider />
            </>
          )}
          {(user.role === 'AN' || user.role === 'ITAG') && (
            <>
              <List>
                <Tooltip title="Angebote bearbeiten" placement="right">
                  <ListItem
                    button
                    component={Link}
                    to="/manageoffering"
                    selected={window.location.pathname === '/manageoffering'}
                  >
                    <ListItemIcon>
                      <ListIcon />
                    </ListItemIcon>
                    <ListItemText primary="Angebote bearbeiten" />
                  </ListItem>
                </Tooltip>
              </List>
              <Divider />
            </>
          )}
          <List>
            <Tooltip title="Mein Profil" placement="right">
              <ListItem
                button
                component={Link}
                to="/profile"
                selected={window.location.pathname === '/profile'}
              >
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="Profil" />
              </ListItem>
            </Tooltip>
            {(user.role === 'AN' || user.role === 'ITAG') && (
              <>
                <Tooltip title="Anleitung" placement="right">
                  <ListItem
                    button
                    component={Link}
                    to="/instructions"
                    selected={window.location.pathname === '/instructions'}
                  >
                    <ListItemIcon>
                      <HelpIcon />
                    </ListItemIcon>
                    <ListItemText primary="Anleitung" />
                  </ListItem>
                </Tooltip>
              </>
            )}
          </List>

          <Box className={classes.toolbarBottom}>
            <Typography variant="body2" color="textSecondary" align="center">
              <Link className={classes.bottomLinks} to="/privacy">
                Datenschutz
              </Link>
            </Typography>

            <Typography variant="body2" color="textSecondary" align="center">
              <Link className={classes.bottomLinks} to="/imprint">
                Impressum
              </Link>
            </Typography>
          </Box>
        </Drawer>
      )}
    </div>
  );
}

ButtonAppBar.propTypes = {
  darkModeEnabled: PropTypes.bool.isRequired,
  user: PropTypes.shape(Object),
  history: PropTypes.shape(Object).isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  logoutUser: PropTypes.func.isRequired,
  toggleDarkMode: PropTypes.func.isRequired,
  selectProject: PropTypes.func,
  selectOffer: PropTypes.func,
  selectNegotiation: PropTypes.func,
  selectedProject: PropTypes.string,
  selectTemplate: PropTypes.func,
  projects: PropTypes.arrayOf(PropTypes.shape(Object)),
};

ButtonAppBar.defaultProps = {
  user: { email: 'info@sourcing-pilot.com' },
};
