import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import {
  TextField,
} from 'formik-material-ui';

function NumberFormatCustom(props) {
  const { inputRef, onChange, name, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      fixedDecimalScale
      decimalScale={2}
      suffix=" €"
    />
  );
}
function CurrencyInput(props) {
  const { value, ...other } = props;

  return <TextField
    {...other}
    InputProps={{
      inputComponent: NumberFormatCustom,
    }}
  />;
}

export default CurrencyInput;

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
};

CurrencyInput.propTypes = {
  value: PropTypes.string,
};
