import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { withStyles } from '@material-ui/core/styles';
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core/';
import DeleteDialog from '../Dialogs/DeleteDialog';

const styles = () => ({
  list: {
    height: 300,
    overflowY: 'scroll',
  },
  inline: {
    display: 'inline',
  },
});

class CommentsList extends Component {
  componentDidMount() {
    this.commentsList.scrollTop = this.commentsList.scrollHeight;
  }

  componentDidUpdate() {
    this.commentsList.scrollTop = this.commentsList.scrollHeight;
  }

  render() {
    const { classes, comments, user, deleteComment } = this.props;
    return (
      <List
        ref={(element) => {
          this.commentsList = element;
        }}
        className={classes.list}
        component="nav"
      >
        {comments.map((comment) => (
          <ListItem key={comment._id}>
            <ListItemText
              primary={comment.text}
              secondary={`${comment.author} ${moment
                .unix(comment.created / 1000)
                .format('DD.MM.YYYY HH:mm')}`}
            />
            {user.id === comment.authorId._id && (
              <ListItemSecondaryAction>
                <DeleteDialog
                  onClick={() =>
                    deleteComment({ variables: { _id: comment._id } })}
                  text="Wollen Sie ihren Kommentar wirklich löschen?"
                  header="Kommentar löschen"
                />
              </ListItemSecondaryAction>
            )}
          </ListItem>
        ))}
      </List>
    );
  }
}

export default withStyles(styles)(CommentsList);

CommentsList.propTypes = {
  classes: PropTypes.shape(Object).isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  user: PropTypes.shape(Object).isRequired,
  deleteComment: PropTypes.func.isRequired,
};
