import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_PRICES, GET_PROJECT, GET_RATECARDS } from '../../graphql/queries';
import { ANSWER_PRICE, ADD_PRICE, DELETE_PRICE } from '../../graphql/mutations';

import {
  // fetchTAndMs,
  // fetchRatecards,
  // addTAndM,
  // updateTAndM,
  // deleteTAndM,
  selectTAndM,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/offeringAction';
import OfferingTAndMsTable from '../../components/OfferingManagement/OfferingTAndMsTable';
import Loading from '../../components/Elements/Loading';

function OfferingTAndMContainer(props) {
  const {
    // tandms,
    // projects,
    templates,
    // ratecards,
    // fetchTAndMs,
    // fetchRatecards,
    // addTAndM,
    // updateTAndM,
    // deleteTAndM,
    selectTAndM,
    selectProject,
    selectTemplate,
    selectedProject,
    // loading,
    // user,
  } = props;
  // useEffect(() => {
  //   fetchTAndMs(selectedProject);
  //   fetchRatecards(selectedProject);
  // }, [fetchTAndMs, fetchRatecards, selectedProject]);

  // tandms.forEach((element) => {
  //   element.templateName = templates.filter((template) => template._id === element.template).map((template) => template.name).toString();
  // });

  const priceType = 'T&M';
  const { loading, error, data } = useQuery(GET_PRICES, {
    variables: { projectId: selectedProject, type: priceType },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading2, error: error2, data: data2 } = useQuery(GET_RATECARDS, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const [answerPrice] = useMutation(ANSWER_PRICE, {
    refetchQueries: [{
      query: GET_PRICES,
      variables: { projectId: selectedProject, type: priceType },
    }],
  });

  const [addPrice] = useMutation(ADD_PRICE, {
    refetchQueries: [{
      query: GET_PRICES,
      variables: { projectId: selectedProject, type: priceType },
    }],
  });
  const [deletePrice] = useMutation(DELETE_PRICE, {
    refetchQueries: [{
      query: GET_PRICES,
      variables: { projectId: selectedProject, type: priceType },
    }],
  });
  if (loading || loading1 || loading2) return <Loading />;
  if (error || error1 || error2) {
    return (
      <p>
        Error :$
        {error + error1 + error2}
      </p>
    );
  }

  return (
    <OfferingTAndMsTable
      tandms={data.pricesByType}
      projects={data1.project}
      ratecards={data2.ratecards}
      templates={templates}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectTAndM={selectTAndM}
      addTAndM={addPrice}
      updateTAndM={answerPrice}
      deleteTAndM={deletePrice}
      // loading={loading}
      // user={user}
    />
  );
}

const mapStateToProps = (state) => ({
  tandms: state.offering.tandms,
  ratecards: state.offering.ratecards,
  projects: state.offering.projects,
  templates: state.offering.templates,
  selectedProject: state.offering.selectedProject,
  loading: state.offering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchTAndMs: (id) => dispatch(fetchTAndMs(id)),
  // fetchRatecards: (id) => dispatch(fetchRatecards(id)),
  // addTAndM: (projectId, moduleId) => dispatch(addTAndM(projectId, moduleId)),
  // updateTAndM: (id, profile, profileName, days, price) =>
  //   dispatch(updateTAndM(id, profile, profileName, days, price)),
  // deleteTAndM: (id) => dispatch(deleteTAndM(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectTAndM: (id) => dispatch(selectTAndM(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferingTAndMContainer),
);

OfferingTAndMContainer.propTypes = {
  // fetchTAndMs: PropTypes.func.isRequired,
  // fetchRatecards: PropTypes.func.isRequired,
  // addTAndM: PropTypes.func.isRequired,
  // updateTAndM: PropTypes.func.isRequired,
  // deleteTAndM: PropTypes.func.isRequired,
  selectTAndM: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // tandms: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // ratecards: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // user: PropTypes.shape(Object).isRequired,
};
