import React from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function ResponsiveDiv({ children }) {
  const matches = useMediaQuery('(min-width:600px)');

  if (matches) {
    return (
      <div
        style={{
          maxWidth: '100%',
          height: '100vh',
          marginLeft: 73,
          paddingTop: 64,
        }}
      >
        {children}
      </div>
    );
  }
  return (
    <div
      style={{
        maxWidth: '100%',
        height: '100vh',
        paddingTop: 56,
      }}
    >
      {children}
    </div>
  );
}

export default ResponsiveDiv;

ResponsiveDiv.propTypes = {
  children: PropTypes.shape(Object).isRequired,
};
