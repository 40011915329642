import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

// apollo Client imports
import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { split } from 'apollo-link';
import { ApolloProvider } from '@apollo/react-hooks';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import gql from 'graphql-tag';

// router
import { BrowserRouter } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import * as serviceWorker from './serviceWorker';

import store from './redux/store/store';
import setAuthToken from './setAuthToken';
import { setCurrentUser } from './redux/actionCreators/authenticationAction';

if (localStorage.jwtToken) {
  setAuthToken(localStorage.jwtToken);
  const decoded = jwtDecode(localStorage.jwtToken);
  store().store.dispatch(setCurrentUser(decoded));
}

// GraphQL
export const typeDefs = gql`
  extend type Query {
    isDisabled: Boolean!
  }
`;

export const resolvers = {};

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_HTTP_LINK,
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_WS_LINK,
  options: {
    reconnect: !!localStorage.jwtToken,
    connectionParams: () => ({
      authToken: `${localStorage.getItem('jwtToken')}`,
    }),
  },
});

const link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition'
      && definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const authLink = setContext((_, { headers }) => {
  const bearerToken = `${localStorage.getItem('jwtToken')}`;
  return {
    headers: {
      ...headers,
      Authorization: bearerToken || '',
    },
  };
});

const cache = new InMemoryCache();

const client = new ApolloClient({
  link: authLink.concat(link),
  cache,
  typeDefs,
  resolvers,
});

cache.writeData({
  data: {
    isDisabled: false,
  },
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store().store}>
      <PersistGate loading={null} persistor={store().persistor}>
        <BrowserRouter>
          <App />

        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ApolloProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
