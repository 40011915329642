import {
  VIEWOFFERING_FETCH_PROJECTS_BEGIN,
  VIEWOFFERING_FETCH_PROJECTS_SUCCESS,
  VIEWOFFERING_FETCH_PROJECTS_FAILURE,
  VIEWOFFERING_FETCH_TEMPLATES_BEGIN,
  VIEWOFFERING_FETCH_TEMPLATES_SUCCESS,
  VIEWOFFERING_FETCH_TEMPLATES_FAILURE,
  VIEWOFFERING_FETCH_MODULES_BEGIN,
  VIEWOFFERING_FETCH_MODULES_SUCCESS,
  VIEWOFFERING_FETCH_MODULES_FAILURE,
  VIEWOFFERING_FETCH_STATEMENTS_BEGIN,
  VIEWOFFERING_FETCH_STATEMENTS_SUCCESS,
  VIEWOFFERING_FETCH_STATEMENTS_FAILURE,
  VIEWOFFERING_CLEAR_PROJECTS_ARRAY,
  VIEWOFFERING_CLEAR_TEMPLATES_ARRAY,
  VIEWOFFERING_CLEAR_MODULES_ARRAY,
  VIEWOFFERING_CLEAR_STATEMENTS_ARRAY,
  VIEWOFFERING_SELECT_PROJECT,
  VIEWOFFERING_SELECT_TEMPLATE,
  VIEWOFFERING_SELECT_MODULE,
  VIEWOFFERING_SELECT_STATEMENT,
  PUBLISH_VIEWOFFERING_BEGIN,
  PUBLISH_VIEWOFFERING_SUCCESS,
  PUBLISH_VIEWOFFERING_FAILURE,
  SELECT_VIEWOFFERING_ASSUMPTION,
  SELECT_VIEWOFFERING_PROVISION,
  FETCH_VIEWOFFERING_ASSUMPTIONS_BEGIN,
  FETCH_VIEWOFFERING_ASSUMPTIONS_SUCCESS,
  FETCH_VIEWOFFERING_ASSUMPTIONS_FAILURE,
  FETCH_VIEWOFFERING_PROVISIONS_BEGIN,
  FETCH_VIEWOFFERING_PROVISIONS_SUCCESS,
  FETCH_VIEWOFFERING_PROVISIONS_FAILURE,
  FETCH_VIEWOFFERING_SLAS_BEGIN,
  FETCH_VIEWOFFERING_SLAS_SUCCESS,
  FETCH_VIEWOFFERING_SLAS_FAILURE,
  CLEAR_VIEWOFFERING_SLAS_ARRAY,
  SELECT_VIEWOFFERING_SLA,
  FETCH_VIEWOFFERING_RATECARDS_BEGIN,
  FETCH_VIEWOFFERING_RATECARDS_SUCCESS,
  FETCH_VIEWOFFERING_RATECARDS_FAILURE,
  FETCH_VIEWOFFERING_FIXEDPRICES_BEGIN,
  FETCH_VIEWOFFERING_FIXEDPRICES_SUCCESS,
  FETCH_VIEWOFFERING_FIXEDPRICES_FAILURE,
  FETCH_VIEWOFFERING_PIECEPRICES_BEGIN,
  FETCH_VIEWOFFERING_PIECEPRICES_SUCCESS,
  FETCH_VIEWOFFERING_PIECEPRICES_FAILURE,
  FETCH_VIEWOFFERING_TANDMS_BEGIN,
  FETCH_VIEWOFFERING_TANDMS_SUCCESS,
  FETCH_VIEWOFFERING_TANDMS_FAILURE,
  SELECT_VIEWOFFERING_RATECARD,
  SELECT_VIEWOFFERING_FIXEDPRICE,
  SELECT_VIEWOFFERING_PIECEPRICE,
  SELECT_VIEWOFFERING_TANDM,
  SELECT_VIEWOFFERING_REFERENCE,
  SELECT_VIEWOFFERING_SUBCONTRACTOR,
  DELETE_VIEWOFFERING_PROJECT_BEGIN,
  DELETE_VIEWOFFERING_PROJECT_SUCCESS,
  DELETE_VIEWOFFERING_PROJECT_FAILURE,
  UPDATE_VIEWOFFERING_STATEMENT_BEGIN,
  UPDATE_VIEWOFFERING_STATEMENT_SUCCESS,
  UPDATE_VIEWOFFERING_STATEMENT_FAILURE,
  MAKE_CONTRACT_BEGIN,
  MAKE_CONTRACT_SUCCESS,
  MAKE_CONTRACT_FAILURE,
  FETCH_CONTRACT_TEMPLATES_BEGIN,
  FETCH_CONTRACT_TEMPLATES_SUCCESS,
  FETCH_CONTRACT_TEMPLATES_FAILURE,
  FETCH_CONTRACT_MODULES_BEGIN,
  FETCH_CONTRACT_MODULES_SUCCESS,
  FETCH_CONTRACT_MODULES_FAILURE,
  FETCH_CONTRACT_STATEMENTS_BEGIN,
  FETCH_CONTRACT_STATEMENTS_SUCCESS,
  FETCH_CONTRACT_STATEMENTS_FAILURE,
  SELECT_VIEWOFFERING_INFORMATION,
  FETCH_VIEWOFFERING_INFORMATION_BEGIN,
  FETCH_VIEWOFFERING_INFORMATION_SUCCESS,
  FETCH_VIEWOFFERING_INFORMATION_FAILURE,
  FETCH_VIEWOFFERING_REFERENCES_BEGIN,
  FETCH_VIEWOFFERING_REFERENCES_SUCCESS,
  FETCH_VIEWOFFERING_REFERENCES_FAILURE,
  SELECT_VIEWOFFERING_REFERENCE_COMPANY,
  FETCH_VIEWOFFERING_SUBCONTRACTORS_BEGIN,
  FETCH_VIEWOFFERING_SUBCONTRACTORS_SUCCESS,
  FETCH_VIEWOFFERING_SUBCONTRACTORS_FAILURE,
  SELECT_VIEWOFFERING_SUBCONTRACTOR_COMPANY,
  SELECT_VIEWOFFERING_PROJECTOVERVIEW,
  FETCH_OFFERING_PRICES_BEGIN,
  FETCH_OFFERING_PRICES_SUCCESS,
  FETCH_OFFERING_PRICES_FAILURE,
} from '../actionTypes/actionTypes';

const initialstate = {
  publishedProjects: [],
  projects: [],
  templates: [],
  modules: [],
  statements: [],
  contractTemplates: [],
  contractModules: [],
  contractStatements: [],
  assumptions: [],
  provisions: [],
  slas: [],
  ratecards: [],
  fixedPrices: [],
  piecePrices: [],
  tandms: [],
  information: [],
  references: [],
  subcontractors: [],
  selectedProjectOverview: '',
  selectedProject: '',
  selectedTemplate: '',
  selectedModule: '',
  selectedStatement: '',
  selectedAssumption: '',
  selectedProvision: '',
  selectedSla: '',
  selectedRatecard: '',
  selectedFixedPrice: '',
  selectedPiecePrice: '',
  selectedTAndM: '',
  selectedReference: '',
  selectedReferenceCompany: '',
  selectedSubcontractorCompany: '',
  selectedSubcontractor: '',
  selectedInformation: '',
  prices: {
    projects: [],
    fixedPrices: [],
    piecePrices: [],
    rateCards: [],
    tAndMs: [],
  },
  loading: false,
  error: null,
};

export default (state = initialstate, action) => {
  switch (action.type) {
    case FETCH_OFFERING_PRICES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OFFERING_PRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        prices: action.payload.prices,
      };
    case FETCH_OFFERING_PRICES_FAILURE:
      return {
        ...state,
        loading: false,
        prices: {
          projects: [],
          fixedPrices: [],
          piecePrices: [],
          rateCards: [],
          tAndMs: [],
        },
        error: action.payload.error,
      };
    case SELECT_VIEWOFFERING_SUBCONTRACTOR_COMPANY:
      return {
        ...state,
        selectedSubcontractorCompany: action.payload.company,
      };
    case FETCH_VIEWOFFERING_SUBCONTRACTORS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_VIEWOFFERING_SUBCONTRACTORS_SUCCESS:
      return {
        ...state,
        subcontractors: action.payload.subcontractors,
        loading: false,
      };
    case FETCH_VIEWOFFERING_SUBCONTRACTORS_FAILURE:
      return {
        ...state,
        loading: false,
        subcontractors: [],
        error: action.payload.error,
      };
    case SELECT_VIEWOFFERING_REFERENCE_COMPANY:
      return {
        ...state,
        selectedReferenceCompany: action.payload.company,
      };
    case FETCH_VIEWOFFERING_REFERENCES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_VIEWOFFERING_REFERENCES_SUCCESS:
      return {
        ...state,
        references: action.payload.references,
        loading: false,
      };
    case FETCH_VIEWOFFERING_REFERENCES_FAILURE:
      return {
        ...state,
        loading: false,
        references: [],
        error: action.payload.error,
      };
    case SELECT_VIEWOFFERING_INFORMATION:
      return {
        ...state,
        selectedInformation: action.payload.id,
      };
    case FETCH_VIEWOFFERING_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_VIEWOFFERING_INFORMATION_SUCCESS:
      return {
        ...state,
        information: action.payload.information,
        loading: false,
      };
    case FETCH_VIEWOFFERING_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        information: [],
        error: action.payload.error,
      };
    case FETCH_CONTRACT_TEMPLATES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CONTRACT_TEMPLATES_SUCCESS:
      return {
        ...state,
        contractTemplates: action.payload.templates,
        loading: false,
      };
    case FETCH_CONTRACT_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        contractTemplates: [],
        error: action.payload.error,
      };
    case FETCH_CONTRACT_MODULES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CONTRACT_MODULES_SUCCESS:
      return {
        ...state,
        contractModules: action.payload.modules,
        loading: false,
      };
    case FETCH_CONTRACT_MODULES_FAILURE:
      return {
        ...state,
        loading: false,
        contractModules: [],
        error: action.payload.error,
      };
    case FETCH_CONTRACT_STATEMENTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CONTRACT_STATEMENTS_SUCCESS:
      return {
        ...state,
        contractStatements: action.payload.statements,
        loading: false,
      };
    case FETCH_CONTRACT_STATEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        contractStatements: [],
        error: action.payload.error,
      };
    case MAKE_CONTRACT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MAKE_CONTRACT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MAKE_CONTRACT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_VIEWOFFERING_STATEMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_VIEWOFFERING_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_VIEWOFFERING_STATEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_VIEWOFFERING_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_VIEWOFFERING_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_VIEWOFFERING_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case SELECT_VIEWOFFERING_RATECARD:
      return {
        ...state,
        selectedRatecard: action.payload.id,
      };
    case SELECT_VIEWOFFERING_FIXEDPRICE:
      return {
        ...state,
        selectedFixedPrice: action.payload.id,
      };
    case SELECT_VIEWOFFERING_PIECEPRICE:
      return {
        ...state,
        selectedPiecePrice: action.payload.id,
      };
    case SELECT_VIEWOFFERING_TANDM:
      return {
        ...state,
        selectedTAndM: action.payload.id,
      };
    case SELECT_VIEWOFFERING_REFERENCE:
      return {
        ...state,
        selectedReference: action.payload.id,
      };
    case SELECT_VIEWOFFERING_SUBCONTRACTOR:
      return {
        ...state,
        selectedSubcontractor: action.payload.id,
      };
    case FETCH_VIEWOFFERING_RATECARDS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_VIEWOFFERING_RATECARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        ratecards: action.payload.ratecards,
      };
    case FETCH_VIEWOFFERING_RATECARDS_FAILURE:
      return {
        ...state,
        loading: false,
        ratecards: [],
        error: action.payload.error,
      };
    case FETCH_VIEWOFFERING_FIXEDPRICES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_VIEWOFFERING_FIXEDPRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        fixedPrices: action.payload.fixedPrices,
      };
    case FETCH_VIEWOFFERING_FIXEDPRICES_FAILURE:
      return {
        ...state,
        loading: false,
        fixedPrices: [],
        error: action.payload.error,
      };
    case FETCH_VIEWOFFERING_PIECEPRICES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_VIEWOFFERING_PIECEPRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        piecePrices: action.payload.piecePrices,
      };
    case FETCH_VIEWOFFERING_PIECEPRICES_FAILURE:
      return {
        ...state,
        loading: false,
        piecePrices: [],
        error: action.payload.error,
      };
    case FETCH_VIEWOFFERING_TANDMS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_VIEWOFFERING_TANDMS_SUCCESS:
      return {
        ...state,
        loading: false,
        tandms: action.payload.tandms,
      };
    case FETCH_VIEWOFFERING_TANDMS_FAILURE:
      return {
        ...state,
        loading: false,
        tandms: [],
        error: action.payload.error,
      };
    case SELECT_VIEWOFFERING_SLA:
      return {
        ...state,
        selectedSla: action.payload.id,
      };
    case CLEAR_VIEWOFFERING_SLAS_ARRAY:
      return {
        ...state,
        slas: [],
      };
    case FETCH_VIEWOFFERING_SLAS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_VIEWOFFERING_SLAS_SUCCESS:
      return {
        ...state,
        loading: false,
        slas: action.payload.slas,
      };
    case FETCH_VIEWOFFERING_SLAS_FAILURE:
      return {
        ...state,
        loading: false,
        slas: [],
        error: action.payload.error,
      };
    case FETCH_VIEWOFFERING_ASSUMPTIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_VIEWOFFERING_ASSUMPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        assumptions: action.payload.assumptions,
      };
    case FETCH_VIEWOFFERING_ASSUMPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_VIEWOFFERING_PROVISIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_VIEWOFFERING_PROVISIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        provisions: action.payload.provisions,
      };
    case FETCH_VIEWOFFERING_PROVISIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SELECT_VIEWOFFERING_ASSUMPTION:
      return {
        ...state,
        selectedAssumption: action.payload.id,
      };
    case SELECT_VIEWOFFERING_PROVISION:
      return {
        ...state,
        selectedProvision: action.payload.id,
      };
    case PUBLISH_VIEWOFFERING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PUBLISH_VIEWOFFERING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PUBLISH_VIEWOFFERING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case VIEWOFFERING_FETCH_PROJECTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VIEWOFFERING_FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: action.payload.projects,
      };
    case VIEWOFFERING_FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        projects: [],
      };
    case VIEWOFFERING_FETCH_TEMPLATES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VIEWOFFERING_FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload.templates,
      };
    case VIEWOFFERING_FETCH_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        statements: [],
      };
    case VIEWOFFERING_FETCH_MODULES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VIEWOFFERING_FETCH_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        modules: action.payload.modules,
      };
    case VIEWOFFERING_FETCH_MODULES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        modules: [],
      };
    case VIEWOFFERING_FETCH_STATEMENTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VIEWOFFERING_FETCH_STATEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        statements: action.payload.statements,
      };
    case VIEWOFFERING_FETCH_STATEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        statements: [],
      };
    case VIEWOFFERING_CLEAR_STATEMENTS_ARRAY:
      return {
        ...state,
        statements: [],
      };
    case VIEWOFFERING_CLEAR_MODULES_ARRAY:
      return {
        ...state,
        modules: [],
      };
    case VIEWOFFERING_CLEAR_TEMPLATES_ARRAY:
      return {
        ...state,
        templates: [],
      };
    case VIEWOFFERING_CLEAR_PROJECTS_ARRAY:
      return {
        ...state,
        projects: [],
      };
    case SELECT_VIEWOFFERING_PROJECTOVERVIEW:
      return {
        ...state,
        selectedProjectOverview: action.payload.project,
      };
    case VIEWOFFERING_SELECT_PROJECT:
      return {
        ...state,
        selectedProject: action.payload.project,
      };
    case VIEWOFFERING_SELECT_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload.template,
      };
    case VIEWOFFERING_SELECT_MODULE:
      return {
        ...state,
        selectedModule: action.payload.module,
      };
    case VIEWOFFERING_SELECT_STATEMENT:
      return {
        ...state,
        selectedStatement: action.payload.statement,
      };
    default:
      return state;
  }
};
