import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery,
  // useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_PRICES, GET_PROJECT } from '../../graphql/queries';

import {
  // fetchTAndMs,
  selectTAndM,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/projectAction';
import ProjectTAndMsTable from '../../components/ProjectManagement/ProjectTAndMsTable';
import Loading from '../../components/Elements/Loading';

function ProjectTAndMContainer(props) {
  const {
    // tandms,
    // projects,
    templates,
    // fetchTAndMs,
    selectTAndM,
    selectProject,
    selectTemplate,
    selectedProject,
    // loading,
  } = props;
  const priceType = 'T&M';
  const { loading, error, data } = useQuery(GET_PRICES, {
    variables: { projectId: selectedProject, type: priceType },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }

  return (
    <ProjectTAndMsTable
      tandms={data.pricesByType}
      projects={data1.project}
      templates={templates}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectTAndM={selectTAndM}
      loading={loading}
    />
  );
}

const mapStateToProps = (state) => ({
  tandms: state.project.tandms,
  projects: state.project.projects,
  templates: state.project.templates,
  selectedProject: state.project.selectedProject,
  loading: state.project.loading,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchTAndMs: (id) => dispatch(fetchTAndMs(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectTAndM: (id) => dispatch(selectTAndM(id)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProjectTAndMContainer),
);

ProjectTAndMContainer.propTypes = {
  // fetchTAndMs: PropTypes.func.isRequired,
  selectTAndM: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // tandms: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
};
