import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PROJECT, GET_TEMPLATES, GET_ADDITIONAL_HEADERS } from '../../graphql/queries';
import { ADD_TEMPLATE,
  DELETE_TEMPLATE, UPDATE_TEMPLATE, TEMPLATE_NUMBER_DOWN, TEMPLATE_NUMBER_UP } from '../../graphql/mutations';

import {
  // fetchTemplates,
  // addTemplate,
  // updateTemplate,
  // deleteTemplate,
  selectProject,
  selectTemplate,
  selectSla,
  selectRatecard,
  selectTAndM,
  selectFixedPrice,
  selectPiecePrice,
  selectInformation,
  selectReference,
  selectSubcontractor,
  // templateNumberDown,
  // templateNumberUp,
  // fetchAdditionalFilesIsShowing,
  // uploadNegotiationTemplateFile,
  // removeNegotiationTemplateUpload,
} from '../../redux/actionCreators/negotiationAction';
import NegotiationTemplatesTable from '../../components/NegotiationManagement/NegotiationTemplatesTable';
import Loading from '../../components/Elements/Loading';

function NegotiationTemplatesContainer(props) {
  const {
    // projects,
    // templates,
    // fetchTemplates,
    // addTemplate,
    // updateTemplate,
    // deleteTemplate,
    selectProject,
    selectedProject,
    selectTemplate,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectSubcontractor,
    // templateNumberDown,
    // templateNumberUp,
    // fetchAdditionalFilesIsShowing,
    // uploadNegotiationTemplateFile,
    // removeNegotiationTemplateUpload,
    // isShowing,
  } = props;

  const { loading, error, data } = useQuery(GET_TEMPLATES, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading2, error: error2, data: data2 } = useQuery(GET_ADDITIONAL_HEADERS, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const [addTemplate] = useMutation(ADD_TEMPLATE, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });
  const [deleteTemplate] = useMutation(DELETE_TEMPLATE, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });
  const [updateTemplate] = useMutation(UPDATE_TEMPLATE, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });
  const [templateNumberDown] = useMutation(TEMPLATE_NUMBER_DOWN, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });
  const [templateNumberUp] = useMutation(TEMPLATE_NUMBER_UP, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });

  if (loading || loading1 || loading2) return <Loading />;
  if (error || error1 || error2) {
    return (
      <p>
        Error :$
        {error + error1 + error2}
      </p>
    );
  }
  return (
    <NegotiationTemplatesTable
      projects={data1.project}
      templates={data.templates}
      addTemplate={addTemplate}
      updateTemplate={updateTemplate}
      deleteTemplate={deleteTemplate}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectSla={selectSla}
      selectRatecard={selectRatecard}
      selectTAndM={selectTAndM}
      selectFixedPrice={selectFixedPrice}
      selectPiecePrice={selectPiecePrice}
      selectInformation={selectInformation}
      selectReference={selectReference}
      selectSubcontractor={selectSubcontractor}
      loading={loading}
      templateNumberDown={templateNumberDown}
      templateNumberUp={templateNumberUp}
      // uploadNegotiationTemplateFile={uploadNegotiationTemplateFile}
      // removeNegotiationTemplateUpload={removeNegotiationTemplateUpload}
      isShowing={data2.additionalHeaders}
    />
  );
}

const mapStateToProps = (state) => ({
  projects: state.negotiation.projects,
  templates: state.negotiation.templates,
  selectedProject: state.negotiation.selectedProject,
  loading: state.negotiation.loading,
  user: state.auth.user,
  isShowing: state.negotiation.isShowing,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectSla: (id) => dispatch(selectSla(id)),
  selectRatecard: (id) => dispatch(selectRatecard(id)),
  selectTAndM: (id) => dispatch(selectTAndM(id)),
  selectFixedPrice: (id) => dispatch(selectFixedPrice(id)),
  selectPiecePrice: (id) => dispatch(selectPiecePrice(id)),
  selectInformation: (id) => dispatch(selectInformation(id)),
  selectReference: (id) => dispatch(selectReference(id)),
  selectSubcontractor: (id) => dispatch(selectSubcontractor(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NegotiationTemplatesContainer),
);

NegotiationTemplatesContainer.propTypes = {
  selectTemplate: PropTypes.func.isRequired,
  selectSla: PropTypes.func.isRequired,
  selectRatecard: PropTypes.func.isRequired,
  selectTAndM: PropTypes.func.isRequired,
  selectFixedPrice: PropTypes.func.isRequired,
  selectPiecePrice: PropTypes.func.isRequired,
  selectInformation: PropTypes.func.isRequired,
  selectReference: PropTypes.func.isRequired,
  selectSubcontractor: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
};
