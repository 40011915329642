import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';

import { Typography } from '@material-ui/core';
import TableButton from '../Buttons/TableButton';
import DateStamp from '../Elements/DateStamp';
import DeleteDialog from '../Dialogs/DeleteDialog';
import PublishProjectDialog from '../Dialogs/PublishProjectDialog';
import UploadHandler from '../Elements/UploadHandler';
import ExportButton from '../Elements/ExportButton';
import ChangeUsersContainer from '../../container/OfferingManagement/ChangeUsersContainer';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function OfferingProjectsTable(props) {
  const {
    projects,
    selectProject,
    // user,
    deleteOffering,
    publishOffering,
    updateRoles,
  } = props;
  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <TableButton
            text={value}
            onClick={() => selectProject(tableMeta.rowData[0])}
          />
        ),
      },
    },
    {
      name: 'projectDescription',
      label: 'Beschreibung',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
            {`Beschreibung: 
            ${value}`}
            {`
            
            Laufzeit: ${tableMeta.rowData[3]} Jahre`}
          </Typography>
        ),
      },
    },
    {
      name: 'duration',
      label: 'Laufzeit',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'phase',
      label: 'Phase',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value === 0) return '1. Angebot';
          return `${value + 1}. Angebot`;
        },
      },
    },
    {
      name: 'export',
      label: 'Export',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <ExportButton
            id={tableMeta.rowData[0]}
            url="/api/v1/export/word/"
          />
        ),
      },
    },
    {
      name: 'attachment',
      label: 'Anhänge',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <UploadHandler
            projectId={tableMeta.rowData[0]}
            referenceId={tableMeta.rowData[0]}
            url="/api/v1/upload/"
            referenceType="project"
            attachments={value}
            disabled
          />
        ),
      },
    },
    {
      name: 'dissenses',
      label: 'Dissense',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'updated',
      label: 'Geändert',
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => <DateStamp date={value} />,
      },
    },
    {
      name: 'deadline',
      label: 'Status',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (tableMeta.rowData[12]) {
            return 'Angebot eingereicht';
          }
          return (
            <>
              {value > new Date().getTime() ? (
                <>
                  <Typography variant="body2">Späteste Abgabe: </Typography>
                  <DateStamp date={value} />
                  {!(tableMeta.rowData[13] === 'read')
                && !tableMeta.rowData[12] && (
                <PublishProjectDialog
                  id={tableMeta.rowData[0]}
                  onClick={publishOffering}
                  type="offer"
                />
                  )}
                </>
              ) : (
                'Abgabetermin überschritten'
              )}
            </>
          );
        },
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <>
            {!(tableMeta.rowData[13] === 'read')
              && (
                <ChangeUsersContainer
                  projectId={tableMeta.rowData[0]}
                  updateRoles={updateRoles}
                  roles={tableMeta.rowData[11]}
                />
              )}
            {tableMeta.rowData[13] === 'admin' && (
              <DeleteDialog
                header="Angebot löschen"
                text={`Möchten Sie das Angebot "${
                  tableMeta.rowData[1]
                } (${tableMeta.rowData[3] + 1}. Angebot)" wirklich löschen?`}
                onClick={() => deleteOffering({ variables: { projectId: tableMeta.rowData[0] } })}
              />
            )}
          </>
        ),
      },
    },
    {
      name: 'roles',
      label: 'Rollen',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'published',
      label: 'Published',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'authorization',
      label: 'Berechtigung',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
  ];

  const data = [];

  projects.forEach((project) => {
    data.push({
      _id: project._id,
      name: project.name,
      projectDescription: project.description,
      dissenses: `SOWs: ${project.dissenses} SL: ${project.dissensesSla}`,
      updated: Math.max(...project.changes.map((o) => o.date)),
      attachment: project.attachments,
      roles: project.roles,
      deadline: project.deadline,
      duration: project.duration,
      published: project.published,
      phase: project.phase,
      authorization: project.authorization,
    });
  });

  return (
    <div>
      <MUIDataTable
        title="Meine Angebote"
        data={data}
        columns={columns}
        options={FullsizeTableOptions({ tableBodyMaxHeight: 'calc(100vh - 129px)' })}
      />
    </div>
  );
}

export default OfferingProjectsTable;

OfferingProjectsTable.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  selectProject: PropTypes.func.isRequired,
  // user: PropTypes.shape(Object).isRequired,
  updateRoles: PropTypes.func.isRequired,
  deleteOffering: PropTypes.func.isRequired,
  publishOffering: PropTypes.func.isRequired,
};
