import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_QANDAS, GET_PROJECT } from '../../graphql/queries';
import {
  ADD_QANDA,
} from '../../graphql/mutations';

import {
  // fetchOfferingQAndAs,
  // addOfferingQAndA,
  // updateOfferingQAndA,
  // deleteOfferingQAndA,
  selectQAndA,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/offeringAction';
import OfferingQAndAsTable from '../../components/OfferingManagement/OfferingQAndAsTable';
import Loading from '../../components/Elements/Loading';

function OfferingQAndAsContainer(props) {
  const {
    // qandas,
    selectedProject,
    // projects,
    // fetchOfferingQAndAs,
    // addOfferingQAndA,
    // updateOfferingQAndA,
    // deleteOfferingQAndA,
    selectQAndA,
    selectProject,
    selectTemplate,
    // user,
    // loading,
  } = props;
  // useEffect(() => {
  //   fetchOfferingQAndAs(selectedProject);
  // }, [fetchOfferingQAndAs, selectedProject]);

  const { loading, error, data } = useQuery(GET_QANDAS, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [addQAndA] = useMutation(ADD_QANDA, {
    refetchQueries: [{
      query: GET_QANDAS,
      variables: { projectId: selectedProject },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }

  return (
    <OfferingQAndAsTable
      qandas={data.qandas}
      projects={data1.project}
      addOfferingQAndA={addQAndA}
      // updateOfferingQAndA={updateOfferingQAndA}
      // deleteOfferingQAndA={deleteOfferingQAndA}
      selectedProject={selectedProject}
      selectProject={selectProject}
      selectTemplate={selectTemplate}
      selectQAndA={selectQAndA}
      // user={user}
      // loading={loading}
    />
  );
}

const mapStateToProps = (state) => ({
  qandas: state.offering.qandas,
  selectedProject: state.offering.selectedProject,
  projects: state.offering.projects,
  loading: state.offering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchOfferingQAndAs: (id) => dispatch(fetchOfferingQAndAs(id)),
  // addOfferingQAndA: (id, question) => dispatch(addOfferingQAndA(id, question)),
  // updateOfferingQAndA: (id, question) =>
  //   dispatch(updateOfferingQAndA(id, question)),
  // deleteOfferingQAndA: (id) => dispatch(deleteOfferingQAndA(id)),
  selectQAndA: (id) => dispatch(selectQAndA(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferingQAndAsContainer),
);

OfferingQAndAsContainer.propTypes = {
  // fetchOfferingQAndAs: PropTypes.func.isRequired,
  // addOfferingQAndA: PropTypes.func.isRequired,
  // updateOfferingQAndA: PropTypes.func.isRequired,
  // deleteOfferingQAndA: PropTypes.func.isRequired,
  selectQAndA: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  // loading: PropTypes.bool.isRequired,
  // user: PropTypes.shape(Object).isRequired,
  // qandas: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  selectedProject: PropTypes.string.isRequired,
};
