import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import useStyles from '../../styles';
import EditOfferingRatecardDialog from '../Dialogs/EditOfferingRatecardDialog';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';
import CurrencyFormat from '../Elements/CurrencyFormat';

function OfferingRatecardTable(props) {
  const {
    ratecards,
    projects,
    updateRatecard,
    selectProject,
    // selectedProject,
    selectTemplate,
    selectRatecard,
    // user,
  } = props;

  const classes = useStyles();
  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'project',
      label: 'projectId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'profile',
      label: 'Profilname',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Profilbeschreibung',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'dailyrate',
      label: 'Tagessatz in €',
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.rightAlignedHeader }),
        setCellProps: () => ({ className: classes.rightAlignedCell }),
        customBodyRender: (value) => <CurrencyFormat value={value} />,
      },
    },
    {
      name: 'travelallowance',
      label: 'Reisekostenpauschale in €',
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.rightAlignedHeader }),
        setCellProps: () => ({ className: classes.rightAlignedCell }),
        customBodyRender: (value) => <CurrencyFormat value={value} />,
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        display: !projects.published && projects.authorization !== 'read',
        viewColumns: !projects.published && projects.authorization !== 'read',
        customBodyRender: (value, tableMeta) => (
          <EditOfferingRatecardDialog
            ratecardId={tableMeta.rowData[0]}
            projectId={projects._id}
            oldData={[
              tableMeta.rowData[2],
              tableMeta.rowData[3],
              tableMeta.rowData[4],
              tableMeta.rowData[5],
              tableMeta.rowData[1],
            ]}
            onClick={updateRatecard}
          />
        ),
      },
    },
  ];

  const data = [];

  ratecards.forEach((ratecard) => {
    data.push({
      _id: ratecard._id,
      project: ratecard.project,
      profile: ratecard.profile,
      description: ratecard.description,
      dailyrate: ratecard.dailyrate,
      travelallowance: ratecard.travelallowance,
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectRatecard('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectRatecard('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => selectRatecard('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Rate Card</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions()}
      />
    </div>
  );
}

export default OfferingRatecardTable;

OfferingRatecardTable.propTypes = {
  projects: PropTypes.shape(Object).isRequired,
  ratecards: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // selectedProject: PropTypes.string.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectRatecard: PropTypes.func.isRequired,
  updateRatecard: PropTypes.func.isRequired,
  // user: PropTypes.shape(Object).isRequired,
};
