import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  loadingRoot: {
    background: '#fafafa',
    minWidth: '100%',
    minHeight: 'calc(100vh - 64px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
});
function Loading() {
  const classes = useStyles();
  return (
    <>
      <Grid
        container
        className={classes.loadingRoot}
        spacing={0}
        alignItems="center"
      >
        <CircularProgress />
      </Grid>
    </>
  );
}

export default Loading;
