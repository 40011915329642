import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_INFORMATIONS, GET_PROJECT } from '../../graphql/queries';
import {
  UPDATE_INFORMATION,
} from '../../graphql/mutations';
import {
  // fetchOfferingInformation,
  // updateOfferingInformation,
  selectInformation,
  selectProject,
  selectTemplate,
  // uploadOfferingInformationFile,
  // removeOfferingInformationUpload,
} from '../../redux/actionCreators/offeringAction';
import OfferingInformationTable from '../../components/OfferingManagement/OfferingInformationTable';
import Loading from '../../components/Elements/Loading';

function OfferingInformationContainer(props) {
  const {
    selectInformation,
    selectProject,
    selectTemplate,
    selectedProject,
    // uploadOfferingInformationFile,
    // removeOfferingInformationUpload,
  } = props;
  const informationType = 'Information';
  const cluster = '';
  const { loading, error, data } = useQuery(GET_INFORMATIONS, {
    variables: { projectId: selectedProject, type: informationType, cluster },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [updateInformation] = useMutation(UPDATE_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }

  return (
    <OfferingInformationTable
      information={data.informations}
      projects={data1.project}
      updateOfferingInformation={updateInformation}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectInformation={selectInformation}
      // loading={loading}
      // user={user}
      // uploadOfferingInformationFile={uploadOfferingInformationFile}
      // removeOfferingInformationUpload={removeOfferingInformationUpload}
    />
  );
}

const mapStateToProps = (state) => ({
  information: state.offering.information,
  projects: state.offering.projects,
  selectedProject: state.offering.selectedProject,
  loading: state.offering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchOfferingInformation: (projectId) =>
  //   dispatch(fetchOfferingInformation(projectId)),
  // updateOfferingInformation: (id, question) =>
  //   dispatch(updateOfferingInformation(id, question)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectInformation: (id) => dispatch(selectInformation(id)),
  // uploadOfferingInformationFile: (formData, config) =>
  //   dispatch(uploadOfferingInformationFile(formData, config)),
  // removeOfferingInformationUpload: (id) =>
  //   dispatch(removeOfferingInformationUpload(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferingInformationContainer),
);

OfferingInformationContainer.propTypes = {
  // fetchOfferingInformation: PropTypes.func.isRequired,
  // updateOfferingInformation: PropTypes.func.isRequired,
  selectInformation: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // information: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  // user: PropTypes.shape(Object).isRequired,
  // uploadOfferingInformationFile: PropTypes.func.isRequired,
  // removeOfferingInformationUpload: PropTypes.func.isRequired,
};
