import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_SERVICELEVELS, GET_PROJECT } from '../../graphql/queries';
import {
  UPDATE_SERVICELEVEL,
} from '../../graphql/mutations';

import {
  // fetchSlas,
  // updateSla,
  // updateOfferingSlaCompliance,
  selectProject,
  selectTemplate,
  selectSla,
} from '../../redux/actionCreators/offeringAction';
import OfferingSlasTable from '../../components/OfferingManagement/OfferingSlasTable';
import Loading from '../../components/Elements/Loading';

function OfferingSlasContainer(props) {
  const {
    // slas,
    // projects,
    // templates,
    // fetchSlas,
    // updateSla,
    // updateOfferingSlaCompliance,
    selectProject,
    selectTemplate,
    selectedProject,
    selectSla,
    // loading,
    // user,
  } = props;
  // useEffect(() => {
  //   fetchSlas(selectedProject);
  // }, [fetchSlas, selectedProject]);

  // slas.forEach((element) => {
  //   element.templateName = templates.filter((template) => template._id === element.template).map((template) => template.name).toString();
  // });

  const { loading, error, data } = useQuery(GET_SERVICELEVELS, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [updateServiceLevelCompliance] = useMutation(UPDATE_SERVICELEVEL, {
    refetchQueries: [{
      query: GET_SERVICELEVELS,
      variables: { projectId: selectedProject },
    }],
  });

  const [answerServiceLevel] = useMutation(UPDATE_SERVICELEVEL, {
    refetchQueries: [{
      query: GET_SERVICELEVELS,
      variables: { projectId: selectedProject },
    }],
  });
  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  return (
    <OfferingSlasTable
      slas={data.servicelevels}
      projects={data1.project}
      // templates={templates}
      answerServiceLevel={answerServiceLevel}
      updateOfferingSlaCompliance={updateServiceLevelCompliance}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectSla={selectSla}
      // loading={loading}
      // user={user}
    />
  );
}

const mapStateToProps = (state) => ({
  slas: state.offering.slas,
  projects: state.offering.projects,
  templates: state.offering.templates,
  selectedProject: state.offering.selectedProject,
  loading: state.offering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchSlas: (id) => dispatch(fetchSlas(id)),
  // updateSla: (slaId, suggestion, project) =>
  //   dispatch(updateSla(slaId, suggestion, project)),
  // updateOfferingSlaCompliance: (id) =>
  //   dispatch(updateOfferingSlaCompliance(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectSla: (id) => dispatch(selectSla(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferingSlasContainer),
);

OfferingSlasContainer.propTypes = {
  // fetchSlas: PropTypes.func.isRequired,
  // updateSla: PropTypes.func.isRequired,
  selectSla: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  // updateOfferingSlaCompliance: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // slas: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  // templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // user: PropTypes.shape(Object).isRequired,
};
