import gql from 'graphql-tag';

export const PROJECT_ADDED = gql`
  subscription projectAdded($type: String!) {
    projectAdded(type: $type) {
      _id
      active
      name
      published
      deadline
      attachments {
        filename
      }
      roles {
        user {
          email
        }
      }      
      changes {
        date
        user {
          firstname
          lastname
        }
        type
      }
      templates {
        name
      }
      company
      description
      sendIn
      phase
      dissenses
      dissensesSla
      type
    }
  }
`;

export const PROJECT_DELETED = gql`
  subscription projectDeleted($type: String!) {
    projectDeleted(type: $type) {
      _id
      active
      name
      published
      deadline
      attachments {
        filename
      }
      roles {
        user {
          email
        }
      }
      changes {
        date
        user {
          firstname
          lastname
        }
        type
      }
      templates {
        name
      }
      company
      description
      sendIn
      phase
      dissenses
      dissensesSla
      type
    }
  }
`;

export const PROJECT_UPDATED = gql`
  subscription projectUpdated($type: String!) {
    projectUpdated(type: $type) {
      _id
      active
      name
      published
      deadline
      attachments {
        filename
      }
      roles {
        user {
          email
        }
      }
      changes {
        date
        user {
          firstname
          lastname
        }
        type
      }
      templates {
        name
      }
      company
      description
      sendIn
      phase
      dissenses
      dissensesSla
      type
    }
  }
`;

export const TEMPLATE_ADDED = gql`
  subscription templateAdded($projectId: ID!) {
    templateAdded(projectId: $projectId) {
      _id
      active
      attachments {
        filename
      }
      name
      number
      changes {
        date
        user {
          firstname
          lastname
        }
        type
      }
      dissenses
    }
  }
`;

export const TEMPLATES_UPDATED = gql`
  subscription templatesUpdated($projectId: ID!) {
    templatesUpdated(projectId: $projectId) {
      _id
      active
      attachments {
        filename
      }
      name
      number
      changes {
        date
        user {
          firstname
          lastname
        }
        type
      }
      dissenses
      deleted
    }
  }
`;

export const MODULE_ADDED = gql`
  subscription moduleAdded($projectId: ID!, $templateId: ID!) {
    moduleAdded(projectId: $projectId, templateId: $templateId) {
      _id
      active
      name
      number
      dissenses
      heading
      changes {
        date
        type
      }
      servicelevels {
        name
      }
      prices {
        type
      }
    }
  }
`;

export const MODULES_UPDATED = gql`
  subscription modulesUpdated($projectId: ID!) {
    modulesUpdated(projectId: $projectId) {
      _id
      active
      name
      number
      dissenses
      deleted
      heading
      changes {
        date
        type
      }
      servicelevels {
        name
      }
      prices {
        type
      }
    }
  }
`;

export const STATEMENT_ADDED = gql`
  subscription statementAdded($projectId: ID!, $moduleId: ID!) {
    statementAdded(projectId: $projectId, moduleId: $moduleId) {
      _id
      description
      suggestion
      result
      compliance
      number
      changes {
        date
        type
      }
    }
  }
`;

export const STATEMENTS_UPDATED = gql`
  subscription statementsUpdated($projectId: ID!) {
    statementsUpdated(projectId: $projectId) {
      _id
      description
      suggestion
      result
      compliance
      number
      changes {
        date
        type
      }
      deleted
    }
  }
`;

export const COMMENT_ADDED = gql`
  subscription commentAdded($projectId: ID!) {
    commentAdded(projectId: $projectId) {
      _id
      text
      author
      authorId{
        _id
      }
      authorLetters
      created
    }
  }
`;

export const COMMENT_DELETED = gql`
  subscription commentDeleted($projectId: ID!) {
    commentDeleted(projectId: $projectId) {
      _id
      text
      author
      authorId{
        _id
      }
      authorLetters
      created
    }
  }
`;

export const RATECARD_ADDED = gql`
  subscription ratecardAdded($projectId: ID!) {
    ratecardAdded(projectId: $projectId) {
      _id
      profile
      description
      dailyrate
      travelallowance
      changes {
        date
        type
      }
    }
  }
`;

export const RATECARD_UPDATED = gql`
  subscription ratecardUpdated($projectId: ID!) {
    ratecardUpdated(projectId: $projectId) {
      _id
      profile
      description
      dailyrate
      travelallowance
      changes {
        date
        user {
          firstname
          lastname
        }
        type
      }
    }
  }
`;

export const RATECARD_DELETED = gql`
  subscription ratecardDeleted($projectId: ID!) {
    ratecardDeleted(projectId: $projectId) {
      _id
    }
  }
`;

export const PRICE_ADDED = gql`
  subscription priceAdded($projectId: ID!, $type: String!) {
    priceAdded(projectId: $projectId, type: $type) {
      _id
      type
      price
      profile {
        _id
        profile
        description
        dailyrate
        travelallowance
      }
      days
      pricePerPiece
      amount
      unit
      calculation
      moduleName
      templateName
      changes {
        date
        type
      }
    }
  }
`;

export const PRICE_UPDATED = gql`
  subscription priceUpdated($projectId: ID!, $type: String!) {
    priceUpdated(projectId: $projectId, type: $type) {
      _id
      type
      price
      profile {
        _id
        profile
        description
        dailyrate
        travelallowance
      }
      days
      pricePerPiece
      amount
      unit
      calculation
      moduleName
      templateName
      changes {
        date
        type
      }
    }
  }
`;

export const PRICE_DELETED = gql`
  subscription priceDeleted($projectId: ID!, $type: String!) {
    priceDeleted(projectId: $projectId, type: $type) {
      _id
    }
  }
`;
