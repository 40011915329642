import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PROJECT, GET_TEMPLATES, GET_ADDITIONAL_HEADERS } from '../../graphql/queries';
import { ADD_TEMPLATE,
  DELETE_TEMPLATE, UPDATE_TEMPLATE, TEMPLATE_NUMBER_DOWN, TEMPLATE_NUMBER_UP, DELETE_PROJECT, UPDATE_PROJECT, COPY_PROJECT_TO_DATABASE, UPDATE_ROLES, PUBLISH_PROJECT } from '../../graphql/mutations';

import {
  selectProject,
  selectTemplate,
  selectSla,
  selectRatecard,
  selectTAndM,
  selectFixedPrice,
  selectPiecePrice,
  selectInformation,
  selectReference,
  selectSubcontractor,
  selectQAndA,
  // templateNumberDown,
  // templateNumberUp,
  // fetchAdditionalFilesIsShowing,
  // uploadProjectTemplateFile,
  // removeProjectTemplateUpload,
  // fetchTemplates,
  // addTemplate,
  // updateTemplate,
  // deleteTemplate,
} from '../../redux/actionCreators/projectAction';
import ProjectTemplatesTable from '../../components/ProjectManagement/ProjectTemplatesTable';
import Loading from '../../components/Elements/Loading';

function ProjectTemplatesContainer(props) {
  const {
    selectProject,
    selectedProject,
    selectTemplate,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectSubcontractor,
    selectQAndA,
    // templateNumberDown,
    // projects,
    // templates,
    // fetchTemplates,
    // addTemplate,
    // updateTemplate,
    // deleteTemplate,
    // loading,
    user,
    // uploadProjectTemplateFile,
    // removeProjectTemplateUpload,
    // templateNumberUp,
    // fetchAdditionalFilesIsShowing,
    // isShowing,
  } = props;
  // useEffect(() => {
  //   fetchTemplates(selectedProject);
  // }, [fetchTemplates, selectedProject]);

  const { loading, error, data } = useQuery(GET_TEMPLATES, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading2, error: error2, data: data2 } = useQuery(GET_ADDITIONAL_HEADERS, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const [addTemplate] = useMutation(ADD_TEMPLATE, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });
  const [deleteTemplate] = useMutation(DELETE_TEMPLATE, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });
  const [updateTemplate] = useMutation(UPDATE_TEMPLATE, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });
  const [templateNumberDown] = useMutation(TEMPLATE_NUMBER_DOWN, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });
  const [templateNumberUp] = useMutation(TEMPLATE_NUMBER_UP, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });
  const [deleteProject] = useMutation(DELETE_PROJECT, {
    onCompleted: () => {
      selectProject('');
    },
  });
  const [copyProjectToDatabase] = useMutation(COPY_PROJECT_TO_DATABASE);
  const [publishProject] = useMutation(PUBLISH_PROJECT);
  const [updateProject] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [{
      query: GET_PROJECT,
      variables: { projectId: selectedProject },
    }],
  });
  const [updateRoles] = useMutation(UPDATE_ROLES, {
    refetchQueries: [{
      query: GET_PROJECT,
      variables: { projectId: selectedProject },
    }],
  });

  if (loading || loading1 || loading2) return <Loading />;
  if (error || error1 || error2) {
    return (
      <p>
        Error :$
        {error + error1 + error2}
      </p>
    );
  }
  return (
    <ProjectTemplatesTable
      projects={data1.project}
      templates={data.templates}
      addTemplate={addTemplate}
      updateTemplate={updateTemplate}
      deleteTemplate={deleteTemplate}
      updateProject={updateProject}
      deleteProject={deleteProject}
      changePublishedProject={publishProject}
      copyProjectToDatabase={copyProjectToDatabase}
      updateRoles={updateRoles}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectSla={selectSla}
      selectRatecard={selectRatecard}
      selectTAndM={selectTAndM}
      selectFixedPrice={selectFixedPrice}
      selectPiecePrice={selectPiecePrice}
      selectInformation={selectInformation}
      selectReference={selectReference}
      selectSubcontractor={selectSubcontractor}
      selectQAndA={selectQAndA}
      loading={loading}
      user={user}
      // uploadProjectTemplateFile={uploadProjectTemplateFile}
      // removeProjectTemplateUpload={removeProjectTemplateUpload}
      templateNumberDown={templateNumberDown}
      templateNumberUp={templateNumberUp}
      // fetchAdditionalFilesIsShowing={fetchAdditionalFilesIsShowing}
      isShowing={data2.additionalHeaders}
    />
  );
}

const mapStateToProps = (state) => ({
  projects: state.project.projects,
  templates: state.project.templates,
  selectedProject: state.project.selectedProject,
  loading: state.project.loading,
  user: state.auth.user,
  isShowing: state.project.isShowing,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchTemplates: (projectId) => dispatch(fetchTemplates(projectId)),
  // addTemplate: (id, name) => dispatch(addTemplate(id, name)),
  // updateTemplate: (id, name) => dispatch(updateTemplate(id, name)),
  // deleteTemplate: (id) => dispatch(deleteTemplate(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  // uploadProjectTemplateFile: (formData, config) =>
  //   dispatch(uploadProjectTemplateFile(formData, config)),
  // removeProjectTemplateUpload: (id) =>
  //   dispatch(removeProjectTemplateUpload(id)),
  selectSla: (id) => dispatch(selectSla(id)),
  selectRatecard: (id) => dispatch(selectRatecard(id)),
  selectTAndM: (id) => dispatch(selectTAndM(id)),
  selectFixedPrice: (id) => dispatch(selectFixedPrice(id)),
  selectPiecePrice: (id) => dispatch(selectPiecePrice(id)),
  selectInformation: (id) => dispatch(selectInformation(id)),
  selectReference: (id) => dispatch(selectReference(id)),
  selectSubcontractor: (id) => dispatch(selectSubcontractor(id)),
  selectQAndA: (id) => dispatch(selectQAndA(id)),
  // templateNumberDown: (id) => dispatch(templateNumberDown(id)),
  // templateNumberUp: (id) => dispatch(templateNumberUp(id)),
  // fetchAdditionalFilesIsShowing: (projectId) =>
  //   dispatch(fetchAdditionalFilesIsShowing(projectId)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectTemplatesContainer),
);

ProjectTemplatesContainer.propTypes = {
  // fetchTemplates: PropTypes.func.isRequired,
  // addTemplate: PropTypes.func.isRequired,
  // updateTemplate: PropTypes.func.isRequired,
  // deleteTemplate: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectSla: PropTypes.func.isRequired,
  selectRatecard: PropTypes.func.isRequired,
  selectTAndM: PropTypes.func.isRequired,
  selectFixedPrice: PropTypes.func.isRequired,
  selectPiecePrice: PropTypes.func.isRequired,
  selectInformation: PropTypes.func.isRequired,
  selectReference: PropTypes.func.isRequired,
  selectSubcontractor: PropTypes.func.isRequired,
  selectQAndA: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  user: PropTypes.shape(Object).isRequired,
  // templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  // uploadProjectTemplateFile: PropTypes.func.isRequired,
  // removeProjectTemplateUpload: PropTypes.func.isRequired,
  // templateNumberDown: PropTypes.func.isRequired,
  // templateNumberUp: PropTypes.func.isRequired,
  // fetchAdditionalFilesIsShowing: PropTypes.func.isRequired,
  // isShowing: PropTypes.arrayOf(PropTypes.string).isRequired,
};
