import React from 'react';
import NumberFormat from 'react-number-format';

function LocalNumberFormat(props) {
  const { ...other } = props;

  return (
    <NumberFormat
      {...other}
      displayType="text"
      thousandSeparator="."
      decimalSeparator=","
    />
  );
}

export default LocalNumberFormat;
