import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PROJECTS } from '../../graphql/queries';
import { DELETE_PROJECT, UPDATE_ROLES, PUBLISH_OFFER } from '../../graphql/mutations';

// import {
//   PROJECT_ADDED,
//   PROJECT_DELETED,
//   PROJECT_UPDATED,
// } from '../../graphql/subscriptions';

import {
  // fetchProjects,
  selectProject,
  // deleteOffering,
  // publishOffering,
} from '../../redux/actionCreators/offeringAction';
import Loading from '../../components/Elements/Loading';
import OfferingProjectsTable from '../../components/OfferingManagement/OfferingProjectsTable';

function OfferingProjectsContainer(props) {
  const {
    selectProject,
    // user,
  } = props;
  const projectType = 'offer';
  const { loading, error, data } = useQuery(GET_PROJECTS, {
    variables: { type: projectType },
    pollInterval: 500,
  });
  const [deleteProject] = useMutation(DELETE_PROJECT, {
    refetchQueries: [{
      query: GET_PROJECTS,
      variables: { type: projectType },
    }],
  });
  const [publishOffer] = useMutation(PUBLISH_OFFER, {
    refetchQueries: [{
      query: GET_PROJECTS,
      variables: { type: 'offer' },
    }],
  });
  const [updateRoles] = useMutation(UPDATE_ROLES, {
    refetchQueries: [{
      query: GET_PROJECTS,
      variables: { type: projectType },
    }],
  });

  if (loading) return <Loading />;
  if (error) {
    return (
      <p>
        Error :$
        {error.message}
      </p>
    );
  }

  return (
    <OfferingProjectsTable
      projects={data.projects}
      selectProject={selectProject}
      // user={user}
      loading={loading}
      deleteOffering={deleteProject}
      publishOffering={publishOffer}
      updateRoles={updateRoles}
    />
  );
}

const mapStateToProps = (state) => ({
  projects: state.offering.projects,
  loading: state.offering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchProjects: () => dispatch(fetchProjects()),
  selectProject: (id) => dispatch(selectProject(id)),
  // deleteOffering: (id) => dispatch(deleteOffering(id)),
  // publishOffering: (id) => dispatch(publishOffering(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferingProjectsContainer),
);

OfferingProjectsContainer.propTypes = {
  // fetchProjects: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  // loading: PropTypes.bool.isRequired,
  // user: PropTypes.shape(Object).isRequired,
  // projects: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // deleteOffering: PropTypes.func.isRequired,
  // publishOffering: PropTypes.func.isRequired,
};
