import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import TableEditIconButton from '../Buttons/TableEditIconButton';

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: grey[300],
  },
  submitButton: {
    color: blue[300],
  },
}));

export default function EditPasswordDialog(props) {
  const { oldData, header, onClick } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  // const inputRef = React.useRef();

  const formik = useFormik({
    initialValues: {
      input: oldData,
      inputRepeat: '',
      inputOldPassword: '',
    },
    validationSchema: Yup.object({
      inputOldPassword: Yup.string().required('Darf nicht leer sein'),
      input: Yup.string()
        .min(8, 'Passwort muss mindestens 8 Zeichen haben')
        .notOneOf(
          [Yup.ref('inputOldPassword'), null],
          'Das neue Passwort darf nicht das alte Passwort sein',
        )
        .required('Darf nicht leer sein'),
      inputRepeat: Yup.string().oneOf(
        [Yup.ref('input'), null],
        'Passwörter müssen übereinstimmen',
      ),
    }),
    onSubmit: (values) => {
      onClick(values.input, values.inputOldPassword);
      setOpen(false);
      formik.values.input = '';
      formik.values.inputRepeat = '';
      formik.values.inputOldPassword = '';
    },
  });

  const handleClickOpen = () => {
    formik.values.input = oldData;
    setOpen(true);
    // timeout to select textfield
    const timeout = setTimeout(() => {
      // inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  };

  const handleClose = () => {
    setOpen(false);
    formik.values.input = '';
    formik.values.inputRepeat = '';
    formik.values.inputOldPassword = '';
  };

  return (
    <div>
      <TableEditIconButton onClick={handleClickOpen} />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          Bitte geben Sie ihr neues Passwort ein. Das Passwort wird direkt
          übernommen und kann bei Ihrer nächsten Anmeldung verwendet werden.
          <form onSubmit={formik.handleSubmit}>
            <TextField
              id="inputOldPassword"
              name="inputOldPassword"
              type={header.includes('Passwort') ? 'password' : 'text'}
              label="Altes Passwort"
              multiline={oldData.length > 75}
              rows={8}
              fullWidth
              margin="normal"
              value={formik.values.inputOldPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                !!(
                  formik.touched.inputOldPassword
                  && formik.errors.inputOldPassword
                )
              }
              helperText={
                formik.touched.inputOldPassword
                && formik.errors.inputOldPassword
              }
            />
            <TextField
              id="input"
              name="input"
              type={header.includes('Passwort') ? 'password' : 'text'}
              label="Neues Passwort"
              multiline={oldData.length > 75}
              rows={8}
              fullWidth
              margin="normal"
              value={formik.values.input}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!(formik.touched.input && formik.errors.input)}
              helperText={formik.touched.input && formik.errors.input}
            />
            <TextField
              id="inputRepeat"
              name="inputRepeat"
              type={header.includes('Passwort') ? 'password' : 'text'}
              label="Neues Passwort wiederholen"
              multiline={oldData.length > 75}
              rows={8}
              fullWidth
              margin="normal"
              value={formik.values.inputRepeat}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                !!(formik.touched.inputRepeat && formik.errors.inputRepeat)
              }
              helperText={
                formik.touched.inputRepeat && formik.errors.inputRepeat
              }
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={handleClose}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            className={classes.submitButton}
            onClick={formik.handleSubmit}
            color="primary"
            autoFocus
          >
            Ändern
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditPasswordDialog.propTypes = {
  oldData: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
