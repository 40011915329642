import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import TableUpButton from '../Buttons/TableUpButton';
import TableDownButton from '../Buttons/TableDownButton';
import AddDialog from '../Dialogs/AddDialog';
import EditDialog from '../Dialogs/EditDialog';
import DeleteDialog from '../Dialogs/DeleteDialog';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function ProjectInformationTable(props) {
  const {
    information,
    projects,
    addProjectInformation,
    updateProjectInformation,
    deleteProjectInformation,
    selectInformation,
    // selectProject,
    selectedProject,
    type,
    // user,
    updateInformationNumber,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'number',
      label: '#',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { width: '50px' } }),
        customBodyRender: (value, tableMeta) => (
          <div>
            {value !== 1 && !projects.published && projects.authorization !== 'read' && (
              <TableUpButton
                onClick={() => updateInformationNumber({ variables: { projectId: projects._id, informationId: tableMeta.rowData[0], newNumber: tableMeta.rowData[1] - 1 } })}
              />
            )}
            {value}
            {value !== information.length && !projects.published && projects.authorization !== 'read' && (
            <TableDownButton
              onClick={() => updateInformationNumber({ variables: { projectId: projects._id, informationId: tableMeta.rowData[0], newNumber: tableMeta.rowData[1] + 1 } })}
            />
            )}
          </div>
        ),
      },
    },
    {
      name: 'question',
      label: 'Frage',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'answer',
      label: 'Antwort',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        display: !projects.published && projects.authorization !== 'read',
        viewColumns: !projects.published && projects.authorization !== 'read',
        customBodyRender: (value, tableMeta) => (
          <div>
            <EditDialog
              informationId={tableMeta.rowData[0]}
              projectId={projects._id}
              oldData={tableMeta.rowData[2]}
              header="Informationsfrage bearbeiten"
              text="Frage"
              field="question"
              onClick={updateProjectInformation}
            />
            <DeleteDialog
              header="Informationsfrage löschen"
              text="Möchten Sie die Informationsfrage wirklich löschen?"
              onClick={() => deleteProjectInformation({
                variables: { projectId: projects._id, informationId: tableMeta.rowData[0] },
              })}
            />
          </div>
        ),
      },
    },
  ];

  const data = [];

  information.forEach((info) => {
    data.push({
      _id: info._id,
      number: info.number,
      question: info.question,
      answer: 'Wird vom Anbieter beantwortet.',
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectInformation('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageproject"
                onClick={() => selectInformation('')}
              >
                Übersicht
              </Link>
              <Typography color="textPrimary">Informationen</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions()}
      />
      {(!projects.published && projects.authorization !== 'read') && (
        <AddDialog
          projectId={selectedProject}
          type={type}
          field="question"
          header="Informationsfrage hinzufügen"
          text="Frage"
          onClick={addProjectInformation}
        />
      )}
    </div>
  );
}

export default ProjectInformationTable;

ProjectInformationTable.propTypes = {
  information: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
  addProjectInformation: PropTypes.func.isRequired,
  updateProjectInformation: PropTypes.func.isRequired,
  deleteProjectInformation: PropTypes.func.isRequired,
  selectInformation: PropTypes.func.isRequired,
  // selectProject: PropTypes.func.isRequired,
  // selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  updateInformationNumber: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
