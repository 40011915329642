import { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withSnackbar } from 'notistack';
import { removeSnackbar } from '../../redux/actionCreators/notifierAction';

class Notifier extends Component {
  constructor(props) {
    super(props);
    this.displayed = [];
  }

  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach(
      ({ key, message, options = {}, dismissed = false }) => {
        if (dismissed) {
          this.props.closeSnackbar(key);
          return;
        }
        // Do nothing if snackbar is already displayed
        if (this.displayed.includes(key)) return;
        // Display snackbar using notistack
        this.props.enqueueSnackbar(message, {
          key,
          ...options,
          onClose: (event, reason, key) => {
            if (options.onClose) {
              options.onClose(event, reason, key);
            }
          },
          TransitionProps: (event, key) => {
            this.props.removeSnackbar(key);
            this.removeDisplayed(key);
          },
        });
        // Keep track of snackbars that we've displayed
        this.storeDisplayed(key);
      },
    );
  }

  removeDisplayed(id) {
    this.displayed = this.displayed.filter((key) => id !== key);
  }

  storeDisplayed(id) {
    this.displayed = [...this.displayed, id];
  }

  render() {
    return null;
  }
}

const mapStateToProps = (store) => ({
  notifications: store.notifier.notifications,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ removeSnackbar }, dispatch);

export default withSnackbar(
  connect(mapStateToProps, mapDispatchToProps)(Notifier),
);

Notifier.propTypes = {
  notifications: PropTypes.arrayOf(PropTypes.shape(Object)),
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  removeSnackbar: PropTypes.func,
};
