import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PROJECT, GET_TEMPLATES, GET_ADDITIONAL_HEADERS } from '../../graphql/queries';
import { ADD_TEMPLATE, DELETE_TEMPLATE, UPDATE_TEMPLATE, TEMPLATE_NUMBER_DOWN, TEMPLATE_NUMBER_UP } from '../../graphql/mutations';

import {

  selectProject,
  selectTemplate,
  selectSla,
  selectRatecard,
  selectTAndM,
  selectFixedPrice,
  selectPiecePrice,
  selectInformation,
  selectReference,
  selectSubcontractor,
  // fetchTemplates,
  // addTemplate,
  // addTemplateSuccess,
  // updateTemplate,
  // deleteTemplate,
  // changeActiveTemplate,
  // uploadDatabaseTemplateFile,
  // removeDatabaseTemplateUpload,
  // templateNumberDown,
  // templateNumberUp,
  // fetchAdditionalFilesIsShowing,
} from '../../redux/actionCreators/databaseAction';
import DatabaseTemplatesTable from '../../components/DatabaseManagement/DatabaseTemplatesTable';
import Loading from '../../components/Elements/Loading';

function DatabaseTemplatesContainer(props) {
  const {
    selectProject,
    selectedProject,
    selectTemplate,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectSubcontractor,
    // changeActiveTemplate,
    // uploadDatabaseTemplateFile,
    // removeDatabaseTemplateUpload,
    // templateNumberDown,
    // templateNumberUp,
    // fetchAdditionalFilesIsShowing,
    // isShowing,
  } = props;

  const { loading, error, data } = useQuery(GET_TEMPLATES, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading2, error: error2, data: data2 } = useQuery(GET_ADDITIONAL_HEADERS, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const [addTemplate] = useMutation(ADD_TEMPLATE, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });
  const [deleteTemplate] = useMutation(DELETE_TEMPLATE, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });
  const [updateTemplate] = useMutation(UPDATE_TEMPLATE, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });
  const [templateNumberDown] = useMutation(TEMPLATE_NUMBER_DOWN, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });
  const [templateNumberUp] = useMutation(TEMPLATE_NUMBER_UP, {
    refetchQueries: [{
      query: GET_TEMPLATES,
      variables: { projectId: selectedProject },
    }],
  });

  if (loading || loading1 || loading2) return <Loading />;
  if (error || error1 || error2) {
    return (
      <p>
        Error :$
        {error + error1 + error2}
      </p>
    );
  }
  return (
    <DatabaseTemplatesTable
      projects={data1.project}
      templates={data.templates}
      addTemplate={addTemplate}
      updateTemplate={updateTemplate}
      deleteTemplate={deleteTemplate}
      // changeActiveTemplate={changeActiveTemplate}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectSla={selectSla}
      selectRatecard={selectRatecard}
      selectTAndM={selectTAndM}
      selectFixedPrice={selectFixedPrice}
      selectPiecePrice={selectPiecePrice}
      selectInformation={selectInformation}
      selectReference={selectReference}
      selectSubcontractor={selectSubcontractor}
      loading={loading}
      // uploadDatabaseTemplateFile={uploadDatabaseTemplateFile}
      // removeDatabaseTemplateUpload={removeDatabaseTemplateUpload}
      templateNumberDown={templateNumberDown}
      templateNumberUp={templateNumberUp}
      isShowing={data2.additionalHeaders}
    />
  );
}

const mapStateToProps = (state) => ({
  projects: state.database.projects,
  templates: state.database.templates,
  selectedProject: state.database.selectedProject,
  loading: state.database.loading,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchTemplates: (projectId) => dispatch(fetchTemplates(projectId)),
  // addTemplate: (id, name) => dispatch(addTemplate(id, name)),
  // addTemplateSuccess: (data) => dispatch(addTemplateSuccess(data)),
  // updateTemplate: (id, name) => dispatch(updateTemplate(id, name)),
  // deleteTemplate: (id) => dispatch(deleteTemplate(id)),
  // changeActiveTemplate: (id) => dispatch(changeActiveTemplate(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  // uploadDatabaseTemplateFile: (formData, config) =>
  //   dispatch(uploadDatabaseTemplateFile(formData, config)),
  // removeDatabaseTemplateUpload: (id) =>
  //   dispatch(removeDatabaseTemplateUpload(id)),
  selectSla: (id) => dispatch(selectSla(id)),
  selectRatecard: (id) => dispatch(selectRatecard(id)),
  selectTAndM: (id) => dispatch(selectTAndM(id)),
  selectFixedPrice: (id) => dispatch(selectFixedPrice(id)),
  selectPiecePrice: (id) => dispatch(selectPiecePrice(id)),
  selectInformation: (id) => dispatch(selectInformation(id)),
  selectReference: (id) => dispatch(selectReference(id)),
  selectSubcontractor: (id) => dispatch(selectSubcontractor(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DatabaseTemplatesContainer),
);

DatabaseTemplatesContainer.propTypes = {
  // changeActiveTemplate: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectSla: PropTypes.func.isRequired,
  selectRatecard: PropTypes.func.isRequired,
  selectTAndM: PropTypes.func.isRequired,
  selectFixedPrice: PropTypes.func.isRequired,
  selectPiecePrice: PropTypes.func.isRequired,
  selectInformation: PropTypes.func.isRequired,
  selectReference: PropTypes.func.isRequired,
  selectSubcontractor: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // uploadDatabaseTemplateFile: PropTypes.func.isRequired,
  // removeDatabaseTemplateUpload: PropTypes.func.isRequired,
};
