import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Grid, Typography } from '@material-ui/core';
import ResponsiveDiv from '../Elements/ResponsiveDiv';
import Footer from '../Elements/Footer';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '80%',
    margin: 'auto',
    paddingTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    height: '100%',
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function PrivacyPage() {
  const classes = useStyles();
  return (
    <ResponsiveDiv>
      <div className={classes.root}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper variant="outlined" className={classes.paper}>
              <Typography variant="h5">Datenschutz</Typography>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper variant="outlined" className={classes.paper} style={{ textAlign: 'left' }}>
              <Typography variant="h5">Datenschutzerklärung</Typography>
              <Typography variant="body1">
                Diese Datenschutzerklärung klärt Sie über die Art, den Umfang und Zweck der Verarbeitung von personenbezogenen Daten (nachfolgend kurz „Daten“)
                innerhalb unseres Onlineangebotes und der mit ihm verbundenen Webseiten, Funktionen und Inhalte sowie externen Onlinepräsenzen, wie z.B.
                unser Social Media Profile auf (nachfolgend gemeinsam bezeichnet als „Onlineangebot“). Im Hinblick auf die verwendeten Begrifflichkeiten,
                wie z.B. „Verarbeitung“ oder „Verantwortlicher“ verweisen wir auf die Definitionen im Art. 4 der Datenschutzgrundverordnung (DSGVO).
              </Typography>

              <Typography variant="h5">
                Kategorien betroffener Personen
              </Typography>
              <Typography variant="body1">
                Besucher und Nutzer des Onlineangebotes (Nachfolgend bezeichnen wir die betroffenen Personen zusammenfassend auch als „Nutzer“).
              </Typography>
              <Typography variant="h5">
                Zweck der Verarbeitung
              </Typography>
              <Typography variant="body1">
                - Zurverfügungstellung des Onlineangebotes, seiner Funktionen und Inhalte.
              </Typography>
              <Typography variant="body1">
                - Beantwortung von Kontaktanfragen und Kommunikation mit Nutzern.
              </Typography>
              <Typography variant="body1">
                - Sicherheitsmaßnahmen.
              </Typography>
              <Typography variant="body1">
                - Reichweitenmessung/Marketing.
              </Typography>
              <Typography variant="h5">
                Verwendete Begrifflichkeiten
              </Typography>
              <Typography variant="body1">
                „Personenbezogene Daten“ sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person (im Folgenden „betroffene Person“) beziehen; als identifizierbar wird eine natürliche Person angesehen, die direkt oder indirekt, insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu Standortdaten, zu einer Online-Kennung (z.B. Cookie) oder zu einem oder mehreren besonderen Merkmalen identifiziert werden kann, die Ausdruck der physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser natürlichen Person sind.
              </Typography>
              <Typography variant="body1">
                „Verarbeitung“ ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche Vorgangsreihe
                im Zusammenhang mit personenbezogenen Daten. Der Begriff reicht weit und umfasst praktisch jeden Umgang mit Daten.
              </Typography>
              <Typography variant="body1">
                „Pseudonymisierung“ die Verarbeitung personenbezogener Daten in einer Weise, dass die personenbezogenen Daten ohne
                Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden können,
                sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen Maßnahmen
                unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder identifizierbaren natürlichen Person zugewiesen werden.
              </Typography>
              <Typography variant="body1">
                „Profiling“ jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese personenbezogenen Daten verwendet werden,
                um bestimmte persönliche Aspekte, die sich auf eine natürliche Person beziehen, zu bewerten, insbesondere um Aspekte bezüglich Arbeitsleistung,
                wirtschaftliche Lage, Gesundheit, persönliche Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen
                Person zu analysieren oder vorherzusagen.
              </Typography>
              <Typography variant="body1">
                Als „Verantwortlicher“ wird die natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die allein oder
                gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten entscheidet, bezeichnet.
                „Auftragsverarbeiter“ eine natürliche oder juristische Person,
                Behörde, Einrichtung oder andere Stelle, die personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.
              </Typography>

              <Typography variant="h5">
                Maßgebliche Rechtsgrundlagen
              </Typography>
              <Typography variant="body1">
                Nach Maßgabe des Art. 13 DSGVO teilen wir Ihnen die Rechtsgrundlagen unserer Datenverarbeitungen mit.
                Sofern die Rechtsgrundlage in der Datenschutzerklärung nicht genannt wird, gilt Folgendes: Die Rechtsgrundlage für die Einholung von Einwilligungen ist Art. 6 Abs. 1 lit. a und Art. 7 DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer Leistungen und Durchführung vertraglicher Maßnahmen sowie Beantwortung von Anfragen ist Art. 6 Abs. 1 lit. b DSGVO, die Rechtsgrundlage für die Verarbeitung zur Erfüllung unserer rechtlichen Verpflichtungen ist Art. 6 Abs. 1 lit. c DSGVO, und die Rechtsgrundlage für die Verarbeitung zur Wahrung unserer berechtigten Interessen ist Art. 6 Abs. 1 lit. f DSGVO. Für den Fall, dass lebenswichtige Interessen der betroffenen Person oder einer anderen natürlichen Person eine Verarbeitung personenbezogener Daten erforderlich machen, dient Art. 6 Abs. 1 lit. d DSGVO als Rechtsgrundlage.
              </Typography>

              <Typography variant="h5">
                Sicherheitsmaßnahmen
              </Typography>
              <Typography variant="body1">
                Wir treffen nach Maßgabe des Art. 32 DSGVO unter Berücksichtigung des Stands der Technik, der Implementierungskosten und der Art,
                des Umfangs, der Umstände und der Zwecke der Verarbeitung sowie der unterschiedlichen Eintrittswahrscheinlichkeit und Schwere des
                Risikos für die Rechte und Freiheiten natürlicher Personen, geeignete technische und organisatorische Maßnahmen, um ein dem Risiko
                angemessenes Schutzniveau zu gewährleisten. Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit
                von Daten durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der
                Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und
                Reaktion auf Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung, bzw.
                Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche
                Voreinstellungen (Art. 25 DSGVO).
              </Typography>

              <Typography variant="body1">
                Zu den Maßnahmen gehören insbesondere die Sicherung der Vertraulichkeit, Integrität und Verfügbarkeit
                von Daten durch Kontrolle des physischen Zugangs zu den Daten, als auch des sie betreffenden Zugriffs, der Eingabe, Weitergabe, der Sicherung der
                Verfügbarkeit und ihrer Trennung. Des Weiteren haben wir Verfahren eingerichtet, die eine Wahrnehmung von Betroffenenrechten, Löschung von Daten und
                Reaktion auf Gefährdung der Daten gewährleisten. Ferner berücksichtigen wir den Schutz personenbezogener Daten bereits bei der Entwicklung, bzw.
                Auswahl von Hardware, Software sowie Verfahren, entsprechend dem Prinzip des Datenschutzes durch Technikgestaltung und durch datenschutzfreundliche
                Voreinstellungen (Art. 25 DSGVO).
              </Typography>

              <Typography variant="h5">Zusammenarbeit mit Auftragsverarbeitern und Dritten</Typography>
              <Typography variant="body1">
                Sofern wir im Rahmen unserer Verarbeitung Daten gegenüber anderen Personen und Unternehmen (Auftragsverarbeitern oder Dritten) offenbaren,
                sie an diese übermitteln oder ihnen sonst Zugriff auf die Daten gewähren, erfolgt dies nur auf Grundlage einer gesetzlichen Erlaubnis
                (z.B. wenn eine Übermittlung der Daten an Dritte, wie an Zahlungsdienstleister, gem. Art. 6 Abs. 1 lit. b DSGVO zur Vertragserfüllung erforderlich ist),
                Sie eingewilligt haben, eine rechtliche Verpflichtung dies vorsieht oder auf Grundlage unserer berechtigten Interessen (z.B. beim Einsatz von Beauftragten,
                Webhostern, etc.). Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies auf Grundlage des Art. 28 DSGVO.
              </Typography>
              <Typography variant="body1">
                Sofern wir Dritte mit der Verarbeitung von Daten auf Grundlage eines sog. „Auftragsverarbeitungsvertrages“ beauftragen, geschieht dies auf Grundlage des Art. 28 DSGVO.
              </Typography>

              <Typography variant="h5">
                Übermittlungen in Drittländer
              </Typography>
              <Typography variant="body1">
                Sofern wir Daten in einem Drittland (d.h. außerhalb der Europäischen Union (EU) oder des Europäischen Wirtschaftsraums (EWR))
                verarbeiten oder dies im Rahmen der Inanspruchnahme von Diensten Dritter oder Offenlegung, bzw. Übermittlung von Daten an Dritte geschieht,
                erfolgt dies nur, wenn es zur Erfüllung unserer (vor)vertraglichen Pflichten, auf Grundlage Ihrer Einwilligung, aufgrund einer rechtlichen Verpflichtung
                oder auf Grundlage unserer berechtigten Interessen geschieht. Vorbehaltlich gesetzlicher oder vertraglicher Erlaubnisse, verarbeiten oder lassen wir die
                Daten in einem Drittland nur beim Vorliegen der besonderen Voraussetzungen der Art. 44 ff. DSGVO verarbeiten. D.h. die Verarbeitung erfolgt z.B. auf
                Grundlage besonderer Garantien, wie der offiziell anerkannten Feststellung eines der EU entsprechenden Datenschutzniveaus (z.B. für die USA durch das „Privacy Shield“)
                oder Beachtung offiziell anerkannter spezieller vertraglicher Verpflichtungen (so genannte „Standardvertragsklauseln“).
              </Typography>

              <Typography variant="h5">
                Rechte der betroffenen Personen
              </Typography>
              <Typography variant="body1">
                Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob betreffende Daten verarbeitet werden und auf Auskunft über
                diese Daten sowie auf weitere Informationen und Kopie der Daten entsprechend Art. 15 DSGVO. Sie haben entsprechend.
                Art. 16 DSGVO das Recht, die Vervollständigung der Sie betreffenden Daten oder die Berichtigung der Sie betreffenden
                unrichtigen Daten zu verlangen. Sie haben nach Maßgabe des Art. 17 DSGVO das Recht zu verlangen, dass betreffende
                Daten unverzüglich gelöscht werden, bzw. alternativ nach Maßgabe des Art. 18 DSGVO eine Einschränkung der Verarbeitung der
                Daten zu verlangen. Sie haben das Recht zu verlangen, dass die Sie betreffenden Daten, die Sie uns bereitgestellt haben nach
                Maßgabe des Art. 20 DSGVO zu erhalten und deren Übermittlung an andere Verantwortliche zu fordern.
                Sie haben ferner gem. Art. 77 DSGVO das Recht, eine Beschwerde bei der zuständigen Aufsichtsbehörde einzureichen.
              </Typography>

              <Typography variant="h5">Widerrufsrecht</Typography>
              <Typography variant="body1">
                Sie haben das Recht, erteilte Einwilligungen gem. Art. 7 Abs. 3 DSGVO mit Wirkung für die Zukunft zu widerrufen
              </Typography>
              <Typography variant="h5">
                Widerspruchsrecht
              </Typography>
              <Typography variant="body1">
                Sie können der künftigen Verarbeitung der Sie betreffenden Daten nach Maßgabe des Art. 21 DSGVO jederzeit widersprechen.
                Der Widerspruch kann insbesondere gegen die Verarbeitung für Zwecke der Direktwerbung erfolgen.
              </Typography>

              <Typography variant="h5">
                Cookies und Widerspruchsrecht bei Direktwerbung
              </Typography>

              <Typography variant="body1">
                Als „Cookies“ werden kleine Dateien bezeichnet, die auf Rechnern der Nutzer gespeichert werden.
                Innerhalb der Cookies können unterschiedliche Angaben gespeichert werden. Ein Cookie dient primär dazu, die Angaben zu
                einem Nutzer (bzw. dem Gerät auf dem das Cookie gespeichert ist) während oder auch nach seinem Besuch innerhalb eines
                Onlineangebotes zu speichern. Als temporäre Cookies, bzw. „Session-Cookies“ oder „transiente Cookies“, werden Cookies
                bezeichnet, die gelöscht werden, nachdem ein Nutzer ein Onlineangebot verlässt und seinen Browser schließt.
                In einem solchen Cookie kann z.B. der Inhalt eines Warenkorbs in einem Onlineshop oder ein Login-Status gespeichert werden.
                Als „permanent“ oder „persistent“ werden Cookies bezeichnet, die auch nach dem Schließen des Browsers gespeichert bleiben.
                So kann z.B. der Login-Status gespeichert werden, wenn die Nutzer diese nach mehreren Tagen aufsuchen. Ebenso können in einem
                solchen Cookie die Interessen der Nutzer gespeichert werden, die für Reichweitenmessung oder Marketingzwecke verwendet werden.
                Als „Third-Party-Cookie“ werden Cookies bezeichnet, die von anderen Anbietern als dem Verantwortlichen, der das Onlineangebot
                betreibt, angeboten werden (andernfalls, wenn es nur dessen Cookies sind spricht man von „First-Party Cookies“).
                Wir können temporäre und permanente Cookies einsetzen und klären hierüber im Rahmen unserer Datenschutzerklärung auf.
                Falls die Nutzer nicht möchten, dass Cookies auf ihrem Rechner gespeichert werden, werden sie gebeten die entsprechende
                Option in den Systemeinstellungen ihres Browsers zu deaktivieren. Gespeicherte Cookies können in den Systemeinstellungen
                des Browsers gelöscht werden. Der Ausschluss von Cookies kann zu Funktionseinschränkungen dieses Onlineangebotes führen.
                Ein genereller Widerspruch gegen den Einsatz der zu Zwecken des Onlinemarketing eingesetzten Cookies kann bei einer Vielzahl
                der Dienste, vor allem im Fall des Trackings, über die US-amerikanische Seite http:/www.aboutads.info/choices/ oder die
                EU-Seite http:/www.youronlinechoice.com erklärt werden. Des Weiteren kann die Speicherung von Cookies mittels deren
                Abschaltung in den Einstellungen des Browsers erreicht werden. Bitte beachten Sie, dass dann gegebenenfalls nicht alle Funktionen dieses Onlineangebotes genutzt werden können.
              </Typography>

              <Typography variant="h5">Löschung von Daten</Typography>

              <Typography variant="body1">
                Die von uns verarbeiteten Daten werden nach Maßgabe der Art. 17 und 18 DSGVO gelöscht oder in ihrer Verarbeitung eingeschränkt.
                Sofern nicht im Rahmen dieser Datenschutzerklärung ausdrücklich angegeben, werden die bei uns gespeicherten Daten gelöscht,
                sobald sie für ihre Zweckbestimmung nicht mehr erforderlich sind und der Löschung keine gesetzlichen Aufbewahrungspflichten
                entgegenstehen. Sofern die Daten nicht gelöscht werden, weil sie für andere und gesetzlich zulässige Zwecke erforderlich sind,
                wird deren Verarbeitung eingeschränkt. D.h. die Daten werden gesperrt und nicht für andere Zwecke verarbeitet. Das gilt z.B.
                für Daten, die aus handels- oder steuerrechtlichen Gründen aufbewahrt werden müssen. Nach gesetzlichen Vorgaben in Deutschland,
                erfolgt die Aufbewahrung insbesondere für 10 Jahre gemäß §§ 147 Abs. 1 AO, 257 Abs. 1 Nr. 1 und 4, Abs. 4 HGB (Bücher,
                Aufzeichnungen, Lageberichte, Buchungsbelege, Handelsbücher, für Besteuerung relevanter Unterlagen, etc.) und 6 Jahre
                gemäß § 257 Abs. 1 Nr. 2 und 3, Abs. 4 HGB (Handelsbriefe). Nach gesetzlichen Vorgaben in Österreich erfolgt die Aufbewahrung
                insbesondere für 7 J gemäß § 132 Abs. 1 BAO (Buchhaltungsunterlagen, Belege/Rechnungen, Konten, Belege, Geschäftspapiere,
                Aufstellung der Einnahmen und Ausgaben, etc.), für 22 Jahre im Zusammenhang mit Grundstücken und für 10 Jahre bei Unterlagen
                im Zusammenhang mit elektronisch erbrachten Leistungen, Telekommunikations-, Rundfunk- und Fernsehleistungen,
                die an Nichtunternehmer in EU-Mitgliedstaaten erbracht werden und für die der Mini-One-Stop-Shop (MOSS) in Anspruchgenommen wird.
              </Typography>

              <Typography variant="h5">
                Administration, Finanzbuchhaltung, Büroorganisation, Kontaktverwaltung
              </Typography>
              <Typography variant="body1">
                Wir verarbeiten Daten im Rahmen von Verwaltungsaufgaben sowie Organisation unseres Betriebs, Finanzbuchhaltung und Befolgung
                der gesetzlichen Pflichten, wie z.B. der Archivierung. Hierbei verarbeiten wir dieselben Daten, die wir im Rahmen der
                Erbringung unserer vertraglichen Leistungen verarbeiten. Die Verarbeitungsgrundlagen sind Art. 6 Abs. 1 lit. c. DSGVO,
                Art. 6 Abs. 1 lit. f. DSGVO. Von der Verarbeitung sind Kunden, Interessenten, Geschäftspartner und Websitebesucher betroffen.
                Der Zweck und unser Interesse an der Verarbeitung liegt in der Administration, Finanzbuchhaltung, Büroorganisation,
                Archivierung von Daten, also Aufgaben die der Aufrechterhaltung unserer Geschäftstätigkeiten, Wahrnehmung unserer Aufgaben und
                Erbringung unserer Leistungen dienen. Die Löschung der Daten im Hinblick auf vertragliche Leistungen und die vertragliche
                Kommunikation entspricht den, bei diesen Verarbeitungstätigkeiten genannten Angaben. Wir offenbaren oder übermitteln hierbei
                Daten an die Finanzverwaltung, Berater, wie z.B., Steuerberater oder Wirtschaftsprüfer sowie weitere Gebührenstellen und
                Zahlungsdienstleister. Ferner speichern wir auf Grundlage unserer betriebswirtschaftlichen Interessen Angaben zu Lieferanten,
                Veranstaltern und sonstigen Geschäftspartnern,
                z.B. zwecks späterer Kontaktaufnahme. Diese mehrheitlich unternehmensbezogenen Daten, speichern wir grundsätzlich dauerhaft.
              </Typography>

              <Typography variant="h5">
                Datenschutzhinweise im Bewerbungsverfahren
              </Typography>

              <Typography variant="body1">
                Wir verarbeiten die Bewerberdaten nur zum Zweck und im Rahmen des Bewerbungsverfahrens im Einklang mit den gesetzlichen Vorgaben.
                Die Verarbeitung der Bewerberdaten erfolgt zur Erfüllung unserer (vor)vertraglichen Verpflichtungen im Rahmen des Bewerbungsverfahrens
                im Sinne des Art. 6 Abs. 1 lit. b. DSGVO Art. 6 Abs. 1 lit. f. DSGVO sofern die Datenverarbeitung z.B. im Rahmen von rechtlichen
                Verfahren für uns erforderlich wird (in Deutschland gilt zusätzlich § 26 BDSG). Das Bewerbungsverfahren setzt voraus, dass Bewerber uns
                die Bewerberdaten mitteilen. Die notwendigen Bewerberdaten sind, sofern wir ein Onlineformular anbieten gekennzeichnet, ergeben sich
                sonst aus den Stellenbeschreibungen und grundsätzlich gehören dazu die Angaben zur Person, Post- und Kontaktadressen und die zur
                Bewerbung gehörenden Unterlagen, wie Anschreiben, Lebenslauf und die Zeugnisse. Daneben können uns Bewerber freiwillig zusätzliche
                Informationen mitteilen. Mit der Übermittlung der Bewerbung an uns, erklären sich die Bewerber mit der Verarbeitung ihrer Daten zu
                Zwecken des Bewerbungsverfahrens entsprechend der in dieser Datenschutzerklärung dargelegten Art und Umfang einverstanden. Soweit im
                Rahmen des Bewerbungsverfahrens freiwillig besondere Kategorien von personenbezogenen Daten im Sinne des Art. 9 Abs. 1 DSGVO mitgeteilt
                werden, erfolgt deren Verarbeitung zusätzlich nach Art. 9 Abs. 2 lit. b DSGVO (z.B. Gesundheitsdaten, wie z.B. Schwerbehinderteneigenschaft
                oder ethnische Herkunft). Soweit im Rahmen des Bewerbungsverfahrens besondere Kategorien von personenbezogenen Daten im Sinne des
                Art. 9 Abs. 1 DSGVO bei Bewerbern angefragt werden, erfolgt deren Verarbeitung zusätzlich nach Art. 9 Abs. 2 lit. a DSGVO
                (z.B. Gesundheitsdaten, wenn diese für die Berufsausübung erforderlich sind). Sofern zur Verfügung gestellt, können uns Bewerber ihre
                Bewerbungen mittels eines Onlineformulars auf unserer Website übermitteln. Die Daten werden entsprechend dem Stand der Technikverschlüsselt an uns übertragen.
                Ferner können Bewerber uns ihre Bewerbungen via E-Mail übermitteln. Hierbei bitten wir jedoch zu beachten,
                dass E-Mails grundsätzlich nicht verschlüsselt versendet werden und die Bewerber selbst für die Verschlüsselung sorgen müssen.
                Wir können daher für den Übertragungsweg der Bewerbung zwischen dem Absender und dem Empfang auf unserem Server keine Verantwortung
                übernehmen und empfehlen daher eher ein Online-Formular oder den postalischen Versand zu nutzen. Denn statt der Bewerbung über das
                Online-Formular und E-Mail, steht den Bewerbern weiterhin die Möglichkeit zur Verfügung, uns die Bewerbung auf dem Postweg zuzusenden.
                Die von den Bewerbern zur Verfügung gestellten Daten, können im Fall einer erfolgreichen Bewerbung für die Zwecke des
                Beschäftigungsverhältnisses von uns weiterverarbeitet werden. Andernfalls, sofern die Bewerbung auf ein Stellenangebot nicht erfolgreich
                ist, werden die Daten der Bewerber gelöscht. Die Daten der Bewerber werden ebenfalls gelöscht, wenn eine Bewerbung zurückgezogen wird,
                wozu die Bewerber jederzeit berechtigt sind. Die Löschung erfolgt, vorbehaltlich eines berechtigten Widerrufs der Bewerber, nach dem
                Ablauf eines Zeitraums von vier Monaten, damit wir etwaige Anschlussfragen zu der Bewerbung beantworten und unseren Nachweispflichten aus
                dem Gleichbehandlungsgesetz genügen können. Rechnungen über etwaige Reisekostenerstattung werden entsprechend den steuerrechtlichenVorgaben archiviert.
              </Typography>

              <Typography variant="h5">
                Talent-Pool
              </Typography>

              <Typography variant="body1">
                Im Rahmen der Bewerbung bieten wir den Bewerbern die Möglichkeit an, in unseren „Talent-Pool“ für einen Zeitraum von zwei Jahren auf Grundlage einer Einwilligung im Sinne der
                Art. 6 Abs. 1 lit. b. und Art. 7 DSGVO aufgenommen zu werden. Die Bewerbungsunterlagen im Talent-Pool werden alleine im Rahmen von künftigen Stellenausschreibungen und der Beschäftigtensuche
                verarbeitet und werden spätestens nach Ablauf der Frist vernichtet. Die Bewerber werden darüber belehrt, dass deren Einwilligung in die Aufnahme in den Talent-Pool freiwillig ist,
                keinen Einfluss auf das aktuelle Bewerbungsverfahren hat und sie diese Einwilligung jederzeit für die Zukunft widerrufen sowie Widerspruch im Sinne des Art. 21 DSGVO erklären können.
              </Typography>

              <Typography variant="h5">
                Kontaktaufnahme
              </Typography>

              <Typography variant="body1">
                Bei der Kontaktaufnahme mit uns (z.B. per Kontaktformular, E-Mail, Telefon oder via sozialer Medien) werden die Angaben des Nutzers zur Bearbeitung der Kontaktanfrage und deren Abwicklung
                gem. Art. 6 Abs. 1 lit. b) DSGVO verarbeitet. Die Angaben der Nutzer können in einem Customer-Relationship-Management System („CRM System“) oder vergleichbarer Anfragenorganisation gespeichert werden.
                Wir löschen die Anfragen, sofern diese nicht mehr erforderlich sind. Wir überprüfen die Erforderlichkeit alle zwei Jahre; Ferner gelten die gesetzlichen Archivierungspflichten.
              </Typography>

              <Typography variant="h5">
                Hosting und E-Mail-Versand
              </Typography>

              <Typography variant="body1">
                Die von uns in Anspruch genommenen Hosting-Leistungen dienen der Zurverfügungstellung der folgenden Leistungen: Infrastruktur- und Plattformdienstleistungen, Rechenkapazität,
                Speicherplatz und Datenbankdienste, E-Mail-Versand, Sicherheitsleistungen sowie technische Wartungsleistungen, die wir zum Zwecke des Betriebs dieses Onlineangebotes einsetzen.
                Hierbei verarbeiten wir, bzw. unser Hostinganbieter Bestandsdaten, Kontaktdaten, Inhaltsdaten, Vertragsdaten, Nutzungsdaten, Meta- und Kommunikationsdaten von Kunden,
                Interessenten und Besuchern dieses Onlineangebotes auf Grundlage unserer berechtigten Interessen an einer effizienten und sicheren Zurverfügungstellung dieses Onlineangebotes gem. Art. 6 Abs. 1
                lit. f DSGVO i.V.m. Art. 28 DSGVO (Abschluss Auftragsverarbeitungsvertrag).
              </Typography>

              <Typography variant="h5">
                Erhebung von Zugriffsdaten und Logfiles
              </Typography>

              <Typography variant="body1">
                Wir, bzw. unser Hostinganbieter, erhebt auf Grundlage unserer berechtigten Interessen im Sinne des Art. 6 Abs. 1 lit. f. DSGVO Daten über jeden Zugriff auf den Server, auf dem sich dieser
                Dienst befindet (sogenannte Serverlogfiles). Zu den Zugriffsdaten gehören Name der abgerufenen Webseite, Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge,
                Meldung über erfolgreichen Abruf, Browsertyp nebst Version, das Betriebssystem des Nutzers, Referrer URL (die zuvor besuchte Seite), IP-Adresse und der anfragende Provider.
                Logfile-Informationen werden aus Sicherheitsgründen (z.B. zur Aufklärung von Missbrauchs- oder Betrugshandlungen) für die Dauer von maximal 7 Tagen gespeichert und danach gelöscht.
                Daten, deren weitere Aufbewahrung zu Beweiszwecken erforderlich ist, sind bis zur endgültigen Klärung des jeweiligen Vorfalls von der Löschung ausgenommen.
              </Typography>

              <Typography variant="h5">
                Google Fonts
              </Typography>

              <Typography variant="body1">
                Wir binden die Schriftarten („Google Fonts“) des Anbieters Google LLC, 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA, ein. Datenschutzerklärung:
                https://www.google.com/policies/privacy/, Opt-Out: https://adssettings.google.com/authenticated.
              </Typography>

              <Typography variant="body1">
                Responsible for the content:
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Paper variant="outlined" className={classes.paper} style={{ textAlign: 'left' }}>
              <Typography variant="h5">
                Verantwortlicher:
              </Typography>
              <Typography variant="body1">pelo IT GmbH</Typography>
              <Typography variant="body1">Bahnhofstraße 1</Typography>
              <Typography variant="body1">30159 Hannover</Typography>
              <Typography variant="body1">Telefon: 0511 - 84897730</Typography>

              <Typography variant="h5">
                Datenschutzbeauftragter:
              </Typography>
              <Typography variant="body1">List + Lohr Datenschutz und Informationssicherheit GmbH</Typography>
              <Typography variant="body1">Garvensstr. 4</Typography>
              <Typography variant="body1">30519 Hannover</Typography>
              <Typography variant="body1">E-Mail: datenschutz@pelo-it.com</Typography>
              <Typography variant="body1">Jede betroffene Person kann sich jederzeit bei allen Fragen und Anregungen zum Datenschutz direkt an unseren Datenschutzbeauftragten wenden.</Typography>
              <Typography variant="body1">Arten der verarbeiteten Daten:</Typography>
              <Typography variant="body1">- Bestandsdaten (z.B., Namen, Adressen).</Typography>
              <Typography variant="body1">- Kontaktdaten (z.B., E-Mail, Telefonnummern)</Typography>
              <Typography variant="body1">- Inhaltsdaten (z.B., Texteingaben, Fotografien, Videos).</Typography>
              <Typography variant="body1">- Nutzungsdaten (z.B., besuchte Webseiten, Interesse an Inhalten, Zugriffszeiten).</Typography>
              <Typography variant="body1">- Meta-/Kommunikationsdaten (z.B., Geräte-Informationen, IP-Adressen).</Typography>
            </Paper>
          </Grid>
        </Grid>
      </div>
      <Footer />
    </ResponsiveDiv>
  );
}

export default PrivacyPage;

PrivacyPage.propTypes = {};
