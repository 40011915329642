import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function NegotiationSubcontractorsTable(props) {
  const {
    selectSubcontractor,
    selectProject,
    selectTemplate,
    selectNegotiationSubcontractorCompany,
    subcontractors,
    projects,
    selectedSubcontractorCompany,
    // user,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'question',
      label: 'Frage',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'answer',
      label: 'Antwort',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const data = [];

  subcontractors
    .filter(
      (subcontractor) => subcontractor.cluster === selectedSubcontractorCompany,
    )
    .forEach((subcontractor) => {
      data.push({
        _id: subcontractor._id,
        question: subcontractor.question,
        answer: subcontractor.answer,
        company: subcontractor.company,
      });
    });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button
              size="small"
              onClick={() => {
                selectNegotiationSubcontractorCompany('');
              }}
            >
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/managenegotiation"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectSubcontractor('');
                  selectNegotiationSubcontractorCompany('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/managenegotiation"
                onClick={() => {
                  selectSubcontractor('');
                  selectNegotiationSubcontractorCompany('');
                }}
              >
                {projects.name}
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/managenegotiation"
                onClick={() => {
                  selectNegotiationSubcontractorCompany('');
                }}
              >
                Subkontraktoren
              </Link>
              <Typography color="textPrimary">
                {selectedSubcontractorCompany}
              </Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions()}
      />
    </div>
  );
}

export default NegotiationSubcontractorsTable;

NegotiationSubcontractorsTable.propTypes = {
  selectSubcontractor: PropTypes.func.isRequired,
  selectNegotiationSubcontractorCompany: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  subcontractors: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
  selectedSubcontractorCompany: PropTypes.string.isRequired,
};
