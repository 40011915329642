import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_INFORMATIONS, GET_PROJECT } from '../../graphql/queries';
import {
  ADD_INFORMATION,
  UPDATE_INFORMATION,
  DELETE_INFORMATION,
  UPDATE_INFORMATION_NUMBER,
} from '../../graphql/mutations';

import {
  // fetchProjectSubcontractors,
  // addProjectSubcontractor,
  // updateProjectSubcontractor,
  // deleteProjectSubcontractor,
  selectSubcontractor,
  selectProject,
  selectTemplate,
  // subcontractorNumberUp,
  // subcontractorNumberDown,
} from '../../redux/actionCreators/projectAction';
import ProjectSubcontractorsTable from '../../components/ProjectManagement/ProjectSubcontractorsTable';
import Loading from '../../components/Elements/Loading';

function ProjectSubcontractorsContainer(props) {
  const {
    // subcontractors,
    // projects,
    // fetchProjectSubcontractors,
    // addProjectSubcontractor,
    // updateProjectSubcontractor,
    // deleteProjectSubcontractor,
    // subcontractorNumberUp,
    // subcontractorNumberDown,
    selectSubcontractor,
    selectProject,
    selectTemplate,
    selectedProject,
    // loading,
    // user,
  } = props;
  // useEffect(() => {
  //   fetchProjectSubcontractors(selectedProject);
  // }, [fetchProjectSubcontractors, selectedProject]);

  const informationType = 'Subkontraktor';
  const cluster = '';
  const { loading, error, data } = useQuery(GET_INFORMATIONS, {
    variables: { projectId: selectedProject, type: informationType, cluster },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [addInformation] = useMutation(ADD_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [updateInformation] = useMutation(UPDATE_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [deleteInformation] = useMutation(DELETE_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [updateInformationNumber] = useMutation(UPDATE_INFORMATION_NUMBER, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  return (
    <ProjectSubcontractorsTable
      subcontractors={data.informations}
      projects={data1.project}
      addProjectSubcontractor={addInformation}
      updateProjectSubcontractor={updateInformation}
      deleteProjectSubcontractor={deleteInformation}
      updateInformationNumber={updateInformationNumber}
      selectSubcontractor={selectSubcontractor}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      // subcontractorNumberUp={subcontractorNumberUp}
      // subcontractorNumberDown={subcontractorNumberDown}
      loading={loading}
      // user={user}
      type={informationType}
      cluster={cluster}
    />
  );
}

const mapStateToProps = (state) => ({
  subcontractors: state.project.subcontractors,
  projects: state.project.projects,
  selectedProject: state.project.selectedProject,
  loading: state.project.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchProjectSubcontractors: (projectId) =>
  //   dispatch(fetchProjectSubcontractors(projectId)),
  // addProjectSubcontractor: (projectId, question) =>
  //   dispatch(addProjectSubcontractor(projectId, question)),
  // updateProjectSubcontractor: (id, question) =>
  //   dispatch(updateProjectSubcontractor(id, question)),
  // deleteProjectSubcontractor: (id) => dispatch(deleteProjectSubcontractor(id)),
  // subcontractorNumberUp: (id) => dispatch(subcontractorNumberUp(id)),
  // subcontractorNumberDown: (id) => dispatch(subcontractorNumberDown(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectSubcontractor: (id) => dispatch(selectSubcontractor(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectSubcontractorsContainer),
);

ProjectSubcontractorsContainer.propTypes = {
  // fetchProjectSubcontractors: PropTypes.func.isRequired,
  // addProjectSubcontractor: PropTypes.func.isRequired,
  // updateProjectSubcontractor: PropTypes.func.isRequired,
  // deleteProjectSubcontractor: PropTypes.func.isRequired,
  // subcontractorNumberUp: PropTypes.func.isRequired,
  // subcontractorNumberDown: PropTypes.func.isRequired,
  selectSubcontractor: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // subcontractors: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  // user: PropTypes.shape(Object).isRequired,
};
