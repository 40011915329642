import React/* ,  { useEffect } */ from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_USERS } from '../../graphql/queries';
import { UPDATE_USER, DELETE_USER } from '../../graphql/mutations';
import UserTable from '../../components/UserManagement/UserTable';
import Loading from '../../components/Elements/Loading';

function UserTableContainer() {
  const { loading, error, data } = useQuery(GET_USERS, {
    pollInterval: 500,
  });
  const [updateUser] = useMutation(UPDATE_USER, {
    refetchQueries: [{
      query: GET_USERS,
    }],
  });
  const [deleteUser] = useMutation(DELETE_USER, {
    refetchQueries: [{
      query: GET_USERS,
    }],
  });

  if (loading) return <Loading />;
  if (error) {
    return (
      <p>
        Error :$
        {error.message}
      </p>
    );
  }
  return (
    <UserTable
      users={data.users}
      deleteUser={deleteUser}
      updateUser={updateUser}
      loading={loading}
    />
  );
}

export default UserTableContainer;
