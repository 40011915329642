import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';

import TableSwitch from '../Buttons/TableSwitch';
import TableSelect from '../Buttons/TableSelect';
import DateStamp from '../Elements/DateStamp';
import DeleteDialog from '../Dialogs/DeleteDialog';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function UserTable(props) {
  const {
    users,
    updateUser,
    deleteUser,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'email',
      label: 'E-Mail',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'company',
      label: 'Unternehmen',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'position',
      label: 'Job Position',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'verified',
      label: 'Verifiziert',
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value) => {
          if (value) {
            return 'Ja';
          }
          return 'Nein';
        },
      },
    },
    {
      name: 'active',
      label: 'Aktiv',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <TableSwitch
            value={value}
            disabled={false}
            onChange={() => {
              updateUser({ variables: { id: tableMeta.rowData[0], active: !value } });
            }}
          />
        ),
      },
    },
    {
      name: 'role',
      label: 'Rolle',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          // to do: change to graphql
          <TableSelect
            id={tableMeta.rowData[0]}
            value={value}
            disabled={false}
            selects={['AG', 'AN', 'ITAG']}
            onChangeHandler={updateUser}
          />
        ),
      },
    },
    {
      name: 'admin',
      label: 'Admin',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <TableSwitch
            value={value}
            disabled={false}
            onChange={() => {
              updateUser({ variables: { id: tableMeta.rowData[0], admin: !value } });
            }}
          />
        ),
      },
    },
    {
      name: 'created',
      label: 'Erstellt',
      options: {
        filter: true,
        sort: true,
        display: false,
        customBodyRender: (value) => <DateStamp date={value} />,
      },
    },
    {
      name: 'lastSeen',
      label: 'Zuletzt eingeloggt',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (!value) return 'Bisher nicht eingeloggt';
          return <DateStamp date={value} />;
        },
      },
    },
    {
      name: 'projects',
      label: 'Projekte',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value.map((item) => <Typography variant="body2">{item}</Typography>),
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <DeleteDialog
            header="Nutzer löschen"
            text="Möchten Sie den Nutzer wirklich löschen?"
            onClick={() => deleteUser({ variables: { userId: tableMeta.rowData[0] } })}
          />
        ),
      },
    },
  ];

  const data = [];
  users
    // .filter((user) => activeUser.id !== user._id)
    .forEach((user) => {
      data.push({
        _id: user._id,
        email: user.email,
        name: user.name,
        company: user.company,
        position: user.position,
        active: user.active,
        role: user.role,
        admin: user.admin,
        verified: user.verified,
        created: user.created,
        lastSeen: user.lastSeen,
        projects: user.projects,
      });
    });

  return (
    <>
      <MUIDataTable
        title="Nutzer"
        data={data}
        columns={columns}
        options={FullsizeTableOptions()}
      />
    </>
  );
}

export default UserTable;

UserTable.propTypes = {
  users: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  updateUser: PropTypes.func.isRequired,
  deleteUser: PropTypes.func.isRequired,
};
