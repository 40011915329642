import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { orange, red } from '@material-ui/core/colors/';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';

import TableEditUsersButton from '../Buttons/TableEditUsersButton';

const useStyles = makeStyles((theme) => ({
  list: {
    height: '200px',
    overflowY: 'scroll',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  textField: {
    width: '90%',
  },
  submitButton: {
    color: orange[300],
  },
  deleteButton: {
    color: red[300],
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function ChangeOfferingUsersDialog(props) {
  const {
    projectId,
    open,
    setOpen,
    projectEditors,
    projectViewers,
    userEmail,
    updateRoles,
  } = props;

  const classes = useStyles();

  const [editorsEmail, setEditorsEmail] = useState('');
  const [viewersEmail, setViewersEmail] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddEditor = () => {
    updateRoles({ variables: { projectId, email: editorsEmail, action: 'ADD', type: 'editor' } });
    setEditorsEmail('');
  };

  const handleRemoveEditor = (email) => {
    updateRoles({ variables: { projectId, email, action: 'REMOVE', type: 'editor' } });
  };

  const handleAddViewer = () => {
    updateRoles({ variables: { projectId, email: viewersEmail, action: 'ADD', type: 'viewer' } });
    setViewersEmail('');
  };

  const handleRemoveViewer = (email) => {
    updateRoles({ variables: { projectId, email, action: 'REMOVE', type: 'viewer' } });
  };

  return (
    <>
      <TableEditUsersButton onClick={handleClickOpen} />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Projekt-Teilnehmer bearbeiten
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs>
              <List
                className={classes.list}
                component="nav"
                subheader={
                  <ListSubheader component="div">Bearbeiter</ListSubheader>
                }
              >
                {Array.isArray(projectEditors) && projectEditors.map((editor) => (
                  <ListItem key={editor.user._id}>
                    <ListItemText primary={editor.user.email} />
                    {editor.user.email !== userEmail ? (
                      <ListItemSecondaryAction>
                        <IconButton
                          aria-label="Delete"
                          className={classes.deleteButton}
                          onClick={() => handleRemoveEditor(editor.user.email)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    ) : (
                      <div key={editor.user._id} />
                    )}
                  </ListItem>
                ))}
              </List>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <TextField
                  className={classes.textField}
                  id="editorsEmail"
                  name="editorsEmail"
                  label="E-Mail"
                  margin="normal"
                  value={editorsEmail}
                  onChange={(e) => setEditorsEmail(e.target.value.toLowerCase())}
                />
              </form>
              <Button
                className={classes.submitButton}
                color="primary"
                onClick={handleAddEditor}
              >
                Hinzufügen
              </Button>
            </Grid>
            <Grid item xs>
              <List
                className={classes.list}
                component="nav"
                subheader={<ListSubheader component="div">Leser</ListSubheader>}
              >
                {Array.isArray(projectViewers) && projectViewers.map((viewer) => (
                  <ListItem key={viewer.user._id}>
                    <ListItemText primary={viewer.user.email} />
                    {viewer.user.email !== userEmail ? (
                      <ListItemSecondaryAction>
                        <IconButton
                          aria-label="Delete"
                          className={classes.deleteButton}
                          onClick={() => handleRemoveViewer(viewer.user.email)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    ) : (
                      <div key={viewer.user._id} />
                    )}
                  </ListItem>
                ))}
              </List>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <TextField
                  className={classes.textField}
                  id="viewersEmail"
                  name="viewersEmail"
                  label="E-Mail"
                  margin="normal"
                  value={viewersEmail}
                  onChange={(e) => setViewersEmail(e.target.value.toLowerCase())}
                />
              </form>
              <Button
                className={classes.submitButton}
                color="primary"
                onClick={handleAddViewer}
              >
                Hinzufügen
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
ChangeOfferingUsersDialog.propTypes = {
  projectEditors: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projectViewers: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projectId: PropTypes.string.isRequired,
  updateRoles: PropTypes.func.isRequired,
  userEmail: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};
