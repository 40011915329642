import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import {
  createTheme,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core/styles';
import { green, red, orange, grey } from '@material-ui/core/colors';
import { SnackbarProvider } from 'notistack';

import jwtDecode from 'jwt-decode';
import { logoutUser } from './redux/actionCreators/authenticationAction';

import './App.css';

import ButtonAppBarContainer from './container/Utilities/ButtonAppBarContainer';
import Routes from './Routes';
import Notifier from './components/Elements/Notifier';
import RouteChangeTracker from './container/Utilities/RouteChangeTracker';
import CookieBanner from './components/Elements/CookieBanner';
import GellixITRegular from './fonts/GellixIT-Regular.woff2';

const gellixITRegular = {
  fontFamily: 'GellixIT-Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('GellixIT'),
    local('GellixIT-Regular'),
    url(${GellixITRegular}) format('woff2')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
};

const homeTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#fff',
      contrastText: '#006da2',
      addFab: '#fff',
    },
    secondary: {
      main: '#f1f2f2',
      contrastText: '#fff',
      addFab: '#fff',
    },
  },
  typography: {
    fontFamily: 'GellixIT-Regular, Arial',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [gellixITRegular],
      },
    },
  },
});

const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#006da2',
      contrastText: '#f1f2f2',
      addFab: '#f1f2f2',
    },
    third: {
      main: '#fab728',
      contrastText: '#fab728',
      addFab: '#fab728',
    },
  },

  overrides: {
    MUIDataTable: {
      paper: {
        boxShadow: 'none',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
      tableRoot: {
        borderCollapse: 'separate',
      },
    },
    MUIDataTableToolbar: {
      root: {
        minHeight: '56px',
        paddingLeft: '16px',
      },
    },
    MuiTableCell: {
      root: {
        whiteSpace: 'pre-wrap',
        padding: '12px',
        paddingLeft: '16px',
      },
    },
    MUIDataTableSelectCell: {
      expandDisabled: {
        // Soft hide the button.
        visibility: 'hidden',
      },
    },
    // MUIDataTableHeadCell: {
    //   contentWrapper: {
    //     justifyContent: 'center',
    //   },
    //   toolButton: {
    //     justifyContent: 'center',
    //     margin: 0,
    //   },
    //   fixedHeader: {
    //     '& div': {
    //       textAlign: 'center',
    //     },
    //   },
    // },
  },
});

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#006da2',
      contrastText: '#f1f2f2',
      addFab: '#f1f2f2',
    },
  },
  overrides: {
    MUIDataTable: {
      paper: {
        boxShadow: 'none',
        borderBottom: '1px solid rgba(224, 224, 224, 1)',
      },
      tableRoot: {
        borderCollapse: 'separate',
      },
    },
    MUIDataTableToolbar: {
      root: {
        minHeight: '56px',
        paddingLeft: '16px',
      },
    },
    MuiTableCell: {
      root: {
        whiteSpace: 'pre-wrap',
        padding: '12px',
        paddingLeft: '16px',
      },
    },
    MUIDataTableHeadCell: {
      contentWrapper: {
        justifyContent: 'center',
      },
      toolButton: {
        justifyContent: 'center',
        margin: 0,
      },
    },
    MUIDataTableSelectCell: {
      expandDisabled: {
        // Soft hide the button.
        visibility: 'hidden',
      },
    },
  },
});

const useStyles = makeStyles(() => ({
  success: { backgroundColor: green[300] },
  error: { backgroundColor: red[300] },
  warning: { backgroundColor: orange[300] },
  info: { backgroundColor: grey[300] },
}));

function App(props) {
  const { darkModeEnabled, location, history, logoutUser } = props;
  const classes = useStyles();

  useEffect(() => {
    // to do: Move to a single component with token refresh
    const checkAuth = () => {
      // console.log('Checking auth status...');
      if (localStorage.jwtToken) {
        const decoded = jwtDecode(localStorage.jwtToken);
        const currentTime = Date.now() / 1000;
        if (decoded.exp < currentTime) {
          logoutUser(history);
        }
      }
    };
    checkAuth();
    const intervalCheck = setInterval(() => {
      checkAuth();
    }, 1000);
    return () => {
      clearInterval(intervalCheck);
    };
  }, [logoutUser, history]);

  if (location.pathname === '/' || location.pathname === '') {
    return (
      <ThemeProvider theme={homeTheme}>
        <CookieBanner />
        <SnackbarProvider
          classes={{
            variantSuccess: classes.success,
            variantError: classes.error,
            variantWarning: classes.warning,
            variantInfo: classes.info,
          }}
          maxSnack={3}
        >
          <CssBaseline />
          <ButtonAppBarContainer />
          <Routes />
          <Notifier />
          <RouteChangeTracker />

        </SnackbarProvider>
      </ThemeProvider>
    );
  }
  return (
    <>
      {darkModeEnabled ? (
        <ThemeProvider theme={darkTheme}>
          <CookieBanner />
          <SnackbarProvider
            classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info,
            }}
            maxSnack={3}
          >
            <CssBaseline />
            <ButtonAppBarContainer />
            <Routes />
            <Notifier />
            <RouteChangeTracker />
          </SnackbarProvider>
        </ThemeProvider>
      ) : (
        <ThemeProvider theme={lightTheme}>
          <CookieBanner />
          <SnackbarProvider
            classes={{
              variantSuccess: classes.success,
              variantError: classes.error,
              variantWarning: classes.warning,
              variantInfo: classes.info,
            }}
            maxSnack={3}
          >
            <CssBaseline />
            <ButtonAppBarContainer />
            <Routes />
            <Notifier />
            <RouteChangeTracker />

          </SnackbarProvider>
        </ThemeProvider>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  darkModeEnabled: state.settings.darkModeEnabled,
});

const mapDispatchToProps = (dispatch) => ({
  logoutUser: (history) => dispatch(logoutUser(history)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));

App.propTypes = {
  darkModeEnabled: PropTypes.bool.isRequired,
  location: PropTypes.shape(Object).isRequired,
  history: PropTypes.shape(Object).isRequired,
  logoutUser: PropTypes.func.isRequired,
};
