import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link, IconButton, Tooltip } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import { Check, Clear } from '@material-ui/icons';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import useStyles from '../../styles';
import DateStamp from '../Elements/DateStamp';
import EditDialog from '../Dialogs/EditDialog';
import AddDialogInline from '../Dialogs/AddDialogInline';
import AddDialog from '../Dialogs/AddDialog';
// import EditTwoFieldsDialog from '../Dialogs/EditTwoFieldsDialog';
import DeleteDialog from '../Dialogs/DeleteDialog';
import TableSwitch from '../Buttons/TableSwitch';
import TableSelect from '../Buttons/TableSelect';
import TableUpButton from '../Buttons/TableUpButton';
import TableDownButton from '../Buttons/TableDownButton';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function NegotiationModulesTable(props) {
  const {
    projects,
    templates,
    modules,
    addModule,
    updateModule,
    deleteModule,
    updateModuleSla,
    updateModulePricing,
    selectProject,
    selectTemplate,
    selectedTemplate,
    moduleNumberDown,
    moduleNumberUp,
    addStatement,
    updateStatement,
    deleteStatement,
    statementNumberDown,
    statementNumberUp,
  } = props;
  const [expanded, setExpansion] = React.useState(true);

  const classes = useStyles();

  const columns = [
    {
      name: 'moduleId',
      label: 'ModuleID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'statementId',
      label: 'StatementID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'heading',
      label: 'Überschrift',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'number',
      label: '#',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { width: '50px' } }),
        customBodyRender: (value, tableMeta) => {
          if (!tableMeta.rowData[1]) {
            return <Typography variant="body2" style={{ fontWeight: 600 }}>{value}</Typography>;
          }
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: 'description',
      label: 'Beschreibung',
      options: {
        setCellProps: () => ({ style: { width: '60%' } }),
        filter: true,
        sort: false,
        customHeadLabelRender: (columnMeta) => (
          <>
            {columnMeta.label}
            <Tooltip title="Beschreibung ein-/ausklappen">
              <IconButton aria-label="Beschreibung ein-/ausklappen" onClick={() => setExpansion(!expanded)}>
                {expanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
              </IconButton>
            </Tooltip>
          </>
        ),
        customBodyRender: (value, tableMeta) => {
          if (!tableMeta.rowData[1]) {
            return (
              <div>
                <Typography variant="body2" style={{ fontWeight: 600 }}>{value}</Typography>
              </div>);
          }
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: 'pricing',
      label: 'Verrechnungsart',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'sla',
      label: 'Service Level',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'updated',
      label: 'Geändert',
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => <DateStamp date={value} />,
      },
    },
    {
      name: 'compliance',
      label: 'Compliance',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (!tableMeta.rowData[1]) return '';
          if (value) return <Check className={classes.check} />;
          return <Clear className={classes.clear} />;
        },
      },
    },
    {
      name: 'suggestion',
      label: 'Vorschlag',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'result',
      label: 'Ergebnis',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'changeNumber',
      label: 'Nummerierung bearbeiten',
      options: {
        filter: false,
        sort: false,
        display: false,
        setCellProps: () => ({ style: { width: '50px' } }),
        customBodyRender: (value, tableMeta) => (
          <div style={{ textAlign: 'left' }}>
            {value !== 1 && !tableMeta.rowData[1] && !projects.published && projects.authorization !== 'read' && (
            <TableUpButton
              onClick={() => moduleNumberDown({ variables: { templateId: selectedTemplate, projectId: projects._id, moduleId: tableMeta.rowData[0] } })}
            />
            )}
            {value
            !== modules.length && !tableMeta.rowData[1] && !projects.published && projects.authorization !== 'read' && (
              <TableDownButton
                onClick={() => moduleNumberUp({ variables: { templateId: selectedTemplate, projectId: projects._id, moduleId: tableMeta.rowData[0] } })}
              />
            )}
            {value.toString().slice(-1) !== '1' && tableMeta.rowData[1] && !projects.published && projects.authorization !== 'read' && (
            <TableUpButton
              onClick={() => statementNumberDown({ variables: { projectId: projects._id, moduleId: tableMeta.rowData[0], statementId: tableMeta.rowData[1] } })}
            />
            )}
            {tableMeta.rowData[1] && !projects.published && projects.authorization !== 'read' && Number(value.toString().slice(-1))
              !== modules.filter((m) => m._id.toString() === tableMeta.rowData[0])[0].statements.length && (
                <TableDownButton
                  onClick={() => statementNumberUp({ variables: { projectId: projects._id, moduleId: tableMeta.rowData[0], statementId: tableMeta.rowData[1] } })}
                />
            )}
          </div>
        ),
      },
    },
    {
      name: 'options',
      label: 'Moduloptionen',
      options: {
        filter: false,
        sort: false,
        display: !projects.published && projects.authorization !== 'read',
        viewColumns: !projects.published && projects.authorization !== 'read',
        customBodyRender: (value, tableMeta) => (
          <div style={{ maxWidth: '200px' }}>
            {!tableMeta.rowData[1] && (
              <>
                <div>
                  <TableSelect
                    moduleId={tableMeta.rowData[0]}
                    projectId={projects._id}
                    value={tableMeta.rowData[5]}
                    disabled={projects.published || projects.authorization === 'read'}
                    selects={['T&M', 'Festpreis', 'Stückpreis']}
                    onChangeHandler={updateModulePricing}
                  />
                  <TableSwitch
                    value={tableMeta.rowData[6]}
                    label="Service Level"
                    disabled={projects.published || projects.authorization === 'read'}
                    onChange={() => {
                      updateModuleSla({
                        variables: { projectId: projects._id, moduleId: tableMeta.rowData[0], toggleSla: !tableMeta.rowData[6] },
                      });
                    }}
                  />
                </div>
              </>
            )}
          </div>
        ),
      },
    },
    {
      name: 'options2',
      label: 'Bearbeiten',
      options: {
        filter: false,
        sort: false,
        display: !projects.published && projects.authorization !== 'read',
        viewColumns: !projects.published && projects.authorization !== 'read',
        customBodyRender: (value, tableMeta) => (
          <div style={{ maxWidth: '200px' }}>
            {!tableMeta.rowData[1] && (
              <>
                <div style={{ display: 'flex' }}>
                  <EditDialog
                    moduleId={tableMeta.rowData[0]}
                    projectId={projects._id}
                    oldData={tableMeta.rowData[4]}
                    header="Modul bearbeiten"
                    text="Modulname"
                    field="name"
                    onClick={updateModule}
                  />
                  {projects.authorization === 'admin' && (
                  <DeleteDialog
                    header="Modul löschen"
                    text={`Möchten Sie das Modul "${tableMeta.rowData[3]}" wirklich löschen?`}
                    onClick={() => deleteModule({
                      variables: { projectId: projects._id, templateId: selectedTemplate, moduleId: tableMeta.rowData[0] },
                    })}
                  />
                  )}
                  {!projects.published && projects.authorization !== 'read' && (
                  <AddDialogInline
                    moduleId={tableMeta.rowData[0]}
                    projectId={projects._id}
                    header="Statement hinzufügen"
                    text="Beschreibung"
                    onClick={addStatement}
                  />
                  )}
                </div>
              </>
            )}
            {tableMeta.rowData[1] && (
            <div style={{ display: 'flex' }}>
              <EditDialog
                statementId={tableMeta.rowData[1]}
                projectId={projects._id}
                oldData={
                  tableMeta.rowData[8]
                    ? tableMeta.rowData[4]
                    : tableMeta.rowData[10] || tableMeta.rowData[9]
                }
                header={
                  tableMeta.rowData[8]
                    ? 'Statement bearbeiten'
                    : 'Verhandlungsergebnis formulieren'
                }
                field={
                  tableMeta.rowData[8]
                    ? 'name'
                    : 'result'
                }
                text="Beschreibung"
                onClick={updateStatement}
              />
              <DeleteDialog
                header="Statement löschen"
                text="Möchten Sie das Statement wirklich löschen?"
                onClick={() => deleteStatement({
                  variables: { projectId: projects._id, templateId: selectedTemplate, moduleId: tableMeta.rowData[0], statementId: tableMeta.rowData[1] },
                })}
              />
            </div>
            )}
          </div>
        ),
      },
    },
    {
      name: 'moduleParameter',
      label: 'Preis-/SLA-Bezug',
      options: {
        filter: true,
        sort: true,
        display: projects.published || projects.authorization === 'read',
        viewColumns: projects.published || projects.authorization === 'read',
        customBodyRender: (value, tableMeta) => {
          if (!tableMeta.rowData[1]) {
            return (
              <Typography variant="body2">
                Preisbezug:
                {' '}
                { tableMeta.rowData[5] || 'Nein' }
                <br />
                Service Level:
                {' '}
                { tableMeta.rowData[6] ? 'Ja' : 'Nein' }
              </Typography>
            );
          }
          return <></>;
        },
      },
    },
  ];

  const data = [];

  modules
    .forEach((module) => {
      data.push({
        moduleId: module._id,
        number: module.number,
        changeNumber: module.number,
        description: module.name,
        heading: module.heading,
        dissenses: module.dissenses,
        pricing: module.prices[0] ? module.prices[0].type : '',
        sla: !!module.servicelevels[0],
        updated: Math.max(...module.changes.map((o) => o.date)),
      });
      module.statements.sort((a, b) => a.number - b.number).forEach((statement) => {
        data.push({
          statementId: statement._id,
          moduleId: module._id,
          number: `${module.number}.${statement.number}`,
          changeNumber: `${module.number}.${statement.number}`,
          description: statement.description,
          updated: Math.max(...statement.changes.map((o) => o.date)),
          compliance: statement.compliance,
          suggestion: statement.suggestion,
          result: statement.result,
        });
      });
    });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectTemplate('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/managenegotiation"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/managenegotiation"
                onClick={() => selectTemplate('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">
                {templates
                  .filter((template) => template._id === selectedTemplate)
                  .map((template) => template.name)}
              </Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({
          setRowProps: (row) => ({
            className: row[1] && !expanded ? classes.rowHidden : classes.rowVisible,
          }),
        })}
      />
      {!projects.published && projects.authorization !== 'read' && (
        <AddDialog
          templateId={selectedTemplate}
          projectId={projects._id}
          header="Modul hinzufügen"
          field="name"
          text="Modulname"
          onClick={addModule}
        />
      )}
    </div>
  );
}

export default NegotiationModulesTable;

NegotiationModulesTable.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
  addModule: PropTypes.func.isRequired,
  updateModule: PropTypes.func.isRequired,
  deleteModule: PropTypes.func.isRequired,
  updateModuleSla: PropTypes.func.isRequired,
  updateModulePricing: PropTypes.func.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  moduleNumberDown: PropTypes.func.isRequired,
  moduleNumberUp: PropTypes.func.isRequired,
  statementNumberDown: PropTypes.func.isRequired,
  statementNumberUp: PropTypes.func.isRequired,
  addStatement: PropTypes.func.isRequired,
  updateStatement: PropTypes.func.isRequired,
  deleteStatement: PropTypes.func.isRequired,
};
