import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Fab, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  tableAddIconButton: {
    backgroundColor: green[300],
    color: theme.palette.primary.addFab,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));

function TableAddIconButton(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <Tooltip title="Hinzufügen">
      <Fab
        size="large"
        className={classes.tableAddIconButton}
        onClick={onClick}
      >
        <Add fontSize="large" />
      </Fab>
    </Tooltip>
  );
}

export default TableAddIconButton;

TableAddIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
