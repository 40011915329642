import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import { ZoomIn } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import TableButton from '../Buttons/TableButton';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function ViewOfferingReferencesCompaniesTable(props) {
  const {
    selectReference,
    selectViewOfferingReferenceCompany,
    selectProject,
    selectTemplate,
    references,
    projects,
    // user,
  } = props;

  const columns = [
    {
      name: 'company',
      label: 'Unternehmen',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <TableButton
            text={value}
            icon={<ZoomIn />}
            onClick={() =>
              selectViewOfferingReferenceCompany(tableMeta.rowData[0])}
          />
        ),
      },
    },
  ];

  // * Important: use this to filter arrays for template movement and copying
  const onlyUnique = (value, index, self) => self.indexOf(value) === index;

  const referenceCompanies = references
    .filter((reference) => reference.cluster !== '')
    .map((reference) => reference.cluster)
    .filter(onlyUnique);

  const data = [];

  referenceCompanies.forEach((reference) => {
    data.push({
      company: reference,
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectReference('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/viewoffering"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectReference('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/viewoffering"
                onClick={() => selectReference('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Referenzen</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions()}
      />
    </div>
  );
}

export default ViewOfferingReferencesCompaniesTable;

ViewOfferingReferencesCompaniesTable.propTypes = {
  selectReference: PropTypes.func.isRequired,
  selectViewOfferingReferenceCompany: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  references: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
};
