import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import { Check, Clear } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { green, red } from '@material-ui/core/colors';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

const useStyles = makeStyles(() => ({
  check: {
    color: green[300],
  },
  clear: {
    color: red[300],
  },
}));

function ProjectSlasTable(props) {
  const {
    slas,
    projects,
    selectProject,
    selectTemplate,
    selectSla,
  } = props;

  const classes = useStyles();

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'extra',
      label: 'Extra',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'module',
      label: 'ModulId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'template',
      label: 'TemplateId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'project',
      label: 'projectId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'templateName',
      label: 'Dokument',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'moduleName',
      label: 'Modul',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'servicelevel',
      label: 'Service Level',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'value',
      label: 'Wert',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'measure',
      label: 'Messpunkt',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Erläuterung',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'penalty',
      label: 'Pönale in €',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'penaltyreference',
      label: 'Pönalenbezug',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'compliance',
      label: 'Compliance',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value) return <Check className={classes.check} />;
          return <Clear className={classes.clear} />;
        },
      },
    },
    {
      name: 'suggestion',
      label: 'Vorschlag',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const data = [];

  slas.forEach((sla) => {
    data.push({
      _id: sla._id,
      name: sla.name,
      value: sla.value,
      servicelevel: sla.servicelevel,
      description: sla.description,
      penalty: sla.penalty,
      penaltyreference: sla.penaltyreference,
      measure: sla.measure,
      module: sla.moduleId,
      template: sla.templateId,
      project: projects._id,
      moduleName: sla.moduleName,
      templateName: sla.templateName,
      compliance: sla.compliance,
      suggestion: sla.suggestion || '',
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectSla('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/viewoffering"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectSla('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/viewoffering"
                onClick={() => selectSla('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Service Level</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({
          sortOrder: { name: 'templateName', direction: 'asc' },
        })}
      />
    </div>
  );
}

export default ProjectSlasTable;

ProjectSlasTable.propTypes = {
  projects: PropTypes.shape(Object).isRequired,
  slas: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectSla: PropTypes.func.isRequired,
  // user: PropTypes.shape(Object).isRequired,
};
