import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';
import TableSwitch from '../Buttons/TableSwitch';
import EditOfferingSuggestionDialog from '../Dialogs/EditOfferingSuggestionDialog';

function ProjectSlasTable(props) {
  const {
    slas,
    projects,
    answerServiceLevel,
    selectProject,
    // selectedProject,
    selectTemplate,
    selectSla,
    // user,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'extra',
      label: 'Extra',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'module',
      label: 'ModulId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'template',
      label: 'TemplateId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'project',
      label: 'projectId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'templateName',
      label: 'Dokument',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'moduleName',
      label: 'Modul',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'servicelevel',
      label: 'Service Level',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'value',
      label: 'Wert',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'measure',
      label: 'Messpunkt',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Erläuterung',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'penalty',
      label: 'Pönale in €',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'penaltyreference',
      label: 'Pönalenbezug',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'compliance',
      label: 'Compliance',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <TableSwitch
            value={value}
            onChange={() => answerServiceLevel({ variables: { projectId: projects._id, servicelevelId: tableMeta.rowData[0], compliance: !value } })}
            disabled={projects.published || projects.authorization === 'read'}
          />
        ),
      },
    },
    {
      name: 'suggestion',
      label: 'Vorschlag',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        display: !projects.published && projects.authorization !== 'read',
        viewColumns: !projects.published && projects.authorization !== 'read',
        customBodyRender: (value, tableMeta) =>
          !tableMeta.rowData[14] && (
            <EditOfferingSuggestionDialog
              servicelevelId={tableMeta.rowData[0]}
              projectId={projects._id}
              originalData={`Modul: ${tableMeta.rowData[6]}
Name: ${tableMeta.rowData[7]}
Service Level: ${tableMeta.rowData[8]}
Wert: ${tableMeta.rowData[9]}
Messpunkt: ${tableMeta.rowData[10]}
Erläuterung: ${tableMeta.rowData[11]}
Pönale in €: ${tableMeta.rowData[12]}
Pönalenbezug: ${tableMeta.rowData[13]}`}
              oldData={tableMeta.rowData[15]}
              header="Vorschlag formulieren"
              text="Vorschlag"
              onClick={answerServiceLevel}
            />
          ),
      },
    },
  ];

  const data = [];

  slas.forEach((sla) => {
    data.push({
      _id: sla._id,
      name: sla.name,
      value: sla.value,
      servicelevel: sla.servicelevel,
      description: sla.description,
      penalty: sla.penalty,
      penaltyreference: sla.penaltyreference,
      measure: sla.measure,
      module: sla.moduleId,
      template: sla.templateId,
      project: projects._id,
      moduleName: sla.moduleName,
      templateName: sla.templateName,
      compliance: sla.compliance,
      suggestion: sla.suggestion || '',
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectSla('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectSla('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => selectSla('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Service Level</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({
          sortOrder: { name: 'templateName', direction: 'asc' },
          tableBodyMaxHeight: 'calc(100vh - 129px)',
        })}
      />
    </div>
  );
}

export default ProjectSlasTable;

ProjectSlasTable.propTypes = {
  projects: PropTypes.shape(Object).isRequired,
  slas: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  answerServiceLevel: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectSla: PropTypes.func.isRequired,
};
