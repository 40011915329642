import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function NegotiationReferencesTable(props) {
  const {
    selectReference,
    selectProject,
    selectTemplate,
    selectNegotiationReferenceCompany,
    references,
    projects,
    selectedReferenceCompany,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'question',
      label: 'Frage',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'answer',
      label: 'Antwort',
      options: {
        filter: true,
        sort: true,
      },
    },
  ];

  const data = [];

  references
    .filter((reference) => reference.cluster === selectedReferenceCompany)
    .forEach((reference) => {
      data.push({
        _id: reference._id,
        question: reference.question,
        answer: reference.answer,
        company: reference.cluster,
      });
    });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button
              size="small"
              onClick={() => {
                selectNegotiationReferenceCompany('');
              }}
            >
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageNegotiation"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectReference('');
                  selectNegotiationReferenceCompany('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/managenegotiation"
                onClick={() => {
                  selectReference('');
                  selectNegotiationReferenceCompany('');
                }}
              >
                {projects.name}
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/managenegotiation"
                onClick={() => {
                  selectNegotiationReferenceCompany('');
                }}
              >
                Referenzen
              </Link>
              <Typography color="textPrimary">
                {selectedReferenceCompany}
              </Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions()}
      />
    </div>
  );
}

export default NegotiationReferencesTable;

NegotiationReferencesTable.propTypes = {
  selectReference: PropTypes.func.isRequired,
  selectNegotiationReferenceCompany: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  references: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
  selectedReferenceCompany: PropTypes.string.isRequired,
};
