import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_SERVICELEVELS, GET_PROJECT } from '../../graphql/queries';

import {
  selectProject,
  selectTemplate,
  selectSla,
} from '../../redux/actionCreators/viewofferingAction';
import ViewOfferingSlasTable from '../../components/ViewOffering/ViewOfferingSlasTable';
import Loading from '../../components/Elements/Loading';

function ViewOfferingSlasContainer(props) {
  const {
    selectProject,
    selectTemplate,
    selectedProject,
    selectSla,
  } = props;
  const { loading, error, data } = useQuery(GET_SERVICELEVELS, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });
  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  return (
    <ViewOfferingSlasTable
      slas={data.servicelevels}
      projects={data1.project}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectSla={selectSla}
    />
  );
}

const mapStateToProps = (state) => ({
  slas: state.viewoffering.slas,
  projects: state.viewoffering.projects,
  templates: state.viewoffering.templates,
  selectedProject: state.viewoffering.selectedProject,
  loading: state.viewoffering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectSla: (id) => dispatch(selectSla(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewOfferingSlasContainer),
);

ViewOfferingSlasContainer.propTypes = {
  selectSla: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
};
