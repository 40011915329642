import React from 'react';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

function TableSelect(props) {
  const { moduleId, projectId, id, value, selects, onChangeHandler, disabled } = props;

  return (
    <Select
      labelid="selectLabel"
      fullWidth
      disabled={disabled}
      value={value}
      onChange={(e) => {
        onChangeHandler({ variables: { projectId, id, moduleId, type: e.target.value, role: e.target.value } });
      }}
    >
      <MenuItem value="">-</MenuItem>
      {selects.map((select) => (
        <MenuItem key={select} value={select}>
          {select}
        </MenuItem>
      ))}
    </Select>
  );
}

export default TableSelect;

TableSelect.propTypes = {
  moduleId: PropTypes.string,
  id: PropTypes.string,
  projectId: PropTypes.string,
  value: PropTypes.string.isRequired,
  selects: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeHandler: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
