import axios from 'axios';
import {
  CLEAR_NEGOTIATION_SLAS_ARRAY,
  DELETE_NEGOTIATION_PROJECT_BEGIN,
  DELETE_NEGOTIATION_PROJECT_FAILURE,
  DELETE_NEGOTIATION_PROJECT_SUCCESS,
  FETCH_CONTRACT_MODULES_BEGIN,
  FETCH_CONTRACT_MODULES_FAILURE,
  FETCH_CONTRACT_MODULES_SUCCESS,
  FETCH_CONTRACT_STATEMENTS_BEGIN,
  FETCH_CONTRACT_STATEMENTS_FAILURE,
  FETCH_CONTRACT_STATEMENTS_SUCCESS,
  FETCH_CONTRACT_TEMPLATES_BEGIN,
  FETCH_CONTRACT_TEMPLATES_FAILURE,
  FETCH_CONTRACT_TEMPLATES_SUCCESS,
  FETCH_NEGOTIATION_ASSUMPTIONS_BEGIN,
  FETCH_NEGOTIATION_ASSUMPTIONS_FAILURE,
  FETCH_NEGOTIATION_ASSUMPTIONS_SUCCESS,
  FETCH_NEGOTIATION_FIXEDPRICES_BEGIN,
  FETCH_NEGOTIATION_FIXEDPRICES_FAILURE,
  FETCH_NEGOTIATION_FIXEDPRICES_SUCCESS,
  FETCH_NEGOTIATION_INFORMATION_BEGIN,
  FETCH_NEGOTIATION_INFORMATION_FAILURE,
  FETCH_NEGOTIATION_INFORMATION_SUCCESS,
  FETCH_NEGOTIATION_PIECEPRICES_BEGIN,
  FETCH_NEGOTIATION_PIECEPRICES_FAILURE,
  FETCH_NEGOTIATION_PIECEPRICES_SUCCESS,
  FETCH_NEGOTIATION_PROVISIONS_BEGIN,
  FETCH_NEGOTIATION_PROVISIONS_FAILURE,
  FETCH_NEGOTIATION_PROVISIONS_SUCCESS,
  FETCH_NEGOTIATION_RATECARDS_BEGIN,
  FETCH_NEGOTIATION_RATECARDS_FAILURE,
  FETCH_NEGOTIATION_RATECARDS_SUCCESS,
  FETCH_NEGOTIATION_SLAS_BEGIN,
  FETCH_NEGOTIATION_SLAS_FAILURE,
  FETCH_NEGOTIATION_SLAS_SUCCESS,
  FETCH_NEGOTIATION_TANDMS_BEGIN,
  FETCH_NEGOTIATION_TANDMS_FAILURE,
  FETCH_NEGOTIATION_TANDMS_SUCCESS,
  MAKE_CONTRACT_BEGIN,
  MAKE_CONTRACT_FAILURE,
  MAKE_CONTRACT_SUCCESS,
  NEGOTIATION_CLEAR_MODULES_ARRAY,
  NEGOTIATION_CLEAR_PROJECTS_ARRAY,
  NEGOTIATION_CLEAR_STATEMENTS_ARRAY,
  NEGOTIATION_CLEAR_TEMPLATES_ARRAY,
  NEGOTIATION_FETCH_MODULES_BEGIN,
  NEGOTIATION_FETCH_MODULES_FAILURE,
  NEGOTIATION_FETCH_MODULES_SUCCESS,
  NEGOTIATION_FETCH_PROJECTS_BEGIN,
  NEGOTIATION_FETCH_PROJECTS_FAILURE,
  NEGOTIATION_FETCH_PROJECTS_SUCCESS,
  NEGOTIATION_FETCH_STATEMENTS_BEGIN,
  NEGOTIATION_FETCH_STATEMENTS_FAILURE,
  NEGOTIATION_FETCH_STATEMENTS_SUCCESS,
  NEGOTIATION_FETCH_TEMPLATES_BEGIN,
  NEGOTIATION_FETCH_TEMPLATES_FAILURE,
  NEGOTIATION_FETCH_TEMPLATES_SUCCESS,
  NEGOTIATION_SELECT_MODULE,
  NEGOTIATION_SELECT_PROJECT,
  NEGOTIATION_SELECT_STATEMENT,
  NEGOTIATION_SELECT_TEMPLATE,
  PUBLISH_NEGOTIATION_BEGIN,
  PUBLISH_NEGOTIATION_FAILURE,
  PUBLISH_NEGOTIATION_SUCCESS,
  SELECT_NEGOTIATION_ASSUMPTION,
  SELECT_NEGOTIATION_FIXEDPRICE,
  SELECT_NEGOTIATION_INFORMATION,
  SELECT_NEGOTIATION_PIECEPRICE,
  SELECT_NEGOTIATION_PROVISION,
  SELECT_NEGOTIATION_RATECARD,
  SELECT_NEGOTIATION_REFERENCE,
  SELECT_NEGOTIATION_SLA,
  SELECT_NEGOTIATION_SUBCONTRACTOR,
  SELECT_NEGOTIATION_TANDM,
  UPDATE_NEGOTIATION_STATEMENT_BEGIN,
  UPDATE_NEGOTIATION_STATEMENT_FAILURE,
  UPDATE_NEGOTIATION_STATEMENT_SUCCESS,
  FETCH_NEGOTIATION_REFERENCES_BEGIN,
  FETCH_NEGOTIATION_REFERENCES_SUCCESS,
  FETCH_NEGOTIATION_REFERENCES_FAILURE,
  SELECT_NEGOTIATION_REFERENCE_COMPANY,
  FETCH_NEGOTIATION_SUBCONTRACTORS_BEGIN,
  FETCH_NEGOTIATION_SUBCONTRACTORS_SUCCESS,
  FETCH_NEGOTIATION_SUBCONTRACTORS_FAILURE,
  SELECT_NEGOTIATION_SUBCONTRACTOR_COMPANY,
  ADD_NEGOTIATION_TEMPLATE_BEGIN,
  ADD_NEGOTIATION_TEMPLATE_SUCCESS,
  ADD_NEGOTIATION_TEMPLATE_FAILURE,
  UPDATE_NEGOTIATION_TEMPLATE_BEGIN,
  UPDATE_NEGOTIATION_TEMPLATE_SUCCESS,
  UPDATE_NEGOTIATION_TEMPLATE_FAILURE,
  DELETE_NEGOTIATION_TEMPLATE_BEGIN,
  DELETE_NEGOTIATION_TEMPLATE_SUCCESS,
  DELETE_NEGOTIATION_TEMPLATE_FAILURE,
  ADD_NEGOTIATION_MODULE_BEGIN,
  ADD_NEGOTIATION_MODULE_SUCCESS,
  ADD_NEGOTIATION_MODULE_FAILURE,
  UPDATE_NEGOTIATION_MODULE_BEGIN,
  UPDATE_NEGOTIATION_MODULE_SUCCESS,
  UPDATE_NEGOTIATION_MODULE_FAILURE,
  DELETE_NEGOTIATION_MODULE_BEGIN,
  DELETE_NEGOTIATION_MODULE_SUCCESS,
  DELETE_NEGOTIATION_MODULE_FAILURE,
  UPDATE_NEGOTIATION_MODULE_PRICING_BEGIN,
  UPDATE_NEGOTIATION_MODULE_PRICING_SUCCESS,
  UPDATE_NEGOTIATION_MODULE_PRICING_FAILURE,
  UPDATE_NEGOTIATION_MODULE_SLA_BEGIN,
  UPDATE_NEGOTIATION_MODULE_SLA_SUCCESS,
  UPDATE_NEGOTIATION_MODULE_SLA_FAILURE,
  UPDATE_NEGOTIATION_PIECEPRICE_BEGIN,
  UPDATE_NEGOTIATION_PIECEPRICE_SUCCESS,
  UPDATE_NEGOTIATION_PIECEPRICE_FAILURE,
  ADD_PIECEPRICE_BEGIN,
  ADD_PIECEPRICE_SUCCESS,
  ADD_PIECEPRICE_FAILURE,
  DELETE_PIECEPRICE_BEGIN,
  DELETE_PIECEPRICE_SUCCESS,
  DELETE_PIECEPRICE_FAILURE,
  ADD_NEGOTIATION_SLA_BEGIN,
  ADD_NEGOTIATION_SLA_SUCCESS,
  ADD_NEGOTIATION_SLA_FAILURE,
  UPDATE_NEGOTIATION_SLA_BEGIN,
  UPDATE_NEGOTIATION_SLA_SUCCESS,
  UPDATE_NEGOTIATION_SLA_FAILURE,
  DELETE_NEGOTIATION_SLA_BEGIN,
  DELETE_NEGOTIATION_SLA_SUCCESS,
  DELETE_NEGOTIATION_SLA_FAILURE,
  ADD_NEGOTIATION_RATECARD_BEGIN,
  ADD_NEGOTIATION_RATECARD_SUCCESS,
  ADD_NEGOTIATION_RATECARD_FAILURE,
  UPDATE_NEGOTIATION_RATECARD_BEGIN,
  UPDATE_NEGOTIATION_RATECARD_SUCCESS,
  UPDATE_NEGOTIATION_RATECARD_FAILURE,
  DELETE_NEGOTIATION_RATECARD_BEGIN,
  DELETE_NEGOTIATION_RATECARD_SUCCESS,
  DELETE_NEGOTIATION_RATECARD_FAILURE,
  ADD_NEGOTIATION_STATEMENT_BEGIN,
  ADD_NEGOTIATION_STATEMENT_SUCCESS,
  ADD_NEGOTIATION_STATEMENT_FAILURE,
  DELETE_NEGOTIATION_STATEMENT_BEGIN,
  DELETE_NEGOTIATION_STATEMENT_SUCCESS,
  DELETE_NEGOTIATION_STATEMENT_FAILURE,
  UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_BEGIN,
  UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_SUCCESS,
  UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_FAILURE,
  FETCH_NEGOTIATION_EDITORS_BEGIN,
  FETCH_NEGOTIATION_EDITORS_SUCCESS,
  FETCH_NEGOTIATION_EDITORS_FAILURE,
  FETCH_NEGOTIATION_VIEWERS_BEGIN,
  FETCH_NEGOTIATION_VIEWERS_SUCCESS,
  FETCH_NEGOTIATION_VIEWERS_FAILURE,
  UPDATE_NEGOTIATION_EDITORS_BEGIN,
  UPDATE_NEGOTIATION_EDITORS_SUCCESS,
  UPDATE_NEGOTIATION_EDITORS_FAILURE,
  UPDATE_NEGOTIATION_VIEWERS_BEGIN,
  UPDATE_NEGOTIATION_VIEWERS_SUCCESS,
  UPDATE_NEGOTIATION_VIEWERS_FAILURE,
  NEGOTIATION_TEMPLATE_NUMBER_DOWN_BEGIN,
  NEGOTIATION_TEMPLATE_NUMBER_DOWN_SUCCESS,
  NEGOTIATION_TEMPLATE_NUMBER_DOWN_FAILURE,
  NEGOTIATION_TEMPLATE_NUMBER_UP_BEGIN,
  NEGOTIATION_TEMPLATE_NUMBER_UP_SUCCESS,
  NEGOTIATION_TEMPLATE_NUMBER_UP_FAILURE,
  NEGOTIATION_MODULE_NUMBER_DOWN_BEGIN,
  NEGOTIATION_MODULE_NUMBER_DOWN_SUCCESS,
  NEGOTIATION_MODULE_NUMBER_DOWN_FAILURE,
  NEGOTIATION_MODULE_NUMBER_UP_BEGIN,
  NEGOTIATION_MODULE_NUMBER_UP_SUCCESS,
  NEGOTIATION_MODULE_NUMBER_UP_FAILURE,
  NEGOTIATION_STATEMENT_NUMBER_DOWN_BEGIN,
  NEGOTIATION_STATEMENT_NUMBER_DOWN_SUCCESS,
  NEGOTIATION_STATEMENT_NUMBER_DOWN_FAILURE,
  NEGOTIATION_STATEMENT_NUMBER_UP_BEGIN,
  NEGOTIATION_STATEMENT_NUMBER_UP_SUCCESS,
  NEGOTIATION_STATEMENT_NUMBER_UP_FAILURE,
  FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_BEGIN,
  FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_SUCCESS,
  FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_FAILURE,
  UPLOAD_NEGOTIATION_PROJECT_FILE_BEGIN,
  UPLOAD_NEGOTIATION_PROJECT_FILE_SUCCESS,
  UPLOAD_NEGOTIATION_PROJECT_FILE_FAILURE,
  REMOVE_NEGOTIATION_PROJECT_UPLOADS_BEGIN,
  REMOVE_NEGOTIATION_PROJECT_UPLOADS_SUCCESS,
  REMOVE_NEGOTIATION_PROJECT_UPLOADS_FAILURE,
  UPLOAD_NEGOTIATION_TEMPLATE_FILE_BEGIN,
  UPLOAD_NEGOTIATION_TEMPLATE_FILE_SUCCESS,
  UPLOAD_NEGOTIATION_TEMPLATE_FILE_FAILURE,
  REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_BEGIN,
  REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_SUCCESS,
  REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_FAILURE,
  UPLOAD_NEGOTIATION_MODULE_FILE_BEGIN,
  UPLOAD_NEGOTIATION_MODULE_FILE_SUCCESS,
  UPLOAD_NEGOTIATION_MODULE_FILE_FAILURE,
  REMOVE_NEGOTIATION_MODULE_UPLOADS_BEGIN,
  REMOVE_NEGOTIATION_MODULE_UPLOADS_SUCCESS,
  REMOVE_NEGOTIATION_MODULE_UPLOADS_FAILURE,
  UPLOAD_NEGOTIATION_STATEMENT_FILE_BEGIN,
  UPLOAD_NEGOTIATION_STATEMENT_FILE_SUCCESS,
  UPLOAD_NEGOTIATION_STATEMENT_FILE_FAILURE,
  REMOVE_NEGOTIATION_STATEMENT_UPLOADS_BEGIN,
  REMOVE_NEGOTIATION_STATEMENT_UPLOADS_SUCCESS,
  REMOVE_NEGOTIATION_STATEMENT_UPLOADS_FAILURE,
} from '../actionTypes/actionTypes';
import { enqueueSnackbar } from './notifierAction';

export const removeNegotiationProjectUploadBegin = () => ({
  type: REMOVE_NEGOTIATION_PROJECT_UPLOADS_BEGIN,
});

export const removeNegotiationProjectUploadSuccess = () => ({
  type: REMOVE_NEGOTIATION_PROJECT_UPLOADS_SUCCESS,
});

export const removeNegotiationProjectUploadFailure = (error) => ({
  type: REMOVE_NEGOTIATION_PROJECT_UPLOADS_FAILURE,
  payload: { error },
});

export const uploadNegotiationProjectFileBegin = () => ({
  type: UPLOAD_NEGOTIATION_PROJECT_FILE_BEGIN,
});

export const uploadNegotiationProjectFileSuccess = () => ({
  type: UPLOAD_NEGOTIATION_PROJECT_FILE_SUCCESS,
});

export const uploadNegotiationProjectFileFailure = (error) => ({
  type: UPLOAD_NEGOTIATION_PROJECT_FILE_FAILURE,
  payload: { error },
});

export const removeNegotiationTemplateUploadBegin = () => ({
  type: REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_BEGIN,
});

export const removeNegotiationTemplateUploadSuccess = () => ({
  type: REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_SUCCESS,
});

export const removeNegotiationTemplateUploadFailure = (error) => ({
  type: REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_FAILURE,
  payload: { error },
});

export const uploadNegotiationTemplateFileBegin = () => ({
  type: UPLOAD_NEGOTIATION_TEMPLATE_FILE_BEGIN,
});

export const uploadNegotiationTemplateFileSuccess = () => ({
  type: UPLOAD_NEGOTIATION_TEMPLATE_FILE_SUCCESS,
});

export const uploadNegotiationTemplateFileFailure = (error) => ({
  type: UPLOAD_NEGOTIATION_TEMPLATE_FILE_FAILURE,
  payload: { error },
});

export const removeNegotiationModuleUploadBegin = () => ({
  type: REMOVE_NEGOTIATION_MODULE_UPLOADS_BEGIN,
});

export const removeNegotiationModuleUploadSuccess = () => ({
  type: REMOVE_NEGOTIATION_MODULE_UPLOADS_SUCCESS,
});

export const removeNegotiationModuleUploadFailure = (error) => ({
  type: REMOVE_NEGOTIATION_MODULE_UPLOADS_FAILURE,
  payload: { error },
});

export const uploadNegotiationModuleFileBegin = () => ({
  type: UPLOAD_NEGOTIATION_MODULE_FILE_BEGIN,
});

export const uploadNegotiationModuleFileSuccess = () => ({
  type: UPLOAD_NEGOTIATION_MODULE_FILE_SUCCESS,
});

export const uploadNegotiationModuleFileFailure = (error) => ({
  type: UPLOAD_NEGOTIATION_MODULE_FILE_FAILURE,
  payload: { error },
});

export const removeNegotiationStatementUploadBegin = () => ({
  type: REMOVE_NEGOTIATION_STATEMENT_UPLOADS_BEGIN,
});

export const removeNegotiationStatementUploadSuccess = () => ({
  type: REMOVE_NEGOTIATION_STATEMENT_UPLOADS_SUCCESS,
});

export const removeNegotiationStatementUploadFailure = (error) => ({
  type: REMOVE_NEGOTIATION_STATEMENT_UPLOADS_FAILURE,
  payload: { error },
});

export const uploadNegotiationStatementFileBegin = () => ({
  type: UPLOAD_NEGOTIATION_STATEMENT_FILE_BEGIN,
});

export const uploadNegotiationStatementFileSuccess = () => ({
  type: UPLOAD_NEGOTIATION_STATEMENT_FILE_SUCCESS,
});

export const uploadNegotiationStatementFileFailure = (error) => ({
  type: UPLOAD_NEGOTIATION_STATEMENT_FILE_FAILURE,
  payload: { error },
});

export const fetchAdditionalFilesIsShowingBegin = () => ({
  type: FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_BEGIN,
});

export const fetchAdditionalFilesIsShowingSuccess = (isShowing) => ({
  type: FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_SUCCESS,
  payload: { isShowing },
});

export const fetchAdditionalFilesIsShowingFailure = (error) => ({
  type: FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_FAILURE,
  payload: { error },
});

export const fetchProjectViewersBegin = () => ({
  type: FETCH_NEGOTIATION_VIEWERS_BEGIN,
});

export const fetchProjectViewersSuccess = (viewers) => ({
  type: FETCH_NEGOTIATION_VIEWERS_SUCCESS,
  payload: { viewers },
});

export const fetchProjectViewersFailure = (error) => ({
  type: FETCH_NEGOTIATION_VIEWERS_FAILURE,
  payload: { error },
});

export const updateViewersBegin = () => ({
  type: UPDATE_NEGOTIATION_VIEWERS_BEGIN,
});

export const updateViewersSuccess = () => ({
  type: UPDATE_NEGOTIATION_VIEWERS_SUCCESS,
});

export const updateViewersFailure = (error) => ({
  type: UPDATE_NEGOTIATION_VIEWERS_FAILURE,
  payload: { error },
});

export const fetchProjectEditorsBegin = () => ({
  type: FETCH_NEGOTIATION_EDITORS_BEGIN,
});

export const fetchProjectEditorsSuccess = (editors) => ({
  type: FETCH_NEGOTIATION_EDITORS_SUCCESS,
  payload: { editors },
});

export const fetchProjectEditorsFailure = (error) => ({
  type: FETCH_NEGOTIATION_EDITORS_FAILURE,
  payload: { error },
});

export const updateEditorsBegin = () => ({
  type: UPDATE_NEGOTIATION_EDITORS_BEGIN,
});

export const updateEditorsSuccess = () => ({
  type: UPDATE_NEGOTIATION_EDITORS_SUCCESS,
});

export const updateEditorsFailure = (error) => ({
  type: UPDATE_NEGOTIATION_EDITORS_FAILURE,
  payload: { error },
});

export const addStatementBegin = () => ({
  type: ADD_NEGOTIATION_STATEMENT_BEGIN,
});

export const addStatementSuccess = () => ({
  type: ADD_NEGOTIATION_STATEMENT_SUCCESS,
});

export const addStatementFailure = (error) => ({
  type: ADD_NEGOTIATION_STATEMENT_FAILURE,
  payload: { error },
});

export const deleteStatementBegin = () => ({
  type: DELETE_NEGOTIATION_STATEMENT_BEGIN,
});

export const deleteStatementSuccess = () => ({
  type: DELETE_NEGOTIATION_STATEMENT_SUCCESS,
});

export const deleteStatementFailure = (error) => ({
  type: DELETE_NEGOTIATION_STATEMENT_FAILURE,
  payload: { error },
});

export const updateStatementResponsibilitiesBegin = () => ({
  type: UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_BEGIN,
});

export const updateStatementResponsibilitiesSuccess = () => ({
  type: UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_SUCCESS,
});

export const updateStatementResponsibilitiesFailure = (error) => ({
  type: UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_FAILURE,
  payload: { error },
});

export const addRatecardBegin = () => ({
  type: ADD_NEGOTIATION_RATECARD_BEGIN,
});

export const addRatecardSuccess = () => ({
  type: ADD_NEGOTIATION_RATECARD_SUCCESS,
});

export const addRatecardFailure = (error) => ({
  type: ADD_NEGOTIATION_RATECARD_FAILURE,
  payload: { error },
});

export const updateRatecardBegin = () => ({
  type: UPDATE_NEGOTIATION_RATECARD_BEGIN,
});

export const updateRatecardSuccess = () => ({
  type: UPDATE_NEGOTIATION_RATECARD_SUCCESS,
});

export const updateRatecardFailure = (error) => ({
  type: UPDATE_NEGOTIATION_RATECARD_FAILURE,
  payload: { error },
});

export const deleteRatecardBegin = () => ({
  type: DELETE_NEGOTIATION_RATECARD_BEGIN,
});

export const deleteRatecardSuccess = () => ({
  type: DELETE_NEGOTIATION_RATECARD_SUCCESS,
});

export const deleteRatecardFailure = (error) => ({
  type: DELETE_NEGOTIATION_RATECARD_FAILURE,
  payload: { error },
});

export const updatePiecePriceBegin = () => ({
  type: UPDATE_NEGOTIATION_PIECEPRICE_BEGIN,
});

export const updatePiecePriceSuccess = () => ({
  type: UPDATE_NEGOTIATION_PIECEPRICE_SUCCESS,
});

export const updatePiecePriceFailure = (error) => ({
  type: UPDATE_NEGOTIATION_PIECEPRICE_FAILURE,
  payload: { error },
});

export const addPiecePriceBegin = () => ({
  type: ADD_PIECEPRICE_BEGIN,
});

export const addPiecePriceSuccess = () => ({
  type: ADD_PIECEPRICE_SUCCESS,
});

export const addPiecePriceFailure = (error) => ({
  type: ADD_PIECEPRICE_FAILURE,
  payload: { error },
});
export const deletePiecePriceBegin = () => ({
  type: DELETE_PIECEPRICE_BEGIN,
});

export const deletePiecePriceSuccess = () => ({
  type: DELETE_PIECEPRICE_SUCCESS,
});

export const deletePiecePriceFailure = (error) => ({
  type: DELETE_PIECEPRICE_FAILURE,
  payload: { error },
});

export const selectNegotiationSubcontractorCompany = (company) => ({
  type: SELECT_NEGOTIATION_SUBCONTRACTOR_COMPANY,
  payload: { company },
});

export const fetchNegotiationSubcontractorsBegin = () => ({
  type: FETCH_NEGOTIATION_SUBCONTRACTORS_BEGIN,
});

export const fetchNegotiationSubcontractorsSuccess = (subcontractors) => ({
  type: FETCH_NEGOTIATION_SUBCONTRACTORS_SUCCESS,
  payload: { subcontractors },
});

export const fetchNegotiationSubcontractorsFailure = (error) => ({
  type: FETCH_NEGOTIATION_SUBCONTRACTORS_FAILURE,
  payload: { error },
});

export const selectNegotiationReferenceCompany = (company) => ({
  type: SELECT_NEGOTIATION_REFERENCE_COMPANY,
  payload: { company },
});

export const fetchNegotiationReferencesBegin = () => ({
  type: FETCH_NEGOTIATION_REFERENCES_BEGIN,
});

export const fetchNegotiationReferencesSuccess = (references) => ({
  type: FETCH_NEGOTIATION_REFERENCES_SUCCESS,
  payload: { references },
});

export const fetchNegotiationReferencesFailure = (error) => ({
  type: FETCH_NEGOTIATION_REFERENCES_FAILURE,
  payload: { error },
});

export const fetchNegotiationInformationBegin = () => ({
  type: FETCH_NEGOTIATION_INFORMATION_BEGIN,
});

export const fetchNegotiationInformationSuccess = (information) => ({
  type: FETCH_NEGOTIATION_INFORMATION_SUCCESS,
  payload: { information },
});

export const fetchNegotiationInformationFailure = (error) => ({
  type: FETCH_NEGOTIATION_INFORMATION_FAILURE,
  payload: { error },
});

export const selectInformation = (id) => ({
  type: SELECT_NEGOTIATION_INFORMATION,
  payload: { id },
});

export const fetchContractTemplatesBegin = () => ({
  type: FETCH_CONTRACT_TEMPLATES_BEGIN,
});

export const fetchContractTemplatesSuccess = (templates) => ({
  type: FETCH_CONTRACT_TEMPLATES_SUCCESS,
  payload: { templates },
});
export const fetchContractTemplatesFailure = (error) => ({
  type: FETCH_CONTRACT_TEMPLATES_FAILURE,
  payload: { error },
});

export const fetchContractModulesBegin = () => ({
  type: FETCH_CONTRACT_MODULES_BEGIN,
});

export const fetchContractModulesSuccess = (modules) => ({
  type: FETCH_CONTRACT_MODULES_SUCCESS,
  payload: { modules },
});
export const fetchContractModulesFailure = (error) => ({
  type: FETCH_CONTRACT_MODULES_FAILURE,
  payload: { error },
});

export const fetchContractStatementsBegin = () => ({
  type: FETCH_CONTRACT_STATEMENTS_BEGIN,
});

export const fetchContractStatementsSuccess = (statements) => ({
  type: FETCH_CONTRACT_STATEMENTS_SUCCESS,
  payload: { statements },
});
export const fetchContractStatementsFailure = (error) => ({
  type: FETCH_CONTRACT_STATEMENTS_FAILURE,
  payload: { error },
});

export const makeContractBegin = () => ({
  type: MAKE_CONTRACT_BEGIN,
});

export const makeContractSuccess = () => ({
  type: MAKE_CONTRACT_SUCCESS,
});
export const makeContractFailure = (error) => ({
  type: MAKE_CONTRACT_FAILURE,
  payload: { error },
});

export const updateStatementBegin = () => ({
  type: UPDATE_NEGOTIATION_STATEMENT_BEGIN,
});

export const updateStatementSuccess = () => ({
  type: UPDATE_NEGOTIATION_STATEMENT_SUCCESS,
});

export const updateStatementFailure = () => ({
  type: UPDATE_NEGOTIATION_STATEMENT_FAILURE,
});

export const addSlaBegin = () => ({
  type: ADD_NEGOTIATION_SLA_BEGIN,
});

export const addSlaSuccess = () => ({
  type: ADD_NEGOTIATION_SLA_SUCCESS,
});

export const addSlaFailure = (error) => ({
  type: ADD_NEGOTIATION_SLA_FAILURE,
  payload: { error },
});

export const updateSlaBegin = () => ({
  type: UPDATE_NEGOTIATION_SLA_BEGIN,
});

export const updateSlaSuccess = () => ({
  type: UPDATE_NEGOTIATION_SLA_SUCCESS,
});

export const updateSlaFailure = () => ({
  type: UPDATE_NEGOTIATION_SLA_FAILURE,
});

export const deleteSlaBegin = () => ({
  type: DELETE_NEGOTIATION_SLA_BEGIN,
});

export const deleteSlaSuccess = () => ({
  type: DELETE_NEGOTIATION_SLA_SUCCESS,
});

export const deleteSlaFailure = (error) => ({
  type: DELETE_NEGOTIATION_SLA_FAILURE,
  payload: { error },
});

export const deleteProjectBegin = () => ({
  type: DELETE_NEGOTIATION_PROJECT_BEGIN,
});

export const deleteProjectSuccess = (id) => ({
  type: DELETE_NEGOTIATION_PROJECT_SUCCESS,
  payload: { id },
});

export const deleteProjectFailure = (error) => ({
  type: DELETE_NEGOTIATION_PROJECT_FAILURE,
  payload: { error },
});

export const selectRatecard = (id) => ({
  type: SELECT_NEGOTIATION_RATECARD,
  payload: { id },
});

export const selectFixedPrice = (id) => ({
  type: SELECT_NEGOTIATION_FIXEDPRICE,
  payload: { id },
});

export const selectPiecePrice = (id) => ({
  type: SELECT_NEGOTIATION_PIECEPRICE,
  payload: { id },
});

export const selectTAndM = (id) => ({
  type: SELECT_NEGOTIATION_TANDM,
  payload: { id },
});

export const selectReference = (id) => ({
  type: SELECT_NEGOTIATION_REFERENCE,
  payload: { id },
});

export const selectSubcontractor = (id) => ({
  type: SELECT_NEGOTIATION_SUBCONTRACTOR,
  payload: { id },
});

export const fetchTAndMsBegin = () => ({
  type: FETCH_NEGOTIATION_TANDMS_BEGIN,
});

export const fetchTAndMsSuccess = (tandms) => ({
  type: FETCH_NEGOTIATION_TANDMS_SUCCESS,
  payload: { tandms },
});

export const fetchTAndMsFailure = (error) => ({
  type: FETCH_NEGOTIATION_TANDMS_FAILURE,
  payload: { error },
});

export const fetchPiecePricesBegin = () => ({
  type: FETCH_NEGOTIATION_PIECEPRICES_BEGIN,
});

export const fetchPiecePricesSuccess = (piecePrices) => ({
  type: FETCH_NEGOTIATION_PIECEPRICES_SUCCESS,
  payload: { piecePrices },
});

export const fetchPiecePricesFailure = (error) => ({
  type: FETCH_NEGOTIATION_PIECEPRICES_FAILURE,
  payload: { error },
});

export const fetchFixedPricesBegin = () => ({
  type: FETCH_NEGOTIATION_FIXEDPRICES_BEGIN,
});

export const fetchFixedPricesSuccess = (fixedPrices) => ({
  type: FETCH_NEGOTIATION_FIXEDPRICES_SUCCESS,
  payload: { fixedPrices },
});

export const fetchFixedPricesFailure = (error) => ({
  type: FETCH_NEGOTIATION_FIXEDPRICES_FAILURE,
  payload: { error },
});

export const fetchRatecardsBegin = () => ({
  type: FETCH_NEGOTIATION_RATECARDS_BEGIN,
});

export const fetchRatecardsSuccess = (ratecards) => ({
  type: FETCH_NEGOTIATION_RATECARDS_SUCCESS,
  payload: { ratecards },
});

export const fetchRatecardsFailure = (error) => ({
  type: FETCH_NEGOTIATION_RATECARDS_FAILURE,
  payload: { error },
});

export const selectSla = (id) => ({
  type: SELECT_NEGOTIATION_SLA,
  payload: { id },
});

export const clearSlas = () => ({
  type: CLEAR_NEGOTIATION_SLAS_ARRAY,
});

export const fetchSlasBegin = () => ({
  type: FETCH_NEGOTIATION_SLAS_BEGIN,
});

export const fetchSlasSuccess = (slas) => ({
  type: FETCH_NEGOTIATION_SLAS_SUCCESS,
  payload: { slas },
});

export const fetchSlasFailure = (error) => ({
  type: FETCH_NEGOTIATION_SLAS_FAILURE,
  payload: { error },
});

export const fetchAssumptionsBegin = () => ({
  type: FETCH_NEGOTIATION_ASSUMPTIONS_BEGIN,
});

export const fetchAssumptionsSuccess = (assumptions) => ({
  type: FETCH_NEGOTIATION_ASSUMPTIONS_SUCCESS,
  payload: { assumptions },
});

export const fetchAssumptionsFailure = (error) => ({
  type: FETCH_NEGOTIATION_ASSUMPTIONS_FAILURE,
  payload: { error },
});

export const fetchProvisionsBegin = () => ({
  type: FETCH_NEGOTIATION_PROVISIONS_BEGIN,
});

export const fetchProvisionsSuccess = (provisions) => ({
  type: FETCH_NEGOTIATION_PROVISIONS_SUCCESS,
  payload: { provisions },
});

export const fetchProvisionsFailure = (error) => ({
  type: FETCH_NEGOTIATION_PROVISIONS_FAILURE,
  payload: { error },
});

export const selectAssumption = (id) => ({
  type: SELECT_NEGOTIATION_ASSUMPTION,
  payload: { id },
});

export const selectProvision = (id) => ({
  type: SELECT_NEGOTIATION_PROVISION,
  payload: { id },
});

export const publishNegotiationBegin = () => ({
  type: PUBLISH_NEGOTIATION_BEGIN,
});

export const publishNegotiationSuccess = () => ({
  type: PUBLISH_NEGOTIATION_SUCCESS,
});

export const publishNegotiationFailure = (error) => ({
  type: PUBLISH_NEGOTIATION_FAILURE,
  payload: { error },
});

export const selectProject = (project) => ({
  type: NEGOTIATION_SELECT_PROJECT,
  payload: { project },
});

export const selectTemplate = (template) => ({
  type: NEGOTIATION_SELECT_TEMPLATE,
  payload: { template },
});

export const selectModule = (module) => ({
  type: NEGOTIATION_SELECT_MODULE,
  payload: { module },
});

export const selectStatement = (statement) => ({
  type: NEGOTIATION_SELECT_STATEMENT,
  payload: { statement },
});

export const clearProjects = () => ({
  type: NEGOTIATION_CLEAR_PROJECTS_ARRAY,
});

export const clearTemplates = () => ({
  type: NEGOTIATION_CLEAR_TEMPLATES_ARRAY,
});

export const clearModules = () => ({
  type: NEGOTIATION_CLEAR_MODULES_ARRAY,
});

export const clearStatements = () => ({
  type: NEGOTIATION_CLEAR_STATEMENTS_ARRAY,
});

export const fetchProjectsBegin = () => ({
  type: NEGOTIATION_FETCH_PROJECTS_BEGIN,
});

export const fetchProjectsSuccess = (projects) => ({
  type: NEGOTIATION_FETCH_PROJECTS_SUCCESS,
  payload: { projects },
});

export const fetchProjectsFailure = (error) => ({
  type: NEGOTIATION_FETCH_PROJECTS_FAILURE,
  payload: { error },
});

export const fetchTemplatesBegin = () => ({
  type: NEGOTIATION_FETCH_TEMPLATES_BEGIN,
});

export const fetchTemplatesSuccess = (templates) => ({
  type: NEGOTIATION_FETCH_TEMPLATES_SUCCESS,
  payload: { templates },
});

export const fetchTemplatesFailure = (error) => ({
  type: NEGOTIATION_FETCH_TEMPLATES_FAILURE,
  payload: { error },
});

export const fetchModulesBegin = () => ({
  type: NEGOTIATION_FETCH_MODULES_BEGIN,
});

export const fetchModulesSuccess = (modules) => ({
  type: NEGOTIATION_FETCH_MODULES_SUCCESS,
  payload: { modules },
});

export const fetchModulesFailure = (error) => ({
  type: NEGOTIATION_FETCH_MODULES_FAILURE,
  payload: { error },
});

export const fetchStatementsBegin = () => ({
  type: NEGOTIATION_FETCH_STATEMENTS_BEGIN,
});

export const fetchStatementsSuccess = (statements) => ({
  type: NEGOTIATION_FETCH_STATEMENTS_SUCCESS,
  payload: { statements },
});

export const fetchStatementsFailure = (error) => ({
  type: NEGOTIATION_FETCH_STATEMENTS_FAILURE,
  payload: { error },
});

export const addTemplateBegin = () => ({
  type: ADD_NEGOTIATION_TEMPLATE_BEGIN,
});

export const addTemplateSuccess = () => ({
  type: ADD_NEGOTIATION_TEMPLATE_SUCCESS,
});

export const addTemplateFailure = (error) => ({
  type: ADD_NEGOTIATION_TEMPLATE_FAILURE,
  payload: { error },
});

export const updateTemplateBegin = () => ({
  type: UPDATE_NEGOTIATION_TEMPLATE_BEGIN,
});

export const updateTemplateSuccess = () => ({
  type: UPDATE_NEGOTIATION_TEMPLATE_SUCCESS,
});

export const updateTemplateFailure = (error) => ({
  type: UPDATE_NEGOTIATION_TEMPLATE_FAILURE,
  payload: { error },
});

export const deleteTemplateBegin = () => ({
  type: DELETE_NEGOTIATION_TEMPLATE_BEGIN,
});

export const deleteTemplateSuccess = () => ({
  type: DELETE_NEGOTIATION_TEMPLATE_SUCCESS,
});

export const deleteTemplateFailure = (error) => ({
  type: DELETE_NEGOTIATION_TEMPLATE_FAILURE,
  payload: { error },
});

export const addModuleBegin = () => ({
  type: ADD_NEGOTIATION_MODULE_BEGIN,
});

export const addModuleSuccess = () => ({
  type: ADD_NEGOTIATION_MODULE_SUCCESS,
});

export const addModuleFailure = (error) => ({
  type: ADD_NEGOTIATION_MODULE_FAILURE,
  payload: { error },
});

export const updateModuleBegin = () => ({
  type: UPDATE_NEGOTIATION_MODULE_BEGIN,
});

export const updateModuleSuccess = () => ({
  type: UPDATE_NEGOTIATION_MODULE_SUCCESS,
});

export const updateModuleFailure = (error) => ({
  type: UPDATE_NEGOTIATION_MODULE_FAILURE,
  payload: { error },
});

export const deleteModuleBegin = () => ({
  type: DELETE_NEGOTIATION_MODULE_BEGIN,
});

export const deleteModuleSuccess = () => ({
  type: DELETE_NEGOTIATION_MODULE_SUCCESS,
});

export const deleteModuleFailure = (error) => ({
  type: DELETE_NEGOTIATION_MODULE_FAILURE,
  payload: { error },
});

export const updateModulePricingBegin = () => ({
  type: UPDATE_NEGOTIATION_MODULE_PRICING_BEGIN,
});

export const updateModulePricingSuccess = () => ({
  type: UPDATE_NEGOTIATION_MODULE_PRICING_SUCCESS,
});

export const updateModulePricingFailure = (error) => ({
  type: UPDATE_NEGOTIATION_MODULE_PRICING_FAILURE,
  payload: { error },
});

export const updateModuleSlaBegin = () => ({
  type: UPDATE_NEGOTIATION_MODULE_SLA_BEGIN,
});

export const updateModuleSlaSuccess = () => ({
  type: UPDATE_NEGOTIATION_MODULE_SLA_SUCCESS,
});

export const updateModuleSlaFailure = (error) => ({
  type: UPDATE_NEGOTIATION_MODULE_SLA_FAILURE,
  payload: { error },
});

export const templateNumberDownBegin = () => ({
  type: NEGOTIATION_TEMPLATE_NUMBER_DOWN_BEGIN,
});

export const templateNumberDownSuccess = () => ({
  type: NEGOTIATION_TEMPLATE_NUMBER_DOWN_SUCCESS,
});

export const templateNumberDownFailure = (error) => ({
  type: NEGOTIATION_TEMPLATE_NUMBER_DOWN_FAILURE,
  payload: { error },
});

export const templateNumberUpBegin = () => ({
  type: NEGOTIATION_TEMPLATE_NUMBER_UP_BEGIN,
});

export const templateNumberUpSuccess = () => ({
  type: NEGOTIATION_TEMPLATE_NUMBER_UP_SUCCESS,
});

export const templateNumberUpFailure = (error) => ({
  type: NEGOTIATION_TEMPLATE_NUMBER_UP_FAILURE,
  payload: { error },
});

export const moduleNumberDownBegin = () => ({
  type: NEGOTIATION_MODULE_NUMBER_DOWN_BEGIN,
});

export const moduleNumberDownSuccess = () => ({
  type: NEGOTIATION_MODULE_NUMBER_DOWN_SUCCESS,
});

export const moduleNumberDownFailure = (error) => ({
  type: NEGOTIATION_MODULE_NUMBER_DOWN_FAILURE,
  payload: { error },
});

export const moduleNumberUpBegin = () => ({
  type: NEGOTIATION_MODULE_NUMBER_UP_BEGIN,
});

export const moduleNumberUpSuccess = () => ({
  type: NEGOTIATION_MODULE_NUMBER_UP_SUCCESS,
});

export const moduleNumberUpFailure = (error) => ({
  type: NEGOTIATION_MODULE_NUMBER_UP_FAILURE,
  payload: { error },
});

export const statementNumberDownBegin = () => ({
  type: NEGOTIATION_STATEMENT_NUMBER_DOWN_BEGIN,
});

export const statementNumberDownSuccess = () => ({
  type: NEGOTIATION_STATEMENT_NUMBER_DOWN_SUCCESS,
});

export const statementNumberDownFailure = (error) => ({
  type: NEGOTIATION_STATEMENT_NUMBER_DOWN_FAILURE,
  payload: { error },
});

export const statementNumberUpBegin = () => ({
  type: NEGOTIATION_STATEMENT_NUMBER_UP_BEGIN,
});

export const statementNumberUpSuccess = () => ({
  type: NEGOTIATION_STATEMENT_NUMBER_UP_SUCCESS,
});

export const statementNumberUpFailure = (error) => ({
  type: NEGOTIATION_STATEMENT_NUMBER_UP_FAILURE,
  payload: { error },
});

export const fetchProjects = () => (dispatch) => {
  dispatch(fetchProjectsBegin());
  return axios('/api/v1/negotiation/projects')
    .then((res) => {
      const projects = res.data;
      dispatch(fetchProjectsSuccess(projects));
    })
    .catch((error) => dispatch(fetchProjectsFailure(error)));
};

export const deleteProject = (id) => (dispatch) => {
  dispatch(deleteProjectBegin());
  return axios
    .delete(`/api/v1/negotiation/projects/${id}`, {})
    .then(() => {
      dispatch(deleteProjectSuccess(id));
      dispatch(
        enqueueSnackbar({
          message: 'Project erfolgreich gelöscht.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(deleteProjectFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Projekt konnte nicht gelöscht werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

// fetching templates from database
export const fetchTemplates = (projectId) => (dispatch) => {
  dispatch(fetchTemplatesBegin());
  return axios(`/api/v1/negotiation/projects/${projectId}/templates`)
    .then((res) => {
      const templates = res.data;
      dispatch(fetchTemplatesSuccess(templates));
      return templates;
    })
    .catch((error) => dispatch(fetchTemplatesFailure(error)));
};

export const addTemplate = (projectId, name) => (dispatch) => {
  dispatch(addTemplateBegin());
  return axios
    .post('/api/v1/negotiation/templates', {
      name,
      projectId,
    })
    .then(() => {
      dispatch(addTemplateSuccess());
      // fetching Templates after success
      dispatch(fetchTemplates(projectId));
      dispatch(
        enqueueSnackbar({
          message: 'Template erfolgreich hinzugefügt.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(addTemplateFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Template konnte nicht hinzugefügt werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const updateTemplate = (templateId, name, projectId) => (dispatch) => {
  dispatch(updateTemplateBegin());
  return axios
    .put(`/api/v1/negotiation/templates/${templateId}`, {
      name,
      projectId,
    })
    .then((res) => {
      const template = res.data;
      dispatch(updateTemplateSuccess());
      // fetching Templates after success
      dispatch(fetchTemplates(template.project));
      dispatch(
        enqueueSnackbar({
          message: 'Template erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(updateTemplateFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Template konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const deleteTemplate = (templateId) => (dispatch) => {
  dispatch(deleteTemplateBegin());
  return axios
    .delete(`/api/v1/negotiation/templates/${templateId}`)
    .then((res) => {
      const template = res.data;
      dispatch(deleteTemplateSuccess());
      // fetching Templates after success
      dispatch(fetchTemplates(template.project));
      dispatch(
        enqueueSnackbar({
          message: 'Template erfolgreich gelöscht.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(deleteTemplateFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'Template erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

// fetching modules from datatbase
export const fetchModules = (templateId) => (dispatch) => {
  dispatch(fetchModulesBegin());
  return axios(`/api/v1/negotiation/templates/${templateId}/modules`)
    .then((res) => {
      const modules = res.data;
      dispatch(fetchModulesSuccess(modules));
    })
    .catch((error) => dispatch(fetchModulesFailure(error)));
};

// fetching statements from database
export const fetchStatements = (moduleId) => (dispatch) => {
  dispatch(fetchStatementsBegin());
  return axios(`/api/v1/negotiation/modules/${moduleId}/statements`)
    .then((res) => {
      const statements = res.data;
      dispatch(fetchStatementsSuccess(statements));
    })
    .catch((error) => dispatch(fetchStatementsFailure(error)));
};

export const publishNegotiation = (id, deadline) => (dispatch) => {
  dispatch(publishNegotiationBegin());
  return axios
    .put(`/api/v1/negotiation/projects/${id}/publish`, { deadline })
    .then(() => {
      dispatch(publishNegotiationSuccess());
      dispatch(fetchProjects());
      dispatch(
        enqueueSnackbar({
          message: 'Verhandlung erfolgreich veröffentlicht.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(publishNegotiationFailure(error));
      if (error.response.data.length > 0) {
        dispatch(
          enqueueSnackbar({
            message:
              'FEHLER: Verhandlung konnte nicht veröffentlicht werden, da noch Verhandslungsergebnisse fehlen.',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              autoHideDuration: 5000,
            },
          }),
        );
      } else {
        dispatch(
          enqueueSnackbar({
            message: 'FEHLER: Verhandlung konnte nicht veröffentlicht werden.',
            options: {
              key: new Date().getTime() + Math.random(),
              variant: 'error',
              autoHideDuration: 3000,
            },
          }),
        );
      }
    });
};

export const fetchAssumptions = (projectId) => (dispatch) => {
  dispatch(fetchAssumptionsBegin());
  return axios
    .get(`/api/v1/negotiation/projects/${projectId}/assumptions`, {})
    .then((res) => {
      const assumptions = res.data;
      dispatch(fetchAssumptionsSuccess(assumptions));
    })
    .catch((error) => dispatch(fetchAssumptionsFailure(error)));
};

export const fetchProvisions = (projectId) => (dispatch) => {
  dispatch(fetchProvisionsBegin());
  return axios
    .get(`/api/v1/negotiation/projects/${projectId}/provisions`, {})
    .then((res) => {
      const provisions = res.data;
      dispatch(fetchProvisionsSuccess(provisions));
    })
    .catch((error) => dispatch(fetchProvisionsFailure(error)));
};

export const fetchSlas = (projectId) => (dispatch) => {
  dispatch(fetchSlasBegin());
  return axios
    .get(`/api/v1/negotiation/projects/${projectId}/slas`, {})
    .then((res) => {
      dispatch(fetchSlasSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchSlasFailure(error)));
};

export const fetchFixedPrices = (projectId) => (dispatch) => {
  dispatch(fetchFixedPricesBegin());
  return axios
    .get(`/api/v1/negotiation/projects/${projectId}/fixedprices`, {})
    .then((res) => {
      dispatch(fetchFixedPricesSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchFixedPricesFailure(error)));
};

export const fetchPiecePrices = (projectId) => (dispatch) => {
  dispatch(fetchPiecePricesBegin());
  return axios
    .get(`/api/v1/negotiation/projects/${projectId}/pieceprices`, {})
    .then((res) => {
      dispatch(fetchPiecePricesSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchPiecePricesFailure(error)));
};

export const fetchTAndMs = (projectId) => (dispatch) => {
  dispatch(fetchTAndMsBegin());
  return axios
    .get(`/api/v1/negotiation/projects/${projectId}/tandms`, {})
    .then((res) => {
      dispatch(fetchTAndMsSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchTAndMsFailure(error)));
};

export const fetchRatecards = (projectId) => (dispatch) => {
  dispatch(fetchRatecardsBegin());
  return axios
    .get(`/api/v1/negotiation/projects/${projectId}/ratecards`, {})
    .then((res) => {
      dispatch(fetchRatecardsSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchRatecardsFailure(error)));
};

export const updateStatement = (id, description, result) => (dispatch) => {
  dispatch(updateStatementBegin());
  return axios
    .put(`/api/v1/negotiation/statements/${id}`, {
      description,
      result,
    })
    .then((res) => {
      const statement = res.data;
      dispatch(updateStatementSuccess());
      dispatch(fetchStatements(statement.module));
      dispatch(
        enqueueSnackbar({
          message: 'Statement erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(updateStatementFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Statement konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const makeContract = (id) => (dispatch) => {
  dispatch(makeContractBegin());
  return axios
    .put(`/api/v1/negotiation/projects/${id}/makecontract`, {})
    .then((res) => {
      dispatch(makeContractSuccess());
      dispatch(fetchProjects());
      return res;
    })
    .catch((error) => dispatch(makeContractFailure(error)));
};

export const fetchContractTemplates = (id) => (dispatch) => {
  dispatch(fetchContractTemplatesBegin());
  return axios
    .get(`/api/v1/negotiation/projects/${id}/templates`, {})
    .then((res) => {
      dispatch(fetchContractTemplatesSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchContractTemplatesFailure(error)));
};

export const fetchContractModules = (id) => (dispatch) => {
  dispatch(fetchContractTemplatesBegin());
  return axios
    .get(`/api/v1/negotiation/projects/${id}/modules`, {})
    .then((res) => {
      dispatch(fetchContractModulesSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchContractModulesFailure(error)));
};

export const fetchContractStatements = (id) => (dispatch) => {
  dispatch(fetchContractStatementsBegin());
  return axios
    .get(`/api/v1/negotiation/projects/${id}/statements`, {})
    .then((res) => {
      dispatch(fetchContractStatementsSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchContractStatementsFailure(error)));
};

export const fetchNegotiationInformation = (id) => (dispatch) => {
  dispatch(fetchNegotiationInformationBegin());
  return axios
    .get(`/api/v1/negotiation/projects/${id}/information`, {})
    .then((res) => {
      dispatch(fetchNegotiationInformationSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchNegotiationInformationFailure(error)));
};

export const fetchNegotiationReferences = (projectId) => (dispatch) => {
  dispatch(fetchNegotiationReferencesBegin());
  return axios
    .post('/graphql', {
      query: `{
          negotiationReferences(projectId: "${projectId}") {
            _id,
            number,
            question,
            answer,
            company,
            project {
              _id
            },
          }
        }`,
    })
    .then((res) => {
      const references = res.data.data.negotiationReferences;
      dispatch(fetchNegotiationReferencesSuccess(references));
      return references;
    })
    .catch((error) => dispatch(fetchNegotiationReferencesFailure(error)));
};

export const fetchNegotiationSubcontractors = (projectId) => (dispatch) => {
  dispatch(fetchNegotiationSubcontractorsBegin());
  return axios
    .post('/graphql', {
      query: `{
          negotiationSubcontractors(projectId: "${projectId}") {
            _id,
            number,
            question,
            answer,
            company,
            project {
              _id
            },
          }
        }`,
    })
    .then((res) => {
      const subcontractors = res.data.data.negotiationSubcontractors;
      dispatch(fetchNegotiationSubcontractorsSuccess(subcontractors));
      return subcontractors;
    })
    .catch((error) => dispatch(fetchNegotiationSubcontractorsFailure(error)));
};

export const addModule = (templateId, moduleName, moduleHeading, projectId) => (
  dispatch,
) => {
  dispatch(addModuleBegin());
  return axios
    .post('/api/v1/negotiation/modules', {
      name: moduleName,
      heading: moduleHeading,
      templateId,
      projectId,
    })
    .then(() => {
      // const module = res.data;
      dispatch(addModuleSuccess());
      // fetching Modules after success
      dispatch(fetchModules(templateId));
      dispatch(
        enqueueSnackbar({
          message: 'Modul erfolgreich hinzugefügt.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(addModuleFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Modul konnte nicht hinzugefügt werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const updateModule = (moduleId, name, heading, templateId) => (
  dispatch,
) => {
  dispatch(updateModuleBegin());
  return axios
    .put(`/api/v1/negotiation/modules/${moduleId}`, {
      name,
      heading,
      templateId,
    })
    .then((res) => {
      const module = res.data;
      dispatch(updateModuleSuccess());
      // fetching Modules after success
      dispatch(fetchModules(module.template));
      dispatch(
        enqueueSnackbar({
          message: 'Modul erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(updateModuleFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Modul konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const deleteModule = (moduleId) => (dispatch) => {
  dispatch(deleteModuleBegin());
  return axios
    .delete(`/api/v1/negotiation/modules/${moduleId}`)
    .then((res) => {
      const module = res.data;
      dispatch(deleteModuleSuccess());
      // fetching Modules after success
      dispatch(fetchModules(module.template));
      dispatch(
        enqueueSnackbar({
          message: 'Modul erfolgreich gelöscht.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(deleteModuleFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Modul konnte nicht gelöscht werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const updateModulePricing = (id, pricing) => (dispatch) => {
  dispatch(updateModulePricingBegin());
  return axios
    .put(`/api/v1/negotiation/modules/${id}/pricing`, {
      pricing,
    })
    .then((res) => {
      const module = res.data;
      dispatch(updateModulePricingSuccess());
      dispatch(fetchModules(module.template));
      dispatch(
        enqueueSnackbar({
          message: 'Modul erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(updateModulePricingFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Modul konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const updateModuleSla = (id, projectId) => (dispatch) => {
  dispatch(updateModuleSlaBegin());
  return axios
    .put(`/api/v1/negotiation/modules/${id}/slas`, {
      projectId,
    })
    .then((res) => {
      const module = res.data;
      dispatch(updateModuleSlaSuccess());
      dispatch(fetchModules(module.template));
      dispatch(
        enqueueSnackbar({
          message: 'Modul erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(updateModuleSlaFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Modul konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const updatePiecePrice = (piecePriceId, unit, amount) => (dispatch) => {
  dispatch(updatePiecePriceBegin());
  return axios
    .put(`/api/v1/negotiation/pieceprices/${piecePriceId}`, {
      unit,
      amount,
    })
    .then((res) => {
      dispatch(updatePiecePriceSuccess());
      dispatch(fetchPiecePrices(res.data.project));
      dispatch(
        enqueueSnackbar({
          message: 'Stückpreis erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(updatePiecePriceFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Stückpreis konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const addPiecePrice = (projectId, moduleId) => (
  dispatch,
) => {
  dispatch(addPiecePriceBegin());
  return axios
    .post(`/api/v1/negotiation/module/${moduleId}/pieceprice/`)
    .then((res) => {
      dispatch(addPiecePriceSuccess());
      dispatch(fetchPiecePrices(res.data.project));
      return res;
    })
    .catch((error) => dispatch(addPiecePriceFailure(error)));
};

export const deletePiecePrice = (priceId, projectId) => (dispatch) => {
  dispatch(deletePiecePriceBegin());
  return axios
    .delete(`/api/v1/negotiation/pieceprice/${priceId}`, {})
    .then((res) => {
      dispatch(deletePiecePriceSuccess());
      dispatch(fetchPiecePrices(projectId));
      return res;
    })
    .catch((error) => {
      dispatch(deletePiecePriceFailure(error));
      dispatch(enqueueSnackbar({
        message: error.response.data,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      }));
    });
};

export const addSla = (projectId, moduleId) => (dispatch) => {
  dispatch(addSlaBegin());
  return axios
    .post('/api/v1/negotiation/slas', {
      projectId,
      moduleId,
    })
    .then((res) => {
      const sla = res.data;
      dispatch(addSlaSuccess());
      dispatch(fetchModules(sla.template));
      dispatch(fetchSlas(projectId));
      dispatch(
        enqueueSnackbar({
          message: 'Service Level erfolgreich hinzugefügt.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(addSlaFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Service Level konnte nicht hinzugefügt werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const updateSla = (
  slaId,
  priority,
  servicetime,
  availability,
  reactiontime,
  solutiontime,
  penalty,
  penaltyreference,
) => (dispatch) => {
  dispatch(updateSlaBegin());
  return axios
    .put(`/api/v1/negotiation/slas/${slaId}`, {
      priority,
      servicetime,
      availability,
      reactiontime,
      solutiontime,
      penalty,
      penaltyreference,
    })
    .then((res) => {
      dispatch(updateSlaSuccess());
      dispatch(fetchSlas(res.data.project._id));
      dispatch(
        enqueueSnackbar({
          message: 'Service Level erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(updateSlaFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Service Level konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const deleteSla = (slaId) => (dispatch) => {
  dispatch(deleteSlaBegin());
  return axios
    .delete(`/api/v1/negotiation/slas/${slaId}`, {})
    .then((res) => {
      dispatch(deleteSlaSuccess());
      dispatch(fetchSlas(res.data.project));
      dispatch(
        enqueueSnackbar({
          message: 'Service Level erfolgreich gelöscht.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(deleteSlaFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Service Level konnte nicht gelöscht werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const addRatecard = (projectId, profile, description) => (dispatch) => {
  dispatch(addRatecardBegin());
  return axios
    .post('/api/v1/negotiation/ratecards', {
      projectId,
      profile,
      description,
    })
    .then((res) => {
      dispatch(addRatecardSuccess());
      dispatch(fetchRatecards(res.data.project));
      dispatch(
        enqueueSnackbar({
          message: 'Rate Card Eintrag erfolgreich hinzugefügt.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(addRatecardFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Rate Card Eintrag konnte nicht hinzugefügt werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const updateRatecard = (ratecardId, profile, description) => (
  dispatch,
) => {
  dispatch(updateRatecardBegin());
  return axios
    .put(`/api/v1/negotiation/ratecards/${ratecardId}`, {
      profile,
      description,
    })
    .then((res) => {
      dispatch(updateRatecardSuccess());
      dispatch(fetchRatecards(res.data.project));
      dispatch(
        enqueueSnackbar({
          message: 'Rate Card Eintrag erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(updateRatecardFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Rate Card Eintrag konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const deleteRatecard = (ratecardId) => (dispatch) => {
  dispatch(deleteRatecardBegin());
  return axios
    .delete(`/api/v1/negotiation/ratecards/${ratecardId}`)
    .then((res) => {
      dispatch(deleteRatecardSuccess());
      dispatch(fetchRatecards(res.data.project));
      dispatch(
        enqueueSnackbar({
          message: 'Rate Card Eintrag erfolgreich gelöscht.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(deleteRatecardFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Rate Card Eintrag konnte nicht gelöscht werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const addStatement = (moduleId, statementDescription) => (dispatch) => {
  dispatch(addStatementBegin());
  return axios
    .post('/api/v1/negotiation/statements', {
      moduleId,
      description: statementDescription,
    })
    .then((res) => {
      const statement = res.data;
      dispatch(addStatementSuccess());
      dispatch(fetchStatements(statement.module));
      // fetching Statements after success
      dispatch(
        enqueueSnackbar({
          message: 'Statement erfolgreich hinzugefügt.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(addStatementFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Statement konnte nicht hinzugefügt werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const deleteStatement = (statementId) => (dispatch) => {
  dispatch(deleteStatementBegin());
  return axios
    .delete(`/api/v1/negotiation/statements/${statementId}`)
    .then((res) => {
      const statement = res.data;
      dispatch(deleteStatementSuccess());
      // fetching Statements after success
      dispatch(fetchStatements(statement.module));
      dispatch(
        enqueueSnackbar({
          message: 'Statement erfolgreich gelöscht.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(deleteStatementFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Statement konnte nicht gelöscht werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const updateStatementResponsibilities = (id) => (dispatch) => {
  dispatch(updateStatementResponsibilitiesBegin());
  return axios
    .put(`/api/v1/negotiation/statements/${id}/responsibilities`, {})
    .then((res) => {
      const statement = res.data;
      dispatch(updateStatementResponsibilitiesSuccess());
      dispatch(fetchStatements(statement.module));
      dispatch(
        enqueueSnackbar({
          message: 'Statement erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(updateStatementResponsibilitiesFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Statement konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const fetchProjectEditors = (projectId) => (dispatch) => {
  dispatch(fetchProjectEditorsBegin());
  return axios(`api/v1/negotiation/editors/${projectId}`)
    .then((res) => {
      const editors = res.data;
      dispatch(fetchProjectEditorsSuccess(editors));
      return editors;
    })
    .catch((error) => dispatch(fetchProjectEditorsFailure(error)));
};

export const fetchProjectViewers = (projectId) => (dispatch) => {
  dispatch(fetchProjectViewersBegin());
  return axios(`api/v1/negotiation/viewers/${projectId}`)
    .then((res) => {
      const viewers = res.data;
      dispatch(fetchProjectViewersSuccess(viewers));
      return viewers;
    })
    .catch((error) => dispatch(fetchProjectViewersFailure(error)));
};

export const updateEditors = (projectId, email, option) => (dispatch) => {
  dispatch(updateEditorsBegin());
  return axios
    .put(`/api/v1/negotiation/editors/${projectId}`, {
      email,
      option,
    })
    .then(() => {
      dispatch(updateEditorsSuccess());
      dispatch(fetchProjectEditors(projectId));
    })
    .catch((error) => dispatch(updateEditorsFailure(error)));
};

export const updateViewers = (projectId, email, option) => (dispatch) => {
  dispatch(updateViewersBegin());
  return axios
    .put(`/api/v1/negotiation/viewers/${projectId}`, {
      email,
      option,
    })
    .then(() => {
      dispatch(updateViewersSuccess());
      dispatch(fetchProjectViewers(projectId));
    })
    .catch((error) => dispatch(updateViewersFailure(error)));
};

export const templateNumberUp = (id) => (dispatch) => {
  dispatch(templateNumberUpBegin());
  return axios
    .put(`/api/v1/negotiation/templates/${id}/numberup`, {})
    .then((res) => {
      const template = res.data;
      dispatch(templateNumberUpSuccess());
      dispatch(fetchTemplates(template.project));
      dispatch(
        enqueueSnackbar({
          message: 'Template erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(templateNumberUpFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Template konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const templateNumberDown = (id) => (dispatch) => {
  dispatch(templateNumberDownBegin());
  return axios
    .put(`/api/v1/negotiation/templates/${id}/numberdown`, {})
    .then((res) => {
      const template = res.data;
      dispatch(templateNumberDownSuccess());
      dispatch(fetchTemplates(template.project));
      dispatch(
        enqueueSnackbar({
          message: 'Template erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(templateNumberDownFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Template konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const moduleNumberDown = (id) => (dispatch) => {
  dispatch(moduleNumberDownBegin());
  return axios
    .put(`/api/v1/negotiation/modules/${id}/numberdown`, {})
    .then((res) => {
      const module = res.data;
      dispatch(moduleNumberDownSuccess());
      dispatch(fetchModules(module.template));
      dispatch(
        enqueueSnackbar({
          message: 'Modul erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(moduleNumberDownFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Modul konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const moduleNumberUp = (id) => (dispatch) => {
  dispatch(moduleNumberUpBegin());
  return axios
    .put(`/api/v1/negotiation/modules/${id}/numberup`, {})
    .then((res) => {
      const module = res.data;
      dispatch(moduleNumberUpSuccess());
      dispatch(fetchModules(module.template));
      dispatch(
        enqueueSnackbar({
          message: 'Modul erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(moduleNumberUpFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Modul konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const statementNumberDown = (id) => (dispatch) => {
  dispatch(statementNumberDownBegin());
  return axios
    .put(`/api/v1/negotiation/statements/${id}/numberdown`, {})
    .then((res) => {
      const statement = res.data;
      dispatch(statementNumberDownSuccess());
      dispatch(fetchStatements(statement.module));
      dispatch(
        enqueueSnackbar({
          message: 'Statement erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(statementNumberDownFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Statement konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const statementNumberUp = (id) => (dispatch) => {
  dispatch(statementNumberUpBegin());
  return axios
    .put(`/api/v1/negotiation/statements/${id}/numberup`, {})
    .then((res) => {
      const statement = res.data;
      dispatch(statementNumberUpSuccess());
      dispatch(fetchStatements(statement.module));
      dispatch(
        enqueueSnackbar({
          message: 'Statement erfolgreich bearbeitet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((error) => {
      dispatch(statementNumberUpFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Statement konnte nicht bearbeitet werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const fetchAdditionalFilesIsShowing = (projectId) => (dispatch) => {
  dispatch(fetchAdditionalFilesIsShowingBegin());
  return axios(`/api/v1/negotiation/projects/${projectId}/additionalfiles`)
    .then((res) => {
      const isShowing = res.data;
      dispatch(fetchAdditionalFilesIsShowingSuccess(isShowing));
    })
    .catch((error) => {
      dispatch(fetchAdditionalFilesIsShowingFailure(error));
    });
};

export const uploadNegotiationProjectFile = (formData, config) => (
  dispatch,
) => {
  dispatch(uploadNegotiationProjectFileBegin());
  return axios
    .post('/api/v1/upload/negotiation/', formData, config)
    .then((res) => {
      dispatch(uploadNegotiationProjectFileSuccess());
      dispatch(fetchProjects());
      return res;
    })
    .catch((error) => dispatch(uploadNegotiationProjectFileFailure(error)));
};

export const removeNegotiationProjectUpload = (id) => (dispatch) => {
  dispatch(removeNegotiationProjectUploadBegin());
  return axios
    .delete(`/api/v1/upload/negotiation/project/${id}`)
    .then((res) => {
      dispatch(removeNegotiationProjectUploadSuccess());
      dispatch(fetchProjects());
      return res;
    })
    .catch((error) => dispatch(removeNegotiationProjectUploadFailure(error)));
};

export const uploadNegotiationTemplateFile = (formData, config) => (
  dispatch,
) => {
  dispatch(uploadNegotiationTemplateFileBegin());
  return axios
    .post('/api/v1/upload/negotiation/', formData, config)
    .then((res) => {
      dispatch(uploadNegotiationTemplateFileSuccess());
      dispatch(fetchTemplates(res.data.project));
      return res;
    })
    .catch((error) => dispatch(uploadNegotiationTemplateFileFailure(error)));
};

export const removeNegotiationTemplateUpload = (id) => (dispatch) => {
  dispatch(removeNegotiationTemplateUploadBegin());
  return axios
    .delete(`/api/v1/upload/negotiation/template/${id}`)
    .then((res) => {
      dispatch(removeNegotiationTemplateUploadSuccess());
      dispatch(fetchTemplates(res.data.project));
      return res;
    })
    .catch((error) => dispatch(removeNegotiationTemplateUploadFailure(error)));
};

export const uploadNegotiationModuleFile = (formData, config) => (dispatch) => {
  dispatch(uploadNegotiationModuleFileBegin());
  return axios
    .post('/api/v1/upload/negotiation/', formData, config)
    .then((res) => {
      dispatch(uploadNegotiationModuleFileSuccess());
      dispatch(fetchModules(res.data.template));
      return res;
    })
    .catch((error) => dispatch(uploadNegotiationModuleFileFailure(error)));
};

export const removeNegotiationModuleUpload = (id) => (dispatch) => {
  dispatch(removeNegotiationModuleUploadBegin());
  return axios
    .delete(`/api/v1/upload/negotiation/module/${id}`)
    .then((res) => {
      dispatch(removeNegotiationModuleUploadSuccess());
      dispatch(fetchModules(res.data.template));
      return res;
    })
    .catch((error) => dispatch(removeNegotiationModuleUploadFailure(error)));
};

export const uploadNegotiationStatementFile = (formData, config) => (
  dispatch,
) => {
  dispatch(uploadNegotiationStatementFileBegin());
  return axios
    .post('/api/v1/upload/negotiation/', formData, config)
    .then((res) => {
      dispatch(uploadNegotiationStatementFileSuccess());
      dispatch(fetchStatements(res.data.module));
      return res;
    })
    .catch((error) => dispatch(uploadNegotiationStatementFileFailure(error)));
};

export const removeNegotiationStatementUpload = (id) => (dispatch) => {
  dispatch(removeNegotiationStatementUploadBegin());
  return axios
    .delete(`/api/v1/upload/negotiation/statement/${id}`)
    .then((res) => {
      dispatch(removeNegotiationStatementUploadSuccess());
      dispatch(fetchStatements(res.data.module));
      return res;
    })
    .catch((error) => dispatch(removeNegotiationModuleUploadFailure(error)));
};
