import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { blue } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  tableEditIconButton: {
    color: blue[300],
  },
}));

function TableEditIconButton(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <Tooltip title="Bearbeiten">
      <IconButton className={classes.tableEditIconButton} onClick={onClick}>
        <Edit />
      </IconButton>
    </Tooltip>
  );
}

export default TableEditIconButton;

TableEditIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
