import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import TableButton from '../Buttons/TableButton';
import DateStamp from '../Elements/DateStamp';
import UploadHandler from '../Elements/UploadHandler';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function ViewOfferingTemplatesTable(props) {
  const {
    projects,
    templates,
    selectedProject,
    selectProject,
    selectTemplate,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectSubcontractor,
    isShowing,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'number',
      label: '#',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { width: '50px' } }),
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <div>
            {!tableMeta.rowData[6] && (
              <TableButton
                text={value}
                onClick={() => selectTemplate(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[6] && value === 'Service Level' && (
              <TableButton
                text={value}
                onClick={() => selectSla(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[6] && value === 'Rate Card' && (
              <TableButton
                text={value}
                onClick={() => selectRatecard(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[6] && value === 'T&M' && (
              <TableButton
                text={value}
                onClick={() => selectTAndM(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[6] && value === 'Festpreise' && (
              <TableButton
                text={value}
                onClick={() => selectFixedPrice(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[6] && value === 'Stückpreise' && (
              <TableButton
                text={value}
                onClick={() => selectPiecePrice(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[6] && value === 'Informationen' && (
              <TableButton
                text={value}
                onClick={() => selectInformation(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[6] && value === 'Referenzen' && (
              <TableButton
                text={value}
                onClick={() => selectReference(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[6] && value === 'Subkontraktoren' && (
              <TableButton
                text={value}
                onClick={() => selectSubcontractor(tableMeta.rowData[0])}
              />
            )}
          </div>
        ),
      },
    },
    {
      name: 'attachment',
      label: 'Anhänge',
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta) => (
          <UploadHandler
            projectId={projects._id}
            referenceId={tableMeta.rowData[0]}
            url="/api/v1/upload/"
            referenceType="template"
            attachments={value}
            disabled
          />
        ),
      },
    },
    {
      name: 'dissenses',
      label: 'Dissense',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'updated',
      label: 'Geändert',
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta) => (
          <>{!tableMeta.rowData[6] && <DateStamp date={value} />}</>
        ),
      },
    },
    {
      name: 'isAdditional',
      label: 'Weiteres Dokument',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
  ];

  const data = [];

  templates
    .forEach((template) => {
      data.push({
        _id: template._id,
        number: template.number,
        name: template.name,
        attachment: template.attachments,
        dissenses: template.dissenses,
        updated: template.updated,
        isAdditional: false,
      });
    });

  // to do: show questions of selected provider only
  isShowing.forEach((temp) => {
    if (temp && temp !== 'Q&A') {
      data.push({
        _id: selectedProject,
        name: temp,
        attachment: [],
        isAdditional: true,
      });
    }
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectProject('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/viewoffering"
                onClick={() => selectProject('')}
              >
                Übersicht
              </Link>
              <Typography color="textPrimary">
                {projects.name}
              </Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({ sort: false })}
      />
    </div>
  );
}

export default ViewOfferingTemplatesTable;

ViewOfferingTemplatesTable.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectSla: PropTypes.func.isRequired,
  selectRatecard: PropTypes.func.isRequired,
  selectTAndM: PropTypes.func.isRequired,
  selectFixedPrice: PropTypes.func.isRequired,
  selectPiecePrice: PropTypes.func.isRequired,
  selectInformation: PropTypes.func.isRequired,
  selectReference: PropTypes.func.isRequired,
  selectSubcontractor: PropTypes.func.isRequired,
  isShowing: PropTypes.arrayOf(PropTypes.string).isRequired,
};
