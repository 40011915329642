import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_PRICES, GET_PROJECT } from '../../graphql/queries';
import { ANSWER_PRICE } from '../../graphql/mutations';

import {
  // fetchFixedPrices,
  selectFixedPrice,
  selectProject,
  selectTemplate,
  // updateFixedPrice,
} from '../../redux/actionCreators/offeringAction';
import OfferingFixedPricesTable from '../../components/OfferingManagement/OfferingFixedPricesTable';
import Loading from '../../components/Elements/Loading';

function OfferingFixedPricesContainer(props) {
  const {
    // fixedPrices,
    // projects,
    templates,
    // fetchFixedPrices,
    selectFixedPrice,
    selectProject,
    selectTemplate,
    selectedProject,
    // updateFixedPrice,
    // loading,
    // user,
  } = props;
  // useEffect(() => {
  //   fetchFixedPrices(selectedProject);
  // }, [fetchFixedPrices, selectedProject]);

  // fixedPrices.forEach((element) => {
  //   element.templateName = templates.filter((template) => template._id === element.template).map((template) => template.name).toString();
  // });

  const priceType = 'Festpreis';
  const { loading, error, data } = useQuery(GET_PRICES, {
    variables: { projectId: selectedProject, type: priceType },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const [answerPrice] = useMutation(ANSWER_PRICE, {
    refetchQueries: [{
      query: GET_PRICES,
      variables: { projectId: selectedProject, type: priceType },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }

  return (
    <OfferingFixedPricesTable
      fixedPrices={data.pricesByType}
      projects={data1.project}
      templates={templates}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectFixedPrice={selectFixedPrice}
      updateFixedPrice={answerPrice}
      // loading={loading}
      // user={user}
    />
  );
}

const mapStateToProps = (state) => ({
  fixedPrices: state.offering.fixedPrices,
  projects: state.offering.projects,
  templates: state.offering.templates,
  selectedProject: state.offering.selectedProject,
  loading: state.offering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchFixedPrices: (id) => dispatch(fetchFixedPrices(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectFixedPrice: (id) => dispatch(selectFixedPrice(id)),
  // updateFixedPrice: (fixedPriceId, price) =>
  //   dispatch(updateFixedPrice(fixedPriceId, price)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferingFixedPricesContainer),
);

OfferingFixedPricesContainer.propTypes = {
  // fetchFixedPrices: PropTypes.func.isRequired,
  selectFixedPrice: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // updateFixedPrice: PropTypes.func.isRequired,
  // fixedPrices: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // user: PropTypes.shape(Object),
};
