import {
  PROJECT_FETCH_PROJECTS_BEGIN,
  PROJECT_FETCH_PROJECTS_SUCCESS,
  PROJECT_FETCH_PROJECTS_FAILURE,
  PROJECT_FETCH_TEMPLATES_BEGIN,
  PROJECT_FETCH_TEMPLATES_SUCCESS,
  PROJECT_FETCH_TEMPLATES_FAILURE,
  PROJECT_FETCH_MODULES_BEGIN,
  PROJECT_FETCH_MODULES_SUCCESS,
  PROJECT_FETCH_MODULES_FAILURE,
  PROJECT_FETCH_STATEMENTS_BEGIN,
  PROJECT_FETCH_STATEMENTS_SUCCESS,
  PROJECT_FETCH_STATEMENTS_FAILURE,
  PROJECT_CLEAR_STATEMENTS_ARRAY,
  PROJECT_CLEAR_MODULES_ARRAY,
  PROJECT_CLEAR_TEMPLATES_ARRAY,
  PROJECT_CLEAR_PROJECTS_ARRAY,
  PROJECT_SELECT_PROJECT,
  PROJECT_SELECT_TEMPLATE,
  PROJECT_SELECT_MODULE,
  PROJECT_SELECT_STATEMENT,
  SELECT_PROJECT_TEMPLATE,
  SELECT_PROJECT_MODULE,
  REMOVE_PROJECT_TEMPLATE,
  REMOVE_PROJECT_MODULE,
  CLEAR_PROJECT_TEMPLATES,
  CLEAR_PROJECT_MODULES,
  CREATE_PROJECT_BEGIN,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  UPDATE_EDITORS_BEGIN,
  UPDATE_EDITORS_SUCCESS,
  UPDATE_EDITORS_FAILURE,
  FETCH_PROJECT_EDITORS_BEGIN,
  FETCH_PROJECT_EDITORS_SUCCESS,
  FETCH_PROJECT_EDITORS_FAILURE,
  PROJECT_DELETE_PROJECT_BEGIN,
  PROJECT_DELETE_PROJECT_SUCCESS,
  PROJECT_DELETE_PROJECT_FAILURE,
  PROJECT_UPDATE_PROJECT_BEGIN,
  PROJECT_UPDATE_PROJECT_SUCCESS,
  PROJECT_UPDATE_PROJECT_FAILURE,
  FETCH_PROJECT_VIEWERS_BEGIN,
  FETCH_PROJECT_VIEWERS_SUCCESS,
  FETCH_PROJECT_VIEWERS_FAILURE,
  UPDATE_VIEWERS_BEGIN,
  UPDATE_VIEWERS_SUCCESS,
  UPDATE_VIEWERS_FAILURE,
  FETCH_PROJECT_CONTRACTORS_BEGIN,
  FETCH_PROJECT_CONTRACTORS_SUCCESS,
  FETCH_PROJECT_CONTRACTORS_FAILURE,
  UPDATE_CONTRACTORS_BEGIN,
  UPDATE_CONTRACTORS_SUCCESS,
  UPDATE_CONTRACTORS_FAILURE,
  ADD_PROJECT_TEMPLATE_BEGIN,
  ADD_PROJECT_TEMPLATE_SUCCESS,
  ADD_PROJECT_TEMPLATE_FAILURE,
  ADD_PROJECT_MODULE_BEGIN,
  ADD_PROJECT_MODULE_SUCCESS,
  ADD_PROJECT_MODULE_FAILURE,
  ADD_PROJECT_STATEMENT_BEGIN,
  ADD_PROJECT_STATEMENT_SUCCESS,
  ADD_PROJECT_STATEMENT_FAILURE,
  UPDATE_PROJECT_TEMPLATE_BEGIN,
  UPDATE_PROJECT_TEMPLATE_SUCCESS,
  UPDATE_PROJECT_TEMPLATE_FAILURE,
  UPDATE_PROJECT_MODULE_BEGIN,
  UPDATE_PROJECT_MODULE_SUCCESS,
  UPDATE_PROJECT_MODULE_FAILURE,
  UPDATE_PROJECT_STATEMENT_BEGIN,
  UPDATE_PROJECT_STATEMENT_SUCCESS,
  UPDATE_PROJECT_STATEMENT_FAILURE,
  DELETE_PROJECT_TEMPLATE_BEGIN,
  DELETE_PROJECT_TEMPLATE_SUCCESS,
  DELETE_PROJECT_TEMPLATE_FAILURE,
  DELETE_PROJECT_MODULE_BEGIN,
  DELETE_PROJECT_MODULE_SUCCESS,
  DELETE_PROJECT_MODULE_FAILURE,
  DELETE_PROJECT_STATEMENT_BEGIN,
  DELETE_PROJECT_STATEMENT_SUCCESS,
  DELETE_PROJECT_STATEMENT_FAILURE,
  ADD_EXISTING_PROJECT_TEMPLATE_BEGIN,
  ADD_EXISTING_PROJECT_TEMPLATE_SUCCESS,
  ADD_EXISTING_PROJECT_TEMPLATE_FAILURE,
  ADD_EXISTING_PROJECT_MODULE_BEGIN,
  ADD_EXISTING_PROJECT_MODULE_SUCCESS,
  ADD_EXISTING_PROJECT_MODULE_FAILURE,
  PROJECT_STATEMENT_NUMBER_DOWN_BEGIN,
  PROJECT_STATEMENT_NUMBER_DOWN_SUCCESS,
  PROJECT_STATEMENT_NUMBER_DOWN_FAILURE,
  PROJECT_STATEMENT_NUMBER_UP_BEGIN,
  PROJECT_STATEMENT_NUMBER_UP_SUCCESS,
  PROJECT_STATEMENT_NUMBER_UP_FAILURE,
  PROJECT_MODULE_NUMBER_DOWN_BEGIN,
  PROJECT_MODULE_NUMBER_DOWN_SUCCESS,
  PROJECT_MODULE_NUMBER_DOWN_FAILURE,
  PROJECT_MODULE_NUMBER_UP_BEGIN,
  PROJECT_MODULE_NUMBER_UP_SUCCESS,
  PROJECT_MODULE_NUMBER_UP_FAILURE,
  PROJECT_TEMPLATE_NUMBER_DOWN_BEGIN,
  PROJECT_TEMPLATE_NUMBER_DOWN_SUCCESS,
  PROJECT_TEMPLATE_NUMBER_DOWN_FAILURE,
  PROJECT_TEMPLATE_NUMBER_UP_BEGIN,
  PROJECT_TEMPLATE_NUMBER_UP_SUCCESS,
  PROJECT_TEMPLATE_NUMBER_UP_FAILURE,
  FETCH_OVERVIEW_TEMPLATE_BEGIN,
  FETCH_OVERVIEW_TEMPLATE_SUCCESS,
  FETCH_OVERVIEW_TEMPLATE_FAILURE,
  FETCH_OVERVIEW_MODULES_BEGIN,
  FETCH_OVERVIEW_MODULES_SUCCESS,
  FETCH_OVERVIEW_MODULES_FAILURE,
  FETCH_OVERVIEW_STATEMENTS_BEGIN,
  FETCH_OVERVIEW_STATEMENTS_SUCCESS,
  FETCH_OVERVIEW_STATEMENTS_FAILURE,
  UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_BEGIN,
  UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_SUCCESS,
  UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_FAILURE,
  UPDATE_PROJECT_MODULE_PRICING_BEGIN,
  UPDATE_PROJECT_MODULE_PRICING_SUCCESS,
  UPDATE_PROJECT_MODULE_PRICING_FAILURE,
  UPDATE_PROJECT_MODULE_SLA_BEGIN,
  UPDATE_PROJECT_MODULE_SLA_SUCCESS,
  UPDATE_PROJECT_MODULE_SLA_FAILURE,
  FETCH_PROJECT_SLAS_BEGIN,
  FETCH_PROJECT_SLAS_SUCCESS,
  FETCH_PROJECT_SLAS_FAILURE,
  CLEAR_PROJECT_SLAS_ARRAY,
  UPDATE_PROJECT_SLA_BEGIN,
  UPDATE_PROJECT_SLA_SUCCESS,
  UPDATE_PROJECT_SLA_FAILURE,
  UPLOAD_PROJECT_PROJECT_FILE_BEGIN,
  UPLOAD_PROJECT_PROJECT_FILE_SUCCESS,
  UPLOAD_PROJECT_PROJECT_FILE_FAILURE,
  REMOVE_PROJECT_PROJECT_UPLOADS_BEGIN,
  REMOVE_PROJECT_PROJECT_UPLOADS_SUCCESS,
  REMOVE_PROJECT_PROJECT_UPLOADS_FAILURE,
  UPLOAD_PROJECT_TEMPLATE_FILE_BEGIN,
  UPLOAD_PROJECT_TEMPLATE_FILE_SUCCESS,
  UPLOAD_PROJECT_TEMPLATE_FILE_FAILURE,
  REMOVE_PROJECT_TEMPLATE_UPLOADS_BEGIN,
  REMOVE_PROJECT_TEMPLATE_UPLOADS_SUCCESS,
  REMOVE_PROJECT_TEMPLATE_UPLOADS_FAILURE,
  UPLOAD_PROJECT_MODULE_FILE_BEGIN,
  UPLOAD_PROJECT_MODULE_FILE_SUCCESS,
  UPLOAD_PROJECT_MODULE_FILE_FAILURE,
  REMOVE_PROJECT_MODULE_UPLOADS_BEGIN,
  REMOVE_PROJECT_MODULE_UPLOADS_SUCCESS,
  REMOVE_PROJECT_MODULE_UPLOADS_FAILURE,
  UPLOAD_PROJECT_STATEMENT_FILE_BEGIN,
  UPLOAD_PROJECT_STATEMENT_FILE_SUCCESS,
  UPLOAD_PROJECT_STATEMENT_FILE_FAILURE,
  REMOVE_PROJECT_STATEMENT_UPLOADS_BEGIN,
  REMOVE_PROJECT_STATEMENT_UPLOADS_SUCCESS,
  REMOVE_PROJECT_STATEMENT_UPLOADS_FAILURE,
  UPLOAD_PROJECT_QANDA_FILE_BEGIN,
  UPLOAD_PROJECT_QANDA_FILE_SUCCESS,
  UPLOAD_PROJECT_QANDA_FILE_FAILURE,
  REMOVE_PROJECT_QANDA_UPLOADS_BEGIN,
  REMOVE_PROJECT_QANDA_UPLOADS_SUCCESS,
  REMOVE_PROJECT_QANDA_UPLOADS_FAILURE,
  SELECT_PROJECT_RATECARD,
  SELECT_PROJECT_TANDM,
  SELECT_PROJECT_FIXEDPRICE,
  SELECT_PROJECT_PIECEPRICE,
  SELECT_PROJECT_SLA,
  FETCH_PROJECT_RATECARD_BEGIN,
  FETCH_PROJECT_RATECARD_SUCCESS,
  FETCH_PROJECT_RATECARD_FAILURE,
  ADD_PROJECT_SLA_BEGIN,
  ADD_PROJECT_SLA_SUCCESS,
  ADD_PROJECT_SLA_FAILURE,
  DELETE_PROJECT_SLA_BEGIN,
  DELETE_PROJECT_SLA_SUCCESS,
  DELETE_PROJECT_SLA_FAILURE,
  FETCH_PROJECT_FIXEDPRICES_BEGIN,
  FETCH_PROJECT_FIXEDPRICES_SUCCESS,
  FETCH_PROJECT_FIXEDPRICES_FAILURE,
  FETCH_PROJECT_PIECEPRICES_BEGIN,
  FETCH_PROJECT_PIECEPRICES_SUCCESS,
  FETCH_PROJECT_PIECEPRICES_FAILURE,
  FETCH_PROJECT_TANDMS_BEGIN,
  FETCH_PROJECT_TANDMS_SUCCESS,
  FETCH_PROJECT_TANDMS_FAILURE,
  ADD_PROJECT_RATECARD_BEGIN,
  ADD_PROJECT_RATECARD_SUCCESS,
  ADD_PROJECT_RATECARD_FAILURE,
  UPDATE_PROJECT_RATECARD_BEGIN,
  UPDATE_PROJECT_RATECARD_SUCCESS,
  UPDATE_PROJECT_RATECARD_FAILURE,
  DELETE_PROJECT_RATECARD_BEGIN,
  DELETE_PROJECT_RATECARD_SUCCESS,
  DELETE_PROJECT_RATECARD_FAILURE,
  UPDATE_PROJECT_PIECEPRICE_BEGIN,
  UPDATE_PROJECT_PIECEPRICE_SUCCESS,
  UPDATE_PROJECT_PIECEPRICE_FAILURE,
  SELECT_PROJECT_INFORMATION,
  FETCH_PROJECT_INFORMATION_BEGIN,
  FETCH_PROJECT_INFORMATION_SUCCESS,
  FETCH_PROJECT_INFORMATION_FAILURE,
  ADD_PROJECT_INFORMATION_BEGIN,
  ADD_PROJECT_INFORMATION_SUCCESS,
  ADD_PROJECT_INFORMATION_FAILURE,
  UPDATE_PROJECT_INFORMATION_BEGIN,
  UPDATE_PROJECT_INFORMATION_SUCCESS,
  UPDATE_PROJECT_INFORMATION_FAILURE,
  DELETE_PROJECT_INFORMATION_BEGIN,
  DELETE_PROJECT_INFORMATION_SUCCESS,
  DELETE_PROJECT_INFORMATION_FAILURE,
  PROJECT_INFORMATION_NUMBER_DOWN_BEGIN,
  PROJECT_INFORMATION_NUMBER_DOWN_SUCCESS,
  PROJECT_INFORMATION_NUMBER_DOWN_FAILURE,
  PROJECT_INFORMATION_NUMBER_UP_BEGIN,
  PROJECT_INFORMATION_NUMBER_UP_SUCCESS,
  PROJECT_INFORMATION_NUMBER_UP_FAILURE,
  SELECT_PROJECT_REFERENCE,
  FETCH_PROJECT_REFERENCES_BEGIN,
  FETCH_PROJECT_REFERENCES_SUCCESS,
  FETCH_PROJECT_REFERENCES_FAILURE,
  ADD_PROJECT_REFERENCE_BEGIN,
  ADD_PROJECT_REFERENCE_SUCCESS,
  ADD_PROJECT_REFERENCE_FAILURE,
  DELETE_PROJECT_REFERENCE_BEGIN,
  DELETE_PROJECT_REFERENCE_SUCCESS,
  DELETE_PROJECT_REFERENCE_FAILURE,
  UPDATE_PROJECT_REFERENCE_BEGIN,
  UPDATE_PROJECT_REFERENCE_SUCCESS,
  UPDATE_PROJECT_REFERENCE_FAILURE,
  SELECT_PROJECT_SUBCONTRACTOR,
  FETCH_PROJECT_SUBCONTRACTORS_BEGIN,
  FETCH_PROJECT_SUBCONTRACTORS_SUCCESS,
  FETCH_PROJECT_SUBCONTRACTORS_FAILURE,
  ADD_PROJECT_SUBCONTRACTOR_BEGIN,
  ADD_PROJECT_SUBCONTRACTOR_SUCCESS,
  ADD_PROJECT_SUBCONTRACTOR_FAILURE,
  DELETE_PROJECT_SUBCONTRACTOR_BEGIN,
  DELETE_PROJECT_SUBCONTRACTOR_SUCCESS,
  DELETE_PROJECT_SUBCONTRACTOR_FAILURE,
  UPDATE_PROJECT_SUBCONTRACTOR_BEGIN,
  UPDATE_PROJECT_SUBCONTRACTOR_SUCCESS,
  UPDATE_PROJECT_SUBCONTRACTOR_FAILURE,
  FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_BEGIN,
  FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_SUCCESS,
  FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_FAILURE,
  SELECT_PROJECT_QANDA,
  FETCH_PROJECT_QANDAS_BEGIN,
  FETCH_PROJECT_QANDAS_SUCCESS,
  FETCH_PROJECT_QANDAS_FAILURE,
  ADD_PROJECT_QANDA_BEGIN,
  ADD_PROJECT_QANDA_SUCCESS,
  ADD_PROJECT_QANDA_FAILURE,
  UPDATE_PROJECT_QANDA_BEGIN,
  UPDATE_PROJECT_QANDA_SUCCESS,
  UPDATE_PROJECT_QANDA_FAILURE,
  DELETE_PROJECT_QANDA_BEGIN,
  DELETE_PROJECT_QANDA_SUCCESS,
  DELETE_PROJECT_QANDA_FAILURE,
  MOVE_UP_PROJECT_REFERENCE_BEGIN,
  MOVE_UP_PROJECT_REFERENCE_SUCCESS,
  MOVE_UP_PROJECT_REFERENCE_FAILURE,
  MOVE_DOWN_PROJECT_REFERENCE_BEGIN,
  MOVE_DOWN_PROJECT_REFERENCE_SUCCESS,
  MOVE_DOWN_PROJECT_REFERENCE_FAILURE,
  MOVE_UP_PROJECT_SUBCONTRACTOR_BEGIN,
  MOVE_UP_PROJECT_SUBCONTRACTOR_SUCCESS,
  MOVE_UP_PROJECT_SUBCONTRACTOR_FAILURE,
  MOVE_DOWN_PROJECT_SUBCONTRACTOR_BEGIN,
  MOVE_DOWN_PROJECT_SUBCONTRACTOR_SUCCESS,
  MOVE_DOWN_PROJECT_SUBCONTRACTOR_FAILURE,
  MOVE_UP_PROJECT_INFORMATION_BEGIN,
  MOVE_UP_PROJECT_INFORMATION_SUCCESS,
  MOVE_UP_PROJECT_INFORMATION_FAILURE,
  MOVE_DOWN_PROJECT_INFORMATION_BEGIN,
  MOVE_DOWN_PROJECT_INFORMATION_SUCCESS,
  MOVE_DOWN_PROJECT_INFORMATION_FAILURE,
} from '../actionTypes/actionTypes';

const initialstate = {
  projects: [],
  templates: [],
  modules: [],
  statements: [],
  qandas: [],
  slas: [],
  ratecards: [],
  fixedPrices: [],
  piecePrices: [],
  tandms: [],
  information: [],
  references: [],
  subcontractors: [],
  overviewTemplate: {},
  overviewModules: [],
  overviewStatements: [],
  selectedProject: '',
  selectedTemplate: '',
  selectedModule: '',
  selectedStatement: '',
  selectedRatecard: '',
  selectedTAndM: '',
  selectedFixedPrice: '',
  selectedPiecePrice: '',
  selectedSla: '',
  selectedInformation: '',
  selectedReference: '',
  selectedSubcontractor: '',
  selectedQAndA: '',
  projectTemplates: [],
  projectModules: [],
  editors: [],
  viewers: [],
  contractors: [],
  loading: false,
  error: null,
  isShowing: [],
};

export default (state = initialstate, action) => {
  switch (action.type) {
    case MOVE_UP_PROJECT_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MOVE_UP_PROJECT_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MOVE_UP_PROJECT_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case MOVE_DOWN_PROJECT_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MOVE_DOWN_PROJECT_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MOVE_DOWN_PROJECT_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case MOVE_UP_PROJECT_REFERENCE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MOVE_UP_PROJECT_REFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MOVE_UP_PROJECT_REFERENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case MOVE_DOWN_PROJECT_REFERENCE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MOVE_DOWN_PROJECT_REFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MOVE_DOWN_PROJECT_REFERENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case MOVE_UP_PROJECT_SUBCONTRACTOR_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MOVE_UP_PROJECT_SUBCONTRACTOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MOVE_UP_PROJECT_SUBCONTRACTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case MOVE_DOWN_PROJECT_SUBCONTRACTOR_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MOVE_DOWN_PROJECT_SUBCONTRACTOR_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MOVE_DOWN_PROJECT_SUBCONTRACTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_PROJECT_QANDA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PROJECT_QANDA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_PROJECT_QANDA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_PROJECT_QANDA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROJECT_QANDA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PROJECT_QANDA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_PROJECT_QANDA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PROJECT_QANDA_SUCCESS:
      return {
        ...state,
        loading: false,
        qandas: [
          ...state.qandas.filter((qanda) => qanda._id !== action.payload.qanda),
        ],
      };
    case DELETE_PROJECT_QANDA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_PROJECT_QANDAS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROJECT_QANDAS_SUCCESS:
      return {
        ...state,
        loading: false,
        qandas: action.payload.qandas,
      };
    case FETCH_PROJECT_QANDAS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        qandas: [],
      };
    case UPDATE_PROJECT_SUBCONTRACTOR_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROJECT_SUBCONTRACTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        subcontractors: [
          ...state.subcontractors.map((subcontractor) =>
            subcontractor._id === action.payload.subcontractor._id
              ? {
                _id: action.payload.subcontractor._id,
                number: action.payload.subcontractor.number,
                question: action.payload.subcontractor.question,
                answer: action.payload.subcontractor.answer,
                project: action.payload.subcontractor.project,
              }
              : subcontractor),
        ],
      };
    case UPDATE_PROJECT_SUBCONTRACTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_PROJECT_SUBCONTRACTOR_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PROJECT_SUBCONTRACTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        subcontractors: [
          ...state.subcontractors
            .filter(
              (subcontractor) =>
                subcontractor._id !== action.payload.subcontractor._id,
            )
            .map((subcontractor) =>
              subcontractor.number > action.payload.subcontractor.number
                ? {
                  _id: subcontractor._id,
                  number: subcontractor.number - 1,
                  question: subcontractor.question,
                  answer: subcontractor.answer,
                  project: subcontractor.project,
                }
                : subcontractor),
        ],
      };
    case DELETE_PROJECT_SUBCONTRACTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_PROJECT_SUBCONTRACTOR_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PROJECT_SUBCONTRACTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        subcontractors: [
          ...state.subcontractors,
          {
            _id: action.payload.subcontractor._id,
            number: action.payload.subcontractor.number,
            question: action.payload.subcontractor.question,
            answer: action.payload.subcontractor.answer,
            project: action.payload.subcontractor.project,
          },
        ],
      };
    case ADD_PROJECT_SUBCONTRACTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SELECT_PROJECT_QANDA:
      return {
        ...state,
        selectedQAndA: action.payload.qanda,
      };
    case SELECT_PROJECT_SUBCONTRACTOR:
      return {
        ...state,
        selectedSubcontractor: action.payload.subcontractor,
      };
    case FETCH_PROJECT_SUBCONTRACTORS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROJECT_SUBCONTRACTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        subcontractors: action.payload.subcontractors,
      };
    case FETCH_PROJECT_SUBCONTRACTORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        subcontractors: [],
      };
    case UPDATE_PROJECT_REFERENCE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROJECT_REFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        references: [
          ...state.references.map((reference) =>
            reference._id === action.payload.reference._id
              ? {
                _id: action.payload.reference._id,
                number: action.payload.reference.number,
                question: action.payload.reference.question,
                answer: action.payload.reference.answer,
                project: action.payload.reference.project,
              }
              : reference),
        ],
      };
    case UPDATE_PROJECT_REFERENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_PROJECT_REFERENCE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PROJECT_REFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        references: [
          ...state.references
            .filter(
              (reference) => reference._id !== action.payload.reference._id,
            )
            .map((reference) =>
              reference.number > action.payload.reference.number
                ? {
                  _id: reference._id,
                  number: reference.number - 1,
                  question: reference.question,
                  answer: reference.answer,
                  project: reference.project,
                }
                : reference),
        ],
      };
    case DELETE_PROJECT_REFERENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_PROJECT_REFERENCE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PROJECT_REFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        references: [
          ...state.references,
          {
            _id: action.payload.reference._id,
            number: action.payload.reference.number,
            question: action.payload.reference.question,
            answer: action.payload.reference.answer,
            project: action.payload.reference.project,
          },
        ],
      };
    case ADD_PROJECT_REFERENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SELECT_PROJECT_REFERENCE:
      return {
        ...state,
        selectedReference: action.payload.reference,
      };
    case FETCH_PROJECT_REFERENCES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROJECT_REFERENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        references: action.payload.references,
      };
    case FETCH_PROJECT_REFERENCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        references: [],
      };
    case ADD_PROJECT_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PROJECT_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_PROJECT_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_PROJECT_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROJECT_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PROJECT_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_PROJECT_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PROJECT_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_PROJECT_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case PROJECT_INFORMATION_NUMBER_DOWN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROJECT_INFORMATION_NUMBER_DOWN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PROJECT_INFORMATION_NUMBER_DOWN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case PROJECT_INFORMATION_NUMBER_UP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROJECT_INFORMATION_NUMBER_UP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PROJECT_INFORMATION_NUMBER_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_PROJECT_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROJECT_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        information: action.payload.information,
      };
    case FETCH_PROJECT_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        information: [],
      };
    case SELECT_PROJECT_INFORMATION:
      return {
        ...state,
        selectedInformation: action.payload.id,
      };
    case UPDATE_PROJECT_PIECEPRICE_BEGIN:
      return {
        ...state,
        lodaing: true,
        error: null,
      };
    case UPDATE_PROJECT_PIECEPRICE_SUCCESS:
      return {
        ...state,
        lodaing: false,
      };
    case UPDATE_PROJECT_PIECEPRICE_FAILURE:
      return {
        ...state,
        lodaing: false,
        error: action.payload.error,
      };
    case DELETE_PROJECT_RATECARD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PROJECT_RATECARD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_PROJECT_RATECARD_FAILURE:
      return {
        ...state,
        lodaing: false,
        error: action.payload.error,
      };
    case UPDATE_PROJECT_RATECARD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROJECT_RATECARD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PROJECT_RATECARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_PROJECT_RATECARD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PROJECT_RATECARD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_PROJECT_RATECARD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }
    case FETCH_PROJECT_FIXEDPRICES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROJECT_FIXEDPRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        fixedPrices: action.payload.fixedPrices,
      };
    case FETCH_PROJECT_FIXEDPRICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_PROJECT_PIECEPRICES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROJECT_PIECEPRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        piecePrices: action.payload.piecePrices,
      };
    case FETCH_PROJECT_PIECEPRICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_PROJECT_TANDMS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROJECT_TANDMS_SUCCESS:
      return {
        ...state,
        loading: false,
        tandms: action.payload.tandms,
      };
    case FETCH_PROJECT_TANDMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_PROJECT_SLA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PROJECT_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_PROJECT_SLA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_PROJECT_SLA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PROJECT_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_PROJECT_SLA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_PROJECT_RATECARD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROJECT_RATECARD_SUCCESS:
      return {
        ...state,
        loading: false,
        ratecards: action.payload.ratecards,
      };
    case FETCH_PROJECT_RATECARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SELECT_PROJECT_RATECARD:
      return {
        ...state,
        selectedRatecard: action.payload.id,
      };
    case SELECT_PROJECT_TANDM:
      return {
        ...state,
        selectedTAndM: action.payload.tandm,
      };
    case SELECT_PROJECT_PIECEPRICE:
      return {
        ...state,
        selectedPiecePrice: action.payload.piecePrice,
      };
    case SELECT_PROJECT_FIXEDPRICE:
      return {
        ...state,
        selectedFixedPrice: action.payload.fixedPrice,
      };
    case SELECT_PROJECT_SLA:
      return {
        ...state,
        selectedSla: action.payload.sla,
      };
    case UPLOAD_PROJECT_PROJECT_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_PROJECT_PROJECT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_PROJECT_PROJECT_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_PROJECT_PROJECT_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_PROJECT_PROJECT_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_PROJECT_PROJECT_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPLOAD_PROJECT_TEMPLATE_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_PROJECT_TEMPLATE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_PROJECT_TEMPLATE_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_PROJECT_TEMPLATE_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_PROJECT_TEMPLATE_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_PROJECT_TEMPLATE_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPLOAD_PROJECT_MODULE_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_PROJECT_MODULE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_PROJECT_MODULE_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_PROJECT_MODULE_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_PROJECT_MODULE_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_PROJECT_MODULE_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPLOAD_PROJECT_STATEMENT_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_PROJECT_STATEMENT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_PROJECT_STATEMENT_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_PROJECT_STATEMENT_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_PROJECT_STATEMENT_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_PROJECT_STATEMENT_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPLOAD_PROJECT_QANDA_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_PROJECT_QANDA_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_PROJECT_QANDA_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_PROJECT_QANDA_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_PROJECT_QANDA_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_PROJECT_QANDA_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_PROJECT_SLA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROJECT_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PROJECT_SLA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CLEAR_PROJECT_SLAS_ARRAY:
      return {
        ...state,
        slas: [],
      };
    case FETCH_PROJECT_SLAS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROJECT_SLAS_SUCCESS:
      return {
        ...state,
        loading: false,
        slas: action.payload.slas,
      };
    case FETCH_PROJECT_SLAS_FAILURE:
      return {
        ...state,
        loading: false,
        slas: [],
        error: action.payload.error,
      };
    case UPDATE_PROJECT_MODULE_SLA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROJECT_MODULE_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PROJECT_MODULE_SLA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_PROJECT_MODULE_PRICING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROJECT_MODULE_PRICING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PROJECT_MODULE_PRICING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_OVERVIEW_STATEMENTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OVERVIEW_STATEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        overviewStatements: action.payload.statements,
      };
    case FETCH_OVERVIEW_STATEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_OVERVIEW_MODULES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OVERVIEW_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        overviewModules: action.payload.modules,
      };
    case FETCH_OVERVIEW_MODULES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_OVERVIEW_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OVERVIEW_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        overviewTemplate: action.payload.template,
      };
    case FETCH_OVERVIEW_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case PROJECT_STATEMENT_NUMBER_DOWN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROJECT_STATEMENT_NUMBER_DOWN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PROJECT_STATEMENT_NUMBER_DOWN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case PROJECT_STATEMENT_NUMBER_UP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROJECT_STATEMENT_NUMBER_UP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PROJECT_STATEMENT_NUMBER_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case PROJECT_MODULE_NUMBER_DOWN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROJECT_MODULE_NUMBER_DOWN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PROJECT_MODULE_NUMBER_DOWN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case PROJECT_MODULE_NUMBER_UP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROJECT_MODULE_NUMBER_UP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PROJECT_MODULE_NUMBER_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case PROJECT_TEMPLATE_NUMBER_DOWN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROJECT_TEMPLATE_NUMBER_DOWN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PROJECT_TEMPLATE_NUMBER_DOWN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case PROJECT_TEMPLATE_NUMBER_UP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROJECT_TEMPLATE_NUMBER_UP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PROJECT_TEMPLATE_NUMBER_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_PROJECT_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PROJECT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_PROJECT_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_PROJECT_MODULE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PROJECT_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_PROJECT_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_EXISTING_PROJECT_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_EXISTING_PROJECT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_EXISTING_PROJECT_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_EXISTING_PROJECT_MODULE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_EXISTING_PROJECT_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_EXISTING_PROJECT_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_PROJECT_STATEMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PROJECT_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_PROJECT_STATEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_PROJECT_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROJECT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PROJECT_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_PROJECT_MODULE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROJECT_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PROJECT_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_PROJECT_STATEMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROJECT_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        statements: [
          ...state.statements.map((statement) =>
            statement._id === action.payload.statement._id
              ? {
                _id: action.payload.statement._id,
                number: action.payload.statement.number,
                attachment: action.payload.statement.attachment,
                customer: action.payload.statement.customer,
                contractor: action.payload.statement.contractor,
                published: action.payload.statement.published,
                updated: action.payload.statement.updated,
                created: action.payload.statement.created,
                description: action.payload.statement.description,
                module: action.payload.statement.module,
                template: action.payload.statement.template,
                project: action.payload.statement.project,
                created_by: action.payload.statement.created_by,
                updated_by: action.payload.statement.updated_by,
                origin: action.payload.statement.origin,
                editors: action.payload.statement.editors,
                viewers: action.payload.statement.viewers,
              }
              : statement),
        ],
      };
    case UPDATE_PROJECT_STATEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_PROJECT_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PROJECT_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_PROJECT_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_PROJECT_MODULE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PROJECT_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_PROJECT_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_PROJECT_STATEMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PROJECT_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_PROJECT_STATEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case PROJECT_UPDATE_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROJECT_UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PROJECT_UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case PROJECT_DELETE_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROJECT_DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PROJECT_DELETE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case PROJECT_FETCH_PROJECTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROJECT_FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: action.payload.projects,
      };
    case PROJECT_FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        projects: [],
      };
    case PROJECT_FETCH_TEMPLATES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROJECT_FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload.templates,
      };
    case PROJECT_FETCH_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        statements: [],
      };
    case PROJECT_FETCH_MODULES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROJECT_FETCH_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        modules: action.payload.modules,
      };
    case PROJECT_FETCH_MODULES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        modules: [],
      };
    case PROJECT_FETCH_STATEMENTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PROJECT_FETCH_STATEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        statements: action.payload.statements,
      };
    case PROJECT_FETCH_STATEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        statements: [],
      };
    case PROJECT_CLEAR_STATEMENTS_ARRAY:
      return {
        ...state,
        statements: [],
      };
    case PROJECT_CLEAR_MODULES_ARRAY:
      return {
        ...state,
        modules: [],
      };
    case PROJECT_CLEAR_TEMPLATES_ARRAY:
      return {
        ...state,
        templates: [],
      };
    case PROJECT_CLEAR_PROJECTS_ARRAY:
      return {
        ...state,
        projects: [],
      };
    case PROJECT_SELECT_PROJECT:
      return {
        ...state,
        selectedProject: action.payload.project,
      };
    case PROJECT_SELECT_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload.template,
      };
    case PROJECT_SELECT_MODULE:
      return {
        ...state,
        selectedModule: action.payload.module,
      };
    case PROJECT_SELECT_STATEMENT:
      return {
        ...state,
        selectedStatement: action.payload.statement,
      };
    case FETCH_PROJECT_EDITORS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROJECT_EDITORS_SUCCESS:
      return {
        ...state,
        loading: false,
        editors: action.payload.editors,
      };
    case FETCH_PROJECT_EDITORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_EDITORS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_EDITORS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_EDITORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_PROJECT_VIEWERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROJECT_VIEWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        viewers: action.payload.viewers,
      };
    case FETCH_PROJECT_VIEWERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_VIEWERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_VIEWERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_VIEWERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_PROJECT_CONTRACTORS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROJECT_CONTRACTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        contractors: action.payload.contractors,
      };
    case FETCH_PROJECT_CONTRACTORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_CONTRACTORS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_CONTRACTORS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_CONTRACTORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CREATE_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case CLEAR_PROJECT_TEMPLATES:
      return {
        ...state,
        projectTemplates: [],
      };
    case CLEAR_PROJECT_MODULES:
      return {
        ...state,
        projectModules: [],
      };
    case SELECT_PROJECT_TEMPLATE:
      return {
        ...state,
        projectTemplates: [...state.projectTemplates, action.payload],
      };
    case SELECT_PROJECT_MODULE:
      return {
        ...state,
        projectModules: [...state.projectModules, action.payload],
      };
    case REMOVE_PROJECT_TEMPLATE:
      return {
        ...state,
        projectTemplates: state.projectTemplates.filter(
          (template) => template !== action.payload,
        ),
      };
    case REMOVE_PROJECT_MODULE:
      return {
        ...state,
        projectModules: state.projectModules.filter(
          (module) => module !== action.payload,
        ),
      };
    case FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_SUCCESS:
      return {
        ...state,
        loading: false,
        isShowing: action.payload.isShowing,
      };
    case FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        isShowing: [true, true, true, true, true, true, true, true],
      };
    default:
      return state;
  }
};
