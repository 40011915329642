import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { green, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

import TableAddInlineIconButton from '../Buttons/TableAddInlineIconButton';

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: grey[300],
  },
  submitButton: {
    color: green[300],
  },
}));

export default function AddDialogInline(props) {
  const { projectId, moduleId, header, text, onClick, description, type } = props;
  let { cluster } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const inputRef = React.useRef();

  const formik = useFormik({
    initialValues: {
      input: '',
    },
    validationSchema: Yup.object({
      input: Yup.string().required('Darf nicht leer sein'),
    }),
    onSubmit: (values) => {
      if (!cluster) cluster = values.input;
      onClick({
        variables: { projectId, moduleId, name: values.input, type, question: values.input, cluster },
      });
      setOpen(false);
    },
  });

  const handleClickOpen = () => {
    formik.values.input = '';
    setOpen(true);
    // timeout to select textfield
    const timeout = setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <TableAddInlineIconButton onClick={handleClickOpen} />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {description}
          </DialogContentText>
          <form onSubmit={formik.handleSubmit}>
            <TextField
              inputRef={inputRef}
              id="input"
              name="input"
              label={text}
              fullWidth
              multiline
              margin="normal"
              value={formik.values.input}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={!!(formik.touched.input && formik.errors.input)}
              helperText={formik.touched.input && formik.errors.input}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={handleClose}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            className={classes.submitButton}
            onClick={formik.handleSubmit}
            color="primary"
            autoFocus
          >
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

AddDialogInline.propTypes = {
  projectId: PropTypes.string,
  moduleId: PropTypes.string,
  header: PropTypes.string.isRequired,
  type: PropTypes.string,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  description: PropTypes.string,
  cluster: PropTypes.string,
};
