import { TOGGLE_DARK_MODE } from '../actionTypes/actionTypes';

export const toggleDarkModeAction = (payload) => ({
  type: TOGGLE_DARK_MODE,
  payload,
});

export const toggleDarkMode = () => (dispatch, getState) => {
  const { settings } = getState();
  if (!settings.darkModeEnabled) {
    dispatch(toggleDarkModeAction(true));
  } else {
    dispatch(toggleDarkModeAction(false));
  }
};
