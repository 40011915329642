import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { connect } from 'react-redux';
// import { withRouter } from 'react-router-dom';
// import {
//   updateEditors,
//   updateViewers,
//   updateContractors,
//   fetchProjectEditors,
//   fetchProjectViewers,
//   fetchProjectContractors,
// } from '../../redux/actionCreators/projectAction';
import ChangeUsersDialog from '../../components/Dialogs/ChangeUsersDialog';

function ChangeUsersContainer(props) {
  const [open, setOpen] = useState(false);
  const {
    projectId,
    // setOpen,
    // projectEditors,
    // projectViewers,
    // projectContractors,
    roles,
    userEmail,
    updateRoles,
    // updateEditors,
    // updateViewers,
    // updateContractors,
    // fetchProjectEditors,
    // fetchProjectViewers,
    // fetchProjectContractors,
    published,
  } = props;

  return (
    <ChangeUsersDialog
      projectId={projectId}
      open={open}
      setOpen={setOpen}
      projectEditors={roles.filter((role) => role.type === 'editor')}
      projectViewers={roles.filter((role) => role.type === 'viewer')}
      projectContractors={roles.filter((role) => role.type === 'contractor')}
      userEmail={userEmail}
      updateRoles={updateRoles}
      // updateViewers={updateViewers}
      // updateContractors={updateContractors}
      // fetchProjectEditors={fetchProjectEditors}
      // fetchProjectViewers={fetchProjectViewers}
      // fetchProjectContractors={fetchProjectContractors}
      published={published}
    />
  );
}

// const mapStateToProps = (state) => ({
//   projectEditors: state.project.editors,
//   projectViewers: state.project.viewers,
//   projectContractors: state.project.contractors,
//   userEmail: state.auth.user.email,
// });

// const mapDispatchToProps = (dispatch) => ({
// updateEditors: (projectId, email, option) =>
//   dispatch(updateEditors(projectId, email, option)),
// updateViewers: (projectId, email, option) =>
//   dispatch(updateViewers(projectId, email, option)),
// updateContractors: (projectId, email, option) =>
//   dispatch(updateContractors(projectId, email, option)),
// fetchProjectEditors: (id) => dispatch(fetchProjectEditors(id)),
// fetchProjectViewers: (id) => dispatch(fetchProjectViewers(id)),
// fetchProjectContractors: (id) => dispatch(fetchProjectContractors(id)),
// });

export default ChangeUsersContainer;

ChangeUsersContainer.propTypes = {
  projectId: PropTypes.string.isRequired,
  // open: PropTypes.bool.isRequired,
  // setOpen: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projectViewers: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projectContractors: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  userEmail: PropTypes.string.isRequired,
  // updateEditors: PropTypes.func.isRequired,
  // updateViewers: PropTypes.func.isRequired,
  // updateContractors: PropTypes.func.isRequired,
  // fetchProjectEditors: PropTypes.func.isRequired,
  // fetchProjectViewers: PropTypes.func.isRequired,
  // fetchProjectContractors: PropTypes.func.isRequired,
  published: PropTypes.bool.isRequired,
  updateRoles: PropTypes.func.isRequired,
};
