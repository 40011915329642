import Button from '@material-ui/core/Button';
import { blue, grey } from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import TableEditIconButton from '../Buttons/TableEditIconButton';

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: grey[300],
  },
  submitButton: {
    color: blue[300],
  },
}));

export default function EditTwoFieldsDialog(props) {
  const { moduleId, projectId, ratecardId, priceId, qandaId, header, fields, oldData, onClick } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [fieldOne, setFieldOne] = useState(oldData[0]);
  const [fieldTwo, setFieldTwo] = useState(oldData[1]);

  const handleClickOpen = () => {
    setFieldOne(oldData[0]);
    setFieldTwo(oldData[1]);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setFieldOne(oldData[0]);
    setFieldTwo(oldData[1]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onClick({
      variables: { projectId,
        moduleId,
        qandaId,
        ratecardId,
        priceId,
        name: fieldOne,
        heading: fieldTwo,
        profile: fieldOne,
        description: fieldTwo,
        unit: fieldOne,
        amount: parseFloat(fieldTwo),
        question: fieldOne,
        answer: fieldTwo },
    });
    setOpen(false);
  };

  return (
    <div>
      <TableEditIconButton onClick={handleClickOpen} />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <TextField
              id="fieldOne"
              label={fields.fieldOne}
              fullWidth
              multiline
              margin="normal"
              value={fieldOne}
              onChange={(e) => setFieldOne(e.target.value)}
            />
            <TextField
              id="fieldTwo"
              label={fields.fieldTwo}
              fullWidth
              multiline
              margin="normal"
              value={fieldTwo}
              onChange={(e) => setFieldTwo(e.target.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={handleClose}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            className={classes.submitButton}
            onClick={handleSubmit}
            color="primary"
            autoFocus
          >
            Ändern
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditTwoFieldsDialog.propTypes = {
  fields: PropTypes.shape(Object).isRequired,
  header: PropTypes.string.isRequired,
  moduleId: PropTypes.string,
  ratecardId: PropTypes.string,
  priceId: PropTypes.string,
  qandaId: PropTypes.string,
  oldData: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};
