import axios from 'axios';
import jwtDecode from 'jwt-decode';
import setAuthToken from '../../setAuthToken';
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  LOGOUT_USER,
} from '../actionTypes/actionTypes';
import { enqueueSnackbar } from './notifierAction';

export const registerUser = (user, history) => (dispatch) => {
  axios
    .post('/api/v1/users/register', user)
    .then(() => {
      history.push('/signin');
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
      dispatch(
        enqueueSnackbar({
          message:
            'Ihr Account wurde erfolgreich erstellt und wird zeitnah von einem Sourcing-Pilot Mitarbeitenden aktiviert.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 10000,
          },
        }),
      );
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
      dispatch(
        enqueueSnackbar({
          message:
            'FEHLER: Ihr Account konnte nicht erstellt werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 5000,
          },
        }),
      );
    });
};

export const setCurrentUser = (decoded) => ({
  type: SET_CURRENT_USER,
  payload: decoded,
});

export const loginUser = (user, history) => (dispatch) => {
  axios
    .post('/api/v1/users/login', user)
    .then((res) => {
      const { token } = res.data;
      localStorage.setItem('jwtToken', token);
      setAuthToken(token);
      const decoded = jwtDecode(token);
      dispatch(setCurrentUser(decoded));
      if (decoded.role === 'AG') {
        history.push('/manageproject');
      } else if (decoded.role === 'AN') {
        history.push('/manageoffering');
      } else if (decoded.role === 'ITAG') {
        history.push('/database');
      } else {
        history.push('/main');
      }

      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
      dispatch(
        enqueueSnackbar({
          message: 'Sie haben sich erfolgreich angemeldet.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const logoutUserAction = () => ({
  type: LOGOUT_USER,
});

export const logoutUser = (history) => (dispatch) => {
  localStorage.removeItem('jwtToken');
  setAuthToken(false);
  dispatch(logoutUserAction());
  dispatch(
    enqueueSnackbar({
      message: 'Sie haben sich erfolgreich abgemeldet.',
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'success',
        autoHideDuration: 3000,
      },
    }),
  );
  history.push('/signin');
};
