import { TOGGLE_DARK_MODE } from '../actionTypes/actionTypes';

const initialstate = {
  darkModeEnabled: false,
};

export default (state = initialstate, action) => {
  switch (action.type) {
    case TOGGLE_DARK_MODE:
      return { ...state, darkModeEnabled: action.payload };
    default:
      return state;
  }
};
