import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Paper } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import ResponsiveDiv from '../Elements/ResponsiveDiv';

const context = require.context('./screenshots', true, /\.(png)$/);
const screenshots = {};

context.keys().forEach((filename) => {
  screenshots[filename] = context(filename);
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '80%',
    maxWidth: 1200,
    margin: 'auto',
    paddingTop: theme.spacing(2),
  },
  block: {
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: 0,
  },
  paperBlock: {
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    width: '100%',
  },
  description: {
    whiteSpace: 'pre-wrap',
    paddingTop: theme.spacing(2),
  },
  image: {
    width: '100%',
    align: 'center',
  },
  imageCarousel: {
    maxWidth: '600px',
    margin: 'auto 0 auto auto',
  },
}));

function InstructionsPage() {
  // #to do Authentifizierung
  // const { user, isAuthenticated, history } = props;

  Object.entries(screenshots).map(([value]) => new Image().src = value);

  const classes = useStyles();
  const pageText = [
    {
      header: 'Übersicht',
      text: 'Auf der Übersichtsseite können Sie die aktuellen Projektanfragen einsehen. \nFür jedes Projekt können Projektteilnehmer (lesende User und Bearbeiter) eingetragen werden. Die Projektteilnehmer sich müssen vor dem Eintragen registriert haben. Sie können den aktuellen Bearbeitungsstand des Angebots als Word Dokument exportieren. \n\nWeiterhin können Sie die Angebote zu den Projektanfragen hier einreichen.',
      images: [screenshots['./01_1_overview.png'], screenshots['./01_2_project-members.png']],
    },
    {
      header: 'Dokumentenstruktur',
      text: 'In Sourcing-Pilot werden alle Vertragsdokumente untereinander dargestellt. Die Dokumente lassen sich in 5 Gruppen aufteilen:\n\n1. MSA (Rahmenvertrag) beinhaltet alle vertraglichen Regelungen der Zusammenarbeit und gilt für alle Leistungsscheine\n\n2. SOWs (Leistungsscheine) bestehen jeweils aus einzelnen Modulen, welche die Statements (Vertragstexte) bündeln. Die Anzahl der SOW-Dokumente hängt vom Leistungsumfang des Vertrages ab.\n\n3. Service Level für alle SOWs sind tabellarisch aufgelistet und verweisen pro Servicelevel auf den entsprechenden SOW und das Modul des SOWs auf den sie sich beziehen\n\n4. Die Vergütung für die SOWs kann in unterschiedlichen Formen (Stückpreis, Festpreis, T&M) erfolgen. Für jede Form gibt es ein eigenes Dokument in dem die Preispositionen tabellarisch aufgelistet sind und pro Preisposition auf den entsprechenden SOW und das Modul des SOWs auf den sie sich beziehen, verweisen.\n\n5. Alle weiteren Bereiche in Sourcing-Pilot sind zusätzliche Dokumente (Referenzen, Subkontraktoren, Informationen), um den Bieter im Rahmen der Ausschreibung besser bewerten zu können.',
      images: [screenshots['./03a_documents.png']],
    },
    {
      header: 'SOWs und begleitende Dokumente',
      text: 'Ausschreibungen umfassen Rahmenvertragliche Regelungen, die SOWs (statements of work / Leistungsscheine) und Service Level. Diese Dokumente sind zu prüfen und hinsichtlich der Compliance zu bestätigen (siehe unten). \n\nDie weiteren Dokumente (Preise, Referenzen, Bieterinformationen) sind hinsichtlich der angefragten Positionen auszufüllen.',
      images: [screenshots['./02_1_overview-SOWs.png'], screenshots['./02_2_overview-additional-documents.png']],
    },
    {
      header: 'Module und Statements',
      text: 'Die Beschreibung der Leistungen in den SOWs ist in Modulen unterteilt. Einzelne Module haben einen direkten Bezug zu Preispositionen und Service Leveln. \n\nModule bestehen aus einzelnen Vertragstexten (Statements), die konkrete Anforderungen beschreiben.\n\nAuf jeder Ebene können in Form von Anhängen weitere Informationen vorhanden sein. Diese können Sie durch Anklicken herunterladen.',
      images: [screenshots['./03_modules.png'], screenshots['./03_statements.png']],
    },
    {
      header: 'Dissense / Compliance',
      text: 'Wenn Sie auf den Namen des Moduls/Absatzes klicken, sehen Sie die zum Absatz gehörenden Vertragstexte. Wenn Sie an den Vertragstexten bzw. Leistungsbeschreibungen Änderungen bzw. Konkretisierungen vornehmen wollen, stellen Sie den Schieberegler unter Compliance auf rot (noncompliant). Es öffnet sich ein Fenster mit dem ursprünglichen Text und der Möglichkeit den Text anzupassen oder zu erweitern. \n\nMit der Auswahl „compliant“ gibt der Bieter seine Zustimmung zu dem Vertragspassus der Zeile. Der Bieter hat die Anforderung verstanden und wird die Anforderung in vollem Umfang erfüllen. \n\nDissense sollen möglichst spezifisch formuliert werden, sodass diese eindeutig und durch Dritte nachvollziehbar sind.\nGrundsätzlich wird Nennung von Dissensen nicht negativ bewertet bzw. kann sogar die Kompetenz des Anbieters unterlegen.',
      images: [screenshots['./04_1_compliance.png'], screenshots['./04_2_dissens_ergebnis.png']],
    },
    {
      header: 'Q&A',
      text: 'Sie haben die Möglichkeit Bieterfragen zu stellen. Alle Fragen und Antworten sind ohne Nennung des Fragenden sofort für alle Bieter sichtbar, um doppelte Fragen zu vermeiden und Antworten schnell zu veröffentlichen. \nBitte stellen Sie daher Ihre Frage so, dass kein Rückschluss auf Ihr Unternehmen möglich ist.',
      images: [screenshots['./05_QandA.png']],
    },
    {
      header: 'Service Level',
      text: 'In der Service Level Übersicht haben Sie analog zu den Leistungen die Möglichkeit einen Gegenvorschlag zu den geforderten Service Leveln einzureichen. Dazu klicken Sie auf den Schieberegler und geben im sich öffnenden Fenster Ihren Vorschlag ein.',
      images: [screenshots['./06_sla.png']],
    },
    {
      header: 'Ratecard und T&M',
      text: 'In der Übersicht zur Rate Card geben Sie für die hinterlegten Profile einen Tagessatz und eine Reisekostenpauschale in Euro ein, in dem Sie auf das Stift-Symbol klicken.\n\nUnter T&M sind bei den Modulen, die nach Aufwand abgerechnet werden, eine geschätzte Anzahl an Personentagen pro Profil zu hinterlegen. Wenn Sie mehr als ein Profil bei einem Modul hinterlegen möchten, können Sie dies über den Button Hinzufügen (+) tun.',
      images: [screenshots['./07_ratecard.png'], screenshots['./07_2_TandM.png'], screenshots['./07_3_TandM.png'], screenshots['./07_4_TandM.png']],
    },
    {
      header: 'Festpreise',
      text: 'Unter Festpreise tragen Sie Preise ein für einmalige Leistungen (z.B. Phasen im Transitionsprojekt) oder Service Requests, bei denen keine Abrufmengen kalkulierbar sind.',
      images: [screenshots['./08_fixedprice.png']],
    },
    {
      header: 'Stückpreise',
      text: 'Unter Stückpreisen sind alle dauerhaft bzw. regelmäßig erbrachten Leistungen anzubieten, die nach tatsächlich erbrachten Mengen abgerechnet werden. Die anzugebenen Preise und Mengen beziehen sich jeweils auf einen Monat. Die Jahreskosten werden automatisch berechnet. \nPauschale monatliche Kosten (ohne Kostentreiber, der von einer Stückzahl abhängig ist) werden mit der Menge 1 dargestellt.',
      images: [screenshots['./09_pieceprice.png']],
    },
    {
      header: 'Informationen',
      text: 'Bitte geben Sie hier die jeweils geforderten Informationen zu Ihrem Unternehmen an. Weiterhin können Sie hier weitere Dokumente zu Ihrem Angebot hochladen.',
      images: [screenshots['./10_information.png']],
    },
    {
      header: 'Referenzen',
      text: 'Bitte geben Sie mindestens 2 inhaltlich passende Referenzen an. Die Referenzen werden nur nach vorheriger Abstimmung durch den Auftraggeber kontaktiert. \n\nDazu klicken Sie in der Übersicht auf den Button Hinzufügen (+) und legen ein neues Referenzprojekt an. Innerhalb des Referenzprojekts sollen die jeweils angefragten Informationen hinterlegt werden.',
      images: [screenshots['./11_1_reference_overview.png'], screenshots['./11_2_reference.png']],
    },
    {
      header: 'Subkontraktoren',
      text: 'Sollten Sie für die Erbringung der ausgeschriebenen Leistungen planen Subkontraktoren einzusetzen, sind diese hier einzutragen. \n\nDazu klicken Sie in der Übersicht auf den Button Hinzufügen (+) und legen einen neuen Subkontraktor an. Innerhalb des Subkontraktors sollen die jeweils angefragten Informationen hinterlegt werden.',
      images: [screenshots['./12_1_sub_overview.png'], screenshots['./12_2_sub-detail.png']],
    },
  ];

  return (
    <ResponsiveDiv>
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Paper className={classes.paperBlock} variant="outlined">
            <Grid item xs={12}>
              <Typography variant="h5">Anleitung für Anbieter</Typography>
            </Grid>
          </Paper>
          {pageText.map((block) => ([
            <Paper className={classes.paperBlock} variant="outlined" key={block.header}>
              <Grid container spacing={2} item xs={12} className={classes.block}>
                <Grid item xs={12} md={4}>
                  <Typography variant="h6">{block.header}</Typography>
                  <Typography variant="body1" className={classes.description}>{block.text}</Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Carousel
                    autoPlay={false}
                    className={classes.imageCarousel}
                    timeout={200}
                    navButtonsAlwaysInvisible={!(block.images.length > 1)}
                    indicators={block.images.length > 1}
                  >
                    {
                        block.images.map((item) => <img key={item.default} src={item.default} className={classes.image} alt={block.header} />)
                      }
                  </Carousel>
                </Grid>

              </Grid>
            </Paper>,
          ]))}

        </Grid>
      </div>
    </ResponsiveDiv>
  );
}

export default InstructionsPage;
