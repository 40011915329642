import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import AddRatecardDialog from '../Dialogs/AddRatecardDialog';
import EditTwoFieldsDialog from '../Dialogs/EditTwoFieldsDialog';
import DeleteDialog from '../Dialogs/DeleteDialog';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function DatabaseRatecardTable(props) {
  const {
    ratecards,
    projects,
    addRatecard,
    updateRatecard,
    deleteRatecard,
    selectProject,
    selectedProject,
    selectTemplate,
    selectRatecard,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'project',
      label: 'projectId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'profile',
      label: 'Profilname',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Profilbeschreibung',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'dailyrate',
      label: 'Tagessatz in €',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'travelallowance',
      label: 'Reisekostenpauschale in €',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <div>
            <EditTwoFieldsDialog
              ratecardId={tableMeta.rowData[0]}
              projectId={selectedProject}
              oldData={[tableMeta.rowData[2], tableMeta.rowData[3]]}
              header="Rate Card bearbeiten"
              fields={{ fieldOne: 'Profil', fieldTwo: 'Beschreibung' }}
              onClick={updateRatecard}
            />
            <DeleteDialog
              header="Rate Card löschen"
              text="Möchten Sie die Rate Card wirklich löschen?"
              onClick={() => deleteRatecard({
                variables: { projectId: selectedProject, ratecardId: tableMeta.rowData[0] },
              })}
            />
          </div>
        ),
      },
    },
  ];

  const data = [];

  ratecards.forEach((ratecard) => {
    data.push({
      _id: ratecard._id,
      project: selectedProject,
      profile: ratecard.profile,
      description: ratecard.description,
      dailyrate: 'Wird vom Anbieter ausgefüllt.',
      travelallowance: 'Wird vom Anbieter ausgefüllt.',
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectRatecard('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/database"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectRatecard('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/database"
                onClick={() => selectRatecard('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Rate Card</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions()}
      />
      <AddRatecardDialog
        projectId={selectedProject}
        header="Rate Card hinzufügen"
        onClick={addRatecard}
      />
    </div>
  );
}

export default DatabaseRatecardTable;

DatabaseRatecardTable.propTypes = {
  projects: PropTypes.shape(Object).isRequired,
  ratecards: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectRatecard: PropTypes.func.isRequired,
  addRatecard: PropTypes.func.isRequired,
  updateRatecard: PropTypes.func.isRequired,
  deleteRatecard: PropTypes.func.isRequired,
};
