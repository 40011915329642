import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SignUpPage from '../../components/SignInUp/SignUpPage';

function SignUpContainer(props) {
  const { isAuthenticated, history } = props;
  useEffect(() => {
    if (isAuthenticated) {
      history.push('/main');
    }
  }, [isAuthenticated, history]);

  return <SignUpPage />;
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default withRouter(connect(mapStateToProps)(SignUpContainer));

SignUpContainer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  history: PropTypes.shape(Object).isRequired,
};
