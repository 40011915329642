import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';

function ExportButton(props) {
  const { id, url } = props;
  const [open, setOpen] = useState(false);

  const exportHandle = (filename) => {
    setOpen(true);
    axios({
      url: `${url + id}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${filename}.docx`);
      document.body.appendChild(link);
      link.click();
      setOpen(false);
    });
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Export wird erstellt...
        </DialogTitle>
        <DialogContent>
          Ihr Word-Dokument wird erstellt. Bitte haben Sie einen Augenblick
          Geduld. Sie werden zum Download aufgefordert, sobald das Dokument
          erstellt wurde.
        </DialogContent>
      </Dialog>
      <IconButton onClick={() => exportHandle(id)}>
        <GetApp />
      </IconButton>
    </>
  );
}

export default ExportButton;

ExportButton.propTypes = {
  id: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};
