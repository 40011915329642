import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import {
  Grid,
  Box,
  Link,
  Button,
  Avatar,
  Typography,
  Container,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import {
  TextField,
  CheckboxWithLabel,
} from 'formik-material-ui';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';

import Footer from '../Elements/Footer';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignUpForm(props) {
  const { errors, registerUser, history } = props;
  const classes = useStyles();
  const [error, setError] = useState({});

  useEffect(() => {
    setError(errors);
  }, [errors]);

  const policyLabel = (
    <label>
      <Typography variant="body2">
        Ich akzeptiere die
        {' '}
        <Link component={RouterLink} to="/privacy" variant="body2" target="_blank" rel="noopener">Datenschutzerklärung</Link>
        {' '}
        und stimme der Speicherung meiner Daten zu. Dies kann jederzeit über info@sourcing-pilot.com widerrufen werden.
      </Typography>
    </label>
  );

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Registrieren
        </Typography>
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            company: '',
            position: '',
            email: '',
            password: '',
            passwordRepeat: '',
            acceptPolicies: false,
          }}
          validationSchema={Yup.object({
            firstName: Yup.string().required('Darf nicht leer sein'),
            lastName: Yup.string().required('Darf nicht leer sein'),
            company: Yup.string().required('Darf nicht leer sein'),
            position: Yup.string().required('Darf nicht leer sein'),
            email: Yup.string().required('Darf nicht leer sein').email('Bitte gütlige E-Mail Adresse eingeben'),
            password: Yup.string().required('Darf nicht leer sein').min(8, 'Passwort zu kurz').max(30, 'Passwort zu lang'),
            passwordRepeat: Yup.mixed().oneOf([Yup.ref('password')], 'Passwörter stimmen nicht überein.'),
            acceptPolicies: Yup.bool().oneOf([true], 'Bitte akzeptieren Sie die Nutzungsbedingungen.'),
          })}
          onSubmit={(values) => {
            const user = {
              firstname: values.firstName,
              lastname: values.lastName,
              email: values.email,
              password: values.password,
              password_confirm: values.passwordRepeat,
              company: values.company,
              position: values.position,
            };
            registerUser(user, history);
          }}

        >
          {({
            handleSubmit,
            errors,
            touched,
          }) => (
            <Form className={classes.form}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <Field
                      component={TextField}
                      label="Vorname *"
                      name="firstName"
                      autoComplete="fname"
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <Field
                      component={TextField}
                      label="Nachname *"
                      name="lastName"
                      autoComplete="lname"
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <Field
                      component={TextField}
                      label="Unternehmen *"
                      name="company"
                      autoComplete="company"
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl margin="dense" fullWidth>
                    <Field
                      component={TextField}
                      label="Job Position *"
                      name="position"
                      autoComplete="position"
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl
                    margin="dense"
                    fullWidth
                    error={!!(touched.email && errors.email) || !!(error.email)}
                  >
                    <Field
                      component={TextField}
                      label="E-Mail Adresse *"
                      name="email"
                      autoComplete="email"
                      variant="outlined"
                      fullWidth
                    />
                    <FormHelperText>{error.email}</FormHelperText>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl margin="dense" fullWidth>
                    <Field
                      component={TextField}
                      label="Passwort *"
                      name="password"
                      autoComplete="current-password"
                      variant="outlined"
                      fullWidth
                      type="password"
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl margin="dense" fullWidth>
                    <Field
                      component={TextField}
                      label="Passwort wiederholen *"
                      name="passwordRepeat"
                      autoComplete="current-passwordRepeat"
                      variant="outlined"
                      fullWidth
                      type="password"
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  <FormControl margin="dense" fullWidth error={!!(touched.acceptPolicies && errors.acceptPolicies)}>
                    <Field
                      component={CheckboxWithLabel}
                      type="checkbox"
                      name="acceptPolicies"
                      Label={{ label: policyLabel }}
                    />
                    <FormHelperText>{touched.acceptPolicies && errors.acceptPolicies}</FormHelperText>
                  </FormControl>
                </Grid>
              </Grid>
              <Button
                onClick={handleSubmit}
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Registrieren
              </Button>
              <Grid container justify="flex-end">
                <Grid item>
                  <Link component={RouterLink} to="/signin" variant="body2">
                    Sie haben bereits ein Konto? Anmelden
                  </Link>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        <Box mt={5}>
          <Footer />
        </Box>
      </div>
    </Container>
  );
}

SignUpForm.propTypes = {
  errors: PropTypes.shape(Object).isRequired,
  registerUser: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
};
