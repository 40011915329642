import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { TextField, Grid } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import Collapse from '@material-ui/core/Collapse';

import ResponsiveDiv from '../Elements/ResponsiveDiv';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  contentWrapper: {
    textAlign: 'center',
  },
  form: {
    width: '75%',
    margin: 'auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  buttonWrapper: {
    textAlign: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  nestedTwo: {
    paddingLeft: theme.spacing(8),
  },
}));

function getSteps() {
  return [
    'Informationen zum Projekt angeben',
    'Inhalte des Projekts auswählen',
    'Überprüfen und Projekt erstellen',
  ];
}

export default function CreateProjectStepper(props) {
  const { projects,
    // templates,
    // modules,
    createProject,
    // history,
  } = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [duration, setDuration] = useState('');

  const [checkedProjects, setCheckedProjects] = React.useState([]);
  const handleProjectsToggle = (value) => () => {
    const currentIndex = checkedProjects.indexOf(value);
    const newChecked = [...checkedProjects];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedProjects(newChecked);
  };

  const [checkedTemplates, setCheckedTemplates] = React.useState([]);
  const handleTemplateToggle = (value) => () => {
    const currentIndex = checkedTemplates.indexOf(value);
    const newChecked = [...checkedTemplates];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedTemplates(newChecked);
  };

  const [checkedModules, setCheckedModules] = React.useState([]);
  const handleModulesToggle = (value) => () => {
    const currentIndex = checkedModules.indexOf(value);
    const newChecked = [...checkedModules];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setCheckedModules(newChecked);
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      if (prevActiveStep === 2) {
        createProject({
          variables: {
            referenceId: checkedProjects,
            name,
            description,
            includedTemplates: checkedTemplates,
            includedModules: checkedModules,
            duration: Number(duration),
            targetType: 'project',
          },
        });
      }
      return prevActiveStep + 1;
    });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  // };

  return (
    <ResponsiveDiv>
      <div className={classes.root}>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <div>
          {activeStep === steps.length ? (
            <div className={classes.contentWrapper}>
              <Typography className={classes.instructions}>
                All steps completed
              </Typography>
              {/* <Button onClick={handleReset}>Zurücksetzen</Button> */}
            </div>
          ) : (
            <div className={classes.contentWrapper}>
              {activeStep === 0 && (
                <form className={classes.form}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        variant="outlined"
                        required
                        fullWidth
                        label="Projektname"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="description"
                        variant="outlined"
                        multiline
                        rows={10}
                        required
                        fullWidth
                        label="Projektbeschreibung"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="duration"
                        variant="outlined"
                        required
                        fullWidth
                        label="Projektlaufzeit in Jahren"
                        value={duration}
                        onChange={(e) => setDuration(e.target.value)}
                      />
                    </Grid>
                  </Grid>
                </form>
              )}
              {activeStep === 1 && (
                <div className={classes.contentWrapper}>
                  <div className={classes.form}>
                    <List className={classes.root}>
                      {projects
                        .filter((project) => project.active)
                        .map((project) => (
                          <div key={project._id}>
                            <ListItem
                              role={undefined}
                              button
                              onClick={handleProjectsToggle(project._id)}
                              disabled={
                                checkedProjects.length !== 0
                                && !checkedProjects.includes(project._id)
                              }
                            >
                              <ListItemIcon>
                                <Checkbox
                                  edge="start"
                                  checked={
                                    checkedProjects.indexOf(project._id) !== -1
                                  }
                                  tabIndex={-1}
                                  disableRipple
                                  inputProps={{
                                    'aria-labelledby': project._id,
                                  }}
                                />
                              </ListItemIcon>
                              <ListItemText
                                id={project._id}
                                primary={project.name}
                              />
                              {checkedProjects.indexOf(project._id) !== -1 ? (
                                <ExpandLess />
                              ) : (
                                <ExpandMore />
                              )}
                            </ListItem>
                            <Collapse
                              in={checkedProjects.indexOf(project._id) !== -1}
                              timeout="auto"
                              unmountOnExit
                            >
                              <List component="div" disablePadding>
                                {project.templates.filter((template) => template.active)
                                  .map((template) => (
                                    <div key={template._id}>
                                      <ListItem
                                        role={undefined}
                                        dense
                                        button
                                        onClick={handleTemplateToggle(
                                          template._id,
                                        )}
                                        className={classes.nested}
                                      >
                                        <ListItemIcon>
                                          <Checkbox
                                            edge="start"
                                            checked={
                                              checkedTemplates.indexOf(
                                                template._id,
                                              ) !== -1
                                            }
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                              'aria-labelledby': template._id,
                                            }}
                                          />
                                        </ListItemIcon>
                                        <ListItemText primary={template.name} />
                                        {checkedTemplates.indexOf(
                                          template._id,
                                        ) !== -1 ? (
                                          <ExpandLess />
                                          ) : (
                                            <ExpandMore />
                                          )}
                                      </ListItem>
                                      <Collapse
                                        in={
                                          checkedTemplates.indexOf(
                                            template._id,
                                          ) !== -1
                                        }
                                        timeout="auto"
                                        unmountOnExit
                                      >
                                        <List component="div" disablePadding>
                                          {template.modules
                                            .filter((module) => module.active)
                                            .map((module) => (
                                              <ListItem
                                                key={module._id}
                                                button
                                                className={classes.nestedTwo}
                                                onClick={handleModulesToggle(
                                                  module._id,
                                                )}
                                              >
                                                <ListItemIcon>
                                                  <Checkbox
                                                    edge="start"
                                                    checked={
                                                      checkedModules.indexOf(
                                                        module._id,
                                                      ) !== -1
                                                    }
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{
                                                      'aria-labelledby':
                                                        module._id,
                                                    }}
                                                  />
                                                </ListItemIcon>
                                                <ListItemText
                                                  primary={module.name}
                                                />
                                              </ListItem>
                                            ))}
                                        </List>
                                      </Collapse>
                                    </div>
                                  ))}
                              </List>
                            </Collapse>
                          </div>
                        ))}
                    </List>
                  </div>
                </div>
              )}
              {activeStep === 2 && (
                <div className={classes.contentWrapper}>
                  <div className={classes.form} />
                </div>
              )}
              <div className={classes.buttonWrapper}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Zurück
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  disabled={name.length === 0 || description.length === 0}
                >
                  {activeStep === steps.length - 1
                    ? 'Projekt erstellen'
                    : 'Weiter'}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </ResponsiveDiv>
  );
}

CreateProjectStepper.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // modules: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // history: PropTypes.shape(Object).isRequired,
  createProject: PropTypes.func.isRequired,
};
