import React from 'react';
import PropTypes from 'prop-types';

// import ViewOfferingProjectsOverviewContainer from '../../container/ViewOffering/ViewOfferingProjectsOverviewContainer';
import ViewOfferingProjectsContainer from '../../container/ViewOffering/ViewOfferingProjectsContainer';
import ViewOfferingTemplatesContainer from '../../container/ViewOffering/ViewOfferingTemplatesContainer';
import ViewOfferingModulesContainer from '../../container/ViewOffering/ViewOfferingModulesContainer';
// import ViewOfferingStatementsContainer from '../../container/ViewOffering/ViewOfferingStatementsContainer';
import ViewOfferingSlasContainer from '../../container/ViewOffering/ViewOfferingSlasContainer';
import ViewOfferingRatecardContainer from '../../container/ViewOffering/ViewOfferingRatecardContainer';
import ViewOfferingTAndMsContainer from '../../container/ViewOffering/ViewOfferingTAndMsContainer';
import ViewOfferingFixedPricesContainer from '../../container/ViewOffering/ViewOfferingFixedPricesContainer';
import ViewOfferingPiecePricesContainer from '../../container/ViewOffering/ViewOfferingPiecePricesContainer';
import ViewOfferingInformationContainer from '../../container/ViewOffering/ViewOfferingInformationContainer';
// import ViewOfferingReferencesContainer from '../../container/ViewOffering/ViewOfferingReferencesContainer';
import ViewOfferingReferencesCompaniesContainer from '../../container/ViewOffering/ViewOfferingReferencesCompaniesContainer';
// import ViewOfferingSubcontractorsContainer from '../../container/ViewOffering/ViewOfferingSubcontractorsContainer';
import ViewOfferingSubcontractorsCompaniesContainer from '../../container/ViewOffering/ViewOfferingSubcontractorsCompaniesContainer';

import ResponsiveDiv from '../Elements/ResponsiveDiv';

function ViewOfferingPage(props) {
  const {
    // selectedProjectOverview,
    selectedProject,
    selectedProjectOrigin,
    selectedTemplate,
    // selectedModule,
    selectedSla,
    selectedRatecard,
    selectedTAndM,
    selectedFixedPrice,
    selectedPiecePrice,
    selectedInformation,
    selectedReference,
    // selectedReferenceCompany,
    selectedSubcontractor,
    // selectedSubcontractorCompany,
  } = props;

  if (selectedSla) {
    return (
      <ResponsiveDiv>
        <ViewOfferingSlasContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedRatecard) {
    return (
      <ResponsiveDiv>
        <ViewOfferingRatecardContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedTAndM) {
    return (
      <ResponsiveDiv>
        <ViewOfferingTAndMsContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedFixedPrice) {
    return (
      <ResponsiveDiv>
        <ViewOfferingFixedPricesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedPiecePrice) {
    return (
      <ResponsiveDiv>
        <ViewOfferingPiecePricesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedInformation) {
    return (
      <ResponsiveDiv>
        <ViewOfferingInformationContainer />
      </ResponsiveDiv>
    );
  }
  // if (selectedReferenceCompany) {
  //   return (
  //     <ResponsiveDiv>
  //       <ViewOfferingReferencesContainer />
  //     </ResponsiveDiv>
  //   );
  // }

  if (selectedReference) {
    return (
      <ResponsiveDiv>
        <ViewOfferingReferencesCompaniesContainer />
      </ResponsiveDiv>
    );
  }

  // if (selectedSubcontractorCompany) {
  //   return (
  //     <ResponsiveDiv>
  //       <ViewOfferingSubcontractorsContainer />
  //     </ResponsiveDiv>
  //   );
  // }

  if (selectedSubcontractor) {
    return (
      <ResponsiveDiv>
        <ViewOfferingSubcontractorsCompaniesContainer />
      </ResponsiveDiv>
    );
  }

  // if (selectedModule) {
  //   return (
  //     <ResponsiveDiv>
  //       <ViewOfferingStatementsContainer />
  //     </ResponsiveDiv>
  //   );
  // }

  if (selectedTemplate) {
    return (
      <ResponsiveDiv>
        <ViewOfferingModulesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedProject) {
    return (
      <ResponsiveDiv>
        <ViewOfferingTemplatesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedProjectOrigin) {
    return (
      <ResponsiveDiv>
        <ViewOfferingProjectsContainer />
      </ResponsiveDiv>
    );
  }

  return (
    <ResponsiveDiv>
      <p>
        Kein Projekt ausgewählt.
      </p>
    </ResponsiveDiv>
  );
}

export default ViewOfferingPage;

ViewOfferingPage.propTypes = {
  selectedProject: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  // selectedModule: PropTypes.string.isRequired,
  selectedSla: PropTypes.string.isRequired,
  selectedRatecard: PropTypes.string.isRequired,
  selectedTAndM: PropTypes.string.isRequired,
  selectedFixedPrice: PropTypes.string.isRequired,
  selectedPiecePrice: PropTypes.string.isRequired,
  selectedInformation: PropTypes.string.isRequired,
  selectedReference: PropTypes.string.isRequired,
  // selectedReferenceCompany: PropTypes.string.isRequired,
  selectedSubcontractor: PropTypes.string.isRequired,
  // selectedSubcontractorCompany: PropTypes.string.isRequired,
  selectedProjectOrigin: PropTypes.string.isRequired,
};
