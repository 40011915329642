import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_PRICES, GET_PROJECT } from '../../graphql/queries';
import {
  ADD_PRICE,
  UPDATE_PRICE,
  DELETE_PRICE,
} from '../../graphql/mutations';

import {
  // fetchPiecePrices,
  // addPiecePrice,
  // updatePiecePrice,
  // deletePiecePrice,
  selectPiecePrice,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/projectAction';
import ProjectPiecePricesTable from '../../components/ProjectManagement/ProjectPiecePricesTable';
import Loading from '../../components/Elements/Loading';

function ProjectPiecePricesContainer(props) {
  const {
    // piecePrices,
    // projects,
    // templates,
    // fetchPiecePrices,
    // addPiecePrice,
    // updatePiecePrice,
    // deletePiecePrice,
    selectPiecePrice,
    selectProject,
    selectTemplate,
    selectedProject,
    // loading,
  } = props;

  const priceType = 'Stückpreis';
  const { loading, error, data } = useQuery(GET_PRICES, {
    variables: { projectId: selectedProject, type: priceType },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [addPrice] = useMutation(ADD_PRICE, {
    refetchQueries: [{
      query: GET_PRICES,
      variables: { projectId: selectedProject, type: priceType },
    }],
  });
  const [updatePrice] = useMutation(UPDATE_PRICE, {
    refetchQueries: [{
      query: GET_PRICES,
      variables: { projectId: selectedProject, type: priceType },
    }],
  });
  const [deletePrice] = useMutation(DELETE_PRICE, {
    refetchQueries: [{
      query: GET_PRICES,
      variables: { projectId: selectedProject, type: priceType },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  return (
    <ProjectPiecePricesTable
      piecePrices={data.pricesByType}
      projects={data1.project}
      addPiecePrice={addPrice}
      updatePiecePrice={updatePrice}
      deletePiecePrice={deletePrice}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectPiecePrice={selectPiecePrice}
      loading={loading}
      priceType={priceType}
    />
  );
}

const mapStateToProps = (state) => ({
  piecePrices: state.project.piecePrices,
  projects: state.project.projects,
  templates: state.project.templates,
  selectedProject: state.project.selectedProject,
  loading: state.project.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchPiecePrices: (id) => dispatch(fetchPiecePrices(id)),
  // addPiecePrice: (projectId, moduleId) => dispatch(addPiecePrice(projectId, moduleId)),
  // updatePiecePrice: (piecePriceId, unit, amount, projectId) =>
  //   dispatch(updatePiecePrice(piecePriceId, unit, amount, projectId)),
  // deletePiecePrice: (piecePriceId, projectId) => dispatch(deletePiecePrice(piecePriceId, projectId)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectPiecePrice: (id) => dispatch(selectPiecePrice(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectPiecePricesContainer),
);

ProjectPiecePricesContainer.propTypes = {
  // fetchPiecePrices: PropTypes.func.isRequired,
  // addPiecePrice: PropTypes.func.isRequired,
  // updatePiecePrice: PropTypes.func.isRequired,
  // deletePiecePrice: PropTypes.func.isRequired,
  selectPiecePrice: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // piecePrices: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  // templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // user: PropTypes.shape(Object).isRequired,
};
