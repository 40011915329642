import {
  VERIFY_USER_BEGIN,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILURE,
  FETCH_USERS_BEGIN,
  FETCH_USERS_SUCCESS,
  FETCH_USERS_FAILURE,
  CHANGE_ADMIN_BEGIN,
  CHANGE_ADMIN_SUCCESS,
  CHANGE_ADMIN_FAILURE,
  CHANGE_ACTIVE_BEGIN,
  CHANGE_ACTIVE_SUCCESS,
  CHANGE_ACTIVE_FAILURE,
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD_BEGIN,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  SELECT_USER,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  TOGGLE_DELETE_USER_MODAL,
  UPDATE_USER_ROLE_BEGIN,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAILURE,
} from '../actionTypes/actionTypes';

const initialstate = {
  users: [],
  loading: false,
  error: null,
  selectedUser: '',
  deleteUserModalIsOpen: false,
};

export default function databaseReducer(state = initialstate, action) {
  switch (action.type) {
    case UPDATE_USER_ROLE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_USER_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_USER_ROLE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case TOGGLE_DELETE_USER_MODAL:
      return {
        ...state,
        deleteUserModalIsOpen: action.payload,
      };
    case SELECT_USER:
      return {
        ...state,
        selectedUser: action.payload.userId,
      };
    case DELETE_USER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CHANGE_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case RESET_PASSWORD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CHANGE_ADMIN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHANGE_ADMIN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CHANGE_ACTIVE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHANGE_ACTIVE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CHANGE_ACTIVE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_USERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload.users,
      };
    case FETCH_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case VERIFY_USER_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case VERIFY_USER_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case VERIFY_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
}
