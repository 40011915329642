import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton, Tooltip } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles(() => ({
  tableAddIconButton: {
    color: green[300],
  },
}));

function TableAddIconButton(props) {
  const classes = useStyles();
  const { onClick } = props;
  return (
    <Tooltip title="Statement hinzufügen">
      <IconButton className={classes.tableAddIconButton} onClick={onClick}>
        <Add />
      </IconButton>
    </Tooltip>
  );
}

export default TableAddIconButton;

TableAddIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
};
