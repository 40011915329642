import React /* ,  { useEffect }  */from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PROJECT, GET_TEMPLATES, GET_MODULES } from '../../graphql/queries';
import { UPDATE_STATEMENT } from '../../graphql/mutations';

import {
  fetchModules,
  selectProject,
  selectTemplate,
  selectModule,
} from '../../redux/actionCreators/offeringAction';
import OfferingModulesTable from '../../components/OfferingManagement/OfferingModulesTable';
import Loading from '../../components/Elements/Loading';

function OfferingModulesContainer(props) {
  const {
    // projects,
    // templates,
    // modules,
    // fetchModules,
    selectProject,
    selectedProject,
    selectTemplate,
    selectedTemplate,
    selectModule,
    // loading,
    // user,
  } = props;
  // useEffect(() => {
  //   fetchModules(selectedTemplate);
  // }, [fetchModules, selectedTemplate]);

  const { loading, error, data } = useQuery(GET_MODULES, {
    variables: { projectId: selectedProject, templateId: selectedTemplate },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });
  const { loading: loading2, error: error2, data: data2 } = useQuery(GET_TEMPLATES, {
    variables: { projectId: selectedProject },
  });
  const [updateStatement] = useMutation(UPDATE_STATEMENT, {
    refetchQueries: [{
      query: GET_MODULES,
      variables: { projectId: selectedProject, templateId: selectedTemplate },
    }],
  });

  if (loading || loading1 || loading2) return <Loading />;
  if (error || error1 || error2) {
    return (
      <p>
        Error :$
        {error + error1 + error2}
      </p>
    );
  }
  return (
    <OfferingModulesTable
      projects={data1.project}
      templates={data2.templates}
      modules={data.modules}
      selectModule={selectModule}
      selectTemplate={selectTemplate}
      selectedTemplate={selectedTemplate}
      selectProject={selectProject}
      selectedProject={selectedProject}
      // loading={loading}
      // user={user}
      updateStatement={updateStatement}
    />
  );
}

const mapStateToProps = (state) => ({
  projects: state.offering.projects,
  templates: state.offering.templates,
  modules: state.offering.modules,
  selectedProject: state.offering.selectedProject,
  selectedTemplate: state.offering.selectedTemplate,
  loading: state.offering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchModules: (templateId) => dispatch(fetchModules(templateId)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectModule: (id) => dispatch(selectModule(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferingModulesContainer),
);

OfferingModulesContainer.propTypes = {
  // fetchModules: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectModule: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // user: PropTypes.shape(Object).isRequired,
  // modules: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
};
