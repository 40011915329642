import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_INFORMATIONS, GET_PROJECT } from '../../graphql/queries';

import {
  selectSubcontractor,
  selectViewOfferingSubcontractorCompany,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/viewofferingAction';

import ViewOfferingSubcontractorsCompaniesTable from '../../components/ViewOffering/ViewOfferingSubcontractorsCompaniesTable';
import ViewOfferingSubcontractorsTable from '../../components/ViewOffering/ViewOfferingSubcontractorsTable';
import Loading from '../../components/Elements/Loading';

function ViewOfferingSubcontractorsCompaniesContainer(props) {
  const {
    selectSubcontractor,
    selectViewOfferingSubcontractorCompany,
    selectProject,
    selectTemplate,
    selectedProject,
    selectedSubcontractorCompany,
  } = props;

  const informationType = 'Subkontraktor';
  const { loading, error, data } = useQuery(GET_INFORMATIONS, {
    variables: { projectId: selectedProject, type: informationType },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  if (selectedSubcontractorCompany === '') {
    return (
      <ViewOfferingSubcontractorsCompaniesTable
        selectSubcontractor={selectSubcontractor}
        selectViewOfferingSubcontractorCompany={selectViewOfferingSubcontractorCompany}
        selectProject={selectProject}
        selectTemplate={selectTemplate}
        subcontractors={data.informations}
        projects={data1.project}
        selectedProject={selectedProject}
      />
    );
  }
  return (
    <ViewOfferingSubcontractorsTable
      selectSubcontractor={selectSubcontractor}
      selectViewOfferingSubcontractorCompany={selectViewOfferingSubcontractorCompany}
      selectProject={selectProject}
      selectTemplate={selectTemplate}
      subcontractors={data.informations}
      projects={data1.project}
      selectedProject={selectedProject}
      selectedSubcontractorCompany={selectedSubcontractorCompany}
    />
  );
}

const mapStateToProps = (state) => ({
  subcontractors: state.viewoffering.subcontractors,
  projects: state.viewoffering.projects,
  templates: state.viewoffering.templates,
  modules: state.viewoffering.modules,
  selectedProject: state.viewoffering.selectedProject,
  selectedSubcontractor: state.viewoffering.selectedSubcontractor,
  loading: state.viewoffering.loading,
  user: state.auth.user,
  selectedSubcontractorCompany: state.viewoffering.selectedSubcontractorCompany,

});

const mapDispatchToProps = (dispatch) => ({
  selectSubcontractor: (id) => dispatch(selectSubcontractor(id)),
  selectViewOfferingSubcontractorCompany: (company) =>
    dispatch(selectViewOfferingSubcontractorCompany(company)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ViewOfferingSubcontractorsCompaniesContainer),
);

ViewOfferingSubcontractorsCompaniesContainer.propTypes = {
  selectSubcontractor: PropTypes.func.isRequired,
  selectViewOfferingSubcontractorCompany: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectedSubcontractorCompany: PropTypes.string.isRequired,
};
