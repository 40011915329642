import React from 'react';
import { Typography, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  footer: {
    textAlign: 'center',
    bottom: 0,
    width: '100%',
    paddingTop: 10,
    paddingBottom: 10,
    background: 'none',
  },
}));

function Footer() {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <Typography variant="body2" color="textSecondary" align="center">
        ©
        {' '}
        {` ${new Date().getFullYear()} `}
        <Link color="inherit" href="https://www.pelo-it.com/">
          pelo IT GmbH
        </Link>
        {' | '}
        <Link color="inherit" href="/imprint">
          Impressum
        </Link>
        {' | '}
        <Link color="inherit" href="/privacy">
          Datenschutz
        </Link>
      </Typography>
    </div>
  );
}
export default Footer;
