import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import { LOGOUT_USER } from '../actionTypes/actionTypes';
// import reducers
import errorReducer from './errorReducer';
import authReducer from './authReducer';
import userReducer from './userReducer';
import settingsReducer from './settingsReducer';
import databaseReducer from './databaseReducer';
import projectReducer from './projectReducer';
import offeringReducer from './offeringReducer';
import negotiationReducer from './negotiationReducer';
import viewofferingReducer from './viewofferingReducer';
import notifierReducer from './notifierReducer';

export const appReducer = combineReducers({
  errors: errorReducer,
  auth: authReducer,
  user: userReducer,
  settings: settingsReducer,
  database: databaseReducer,
  project: projectReducer,
  offering: offeringReducer,
  negotiation: negotiationReducer,
  viewoffering: viewofferingReducer,
  notifier: notifierReducer,
});

export const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    Object.keys(state).forEach((key) => {
      storage.removeItem(`persist:${key}`);
    });
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
