import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_PRICES, GET_PROJECT } from '../../graphql/queries';

import {
  fetchTAndMs,
  fetchRatecards,
  selectTAndM,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/viewofferingAction';
import ViewOfferingTAndMsTable from '../../components/ViewOffering/ViewOfferingTAndMsTable';
import Loading from '../../components/Elements/Loading';

function ViewOfferingTAndMContainer(props) {
  const {
    templates,
    selectTAndM,
    selectProject,
    selectTemplate,
    selectedProject,
  } = props;

  const priceType = 'T&M';
  const { loading, error, data } = useQuery(GET_PRICES, {
    variables: { projectId: selectedProject, type: priceType },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  return (
    <ViewOfferingTAndMsTable
      tandms={data.pricesByType}
      projects={data1.project}
      templates={templates}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectTAndM={selectTAndM}
    />
  );
}

const mapStateToProps = (state) => ({
  tandms: state.viewoffering.tandms,
  ratecards: state.viewoffering.ratecards,
  projects: state.viewoffering.projects,
  templates: state.viewoffering.templates,
  selectedProject: state.viewoffering.selectedProject,
  loading: state.viewoffering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTAndMs: (id) => dispatch(fetchTAndMs(id)),
  fetchRatecards: (id) => dispatch(fetchRatecards(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectTAndM: (id) => dispatch(selectTAndM(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewOfferingTAndMContainer),
);

ViewOfferingTAndMContainer.propTypes = {
  selectTAndM: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
};
