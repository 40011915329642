import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_INFORMATIONS, GET_PROJECT } from '../../graphql/queries';
import {
  UPDATE_INFORMATION_CLUSTER, ADD_INFORMATION_CLUSTER, DELETE_INFORMATION_CLUSTER, UPDATE_INFORMATION,
} from '../../graphql/mutations';
import {
  selectSubcontractor,
  selectOfferingSubcontractorCompany,
  selectProject,
  selectTemplate,
  // fetchOfferingSubcontractors,
  // addOfferingSubcontractorCompany,
  // updateOfferingSubcontractorCompany,
  // deleteOfferingSubcontractorCompany,
} from '../../redux/actionCreators/offeringAction';

import OfferingSubcontractorsCompaniesTable from '../../components/OfferingManagement/OfferingSubcontractorsCompaniesTable';
import OfferingSubcontractorsTable from '../../components/OfferingManagement/OfferingSubcontractorsTable';
import Loading from '../../components/Elements/Loading';

function OfferingSubcontractorsCompaniesContainer(props) {
  const {
    selectSubcontractor,
    selectOfferingSubcontractorCompany,
    selectedSubcontractorCompany,
    selectProject,
    selectTemplate,
    selectedProject,
  } = props;

  const informationType = 'Subkontraktor';
  const { loading, error, data } = useQuery(GET_INFORMATIONS, {
    variables: { projectId: selectedProject, type: informationType },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [addInformationCluster] = useMutation(ADD_INFORMATION_CLUSTER, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType },
    }],
  });

  const [updateInformation] = useMutation(UPDATE_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType },
    }],
  });

  const [deleteInformationCluster] = useMutation(DELETE_INFORMATION_CLUSTER, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType },
    }],
  });

  const [updateInformationCluster] = useMutation(UPDATE_INFORMATION_CLUSTER, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  if (selectedSubcontractorCompany === '') {
    return (
      <OfferingSubcontractorsCompaniesTable
        selectSubcontractor={selectSubcontractor}
        selectOfferingSubcontractorCompany={selectOfferingSubcontractorCompany}
        selectProject={selectProject}
        selectTemplate={selectTemplate}
        addOfferingSubcontractorCompany={addInformationCluster}
        updateOfferingSubcontractorCompany={updateInformationCluster}
        deleteOfferingSubcontractorCompany={deleteInformationCluster}
        subcontractors={data.informations}
        projects={data1.project}
        selectedProject={selectedProject}
      />
    );
  }
  return (
    <OfferingSubcontractorsTable
      selectSubcontractor={selectSubcontractor}
      selectOfferingSubcontractorCompany={selectOfferingSubcontractorCompany}
      selectProject={selectProject}
      selectTemplate={selectTemplate}
      updateOfferingSubcontractor={updateInformation}
      subcontractors={data.informations}
      projects={data1.project}
      selectedProject={selectedProject}
      selectedSubcontractorCompany={selectedSubcontractorCompany}
    />
  );
}

const mapStateToProps = (state) => ({
  subcontractors: state.offering.subcontractors,
  projects: state.offering.projects,
  templates: state.offering.templates,
  modules: state.offering.modules,
  selectedProject: state.offering.selectedProject,
  selectedSubcontractor: state.offering.selectedSubcontractor,
  selectedSubcontractorCompany: state.offering.selectedSubcontractorCompany,
  loading: state.offering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  selectSubcontractor: (id) => dispatch(selectSubcontractor(id)),
  selectOfferingSubcontractorCompany: (company) =>
    dispatch(selectOfferingSubcontractorCompany(company)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OfferingSubcontractorsCompaniesContainer),
);

OfferingSubcontractorsCompaniesContainer.propTypes = {
  selectSubcontractor: PropTypes.func.isRequired,
  selectOfferingSubcontractorCompany: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectedSubcontractorCompany: PropTypes.string.isRequired,
};
