import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function ProjectFixedPricesTable(props) {
  const {
    fixedPrices,
    selectFixedPrice,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'templateName',
      label: 'Dokument',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'moduleName',
      label: 'Modul',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'price',
      label: 'Preis in €',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const data = [];

  fixedPrices.forEach((fixedprice) => {
    data.push({
      _id: fixedprice._id,
      template: fixedprice.template,
      moduleName: fixedprice.moduleName,
      templateName: fixedprice.templateName,
      price: 'Wird vom Anbieter ausgefüllt.',
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectFixedPrice('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageproject"
                onClick={() => selectFixedPrice('')}
              >
                Übersicht
              </Link>
              <Typography color="textPrimary">Festpreise</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({ download: true,
          downloadOptions: { filename: 'export.csv', filterOptions: { useDisplayedColumnsOnly: true } },
          sortOrder: { name: 'templateName', direction: 'asc' },
        })}
      />
    </div>
  );
}

export default ProjectFixedPricesTable;

ProjectFixedPricesTable.propTypes = {
  // projects: PropTypes.shape(Object).isRequired,
  fixedPrices: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // selectedProject: PropTypes.string.isRequired,
  // selectProject: PropTypes.func.isRequired,
  // selectTemplate: PropTypes.func.isRequired,
  selectFixedPrice: PropTypes.func.isRequired,
};
