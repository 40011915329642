import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import useStyles from '../../styles';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';
import EditDialogCurrency from '../Dialogs/EditDialogCurrency';
import CurrencyFormat from '../Elements/CurrencyFormat';

function OfferingFixedPricesTable(props) {
  const {
    fixedPrices,
    projects,
    selectProject,
    // selectedProject,
    selectTemplate,
    selectFixedPrice,
    updateFixedPrice,
    // user,
  } = props;

  const getSum = (total, num) => total + num;

  const classes = useStyles();
  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'template',
      label: 'TemplateId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'templateName',
      label: 'Dokument',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'moduleName',
      label: 'Modul',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'price',
      label: 'Preis in €',
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.rightAlignedHeader }),
        setCellProps: () => ({ className: classes.rightAlignedCell }),
        customBodyRender: (value) => <CurrencyFormat value={value} />,
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        display: !projects.published && projects.authorization !== 'read',
        viewColumns: !projects.published && projects.authorization !== 'read',
        customBodyRender: (value, tableMeta) => (
          <EditDialogCurrency
            priceId={tableMeta.rowData[0]}
            projectId={projects._id}
            header="Festpreis angeben"
            text="Festpreis in €"
            oldData={tableMeta.rowData[4].toLocaleString()}
            onClick={updateFixedPrice}
          />
        ),
      },
    },
  ];

  const data = [];

  fixedPrices.forEach((fixedprice) => {
    data.push({
      _id: fixedprice._id,
      template: fixedprice.template,
      moduleName: fixedprice.moduleName,
      templateName: fixedprice.templateName,
      price: fixedprice.price,
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectFixedPrice('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectFixedPrice('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => selectFixedPrice('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Festpreise</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({
          sortOrder: { name: 'templateName', direction: 'asc' },
        })}
      />
      <div style={{ textAlign: 'center', marginTop: 16 }}>
        <Typography variant="h6">
          Summe:
          {' '}
          <CurrencyFormat value={fixedPrices
            .map((fixedPrice) => fixedPrice.price)
            .reduce(getSum, 0)}
          />

        </Typography>
      </div>
    </div>
  );
}

export default OfferingFixedPricesTable;

OfferingFixedPricesTable.propTypes = {
  projects: PropTypes.shape(Object).isRequired,
  fixedPrices: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // user: PropTypes.shape(Object).isRequired,
  // selectedProject: PropTypes.string.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectFixedPrice: PropTypes.func.isRequired,
  updateFixedPrice: PropTypes.func.isRequired,
};
