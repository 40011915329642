import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';

import TableButton from '../Buttons/TableButton';
import AddDialog from '../Dialogs/AddDialog';
import EditDialog from '../Dialogs/EditDialog';
import DeleteDialog from '../Dialogs/DeleteDialog';
import TableSwitch from '../Buttons/TableSwitch';
import DateStamp from '../Elements/DateStamp';
import ExportButton from '../Elements/ExportButton';
import UploadHandler from '../Elements/UploadHandler';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function DatabaseProjectsTable(props) {
  const {
    projects,
    addProject,
    updateProject,
    deleteProject,
    selectProject,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <TableButton
            text={value}
            onClick={() => selectProject(tableMeta.rowData[0])}
          />
        ),
      },
    },
    {
      name: 'export',
      label: 'Export',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <ExportButton
            id={tableMeta.rowData[0]}
            url="/api/v1/export/word/"
          />
        ),
      },
    },
    {
      name: 'attachments',
      label: 'Anhänge',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <UploadHandler
            projectId={tableMeta.rowData[0]}
            referenceId={tableMeta.rowData[0]}
            url="/api/v1/upload/"
            referenceType="project"
            attachments={value}
          />
        ),
      },
    },
    {
      name: 'active',
      label: 'Aktiv',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <TableSwitch
            value={value}
            disabled={false}
            onChange={() => {
              updateProject({
                variables: { projectId: tableMeta.rowData[0], active: !value },
              });
            }}
          />
        ),
      },
    },
    {
      name: 'updated',
      label: 'Geändert',
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => <DateStamp date={value} />,
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <div>
            <EditDialog
              projectId={tableMeta.rowData[0]}
              oldData={tableMeta.rowData[1]}
              header="Cluster bearbeiten"
              text="Name"
              field="name"
              onClick={updateProject}
            />
            <DeleteDialog
              header="Cluster löschen"
              text={`Möchten Sie den Cluster "${tableMeta.rowData[1]}" wirklich löschen?`}
              onClick={() => deleteProject({
                variables: { projectId: tableMeta.rowData[0] },
              })}
            />
          </div>
        ),
      },
    },
  ];

  const data = [];

  projects.forEach((project) => {
    data.push({
      _id: project._id,
      name: project.name,
      active: project.active,
      updated: Math.max(...project.changes.map((o) => o.date)),
      attachments: project.attachments,
    });
  });

  return (
    <div>
      <MUIDataTable
        title="Datenbank"
        data={data}
        columns={columns}
        options={FullsizeTableOptions()}
      />
      <AddDialog
        id=""
        header="Cluster hinzufügen"
        text="Name"
        onClick={addProject}
        field="name"
        description=""
        type="database"
      />
    </div>
  );
}

export default DatabaseProjectsTable;

DatabaseProjectsTable.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  addProject: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
};
