import React from 'react';
import PropTypes from 'prop-types';
// import { Link as RouterLink } from 'react-router-dom';
import { Typography, Card, CardContent } from '@material-ui/core';

import MUIDataTable from 'mui-datatables';
import TableButton from '../Buttons/TableButton';
import AddDialog from '../Dialogs/AddDialog';
import EditDialog from '../Dialogs/EditDialog';
import DeleteDialog from '../Dialogs/DeleteDialog';
import DateStamp from '../Elements/DateStamp';
import UploadHandler from '../Elements/UploadHandler';
import TableUpButton from '../Buttons/TableUpButton';
import ExportButton from '../Elements/ExportButton';
import TableDownButton from '../Buttons/TableDownButton';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';
import ChangeUsersContainer from '../../container/ProjectManagement/ChangeUsersContainer';
import PublishProjectDialog from '../Dialogs/PublishProjectDialog';
import CopyProjectDialog from '../Dialogs/CopyProjectDialog';
import EditTwoFieldsDialog from '../Dialogs/EditTwoFieldsDialog';

function ProjectTemplatesTable(props) {
  const {
    projects,
    templates,
    addTemplate,
    updateTemplate,
    deleteTemplate,
    selectedProject,
    // selectProject,
    selectTemplate,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectSubcontractor,
    selectQAndA,
    user,
    templateNumberDown,
    templateNumberUp,
    changePublishedProject,
    copyProjectToDatabase,
    updateRoles,
    updateProject,
    deleteProject,
    // fetchAdditionalFilesIsShowing,
    isShowing,
  } = props;

  // useEffect(() => {
  //   fetchAdditionalFilesIsShowing(selectedProject);
  // }, [fetchAdditionalFilesIsShowing, selectedProject]);

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'number',
      label: '#',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { width: '50px' } }),
        customBodyRender: (value, tableMeta) => (
          <div>
            {!tableMeta.rowData[5] && (
              <div>
                {value !== 1 && !projects.published && projects.authorization !== 'read' && (
                <TableUpButton
                  onClick={() => templateNumberDown({ variables: { projectId: projects._id, templateId: tableMeta.rowData[0] } })}
                />
                )}
                {value}
                {value !== templates.length && !projects.published && projects.authorization !== 'read' && (
                <TableDownButton
                  onClick={() => templateNumberUp({ variables: { projectId: projects._id, templateId: tableMeta.rowData[0] } })}
                />
                )}
              </div>
            )}
          </div>
        ),
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <div>
            {!tableMeta.rowData[5] && (
              <TableButton
                text={value}
                onClick={() => selectTemplate(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[5] && value === 'Q&A' && (
              <TableButton
                text={value}
                onClick={() => selectQAndA(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[5] && value === 'Service Level' && (
              <TableButton
                text={value}
                onClick={() => selectSla(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[5] && value === 'Rate Card' && (
              <TableButton
                text={value}
                onClick={() => selectRatecard(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[5] && value === 'T&M' && (
              <TableButton
                text={value}
                onClick={() => selectTAndM(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[5] && value === 'Festpreise' && (
              <TableButton
                text={value}
                onClick={() => selectFixedPrice(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[5] && value === 'Stückpreise' && (
              <TableButton
                text={value}
                onClick={() => selectPiecePrice(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[5] && value === 'Informationen' && (
              <TableButton
                text={value}
                onClick={() => selectInformation(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[5] && value === 'Referenzen' && (
              <TableButton
                text={value}
                onClick={() => selectReference(tableMeta.rowData[0])}
              />
            )}
            {tableMeta.rowData[5] && value === 'Subkontraktoren' && (
              <TableButton
                text={value}
                onClick={() => selectSubcontractor(tableMeta.rowData[0])}
              />
            )}
          </div>
        ),
      },
    },
    {
      name: 'attachment',
      label: 'Anhänge',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <div>
            {!tableMeta.rowData[5] && (
              <UploadHandler
                projectId={selectedProject}
                referenceId={tableMeta.rowData[0]}
                url="/api/v1/upload/"
                referenceType="template"
                attachments={value}
                disabled={(projects.published || projects.authorization === 'read')}
              />
            )}
          </div>
        ),
      },
    },
    {
      name: 'updated',
      label: 'Geändert',
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value, tableMeta) => (
          <>{!tableMeta.rowData[5] && <DateStamp date={value} />}</>
        ),
      },
    },
    {
      name: 'isAdditional',
      label: 'Weiteres Dokument',
      options: {
        filter: false,
        sort: false,
        display: 'excluded',
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        display: !projects.published && projects.authorization !== 'read',
        viewColumns: !projects.published && projects.authorization !== 'read',
        customBodyRender: (value, tableMeta) => (
          <div style={{ display: 'flex' }}>
            {!tableMeta.rowData[5] && (
              <>
                <EditDialog
                  templateId={tableMeta.rowData[0]}
                  projectId={selectedProject}
                  oldData={tableMeta.rowData[2]}
                  header="Template bearbeiten"
                  text="Name"
                  field="name"
                  onClick={updateTemplate}
                />
                {projects.authorization === 'admin' && (
                  <DeleteDialog
                    header="Template löschen"
                    text={`Möchten Sie das Modul "${tableMeta.rowData[2]}" wirklich löschen?`}
                    onClick={() => deleteTemplate({
                      variables: { projectId: projects._id, templateId: tableMeta.rowData[0] },
                    })}
                  />
                )}
              </>
            )}
          </div>
        ),
      },
    },
  ];

  const data = [];

  templates
    .forEach((template) => {
      data.push({
        _id: template._id,
        number: template.number,
        name: template.name,
        attachment: template.attachments,
        updated: Math.max(...template.changes.map((o) => o.date)),
        isAdditional: false,
      });
    });

  isShowing.forEach((temp) => {
    if (temp) {
      data.push({
        _id: selectedProject,
        name: temp,
        attachment: [],
        isAdditional: true,
      });
    }
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Typography color="textPrimary">
              {`Projekt: ${projects.name}`}
            </Typography>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({
          tableBodyMaxHeight: 'calc(100vh - 295px)', sort: false })}
      />
      {(!projects.published && projects.authorization !== 'read') && (
      <AddDialog
        projectId={selectedProject}
        header="SOW hinzufügen"
        text="Name"
        field="name"
        onClick={addTemplate}
      />
      )}
      <div style={{ display: 'flex',
        padding: '5px',
        justifyContent: 'flex-start' }}
      >
        <Card style={{ margin: '5px', width: '500px', height: '150px', overflow: 'auto' }}>
          <CardContent>
            <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
              {`Beschreibung: 
              ${projects.description}`}
            </Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body2" style={{ whiteSpace: 'pre-line' }}>
                {`Laufzeit: ${projects.duration} Jahre`}
              </Typography>
              {(projects.authorization !== 'read' && !projects.published)
              && (
                <EditDialog
                  projectId={projects._id}
                  oldData={projects.duration}
                  header="Laufzeit bearbeiten"
                  text="Laufzeit"
                  field="duration"
                  onClick={updateProject}
                />
              )}
            </div>
          </CardContent>
        </Card>
        <Card style={{ margin: '5px' }}>
          <CardContent>
            <UploadHandler
              projectId={selectedProject}
              referenceId={selectedProject}
              url="/api/v1/upload/"
              referenceType="project"
              attachments={projects.attachments}
              disabled={(projects.published || projects.authorization === 'read')}
            />
          </CardContent>
        </Card>
        <Card style={{ margin: '5px' }}>
          <CardContent>
            {!projects.published
          && (
            <Typography variant="body2">
              Status: In Bearbeitung
            </Typography>
          )}
            {projects.published
          && (
            <Typography variant="body2">
              Veröffentlicht / Deadline:
              <DateStamp date={projects.deadline} />
            </Typography>
          )}
            {((projects.published && projects.authorization !== 'read') || !projects.published)
            && (
              <div>
                <PublishProjectDialog
                  id={projects._id}
                  onClick={changePublishedProject}
                  type="project"
                  published={projects.published}
                />
              </div>
            )}
          </CardContent>
        </Card>
        <Card style={{ margin: '5px' }}>
          <CardContent style={{ display: 'flex', flexWrap: 'wrap' }}>
            {projects.authorization !== 'read' && !projects.published
              && (
                <>
                  <EditTwoFieldsDialog
                    projectId={projects._id}
                    oldData={[projects.name, projects.description]}
                    header="Projekt bearbeiten"
                    fields={{ fieldOne: 'Name', fieldTwo: 'Beschreibung' }}
                    onClick={updateProject}
                  />
                </>
              )}
            <ChangeUsersContainer
              projectId={projects._id}
              published={projects.published}
              roles={projects.roles}
              updateRoles={updateRoles}
              userEmail={user.email}
            />
            <ExportButton
              id={projects._id}
              url="/api/v1/export/word/"
            />

            {projects.authorization === 'admin' && (
            <>
              <DeleteDialog
                header="Projekt löschen"
                text={`Möchten Sie das Projekt "${projects.name}" wirklich löschen?`}
                onClick={() => deleteProject({
                  variables: { projectId: projects._id },
                })}
              />
              <CopyProjectDialog
                id={projects._id}
                header="Projekt kopieren"
                text={`Möchten Sie das Projekt "${projects.name}" wirklich in die Datenbank kopieren?`}
                onClick={copyProjectToDatabase}
              />
            </>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}

export default ProjectTemplatesTable;

ProjectTemplatesTable.propTypes = {
  templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
  selectedProject: PropTypes.string.isRequired,
  // selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectSla: PropTypes.func.isRequired,
  selectRatecard: PropTypes.func.isRequired,
  selectTAndM: PropTypes.func.isRequired,
  selectFixedPrice: PropTypes.func.isRequired,
  selectPiecePrice: PropTypes.func.isRequired,
  selectInformation: PropTypes.func.isRequired,
  selectReference: PropTypes.func.isRequired,
  selectSubcontractor: PropTypes.func.isRequired,
  selectQAndA: PropTypes.func.isRequired,
  addTemplate: PropTypes.func.isRequired,
  updateTemplate: PropTypes.func.isRequired,
  deleteTemplate: PropTypes.func.isRequired,
  changePublishedProject: PropTypes.func.isRequired,
  copyProjectToDatabase: PropTypes.func.isRequired,
  updateRoles: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  deleteProject: PropTypes.func.isRequired,
  user: PropTypes.shape(Object).isRequired,
  templateNumberDown: PropTypes.func.isRequired,
  templateNumberUp: PropTypes.func.isRequired,
  // fetchAdditionalFilesIsShowing: PropTypes.func.isRequired,
  isShowing: PropTypes.arrayOf(PropTypes.string).isRequired,
};
