export const GET_ERRORS = 'GET_ERRORS';
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export const TOGGLE_DARK_MODE = 'TOGGLE_DARK_MODE';

export const FETCH_PROJECTS_BEGIN = 'FETCH_PROJECTS_BEGIN';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';

export const PROJECT_FETCH_PROJECTS_BEGIN = 'PROJECT_FETCH_PROJECTS_BEGIN';
export const PROJECT_FETCH_PROJECTS_SUCCESS = 'PROJECT_FETCH_PROJECTS_SUCCESS';
export const PROJECT_FETCH_PROJECTS_FAILURE = 'PROJECT_FETCH_PROJECTS_FAILURE';

export const OFFERING_FETCH_PROJECTS_BEGIN = 'OFFERING_FETCH_PROJECTS_BEGIN';
export const OFFERING_FETCH_PROJECTS_SUCCESS = 'OFFERING_FETCH_PROJECTS_SUCCESS';
export const OFFERING_FETCH_PROJECTS_FAILURE = 'OFFERING_FETCH_PROJECTS_FAILURE';

export const FETCH_TEMPLATES_BEGIN = 'FETCH_TEMPLATES_BEGIN';
export const FETCH_TEMPLATES_SUCCESS = 'FETCH_TEMPLATES_SUCCESS';
export const FETCH_TEMPLATES_FAILURE = 'FETCH_TEMPLATES_FAILURE';

export const PROJECT_FETCH_TEMPLATES_BEGIN = 'PROJECT_FETCH_TEMPLATES_BEGIN';
export const PROJECT_FETCH_TEMPLATES_SUCCESS = 'PROJECT_FETCH_TEMPLATES_SUCCESS';
export const PROJECT_FETCH_TEMPLATES_FAILURE = 'PROJECT_FETCH_TEMPLATES_FAILURE';

export const OFFERING_FETCH_TEMPLATES_BEGIN = 'OFFERING_FETCH_TEMPLATES_BEGIN';
export const OFFERING_FETCH_TEMPLATES_SUCCESS = 'OFFERING_FETCH_TEMPLATES_SUCCESS';
export const OFFERING_FETCH_TEMPLATES_FAILURE = 'OFFERING_FETCH_TEMPLATES_FAILURE';

export const FETCH_MODULES_BEGIN = 'FETCH_MODULES_BEGIN';
export const FETCH_MODULES_SUCCESS = 'FETCH_MODULES_SUCCESS';
export const FETCH_MODULES_FAILURE = 'FETCH_MODULES_FAILURE';

export const PROJECT_FETCH_MODULES_BEGIN = 'PROJECT_FETCH_MODULES_BEGIN';
export const PROJECT_FETCH_MODULES_SUCCESS = 'PROJECT_FETCH_MODULES_SUCCESS';
export const PROJECT_FETCH_MODULES_FAILURE = 'PROJECT_FETCH_MODULES_FAILURE';

export const OFFERING_FETCH_MODULES_BEGIN = 'OFFERING_FETCH_MODULES_BEGIN';
export const OFFERING_FETCH_MODULES_SUCCESS = 'OFFERING_FETCH_MODULES_SUCCESS';
export const OFFERING_FETCH_MODULES_FAILURE = 'OFFERING_FETCH_MODULES_FAILURE';

export const FETCH_STATEMENTS_BEGIN = 'FETCH_STATEMENTS_BEGIN';
export const FETCH_STATEMENTS_SUCCESS = 'FETCH_STATEMENTS_SUCCESS';
export const FETCH_STATEMENTS_FAILURE = 'FETCH_STATEMENTS_FAILURE';

export const PROJECT_FETCH_STATEMENTS_BEGIN = 'PROJECT_FETCH_STATEMENTS_BEGIN';
export const PROJECT_FETCH_STATEMENTS_SUCCESS = 'PROJECT_FETCH_STATEMENTS_SUCCESS';
export const PROJECT_FETCH_STATEMENTS_FAILURE = 'PROJECT_FETCH_STATEMENTS_FAILURE';

export const OFFERING_FETCH_STATEMENTS_BEGIN = 'OFFERING_FETCH_STATEMENTS_BEGIN';
export const OFFERING_FETCH_STATEMENTS_SUCCESS = 'OFFERING_FETCH_STATEMENTS_SUCCESS';
export const OFFERING_FETCH_STATEMENTS_FAILURE = 'OFFERING_FETCH_STATEMENTS_FAILURE';

export const CLEAR_PROJECTS_ARRAY = 'CLEAR_PROJECTS_ARRAY';
export const CLEAR_TEMPLATES_ARRAY = 'CLEAR_TEMPLATES_ARRAY';
export const CLEAR_MODULES_ARRAY = 'CLEAR_MODULES_ARRAY';
export const CLEAR_STATEMENTS_ARRAY = 'CLEAR_STATEMENTS_ARRAY';

export const PROJECT_CLEAR_PROJECTS_ARRAY = 'PROJECT_CLEAR_PROJECTS_ARRAY';
export const PROJECT_CLEAR_TEMPLATES_ARRAY = 'PROJECT_CLEAR_TEMPLATES_ARRAY';
export const PROJECT_CLEAR_MODULES_ARRAY = 'PROJECT_CLEAR_MODULES_ARRAY';
export const PROJECT_CLEAR_STATEMENTS_ARRAY = 'PROJECT_CLEAR_STATEMENTS_ARRAY';

export const OFFERING_CLEAR_PROJECTS_ARRAY = 'OFFERING_CLEAR_PROJECTS_ARRAY';
export const OFFERING_CLEAR_TEMPLATES_ARRAY = 'OFFERING_CLEAR_TEMPLATES_ARRAY';
export const OFFERING_CLEAR_MODULES_ARRAY = 'OFFERING_CLEAR_MODULES_ARRAY';
export const OFFERING_CLEAR_STATEMENTS_ARRAY = 'OFFERING_CLEAR_STATEMENTS_ARRAY';

export const SELECT_PROJECT = 'SELECT_PROJECT';
export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const SELECT_MODULE = 'SELECT_MODULE';
export const SELECT_STATEMENT = 'SELECT_STATEMENT';

export const PROJECT_SELECT_PROJECT = 'PROJECT_SELECT_PROJECT';
export const PROJECT_SELECT_TEMPLATE = 'PROJECT_SELECT_TEMPLATE';
export const PROJECT_SELECT_MODULE = 'PROJECT_SELECT_MODULE';
export const PROJECT_SELECT_STATEMENT = 'PROJECT_SELECT_STATEMENT';

export const OFFERING_SELECT_PROJECT = 'OFFERING_SELECT_PROJECT';
export const OFFERING_SELECT_TEMPLATE = 'OFFERING_SELECT_TEMPLATE';
export const OFFERING_SELECT_MODULE = 'OFFERING_SELECT_MODULE';
export const OFFERING_SELECT_STATEMENT = 'OFFERING_SELECT_STATEMENT';

export const ADD_PROJECT_BEGIN = 'ADD_PROJECT_BEGIN';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';

export const ADD_TEMPLATE_BEGIN = 'ADD_TEMPLATE_BEGIN';
export const ADD_TEMPLATE_SUCCESS = 'ADD_TEMPLATE_SUCCESS';
export const ADD_TEMPLATE_FAILURE = 'ADD_TEMPLATE_FAILURE';

export const ADD_PROJECT_TEMPLATE_BEGIN = 'ADD_PROJECT_TEMPLATE_BEGIN';
export const ADD_PROJECT_TEMPLATE_SUCCESS = 'ADD_PROJECT_TEMPLATE_SUCCESS';
export const ADD_PROJECT_TEMPLATE_FAILURE = 'ADD_PROJECT_TEMPLATE_FAILURE';

export const ADD_EXISTING_TEMPLATE_BEGIN = 'ADD_EXISTING_TEMPLATE_BEGIN';
export const ADD_EXISTING_TEMPLATE_SUCCESS = 'ADD_EXISTING_TEMPLATE_SUCCESS';
export const ADD_EXISTING_TEMPLATE_FAILURE = 'ADD_EXISTING_TEMPLATE_FAILURE';

export const ADD_EXISTING_MODULE_BEGIN = 'ADD_EXISTING_MODULE_BEGIN';
export const ADD_EXISTING_MODULE_SUCCESS = 'ADD_EXISTING_MODULE_SUCCESS';
export const ADD_EXISTING_MODULE_FAILURE = 'ADD_EXISTING_MODULE_FAILURE';

export const ADD_EXISTING_PROJECT_TEMPLATE_BEGIN = 'ADD_EXISTING_PROJECT_TEMPLATE_BEGIN';
export const ADD_EXISTING_PROJECT_TEMPLATE_SUCCESS = 'ADD_EXISTING_PROJECT_TEMPLATE_SUCCESS';
export const ADD_EXISTING_PROJECT_TEMPLATE_FAILURE = 'ADD_EXISTING_PROJECT_TEMPLATE_FAILURE';

export const ADD_EXISTING_PROJECT_MODULE_BEGIN = 'ADD_EXISTING_PROJECT_MODULE_BEGIN';
export const ADD_EXISTING_PROJECT_MODULE_SUCCESS = 'ADD_EXISTING_PROJECT_MODULE_SUCCESS';
export const ADD_EXISTING_PROJECT_MODULE_FAILURE = 'ADD_EXISTING_PROJECT_MODULE_FAILURE';

export const ADD_MODULE_BEGIN = 'ADD_MODULE_BEGIN';
export const ADD_MODULE_SUCCESS = 'ADD_MODULE_SUCCESS';
export const ADD_MODULE_FAILURE = 'ADD_MODULE_FAILURE';

export const ADD_PROJECT_MODULE_BEGIN = 'ADD_PROJECT_MODULE_BEGIN';
export const ADD_PROJECT_MODULE_SUCCESS = 'ADD_PROJECT_MODULE_SUCCESS';
export const ADD_PROJECT_MODULE_FAILURE = 'ADD_PROJECT_MODULE_FAILURE';

export const ADD_STATEMENT_BEGIN = 'ADD_STATEMENT_BEGIN';
export const ADD_STATEMENT_SUCCESS = 'ADD_STATEMENT_SUCCESS';
export const ADD_STATEMENT_FAILURE = 'ADD_STATEMENT_FAILURE';

export const ADD_PROJECT_STATEMENT_BEGIN = 'ADD_PROJECT_STATEMENT_BEGIN';
export const ADD_PROJECT_STATEMENT_SUCCESS = 'ADD_PROJECT_STATEMENT_SUCCESS';
export const ADD_PROJECT_STATEMENT_FAILURE = 'ADD_PROJECT_STATEMENT_FAILURE';

export const DELETE_STATEMENT_BEGIN = 'DELETE_STATEMENT_BEGIN';
export const DELETE_STATEMENT_SUCCESS = 'DELETE_STATEMENT_SUCCESS';
export const DELETE_STATEMENT_FAILURE = 'DELETE_STATEMENT_FAILURE';

export const DELETE_MODULE_BEGIN = 'DELETE_MODULE_BEGIN';
export const DELETE_MODULE_SUCCESS = 'DELETE_MODULE_SUCCESS';
export const DELETE_MODULE_FAILURE = 'DELETE_MODULE_FAILURE';

export const DELETE_TEMPLATE_BEGIN = 'DELETE_TEMPLATE_BEGIN';
export const DELETE_TEMPLATE_SUCCESS = 'DELETE_TEMPLATE_SUCCESS';
export const DELETE_TEMPLATE_FAILURE = 'DELETE_TEMPLATE_FAILURE';

export const DELETE_PROJECT_STATEMENT_BEGIN = 'DELETE_PROJECT_STATEMENT_BEGIN';
export const DELETE_PROJECT_STATEMENT_SUCCESS = 'DELETE_PROJECT_STATEMENT_SUCCESS';
export const DELETE_PROJECT_STATEMENT_FAILURE = 'DELETE_PROJECT_STATEMENT_FAILURE';

export const DELETE_PROJECT_MODULE_BEGIN = 'DELETE_PROJECT_MODULE_BEGIN';
export const DELETE_PROJECT_MODULE_SUCCESS = 'DELETE_PROJECT_MODULE_SUCCESS';
export const DELETE_PROJECT_MODULE_FAILURE = 'DELETE_PROJECT_MODULE_FAILURE';

export const DELETE_PROJECT_TEMPLATE_BEGIN = 'DELETE_PROJECT_TEMPLATE_BEGIN';
export const DELETE_PROJECT_TEMPLATE_SUCCESS = 'DELETE_PROJECT_TEMPLATE_SUCCESS';
export const DELETE_PROJECT_TEMPLATE_FAILURE = 'DELETE_PROJECT_TEMPLATE_FAILURE';

export const DELETE_PROJECT_BEGIN = 'DELETE_PROJECT_BEGIN';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

export const PROJECT_DELETE_PROJECT_BEGIN = 'PROJECT_DELETE_PROJECT_BEGIN';
export const PROJECT_DELETE_PROJECT_SUCCESS = 'PROJECT_DELETE_PROJECT_SUCCESS';
export const PROJECT_DELETE_PROJECT_FAILURE = 'PROJECT_DELETE_PROJECT_FAILURE';

export const TOGGLE_ADD_PROJECT_MODAL = 'TOGGLE_ADD_PROJECT_MODAL';
export const TOGGLE_ADD_TEMPLATE_MODAL = 'TOGGLE_ADD_TEMPLATE_MODAL';
export const TOGGLE_ADD_MODULE_MODAL = 'TOGGLE_ADD_MODULE_MODAL';
export const TOGGLE_ADD_STATEMENT_MODAL = 'TOGGLE_ADD_STATEMENT_MODAL';

export const TOGGLE_ADD_PROJECT_TEMPLATE_MODAL = 'TOGGLE_ADD_PROJECT_TEMPLATE_MODAL';
export const TOGGLE_UPDATE_PROJECT_TEMPLATE_MODAL = 'TOGGLE_UPDATE_PROJECT_TEMPLATE_MODAL';
export const TOGGLE_DELETE_PROJECT_TEMPLATE_MODAL = 'TOGGLE_DELETE_PROJECT_TEMPLATE_MODAL';

export const TOGGLE_ADD_PROJECT_MODULE_MODAL = 'TOGGLE_ADD_PROJECT_MODULE_MODAL';
export const TOGGLE_UPDATE_PROJECT_MODULE_MODAL = 'TOGGLE_UPDATE_PROJECT_MODULE_MODAL';
export const TOGGLE_DELETE_PROJECT_MODULE_MODAL = 'TOGGLE_DELETE_PROJECT_MODULE_MODAL';

export const TOGGLE_ADD_PROJECT_STATEMENT_MODAL = 'TOGGLE_ADD_PROJECT_STATEMENT_MODAL';
export const TOGGLE_UPDATE_PROJECT_STATEMENT_MODAL = 'TOGGLE_UPDATE_PROJECT_STATEMENT_MODAL';
export const TOGGLE_DELETE_PROJECT_STATEMENT_MODAL = 'TOGGLE_DELETE_PROJECT_STATEMENT_MODAL';

export const TOGGLE_DELETE_PROJECT_MODAL = 'TOGGLE_DELETE_PROJECT_MODAL';
export const TOGGLE_DELETE_TEMPLATE_MODAL = 'TOGGLE_DELETE_TEMPLATE_MODAL';
export const TOGGLE_DELETE_MODULE_MODAL = 'TOGGLE_DELETE_MODULE_MODAL';
export const TOGGLE_DELETE_STATEMENT_MODAL = 'TOGGLE_DELETE_STATEMENT_MODAL';

export const TOGGLE_DELETE_PROJECT_PROJECT_MODAL = 'TOGGLE_DELETE_PROJECT_PROJECT_MODAL';
export const TOGGLE_UPDATE_PROJECT_PROJECT_MODAL = 'TOGGLE_UPDATE_PROJECT_PROJECT_MODAL';

export const TOGGLE_UPDATE_PROJECT_MODAL = 'TOGGLE_UPDATE_PROJECT_MODAL';
export const TOGGLE_UPDATE_TEMPLATE_MODAL = 'TOGGLE_UPDATE_TEMPLATE_MODAL';
export const TOGGLE_UPDATE_MODULE_MODAL = 'TOGGLE_UPDATE_MODULE_MODAL';
export const TOGGLE_UPDATE_STATEMENT_MODAL = 'TOGGLE_UPDATE_STATEMENT_MODAL';

export const TOGGLE_UPDATE_EDITORS_MODAL = 'TOGGLE_UPDATE_EDITORS_MODAL';

export const UPDATE_PROJECT_BEGIN = 'UPDATE_PROJECT_BEGIN';
export const UPDATE_PROJECT_SUCCESS = 'UPDATE_PROJECT_SUCCESS';
export const UPDATE_PROJECT_FAILURE = 'UPDATE_PROJECT_FAILURE';

export const PROJECT_UPDATE_PROJECT_BEGIN = 'PROJECT_UPDATE_PROJECT_BEGIN';
export const PROJECT_UPDATE_PROJECT_SUCCESS = 'PROJECT_UPDATE_PROJECT_SUCCESS';
export const PROJECT_UPDATE_PROJECT_FAILURE = 'PROJECT_UPDATE_PROJECT_FAILURE';

export const UPDATE_TEMPLATE_BEGIN = 'UPDATE_TEMPLATE_BEGIN';
export const UPDATE_TEMPLATE_SUCCESS = 'UPDATE_TEMPLATE_SUCCESS';
export const UPDATE_TEMPLATE_FAILURE = 'UPDATE_TEMPLATE_FAILURE';

export const UPDATE_MODULE_BEGIN = 'UPDATE_MODULE_BEGIN';
export const UPDATE_MODULE_SUCCESS = 'UPDATE_MODULE_SUCCESS';
export const UPDATE_MODULE_FAILURE = 'UPDATE_MODULE_FAILURE';

export const UPDATE_STATEMENT_BEGIN = 'UPDATE_STATEMENT_BEGIN';
export const UPDATE_STATEMENT_SUCCESS = 'UPDATE_STATEMENT_SUCCESS';
export const UPDATE_STATEMENT_FAILURE = 'UPDATE_STATEMENT_FAILURE';

export const UPDATE_PROJECT_TEMPLATE_BEGIN = 'UPDATE_PROJECT_TEMPLATE_BEGIN';
export const UPDATE_PROJECT_TEMPLATE_SUCCESS = 'UPDATE_PROJECT_TEMPLATE_SUCCESS';
export const UPDATE_PROJECT_TEMPLATE_FAILURE = 'UPDATE_PROJECT_TEMPLATE_FAILURE';

export const UPDATE_PROJECT_MODULE_BEGIN = 'UPDATE_PROJECT_MODULE_BEGIN';
export const UPDATE_PROJECT_MODULE_SUCCESS = 'UPDATE_PROJECT_MODULE_SUCCESS';
export const UPDATE_PROJECT_MODULE_FAILURE = 'UPDATE_PROJECT_MODULE_FAILURE';

export const UPDATE_PROJECT_STATEMENT_BEGIN = 'UPDATE_PROJECT_STATEMENT_BEGIN';
export const UPDATE_PROJECT_STATEMENT_SUCCESS = 'UPDATE_PROJECT_STATEMENT_SUCCESS';
export const UPDATE_PROJECT_STATEMENT_FAILURE = 'UPDATE_PROJECT_STATEMENT_FAILURE';

export const UPDATE_EDITORS_BEGIN = 'UPDATE_EDITORS_BEGIN';
export const UPDATE_EDITORS_SUCCESS = 'UPDATEEDITORS_SUCCESS';
export const UPDATE_EDITORS_FAILURE = 'UPDATE_EDITORS_FAILURE';

export const SELECT_PROJECT_TEMPLATE = 'SELECT_PROJECT_TEMPLATE';
export const SELECT_PROJECT_MODULE = 'SELECT_PROJECT_MODULE';
export const REMOVE_PROJECT_TEMPLATE = 'REMOVE_PROJECT_TEMPLATE';
export const REMOVE_PROJECT_MODULE = 'REMOVE_PROJECT_MODULE';

export const CLEAR_PROJECT_TEMPLATES = 'CLEAR_PROJECT_TEMPLATES';
export const CLEAR_PROJECT_MODULES = 'CLEAR_PROJECT_MODULES';

export const CREATE_PROJECT_BEGIN = 'CREATE_PROJECT_BEGIN';
export const CREATE_PROJECT_SUCCESS = 'CREATE_PROJECT_SUCCESS';
export const CREATE_PROJECT_FAILURE = 'CREATE_PROJECT_FAILURE';

export const FETCH_PROJECT_EDITORS_BEGIN = 'FETCH_PROJECT_EDITORS_BEGIN';
export const FETCH_PROJECT_EDITORS_SUCCESS = 'FETCH_PROJECT_EDITORS_SUCCESS';
export const FETCH_PROJECT_EDITORS_FAILURE = 'FETCH_PROJECT_EDITORS_FAILURE';

export const FETCH_PROJECT_VIEWERS_BEGIN = 'FETCH_PROJECT_VIEWERS_BEGIN';
export const FETCH_PROJECT_VIEWERS_SUCCESS = 'FETCH_PROJECT_VIEWERS_SUCCESS';
export const FETCH_PROJECT_VIEWERS_FAILURE = 'FETCH_PROJECT_VIEWERS_FAILURE';

export const UPDATE_VIEWERS_BEGIN = 'UPDATE_VIEWERS_BEGIN';
export const UPDATE_VIEWERS_SUCCESS = 'UPDATE_VIEWERS_SUCCESS';
export const UPDATE_VIEWERS_FAILURE = 'UPDATE_VIEWERS_FAILURE';

export const FETCH_PROJECT_CONTRACTORS_BEGIN = 'FETCH_PROJECT_CONTRACTORS_BEGIN';
export const FETCH_PROJECT_CONTRACTORS_SUCCESS = 'FETCH_PROJECT_CONTRACTORS_SUCCESS';
export const FETCH_PROJECT_CONTRACTORS_FAILURE = 'FETCH_PROJECT_CONTRACTORS_FAILURE';
export const UPDATE_CONTRACTORS_BEGIN = 'UPDATE_CONTRACTORS_BEGIN';
export const UPDATE_CONTRACTORS_SUCCESS = 'UPDATE_CONTRACTORS_SUCCESS';
export const UPDATE_CONTRACTORS_FAILURE = 'UPDATE_CONTRACTORS_FAILURE';

export const VERIFY_USER_BEGIN = 'VERIFY_USER_BEGIN';
export const VERIFY_USER_SUCCESS = 'VERIFY_USER_SUCCESS';
export const VERIFY_USER_FAILURE = 'VERIFY_USER_FAILURE';

export const FETCH_USERS_BEGIN = 'FETCH_USERS_BEGIN';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE';

export const CHANGE_ADMIN_BEGIN = 'CHANGE_ADMIN_BEGIN';
export const CHANGE_ADMIN_SUCCESS = 'CHANGE_ADMIN_SUCCESS';
export const CHANGE_ADMIN_FAILURE = 'CHANGE_ADMIN_FAILURE';

export const CHANGE_ACTIVE_BEGIN = 'CHANGE_ACTIVE_BEGIN';
export const CHANGE_ACTIVE_SUCCESS = 'CHANGE_ACTIVE_SUCCESS';
export const CHANGE_ACTIVE_FAILURE = 'CHANGE_ACTIVE_FAILURE';

export const RESET_PASSWORD_BEGIN = 'RESET_PASSWORD_BEGIN';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const CHANGE_PASSWORD_BEGIN = 'CHANGE_PASSWORD_BEGIN';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

export const CHANGE_ACTIVE_PROJECT_BEGIN = 'CHANGE_ACTIVE_PROJECT_BEGIN';
export const CHANGE_ACTIVE_PROJECT_SUCCESS = 'CHANGE_ACTIVE_PROJECT_SUCCESS';
export const CHANGE_ACTIVE_PROJECT_FAILURE = 'CHANGE_ACTIVE_PROJECT_FAILURE';

export const CHANGE_ACTIVE_TEMPLATE_BEGIN = 'CHANGE_ACTIVE_TEMPLATE_BEGIN';
export const CHANGE_ACTIVE_TEMPLATE_SUCCESS = 'CHANGE_ACTIVE_TEMPLATE_SUCCESS';
export const CHANGE_ACTIVE_TEMPLATE_FAILURE = 'CHANGE_ACTIVE_TEMPLATE_FAILURE';

export const CHANGE_ACTIVE_MODULE_BEGIN = 'CHANGE_ACTIVE_MODULE_BEGIN';
export const CHANGE_ACTIVE_MODULE_SUCCESS = 'CHANGE_ACTIVE_MODULE_SUCCESS';
export const CHANGE_ACTIVE_MODULE_FAILURE = 'CHANGE_ACTIVE_MODULE_FAILURE';

export const CHANGE_ACTIVE_STATEMENT_BEGIN = 'CHANGE_ACTIVE_STATEMENT_BEGIN';
export const CHANGE_ACTIVE_STATEMENT_SUCCESS = 'CHANGE_ACTIVE_STATEMENT_SUCCESS';
export const CHANGE_ACTIVE_STATEMENT_FAILURE = 'CHANGE_ACTIVE_STATEMENT_FAILURE';

export const CHANGE_PUBLISHED_PROJECT_BEGIN = 'CHANGE_PUBLISHED_PROJECT_BEGIN';
export const CHANGE_PUBLISHED_PROJECT_SUCCESS = 'CHANGE_PUBLISHED_PROJECT_SUCCESS';
export const CHANGE_PUBLISHED_PROJECT_FAILURE = 'CHANGE_PUBLISHED_PROJECT_FAILURE';

export const COPY_PROJECT_TO_DATABASE_BEGIN = 'COPY_PROJECT_TO_DATABASE_BEGIN';
export const COPY_PROJECT_TO_DATABASE_SUCCESS = 'COPY_PROJECT_TO_DATABASE_SUCCESS';
export const COPY_PROJECT_TO_DATABASE_FAILURE = 'COPY_PROJECT_TO_DATABASE_FAILURE';

export const CREATE_OFFERING_BEGIN = 'CREATE_OFFERING_BEGIN';
export const CREATE_OFFERING_SUCCESS = 'CREATE_OFFERING_SUCCESS';
export const CREATE_OFFERING_FAILURE = 'CREATE_OFFERING_FAILURE';

export const SELECT_USER = 'SELECT_USER';
export const DELETE_USER_BEGIN = 'DELETE_USER_BEGIN';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const TOGGLE_DELETE_USER_MODAL = 'TOGGLE_DELETE_USER_MODAL';

export const STATEMENT_NUMBER_DOWN_BEGIN = 'STATEMENT_NUMBER_DOWN_BEGIN';
export const STATEMENT_NUMBER_DOWN_SUCCESS = 'STATEMENT_NUMBER_DOWN_SUCCESS';
export const STATEMENT_NUMBER_DOWN_FAILURE = 'STATEMENT_NUMBER_DOWN_FAILURE';

export const STATEMENT_NUMBER_UP_BEGIN = 'STATEMENT_NUMBER_UP_BEGIN';
export const STATEMENT_NUMBER_UP_SUCCESS = 'STATEMENT_NUMBER_UP_SUCCESS';
export const STATEMENT_NUMBER_UP_FAILURE = 'STATEMENT_NUMBER_UP_FAILURE';

export const MODULE_NUMBER_DOWN_BEGIN = 'MODULE_NUMBER_DOWN_BEGIN';
export const MODULE_NUMBER_DOWN_SUCCESS = 'MODULE_NUMBER_DOWN_SUCCESS';
export const MODULE_NUMBER_DOWN_FAILURE = 'MODULE_NUMBER_DOWN_FAILURE';

export const MODULE_NUMBER_UP_BEGIN = 'MODULE_NUMBER_UP_BEGIN';
export const MODULE_NUMBER_UP_SUCCESS = 'MODULE_NUMBER_UP_SUCCESS';
export const MODULE_NUMBER_UP_FAILURE = 'MODULE_NUMBER_UP_FAILURE';

export const TEMPLATE_NUMBER_DOWN_BEGIN = 'TEMPLATE_NUMBER_DOWN_BEGIN';
export const TEMPLATE_NUMBER_DOWN_SUCCESS = 'TEMPLATE_NUMBER_DOWN_SUCCESS';
export const TEMPLATE_NUMBER_DOWN_FAILURE = 'TEMPLATE_NUMBER_DOWN_FAILURE';

export const TEMPLATE_NUMBER_UP_BEGIN = 'TEMPLATE_NUMBER_UP_BEGIN';
export const TEMPLATE_NUMBER_UP_SUCCESS = 'TEMPLATE_NUMBER_UP_SUCCESS';
export const TEMPLATE_NUMBER_UP_FAILURE = 'TEMPLATE_NUMBER_UP_FAILURE';

export const PROJECT_STATEMENT_NUMBER_DOWN_BEGIN = 'PROJECT_STATEMENT_NUMBER_DOWN_BEGIN';
export const PROJECT_STATEMENT_NUMBER_DOWN_SUCCESS = 'PROJECT_STATEMENT_NUMBER_DOWN_SUCCESS';
export const PROJECT_STATEMENT_NUMBER_DOWN_FAILURE = 'PROJECT_STATEMENT_NUMBER_DOWN_FAILURE';

export const PROJECT_STATEMENT_NUMBER_UP_BEGIN = 'PROJECT_STATEMENT_NUMBER_UP_BEGIN';
export const PROJECT_STATEMENT_NUMBER_UP_SUCCESS = 'PROJECT_STATEMENT_NUMBER_UP_SUCCESS';
export const PROJECT_STATEMENT_NUMBER_UP_FAILURE = 'PROJECT_STATEMENT_NUMBER_UP_FAILURE';

export const PROJECT_MODULE_NUMBER_DOWN_BEGIN = 'PROJECT_MODULE_NUMBER_DOWN_BEGIN';
export const PROJECT_MODULE_NUMBER_DOWN_SUCCESS = 'PROJECT_MODULE_NUMBER_DOWN_SUCCESS';
export const PROJECT_MODULE_NUMBER_DOWN_FAILURE = 'PROJECT_MODULE_NUMBER_DOWN_FAILURE';

export const PROJECT_MODULE_NUMBER_UP_BEGIN = 'PROJECT_MODULE_NUMBER_UP_BEGIN';
export const PROJECT_MODULE_NUMBER_UP_SUCCESS = 'PROJECT_MODULE_NUMBER_UP_SUCCESS';
export const PROJECT_MODULE_NUMBER_UP_FAILURE = 'PROJECT_MODULE_NUMBER_UP_FAILURE';

export const PROJECT_TEMPLATE_NUMBER_DOWN_BEGIN = 'PROJECT_TEMPLATE_NUMBER_DOWN_BEGIN';
export const PROJECT_TEMPLATE_NUMBER_DOWN_SUCCESS = 'PROJECT_TEMPLATE_NUMBER_DOWN_SUCCESS';
export const PROJECT_TEMPLATE_NUMBER_DOWN_FAILURE = 'PROJECT_TEMPLATE_NUMBER_DOWN_FAILURE';

export const PROJECT_TEMPLATE_NUMBER_UP_BEGIN = 'PROJECT_TEMPLATE_NUMBER_UP_BEGIN';
export const PROJECT_TEMPLATE_NUMBER_UP_SUCCESS = 'PROJECT_TEMPLATE_NUMBER_UP_SUCCESS';
export const PROJECT_TEMPLATE_NUMBER_UP_FAILURE = 'PROJECT_TEMPLATE_NUMBER_UP_FAILURE';

export const FETCH_OVERVIEW_TEMPLATE_BEGIN = 'FETCH_OVERVIEW_TEMPLATE_BEGIN';
export const FETCH_OVERVIEW_TEMPLATE_SUCCESS = 'FETCH_OVERVIEW_TEMPLATE_SUCCESS';
export const FETCH_OVERVIEW_TEMPLATE_FAILURE = 'FETCH_OVERVIEW_TEMPLATE_FAILURE';

export const FETCH_OVERVIEW_MODULES_BEGIN = 'FETCH_OVERVIEW_MODULES_BEGIN';
export const FETCH_OVERVIEW_MODULES_SUCCESS = 'FETCH_OVERVIEW_MODULES_SUCCESS';
export const FETCH_OVERVIEW_MODULES_FAILURE = 'FETCH_OVERVIEW_MODULES_FAILURE';

export const FETCH_OVERVIEW_STATEMENTS_BEGIN = 'FETCH_OVERVIEW_STATEMENTS_BEGIN';
export const FETCH_OVERVIEW_STATEMENTS_SUCCESS = 'FETCH_OVERVIEW_STATEMENTS_SUCCESS';
export const FETCH_OVERVIEW_STATEMENTS_FAILURE = 'FETCH_OVERVIEW_STATEMENTS_FAILURE';

export const UPDATE_USER_ROLE_BEGIN = 'UPDATE_USER_ROLE_BEGIN';
export const UPDATE_USER_ROLE_SUCCESS = 'UPDATE_USER_ROLE_SUCCESS';
export const UPDATE_USER_ROLE_FAILURE = 'UPDATE_USER_ROLE_FAILURE';

export const UPDATE_STATEMENT_RESPONSIBILITIES_BEGIN = 'UPDATE_STATEMENT_RESPONSIBILITIES_BEGIN';
export const UPDATE_STATEMENT_RESPONSIBILITIES_SUCCESS = 'UPDATE_STATEMENT_RESPONSIBILITIES_SUCCESS';
export const UPDATE_STATEMENT_RESPONSIBILITIES_FAILURE = 'UPDATE_STATEMENT_RESPONSIBILITIES_FAILURE';

export const UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_BEGIN = 'UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_BEGIN';
export const UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_SUCCESS = 'UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_SUCCESS';
export const UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_FAILURE = 'UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_FAILURE';

export const UPDATE_MODULE_PRICING_BEGIN = 'UPDATE_MODULE_PRICING_BEGIN';
export const UPDATE_MODULE_PRICING_SUCCESS = 'UPDATE_MODULE_PRICING_SUCCESS';
export const UPDATE_MODULE_PRICING_FAILURE = 'UPDATE_MODULE_PRICING_FAILURE';

export const UPDATE_MODULE_SLA_BEGIN = 'UPDATE_MODULE_SLA_BEGIN';
export const UPDATE_MODULE_SLA_SUCCESS = 'UPDATE_MODULE_SLA_SUCCESS';
export const UPDATE_MODULE_SLA_FAILURE = 'UPDATE_MODULE_SLA_FAILURE';

export const UPDATE_PROJECT_MODULE_PRICING_BEGIN = 'UPDATE_PROJECT_MODULE_PRICING_BEGIN';
export const UPDATE_PROJECT_MODULE_PRICING_SUCCESS = 'UPDATE_PROJECT_MODULE_PRICING_SUCCESS';
export const UPDATE_PROJECT_MODULE_PRICING_FAILURE = 'UPDATE_PROJECT_MODULE_PRICING_FAILURE';

export const UPDATE_PROJECT_MODULE_SLA_BEGIN = 'UPDATE_PROJECT_MODULE_SLA_BEGIN';
export const UPDATE_PROJECT_MODULE_SLA_SUCCESS = 'UPDATE_PROJECT_MODULE_SLA_SUCCESS';
export const UPDATE_PROJECT_MODULE_SLA_FAILURE = 'UPDATE_PROJECT_MODULE_SLA_FAILURE';

export const UPDATE_OFFERING_STATEMENT_COMPLIANCE_BEGIN = 'UPDATE_OFFERING_STATEMENT_COMPLIANCE_BEGIN';
export const UPDATE_OFFERING_STATEMENT_COMPLIANCE_SUCCESS = 'UPDATE_OFFERING_STATEMENT_COMPLIANCE_SUCCESS';
export const UPDATE_OFFERING_STATEMENT_COMPLIANCE_FAILURE = 'UPDATE_OFFERING_STATEMENT_COMPLIANCE_FAILURE';

export const TOGGLE_SUGGESTION_MODAL = 'TOGGLE_SUGGESTION_MODAL';

export const UPDATE_SUGGESTION_BEGIN = 'UPDATE_SUGGESTION_BEGIN';
export const UPDATE_SUGGESTION_SUCCESS = 'UPDATE_SUGGESTION_SUCCESS';
export const UPDATE_SUGGESTION_FAILURE = 'UPDATE_SUGGESTION_FAILURE';

export const TOGGLE_DELETE_OFFERING_MODAL = 'TOGGLE_DELETE_OFFERING_MODAL';

export const DELETE_OFFERING_BEGIN = 'DELETE_OFFERING_BEGIN';
export const DELETE_OFFERING_SUCCESS = 'DELETE_OFFERING_SUCCESS';
export const DELETE_OFFERING_FAILURE = 'DELETE_OFFERING_FAILURE';

export const PUBLISH_OFFERING_BEGIN = 'PUBLISH_OFFERING_BEGIN';
export const PUBLISH_OFFERING_SUCCESS = 'PUBLISH_OFFERING_SUCCESS';
export const PUBLISH_OFFERING_FAILURE = 'PUBLISH_OFFERING_FAILURE';

// toggle to display selection tree

export const DATABASE_EXPLORER_TOGGLE = 'DATABASE_EXPLORER_TOGGLE';

// SLA and ratecard for database management

export const FETCH_SLAS_BEGIN = 'FETCH_SLAS_BEGIN';
export const FETCH_SLAS_SUCCESS = 'FETCH_SLAS_SUCCESS';
export const FETCH_SLAS_FAILURE = 'FETCH_SLAS_FAILURE';

export const CLEAR_SLAS_ARRAY = 'CLEAR_SLAS_ARRAY';

export const UPDATE_SLA_BEGIN = 'UPDATE_SLA_BEGIN';
export const UPDATE_SLA_SUCCESS = 'UPDATE_SLA_SUCCESS';
export const UPDATE_SLA_FAILURE = 'UPDATE_SLA_FAILURE';

export const FETCH_DATABASE_RATECARD_BEGIN = 'FETCH_DATABASE_RATECARD_BEGIN';
export const FETCH_DATABASE_RATECARD_SUCCESS = 'FETCH_DATABASE_RATECARD_SUCCESS';
export const FETCH_DATABASE_RATECARD_FAILURE = 'FETCH_DATABASE_RATECARD_FAILURE';

export const TOGGLE_ADD_RATECARD_MODAL = 'TOGGLE_ADD_RATECARD_MODAL';
export const TOGGLE_UPDATE_RATECARD_MODAL = 'TOGGLE_UPDATE_RATECARD_MODAL';
export const TOGGLE_DELETE_RATECARD_MODAL = 'TOGGLE_DELETE_RATECARD_MODAL';

export const TOGGLE_ADD_SLA_MODAL = 'TOGGLE_ADD_SLA_MODAL';
export const TOGGLE_UPDATE_SLA_MODAL = 'TOGGLE_UPDATE_SLA_MODAL';

export const ADD_RATECARD_BEGIN = 'ADD_RATECARD_BEGIN';
export const ADD_RATECARD_SUCCESS = 'ADD_RATECARD_SUCCESS';
export const ADD_RATECARD_FAILURE = 'ADD_RATECARD_FAILURE';

export const UPDATE_RATECARD_BEGIN = 'UPDATE_RATECARD_BEGIN';
export const UPDATE_RATECARD_SUCCESS = 'UPDATE_RATECARD_SUCCESS';
export const UPDATE_RATECARD_FAILURE = 'UPDATE_RATECARD_FAILURE';

export const DELETE_RATECARD_BEGIN = 'DELETE_RATECARD_BEGIN';
export const DELETE_RATECARD_SUCCESS = 'DELETE_RATECARD_BEGIN';
export const DELETE_RATECARD_FAILURE = 'DELETE_RATECARD_FAILURE';

export const FETCH_DATABASE_FIXEDPRICES_BEGIN = 'FETCH_DATABASE_FIXEDPRICES_BEGIN';
export const FETCH_DATABASE_FIXEDPRICES_SUCCESS = 'FETCH_DATABASE_FIXEDPRICES_SUCCESS';
export const FETCH_DATABASE_FIXEDPRICES_FAILURE = 'FETCH_DATABASE_FIXEDPRICES_FAILURE';

export const FETCH_DATABASE_PIECEPRICES_BEGIN = 'FETCH_DATABASE_PIECEPRICES_BEGIN';
export const FETCH_DATABASE_PIECEPRICES_SUCCESS = 'FETCH_DATABASE_PIECEPRICES_SUCCESS';
export const FETCH_DATABASE_PIECEPRICES_FAILURE = 'FETCH_DATABASE_PIECEPRICES_FAILURE';

export const FETCH_DATABASE_TANDMS_BEGIN = 'FETCH_DATABASE_TANDMS_BEGIN';
export const FETCH_DATABASE_TANDMS_SUCCESS = 'FETCH_DATABASE_TANDMS_SUCCESS';
export const FETCH_DATABASE_TANDMS_FAILURE = 'FETCH_DATABASE_TANDMS_FAILURE';

// SLA and ratecard for project management

export const FETCH_PROJECT_SLAS_BEGIN = 'FETCH_PROJECT_SLAS_BEGIN';
export const FETCH_PROJECT_SLAS_SUCCESS = 'FETCH_PROJECT_SLAS_SUCCESS';
export const FETCH_PROJECT_SLAS_FAILURE = 'FETCH_PROJECT_SLAS_FAILURE';

export const CLEAR_PROJECT_SLAS_ARRAY = 'CLEAR_PROJECT_SLAS_ARRAY';

export const ADD_PROJECT_SLA_BEGIN = 'ADD_PROJECT_SLA_BEGIN';
export const ADD_PROJECT_SLA_SUCCESS = 'ADD_PROJECT_SLA_SUCCESS';
export const ADD_PROJECT_SLA_FAILURE = 'ADD_PROJECT_SLA_FAILURE';

export const UPDATE_PROJECT_SLA_BEGIN = 'UPDATE_PROJECT_SLA_BEGIN';
export const UPDATE_PROJECT_SLA_SUCCESS = 'UPDATE_PROJECT_SLA_SUCCESS';
export const UPDATE_PROJECT_SLA_FAILURE = 'UPDATE_PROJECT_SLA_FAILURE';

export const DELETE_PROJECT_SLA_BEGIN = 'DELETE_PROJECT_SLA_BEGIN';
export const DELETE_PROJECT_SLA_SUCCESS = 'DELETE_PROJECT_SLA_SUCCESS';
export const DELETE_PROJECT_SLA_FAILURE = 'DELETE_PROJECT_SLA_FAILURE';

export const FETCH_PROJECT_RATECARD_BEGIN = 'FETCH_PROJECT_RATECARD_BEGIN';
export const FETCH_PROJECT_RATECARD_SUCCESS = 'FETCH_PROJECT_RATECARD_SUCCESS';
export const FETCH_PROJECT_RATECARD_FAILURE = 'FETCH_PROJECT_RATECARD_FAILURE';

export const TOGGLE_PROJECT_ADD_RATECARD_MODAL = 'TOGGLE_PROJECT_ADD_RATECARD_MODAL';

export const ADD_PROJECT_RATECARD_BEGIN = 'ADD_PROJECT_RATECARD_BEGIN';
export const ADD_PROJECT_RATECARD_SUCCESS = 'ADD_PROJECT_RATECARD_SUCCESS';
export const ADD_PROJECT_RATECARD_FAILURE = 'ADD_PROJECT_RATECARD_FAILURE';

export const UPDATE_PROJECT_RATECARD_BEGIN = 'UPDATE_PROJECT_RATECARD_BEGIN';
export const UPDATE_PROJECT_RATECARD_SUCCESS = 'UPDATE_PROJECT_RATECARD_SUCCESS';
export const UPDATE_PROJECT_RATECARD_FAILURE = 'UPDATE_PROJECT_RATECARD_FAILURE';

export const DELETE_PROJECT_RATECARD_BEGIN = 'DELETE_PROJECT_RATECARD_BEGIN';
export const DELETE_PROJECT_RATECARD_SUCCESS = 'DELETE_PROJECT_RATECARD_SUCCESS';
export const DELETE_PROJECT_RATECARD_FAILURE = 'DELETE_PROJECT_RATECARD_FAILURE';

// assumptions and provisions

export const SELECT_ASSUMPTION = 'SELECT_ASSUMPTION';
export const SELECT_PROVISION = 'SELECT_PROVISION';

export const FETCH_ASSUMPTIONS_BEGIN = 'FETCH_ASSUMPTIONS_BEGIN';
export const FETCH_ASSUMPTIONS_SUCCESS = 'FETCH_ASSUMPTIONS_SUCCESS';
export const FETCH_ASSUMPTIONS_FAILURE = 'FETCH_ASSUMPTIONS_FAILURE';

export const FETCH_PROVISIONS_BEGIN = 'FETCH_PROVISIONS_BEGIN';
export const FETCH_PROVISIONS_SUCCESS = 'FETCH_PROVISIONS_SUCCESS';
export const FETCH_PROVISIONS_FAILURE = 'FETCH_PROVISIONS_FAILURE';

export const TOGGLE_ADD_ASSUMPTION_MODAL = 'TOGGLE_ADD_ASSUMPTION_MODAL';
export const TOGGLE_ADD_PROVISION_MODAL = 'TOGGLE_ADD_PROVISION_MODAL';
export const TOGGLE_DELETE_ASSUMPTION_MODAL = 'TOGGLE_DELETE_ASSUMPTION_MODAL';
export const TOGGLE_DELETE_PROVISION_MODAL = 'TOGGLE_DELETE_PROVISION_MODAL';

export const DELETE_ASSUMPTIONS_BEGIN = 'DELETE_ASSUMPTIONS_BEGIN';
export const DELETE_ASSUMPTIONS_SUCCESS = 'DELETE_ASSUMPTIONS_SUCCESS';
export const DELETE_ASSUMPTIONS_FAILURE = 'DELETE_ASSUMPTIONS_FAILURE';

export const DELETE_PROVISIONS_BEGIN = 'DELETE_PROVISIONS_BEGIN';
export const DELETE_PROVISIONS_SUCCESS = 'DELETE_PROVISIONS_SUCCESS';
export const DELETE_PROVISIONS_FAILURE = 'DELETE_PROVISIONS_FAILURE';

export const ADD_ASSUMPTION_BEGIN = 'ADD_ASSUMPTION_BEGIN';
export const ADD_ASSUMPTION_SUCCESS = 'ADD_ASSUMPTION_SUCCESS';
export const ADD_ASSUMPTION_FAILURE = 'ADD_ASSUMPTION_FAILURE';

export const ADD_PROVISION_BEGIN = 'ADD_PROVISION_BEGIN';
export const ADD_PROVISION_SUCCESS = 'ADD_PROVISION_SUCCESS';
export const ADD_PROVISION_FAILURE = 'ADD_PROVISION_FAILURE';

export const FETCH_OFFERING_SLAS_BEGIN = 'FETCH_OFFERING_SLAS_BEGIN';
export const FETCH_OFFERING_SLAS_SUCCESS = 'FETCH_OFFERING_SLAS_SUCCESS';
export const FETCH_OFFERING_SLAS_FAILURE = 'FETCH_OFFERING_SLAS_FAILURE';

export const FETCH_OFFERING_RATECARDS_BEGIN = 'FETCH_OFFERING_RATECARDS_BEGIN';
export const FETCH_OFFERING_RATECARDS_SUCCESS = 'FETCH_OFFERING_RATECARDS_SUCCESS';
export const FETCH_OFFERING_RATECARDS_FAILURE = 'FETCH_OFFERING_RATECARDS_FAILURE';

export const FETCH_OFFERING_FIXEDPRICES_BEGIN = 'FETCH_OFFERING_FIXEDPRICES_BEGIN';
export const FETCH_OFFERING_FIXEDPRICES_SUCCESS = 'FETCH_OFFERING_FIXEDPRICES_SUCCESS';
export const FETCH_OFFERING_FIXEDPRICES_FAILURE = 'FETCH_OFFERING_FIXEDPRICES_FAILURE';

export const FETCH_OFFERING_PIECEPRICES_BEGIN = 'FETCH_OFFERING_PIECEPRICES_BEGIN';
export const FETCH_OFFERING_PIECEPRICES_SUCCESS = 'FETCH_OFFERING_PIECEPRICES_SUCCESS';
export const FETCH_OFFERING_PIECEPRICES_FAILURE = 'FETCH_OFFERING_PIECEPRICES_FAILURE';

export const FETCH_OFFERING_TANDMS_BEGIN = 'FETCH_OFFERING_TANDMS_BEGIN';
export const FETCH_OFFERING_TANDMS_SUCCESS = 'FETCH_OFFERING_TANDMS_SUCCESS';
export const FETCH_OFFERING_TANDMS_FAILURE = 'FETCH_OFFERING_TANDMS_FAILURE';

export const CLEAR_OFFERING_SLAS_ARRAY = 'CLEAR_OFFERING_SLAS_ARRAY';

export const UPDATE_OFFERING_SLA_BEGIN = 'UPDATE_OFFERING_SLA_BEGIN';
export const UPDATE_OFFERING_SLA_SUCCESS = 'UPDATE_OFFERING_SLA_SUCCESS';
export const UPDATE_OFFERING_SLA_FAILURE = 'UPDATE_OFFERING_SLA_FAILURE';

export const TOGGLE_UPDATE_OFFERING_SLA_MODAL = 'TOGGLE_UPDATE_OFFERING_SLA_MODAL';

export const UPDATE_OFFERING_SLA_COMPLIANCE_BEGIN = 'UPDATE_OFFERING_SLA_COMPLIANCE_BEGIN';
export const UPDATE_OFFERING_SLA_COMPLIANCE_SUCCESS = 'UPDATE_OFFERING_SLA_COMPLIANCE_SUCCESS';
export const UPDATE_OFFERING_SLA_COMPLIANCE_FAILURE = 'UPDATE_OFFERING_SLA_COMPLIANCE_FAILURE';

export const UPDATE_OFFERING_RATECARD_BEGIN = 'UPDATE_OFFERING_RATECARD_BEGIN';
export const UPDATE_OFFERING_RATECARD_SUCCESS = 'UPDATE_OFFERING_RATECARD_SUCCESS';
export const UPDATE_OFFERING_RATECARD_FAILURE = 'UPDATE_OFFERING_RATECARD_FAILURE';

export const TOGGLE_UPDATE_OFFERING_RATECARD_MODAL = 'TOGGLE_UPDATE_OFFERING_RATECARD_MODAL';

// Prices for database management

export const SELECT_RATECARD = 'SELECT_RATECARD';
export const SELECT_TANDM = 'SELECT_TANDM';
export const SELECT_FIXEDPRICE = 'SELECT_FIXEDPRICE';
export const SELECT_PIECEPRICE = 'SELECT_PIECEPRICE';
export const SELECT_SLA = 'SELECT_SLA';

// prices for project management

export const SELECT_PROJECT_RATECARD = 'SELECT_PROJECT_RATECARD';
export const SELECT_PROJECT_TANDM = 'SELECT_PROJECT_TANDM';
export const SELECT_PROJECT_FIXEDPRICE = 'SELECT_PROJECT_FIXEDPRICE';
export const SELECT_PROJECT_PIECEPRICE = 'SELECT_PROJECT_PIECEPRICE';
export const SELECT_PROJECT_SLA = 'SELECT_PROJECT_SLA';

export const SELECT_OFFERING_RATECARD = 'SELECT_OFFERING_RATECARD';
export const SELECT_OFFERING_TANDM = 'SELECT_OFFERING_TANDM';
export const SELECT_OFFERING_FIXEDPRICE = 'SELECT_OFFERING_FIXEDPRICE';
export const SELECT_OFFERING_PIECEPRICE = 'SELECT_OFFERING_PIECEPRICE';
export const SELECT_OFFERING_SLA = 'SELECT_OFFERING_SLA';
export const SELECT_OFFERING_REFERENCE = 'SELECT_OFFERING_REFERENCE';
export const SELECT_OFFERING_SUBCONTRACTOR = 'SELECT_OFFERING_SUBCONTRACTOR';

// File upload for database management

export const UPLOAD_DATABASE_PROJECT_FILE_BEGIN = 'UPLOAD_DATABASE_PROJECT_FILE_BEGIN';
export const UPLOAD_DATABASE_PROJECT_FILE_SUCCESS = 'UPLOAD_DATABASE_PROJECT_FILE_SUCCESS';
export const UPLOAD_DATABASE_PROJECT_FILE_FAILURE = 'UPLOAD_DATABASE_MODULE_FILE_FAILURE';

export const UPLOAD_DATABASE_TEMPLATE_FILE_BEGIN = 'UPLOAD_DATABASE_TEMPLATE_FILE_BEGIN';
export const UPLOAD_DATABASE_TEMPLATE_FILE_SUCCESS = 'UPLOAD_DATABASE_TEMPLATE_FILE_SUCCESS';
export const UPLOAD_DATABASE_TEMPLATE_FILE_FAILURE = 'UPLOAD_DATABASE_TEMPLATE_FILE_FAILURE';

export const UPLOAD_DATABASE_MODULE_FILE_BEGIN = 'UPLOAD_DATABASE_MODULE_FILE_BEGIN';
export const UPLOAD_DATABASE_MODULE_FILE_SUCCESS = 'UPLOAD_DATABASE_MODULE_FILE_SUCCESS';
export const UPLOAD_DATABASE_MODULE_FILE_FAILURE = 'UPLOAD_DATABASE_MODULE_FILE_FAILURE';

export const UPLOAD_DATABASE_STATEMENT_FILE_BEGIN = 'UPLOAD_DATABASE_STATEMENT_FILE_BEGIN';
export const UPLOAD_DATABASE_STATEMENT_FILE_SUCCESS = 'UPLOAD_DATABASE_STATEMENT_FILE_SUCCESS';
export const UPLOAD_DATABASE_STATEMENT_FILE_FAILURE = 'UPLOAD_DATABASE_STATEMENT_FILE_FAILURE';

export const REMOVE_DATABASE_PROJECT_UPLOADS_BEGIN = 'REMOVE_DATABASE_PROJECT_UPLOADS_BEGIN';
export const REMOVE_DATABASE_PROJECT_UPLOADS_SUCCESS = 'REMOVE_DATABASE_PROJECT_UPLOADS_SUCCESS';
export const REMOVE_DATABASE_PROJECT_UPLOADS_FAILURE = 'REMOVE_DATABASE_PROJECT_UPLOADS_FAILURE';

export const REMOVE_DATABASE_TEMPLATE_UPLOADS_BEGIN = 'REMOVE_DATABASE_TEMPLATE_UPLOADS_BEGIN';
export const REMOVE_DATABASE_TEMPLATE_UPLOADS_SUCCESS = 'REMOVE_DATABASE_TEMPLATE_UPLOADS_SUCCESS';
export const REMOVE_DATABASE_TEMPLATE_UPLOADS_FAILURE = 'REMOVE_DATABASE_TEMPLATE_UPLOADS_FAILURE';

export const REMOVE_DATABASE_MODULE_UPLOADS_BEGIN = 'REMOVE_DATABASE_MODULE_UPLOADS_BEGIN';
export const REMOVE_DATABASE_MODULE_UPLOADS_SUCCESS = 'REMOVE_DATABASE_MODULE_UPLOADS_SUCCESS';
export const REMOVE_DATABASE_MODULE_UPLOADS_FAILURE = 'REMOVE_DATABASE_MODULE_UPLOADS_FAILURE';

export const REMOVE_DATABASE_STATEMENT_UPLOADS_BEGIN = 'REMOVE_DATABASE_STATEMENT_UPLOADS_BEGIN';
export const REMOVE_DATABASE_STATEMENT_UPLOADS_SUCCESS = 'REMOVE_DATABASE_STATEMENT_UPLOADS_SUCCESS';
export const REMOVE_DATABASE_STATEMENT_UPLOADS_FAILURE = 'REMOVE_DATABASE_STATEMENT_UPLOADS_FAILURE';

// File upload for project management

export const UPLOAD_PROJECT_PROJECT_FILE_BEGIN = 'UPLOAD_PROJECT_PROJECT_FILE_BEGIN';
export const UPLOAD_PROJECT_PROJECT_FILE_SUCCESS = 'UPLOAD_PROJECT_PROJECT_FILE_SUCCESS';
export const UPLOAD_PROJECT_PROJECT_FILE_FAILURE = 'UPLOAD_PROJECT_PROJECT_FILE_FAILURE';

export const UPLOAD_PROJECT_TEMPLATE_FILE_BEGIN = 'UPLOAD_PROJECT_TEMPLATE_FILE_BEGIN';
export const UPLOAD_PROJECT_TEMPLATE_FILE_SUCCESS = 'UPLOAD_PROJECT_TEMPLATE_FILE_SUCCESS';
export const UPLOAD_PROJECT_TEMPLATE_FILE_FAILURE = 'UPLOAD_PROJECT_TEMPLATE_FILE_FAILURE';

export const UPLOAD_PROJECT_MODULE_FILE_BEGIN = 'UPLOAD_PROJECT_MODULE_FILE_BEGIN';
export const UPLOAD_PROJECT_MODULE_FILE_SUCCESS = 'UPLOAD_PROJECT_MODULE_FILE_SUCCESS';
export const UPLOAD_PROJECT_MODULE_FILE_FAILURE = 'UPLOAD_PROJECT_MODULE_FILE_FAILURE';

export const UPLOAD_PROJECT_STATEMENT_FILE_BEGIN = 'UPLOAD_PROJECT_STATEMENT_FILE_BEGIN';
export const UPLOAD_PROJECT_STATEMENT_FILE_SUCCESS = 'UPLOAD_PROJECT_STATEMENT_FILE_SUCCESS';
export const UPLOAD_PROJECT_STATEMENT_FILE_FAILURE = 'UPLOAD_PROJECT_STATEMENT_FILE_FAILURE';

export const UPLOAD_PROJECT_QANDA_FILE_BEGIN = 'UPLOAD_PROJECT_QANDA_FILE_BEGIN';
export const UPLOAD_PROJECT_QANDA_FILE_SUCCESS = 'UPLOAD_PROJECT_QANDA_FILE_SUCCESS';
export const UPLOAD_PROJECT_QANDA_FILE_FAILURE = 'UPLOAD_PROJECT_QANDA_FILE_FAILURE';

export const REMOVE_PROJECT_PROJECT_UPLOADS_BEGIN = 'REMOVE_PROJECT_PROJECT_UPLOADS_BEGIN';
export const REMOVE_PROJECT_PROJECT_UPLOADS_SUCCESS = 'REMOVE_PROJECT_PROJECT_UPLOADS_SUCCESS';
export const REMOVE_PROJECT_PROJECT_UPLOADS_FAILURE = 'REMOVE_PROJECT_PROJECT_UPLOADS_FAILURE';

export const REMOVE_PROJECT_TEMPLATE_UPLOADS_BEGIN = 'REMOVE_PROJECT_TEMPLATE_UPLOADS_BEGIN';
export const REMOVE_PROJECT_TEMPLATE_UPLOADS_SUCCESS = 'REMOVE_PROJECT_TEMPLATE_UPLOADS_SUCCESS';
export const REMOVE_PROJECT_TEMPLATE_UPLOADS_FAILURE = 'REMOVE_PROJECT_TEMPLATE_UPLOADS_FAILURE';

export const REMOVE_PROJECT_MODULE_UPLOADS_BEGIN = 'REMOVE_PROJECT_MODULE_UPLOADS_BEGIN';
export const REMOVE_PROJECT_MODULE_UPLOADS_SUCCESS = 'REMOVE_PROJECT_MODULE_UPLOADS_SUCCESS';
export const REMOVE_PROJECT_MODULE_UPLOADS_FAILURE = 'REMOVE_PROJECT_MODULE_UPLOADS_FAILURE';

export const REMOVE_PROJECT_STATEMENT_UPLOADS_BEGIN = 'REMOVE_PROJECT_STATEMENT_UPLOADS_BEGIN';
export const REMOVE_PROJECT_STATEMENT_UPLOADS_SUCCESS = 'REMOVE_PROJECT_STATEMENT_UPLOADS_SUCCESS';
export const REMOVE_PROJECT_STATEMENT_UPLOADS_FAILURE = 'REMOVE_PROJECT_STATEMENT_UPLOADS_FAILURE';

export const REMOVE_PROJECT_QANDA_UPLOADS_BEGIN = 'REMOVE_PROJECT_QANDA_UPLOADS_BEGIN';
export const REMOVE_PROJECT_QANDA_UPLOADS_SUCCESS = 'REMOVE_PROJECT_QANDA_UPLOADS_SUCCESS';
export const REMOVE_PROJECT_QANDA_UPLOADS_FAILURE = 'REMOVE_PROJECT_QANDA_UPLOADS_FAILURE';

// Upload Offering

export const UPLOAD_OFFERING_PROJECT_FILE_BEGIN = 'UPLOAD_OFFERING_PROJECT_FILE_BEGIN';
export const UPLOAD_OFFERING_PROJECT_FILE_SUCCESS = 'UPLOAD_OFFERING_PROJECT_FILE_SUCCESS';
export const UPLOAD_OFFERING_PROJECT_FILE_FAILURE = 'UPLOAD_OFFERING_PROJECT_FILE_FAILURE';

export const REMOVE_OFFERING_PROJECT_UPLOADS_BEGIN = 'REMOVE_OFFERING_PROJECT_UPLOADS_BEGIN';
export const REMOVE_OFFERING_PROJECT_UPLOADS_SUCCESS = 'REMOVE_OFFERING_PROJECT_UPLOADS_SUCCESS';
export const REMOVE_OFFERING_PROJECT_UPLOADS_FAILURE = 'REMOVE_OFFERING_PROJECT_UPLOADS_FAILURE';

// Upload Negotiation

export const UPLOAD_NEGOTIATION_PROJECT_FILE_BEGIN = 'UPLOAD_NEGOTIATION_PROJECT_FILE_BEGIN';
export const UPLOAD_NEGOTIATION_PROJECT_FILE_SUCCESS = 'UPLOAD_NEGOTIATION_PROJECT_FILE_SUCCESS';
export const UPLOAD_NEGOTIATION_PROJECT_FILE_FAILURE = 'UPLOAD_NEGOTIATION_PROJECT_FILE_FAILURE';

export const UPLOAD_NEGOTIATION_TEMPLATE_FILE_BEGIN = 'UPLOAD_NEGOTIATION_TEMPLATE_FILE_BEGIN';
export const UPLOAD_NEGOTIATION_TEMPLATE_FILE_SUCCESS = 'UPLOAD_NEGOTIATION_TEMPLATE_FILE_SUCCESS';
export const UPLOAD_NEGOTIATION_TEMPLATE_FILE_FAILURE = 'UPLOAD_NEGOTIATION_TEMPLATE_FILE_FAILURE';

export const UPLOAD_NEGOTIATION_MODULE_FILE_BEGIN = 'UPLOAD_NEGOTIATION_MODULE_FILE_BEGIN';
export const UPLOAD_NEGOTIATION_MODULE_FILE_SUCCESS = 'UPLOAD_NEGOTIATION_MODULE_FILE_SUCCESS';
export const UPLOAD_NEGOTIATION_MODULE_FILE_FAILURE = 'UPLOAD_NEGOTIATION_MODULE_FILE_FAILURE';

export const UPLOAD_NEGOTIATION_STATEMENT_FILE_BEGIN = 'UPLOAD_NEGOTIATION_STATEMENT_FILE_BEGIN';
export const UPLOAD_NEGOTIATION_STATEMENT_FILE_SUCCESS = 'UPLOAD_NEGOTIATION_STATEMENT_FILE_SUCCESS';
export const UPLOAD_NEGOTIATION_STATEMENT_FILE_FAILURE = 'UPLOAD_NEGOTIATION_STATEMENT_FILE_FAILURE';

export const REMOVE_NEGOTIATION_PROJECT_UPLOADS_BEGIN = 'REMOVE_NEGOTIATION_PROJECT_UPLOADS_BEGIN';
export const REMOVE_NEGOTIATION_PROJECT_UPLOADS_SUCCESS = 'REMOVE_NEGOTIATION_PROJECT_UPLOADS_SUCCESS';
export const REMOVE_NEGOTIATION_PROJECT_UPLOADS_FAILURE = 'REMOVE_NEGOTIATION_PROJECT_UPLOADS_FAILURE';

export const REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_BEGIN = 'REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_BEGIN';
export const REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_SUCCESS = 'REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_SUCCESS';
export const REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_FAILURE = 'REMOVE_NEGOTIATION_TEMPLATE_UPLOADS_FAILURE';

export const REMOVE_NEGOTIATION_MODULE_UPLOADS_BEGIN = 'REMOVE_NEGOTIATION_MODULE_UPLOADS_BEGIN';
export const REMOVE_NEGOTIATION_MODULE_UPLOADS_SUCCESS = 'REMOVE_NEGOTIATION_MODULE_UPLOADS_SUCCESS';
export const REMOVE_NEGOTIATION_MODULE_UPLOADS_FAILURE = 'REMOVE_NEGOTIATION_MODULE_UPLOADS_FAILURE';

export const REMOVE_NEGOTIATION_STATEMENT_UPLOADS_BEGIN = 'REMOVE_NEGOTIATION_STATEMENT_UPLOADS_BEGIN';
export const REMOVE_NEGOTIATION_STATEMENT_UPLOADS_SUCCESS = 'REMOVE_NEGOTIATION_STATEMENT_UPLOADS_SUCCESS';
export const REMOVE_NEGOTIATION_STATEMENT_UPLOADS_FAILURE = 'REMOVE_NEGOTIATION_STATEMENT_UPLOADS_FAILURE';

// Negotiation

export const FETCH_NEGOTIATION_PROJECTS_BEGIN = 'FETCH_NEGOTIATION_PROJECTS_BEGIN';

export const FETCH_NEGOTIATION_PROJECTS_SUCCESS = 'FETCH_NEGOTIATION_PROJECTS_SUCCESS';

export const FETCH_NEGOTIATION_PROJECTS_FAILURE = 'FETCH_NEGOTIATION_PROJECTS_FAILURE';

export const ADD_SLA_BEGIN = 'ADD_SLA_BEGIN';
export const ADD_SLA_SUCCESS = 'ADD_SLA_SUCCESS';
export const ADD_SLA_FAILURE = 'ADD_SLA_FAILURE';

export const DELETE_SLA_BEGIN = 'DELETE_SLA_BEGIN';
export const DELETE_SLA_SUCCESS = 'DELETE_SLA_SUCCESS';
export const DELETE_SLA_FAILURE = 'DELETE_SLA_FAILURE';

export const TOGGLE_ADD_PROJECT_SLA_MODAL = 'TOGGLE_ADD_PROJECT_SLA_MODAL';
export const TOGGLE_UPDATE_PROJECT_SLA_MODAL = 'TOGGLE_UPDATE_PROJECT_SLA_MODAL';

export const FETCH_PROJECT_FIXEDPRICES_BEGIN = 'FETCH_PROJECT_FIXEDPRICES_BEGIN';
export const FETCH_PROJECT_FIXEDPRICES_SUCCESS = 'FETCH_PROJECT_FIXEDPRICES_SUCCESS';
export const FETCH_PROJECT_FIXEDPRICES_FAILURE = 'FETCH_PROJECT_FIXEDPRICES_FAILURE';

export const FETCH_PROJECT_PIECEPRICES_BEGIN = 'FETCH_PROJECT_PIECEPRICES_BEGIN';
export const FETCH_PROJECT_PIECEPRICES_SUCCESS = 'FETCH_PROJECT_PIECEPRICES_SUCCESS';
export const FETCH_PROJECT_PIECEPRICES_FAILURE = 'FETCH_PROJECT_PIECEPRICES_FAILURE';

export const FETCH_PROJECT_TANDMS_BEGIN = 'FETCH_PROJECT_TANDMS_BEGIN';
export const FETCH_PROJECT_TANDMS_SUCCESS = 'FETCH_PROJECT_TANDMS_SUCCESS';
export const FETCH_PROJECT_TANDMS_FAILURE = 'FETCH_PROJECT_TANDMS_FAILURE';

export const TOGGLE_ADD_PROJECT_RATECARD_MODAL = 'TOGGLE_ADD_PROJECT_RATECARD_MODAL';
export const TOGGLE_UPDATE_PROJECT_RATECARD_MODAL = 'TOGGLE_UPDATE_PROJECT_RATECARD_MODAL';
export const TOGGLE_DELETE_PROJECT_RATECARD_MODAL = 'TOGGLE_DELETE_PROJECT_RATECARD_MODAL';

export const TOGGLE_UPDATE_PIECEPRICE_MODAL = 'TOGGLE_UPDATE_PIECEPRICE_MODAL';

export const UPDATE_PIECEPRICE_BEGIN = 'UPDATE_PIECEPRICE_BEGIN';
export const UPDATE_PIECEPRICE_SUCCESS = 'UPDATE_PIECEPRICE_SUCCESS';
export const UPDATE_PIECEPRICE_FAILURE = 'UPDATE_PIECEPRICE_FAILURE';

export const ADD_PIECEPRICE_BEGIN = 'ADD_PIECEPRICE_BEGIN';
export const ADD_PIECEPRICE_SUCCESS = 'ADD_PIECEPRICE_SUCCESS';
export const ADD_PIECEPRICE_FAILURE = 'ADD_PIECEPRICE_FAILURE';

export const DELETE_PIECEPRICE_BEGIN = 'DELETE_PIECEPRICE_BEGIN';
export const DELETE_PIECEPRICE_SUCCESS = 'DELETE_PIECEPRICE_SUCCESS';
export const DELETE_PIECEPRICE_FAILURE = 'DELETE_PIECEPRICE_FAILURE';

export const TOGGLE_UPDATE_PROJECT_PIECEPRICE_MODAL = 'TOGGLE_UPDATE_PROJECT_PIECEPRICE_MODAL';

export const ADD_PROJECT_PIECEPRICE_BEGIN = 'ADD_PROJECT_PIECEPRICE_BEGIN';
export const ADD_PROJECT_PIECEPRICE_SUCCESS = 'ADD_PROJECT_PIECEPRICE_SUCCESS';
export const ADD_PROJECT_PIECEPRICE_FAILURE = 'ADD_PROJECT_PIECEPRICE_FAILURE';
export const DELETE_PROJECT_PIECEPRICE_BEGIN = 'DELETE_PROJECT_PIECEPRICE_BEGIN';
export const DELETE_PROJECT_PIECEPRICE_SUCCESS = 'DELETE_PROJECT_PIECEPRICE_SUCCESS';
export const DELETE_PROJECT_PIECEPRICE_FAILURE = 'DELETE_PROJECT_PIECEPRICE_FAILURE';

export const UPDATE_PROJECT_PIECEPRICE_BEGIN = 'UPDATE_PROJECT_PIECEPRICE_BEGIN';
export const UPDATE_PROJECT_PIECEPRICE_SUCCESS = 'UPDATE_PROJECT_PIECEPRICE_SUCCESS';
export const UPDATE_PROJECT_PIECEPRICE_FAILURE = 'UPDATE_PROJECT_PIECEPRICE_FAILURE';

export const TOGGLE_UPDATE_OFFERING_PIECEPRICE_MODAL = 'TOGGLE_UPDATE_OFFERING_PIECEPRICE_MODAL';

export const UPDATE_OFFERING_PIECEPRICE_BEGIN = 'UPDATE_OFFERING_PIECEPRICE_BEGIN';
export const UPDATE_OFFERING_PIECEPRICE_SUCCESS = 'UPDATE_OFFERING_PIECEPRICE_SUCCESS';
export const UPDATE_OFFERING_PIECEPRICE_FAILURE = 'UPDATE_OFFERING_PIECEPRICE_FAILURE';

export const TOGGLE_PUBLISH_PROJECT_MODAL = 'TOGGLE_PUBLISH_PROJECT_MODAL';

export const TOGGLE_UPDATE_TANDM_MODAL = 'TOGGLE_UPDATE_TANDM_MODAL';

export const UPDATE_TANDM_BEGIN = 'UPDATE_TANDM_BEGIN';
export const UPDATE_TANDM_SUCCESS = 'UPDATE_TANDM_SUCCESS';
export const UPDATE_TANDM_FAILURE = 'UPDATE_TANDM_FAILURE';

export const TOGGLE_UPDATE_FIXEDPRICE_MODAL = 'TOGGLE_UPDATE_FIXEDPRICE_MODAL';

export const UPDATE_FIXEDPRICE_BEGIN = 'UPDATE_FIXEDPRICE_BEGIN';
export const UPDATE_FIXEDPRICE_SUCCESS = 'UPDATE_FIXEDPRICE_SUCCESS';
export const UPDATE_FIXEDPRICE_FAILURE = 'UPDATE_FIXEDPRICE_FAILURE';

export const TOGGLE_ADD_TANDM_MODAL = 'TOGGLE_ADD_TANDM_MODAL';

export const ADD_TANDM_BEGIN = 'ADD_TANDM_BEGIN';
export const ADD_TANDM_SUCCESS = 'ADD_TANDM_SUCCESS';
export const ADD_TANDM_FAILURE = 'ADD_TANDM_FAILURE';

export const DELETE_TANDM_BEGIN = 'DELETE_TANDM_BEGIN';
export const DELETE_TANDM_SUCCESS = 'DELETE_TANDM_SUCCESS';
export const DELETE_TANDM_FAILURE = 'DELETE_TANDM_FAILURE';

export const NEGOTIATION_FETCH_PROJECTS_BEGIN = 'NEGOTIATION_FETCH_PROJECTS_BEGIN';
export const NEGOTIATION_FETCH_PROJECTS_SUCCESS = 'NEGOTIATION_FETCH_PROJECTS_SUCCESS';
export const NEGOTIATION_FETCH_PROJECTS_FAILURE = 'NEGOTIATION_FETCH_PROJECTS_FAILURE';
export const NEGOTIATION_FETCH_TEMPLATES_BEGIN = 'NEGOTIATION_FETCH_TEMPLATES_BEGIN';
export const NEGOTIATION_FETCH_TEMPLATES_SUCCESS = 'NEGOTIATION_FETCH_TEMPLATES_SUCCESS';
export const NEGOTIATION_FETCH_TEMPLATES_FAILURE = 'NEGOTIATION_FETCH_TEMPLATES_FAILURE';
export const NEGOTIATION_FETCH_MODULES_BEGIN = 'NEGOTIATION_FETCH_MODULES_BEGIN';
export const NEGOTIATION_FETCH_MODULES_SUCCESS = 'NEGOTIATION_FETCH_MODULES_SUCCESS';
export const NEGOTIATION_FETCH_MODULES_FAILURE = 'NEGOTIATION_FETCH_MODULES_FAILURE';
export const NEGOTIATION_FETCH_STATEMENTS_BEGIN = 'NEGOTIATION_FETCH_STATEMENTS_BEGIN';
export const NEGOTIATION_FETCH_STATEMENTS_SUCCESS = 'NEGOTIATION_FETCH_STATEMENTS_SUCCESS';
export const NEGOTIATION_FETCH_STATEMENTS_FAILURE = 'NEGOTIATION_FETCH_STATEMENTS_FAILURE';
export const NEGOTIATION_CLEAR_PROJECTS_ARRAY = 'NEGOTIATION_CLEAR_PROJECTS_ARRAY';
export const NEGOTIATION_CLEAR_TEMPLATES_ARRAY = 'NEGOTIATION_CLEAR_TEMPLATES_ARRAY';
export const NEGOTIATION_CLEAR_MODULES_ARRAY = 'NEGOTIATION_CLEAR_MODULES_ARRAY';
export const NEGOTIATION_CLEAR_STATEMENTS_ARRAY = 'NEGOTIATION_CLEAR_STATEMENTS_ARRAY';
export const NEGOTIATION_SELECT_PROJECT = 'NEGOTIATION_SELECT_PROJECT';
export const NEGOTIATION_SELECT_TEMPLATE = 'NEGOTIATION_SELECT_TEMPLATE';
export const NEGOTIATION_SELECT_MODULE = 'NEGOTIATION_SELECT_MODULE';
export const NEGOTIATION_SELECT_STATEMENT = 'NEGOTIATION_SELECT_STATEMENT';
export const PUBLISH_NEGOTIATION_BEGIN = 'PUBLISH_NEGOTIATION_BEGIN';
export const PUBLISH_NEGOTIATION_SUCCESS = 'PUBLISH_NEGOTIATION_SUCCESS';
export const PUBLISH_NEGOTIATION_FAILURE = 'PUBLISH_NEGOTIATION_FAILURE';
export const SELECT_NEGOTIATION_ASSUMPTION = 'SELECT_NEGOTIATION_ASSUMPTION';
export const SELECT_NEGOTIATION_PROVISION = 'SELECT_NEGOTIATION_PROVISION';
export const FETCH_NEGOTIATION_ASSUMPTIONS_BEGIN = 'FETCH_NEGOTIATION_ASSUMPTIONS_BEGIN';
export const FETCH_NEGOTIATION_ASSUMPTIONS_SUCCESS = 'FETCH_NEGOTIATION_ASSUMPTIONS_SUCCESS';
export const FETCH_NEGOTIATION_ASSUMPTIONS_FAILURE = 'FETCH_NEGOTIATION_ASSUMPTIONS_FAILURE';
export const FETCH_NEGOTIATION_PROVISIONS_BEGIN = 'FETCH_NEGOTIATION_PROVISIONS_BEGIN';
export const FETCH_NEGOTIATION_PROVISIONS_SUCCESS = 'FETCH_NEGOTIATION_PROVISIONS_SUCCESS';
export const FETCH_NEGOTIATION_PROVISIONS_FAILURE = 'FETCH_NEGOTIATION_PROVISIONS_FAILURE';
export const FETCH_NEGOTIATION_SLAS_BEGIN = 'FETCH_NEGOTIATION_SLAS_BEGIN';
export const FETCH_NEGOTIATION_SLAS_SUCCESS = 'FETCH_NEGOTIATION_SLAS_SUCCESS';
export const FETCH_NEGOTIATION_SLAS_FAILURE = 'FETCH_NEGOTIATION_SLAS_FAILURE';
export const CLEAR_NEGOTIATION_SLAS_ARRAY = 'CLEAR_NEGOTIATION_SLAS_ARRAY';
export const SELECT_NEGOTIATION_SLA = 'SELECT_NEGOTIATION_SLA';
export const FETCH_NEGOTIATION_RATECARDS_BEGIN = 'FETCH_NEGOTIATION_RATECARDS_BEGIN';
export const FETCH_NEGOTIATION_RATECARDS_SUCCESS = 'FETCH_NEGOTIATION_RATECARDS_SUCCESS';
export const FETCH_NEGOTIATION_RATECARDS_FAILURE = 'FETCH_NEGOTIATION_RATECARDS_FAILURE';
export const FETCH_NEGOTIATION_FIXEDPRICES_BEGIN = 'FETCH_NEGOTIATION_FIXEDPRICES_BEGIN';
export const FETCH_NEGOTIATION_FIXEDPRICES_SUCCESS = 'FETCH_NEGOTIATION_FIXEDPRICES_SUCCESS';
export const FETCH_NEGOTIATION_FIXEDPRICES_FAILURE = 'FETCH_NEGOTIATION_FIXEDPRICES_FAILURE';
export const FETCH_NEGOTIATION_PIECEPRICES_BEGIN = 'FETCH_NEGOTIATION_PIECEPRICES_BEGIN';
export const FETCH_NEGOTIATION_PIECEPRICES_SUCCESS = 'FETCH_NEGOTIATION_PIECEPRICES_SUCCESS';
export const FETCH_NEGOTIATION_PIECEPRICES_FAILURE = 'FETCH_NEGOTIATION_PIECEPRICES_FAILURE';
export const FETCH_NEGOTIATION_TANDMS_BEGIN = 'FETCH_NEGOTIATION_TANDMS_BEGIN';
export const FETCH_NEGOTIATION_TANDMS_SUCCESS = 'FETCH_NEGOTIATION_TANDMS_SUCCESS';
export const FETCH_NEGOTIATION_TANDMS_FAILURE = 'FETCH_NEGOTIATION_TANDMS_FAILURE';
export const SELECT_NEGOTIATION_RATECARD = 'SELECT_NEGOTIATION_RATECARD';
export const SELECT_NEGOTIATION_FIXEDPRICE = 'SELECT_NEGOTIATION_FIXEDPRICE';
export const SELECT_NEGOTIATION_PIECEPRICE = 'SELECT_NEGOTIATION_PIECEPRICE';
export const SELECT_NEGOTIATION_TANDM = 'SELECT_NEGOTIATION_TANDM';
export const SELECT_NEGOTIATION_REFERENCE = 'SELECT_NEGOTIATION_REFERENCE';
export const SELECT_NEGOTIATION_SUBCONTRACTOR = 'SELECT_NEGOTIATION_SUBCONTRACTOR';
export const DELETE_NEGOTIATION_PROJECT_BEGIN = 'DELETE_NEGOTIATION_PROJECT_BEGIN';
export const DELETE_NEGOTIATION_PROJECT_SUCCESS = 'DELETE_NEGOTIATION_PROJECT_SUCCESS';
export const DELETE_NEGOTIATION_PROJECT_FAILURE = 'DELETE_NEGOTIATION_PROJECT_FAILURE';

export const TOGGLE_UPDATE_NEGOTIATION_STATEMENT_MODAL = 'TOGGLE_UPDATE_NEGOTIATION_STATEMENT_MODAL';

export const UPDATE_NEGOTIATION_STATEMENT_BEGIN = 'UPDATE_NEGOTIATION_STATEMENT_BEGIN';
export const UPDATE_NEGOTIATION_STATEMENT_SUCCESS = 'UPDATE_NEGOTIATION_STATEMENT_SUCCESS';
export const UPDATE_NEGOTIATION_STATEMENT_FAILURE = 'UPDATE_NEGOTIATION_STATEMENT_FAILURE';

export const MAKE_CONTRACT_BEGIN = 'MAKE_CONTRACT_BEGIN';
export const MAKE_CONTRACT_SUCCESS = 'MAKE_CONTRACT_SUCCESS';
export const MAKE_CONTRACT_FAILURE = 'MAKE_CONTRACT_FAILURE';

export const FETCH_CONTRACT_TEMPLATES_BEGIN = 'FETCH_CONTRACT_TEMPLATES_BEGIN';
export const FETCH_CONTRACT_TEMPLATES_SUCCESS = 'FETCH_CONTRACT_TEMPLATES_SUCCESS';
export const FETCH_CONTRACT_TEMPLATES_FAILURE = 'FETCH_CONTRACT_TEMPLATES_FAILURE';

export const FETCH_CONTRACT_MODULES_BEGIN = 'FETCH_CONTRACT_MODULES_BEGIN';
export const FETCH_CONTRACT_MODULES_SUCCESS = 'FETCH_CONTRACT_MODULES_SUCCESS';
export const FETCH_CONTRACT_MODULES_FAILURE = 'FETCH_CONTRACT_MODULES_FAILURE';

export const FETCH_CONTRACT_STATEMENTS_BEGIN = 'FETCH_CONTRACT_STATEMENTS_BEGIN';
export const FETCH_CONTRACT_STATEMENTS_SUCCESS = 'FETCH_CONTRACT_STATEMENTS_SUCCESS';
export const FETCH_CONTRACT_STATEMENTS_FAILURE = 'FETCH_CONTRACT_STATEMENTS_FAILURE';

export const TOGGLE_UPDATE_NEGOTIATION_SLA_MODAL = 'TOGGLE_UPDATE_NEGOTIATION_SLA_MODAL';

// database references
export const SELECT_DATABASE_REFERENCE = 'SELECT_DATABASE_REFERENCE';

export const FETCH_DATABASE_REFERENCES_BEGIN = 'FETCH_DATABASE_REFERENCES_BEGIN';
export const FETCH_DATABASE_REFERENCES_SUCCESS = 'FETCH_DATABASE_REFERENCES_SUCCESS';
export const FETCH_DATABASE_REFERENCES_FAILURE = 'FETCH_DATABASE_REFERENCES_FAILURE';

export const TOGGLE_ADD_DATABASE_REFERENCE_MODAL = 'TOGGLE_ADD_DATABASE_REFERENCE_MODAL';

export const TOGGLE_UPDATE_DATABASE_REFERENCE_MODAL = 'TOGGLE_UPDATE_DATABASE_REFERENCE_MODAL';

export const ADD_DATABASE_REFERENCE_BEGIN = 'ADD_DATABASE_REFERENCE_BEGIN';
export const ADD_DATABASE_REFERENCE_SUCCESS = 'ADD_DATABASE_REFERENCE_SUCCESS';
export const ADD_DATABASE_REFERENCE_FAILURE = 'ADD_DATABASE_REFERENCE_FAILURE';

export const UPDATE_DATABASE_REFERENCE_BEGIN = 'UPDATE_DATABASE_REFERENCE_BEGIN';
export const UPDATE_DATABASE_REFERENCE_SUCCESS = 'UPDATE_DATABASE_REFERENCE_SUCCESS';
export const UPDATE_DATABASE_REFERENCE_FAILURE = 'UPDATE_DATABASE_REFERENCE_FAILURE';

export const DELETE_DATABASE_REFERENCE_BEGIN = 'DELETE_DATABASE_REFERENCE_BEGIN';
export const DELETE_DATABASE_REFERENCE_SUCCESS = 'DELETE_DATABASE_REFERENCE_SUCCESS';
export const DELETE_DATABASE_REFERENCE_FAILURE = 'DELETE_DATABASE_REFERENCE_FAILURE';

// project references
export const SELECT_PROJECT_REFERENCE = 'SELECT_PROJECT_REFERENCE';

export const FETCH_PROJECT_REFERENCES_BEGIN = 'FETCH_PROJECT_REFERENCES_BEGIN';
export const FETCH_PROJECT_REFERENCES_SUCCESS = 'FETCH_PROJECT_REFERENCES_SUCCESS';
export const FETCH_PROJECT_REFERENCES_FAILURE = 'FETCH_PROJECT_REFERENCES_FAILURE';

export const ADD_PROJECT_REFERENCE_BEGIN = 'ADD_PROJECT_REFERENCE_BEGIN';
export const ADD_PROJECT_REFERENCE_SUCCESS = 'ADD_PROJECT_REFERENCE_SUCCESS';
export const ADD_PROJECT_REFERENCE_FAILURE = 'ADD_PROJECT_REFERENCE_FAILURE';

export const UPDATE_PROJECT_REFERENCE_BEGIN = 'UPDATE_PROJECT_REFERENCE_BEGIN';
export const UPDATE_PROJECT_REFERENCE_SUCCESS = 'UPDATE_PROJECT_REFERENCE_SUCCESS';
export const UPDATE_PROJECT_REFERENCE_FAILURE = 'UPDATE_PROJECT_REFERENCE_FAILURE';

export const DELETE_PROJECT_REFERENCE_BEGIN = 'DELETE_PROJECT_REFERENCE_BEGIN';
export const DELETE_PROJECT_REFERENCE_SUCCESS = 'DELETE_PROJECT_REFERENCE_SUCCESS';
export const DELETE_PROJECT_REFERENCE_FAILURE = 'DELETE_PROJECT_REFERENCE_FAILURE';

export const TOGGLE_ADD_PROJECT_REFERENCE_MODAL = 'TOGGLE_ADD_PROJECT_REFERENCE_MODAL';

export const TOGGLE_UPDATE_PROJECT_REFERENCE_MODAL = 'TOGGLE_UPDATE_PROJECT_REFERENCE_MODAL';

// offering references
export const FETCH_OFFERING_REFERENCES_BEGIN = 'FETCH_OFFERING_REFERENCES_BEGIN';
export const FETCH_OFFERING_REFERENCES_SUCCESS = 'FETCH_OFFERING_REFERENCES_SUCCESS';
export const FETCH_OFFERING_REFERENCES_FAILURE = 'FETCH_OFFERING_REFERENCES_FAILURE';

export const ADD_OFFERING_REFERENCE_COMPANY_BEGIN = 'ADD_OFFERING_REFERENCE_COMPANY_BEGIN';
export const ADD_OFFERING_REFERENCE_COMPANY_SUCCESS = 'ADD_OFFERING_REFERENCE_COMPANY_SUCCESS';
export const ADD_OFFERING_REFERENCE_COMPANY_FAILURE = 'ADD_OFFERING_REFERENCE_COMPANY_FAILURE';

export const TOGGLE_ADD_OFFERING_REFERENCE_COMPANY_MODAL = 'TOGGLE_ADD_OFFERING_REFERENCE_COMPANY_MODAL';

export const SELECT_OFFERING_REFERENCE_COMPANY = 'SELECT_OFFERING_REFERENCE_COMPANY';

export const DELETE_OFFERING_REFERENCE_COMPANY_BEGIN = 'DELETE_OFFERING_REFERENCE_COMPANY_BEGIN';
export const DELETE_OFFERING_REFERENCE_COMPANY_SUCCESS = 'DELETE_OFFERING_REFERENCE_COMPANY_SUCCESS';
export const DELETE_OFFERING_REFERENCE_COMPANY_FAILURE = 'DELETE_OFFERING_REFERENCE_COMPANY_FAILURE';

export const TOGGLE_UPDATE_OFFERING_REFERENCE_COMPANY_MODAL = 'TOGGLE_UPDATE_OFFERING_REFERENCE_COMPANY_MODAL';

export const UPDATE_OFFERING_REFERENCE_COMPANY_BEGIN = 'UPDATE_OFFERING_REFERENCE_COMPANY_BEGIN';
export const UPDATE_OFFERING_REFERENCE_COMPANY_SUCCESS = 'UPDATE_OFFERING_REFERENCE_COMPANY_SUCCESS';
export const UPDATE_OFFERING_REFERENCE_COMPANY_FAILURE = 'UPDATE_OFFERING_REFERENCE_COMPANY_FAILURE';

export const TOGGLE_UPDATE_OFFERING_REFERENCE_MODAL = 'TOGGLE_UPDATE_OFFERING_REFERENCE_MODAL';

export const UPDATE_OFFERING_REFERENCE_BEGIN = 'UPDATE_OFFERING_REFERENCE_BEGIN';
export const UPDATE_OFFERING_REFERENCE_SUCCESS = 'UPDATE_OFFERING_REFERENCE_SUCCESS';
export const UPDATE_OFFERING_REFERENCE_FAILURE = 'UPDATE_OFFERING_REFERENCE_FAILURE';

// negotiation reference management
export const FETCH_NEGOTIATION_REFERENCES_BEGIN = 'FETCH_NEGOTIATION_REFERENCES_BEGIN';
export const FETCH_NEGOTIATION_REFERENCES_SUCCESS = 'FETCH_NEGOTIATION_REFERENCES_SUCCESS';
export const FETCH_NEGOTIATION_REFERENCES_FAILURE = 'FETCH_NEGOTIATION_REFERENCES_FAILURE';

export const SELECT_NEGOTIATION_REFERENCE_COMPANY = 'SELECT_NEGOTIATION_REFERENCE_COMPANY';

// database information management
export const SELECT_DATABASE_INFORMATION = 'SELECT_DATABASE_INFORMATION';

export const FETCH_DATABASE_INFORMATION_BEGIN = 'FETCH_DATABASE_INFORMATION_BEGIN';
export const FETCH_DATABASE_INFORMATION_SUCCESS = 'FETCH_DATABASE_INFORMATION_SUCCESS';
export const FETCH_DATABASE_INFORMATION_FAILURE = 'FETCH_DATABASE_INFORMATION_FAILURE';

export const TOGGLE_ADD_DATABASE_INFORMATION_MODAL = 'TOGGLE_ADD_DATABASE_INFORMATION_MODAL';

export const ADD_DATABASE_INFORMATION_BEGIN = 'ADD_DATABASE_INFORMATION_BEGIN';
export const ADD_DATABASE_INFORMATION_SUCCESS = 'ADD_DATABASE_INFORMATION_SUCCESS';
export const ADD_DATABASE_INFORMATION_FAILURE = 'ADD_DATABASE_INFORMATION_FAILURE';

export const DATABASE_INFORMATION_NUMBER_DOWN_BEGIN = 'DATABASE_INFORMATION_NUMBER_DOWN_BEGIN';
export const DATABASE_INFORMATION_NUMBER_DOWN_SUCCESS = 'DATABASE_INFORMATION_NUMBER_DOWN_SUCCESS';
export const DATABASE_INFORMATION_NUMBER_DOWN_FAILURE = 'DATABASE_INFORMATION_NUMBER_DOWN_FAILURE';

export const DATABASE_INFORMATION_NUMBER_UP_BEGIN = 'DATABASE_INFORMATION_NUMBER_UP_BEGIN';
export const DATABASE_INFORMATION_NUMBER_UP_SUCCESS = 'DATABASE_INFORMATION_NUMBER_UP_SUCCESS';
export const DATABASE_INFORMATION_NUMBER_UP_FAILURE = 'DATABASE_INFORMATION_NUMBER_UP_FAILURE';

export const DELETE_DATABASE_INFORMATION_BEGIN = 'DELETE_DATABASE_INFORMATION_BEGIN';
export const DELETE_DATABASE_INFORMATION_SUCCESS = 'DELETE_DATABASE_INFORMATION_SUCCESS';
export const DELETE_DATABASE_INFORMATION_FAILURE = 'DELETE_DATABASE_INFORMATION_FAILURE';

export const TOGGLE_UPDATE_DATABASE_INFORMATION_MODAL = 'TOGGLE_UPDATE_DATABASE_INFORMATION_MODAL';

export const UPDATE_DATABASE_INFORMATION_BEGIN = 'UPDATE_DATABASE_INFORMATION_BEGIN';
export const UPDATE_DATABASE_INFORMATION_SUCCESS = 'UPDATE_DATABASE_INFORMATION_SUCCESS';
export const UPDATE_DATABASE_INFORMATION_FAILURE = 'UPDATE_DATABASE_INFORMATION_FAILURE';

// project information management

export const SELECT_PROJECT_INFORMATION = 'SELECT_PROJECT_INFORMATION';

export const FETCH_PROJECT_INFORMATION_BEGIN = 'FETCH_PROJECT_INFORMATION_BEGIN';
export const FETCH_PROJECT_INFORMATION_SUCCESS = 'FETCH_PROJECT_INFORMATION_SUCCESS';
export const FETCH_PROJECT_INFORMATION_FAILURE = 'FETCH_PROJECT_INFORMATION_FAILURE';

export const TOGGLE_ADD_PROJECT_INFORMATION_MODAL = 'TOGGLE_ADD_PROJECT_INFORMATION_MODAL';

export const ADD_PROJECT_INFORMATION_BEGIN = 'ADD_PROJECT_INFORMATION_BEGIN';
export const ADD_PROJECT_INFORMATION_SUCCESS = 'ADD_PROJECT_INFORMATION_SUCCESS';
export const ADD_PROJECT_INFORMATION_FAILURE = 'ADD_PROJECT_INFORMATION_FAILURE';

export const PROJECT_INFORMATION_NUMBER_DOWN_BEGIN = 'PROJECT_INFORMATION_NUMBER_DOWN_BEGIN';
export const PROJECT_INFORMATION_NUMBER_DOWN_SUCCESS = 'PROJECT_INFORMATION_NUMBER_DOWN_SUCCESS';
export const PROJECT_INFORMATION_NUMBER_DOWN_FAILURE = 'PROJECT_INFORMATION_NUMBER_DOWN_FAILURE';

export const PROJECT_INFORMATION_NUMBER_UP_BEGIN = 'PROJECT_INFORMATION_NUMBER_UP_BEGIN';
export const PROJECT_INFORMATION_NUMBER_UP_SUCCESS = 'PROJECT_INFORMATION_NUMBER_UP_SUCCESS';
export const PROJECT_INFORMATION_NUMBER_UP_FAILURE = 'PROJECT_INFORMATION_NUMBER_UP_FAILURE';

export const DELETE_PROJECT_INFORMATION_BEGIN = 'DELETE_PROJECT_INFORMATION_BEGIN';
export const DELETE_PROJECT_INFORMATION_SUCCESS = 'DELETE_PROJECT_INFORMATION_SUCCESS';
export const DELETE_PROJECT_INFORMATION_FAILURE = 'DELETE_PROJECT_INFORMATION_FAILURE';

export const TOGGLE_UPDATE_PROJECT_INFORMATION_MODAL = 'TOGGLE_UPDATE_PROJECT_INFORMATION_MODAL';

export const UPDATE_PROJECT_INFORMATION_BEGIN = 'UPDATE_PROJECT_INFORMATION_BEGIN';
export const UPDATE_PROJECT_INFORMATION_SUCCESS = 'UPDATE_PROJECT_INFORMATION_SUCCESS';
export const UPDATE_PROJECT_INFORMATION_FAILURE = 'UPDATE_PROJECT_INFORMATION_FAILURE';

// offering information management

export const SELECT_OFFERING_INFORMATION = 'SELECT_OFFERING_INFORMATION';

export const FETCH_OFFERING_INFORMATION_BEGIN = 'FETCH_OFFERING_INFORMATION_BEGIN';
export const FETCH_OFFERING_INFORMATION_SUCCESS = 'FETCH_OFFERING_INFORMATION_SUCCESS';
export const FETCH_OFFERING_INFORMATION_FAILURE = 'FETCH_OFFERING_INFORMATION_FAILURE';

export const TOGGLE_UPDATE_OFFERING_INFORMATION_MODAL = 'TOGGLE_UPDATE_OFFERING_INFORMATION_MODAL';

export const UPDATE_OFFERING_INFORMATION_BEGIN = 'UPDATE_OFFERING_INFORMATION_BEGIN';
export const UPDATE_OFFERING_INFORMATION_SUCCESS = 'UPDATE_OFFERING_INFORMATION_SUCCESS';
export const UPDATE_OFFERING_INFORMATION_FAILURE = 'UPDATE_OFFERING_INFORMATION_FAILURE';

// negotiation informatuon management

export const SELECT_NEGOTIATION_INFORMATION = 'SELECT_NEGOTIATION_INFORMATION';

export const FETCH_NEGOTIATION_INFORMATION_BEGIN = 'FETCH_NEGOTIATION_INFORMATION_BEGIN';
export const FETCH_NEGOTIATION_INFORMATION_SUCCESS = 'FETCH_NEGOTIATION_INFORMATION_SUCCESS';
export const FETCH_NEGOTIATION_INFORMATION_FAILURE = 'FETCH_NEGOTIATION_INFORMATION_FAILURE';

export const FETCH_OFFERING_EDITORS_BEGIN = 'FETCH_OFFERING_EDITORS_BEGIN';
export const FETCH_OFFERING_EDITORS_SUCCESS = 'FETCH_OFFERING_EDITORS_SUCCESS';
export const FETCH_OFFERING_EDITORS_FAILURE = 'FETCH_OFFERING_EDITORS_FAILURE';

export const UPDATE_OFFERING_EDITORS_BEGIN = 'UPDATE_OFFERING_EDITORS_BEGIN';
export const UPDATE_OFFERING_EDITORS_SUCCESS = 'UPDATE_OFFERING_EDITORS_SUCCESS';
export const UPDATE_OFFERING_EDITORS_FAILURE = 'UPDATE_OFFERING_EDITORS_FAILURE';

export const FETCH_OFFERING_VIEWERS_BEGIN = 'FETCH_OFFERING_VIEWERS_BEGIN';
export const FETCH_OFFERING_VIEWERS_SUCCESS = 'FETCH_OFFERING_VIEWERS_SUCCESS';
export const FETCH_OFFERING_VIEWERS_FAILURE = 'FETCH_OFFERING_VIEWERS_FAILURE';

export const UPDATE_OFFERING_VIEWERS_BEGIN = 'UPDATE_OFFERING_VIEWERS_BEGIN';
export const UPDATE_OFFERING_VIEWERS_SUCCESS = 'UPDATE_OFFERING_VIEWERS_SUCCESS';
export const UPDATE_OFFERING_VIEWERS_FAILURE = 'UPDATE_OFFERING_VIEWERS_FAILURE';

export const TOGGLE_UPDATE_OFFERING_EDITORS_MODAL = 'TOGGLE_UPDATE_OFFERING_EDITORS_MODAL';

export const TOGGLE_LOGOUT_MODAL = 'TOGGLE_LOGOUT_MODAL';

// database subcontractors
export const SELECT_DATABASE_SUBCONTRACTOR = 'SELECT_DATABASE_SUBCONTRACTOR';

export const FETCH_DATABASE_SUBCONTRACTORS_BEGIN = 'FETCH_DATABASE_SUBCONTRACTORS_BEGIN';
export const FETCH_DATABASE_SUBCONTRACTORS_SUCCESS = 'FETCH_DATABASE_SUBCONTRACTORS_SUCCESS';
export const FETCH_DATABASE_SUBCONTRACTORS_FAILURE = 'FETCH_DATABASE_SUBCONTRACTORS_FAILURE';

export const TOGGLE_ADD_DATABASE_SUBCONTRACTOR_MODAL = 'TOGGLE_ADD_DATABASE_SUBCONTRACTOR_MODAL';

export const TOGGLE_UPDATE_DATABASE_SUBCONTRACTOR_MODAL = 'TOGGLE_UPDATE_DATABASE_SUBCONTRACTOR_MODAL';

export const ADD_DATABASE_SUBCONTRACTOR_BEGIN = 'ADD_DATABASE_SUBCONTRACTOR_BEGIN';
export const ADD_DATABASE_SUBCONTRACTOR_SUCCESS = 'ADD_DATABASE_SUBCONTRACTOR_SUCCESS';
export const ADD_DATABASE_SUBCONTRACTOR_FAILURE = 'ADD_DATABASE_SUBCONTRACTOR_FAILURE';

export const UPDATE_DATABASE_SUBCONTRACTOR_BEGIN = 'UPDATE_DATABASE_SUBCONTRACTOR_BEGIN';
export const UPDATE_DATABASE_SUBCONTRACTOR_SUCCESS = 'UPDATE_DATABASE_SUBCONTRACTOR_SUCCESS';
export const UPDATE_DATABASE_SUBCONTRACTOR_FAILURE = 'UPDATE_DATABASE_SUBCONTRACTOR_FAILURE';

export const DELETE_DATABASE_SUBCONTRACTOR_BEGIN = 'DELETE_DATABASE_SUBCONTRACTOR_BEGIN';
export const DELETE_DATABASE_SUBCONTRACTOR_SUCCESS = 'DELETE_DATABASE_SUBCONTRACTOR_SUCCESS';
export const DELETE_DATABASE_SUBCONTRACTOR_FAILURE = 'DELETE_DATABASE_SUBCONTRACTOR_FAILURE';

// project subcontractors
export const SELECT_PROJECT_SUBCONTRACTOR = 'SELECT_PROJECT_SUBCONTRACTOR';

export const FETCH_PROJECT_SUBCONTRACTORS_BEGIN = 'FETCH_PROJECT_SUBCONTRACTORS_BEGIN';
export const FETCH_PROJECT_SUBCONTRACTORS_SUCCESS = 'FETCH_PROJECT_SUBCONTRACTORS_SUCCESS';
export const FETCH_PROJECT_SUBCONTRACTORS_FAILURE = 'FETCH_PROJECT_SUBCONTRACTORS_FAILURE';

export const ADD_PROJECT_SUBCONTRACTOR_BEGIN = 'ADD_PROJECT_SUBCONTRACTOR_BEGIN';
export const ADD_PROJECT_SUBCONTRACTOR_SUCCESS = 'ADD_PROJECT_SUBCONTRACTOR_SUCCESS';
export const ADD_PROJECT_SUBCONTRACTOR_FAILURE = 'ADD_PROJECT_SUBCONTRACTOR_FAILURE';

export const UPDATE_PROJECT_SUBCONTRACTOR_BEGIN = 'UPDATE_PROJECT_SUBCONTRACTOR_BEGIN';
export const UPDATE_PROJECT_SUBCONTRACTOR_SUCCESS = 'UPDATE_PROJECT_SUBCONTRACTOR_SUCCESS';
export const UPDATE_PROJECT_SUBCONTRACTOR_FAILURE = 'UPDATE_PROJECT_SUBCONTRACTOR_FAILURE';

export const DELETE_PROJECT_SUBCONTRACTOR_BEGIN = 'DELETE_PROJECT_SUBCONTRACTOR_BEGIN';
export const DELETE_PROJECT_SUBCONTRACTOR_SUCCESS = 'DELETE_PROJECT_SUBCONTRACTOR_SUCCESS';
export const DELETE_PROJECT_SUBCONTRACTOR_FAILURE = 'DELETE_PROJECT_SUBCONTRACTOR_FAILURE';

export const TOGGLE_ADD_PROJECT_SUBCONTRACTOR_MODAL = 'TOGGLE_ADD_PROJECT_SUBCONTRACTOR_MODAL';

export const TOGGLE_UPDATE_PROJECT_SUBCONTRACTOR_MODAL = 'TOGGLE_UPDATE_PROJECT_SUBCONTRACTOR_MODAL';

// offering subcontractors
export const FETCH_OFFERING_SUBCONTRACTORS_BEGIN = 'FETCH_OFFERING_SUBCONTRACTORS_BEGIN';
export const FETCH_OFFERING_SUBCONTRACTORS_SUCCESS = 'FETCH_OFFERING_SUBCONTRACTORS_SUCCESS';
export const FETCH_OFFERING_SUBCONTRACTORS_FAILURE = 'FETCH_OFFERING_SUBCONTRACTORS_FAILURE';

export const ADD_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN = 'ADD_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN';
export const ADD_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS = 'ADD_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS';
export const ADD_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE = 'ADD_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE';

export const TOGGLE_ADD_OFFERING_SUBCONTRACTOR_COMPANY_MODAL = 'TOGGLE_ADD_OFFERING_SUBCONTRACTOR_COMPANY_MODAL';

export const SELECT_OFFERING_SUBCONTRACTOR_COMPANY = 'SELECT_OFFERING_SUBCONTRACTOR_COMPANY';

export const DELETE_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN = 'DELETE_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN';
export const DELETE_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS = 'DELETE_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS';
export const DELETE_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE = 'DELETE_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE';

export const TOGGLE_UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_MODAL = 'TOGGLE_UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_MODAL';

export const UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN = 'UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN';
export const UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS = 'UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS';
export const UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE = 'UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE';

export const TOGGLE_UPDATE_OFFERING_SUBCONTRACTOR_MODAL = 'TOGGLE_UPDATE_OFFERING_SUBCONTRACTOR_MODAL';

export const UPDATE_OFFERING_SUBCONTRACTOR_BEGIN = 'UPDATE_OFFERING_SUBCONTRACTOR_BEGIN';
export const UPDATE_OFFERING_SUBCONTRACTOR_SUCCESS = 'UPDATE_OFFERING_SUBCONTRACTOR_SUCCESS';
export const UPDATE_OFFERING_SUBCONTRACTOR_FAILURE = 'UPDATE_OFFERING_SUBCONTRACTOR_FAILURE';

// negotiation subcontractors
export const FETCH_NEGOTIATION_SUBCONTRACTORS_BEGIN = 'FETCH_NEGOTIATION_SUBCONTRACTORS_BEGIN';
export const FETCH_NEGOTIATION_SUBCONTRACTORS_SUCCESS = 'FETCH_NEGOTIATION_SUBCONTRACTORS_SUCCESS';
export const FETCH_NEGOTIATION_SUBCONTRACTORS_FAILURE = 'FETCH_NEGOTIATION_SUBCONTRACTORS_FAILURE';

export const SELECT_NEGOTIATION_SUBCONTRACTOR_COMPANY = 'SELECT_NEGOTIATION_SUBCONTRACTOR_COMPANY';

// View Offering

export const FETCH_VIEWOFFERING_PROJECTS_BEGIN = 'FETCH_VIEWOFFERING_PROJECTS_BEGIN';
export const FETCH_VIEWOFFERING_PROJECTS_SUCCESS = 'FETCH_VIEWOFFERING_PROJECTS_SUCCESS';
export const FETCH_VIEWOFFERING_PROJECTS_FAILURE = 'FETCH_VIEWOFFERING_PROJECTS_FAILURE';
export const VIEWOFFERING_FETCH_PROJECTS_BEGIN = 'VIEWOFFERING_FETCH_PROJECTS_BEGIN';
export const VIEWOFFERING_FETCH_PROJECTS_SUCCESS = 'VIEWOFFERING_FETCH_PROJECTS_SUCCESS';
export const VIEWOFFERING_FETCH_PROJECTS_FAILURE = 'VIEWOFFERING_FETCH_PROJECTS_FAILURE';
export const VIEWOFFERING_FETCH_TEMPLATES_BEGIN = 'VIEWOFFERING_FETCH_TEMPLATES_BEGIN';
export const VIEWOFFERING_FETCH_TEMPLATES_SUCCESS = 'VIEWOFFERING_FETCH_TEMPLATES_SUCCESS';
export const VIEWOFFERING_FETCH_TEMPLATES_FAILURE = 'VIEWOFFERING_FETCH_TEMPLATES_FAILURE';
export const VIEWOFFERING_FETCH_MODULES_BEGIN = 'VIEWOFFERING_FETCH_MODULES_BEGIN';
export const VIEWOFFERING_FETCH_MODULES_SUCCESS = 'VIEWOFFERING_FETCH_MODULES_SUCCESS';
export const VIEWOFFERING_FETCH_MODULES_FAILURE = 'VIEWOFFERING_FETCH_MODULES_FAILURE';
export const VIEWOFFERING_FETCH_STATEMENTS_BEGIN = 'VIEWOFFERING_FETCH_STATEMENTS_BEGIN';
export const VIEWOFFERING_FETCH_STATEMENTS_SUCCESS = 'VIEWOFFERING_FETCH_STATEMENTS_SUCCESS';
export const VIEWOFFERING_FETCH_STATEMENTS_FAILURE = 'VIEWOFFERING_FETCH_STATEMENTS_FAILURE';
export const VIEWOFFERING_CLEAR_PROJECTS_ARRAY = 'VIEWOFFERING_CLEAR_PROJECTS_ARRAY';
export const VIEWOFFERING_CLEAR_TEMPLATES_ARRAY = 'VIEWOFFERING_CLEAR_TEMPLATES_ARRAY';
export const VIEWOFFERING_CLEAR_MODULES_ARRAY = 'VIEWOFFERING_CLEAR_MODULES_ARRAY';
export const VIEWOFFERING_CLEAR_STATEMENTS_ARRAY = 'VIEWOFFERING_CLEAR_STATEMENTS_ARRAY';
export const VIEWOFFERING_SELECT_PROJECT = 'VIEWOFFERING_SELECT_PROJECT';
export const VIEWOFFERING_SELECT_TEMPLATE = 'VIEWOFFERING_SELECT_TEMPLATE';
export const VIEWOFFERING_SELECT_MODULE = 'VIEWOFFERING_SELECT_MODULE';
export const VIEWOFFERING_SELECT_STATEMENT = 'VIEWOFFERING_SELECT_STATEMENT';
export const PUBLISH_VIEWOFFERING_BEGIN = 'PUBLISH_VIEWOFFERING_BEGIN';
export const PUBLISH_VIEWOFFERING_SUCCESS = 'PUBLISH_VIEWOFFERING_SUCCESS';
export const PUBLISH_VIEWOFFERING_FAILURE = 'PUBLISH_VIEWOFFERING_FAILURE';
export const SELECT_VIEWOFFERING_ASSUMPTION = 'SELECT_VIEWOFFERING_ASSUMPTION';
export const SELECT_VIEWOFFERING_PROVISION = 'SELECT_VIEWOFFERING_PROVISION';
export const FETCH_VIEWOFFERING_ASSUMPTIONS_BEGIN = 'FETCH_VIEWOFFERING_ASSUMPTIONS_BEGIN';
export const FETCH_VIEWOFFERING_ASSUMPTIONS_SUCCESS = 'FETCH_VIEWOFFERING_ASSUMPTIONS_SUCCESS';
export const FETCH_VIEWOFFERING_ASSUMPTIONS_FAILURE = 'FETCH_VIEWOFFERING_ASSUMPTIONS_FAILURE';
export const FETCH_VIEWOFFERING_PROVISIONS_BEGIN = 'FETCH_VIEWOFFERING_PROVISIONS_BEGIN';
export const FETCH_VIEWOFFERING_PROVISIONS_SUCCESS = 'FETCH_VIEWOFFERING_PROVISIONS_SUCCESS';
export const FETCH_VIEWOFFERING_PROVISIONS_FAILURE = 'FETCH_VIEWOFFERING_PROVISIONS_FAILURE';
export const FETCH_VIEWOFFERING_SLAS_BEGIN = 'FETCH_VIEWOFFERING_SLAS_BEGIN';
export const FETCH_VIEWOFFERING_SLAS_SUCCESS = 'FETCH_VIEWOFFERING_SLAS_SUCCESS';
export const FETCH_VIEWOFFERING_SLAS_FAILURE = 'FETCH_VIEWOFFERING_SLAS_FAILURE';
export const CLEAR_VIEWOFFERING_SLAS_ARRAY = 'CLEAR_VIEWOFFERING_SLAS_ARRAY';
export const SELECT_VIEWOFFERING_SLA = 'SELECT_VIEWOFFERING_SLA';
export const FETCH_VIEWOFFERING_RATECARDS_BEGIN = 'FETCH_VIEWOFFERING_RATECARDS_BEGIN';
export const FETCH_VIEWOFFERING_RATECARDS_SUCCESS = 'FETCH_VIEWOFFERING_RATECARDS_SUCCESS';
export const FETCH_VIEWOFFERING_RATECARDS_FAILURE = 'FETCH_VIEWOFFERING_RATECARDS_FAILURE';
export const FETCH_VIEWOFFERING_FIXEDPRICES_BEGIN = 'FETCH_VIEWOFFERING_FIXEDPRICES_BEGIN';
export const FETCH_VIEWOFFERING_FIXEDPRICES_SUCCESS = 'FETCH_VIEWOFFERING_FIXEDPRICES_SUCCESS';
export const FETCH_VIEWOFFERING_FIXEDPRICES_FAILURE = 'FETCH_VIEWOFFERING_FIXEDPRICES_FAILURE';
export const FETCH_VIEWOFFERING_PIECEPRICES_BEGIN = 'FETCH_VIEWOFFERING_PIECEPRICES_BEGIN';
export const FETCH_VIEWOFFERING_PIECEPRICES_SUCCESS = 'FETCH_VIEWOFFERING_PIECEPRICES_SUCCESS';
export const FETCH_VIEWOFFERING_PIECEPRICES_FAILURE = 'FETCH_VIEWOFFERING_PIECEPRICES_FAILURE';
export const FETCH_VIEWOFFERING_TANDMS_BEGIN = 'FETCH_VIEWOFFERING_TANDMS_BEGIN';
export const FETCH_VIEWOFFERING_TANDMS_SUCCESS = 'FETCH_VIEWOFFERING_TANDMS_SUCCESS';
export const FETCH_VIEWOFFERING_TANDMS_FAILURE = 'FETCH_VIEWOFFERING_TANDMS_FAILURE';
export const SELECT_VIEWOFFERING_RATECARD = 'SELECT_VIEWOFFERING_RATECARD';
export const SELECT_VIEWOFFERING_FIXEDPRICE = 'SELECT_VIEWOFFERING_FIXEDPRICE';
export const SELECT_VIEWOFFERING_PIECEPRICE = 'SELECT_VIEWOFFERING_PIECEPRICE';
export const SELECT_VIEWOFFERING_TANDM = 'SELECT_VIEWOFFERING_TANDM';
export const SELECT_VIEWOFFERING_REFERENCE = 'SELECT_VIEWOFFERING_REFERENCE';
export const SELECT_VIEWOFFERING_SUBCONTRACTOR = 'SELECT_VIEWOFFERING_SUBCONTRACTOR';
export const DELETE_VIEWOFFERING_PROJECT_BEGIN = 'DELETE_VIEWOFFERING_PROJECT_BEGIN';
export const DELETE_VIEWOFFERING_PROJECT_SUCCESS = 'DELETE_VIEWOFFERING_PROJECT_SUCCESS';
export const DELETE_VIEWOFFERING_PROJECT_FAILURE = 'DELETE_VIEWOFFERING_PROJECT_FAILURE';
export const UPDATE_VIEWOFFERING_STATEMENT_BEGIN = 'UPDATE_VIEWOFFERING_STATEMENT_BEGIN';
export const UPDATE_VIEWOFFERING_STATEMENT_SUCCESS = 'UPDATE_VIEWOFFERING_STATEMENT_SUCCESS';
export const UPDATE_VIEWOFFERING_STATEMENT_FAILURE = 'UPDATE_VIEWOFFERING_STATEMENT_FAILURE';
export const UPDATE_VIEWOFFERING_SLA_BEGIN = 'UPDATE_VIEWOFFERING_SLA_BEGIN';
export const UPDATE_VIEWOFFERING_SLA_SUCCESS = 'UPDATE_VIEWOFFERING_SLA_SUCCESS';
export const UPDATE_VIEWOFFERING_SLA_FAILURE = 'UPDATE_VIEWOFFERING_SLA_FAILURE';
export const FETCH_VIEWOFFERING_REFERENCES_BEGIN = 'FETCH_VIEWOFFERING_REFERENCES_BEGIN';
export const FETCH_VIEWOFFERING_REFERENCES_SUCCESS = 'FETCH_VIEWOFFERING_REFERENCES_SUCCESS';
export const FETCH_VIEWOFFERING_REFERENCES_FAILURE = 'FETCH_VIEWOFFERING_REFERENCES_FAILURE';
export const SELECT_VIEWOFFERING_REFERENCE_COMPANY = 'SELECT_VIEWOFFERING_REFERENCE_COMPANY';
export const SELECT_VIEWOFFERING_INFORMATION = 'SELECT_VIEWOFFERING_INFORMATION';
export const FETCH_VIEWOFFERING_INFORMATION_BEGIN = 'FETCH_VIEWOFFERING_INFORMATION_BEGIN';
export const FETCH_VIEWOFFERING_INFORMATION_SUCCESS = 'FETCH_VIEWOFFERING_INFORMATION_SUCCESS';
export const FETCH_VIEWOFFERING_INFORMATION_FAILURE = 'FETCH_VIEWOFFERING_INFORMATION_FAILURE';
export const FETCH_VIEWOFFERING_SUBCONTRACTORS_BEGIN = 'FETCH_VIEWOFFERING_SUBCONTRACTORS_BEGIN';
export const FETCH_VIEWOFFERING_SUBCONTRACTORS_SUCCESS = 'FETCH_VIEWOFFERING_SUBCONTRACTORS_SUCCESS';
export const FETCH_VIEWOFFERING_SUBCONTRACTORS_FAILURE = 'FETCH_VIEWOFFERING_SUBCONTRACTORS_FAILURE';
export const SELECT_VIEWOFFERING_SUBCONTRACTOR_COMPANY = 'SELECT_VIEWOFFERING_SUBCONTRACTOR_COMPANY';

// Negotiation Mangement Additionals
export const ADD_NEGOTIATION_TEMPLATE_BEGIN = 'ADD_NEGOTIATION_TEMPLATE_BEGIN';
export const ADD_NEGOTIATION_TEMPLATE_SUCCESS = 'ADD_NEGOTIATION_TEMPLATE_SUCCESS';
export const ADD_NEGOTIATION_TEMPLATE_FAILURE = 'ADD_NEGOTIATION_TEMPLATE_FAILURE';
export const UPDATE_NEGOTIATION_TEMPLATE_BEGIN = 'UPDATE_NEGOTIATION_TEMPLATE_BEGIN';
export const UPDATE_NEGOTIATION_TEMPLATE_SUCCESS = 'UPDATE_NEGOTIATION_TEMPLATE_SUCCESS';
export const UPDATE_NEGOTIATION_TEMPLATE_FAILURE = 'UPDATE_NEGOTIATION_TEMPLATE_FAILURE';
export const DELETE_NEGOTIATION_TEMPLATE_BEGIN = 'DELETE_NEGOTIATION_TEMPLATE_BEGIN';
export const DELETE_NEGOTIATION_TEMPLATE_SUCCESS = 'DELETE_NEGOTIATION_TEMPLATE_SUCCESS';
export const DELETE_NEGOTIATION_TEMPLATE_FAILURE = 'DELETE_NEGOTIATION_TEMPLATE_FAILURE';

export const ADD_NEGOTIATION_MODULE_BEGIN = 'ADD_NEGOTIATION_MODULE_BEGIN';
export const ADD_NEGOTIATION_MODULE_SUCCESS = 'ADD_NEGOTIATION_MODULE_SUCCESS';
export const ADD_NEGOTIATION_MODULE_FAILURE = 'ADD_NEGOTIATION_MODULE_FAILURE';
export const UPDATE_NEGOTIATION_MODULE_BEGIN = 'UPDATE_NEGOTIATION_MODULE_BEGIN';
export const UPDATE_NEGOTIATION_MODULE_SUCCESS = 'UPDATE_NEGOTIATION_MODULE_SUCCESS';
export const UPDATE_NEGOTIATION_MODULE_FAILURE = 'UPDATE_NEGOTIATION_MODULE_FAILURE';
export const DELETE_NEGOTIATION_MODULE_BEGIN = 'DELETE_NEGOTIATION_MODULE_BEGIN';
export const DELETE_NEGOTIATION_MODULE_SUCCESS = 'DELETE_NEGOTIATION_MODULE_SUCCESS';
export const DELETE_NEGOTIATION_MODULE_FAILURE = 'DELETE_NEGOTIATION_MODULE_FAILURE';
export const UPDATE_NEGOTIATION_MODULE_PRICING_BEGIN = 'UPDATE_NEGOTIATION_MODULE_PRICING_BEGIN';
export const UPDATE_NEGOTIATION_MODULE_PRICING_SUCCESS = 'UPDATE_NEGOTIATION_MODULE_PRICING_SUCCESS';
export const UPDATE_NEGOTIATION_MODULE_PRICING_FAILURE = 'UPDATE_NEGOTIATION_MODULE_PRICING_FAILURE';
export const UPDATE_NEGOTIATION_MODULE_SLA_BEGIN = 'UPDATE_NEGOTIATION_MODULE_SLA_BEGIN';
export const UPDATE_NEGOTIATION_MODULE_SLA_SUCCESS = 'UPDATE_NEGOTIATION_MODULE_SLA_SUCCESS';
export const UPDATE_NEGOTIATION_MODULE_SLA_FAILURE = 'UPDATE_NEGOTIATION_MODULE_SLA_FAILURE';
export const UPDATE_NEGOTIATION_PIECEPRICE_BEGIN = 'UPDATE_NEGOTIATION_PIECEPRICE_BEGIN';
export const UPDATE_NEGOTIATION_PIECEPRICE_SUCCESS = 'UPDATE_NEGOTIATION_PIECEPRICE_SUCCESS';
export const UPDATE_NEGOTIATION_PIECEPRICE_FAILURE = 'UPDATE_NEGOTIATION_PIECEPRICE_FAILURE';
export const ADD_NEGOTIATION_SLA_BEGIN = 'ADD_NEGOTIATION_SLA_BEGIN';
export const ADD_NEGOTIATION_SLA_SUCCESS = 'ADD_NEGOTIATION_SLA_SUCCESS';
export const ADD_NEGOTIATION_SLA_FAILURE = 'ADD_NEGOTIATION_SLA_FAILURE';
export const UPDATE_NEGOTIATION_SLA_BEGIN = 'UPDATE_NEGOTIATION_SLA_BEGIN';
export const UPDATE_NEGOTIATION_SLA_SUCCESS = 'UPDATE_NEGOTIATION_SLA_SUCCESS';
export const UPDATE_NEGOTIATION_SLA_FAILURE = 'UPDATE_NEGOTIATION_SLA_FAILURE';
export const DELETE_NEGOTIATION_SLA_BEGIN = 'DELETE_NEGOTIATION_SLA_BEGIN';
export const DELETE_NEGOTIATION_SLA_SUCCESS = 'DELETE_NEGOTIATION_SLA_SUCCESS';
export const DELETE_NEGOTIATION_SLA_FAILURE = 'DELETE_NEGOTIATION_SLA_FAILURE';
export const ADD_NEGOTIATION_RATECARD_BEGIN = 'ADD_NEGOTIATION_RATECARD_BEGIN';
export const ADD_NEGOTIATION_RATECARD_SUCCESS = 'ADD_NEGOTIATION_RATECARD_SUCCESS';
export const ADD_NEGOTIATION_RATECARD_FAILURE = 'ADD_NEGOTIATION_RATECARD_FAILURE';
export const UPDATE_NEGOTIATION_RATECARD_BEGIN = 'UPDATE_NEGOTIATION_RATECARD_BEGIN';
export const UPDATE_NEGOTIATION_RATECARD_SUCCESS = 'UPDATE_NEGOTIATION_RATECARD_SUCCESS';
export const UPDATE_NEGOTIATION_RATECARD_FAILURE = 'UPDATE_NEGOTIATION_RATECARD_FAILURE';
export const DELETE_NEGOTIATION_RATECARD_BEGIN = 'DELETE_NEGOTIATION_RATECARD_BEGIN';
export const DELETE_NEGOTIATION_RATECARD_SUCCESS = 'DELETE_NEGOTIATION_RATECARD_SUCCESS';
export const DELETE_NEGOTIATION_RATECARD_FAILURE = 'DELETE_NEGOTIATION_RATECARD_FAILURE';
export const ADD_NEGOTIATION_STATEMENT_BEGIN = 'ADD_NEGOTIATION_STATEMENT_BEGIN';
export const ADD_NEGOTIATION_STATEMENT_SUCCESS = 'ADD_NEGOTIATION_STATEMENT_SUCCESS';
export const ADD_NEGOTIATION_STATEMENT_FAILURE = 'ADD_NEGOTIATION_STATEMENT_FAILURE';
export const DELETE_NEGOTIATION_STATEMENT_BEGIN = 'DELETE_STATEMENT_BEGIN';
export const DELETE_NEGOTIATION_STATEMENT_SUCCESS = 'DELETE_STATEMENT_SUCCESS';
export const DELETE_NEGOTIATION_STATEMENT_FAILURE = 'DELETE_STATEMENT_FAILURE';
export const UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_BEGIN = 'UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_BEGIN';
export const UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_SUCCESS = 'UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_SUCCESS';
export const UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_FAILURE = 'UPDATE_NEGOTIATION_STATEMENT_RESPONSIBILITIES_FAILURE';
export const FETCH_NEGOTIATION_EDITORS_BEGIN = 'FETCH_NEGOTIATION_EDITORS_BEGIN';
export const FETCH_NEGOTIATION_EDITORS_SUCCESS = 'FETCH_NEGOTIATION_EDITORS_SUCCESS';
export const FETCH_NEGOTIATION_EDITORS_FAILURE = 'FETCH_NEGOTIATION_EDITORS_FAILURE';
export const FETCH_NEGOTIATION_VIEWERS_BEGIN = 'FETCH_NEGOTIATION_VIEWERS_BEGIN';
export const FETCH_NEGOTIATION_VIEWERS_SUCCESS = 'FETCH_NEGOTIATION_VIEWERS_SUCCESS';
export const FETCH_NEGOTIATION_VIEWERS_FAILURE = 'FETCH_NEGOTIATION_VIEWERS_FAILURE';
export const UPDATE_NEGOTIATION_EDITORS_BEGIN = 'UPDATE_NEGOTIATION_EDITORS_BEGIN';
export const UPDATE_NEGOTIATION_EDITORS_SUCCESS = 'UPDATE_NEGOTIATION_EDITORS_SUCCESS';
export const UPDATE_NEGOTIATION_EDITORS_FAILURE = 'UPDATE_NEGOTIATION_EDITORS_FAILURE';
export const UPDATE_NEGOTIATION_VIEWERS_BEGIN = 'UPDATE_NEGOTIATION_VIEWERS_BEGIN';
export const UPDATE_NEGOTIATION_VIEWERS_SUCCESS = 'UPDATE_NEGOTIATION_VIEWERS_SUCCESS';
export const UPDATE_NEGOTIATION_VIEWERS_FAILURE = 'UPDATE_NEGOTIATION_VIEWERS_FAILURE';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';

export const FETCH_CREATION_PROJECTS_BEGIN = 'FETCH_CREATION_PROJECTS_BEGIN';
export const FETCH_CREATION_PROJECTS_SUCCESS = 'FETCH_CREATION_PROJECTS_SUCCESS';
export const FETCH_CREATION_PROJECTS_FAILURE = 'FETCH_CREATION_PROJECTS_FAILURE';
export const FETCH_CREATION_TEMPLATES_BEGIN = 'FETCH_CREATION_TEMPLATES_BEGIN';
export const FETCH_CREATION_TEMPLATES_SUCCESS = 'FETCH_CREATION_TEMPLATES_SUCCESS';
export const FETCH_CREATION_TEMPLATES_FAILURE = 'FETCH_CREATION_TEMPLATES_FAILURE';
export const FETCH_CREATION_MODULES_BEGIN = 'FETCH_CREATION_MODULES_BEGIN';
export const FETCH_CREATION_MODULES_SUCCESS = 'FETCH_CREATION_MODULES_SUCCESS';
export const FETCH_CREATION_MODULES_FAILURE = 'FETCH_CREATION_MODULES_FAILURE';

export const NEGOTIATION_TEMPLATE_NUMBER_DOWN_BEGIN = 'NEGOTIATION_TEMPLATE_NUMBER_DOWN_BEGIN';
export const NEGOTIATION_TEMPLATE_NUMBER_DOWN_SUCCESS = 'NEGOTIATION_TEMPLATE_NUMBER_DOWN_SUCCESS';
export const NEGOTIATION_TEMPLATE_NUMBER_DOWN_FAILURE = 'NEGOTIATION_TEMPLATE_NUMBER_DOWN_FAILURE';

export const NEGOTIATION_TEMPLATE_NUMBER_UP_BEGIN = 'NEGOTIATION_TEMPLATE_NUMBER_UP_BEGIN';
export const NEGOTIATION_TEMPLATE_NUMBER_UP_SUCCESS = 'NEGOTIATION_TEMPLATE_NUMBER_UP_SUCCESS';
export const NEGOTIATION_TEMPLATE_NUMBER_UP_FAILURE = 'NEGOTIATION_TEMPLATE_NUMBER_UP_FAILURE';

export const NEGOTIATION_MODULE_NUMBER_DOWN_BEGIN = 'NEGOTIATION_MODULE_NUMBER_DOWN_BEGIN';
export const NEGOTIATION_MODULE_NUMBER_DOWN_SUCCESS = 'NEGOTIATION_MODULE_NUMBER_DOWN_SUCCESS';
export const NEGOTIATION_MODULE_NUMBER_DOWN_FAILURE = 'NEGOTIATION_MODULE_NUMBER_DOWN_FAILURE';

export const NEGOTIATION_MODULE_NUMBER_UP_BEGIN = 'NEGOTIATION_MODULE_NUMBER_UP_BEGIN';
export const NEGOTIATION_MODULE_NUMBER_UP_SUCCESS = 'NEGOTIATION_MODULE_NUMBER_UP_SUCCESS';
export const NEGOTIATION_MODULE_NUMBER_UP_FAILURE = 'NEGOTIATION_MODULE_NUMBER_UP_FAILURE';

export const NEGOTIATION_STATEMENT_NUMBER_DOWN_BEGIN = 'NEGOTIATION_STATEMENT_NUMBER_DOWN_BEGIN';
export const NEGOTIATION_STATEMENT_NUMBER_DOWN_SUCCESS = 'NEGOTIATION_STATEMENT_NUMBER_DOWN_SUCCESS';
export const NEGOTIATION_STATEMENT_NUMBER_DOWN_FAILURE = 'NEGOTIATION_STATEMENT_NUMBER_DOWN_FAILURE';

export const NEGOTIATION_STATEMENT_NUMBER_UP_BEGIN = 'NEGOTIATION_STATEMENT_NUMBER_UP_BEGIN';
export const NEGOTIATION_STATEMENT_NUMBER_UP_SUCCESS = 'NEGOTIATION_STATEMENT_NUMBER_UP_SUCCESS';
export const NEGOTIATION_STATEMENT_NUMBER_UP_FAILURE = 'NEGOTIATION_STATEMENT_NUMBER_UP_FAILURE';

export const SELECT_VIEWOFFERING_PROJECTOVERVIEW = 'SELECT_VIEWOFFERING_PROJECTOVERVIEW';

export const FETCH_OFFERING_PRICES_BEGIN = 'FETCH_OFFERING_PRICES_BEGIN';
export const FETCH_OFFERING_PRICES_SUCCESS = 'FETCH_OFFERING_PRICES_SUCCESS';
export const FETCH_OFFERING_PRICES_FAILURE = 'FETCH_OFFERING_PRICES_FAILURE';

export const FETCH_DATABASE_ADDITIONAL_FILES_IS_SHOWING_BEGIN = 'FETCH_DATABASE_ADDITIONAL_FILES_IS_SHOWING_BEGIN';
export const FETCH_DATABASE_ADDITIONAL_FILES_IS_SHOWING_SUCCESS = 'FETCH_DATABASE_ADDITIONAL_FILES_IS_SHOWING_SUCCESS';
export const FETCH_DATABASE_ADDITIONAL_FILES_IS_SHOWING_FAILURE = 'FETCH_DATABASE_ADDITIONAL_FILES_IS_SHOWING_FAILURE';

export const FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_BEGIN = 'FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_BEGIN';
export const FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_SUCCESS = 'FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_SUCCESS';
export const FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_FAILURE = 'FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_FAILURE';

export const FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_BEGIN = 'FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_BEGIN';
export const FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_SUCCESS = 'FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_SUCCESS';
export const FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_FAILURE = 'FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_FAILURE';

export const FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_BEGIN = 'FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_BEGIN';
export const FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_SUCCESS = 'FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_SUCCESS';
export const FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_FAILURE = 'FETCH_NEGOTIATION_ADDITIONAL_FILES_IS_SHOWING_FAILURE';

export const SELECT_PROJECT_QANDA = 'SELECT_PROJECT_QANDA';

export const FETCH_PROJECT_QANDAS_BEGIN = 'FETCH_PROJECT_QANDAS_BEGIN';
export const FETCH_PROJECT_QANDAS_SUCCESS = 'FETCH_PROJECT_QANDAS_SUCCESS';
export const FETCH_PROJECT_QANDAS_FAILURE = 'FETCH_PROJECT_QANDAS_FAILURE';

export const FETCH_QANDA_BEGIN = 'FETCH_QANDA_BEGIN';
export const FETCH_QANDA_SUCCESS = 'FETCH_QANDA_SUCCESS';
export const FETCH_QANDA_FAILURE = 'FETCH_QANDA_FAILURE';

export const ADD_PROJECT_QANDA_BEGIN = 'ADD_PROJECT_QANDA_BEGIN';
export const ADD_PROJECT_QANDA_SUCCESS = 'ADD_PROJECT_QANDA_SUCCESS';
export const ADD_PROJECT_QANDA_FAILURE = 'ADD_PROJECT_QANDA_FAILURE';

export const UPDATE_PROJECT_QANDA_BEGIN = 'UPDATE_PROJECT_QANDA_BEGIN';
export const UPDATE_PROJECT_QANDA_SUCCESS = 'UPDATE_PROJECT_QANDA_SUCCESS';
export const UPDATE_PROJECT_QANDA_FAILURE = 'UPDATE_PROJECT_QANDA_FAILURE';

export const DELETE_PROJECT_QANDA_BEGIN = 'DELETE_PROJECT_QANDA_BEGIN';
export const DELETE_PROJECT_QANDA_SUCCESS = 'DELETE_PROJECT_QANDA_SUCCESS';
export const DELETE_PROJECT_QANDA_FAILURE = 'DELETE_PROJECT_QANDA_FAILURE';

export const TOGGLE_QANDA_PUBLISHED_BEGIN = 'TOGGLE_QANDA_PUBLISHED_BEGIN';
export const TOGGLE_QANDA_PUBLISHED_SUCCESS = 'DELETE_PROJECT_QANDA_SUCCESS';
export const TOGGLE_QANDA_PUBLISHED_FAILURE = 'DELETE_PROJECT_QANDA_FAILURE';

export const SELECT_OFFERING_QANDA = 'SELECT_OFFERING_QANDA';

export const FETCH_OFFERING_QANDAS_BEGIN = 'FETCH_OFFERING_QANDAS_BEGIN';
export const FETCH_OFFERING_QANDAS_SUCCESS = 'FETCH_OFFERING_QANDAS_SUCCESS';
export const FETCH_OFFERING_QANDAS_FAILURE = 'FETCH_OFFERING_QANDAS_FAILURE';

export const ADD_OFFERING_QANDA_BEGIN = 'ADD_OFFERING_QANDA_BEGIN';
export const ADD_OFFERING_QANDA_SUCCESS = 'ADD_OFFERING_QANDA_SUCCESS';
export const ADD_OFFERING_QANDA_FAILURE = 'ADD_OFFERING_QANDA_FAILURE';

export const UPDATE_OFFERING_QANDA_BEGIN = 'UPDATE_OFFERING_QANDA_BEGIN';
export const UPDATE_OFFERING_QANDA_SUCCESS = 'UPDATE_OFFERING_QANDA_SUCCESS';
export const UPDATE_OFFERING_QANDA_FAILURE = 'UPDATE_OFFERING_QANDA_FAILURE';

export const DELETE_OFFERING_QANDA_BEGIN = 'DELETE_OFFERING_QANDA_BEGIN';
export const DELETE_OFFERING_QANDA_SUCCESS = 'DELETE_OFFERING_QANDA_SUCCESS';
export const DELETE_OFFERING_QANDA_FAILURE = 'DELETE_OFFERING_QANDA_FAILURE';

export const MOVE_UP_PROJECT_REFERENCE_BEGIN = 'MOVE_UP_PROJECT_REFERENCE_BEGIN';
export const MOVE_UP_PROJECT_REFERENCE_SUCCESS = 'MOVE_UP_PROJECT_REFERENCE_SUCCESS';
export const MOVE_UP_PROJECT_REFERENCE_FAILURE = 'MOVE_UP_PROJECT_REFERENCE_FAILURE';

export const MOVE_DOWN_PROJECT_REFERENCE_BEGIN = 'MOVE_DOWN_PROJECT_REFERENCE_BEGIN';
export const MOVE_DOWN_PROJECT_REFERENCE_SUCCESS = 'MOVE_DOWN_PROJECT_REFERENCE_SUCCESS';
export const MOVE_DOWN_PROJECT_REFERENCE_FAILURE = 'MOVE_DOWN_PROJECT_REFERENCE_FAILURE';

export const MOVE_UP_PROJECT_SUBCONTRACTOR_BEGIN = 'MOVE_UP_PROJECT_SUBCONTRACTOR_BEGIN';
export const MOVE_UP_PROJECT_SUBCONTRACTOR_SUCCESS = 'MOVE_UP_PROJECT_SUBCONTRACTOR_SUCCESS';
export const MOVE_UP_PROJECT_SUBCONTRACTOR_FAILURE = 'MOVE_UP_PROJECT_SUBCONTRACTOR_FAILURE';

export const MOVE_DOWN_PROJECT_SUBCONTRACTOR_BEGIN = 'MOVE_DOWN_PROJECT_SUBCONTRACTOR_BEGIN';
export const MOVE_DOWN_PROJECT_SUBCONTRACTOR_SUCCESS = 'MOVE_DOWN_PROJECT_SUBCONTRACTOR_SUCCESS';
export const MOVE_DOWN_PROJECT_SUBCONTRACTOR_FAILURE = 'MOVE_DOWN_PROJECT_SUBCONTRACTOR_FAILURE';

export const UPLOAD_OFFERING_INFORMATION_FILE_BEGIN = 'UPLOAD_OFFERING_INFORMATION_FILE_BEGIN';
export const UPLOAD_OFFERING_INFORMATION_FILE_SUCCESS = 'UPLOAD_OFFERING_INFORMATION_FILE_SUCCESS';
export const UPLOAD_OFFERING_INFORMATION_FILE_FAILURE = 'UPLOAD_OFFERING_INFORMATION_FILE_FAILURE';

export const REMOVE_OFFERING_INFORMATION_UPLOADS_BEGIN = 'REMOVE_OFFERING_INFORMATION_UPLOADS_BEGIN';
export const REMOVE_OFFERING_INFORMATION_UPLOADS_SUCCESS = 'REMOVE_OFFERING_INFORMATION_UPLOADS_SUCCESS';
export const REMOVE_OFFERING_INFORMATION_UPLOADS_FAILURE = 'REMOVE_OFFERING_INFORMATION_UPLOADS_FAILURE';

export const MOVE_UP_PROJECT_INFORMATION_BEGIN = 'MOVE_UP_PROJECT_INFORMATION_BEGIN';
export const MOVE_UP_PROJECT_INFORMATION_SUCCESS = 'MOVE_UP_PROJECT_INFORMATION_SUCCESS';
export const MOVE_UP_PROJECT_INFORMATION_FAILURE = 'MOVE_UP_PROJECT_INFORMATION_FAILURE';

export const MOVE_DOWN_PROJECT_INFORMATION_BEGIN = 'MOVE_DOWN_PROJECT_INFORMATION_BEGIN';
export const MOVE_DOWN_PROJECT_INFORMATION_SUCCESS = 'MOVE_DOWN_PROJECT_INFORMATION_SUCCESS';
export const MOVE_DOWN_PROJECT_INFORMATION_FAILURE = 'MOVE_DOWN_PROJECT_INFORMATION_FAILURE';
