import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { blue, grey } from '@material-ui/core/colors';

import TableEditIconButton from '../Buttons/TableEditIconButton';

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: grey[300],
  },
  submitButton: {
    color: blue[300],
  },
}));

export default function EditOfferingSuggestionDialog(props) {
  const {
    projectId,
    statementId,
    servicelevelId,
    originalData,
    oldData,
    header,
    text,
    onClick,
    opened,
    // toggleCompliance,
  } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(opened);
  const [input, setInput] = useState(
    oldData.length > 0 ? oldData : originalData,
  );

  const handleClickOpen = () => {
    setOpen(true);
    setInput(oldData.length > 0 ? oldData : originalData);
  };

  const handleClose = () => {
    setOpen(false);
    // toggleCompliance(id);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onClick({ variables: { projectId, statementId, servicelevelId, suggestion: input } });
    setOpen(false);
  };

  return (
    <div>
      <TableEditIconButton onClick={handleClickOpen} />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <TextField
              id="original"
              type="text"
              label="Originaltext"
              fullWidth
              multiline
              margin="normal"
              value={originalData}
            />
            <TextField
              id="input"
              type="text"
              label={text}
              fullWidth
              multiline
              margin="normal"
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={handleClose}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            className={classes.submitButton}
            onClick={handleSubmit}
            color="primary"
            autoFocus
          >
            Ändern
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditOfferingSuggestionDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  statementId: PropTypes.string.isRequired,
  servicelevelId: PropTypes.string.isRequired,
  oldData: PropTypes.string.isRequired,
  originalData: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  opened: PropTypes.bool.isRequired,
  // toggleCompliance: PropTypes.func.isRequired,
};
