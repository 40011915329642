import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_INFORMATIONS, GET_PROJECT } from '../../graphql/queries';

import {
  selectReference,
  selectNegotiationReferenceCompany,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/negotiationAction';

import NegotiationReferencesCompaniesTable from '../../components/NegotiationManagement/NegotiationReferencesCompaniesTable';
import NegotiationReferencesTable from '../../components/NegotiationManagement/NegotiationReferencesTable';
import Loading from '../../components/Elements/Loading';

function NegotiationReferencesCompaniesContainer(props) {
  const {
    selectReference,
    selectNegotiationReferenceCompany,
    selectProject,
    selectTemplate,
    selectedReferenceCompany,
    selectedProject,
  } = props;

  const informationType = 'Referenz';
  const { loading, error, data } = useQuery(GET_INFORMATIONS, {
    variables: { projectId: selectedProject, type: informationType },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });
  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }

  if (selectedReferenceCompany === '') {
    return (
      <NegotiationReferencesCompaniesTable
        selectReference={selectReference}
        selectNegotiationReferenceCompany={selectNegotiationReferenceCompany}
        selectProject={selectProject}
        selectTemplate={selectTemplate}
        references={data.informations}
        projects={data1.project}
        selectedProject={selectedProject}
      />
    );
  }
  return (
    <NegotiationReferencesTable
      selectReference={selectReference}
      selectNegotiationReferenceCompany={selectNegotiationReferenceCompany}
      selectedReferenceCompany={selectedReferenceCompany}
      selectProject={selectProject}
      selectTemplate={selectTemplate}
      references={data.informations}
      projects={data1.project}
      selectedProject={selectedProject}
    />
  );
}

const mapStateToProps = (state) => ({
  references: state.negotiation.references,
  projects: state.negotiation.projects,
  templates: state.negotiation.templates,
  modules: state.negotiation.modules,
  selectedProject: state.negotiation.selectedProject,
  selectedReference: state.negotiation.selectedReference,
  selectedReferenceCompany: state.negotiation.selectedReferenceCompany,
  loading: state.negotiation.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  selectReference: (id) => dispatch(selectReference(id)),
  selectNegotiationReferenceCompany: (company) =>
    dispatch(selectNegotiationReferenceCompany(company)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(NegotiationReferencesCompaniesContainer),
);

NegotiationReferencesCompaniesContainer.propTypes = {
  selectReference: PropTypes.func.isRequired,
  selectNegotiationReferenceCompany: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectedReferenceCompany: PropTypes.string.isRequired,
};
