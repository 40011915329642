import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { blue, grey } from '@material-ui/core/colors';

import TableEditIconButton from '../Buttons/TableEditIconButton';

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: grey[300],
  },
  submitButton: {
    color: blue[300],
  },
}));

export default function EditSlaDialog(props) {
  const { projectId, servicelevelId, oldData, header, onClick } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [moduleName, setmoduleName] = useState(
    `${oldData.rowData[5]} - ${oldData.rowData[6]}`,
  );
  const [name, setName] = useState(oldData.rowData[7]);
  const [servicelevel, setServicelevel] = useState(oldData.rowData[8]);
  const [value, setValue] = useState(oldData.rowData[9]);
  const [measure, setMeasure] = useState(oldData.rowData[10]);
  const [description, setDescription] = useState(oldData.rowData[11]);
  const [penalty, setPenalty] = useState(oldData.rowData[12]);
  const [penaltyreference, setPenaltyreference] = useState(oldData.rowData[13]);

  const handleClickOpen = () => {
    setOpen(true);
    setmoduleName(
      `${oldData.rowData[5]} - ${oldData.rowData[6]}`,
    );
    setName(oldData.rowData[7]);
    setServicelevel(oldData.rowData[8]);
    setValue(oldData.rowData[9]);
    setMeasure(oldData.rowData[10]);
    setDescription(oldData.rowData[11]);
    setPenalty(oldData.rowData[12]);
    setPenaltyreference(oldData.rowData[13]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onClick({
      variables: {
        projectId,
        servicelevelId,
        value,
        name,
        servicelevel,
        description,
        penalty,
        penaltyreference,
        measure,
      },
    });
    setOpen(false);
  };

  return (
    <div>
      <TableEditIconButton onClick={handleClickOpen} />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <TextField
              id="input"
              label="Modul"
              fullWidth
              margin="normal"
              value={moduleName}
              disabled
              onChange={(e) => setmoduleName(e.target.value)}
            />
            <TextField
              id="name"
              label="Name"
              fullWidth
              margin="normal"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <TextField
              id="servicelevel"
              label="Service Level"
              fullWidth
              margin="normal"
              value={servicelevel}
              onChange={(e) => setServicelevel(e.target.value)}
            />
            <TextField
              id="value"
              label="Wert"
              fullWidth
              margin="normal"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <TextField
              id="measure"
              label="Messpunkt"
              fullWidth
              margin="normal"
              value={measure}
              onChange={(e) => setMeasure(e.target.value)}
            />
            <TextField
              id="description"
              label="Erläuterung"
              fullWidth
              multiline
              rowsMax={4}
              margin="normal"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <TextField
              id="penalty"
              label="Pönale in €"
              fullWidth
              margin="normal"
              value={penalty}
              onChange={(e) => setPenalty(e.target.value)}
            />
            <TextField
              id="penaltyreference"
              label="Pönalenbezug"
              fullWidth
              multiline
              rowsMax={4}
              margin="normal"
              value={penaltyreference}
              onChange={(e) => setPenaltyreference(e.target.value)}
            />
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={handleClose}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            className={classes.submitButton}
            onClick={handleSubmit}
            color="primary"
            autoFocus
          >
            Ändern
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

EditSlaDialog.propTypes = {
  projectId: PropTypes.string.isRequired,
  servicelevelId: PropTypes.string.isRequired,
  oldData: PropTypes.shape(Object).isRequired,
  header: PropTypes.string.isRequired,
  // templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  onClick: PropTypes.func.isRequired,
};
