import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import useStyles from '../../styles';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';
import CurrencyFormat from '../Elements/CurrencyFormat';

function ViewOfferingRatecardTable(props) {
  const {
    ratecards,
    projects,
    selectProject,
    selectTemplate,
    selectRatecard,
    // user,
  } = props;

  const classes = useStyles();
  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'project',
      label: 'projectId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'profile',
      label: 'Profilname',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Profilbeschreibung',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'dailyrate',
      label: 'Tagessatz in €',
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.rightAlignedHeader }),
        setCellProps: () => ({ className: classes.rightAlignedCell }),
        customBodyRender: (value) => <CurrencyFormat value={value} />,
      },
    },
    {
      name: 'travelallowance',
      label: 'Reisekostenpauschale in €',
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.rightAlignedHeader }),
        setCellProps: () => ({ className: classes.rightAlignedCell }),
        customBodyRender: (value) => <CurrencyFormat value={value} />,
      },
    },
  ];

  const data = [];

  ratecards.forEach((ratecard) => {
    data.push({
      _id: ratecard._id,
      project: ratecard.project,
      profile: ratecard.profile,
      description: ratecard.description,
      dailyrate: ratecard.dailyrate,
      travelallowance: ratecard.travelallowance,
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectRatecard('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/viewoffering"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectRatecard('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/viewoffering"
                onClick={() => selectRatecard('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Rate Card</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions()}
      />
    </div>
  );
}

export default ViewOfferingRatecardTable;

ViewOfferingRatecardTable.propTypes = {
  projects: PropTypes.shape(Object).isRequired,
  ratecards: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectRatecard: PropTypes.func.isRequired,
};
