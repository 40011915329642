import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  FormControl,
} from '@material-ui/core';
import {
  TextField,
} from 'formik-material-ui';
import { blue, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyInput from '../Elements/CurrencyInput';
import NumberInput from '../Elements/LocalNumberInput';

import TableEditIconButton from '../Buttons/TableEditIconButton';

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: grey[300],
  },
  submitButton: {
    color: blue[300],
  },
}));

export default function EditOfferingTAndMDialog(props) {
  const { projectId, priceId, header, ratecards, onClick, oldData } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const calculatePrice = (days, ratecardId) => ratecards.filter((ratecard) => ratecard._id === ratecardId)
    .map((ratecard) => ratecard.dailyrate)[0] * days || 0;

  return (
    <div>
      <TableEditIconButton onClick={handleClickOpen} />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{header}</DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              ratecardId: oldData[1] || 0,
              days: oldData[2],
              price: calculatePrice(oldData[2], oldData[1]),
              profile: ratecards
                .filter((ratecard) => ratecard._id === oldData[1])
                .map((ratecard) => ratecard.profile)[0],
            }}
            validationSchema={Yup.object({
              days: Yup.mixed().required('Darf nicht leer sein'),
              ratecardId: Yup.string().min(5, 'Bitte Profil auswählen'),
            })}
            onSubmit={(values) => {
              onClick({ variables: {
                projectId,
                priceId,
                profile: values.ratecardId,
                days: values.days,
                price: values.price,
              } });
              setOpen(false);
            }}

          >
            {({
              values,
              handleSubmit,
              setFieldValue,
            }) => (
              <Form>
                <FormControl margin="normal" fullWidth>
                  <Field
                    component={TextField}
                    label="Profil"
                    type="text"
                    select
                    SelectProps={{
                      multiple: false,
                    }}
                    name="ratecardId"
                    onChange={(e) => {
                      setFieldValue('ratecardId', e.target.value);
                      setFieldValue('price', calculatePrice(values.days, e.target.value));
                    }}
                  >
                    <MenuItem value="none">
                      <em>-</em>
                    </MenuItem>
                    {ratecards.map((ratecard) => (
                      <MenuItem key={ratecard._id} value={ratecard._id}>
                        {ratecard.profile}
                      </MenuItem>
                    ))}
                  </Field>

                </FormControl>
                <FormControl margin="normal" fullWidth>
                  <Field
                    component={NumberInput}
                    label="Anzahl PT"
                    name="days"
                    onChange={(e) => {
                      setFieldValue('days', e.target.value);
                      setFieldValue('price', calculatePrice(e.target.value, values.ratecardId));
                    }}
                  />
                </FormControl>
                <FormControl margin="normal" fullWidth>
                  <Field
                    component={CurrencyInput}
                    label="Preis in €"
                    name="price"
                    disabled
                  />
                </FormControl>
                <DialogActions>
                  <Button
                    className={classes.cancelButton}
                    onClick={handleClose}
                    color="primary"
                  >
                    Abbrechen
                  </Button>
                  <Button
                    className={classes.submitButton}
                    onClick={handleSubmit}
                    color="primary"
                    autoFocus
                  >
                    Ändern
                  </Button>

                </DialogActions>

              </Form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </div>
  );
}

EditOfferingTAndMDialog.propTypes = {
  onClick: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  ratecards: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projectId: PropTypes.string.isRequired,
  priceId: PropTypes.string.isRequired,
  oldData: PropTypes.arrayOf(PropTypes.any).isRequired,
};
