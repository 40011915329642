import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery } from '@apollo/react-hooks';
import { GET_RATECARDS, GET_PROJECT } from '../../graphql/queries';

import {
  selectRatecard,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/viewofferingAction';
import ViewOfferingRatecardTable from '../../components/ViewOffering/ViewOfferingRatecardTable';
import Loading from '../../components/Elements/Loading';

function ViewOfferingRatecardContainer(props) {
  const {
    selectRatecard,
    selectProject,
    selectTemplate,
    selectedProject,
  } = props;

  const { loading, error, data } = useQuery(GET_RATECARDS, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  return (
    <ViewOfferingRatecardTable
      ratecards={data.ratecards}
      projects={data1.project}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectRatecard={selectRatecard}
    />
  );
}

const mapStateToProps = (state) => ({
  ratecards: state.viewoffering.ratecards,
  projects: state.viewoffering.projects,
  selectedProject: state.viewoffering.selectedProject,
  loading: state.viewoffering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectRatecard: (id) => dispatch(selectRatecard(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewOfferingRatecardContainer),
);

ViewOfferingRatecardContainer.propTypes = {
  selectRatecard: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
};
