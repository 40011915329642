import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import useStyles from '../../styles';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';
import CurrencyFormat from '../Elements/CurrencyFormat';

function NegotiationFixedPricesTable(props) {
  const {
    fixedPrices,
    projects,
    selectProject,
    selectTemplate,
    selectFixedPrice,
  } = props;

  const getSum = (total, num) => total + num;

  const classes = useStyles();
  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'template',
      label: 'TemplateId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'templateName',
      label: 'Dokument',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'moduleName',
      label: 'Modul',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'price',
      label: 'Preis in €',
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.rightAlignedHeader }),
        setCellProps: () => ({ className: classes.rightAlignedCell }),
        customBodyRender: (value) => {
          if (value !== 0) return <CurrencyFormat value={value} />;
          return 'Wird vom Anbieter ausgefüllt.';
        },
      },
    },
  ];

  const data = [];

  fixedPrices.forEach((fixedprice) => {
    data.push({
      _id: fixedprice._id,
      template: fixedprice.template,
      templateName: fixedprice.templateName,
      moduleName: fixedprice.moduleName,
      price: fixedprice.price,
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectFixedPrice('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/managenegotiation"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectFixedPrice('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/managenegotiation"
                onClick={() => selectFixedPrice('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Festpreise</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({
          sortOrder: { name: 'templateName', direction: 'asc' },
        })}
      />
      <div style={{ textAlign: 'center', marginTop: 16 }}>
        <Typography variant="h6">
          Summe:
          <CurrencyFormat value={
          fixedPrices
            .map((fixedPrice) => fixedPrice.price)
            .reduce(getSum, 0)
            .toLocaleString()
          }
          />
        </Typography>
      </div>
    </div>
  );
}

export default NegotiationFixedPricesTable;

NegotiationFixedPricesTable.propTypes = {
  projects: PropTypes.shape(Object).isRequired,
  fixedPrices: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectFixedPrice: PropTypes.func.isRequired,
};
