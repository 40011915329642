import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_SERVICELEVELS, GET_PROJECT } from '../../graphql/queries';
import {
  ADD_SERVICELEVEL,
  UPDATE_SERVICELEVEL,
  DELETE_SERVICELEVEL,
} from '../../graphql/mutations';

import {
  selectProject,
  selectTemplate,
  selectSla,
} from '../../redux/actionCreators/negotiationAction';
import NegotiationSlasTable from '../../components/NegotiationManagement/NegotiationSlasTable';
import Loading from '../../components/Elements/Loading';

function NegotiationSlasContainer(props) {
  const {
    selectProject,
    selectTemplate,
    selectedProject,
    selectSla,
  } = props;

  const { loading, error, data } = useQuery(GET_SERVICELEVELS, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [addServiceLevel] = useMutation(ADD_SERVICELEVEL, {
    refetchQueries: [{
      query: GET_SERVICELEVELS,
      variables: { projectId: selectedProject },
    }],
  });
  const [updateServiceLevel] = useMutation(UPDATE_SERVICELEVEL, {
    refetchQueries: [{
      query: GET_SERVICELEVELS,
      variables: { projectId: selectedProject },
    }],
  });
  const [deleteServiceLevel] = useMutation(DELETE_SERVICELEVEL, {
    refetchQueries: [{
      query: GET_SERVICELEVELS,
      variables: { projectId: selectedProject },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  return (
    <NegotiationSlasTable
      slas={data.servicelevels}
      projects={data1.project}
      addSla={addServiceLevel}
      updateSla={updateServiceLevel}
      deleteSla={deleteServiceLevel}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectSla={selectSla}
    />
  );
}

const mapStateToProps = (state) => ({
  slas: state.negotiation.slas,
  projects: state.negotiation.projects,
  templates: state.negotiation.templates,
  selectedProject: state.negotiation.selectedProject,
  loading: state.negotiation.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectSla: (id) => dispatch(selectSla(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NegotiationSlasContainer),
);

NegotiationSlasContainer.propTypes = {
  selectSla: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
};
