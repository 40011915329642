import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import { ZoomIn } from '@material-ui/icons';
import TableButton from '../Buttons/TableButton';
import AddDialog from '../Dialogs/AddDialog';
import EditDialog from '../Dialogs/EditDialog';
import DeleteDialog from '../Dialogs/DeleteDialog';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function OfferingReferencesCompaniesTable(props) {
  const {
    selectReference,
    selectOfferingReferenceCompany,
    selectProject,
    selectTemplate,
    addOfferingReferenceCompany,
    updateOfferingReferenceCompany,
    deleteOfferingReferenceCompany,
    references,
    projects,
    selectedProject,
    // user,
  } = props;

  const columns = [
    {
      name: 'company',
      label: 'Unternehmen',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <TableButton
            text={value}
            icon={<ZoomIn />}
            onClick={() => selectOfferingReferenceCompany(tableMeta.rowData[0])}
          />
        ),
      },
    },

    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        display: !projects.published && projects.authorization !== 'read',
        viewColumns: !projects.published && projects.authorization !== 'read',
        customBodyRender: (value, tableMeta) => (
          <>
            <EditDialog
              projectId={selectedProject}
              oldData={tableMeta.rowData[0]}
              type="Referenz"
              header="Referenz bearbeiten"
              text="Unternehmen"
              field="newCluster"
              onClick={updateOfferingReferenceCompany}
            />
            <DeleteDialog
              header="Referenz entfernen"
              text={`Wollen Sie ${tableMeta.rowData[0]} entfernen?`}
              onClick={() =>
                deleteOfferingReferenceCompany({
                  variables: {
                    projectId: selectedProject,
                    cluster: tableMeta.rowData[0],
                    type: 'Referenz',
                  },
                })}
            />
          </>
        ),
      },
    },
  ];

  // * Important: use this to filter arrays for template movement and copying
  const onlyUnique = (value, index, self) => self.indexOf(value) === index;

  const referenceCompanies = references
    .filter((reference) => reference.cluster !== '')
    .map((reference) => reference.cluster)
    .filter(onlyUnique);

  const data = [];

  referenceCompanies.forEach((reference) => {
    data.push({
      company: reference,
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectReference('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectReference('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => selectReference('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Referenzen</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions()}
      />
      {!projects.published && projects.authorization !== 'read' && (
        <AddDialog
          projectId={selectedProject}
          type="Referenz"
          field="cluster"
          header="Referenz hinzufügen"
          text="Unternehmen"
          onClick={addOfferingReferenceCompany}
        />
      )}
    </div>
  );
}

export default OfferingReferencesCompaniesTable;

OfferingReferencesCompaniesTable.propTypes = {
  selectReference: PropTypes.func.isRequired,
  selectOfferingReferenceCompany: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  addOfferingReferenceCompany: PropTypes.func.isRequired,
  updateOfferingReferenceCompany: PropTypes.func.isRequired,
  deleteOfferingReferenceCompany: PropTypes.func.isRequired,
  references: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
  selectedProject: PropTypes.string.isRequired,
  // user: PropTypes.shape(Object).isRequired,
};
