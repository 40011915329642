import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_PRICES, GET_PROJECT } from '../../graphql/queries';

import {
  fetchFixedPrices,
  selectFixedPrice,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/viewofferingAction';
import ViewOfferingFixedPricesTable from '../../components/ViewOffering/ViewOfferingFixedPricesTable';
import Loading from '../../components/Elements/Loading';

function ViewOfferingFixedPricesContainer(props) {
  const {
    templates,
    selectFixedPrice,
    selectProject,
    selectTemplate,
    selectedProject,
  } = props;

  const priceType = 'Festpreis';
  const { loading, error, data } = useQuery(GET_PRICES, {
    variables: { projectId: selectedProject, type: priceType },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }

  return (
    <ViewOfferingFixedPricesTable
      fixedPrices={data.pricesByType}
      projects={data1.project}
      templates={templates}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectFixedPrice={selectFixedPrice}
    />
  );
}

const mapStateToProps = (state) => ({
  fixedPrices: state.viewoffering.fixedPrices,
  projects: state.viewoffering.projects,
  templates: state.viewoffering.templates,
  selectedProject: state.viewoffering.selectedProject,
  loading: state.viewoffering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchFixedPrices: (id) => dispatch(fetchFixedPrices(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectFixedPrice: (id) => dispatch(selectFixedPrice(id)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ViewOfferingFixedPricesContainer),
);

ViewOfferingFixedPricesContainer.propTypes = {
  selectFixedPrice: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
};
