import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { QuestionAnswer, Description, EuroSymbol, AssignmentInd, Schedule } from '@material-ui/icons';
import MUIDataTable from 'mui-datatables';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Chart } from 'chart.js';
import TableButton from '../Buttons/TableButton';
import ExportButton from '../Elements/ExportButton';
import ExportExcelButton from '../Elements/ExportExcelButton';
import DateStamp from '../Elements/DateStamp';
import UploadHandler from '../Elements/UploadHandler';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

Chart.register(ChartDataLabels);

function ViewOfferingProjectsTable(props) {
  const {
    projects,
    selectProject,
    selectedProjectOrigin,
  } = props;

  const backgroundColors = [
    'rgba(7, 97, 130, 0.7)',
    'rgba(3, 50, 66, 0.7)',
    'rgba(10, 145, 194, 0.7)',
    'rgba(10, 154, 207, 0.7)',
    'rgba(8, 126, 168, 0.7)',
    'rgba(12, 183, 245, 0.7)',
    'rgba(5, 78, 105, 0.7)',
    'rgba(7, 107, 143, 0.7)',
  ];

  let highestPhase = 0;
  projects
    .forEach((project) => {
      if (project.phase > highestPhase) {
        highestPhase = project.phase;
      }
    });

  const [select, setSelect] = useState(highestPhase);

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (tableMeta.rowData[9]) {
            return <TableButton
              text={value}
              onClick={() => selectProject(tableMeta.rowData[0])}
            />;
          }
          return <Typography variant="body2" style={{ paddingLeft: '6px' }}>{value}</Typography>;
        },
      },
    },
    {
      name: 'company',
      label: 'Anbieter',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'phase',
      label: 'Phase',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => `${value + 1}. Angebot`,
      },
    },
    {
      name: 'export',
      label: 'Export',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => {
          if (tableMeta.rowData[9]) {
            return <ExportButton
              id={tableMeta.rowData[0]}
              url="/api/v1/export/word/"
            />;
          } return '';
        },
      },
    },
    {
      name: 'attachment',
      label: 'Anhänge',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <UploadHandler
            projectId={tableMeta.rowData[0]}
            referenceId={tableMeta.rowData[0]}
            url="/api/v1/upload/"
            referenceType="project"
            attachments={value}
            disabled
          />
        ),
      },
    },
    {
      name: 'dissenses',
      label: 'Dissense',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'updated',
      label: 'Geändert',
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => (
          <div>
            <DateStamp date={value} />
          </div>
        ),
      },
    },
    {
      name: 'sendIn',
      label: 'Erhalten am',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (tableMeta.rowData[9]) {
            return <DateStamp date={value} />;
          }
          return 'in Bearbeitung';
        },
      },
    },
    {
      name: 'published',
      label: 'Published',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
  ];
  const data = [];
  const filteredProjects = projects
    .filter((project) => project.phase === select);
  filteredProjects
    .forEach((project) => {
      data.push({
        _id: project._id,
        name: project.name,
        company: project.company,
        phase: project.phase,
        dissenses: project.published ? `SOWs: ${project.dissenses} SL: ${project.dissensesSla}` : '',
        description: project.description,
        updated: project.published ? Math.max(...project.changes.map((o) => o.date)) : '',
        attachment: project.published ? project.attachments : [],
        sendIn: project.published ? project.sendIn : '',
        published: project.published,
      });
    });

  const createMenuItems = () => {
    const items = [];
    for (let i = 0; i <= highestPhase; i += 1) {
      items.push(
        <MenuItem key={i} value={i}>
          {i + 1}
        </MenuItem>,
      );
    }
    return items;
  };

  // options for offer overview
  const columnsInformationTable = [
    {
      name: 'company',
      label: 'Anbieter',
      options: {
        display: true,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'references',
      label: 'Referenzen',
      options: {
        display: true,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
  ];

  const options = {
    indexAxis: 'y',
    scales: {
      x: {
        display: false,
        ticks: {
          font: { size: 10 },
          callback(val, index) {
            return index % 2 === 0 ? `${new Intl.NumberFormat('de-DE').format(val / 1000)} k€` : '';
          },
        },
      },
      y: {
        ticks: {
          font: { size: 10 },
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: '', // Placeholder for the title
      },
      legend: {
        display: false,
      },
      datalabels: {
        color: 'black',
        display: true,
        font: {
          size: 10,
          weight: 'bold',
        },
        formatter(val) {
          return val > 0 ? new Intl.NumberFormat('de-DE').format(val) : '';
        },
      },
    },
  };

  const publishedProjects = filteredProjects.filter((project) => project.published);
  let chartLabels = [];
  let duration = [];
  let tcvData = [];
  let yearlyData = [];
  let projectData = [];
  let dissenses = [];
  let dissensesSla = [];
  let avgDailyrate = [];
  let informationTableData = [];

  if (publishedProjects.length > 0) {
    chartLabels = publishedProjects.map((project) => project.company);
    duration = publishedProjects.length > 0 ? projects[0].duration : 0;
    tcvData = publishedProjects.map((project) => project.piecePrices * duration + project.fixedPrices + project.tAndMs);
    yearlyData = publishedProjects.map((project) => project.piecePrices);
    projectData = publishedProjects.map((project) => project.fixedPrices + project.tAndMs);
    dissenses = publishedProjects.map((project) => project.dissenses);
    dissensesSla = publishedProjects.map((project) => project.dissensesSla);
    avgDailyrate = publishedProjects.map((project) => project.ratecards.reduce((accumulator, currentValue) => accumulator + currentValue.dailyrate, 0) / project.ratecards.length);
    informationTableData = publishedProjects.map((project) => ({ company: project.company, references: [...new Set(project.information.filter((info) => info.type === 'Referenz' && info.cluster !== '').map((info) => info.cluster))].join('\r\n') }));
  }
  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', marginTop: 10 }}>
              <Typography color="textPrimary">
                Angebote
                {projects.length > 0 ? `: ${projects[0].originProject.name}` : ''}
              </Typography>
            </div>
            <div style={{ marginLeft: 20 }}>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Phase</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={select}
                  onChange={(e) => setSelect(e.target.value)}
                >
                  {createMenuItems()}
                </Select>
              </FormControl>
              <ExportExcelButton
                projectId={selectedProjectOrigin}
                phase={select}
                type="sows"
                typeText="SOWs"
                icon={<Description />}
              />
              <ExportExcelButton
                projectId={selectedProjectOrigin}
                phase={select}
                type="prices"
                typeText="Preise"
                icon={<EuroSymbol />}
              />
              <ExportExcelButton
                projectId={selectedProjectOrigin}
                phase={select}
                type="sla"
                typeText="Service Level"
                icon={<Schedule />}
              />
              <ExportExcelButton
                projectId={selectedProjectOrigin}
                phase={select}
                type="ratecards"
                typeText="Rate Cards"
                icon={<AssignmentInd />}
              />
              <ExportExcelButton
                projectId={selectedProjectOrigin}
                phase={select}
                type="information"
                typeText="Informationen"
                icon={<QuestionAnswer />}
              />
            </div>
          </div>
        }
        data={data.sort((a, b) => b.sendIn - a.sendIn)}
        columns={columns}
        options={FullsizeTableOptions({ fixedHeader: false })}
      />
      { publishedProjects.length > 0 && (
      <div style={{ marginTop: 10, paddingBottom: 20 }}>
        <Grid container>
          <Grid item xs={6} style={{ backgroundColor: 'white', border: '3px solid #fafafa' }}>
            <div style={{ width: '95%', margin: 'auto', marginTop: 10 }}>
              <Bar
                data={{
                  labels: chartLabels,
                  datasets: [
                    {
                      label: `Total Contract Value (über ${duration} Jahre)`,
                      data: tcvData,
                      backgroundColor: backgroundColors[0],
                      datalabels: {
                        align: 'end',
                        anchor: 'start',
                      },
                    },
                  ],
                }}
                options={{
                  ...options,
                  plugins: {
                    ...options.plugins,
                    title: {
                      ...options.plugins.title,
                      text: `TCV (Projekt + Betriebskosten für ${duration} Jahre)`,
                    },
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={{ backgroundColor: 'white', border: '3px solid #fafafa' }}>
            <div style={{ width: '95%', margin: 'auto', marginTop: 10 }}>
              <Bar
                data={{
                  labels: chartLabels,
                  datasets: [
                    {
                      label: 'Betriebskosten p.a.',
                      data: yearlyData,
                      backgroundColor: backgroundColors[1],
                      datalabels: {
                        align: 'end',
                        anchor: 'start',
                      },
                    },
                  ],
                }}
                options={{
                  ...options,
                  plugins: {
                    ...options.plugins,
                    title: {
                      ...options.plugins.title,
                      text: 'Betriebskosten p.a.',
                    },
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={{ backgroundColor: 'white', border: '3px solid #fafafa' }}>
            <div style={{ width: '95%', margin: 'auto', marginTop: 10 }}>
              <Bar
                data={{
                  labels: chartLabels,
                  datasets: [
                    {
                      label: 'Projektkosten (einmalig)',
                      data: projectData,
                      backgroundColor: backgroundColors[2],
                    },
                  ],
                }}
                options={{
                  ...options,
                  plugins: {
                    ...options.plugins,
                    title: {
                      ...options.plugins.title,
                      text: 'Projektkosten',
                    },
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={{ backgroundColor: 'white', border: '3px solid #fafafa' }}>
            <div style={{ width: '95%', margin: 'auto', marginTop: 10 }}>
              <Bar
                data={{
                  labels: chartLabels,
                  datasets: [
                    {
                      label: 'Mittelwert Tagessätze',
                      data: avgDailyrate,
                      backgroundColor: backgroundColors[4],
                      datalabels: {
                        align: 'end',
                        anchor: 'start',
                      },
                    },
                  ],
                }}
                options={{
                  ...options,
                  scales: {
                    ...options.scales,
                    x: {
                      ...options.scales.x,
                      ticks: {
                        ...options.scales.x.ticks,
                        callback(val, index) {
                          return index % 2 === 0 ? `${new Intl.NumberFormat('de-DE').format(val)} €` : '';
                        },
                      },
                    },
                  },
                  plugins: {
                    ...options.plugins,
                    title: {
                      ...options.plugins.title,
                      text: 'Mittelwert Tagessätze',
                    },
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={{ backgroundColor: 'white', border: '3px solid #fafafa' }}>
            <div style={{ width: '95%', margin: 'auto', marginTop: 10 }}>
              <Bar
                data={{
                  labels: chartLabels,
                  datasets: [
                    {
                      label: 'Dissense SOWs',
                      data: dissenses,
                      backgroundColor: backgroundColors[3],
                    },
                    {
                      label: 'Dissense SLA',
                      data: dissensesSla,
                      backgroundColor: backgroundColors[4],
                    },
                  ],
                }}
                options={{
                  ...options,
                  scales: {
                    ...options.scales,
                    x: {
                      ...options.scales.x,
                      stacked: true,
                      ticks: {
                        ...options.scales.x.ticks,
                        callback(val, index) {
                          return index % 2 === 0 ? new Intl.NumberFormat('de-DE').format(val) : '';
                        },
                      },
                    },
                    y: {
                      ...options.scales.y,
                      stacked: true,
                    },
                  },
                  plugins: {
                    ...options.plugins,
                    title: {
                      ...options.plugins.title,
                      text: 'Dissense',
                    },
                  },
                }}
              />
            </div>
          </Grid>
          <Grid item xs={6} style={{ backgroundColor: 'white', border: '3px solid #fafafa' }}>
            <div style={{ width: '95%', margin: 'auto', marginTop: 10 }}>
              <MUIDataTable
                data={informationTableData}
                columns={columnsInformationTable}
                options={FullsizeTableOptions({ fixedHeader: false, viewColumns: false, filter: false, search: false })}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      )}
    </div>
  );
}

export default ViewOfferingProjectsTable;

ViewOfferingProjectsTable.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  selectedProjectOrigin: PropTypes.string.isRequired,
  selectProject: PropTypes.func.isRequired,
};
