import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useQuery } from '@apollo/react-hooks';
import { GET_PROJECT, GET_TEMPLATES, GET_ADDITIONAL_HEADERS } from '../../graphql/queries';

import {
  selectProject,
  selectTemplate,
  selectSla,
  selectRatecard,
  selectTAndM,
  selectFixedPrice,
  selectPiecePrice,
  selectInformation,
  selectReference,
  selectSubcontractor,
  selectQAndA,
  // fetchTemplates,
  // fetchAdditionalFilesIsShowing,
} from '../../redux/actionCreators/offeringAction';
import Loading from '../../components/Elements/Loading';
import OfferingTemplatesTable from '../../components/OfferingManagement/OfferingTemplatesTable';

function OfferingTemplatesContainer(props) {
  const {
    selectProject,
    selectedProject,
    selectTemplate,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectSubcontractor,
    selectQAndA,
    // fetchTemplates,
    // templates,
    // projects,
    // user,
    // loading,
    // fetchAdditionalFilesIsShowing,
    // isShowing,
  } = props;
  // useEffect(() => {
  //   fetchTemplates(selectedProject);
  // }, [fetchTemplates, selectedProject]);

  const { loading, error, data } = useQuery(GET_TEMPLATES, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading2, error: error2, data: data2 } = useQuery(GET_ADDITIONAL_HEADERS, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  if (loading || loading1 || loading2) return <Loading />;
  if (error || error1 || error2) {
    console.log(error);
    return (
      <p>
        Error :$
        {error + error1 + error2}
      </p>
    );
  }
  return (
    <OfferingTemplatesTable
      projects={data1.project}
      templates={data.templates}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectSla={selectSla}
      selectRatecard={selectRatecard}
      selectTAndM={selectTAndM}
      selectFixedPrice={selectFixedPrice}
      selectPiecePrice={selectPiecePrice}
      selectInformation={selectInformation}
      selectReference={selectReference}
      selectSubcontractor={selectSubcontractor}
      selectQAndA={selectQAndA}
      isShowing={data2.additionalHeaders}
      // user={user}
      // loading={loading}
      // fetchAdditionalFilesIsShowing={fetchAdditionalFilesIsShowing}
      // isShowing={isShowing}
    />
  );
}

const mapStateToProps = (state) => ({
  projects: state.offering.projects,
  selectedProject: state.offering.selectedProject,
  templates: state.offering.templates,
  loading: state.offering.loading,
  user: state.auth.user,
  isShowing: state.offering.isShowing,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (id) => dispatch(selectProject(id)),
  // fetchTemplates: (projectId) => dispatch(fetchTemplates(projectId)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectSla: (id) => dispatch(selectSla(id)),
  selectRatecard: (id) => dispatch(selectRatecard(id)),
  selectTAndM: (id) => dispatch(selectTAndM(id)),
  selectFixedPrice: (id) => dispatch(selectFixedPrice(id)),
  selectPiecePrice: (id) => dispatch(selectPiecePrice(id)),
  selectInformation: (id) => dispatch(selectInformation(id)),
  selectReference: (id) => dispatch(selectReference(id)),
  selectSubcontractor: (id) => dispatch(selectSubcontractor(id)),
  // fetchAdditionalFilesIsShowing: (id) =>
  //   dispatch(fetchAdditionalFilesIsShowing(id)),
  selectQAndA: (id) => dispatch(selectQAndA(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferingTemplatesContainer),
);

OfferingTemplatesContainer.propTypes = {
  // projects: PropTypes.shape(Object).isRequired,
  selectProject: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // fetchTemplates: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectSla: PropTypes.func.isRequired,
  selectRatecard: PropTypes.func.isRequired,
  selectTAndM: PropTypes.func.isRequired,
  selectFixedPrice: PropTypes.func.isRequired,
  selectPiecePrice: PropTypes.func.isRequired,
  selectInformation: PropTypes.func.isRequired,
  selectReference: PropTypes.func.isRequired,
  selectSubcontractor: PropTypes.func.isRequired,
  selectQAndA: PropTypes.func.isRequired,
  // templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // loading: PropTypes.bool.isRequired,
  // user: PropTypes.shape(Object).isRequired,
  // fetchAdditionalFilesIsShowing: PropTypes.func.isRequired,
  // isShowing: PropTypes.arrayOf(PropTypes.string).isRequired,
};
