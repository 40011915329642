import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { resetPassword } from '../../redux/actionCreators/userAction';

import ResetPasswordForm from '../../components/SignInUp/ResetPasswordForm';

const mapStateToProps = (state) => ({
  errors: state.errors,
});

const mapDispatchToProps = (dispatch) => ({
  resetPassword: (email) => dispatch(resetPassword(email)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm),
);
