import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loginUser } from '../../redux/actionCreators/authenticationAction';

import SignInForm from '../../components/SignInUp/SignInForm';

const mapStateToProps = (state) => ({
  errors: state.errors,
});

const mapDispatchToProps = {
  loginUser,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SignInForm),
);
