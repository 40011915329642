import React from 'react';
import PropTypes from 'prop-types';

import OfferingProjectsContainer from '../../container/OfferingManagement/OfferingProjectsContainer';
import OfferingTemplatesContainer from '../../container/OfferingManagement/OfferingTemplatesContainer';
import OfferingModulesContainer from '../../container/OfferingManagement/OfferingModulesContainer';
// import OfferingStatementsContainer from '../../container/OfferingManagement/OfferingStatementsContainer';
import OfferingQAndAsContainer from '../../container/OfferingManagement/OfferingQAndAsContainer';
import OfferingSlasContainer from '../../container/OfferingManagement/OfferingSlasContainer';
import OfferingRatecardContainer from '../../container/OfferingManagement/OfferingRatecardContainer';
import OfferingTAndMsContainer from '../../container/OfferingManagement/OfferingTAndMsContainer';
import OfferingFixedPricesContainer from '../../container/OfferingManagement/OfferingFixedPricesContainer';
import OfferingPiecePricesContainer from '../../container/OfferingManagement/OfferingPiecePricesContainer';
import OfferingInformationContainer from '../../container/OfferingManagement/OfferingInformationContainer';
import OfferingReferencesCompaniesContainer from '../../container/OfferingManagement/OfferingReferencesCompaniesContainer';
// import OfferingReferencesContainer from '../../container/OfferingManagement/OfferingReferencesContainer';
import OfferingSubcontractorsCompaniesContainer from '../../container/OfferingManagement/OfferingSubcontractorsCompaniesContainer';
// import OfferingSubcontractorsContainer from '../../container/OfferingManagement/OfferingSubcontractorsContainer';

import ResponsiveDiv from '../Elements/ResponsiveDiv';

function OfferingPage(props) {
  const {
    selectedProject,
    selectedTemplate,
    // selectedModule,
    selectedSla,
    selectedRatecard,
    selectedTAndM,
    selectedFixedPrice,
    selectedPiecePrice,
    selectedInformation,
    selectedReference,
    // selectedReferenceCompany,
    selectedSubcontractor,
    // selectedSubcontractorCompany,
    selectedQAndA,
  } = props;

  if (selectedQAndA) {
    return (
      <ResponsiveDiv>
        <OfferingQAndAsContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedSla) {
    return (
      <ResponsiveDiv>
        <OfferingSlasContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedRatecard) {
    return (
      <ResponsiveDiv>
        <OfferingRatecardContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedTAndM) {
    return (
      <ResponsiveDiv>
        <OfferingTAndMsContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedFixedPrice) {
    return (
      <ResponsiveDiv>
        <OfferingFixedPricesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedPiecePrice) {
    return (
      <ResponsiveDiv>
        <OfferingPiecePricesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedInformation) {
    return (
      <ResponsiveDiv>
        <OfferingInformationContainer />
      </ResponsiveDiv>
    );
  }

  // if (selectedReferenceCompany) {
  //   return (
  //     <ResponsiveDiv>
  //       <OfferingReferencesContainer />
  //     </ResponsiveDiv>
  //   );
  // }

  if (selectedReference) {
    return (
      <ResponsiveDiv>
        <OfferingReferencesCompaniesContainer />
      </ResponsiveDiv>
    );
  }
  //
  // if (selectedSubcontractorCompany) {
  //   return (
  //     <ResponsiveDiv>
  //       <OfferingSubcontractorsContainer />
  //     </ResponsiveDiv>
  //   );
  // }

  if (selectedSubcontractor) {
    return (
      <ResponsiveDiv>
        <OfferingSubcontractorsCompaniesContainer />
      </ResponsiveDiv>
    );
  }

  // if (selectedModule) {
  //   return (
  //     <ResponsiveDiv>
  //       <OfferingStatementsContainer />
  //     </ResponsiveDiv>
  //   );
  // }

  if (selectedTemplate) {
    return (
      <ResponsiveDiv>
        <OfferingModulesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedProject) {
    return (
      <ResponsiveDiv>
        <OfferingTemplatesContainer />
      </ResponsiveDiv>
    );
  }

  return (
    <ResponsiveDiv>
      <OfferingProjectsContainer />
    </ResponsiveDiv>
  );
}

export default OfferingPage;

OfferingPage.propTypes = {
  selectedProject: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  // selectedModule: PropTypes.string.isRequired,
  selectedSla: PropTypes.string.isRequired,
  selectedRatecard: PropTypes.string.isRequired,
  selectedTAndM: PropTypes.string.isRequired,
  selectedFixedPrice: PropTypes.string.isRequired,
  selectedPiecePrice: PropTypes.string.isRequired,
  selectedInformation: PropTypes.string.isRequired,
  selectedReference: PropTypes.string.isRequired,
  // selectedReferenceCompany: PropTypes.string.isRequired,
  selectedSubcontractor: PropTypes.string.isRequired,
  // selectedSubcontractorCompany: PropTypes.string.isRequired,
  selectedQAndA: PropTypes.string.isRequired,
};
