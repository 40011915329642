import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_INFORMATIONS, GET_PROJECT } from '../../graphql/queries';
import {
  ADD_INFORMATION,
  UPDATE_INFORMATION,
  DELETE_INFORMATION,
  UPDATE_INFORMATION_NUMBER,
} from '../../graphql/mutations';
import {
  // fetchProjectInformation,
  // addProjectInformation,
  // updateProjectInformation,
  // deleteProjectInformation,
  selectInformation,
  selectProject,
  selectTemplate,
  // informationNumberUp,
  // informationNumberDown,
} from '../../redux/actionCreators/projectAction';
import ProjectInformationTable from '../../components/ProjectManagement/ProjectInformationTable';
import Loading from '../../components/Elements/Loading';

function ProjectInformationContainer(props) {
  const {
    // information,
    // projects,
    // fetchProjectInformation,
    // addProjectInformation,
    // updateProjectInformation,
    // deleteProjectInformation,
    selectInformation,
    selectProject,
    selectTemplate,
    selectedProject,
    // loading,
    // user,
    // informationNumberUp,
    // informationNumberDown,
  } = props;
  // useEffect(() => {
  //   fetchProjectInformation(selectedProject);
  // }, [fetchProjectInformation, selectedProject]);

  const informationType = 'Information';
  const cluster = '';
  const { loading, error, data } = useQuery(GET_INFORMATIONS, {
    variables: { projectId: selectedProject, type: informationType, cluster },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [addInformation] = useMutation(ADD_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [updateInformation] = useMutation(UPDATE_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [deleteInformation] = useMutation(DELETE_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [updateInformationNumber] = useMutation(UPDATE_INFORMATION_NUMBER, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }

  return (
    <ProjectInformationTable
      information={data.informations}
      projects={data1.project}
      addProjectInformation={addInformation}
      updateProjectInformation={updateInformation}
      deleteProjectInformation={deleteInformation}
      updateInformationNumber={updateInformationNumber}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectInformation={selectInformation}
      loading={loading}
      type={informationType}
      cluster={cluster}
    />
  );
}

const mapStateToProps = (state) => ({
  information: state.project.information,
  projects: state.project.projects,
  selectedProject: state.project.selectedProject,
  loading: state.project.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchProjectInformation: (projectId) =>
  //   dispatch(fetchProjectInformation(projectId)),
  // addProjectInformation: (projectId, question) =>
  //   dispatch(addProjectInformation(projectId, question)),
  // updateProjectInformation: (id, question) =>
  //   dispatch(updateProjectInformation(id, question)),
  // deleteProjectInformation: (id) => dispatch(deleteProjectInformation(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectInformation: (id) => dispatch(selectInformation(id)),
  // informationNumberUp: (id) => dispatch(informationNumberUp(id)),
  // informationNumberDown: (id) => dispatch(informationNumberDown(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectInformationContainer),
);

ProjectInformationContainer.propTypes = {
  // fetchProjectInformation: PropTypes.func.isRequired,
  // addProjectInformation: PropTypes.func.isRequired,
  // updateProjectInformation: PropTypes.func.isRequired,
  // deleteProjectInformation: PropTypes.func.isRequired,
  selectInformation: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // information: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  // user: PropTypes.shape(Object).isRequired,
  // informationNumberUp: PropTypes.shape(Object).isRequired,
  // informationNumberDown: PropTypes.shape(Object).isRequired,
};
