import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery } from '@apollo/react-hooks';
import { GET_PROJECTS } from '../../graphql/queries';

import {
  // deleteProject,
  selectProject,
  selectProjectOverview,
  // fetchOfferingPrices,
} from '../../redux/actionCreators/viewofferingAction';
import ViewOfferingProjectsTable from '../../components/ViewOffering/ViewOfferingProjectsTable';
import Loading from '../../components/Elements/Loading';

function ViewOfferingProjectsContainer(props) {
  const {
    selectedProjectOverview,
    selectProjectOverview,
    selectedProjectOrigin,
    selectProject,
  } = props;

  const projectType = 'offer';
  const { loading, error, data } = useQuery(GET_PROJECTS, {
    variables: { type: projectType },
    pollInterval: 500,
  });
  if (loading) return <Loading />;
  if (selectedProjectOrigin === '') {
    return (
      <p>
        Kein Projekt ausgewählt.
      </p>
    );
  }
  if (error) {
    return (
      <p>
        Error :$
        {error.message}
      </p>
    );
  }

  return (
    <ViewOfferingProjectsTable
      projects={data.projects.filter((project) => project.originProject._id === selectedProjectOrigin)}
      selectedProjectOverview={selectedProjectOverview}
      selectedProjectOrigin={selectedProjectOrigin}
      selectProjectOverview={selectProjectOverview}
      selectProject={selectProject}
    />
  );
}

const mapStateToProps = (state) => ({
  projects: state.viewoffering.projects,
  templates: state.viewoffering.templates,
  selectedProjectOverview: state.viewoffering.selectedProjectOverview,
  selectedProjectOrigin: state.project.selectedProject,
  loading: state.viewoffering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchProjects: () => dispatch(fetchProjects()),
  // deleteProject: (id) => dispatch(deleteProject(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectProjectOverview: (id) => dispatch(selectProjectOverview(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewOfferingProjectsContainer),
);

ViewOfferingProjectsContainer.propTypes = {
  selectProject: PropTypes.func.isRequired,
  selectProjectOverview: PropTypes.func.isRequired,
  selectedProjectOverview: PropTypes.string.isRequired,
  selectedProjectOrigin: PropTypes.string.isRequired,
};
