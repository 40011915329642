import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PROJECTS } from '../../graphql/queries';
import { ADD_EMPTY_PROJECT, DELETE_PROJECT, UPDATE_PROJECT } from '../../graphql/mutations';

import {
  selectProject,
  // uploadDatabaseProjectFile,
  // removeDatabaseProjectUpload,
} from '../../redux/actionCreators/databaseAction';
import { enqueueSnackbar } from '../../redux/actionCreators/notifierAction';
import DatabaseProjectsTable from '../../components/DatabaseManagement/DatabaseProjectsTable';
import Loading from '../../components/Elements/Loading';

function DatabaseProjectsContainer(props) {
  const {
    selectProject,
    // uploadDatabaseProjectFile,
    // removeDatabaseProjectUpload,
  } = props;

  const projectType = 'database';
  const { loading, error, data } = useQuery(GET_PROJECTS, {
    variables: { type: projectType },
    pollInterval: 500,
  });
  const [addProject] = useMutation(ADD_EMPTY_PROJECT, {
    refetchQueries: [{
      query: GET_PROJECTS,
      variables: { type: projectType },
    }],
  });
  const [deleteProject] = useMutation(DELETE_PROJECT, {
    refetchQueries: [{
      query: GET_PROJECTS,
      variables: { type: projectType },
    }],
  });
  const [updateProject] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [{
      query: GET_PROJECTS,
      variables: { type: projectType },
    }],
  });

  if (loading) return <Loading />;
  if (error) {
    return (
      <p>
        Error :$
        {error.message}
      </p>
    );
  }
  return (
    <DatabaseProjectsTable
      projects={data.projects}
      addProject={addProject}
      updateProject={updateProject}
      deleteProject={deleteProject}
      selectProject={selectProject}
      // uploadDatabaseProjectFile={uploadDatabaseProjectFile}
      // removeDatabaseProjectUpload={removeDatabaseProjectUpload}
      loading={loading}
    />
  );
}

const mapStateToProps = (state) => ({
  projects: state.database.projects,
  loading: state.database.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (id) => dispatch(selectProject(id)),
  // uploadDatabaseProjectFile: (formData, config) =>
  //   dispatch(uploadDatabaseProjectFile(formData, config)),
  // removeDatabaseProjectUpload: (id) =>
  //   dispatch(removeDatabaseProjectUpload(id)),
  enqueueSnackbar: (notification) => dispatch(enqueueSnackbar(notification)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DatabaseProjectsContainer),
);

DatabaseProjectsContainer.propTypes = {
  selectProject: PropTypes.func.isRequired,
  // uploadDatabaseProjectFile: PropTypes.func.isRequired,
  // removeDatabaseProjectUpload: PropTypes.func.isRequired,
};
