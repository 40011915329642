import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_INFORMATIONS, GET_PROJECT } from '../../graphql/queries';
import {
  UPDATE_INFORMATION_CLUSTER, ADD_INFORMATION_CLUSTER, DELETE_INFORMATION_CLUSTER, UPDATE_INFORMATION,
} from '../../graphql/mutations';
import {
  selectReference,
  selectOfferingReferenceCompany,
  selectProject,
  selectTemplate,
  // fetchOfferingReferences,
  // addOfferingReferenceCompany,
  // updateOfferingReferenceCompany,
  // deleteOfferingReferenceCompany,
} from '../../redux/actionCreators/offeringAction';

import OfferingReferencesCompaniesTable from '../../components/OfferingManagement/OfferingReferencesCompaniesTable';
import OfferingReferencesTable from '../../components/OfferingManagement/OfferingReferencesTable';
import Loading from '../../components/Elements/Loading';

function OfferingReferencesCompaniesContainer(props) {
  const {
    selectReference,
    selectOfferingReferenceCompany,
    selectProject,
    selectTemplate,
    selectedProject,
    selectedReferenceCompany,
  } = props;

  const informationType = 'Referenz';
  const { loading, error, data } = useQuery(GET_INFORMATIONS, {
    variables: { projectId: selectedProject, type: informationType },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [addInformationCluster] = useMutation(ADD_INFORMATION_CLUSTER, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType },
    }],
  });

  const [updateInformationCluster] = useMutation(UPDATE_INFORMATION_CLUSTER, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType },
    }],
  });

  const [deleteInformationCluster] = useMutation(DELETE_INFORMATION_CLUSTER, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType },
    }],
  });

  const [updateInformation] = useMutation(UPDATE_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  if (selectedReferenceCompany === '') {
    return (
      <OfferingReferencesCompaniesTable
        selectReference={selectReference}
        selectOfferingReferenceCompany={selectOfferingReferenceCompany}
        selectProject={selectProject}
        selectTemplate={selectTemplate}
        addOfferingReferenceCompany={addInformationCluster}
        updateOfferingReferenceCompany={updateInformationCluster}
        deleteOfferingReferenceCompany={deleteInformationCluster}
        references={data.informations}
        projects={data1.project}
        selectedProject={selectedProject}
      />
    );
  }
  return (
    <OfferingReferencesTable
      selectReference={selectReference}
      selectOfferingReferenceCompany={selectOfferingReferenceCompany}
      selectedReferenceCompany={selectedReferenceCompany}
      selectProject={selectProject}
      selectTemplate={selectTemplate}
      updateOfferingReference={updateInformation}
      references={data.informations}
      projects={data1.project}
      selectedProject={selectedProject}
    />
  );
}

const mapStateToProps = (state) => ({
  references: state.offering.references,
  projects: state.offering.projects,
  templates: state.offering.templates,
  modules: state.offering.modules,
  selectedProject: state.offering.selectedProject,
  selectedReference: state.offering.selectedReference,
  selectedReferenceCompany: state.offering.selectedReferenceCompany,
  loading: state.offering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  selectReference: (id) => dispatch(selectReference(id)),
  selectOfferingReferenceCompany: (company) =>
    dispatch(selectOfferingReferenceCompany(company)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(OfferingReferencesCompaniesContainer),
);

OfferingReferencesCompaniesContainer.propTypes = {
  selectReference: PropTypes.func.isRequired,
  selectOfferingReferenceCompany: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectedReferenceCompany: PropTypes.string.isRequired,
};
