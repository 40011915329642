import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import SignInPage from '../../components/SignInUp/SignInPage';

function SignInContainer(props) {
  const { isAuthenticated, history } = props;
  useEffect(() => {
    if (isAuthenticated) {
      history.push('/main');
    }
  }, [isAuthenticated, history]);

  return <SignInPage />;
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default withRouter(connect(mapStateToProps)(SignInContainer));

SignInContainer.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  history: PropTypes.shape(Object).isRequired,
};
