import {
  CREATE_OFFERING_BEGIN,
  CREATE_OFFERING_SUCCESS,
  CREATE_OFFERING_FAILURE,
  OFFERING_FETCH_PROJECTS_BEGIN,
  OFFERING_FETCH_PROJECTS_SUCCESS,
  OFFERING_FETCH_PROJECTS_FAILURE,
  OFFERING_FETCH_TEMPLATES_BEGIN,
  OFFERING_FETCH_TEMPLATES_SUCCESS,
  OFFERING_FETCH_TEMPLATES_FAILURE,
  OFFERING_FETCH_MODULES_BEGIN,
  OFFERING_FETCH_MODULES_SUCCESS,
  OFFERING_FETCH_MODULES_FAILURE,
  OFFERING_FETCH_STATEMENTS_BEGIN,
  OFFERING_FETCH_STATEMENTS_SUCCESS,
  OFFERING_FETCH_STATEMENTS_FAILURE,
  OFFERING_CLEAR_PROJECTS_ARRAY,
  OFFERING_CLEAR_TEMPLATES_ARRAY,
  OFFERING_CLEAR_MODULES_ARRAY,
  OFFERING_CLEAR_STATEMENTS_ARRAY,
  OFFERING_SELECT_PROJECT,
  OFFERING_SELECT_TEMPLATE,
  OFFERING_SELECT_MODULE,
  OFFERING_SELECT_STATEMENT,
  UPDATE_OFFERING_STATEMENT_COMPLIANCE_BEGIN,
  UPDATE_OFFERING_STATEMENT_COMPLIANCE_SUCCESS,
  UPDATE_OFFERING_STATEMENT_COMPLIANCE_FAILURE,
  UPDATE_SUGGESTION_BEGIN,
  UPDATE_SUGGESTION_SUCCESS,
  UPDATE_SUGGESTION_FAILURE,
  DELETE_OFFERING_BEGIN,
  DELETE_OFFERING_SUCCESS,
  DELETE_OFFERING_FAILURE,
  PUBLISH_OFFERING_BEGIN,
  PUBLISH_OFFERING_SUCCESS,
  PUBLISH_OFFERING_FAILURE,
  SELECT_ASSUMPTION,
  SELECT_PROVISION,
  FETCH_ASSUMPTIONS_BEGIN,
  FETCH_ASSUMPTIONS_SUCCESS,
  FETCH_ASSUMPTIONS_FAILURE,
  FETCH_PROVISIONS_BEGIN,
  FETCH_PROVISIONS_SUCCESS,
  FETCH_PROVISIONS_FAILURE,
  DELETE_ASSUMPTIONS_BEGIN,
  DELETE_ASSUMPTIONS_SUCCESS,
  DELETE_ASSUMPTIONS_FAILURE,
  DELETE_PROVISIONS_BEGIN,
  DELETE_PROVISIONS_SUCCESS,
  DELETE_PROVISIONS_FAILURE,
  ADD_ASSUMPTION_BEGIN,
  ADD_ASSUMPTION_SUCCESS,
  ADD_ASSUMPTION_FAILURE,
  ADD_PROVISION_BEGIN,
  ADD_PROVISION_SUCCESS,
  ADD_PROVISION_FAILURE,
  FETCH_OFFERING_SLAS_BEGIN,
  FETCH_OFFERING_SLAS_SUCCESS,
  FETCH_OFFERING_SLAS_FAILURE,
  CLEAR_OFFERING_SLAS_ARRAY,
  UPDATE_OFFERING_SLA_BEGIN,
  UPDATE_OFFERING_SLA_SUCCESS,
  UPDATE_OFFERING_SLA_FAILURE,
  SELECT_OFFERING_SLA,
  FETCH_OFFERING_RATECARDS_BEGIN,
  FETCH_OFFERING_RATECARDS_SUCCESS,
  FETCH_OFFERING_RATECARDS_FAILURE,
  FETCH_OFFERING_FIXEDPRICES_BEGIN,
  FETCH_OFFERING_FIXEDPRICES_SUCCESS,
  FETCH_OFFERING_FIXEDPRICES_FAILURE,
  FETCH_OFFERING_PIECEPRICES_BEGIN,
  FETCH_OFFERING_PIECEPRICES_SUCCESS,
  FETCH_OFFERING_PIECEPRICES_FAILURE,
  FETCH_OFFERING_TANDMS_BEGIN,
  FETCH_OFFERING_TANDMS_SUCCESS,
  FETCH_OFFERING_TANDMS_FAILURE,
  UPDATE_OFFERING_PIECEPRICE_BEGIN,
  UPDATE_OFFERING_PIECEPRICE_SUCCESS,
  UPDATE_OFFERING_PIECEPRICE_FAILURE,
  SELECT_OFFERING_RATECARD,
  SELECT_OFFERING_FIXEDPRICE,
  SELECT_OFFERING_PIECEPRICE,
  SELECT_OFFERING_TANDM,
  SELECT_OFFERING_REFERENCE,
  UPDATE_OFFERING_SLA_COMPLIANCE_BEGIN,
  UPDATE_OFFERING_SLA_COMPLIANCE_SUCCESS,
  UPDATE_OFFERING_SLA_COMPLIANCE_FAILURE,
  UPLOAD_OFFERING_PROJECT_FILE_BEGIN,
  UPLOAD_OFFERING_PROJECT_FILE_SUCCESS,
  UPLOAD_OFFERING_PROJECT_FILE_FAILURE,
  REMOVE_OFFERING_PROJECT_UPLOADS_BEGIN,
  REMOVE_OFFERING_PROJECT_UPLOADS_SUCCESS,
  REMOVE_OFFERING_PROJECT_UPLOADS_FAILURE,
  UPDATE_TANDM_BEGIN,
  UPDATE_TANDM_SUCCESS,
  UPDATE_TANDM_FAILURE,
  UPDATE_FIXEDPRICE_BEGIN,
  UPDATE_FIXEDPRICE_SUCCESS,
  UPDATE_FIXEDPRICE_FAILURE,
  ADD_TANDM_BEGIN,
  ADD_TANDM_SUCCESS,
  ADD_TANDM_FAILURE,
  DELETE_TANDM_BEGIN,
  DELETE_TANDM_SUCCESS,
  DELETE_TANDM_FAILURE,
  SELECT_OFFERING_INFORMATION,
  FETCH_OFFERING_INFORMATION_BEGIN,
  FETCH_OFFERING_INFORMATION_SUCCESS,
  FETCH_OFFERING_INFORMATION_FAILURE,
  UPDATE_OFFERING_INFORMATION_BEGIN,
  UPDATE_OFFERING_INFORMATION_SUCCESS,
  UPDATE_OFFERING_INFORMATION_FAILURE,
  FETCH_OFFERING_EDITORS_BEGIN,
  FETCH_OFFERING_EDITORS_SUCCESS,
  FETCH_OFFERING_EDITORS_FAILURE,
  UPDATE_OFFERING_EDITORS_BEGIN,
  UPDATE_OFFERING_EDITORS_SUCCESS,
  UPDATE_OFFERING_EDITORS_FAILURE,
  FETCH_OFFERING_VIEWERS_BEGIN,
  FETCH_OFFERING_VIEWERS_SUCCESS,
  FETCH_OFFERING_VIEWERS_FAILURE,
  UPDATE_OFFERING_VIEWERS_BEGIN,
  UPDATE_OFFERING_VIEWERS_SUCCESS,
  UPDATE_OFFERING_VIEWERS_FAILURE,
  FETCH_OFFERING_REFERENCES_BEGIN,
  FETCH_OFFERING_REFERENCES_SUCCESS,
  FETCH_OFFERING_REFERENCES_FAILURE,
  ADD_OFFERING_REFERENCE_COMPANY_BEGIN,
  ADD_OFFERING_REFERENCE_COMPANY_SUCCESS,
  ADD_OFFERING_REFERENCE_COMPANY_FAILURE,
  SELECT_OFFERING_REFERENCE_COMPANY,
  DELETE_OFFERING_REFERENCE_COMPANY_BEGIN,
  DELETE_OFFERING_REFERENCE_COMPANY_SUCCESS,
  DELETE_OFFERING_REFERENCE_COMPANY_FAILURE,
  UPDATE_OFFERING_REFERENCE_COMPANY_BEGIN,
  UPDATE_OFFERING_REFERENCE_COMPANY_SUCCESS,
  UPDATE_OFFERING_REFERENCE_COMPANY_FAILURE,
  UPDATE_OFFERING_REFERENCE_BEGIN,
  UPDATE_OFFERING_REFERENCE_SUCCESS,
  UPDATE_OFFERING_REFERENCE_FAILURE,
  SELECT_OFFERING_SUBCONTRACTOR,
  FETCH_OFFERING_SUBCONTRACTORS_BEGIN,
  FETCH_OFFERING_SUBCONTRACTORS_SUCCESS,
  FETCH_OFFERING_SUBCONTRACTORS_FAILURE,
  ADD_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN,
  ADD_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS,
  ADD_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE,
  SELECT_OFFERING_SUBCONTRACTOR_COMPANY,
  DELETE_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN,
  DELETE_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS,
  DELETE_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE,
  UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN,
  UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS,
  UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE,
  UPDATE_OFFERING_SUBCONTRACTOR_BEGIN,
  UPDATE_OFFERING_SUBCONTRACTOR_SUCCESS,
  UPDATE_OFFERING_SUBCONTRACTOR_FAILURE,
  FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_BEGIN,
  FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_SUCCESS,
  FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_FAILURE,
  SELECT_OFFERING_QANDA,
  FETCH_OFFERING_QANDAS_BEGIN,
  FETCH_OFFERING_QANDAS_SUCCESS,
  FETCH_OFFERING_QANDAS_FAILURE,
  ADD_OFFERING_QANDA_BEGIN,
  ADD_OFFERING_QANDA_SUCCESS,
  ADD_OFFERING_QANDA_FAILURE,
  UPDATE_OFFERING_QANDA_BEGIN,
  UPDATE_OFFERING_QANDA_SUCCESS,
  UPDATE_OFFERING_QANDA_FAILURE,
  DELETE_OFFERING_QANDA_BEGIN,
  DELETE_OFFERING_QANDA_SUCCESS,
  DELETE_OFFERING_QANDA_FAILURE,
  UPLOAD_OFFERING_INFORMATION_FILE_BEGIN,
  UPLOAD_OFFERING_INFORMATION_FILE_SUCCESS,
  UPLOAD_OFFERING_INFORMATION_FILE_FAILURE,
  REMOVE_OFFERING_INFORMATION_UPLOADS_BEGIN,
  REMOVE_OFFERING_INFORMATION_UPLOADS_SUCCESS,
  REMOVE_OFFERING_INFORMATION_UPLOADS_FAILURE,
} from '../actionTypes/actionTypes';

const initialstate = {
  publishedProjects: [],
  projects: [],
  templates: [],
  modules: [],
  statements: [],
  qandas: [],
  assumptions: [],
  provisions: [],
  slas: [],
  ratecards: [],
  fixedPrices: [],
  piecePrices: [],
  tandms: [],
  information: [],
  references: [],
  subcontractors: [],
  editors: [],
  viewers: [],
  selectedProject: '',
  selectedTemplate: '',
  selectedModule: '',
  selectedStatement: '',
  selectedAssumption: '',
  selectedProvision: '',
  selectedSla: '',
  selectedRatecard: '',
  selectedFixedPrice: '',
  selectedPiecePrice: '',
  selectedTAndM: '',
  selectedInformation: '',
  selectedReference: '',
  selectedSubcontractor: '',
  selectedReferenceCompany: '',
  selectedSubcontractorCompany: '',
  selectedQAndA: '',
  loading: false,
  error: null,
  isShowing: [],
};

export default (state = initialstate, action) => {
  switch (action.type) {
    case SELECT_OFFERING_QANDA:
      return {
        ...state,
        selectedQAndA: action.payload.qanda,
      };
    case ADD_OFFERING_QANDA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_OFFERING_QANDA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_OFFERING_QANDA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_OFFERING_QANDA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_OFFERING_QANDA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_OFFERING_QANDA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_OFFERING_QANDA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_OFFERING_QANDA_SUCCESS:
      return {
        ...state,
        loading: false,
        qandas: [
          ...state.qandas.filter((qanda) => qanda._id !== action.payload.qanda),
        ],
      };
    case DELETE_OFFERING_QANDA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_OFFERING_QANDAS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OFFERING_QANDAS_SUCCESS:
      return {
        ...state,
        loading: false,
        qandas: action.payload.qandas,
      };
    case FETCH_OFFERING_QANDAS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        qandas: [],
      };
    case UPDATE_OFFERING_SUBCONTRACTOR_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_OFFERING_SUBCONTRACTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        subcontractors: [
          ...state.subcontractors.map((subcontractor) =>
            subcontractor._id === action.payload.subcontractor._id
              ? {
                _id: action.payload.subcontractor._id,
                number: action.payload.subcontractor.number,
                company: action.payload.subcontractor.company,
                question: action.payload.subcontractor.question,
                answer: action.payload.subcontractor.answer,
                project: action.payload.subcontractor.project,
              }
              : subcontractor),
        ],
      };
    case UPDATE_OFFERING_SUBCONTRACTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        subcontractors: [],
      };
    case UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        subcontractors: action.payload.subcontractors,
      };
    case UPDATE_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        subcontractors: [],
      };
    case DELETE_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        subcontractors: [
          ...state.subcontractors.filter(
            (subcontractor) =>
              subcontractor.company
              !== action.payload.subcontractors[0].company,
          ),
        ],
      };
    case DELETE_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        subcontractors: [],
      };
    case SELECT_OFFERING_SUBCONTRACTOR_COMPANY:
      return {
        ...state,
        selectedSubcontractorCompany: action.payload.company,
      };
    case ADD_OFFERING_SUBCONTRACTOR_COMPANY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_OFFERING_SUBCONTRACTOR_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        subcontractors: [...state.subcontractors].concat(
          action.payload.subcontractors,
        ),
      };
    case ADD_OFFERING_SUBCONTRACTOR_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        subcontractors: [],
      };
    case FETCH_OFFERING_SUBCONTRACTORS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OFFERING_SUBCONTRACTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        subcontractors: action.payload.subcontractors,
      };
    case FETCH_OFFERING_SUBCONTRACTORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        subcontractors: [],
      };

    case UPDATE_OFFERING_REFERENCE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_OFFERING_REFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        references: [
          ...state.references.map((reference) =>
            reference._id === action.payload.reference._id
              ? {
                _id: action.payload.reference._id,
                number: action.payload.reference.number,
                company: action.payload.reference.company,
                question: action.payload.reference.question,
                answer: action.payload.reference.answer,
                project: action.payload.reference.project,
              }
              : reference),
        ],
      };
    case UPDATE_OFFERING_REFERENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        references: [],
      };
    case UPDATE_OFFERING_REFERENCE_COMPANY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_OFFERING_REFERENCE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        references: action.payload.references,
      };
    case UPDATE_OFFERING_REFERENCE_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        references: [],
      };
    case DELETE_OFFERING_REFERENCE_COMPANY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_OFFERING_REFERENCE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        references: [
          ...state.references.filter(
            (reference) =>
              reference.company !== action.payload.references[0].company,
          ),
        ],
      };
    case DELETE_OFFERING_REFERENCE_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        references: [],
      };
    case SELECT_OFFERING_REFERENCE_COMPANY:
      return {
        ...state,
        selectedReferenceCompany: action.payload.company,
      };
    case ADD_OFFERING_REFERENCE_COMPANY_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_OFFERING_REFERENCE_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        references: [...state.references].concat(action.payload.references),
      };
    case ADD_OFFERING_REFERENCE_COMPANY_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        references: [],
      };
    case FETCH_OFFERING_REFERENCES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OFFERING_REFERENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        references: action.payload.references,
      };
    case FETCH_OFFERING_REFERENCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        references: [],
      };
    case UPDATE_OFFERING_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_OFFERING_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_OFFERING_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_OFFERING_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OFFERING_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        information: action.payload.information,
      };
    case FETCH_OFFERING_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        information: [],
      };
    case DELETE_TANDM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_TANDM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_TANDM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_TANDM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_TANDM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_TANDM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_FIXEDPRICE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_FIXEDPRICE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_FIXEDPRICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_TANDM_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_TANDM_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_TANDM_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPLOAD_OFFERING_PROJECT_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_OFFERING_PROJECT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_OFFERING_PROJECT_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_OFFERING_PROJECT_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_OFFERING_PROJECT_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_OFFERING_PROJECT_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_OFFERING_SLA_COMPLIANCE_BEGIN:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case UPDATE_OFFERING_SLA_COMPLIANCE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_OFFERING_SLA_COMPLIANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SELECT_OFFERING_RATECARD:
      return {
        ...state,
        selectedRatecard: action.payload.id,
      };
    case SELECT_OFFERING_FIXEDPRICE:
      return {
        ...state,
        selectedFixedPrice: action.payload.id,
      };
    case SELECT_OFFERING_PIECEPRICE:
      return {
        ...state,
        selectedPiecePrice: action.payload.id,
      };
    case SELECT_OFFERING_TANDM:
      return {
        ...state,
        selectedTAndM: action.payload.id,
      };
    case SELECT_OFFERING_INFORMATION:
      return {
        ...state,
        selectedInformation: action.payload.id,
      };
    case SELECT_OFFERING_REFERENCE:
      return {
        ...state,
        selectedReference: action.payload.id,
      };
    case SELECT_OFFERING_SUBCONTRACTOR:
      return {
        ...state,
        selectedSubcontractor: action.payload.id,
      };
    case UPDATE_OFFERING_PIECEPRICE_BEGIN:
      return {
        ...state,
        lodaing: true,
        error: null,
      };
    case UPDATE_OFFERING_PIECEPRICE_SUCCESS:
      return {
        ...state,
        lodaing: false,
      };
    case UPDATE_OFFERING_PIECEPRICE_FAILURE:
      return {
        ...state,
        lodaing: false,
        error: action.payload.error,
      };
    case FETCH_OFFERING_RATECARDS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OFFERING_RATECARDS_SUCCESS:
      return {
        ...state,
        loading: false,
        ratecards: action.payload.ratecards,
      };
    case FETCH_OFFERING_RATECARDS_FAILURE:
      return {
        ...state,
        loading: false,
        ratecards: [],
        error: action.payload.error,
      };
    case FETCH_OFFERING_FIXEDPRICES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OFFERING_FIXEDPRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        fixedPrices: action.payload.fixedPrices,
      };
    case FETCH_OFFERING_FIXEDPRICES_FAILURE:
      return {
        ...state,
        loading: false,
        fixedPrices: [],
        error: action.payload.error,
      };
    case FETCH_OFFERING_PIECEPRICES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OFFERING_PIECEPRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        piecePrices: action.payload.piecePrices,
      };
    case FETCH_OFFERING_PIECEPRICES_FAILURE:
      return {
        ...state,
        loading: false,
        piecePrices: [],
        error: action.payload.error,
      };
    case FETCH_OFFERING_TANDMS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OFFERING_TANDMS_SUCCESS:
      return {
        ...state,
        loading: false,
        tandms: action.payload.tandms,
      };
    case FETCH_OFFERING_TANDMS_FAILURE:
      return {
        ...state,
        loading: false,
        tandms: [],
        error: action.payload.error,
      };
    case SELECT_OFFERING_SLA:
      return {
        ...state,
        selectedSla: action.payload.id,
      };
    case UPDATE_OFFERING_SLA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_OFFERING_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_OFFERING_SLA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CLEAR_OFFERING_SLAS_ARRAY:
      return {
        ...state,
        slas: [],
      };
    case FETCH_OFFERING_SLAS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OFFERING_SLAS_SUCCESS:
      return {
        ...state,
        loading: false,
        slas: action.payload.slas,
      };
    case FETCH_OFFERING_SLAS_FAILURE:
      return {
        ...state,
        loading: false,
        slas: [],
        error: action.payload.error,
      };
    case ADD_ASSUMPTION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_ASSUMPTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_ASSUMPTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_PROVISION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PROVISION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_PROVISION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_ASSUMPTIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_ASSUMPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_ASSUMPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_PROVISIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PROVISIONS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_PROVISIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_ASSUMPTIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_ASSUMPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        assumptions: action.payload.assumptions,
      };
    case FETCH_ASSUMPTIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_PROVISIONS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROVISIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        provisions: action.payload.provisions,
      };
    case FETCH_PROVISIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SELECT_ASSUMPTION:
      return {
        ...state,
        selectedAssumption: action.payload.id,
      };
    case SELECT_PROVISION:
      return {
        ...state,
        selectedProvision: action.payload.id,
      };
    case PUBLISH_OFFERING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case PUBLISH_OFFERING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case PUBLISH_OFFERING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_OFFERING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_OFFERING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_OFFERING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_SUGGESTION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_SUGGESTION_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_SUGGESTION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_OFFERING_STATEMENT_COMPLIANCE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_OFFERING_STATEMENT_COMPLIANCE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_OFFERING_STATEMENT_COMPLIANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case OFFERING_FETCH_PROJECTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case OFFERING_FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: action.payload.projects,
      };
    case OFFERING_FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        projects: [],
      };
    case OFFERING_FETCH_TEMPLATES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case OFFERING_FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload.templates,
      };
    case OFFERING_FETCH_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        statements: [],
      };
    case OFFERING_FETCH_MODULES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case OFFERING_FETCH_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        modules: action.payload.modules,
      };
    case OFFERING_FETCH_MODULES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        modules: [],
      };
    case OFFERING_FETCH_STATEMENTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case OFFERING_FETCH_STATEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        statements: action.payload.statements,
      };
    case OFFERING_FETCH_STATEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        statements: [],
      };
    case OFFERING_CLEAR_STATEMENTS_ARRAY:
      return {
        ...state,
        statements: [],
      };
    case OFFERING_CLEAR_MODULES_ARRAY:
      return {
        ...state,
        modules: [],
      };
    case OFFERING_CLEAR_TEMPLATES_ARRAY:
      return {
        ...state,
        templates: [],
      };
    case OFFERING_CLEAR_PROJECTS_ARRAY:
      return {
        ...state,
        projects: [],
      };
    case OFFERING_SELECT_PROJECT:
      return {
        ...state,
        selectedProject: action.payload.project,
      };
    case OFFERING_SELECT_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload.template,
      };
    case OFFERING_SELECT_MODULE:
      return {
        ...state,
        selectedModule: action.payload.module,
      };
    case OFFERING_SELECT_STATEMENT:
      return {
        ...state,
        selectedStatement: action.payload.statement,
      };
    case CREATE_OFFERING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_OFFERING_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case CREATE_OFFERING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_OFFERING_EDITORS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OFFERING_EDITORS_SUCCESS:
      return {
        ...state,
        loading: false,
        editors: action.payload.editors,
      };
    case FETCH_OFFERING_EDITORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_OFFERING_EDITORS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_OFFERING_EDITORS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_OFFERING_EDITORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_OFFERING_VIEWERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OFFERING_VIEWERS_SUCCESS:
      return {
        ...state,
        loading: false,
        viewers: action.payload.viewers,
      };
    case FETCH_OFFERING_VIEWERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_OFFERING_VIEWERS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_OFFERING_VIEWERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_OFFERING_VIEWERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_SUCCESS:
      return {
        ...state,
        loading: false,
        isShowing: action.payload.isShowing,
      };
    case FETCH_OFFERING_ADDITIONAL_FILES_IS_SHOWING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        isShowing: [true, true, true, true, true, true, true, true],
      };
    case UPLOAD_OFFERING_INFORMATION_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_OFFERING_INFORMATION_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_OFFERING_INFORMATION_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_OFFERING_INFORMATION_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_OFFERING_INFORMATION_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_OFFERING_INFORMATION_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    default:
      return state;
  }
};
