import axios from 'axios';
import {
  CLEAR_VIEWOFFERING_SLAS_ARRAY,
  DELETE_VIEWOFFERING_PROJECT_BEGIN,
  DELETE_VIEWOFFERING_PROJECT_FAILURE,
  DELETE_VIEWOFFERING_PROJECT_SUCCESS,
  FETCH_VIEWOFFERING_ASSUMPTIONS_BEGIN,
  FETCH_VIEWOFFERING_ASSUMPTIONS_FAILURE,
  FETCH_VIEWOFFERING_ASSUMPTIONS_SUCCESS,
  FETCH_VIEWOFFERING_FIXEDPRICES_BEGIN,
  FETCH_VIEWOFFERING_FIXEDPRICES_FAILURE,
  FETCH_VIEWOFFERING_FIXEDPRICES_SUCCESS,
  FETCH_VIEWOFFERING_INFORMATION_BEGIN,
  FETCH_VIEWOFFERING_INFORMATION_FAILURE,
  FETCH_VIEWOFFERING_INFORMATION_SUCCESS,
  FETCH_VIEWOFFERING_PIECEPRICES_BEGIN,
  FETCH_VIEWOFFERING_PIECEPRICES_FAILURE,
  FETCH_VIEWOFFERING_PIECEPRICES_SUCCESS,
  FETCH_VIEWOFFERING_PROVISIONS_BEGIN,
  FETCH_VIEWOFFERING_PROVISIONS_FAILURE,
  FETCH_VIEWOFFERING_PROVISIONS_SUCCESS,
  FETCH_VIEWOFFERING_RATECARDS_BEGIN,
  FETCH_VIEWOFFERING_RATECARDS_FAILURE,
  FETCH_VIEWOFFERING_RATECARDS_SUCCESS,
  FETCH_VIEWOFFERING_SLAS_BEGIN,
  FETCH_VIEWOFFERING_SLAS_FAILURE,
  FETCH_VIEWOFFERING_SLAS_SUCCESS,
  FETCH_VIEWOFFERING_TANDMS_BEGIN,
  FETCH_VIEWOFFERING_TANDMS_FAILURE,
  FETCH_VIEWOFFERING_TANDMS_SUCCESS,
  VIEWOFFERING_CLEAR_MODULES_ARRAY,
  VIEWOFFERING_CLEAR_PROJECTS_ARRAY,
  VIEWOFFERING_CLEAR_STATEMENTS_ARRAY,
  VIEWOFFERING_CLEAR_TEMPLATES_ARRAY,
  VIEWOFFERING_FETCH_MODULES_BEGIN,
  VIEWOFFERING_FETCH_MODULES_FAILURE,
  VIEWOFFERING_FETCH_MODULES_SUCCESS,
  VIEWOFFERING_FETCH_PROJECTS_BEGIN,
  VIEWOFFERING_FETCH_PROJECTS_FAILURE,
  VIEWOFFERING_FETCH_PROJECTS_SUCCESS,
  VIEWOFFERING_FETCH_STATEMENTS_BEGIN,
  VIEWOFFERING_FETCH_STATEMENTS_FAILURE,
  VIEWOFFERING_FETCH_STATEMENTS_SUCCESS,
  VIEWOFFERING_FETCH_TEMPLATES_BEGIN,
  VIEWOFFERING_FETCH_TEMPLATES_FAILURE,
  VIEWOFFERING_FETCH_TEMPLATES_SUCCESS,
  VIEWOFFERING_SELECT_MODULE,
  VIEWOFFERING_SELECT_PROJECT,
  VIEWOFFERING_SELECT_STATEMENT,
  VIEWOFFERING_SELECT_TEMPLATE,
  SELECT_VIEWOFFERING_ASSUMPTION,
  SELECT_VIEWOFFERING_FIXEDPRICE,
  SELECT_VIEWOFFERING_INFORMATION,
  SELECT_VIEWOFFERING_PIECEPRICE,
  SELECT_VIEWOFFERING_PROVISION,
  SELECT_VIEWOFFERING_RATECARD,
  SELECT_VIEWOFFERING_REFERENCE,
  SELECT_VIEWOFFERING_SLA,
  SELECT_VIEWOFFERING_SUBCONTRACTOR,
  SELECT_VIEWOFFERING_TANDM,
  FETCH_VIEWOFFERING_REFERENCES_BEGIN,
  FETCH_VIEWOFFERING_REFERENCES_SUCCESS,
  FETCH_VIEWOFFERING_REFERENCES_FAILURE,
  SELECT_VIEWOFFERING_REFERENCE_COMPANY,
  FETCH_VIEWOFFERING_SUBCONTRACTORS_BEGIN,
  FETCH_VIEWOFFERING_SUBCONTRACTORS_SUCCESS,
  FETCH_VIEWOFFERING_SUBCONTRACTORS_FAILURE,
  SELECT_VIEWOFFERING_SUBCONTRACTOR_COMPANY,
  SELECT_VIEWOFFERING_PROJECTOVERVIEW,
  FETCH_OFFERING_PRICES_BEGIN,
  FETCH_OFFERING_PRICES_SUCCESS,
  FETCH_OFFERING_PRICES_FAILURE,
} from '../actionTypes/actionTypes';

export const selectViewOfferingSubcontractorCompany = (company) => ({
  type: SELECT_VIEWOFFERING_SUBCONTRACTOR_COMPANY,
  payload: { company },
});

export const fetchViewOfferingSubcontractorsBegin = () => ({
  type: FETCH_VIEWOFFERING_SUBCONTRACTORS_BEGIN,
});

export const fetchViewOfferingSubcontractorsSuccess = (subcontractors) => ({
  type: FETCH_VIEWOFFERING_SUBCONTRACTORS_SUCCESS,
  payload: { subcontractors },
});

export const fetchViewOfferingSubcontractorsFailure = (error) => ({
  type: FETCH_VIEWOFFERING_SUBCONTRACTORS_FAILURE,
  payload: { error },
});

export const fetchOfferingPricesBegin = () => ({
  type: FETCH_OFFERING_PRICES_BEGIN,
});

export const fetchOfferingPricesSuccess = (prices) => ({
  type: FETCH_OFFERING_PRICES_SUCCESS,
  payload: { prices },
});

export const fetchOfferingPricesFailure = (error) => ({
  type: FETCH_OFFERING_PRICES_FAILURE,
  payload: { error },
});

export const selectViewOfferingReferenceCompany = (company) => ({
  type: SELECT_VIEWOFFERING_REFERENCE_COMPANY,
  payload: { company },
});

export const fetchViewOfferingReferencesBegin = () => ({
  type: FETCH_VIEWOFFERING_REFERENCES_BEGIN,
});

export const fetchViewOfferingReferencesSuccess = (references) => ({
  type: FETCH_VIEWOFFERING_REFERENCES_SUCCESS,
  payload: { references },
});

export const fetchViewOfferingReferencesFailure = (error) => ({
  type: FETCH_VIEWOFFERING_REFERENCES_FAILURE,
  payload: { error },
});

export const fetchViewOfferingInformationBegin = () => ({
  type: FETCH_VIEWOFFERING_INFORMATION_BEGIN,
});

export const fetchViewOfferingInformationSuccess = (information) => ({
  type: FETCH_VIEWOFFERING_INFORMATION_SUCCESS,
  payload: { information },
});

export const fetchViewOfferingInformationFailure = (error) => ({
  type: FETCH_VIEWOFFERING_INFORMATION_FAILURE,
  payload: { error },
});

export const selectInformation = (id) => ({
  type: SELECT_VIEWOFFERING_INFORMATION,
  payload: { id },
});

export const deleteProjectBegin = () => ({
  type: DELETE_VIEWOFFERING_PROJECT_BEGIN,
});

export const deleteProjectSuccess = () => ({
  type: DELETE_VIEWOFFERING_PROJECT_SUCCESS,
});

export const deleteProjectFailure = (error) => ({
  type: DELETE_VIEWOFFERING_PROJECT_FAILURE,
  payload: { error },
});

export const selectRatecard = (id) => ({
  type: SELECT_VIEWOFFERING_RATECARD,
  payload: { id },
});

export const selectFixedPrice = (id) => ({
  type: SELECT_VIEWOFFERING_FIXEDPRICE,
  payload: { id },
});

export const selectPiecePrice = (id) => ({
  type: SELECT_VIEWOFFERING_PIECEPRICE,
  payload: { id },
});

export const selectTAndM = (id) => ({
  type: SELECT_VIEWOFFERING_TANDM,
  payload: { id },
});

export const selectReference = (id) => ({
  type: SELECT_VIEWOFFERING_REFERENCE,
  payload: { id },
});

export const selectSubcontractor = (id) => ({
  type: SELECT_VIEWOFFERING_SUBCONTRACTOR,
  payload: { id },
});

export const fetchTAndMsBegin = () => ({
  type: FETCH_VIEWOFFERING_TANDMS_BEGIN,
});

export const fetchTAndMsSuccess = (tandms) => ({
  type: FETCH_VIEWOFFERING_TANDMS_SUCCESS,
  payload: { tandms },
});

export const fetchTAndMsFailure = (error) => ({
  type: FETCH_VIEWOFFERING_TANDMS_FAILURE,
  payload: { error },
});

export const fetchPiecePricesBegin = () => ({
  type: FETCH_VIEWOFFERING_PIECEPRICES_BEGIN,
});

export const fetchPiecePricesSuccess = (piecePrices) => ({
  type: FETCH_VIEWOFFERING_PIECEPRICES_SUCCESS,
  payload: { piecePrices },
});

export const fetchPiecePricesFailure = (error) => ({
  type: FETCH_VIEWOFFERING_PIECEPRICES_FAILURE,
  payload: { error },
});

export const fetchFixedPricesBegin = () => ({
  type: FETCH_VIEWOFFERING_FIXEDPRICES_BEGIN,
});

export const fetchFixedPricesSuccess = (fixedPrices) => ({
  type: FETCH_VIEWOFFERING_FIXEDPRICES_SUCCESS,
  payload: { fixedPrices },
});

export const fetchFixedPricesFailure = (error) => ({
  type: FETCH_VIEWOFFERING_FIXEDPRICES_FAILURE,
  payload: { error },
});

export const fetchRatecardsBegin = () => ({
  type: FETCH_VIEWOFFERING_RATECARDS_BEGIN,
});

export const fetchRatecardsSuccess = (ratecards) => ({
  type: FETCH_VIEWOFFERING_RATECARDS_SUCCESS,
  payload: { ratecards },
});

export const fetchRatecardsFailure = (error) => ({
  type: FETCH_VIEWOFFERING_RATECARDS_FAILURE,
  payload: { error },
});

export const selectSla = (id) => ({
  type: SELECT_VIEWOFFERING_SLA,
  payload: { id },
});

export const clearSlas = () => ({
  type: CLEAR_VIEWOFFERING_SLAS_ARRAY,
});

export const fetchSlasBegin = () => ({
  type: FETCH_VIEWOFFERING_SLAS_BEGIN,
});

export const fetchSlasSuccess = (slas) => ({
  type: FETCH_VIEWOFFERING_SLAS_SUCCESS,
  payload: { slas },
});

export const fetchSlasFailure = (error) => ({
  type: FETCH_VIEWOFFERING_SLAS_FAILURE,
  payload: { error },
});

export const fetchAssumptionsBegin = () => ({
  type: FETCH_VIEWOFFERING_ASSUMPTIONS_BEGIN,
});

export const fetchAssumptionsSuccess = (assumptions) => ({
  type: FETCH_VIEWOFFERING_ASSUMPTIONS_SUCCESS,
  payload: { assumptions },
});

export const fetchAssumptionsFailure = (error) => ({
  type: FETCH_VIEWOFFERING_ASSUMPTIONS_FAILURE,
  payload: { error },
});

export const fetchProvisionsBegin = () => ({
  type: FETCH_VIEWOFFERING_PROVISIONS_BEGIN,
});

export const fetchProvisionsSuccess = (provisions) => ({
  type: FETCH_VIEWOFFERING_PROVISIONS_SUCCESS,
  payload: { provisions },
});

export const fetchProvisionsFailure = (error) => ({
  type: FETCH_VIEWOFFERING_PROVISIONS_FAILURE,
  payload: { error },
});

export const selectAssumption = (id) => ({
  type: SELECT_VIEWOFFERING_ASSUMPTION,
  payload: { id },
});

export const selectProvision = (id) => ({
  type: SELECT_VIEWOFFERING_PROVISION,
  payload: { id },
});

export const selectProjectOverview = (project) => ({
  type: SELECT_VIEWOFFERING_PROJECTOVERVIEW,
  payload: { project },
});

export const selectProject = (project) => ({
  type: VIEWOFFERING_SELECT_PROJECT,
  payload: { project },
});

export const selectTemplate = (template) => ({
  type: VIEWOFFERING_SELECT_TEMPLATE,
  payload: { template },
});

export const selectModule = (module) => ({
  type: VIEWOFFERING_SELECT_MODULE,
  payload: { module },
});

export const selectStatement = (statement) => ({
  type: VIEWOFFERING_SELECT_STATEMENT,
  payload: { statement },
});

export const clearProjects = () => ({
  type: VIEWOFFERING_CLEAR_PROJECTS_ARRAY,
});

export const clearTemplates = () => ({
  type: VIEWOFFERING_CLEAR_TEMPLATES_ARRAY,
});

export const clearModules = () => ({
  type: VIEWOFFERING_CLEAR_MODULES_ARRAY,
});

export const clearStatements = () => ({
  type: VIEWOFFERING_CLEAR_STATEMENTS_ARRAY,
});

export const fetchProjectsBegin = () => ({
  type: VIEWOFFERING_FETCH_PROJECTS_BEGIN,
});

export const fetchProjectsSuccess = (projects) => ({
  type: VIEWOFFERING_FETCH_PROJECTS_SUCCESS,
  payload: { projects },
});

export const fetchProjectsFailure = (error) => ({
  type: VIEWOFFERING_FETCH_PROJECTS_FAILURE,
  payload: { error },
});

export const fetchTemplatesBegin = () => ({
  type: VIEWOFFERING_FETCH_TEMPLATES_BEGIN,
});

export const fetchTemplatesSuccess = (templates) => ({
  type: VIEWOFFERING_FETCH_TEMPLATES_SUCCESS,
  payload: { templates },
});

export const fetchTemplatesFailure = (error) => ({
  type: VIEWOFFERING_FETCH_TEMPLATES_FAILURE,
  payload: { error },
});

export const fetchModulesBegin = () => ({
  type: VIEWOFFERING_FETCH_MODULES_BEGIN,
});

export const fetchModulesSuccess = (modules) => ({
  type: VIEWOFFERING_FETCH_MODULES_SUCCESS,
  payload: { modules },
});

export const fetchModulesFailure = (error) => ({
  type: VIEWOFFERING_FETCH_MODULES_FAILURE,
  payload: { error },
});

export const fetchStatementsBegin = () => ({
  type: VIEWOFFERING_FETCH_STATEMENTS_BEGIN,
});

export const fetchStatementsSuccess = (statements) => ({
  type: VIEWOFFERING_FETCH_STATEMENTS_SUCCESS,
  payload: { statements },
});

export const fetchStatementsFailure = (error) => ({
  type: VIEWOFFERING_FETCH_STATEMENTS_FAILURE,
  payload: { error },
});

// fetching projects from database
export const fetchProjects = () => (dispatch) => {
  dispatch(fetchProjectsBegin());
  return axios('/api/v1/viewoffering/projects')
    .then((res) => {
      const projects = res.data;
      dispatch(fetchProjectsSuccess(projects));
      return projects;
    })
    .catch((error) => dispatch(fetchProjectsFailure(error)));
};

export const deleteProject = (id) => (dispatch) => {
  dispatch(deleteProjectBegin());
  return axios
    .delete(`/api/v1/negotiation/projects/${id}`, {})
    .then(() => {
      dispatch(deleteProjectSuccess());
      dispatch(fetchProjects());
    })
    .catch((error) => dispatch(deleteProjectFailure(error)));
};

// fetching templates from database
export const fetchTemplates = (projectId) => (dispatch) => {
  dispatch(fetchTemplatesBegin());
  return axios(`/api/v1/viewoffering/${projectId}/templates`)
    .then((res) => {
      const templates = res.data;
      dispatch(fetchTemplatesSuccess(templates));
      return templates;
    })
    .catch((error) => dispatch(fetchTemplatesFailure(error)));
};

// fetching modules from datatbase
export const fetchModules = (templateId) => (dispatch) => {
  dispatch(fetchModulesBegin());
  return axios(`/api/v1/viewoffering/template/${templateId}/modules`)
    .then((res) => {
      const modules = res.data;
      dispatch(fetchModulesSuccess(modules));
      return modules;
    })
    .catch((error) => dispatch(fetchModulesFailure(error)));
};

// fetching statements from database
export const fetchStatements = (moduleId) => (dispatch) => {
  dispatch(fetchStatementsBegin());
  return axios(`/api/v1/viewoffering/module/${moduleId}/statements`)
    .then((res) => {
      const statements = res.data;
      dispatch(fetchStatementsSuccess(statements));
      return statements;
    })
    .catch((error) => dispatch(fetchStatementsFailure(error)));
};

export const fetchAssumptions = (projectId) => (dispatch) => {
  dispatch(fetchAssumptionsBegin());
  return axios
    .get(`/api/v1/negotiation/projects/${projectId}/assumptions`, {})
    .then((res) => {
      const assumptions = res.data;
      dispatch(fetchAssumptionsSuccess(assumptions));
    })
    .catch((error) => dispatch(fetchAssumptionsFailure(error)));
};

export const fetchProvisions = (projectId) => (dispatch) => {
  dispatch(fetchProvisionsBegin());
  return axios
    .get(`/api/v1/negotiation/projects/${projectId}/provisions`, {})
    .then((res) => {
      const provisions = res.data;
      dispatch(fetchProvisionsSuccess(provisions));
    })
    .catch((error) => dispatch(fetchProvisionsFailure(error)));
};

export const fetchSlas = (projectId) => (dispatch) => {
  dispatch(fetchSlasBegin());
  return axios
    .get(`/api/v1/viewoffering/projects/${projectId}/slas`, {})
    .then((res) => {
      dispatch(fetchSlasSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchSlasFailure(error)));
};

export const fetchFixedPrices = (projectId) => (dispatch) => {
  dispatch(fetchFixedPricesBegin());
  return axios
    .get(`/api/v1/viewoffering/projects/${projectId}/fixedprices`, {})
    .then((res) => {
      dispatch(fetchFixedPricesSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchFixedPricesFailure(error)));
};

export const fetchPiecePrices = (projectId) => (dispatch) => {
  dispatch(fetchPiecePricesBegin());
  return axios
    .get(`/api/v1/viewoffering/projects/${projectId}/pieceprices`, {})
    .then((res) => {
      dispatch(fetchPiecePricesSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchPiecePricesFailure(error)));
};

export const fetchTAndMs = (projectId) => (dispatch) => {
  dispatch(fetchTAndMsBegin());
  return axios
    .get(`/api/v1/viewoffering/projects/${projectId}/tandms`, {})
    .then((res) => {
      dispatch(fetchTAndMsSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchTAndMsFailure(error)));
};

export const fetchRatecards = (projectId) => (dispatch) => {
  dispatch(fetchRatecardsBegin());
  return axios
    .get(`/api/v1/viewoffering/projects/${projectId}/ratecards`, {})
    .then((res) => {
      dispatch(fetchRatecardsSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchRatecardsFailure(error)));
};

export const fetchViewOfferingInformation = (id) => (dispatch) => {
  dispatch(fetchViewOfferingInformationBegin());
  return axios
    .get(`/api/v1/viewoffering/projects/${id}/information`, {})
    .then((res) => {
      dispatch(fetchViewOfferingInformationSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchViewOfferingInformationFailure(error)));
};

export const fetchViewOfferingReferences = (id) => (dispatch) => {
  dispatch(fetchViewOfferingReferencesBegin());
  return axios
    .get(`/api/v1/viewoffering/projects/${id}/references`, {})
    .then((res) => {
      const references = res.data;
      dispatch(fetchViewOfferingReferencesSuccess(references));
      return references;
    })
    .catch((error) => dispatch(fetchViewOfferingReferencesFailure(error)));
};

export const fetchViewOfferingSubcontractors = (id) => (dispatch) => {
  dispatch(fetchViewOfferingSubcontractorsBegin());
  return axios
    .get(`/api/v1/viewoffering/projects/${id}/subcontractors`, {})
    .then((res) => {
      const subcontractors = res.data;
      dispatch(fetchViewOfferingSubcontractorsSuccess(subcontractors));
      return subcontractors;
    })
    .catch((error) => dispatch(fetchViewOfferingSubcontractorsFailure(error)));
};

export const fetchOfferingPrices = (projectName, projectPhase) => (
  dispatch,
) => {
  dispatch(fetchOfferingPricesBegin());
  return axios
    .post('/api/v1/viewoffering/prices', { projectName, projectPhase })
    .then((res) => {
      const prices = res.data;
      dispatch(fetchOfferingPricesSuccess(prices));
      return prices;
    })
    .catch((error) => dispatch(fetchOfferingPricesFailure(error)));
};
