import axios from 'axios';
import {
  VERIFY_USER_BEGIN,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILURE,
  CHANGE_ADMIN_BEGIN,
  CHANGE_ADMIN_SUCCESS,
  CHANGE_ADMIN_FAILURE,
  CHANGE_ACTIVE_BEGIN,
  CHANGE_ACTIVE_SUCCESS,
  CHANGE_ACTIVE_FAILURE,
  RESET_PASSWORD_BEGIN,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CHANGE_PASSWORD_BEGIN,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  SELECT_USER,
  DELETE_USER_BEGIN,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  TOGGLE_DELETE_USER_MODAL,
  UPDATE_USER_ROLE_BEGIN,
  UPDATE_USER_ROLE_SUCCESS,
  UPDATE_USER_ROLE_FAILURE,
} from '../actionTypes/actionTypes';
import { enqueueSnackbar } from './notifierAction';

export const toggleDeleteUserModalAction = (payload) => ({
  type: TOGGLE_DELETE_USER_MODAL,
  payload,
});

export const selectUser = (userId) => ({
  type: SELECT_USER,
  payload: { userId },
});

export const deleteUserBegin = () => ({
  type: DELETE_USER_BEGIN,
});

export const deleteUserSuccess = () => ({
  type: DELETE_USER_SUCCESS,
});

export const deleteUserFailure = (error) => ({
  type: DELETE_USER_FAILURE,
  payload: { error },
});

export const changePasswordBegin = () => ({
  type: CHANGE_PASSWORD_BEGIN,
});

export const changePasswordSuccess = () => ({
  type: CHANGE_PASSWORD_SUCCESS,
});

export const changePasswordFailure = (error) => ({
  type: CHANGE_PASSWORD_FAILURE,
  payload: { error },
});

export const resetPasswordBegin = () => ({
  type: RESET_PASSWORD_BEGIN,
});

export const resetPasswordSuccess = () => ({
  type: RESET_PASSWORD_SUCCESS,
});

export const resetPasswordFailure = (error) => ({
  type: RESET_PASSWORD_FAILURE,
  payload: { error },
});

export const changeAdminBegin = () => ({
  type: CHANGE_ADMIN_BEGIN,
});

export const changeAdminSuccess = () => ({
  type: CHANGE_ADMIN_SUCCESS,
});

export const changeAdminFailure = (error) => ({
  type: CHANGE_ADMIN_FAILURE,
  payload: { error },
});

export const changeActiveBegin = () => ({
  type: CHANGE_ACTIVE_BEGIN,
});

export const changeActiveSuccess = () => ({
  type: CHANGE_ACTIVE_SUCCESS,
});

export const changeActiveFailure = (error) => ({
  type: CHANGE_ACTIVE_FAILURE,
  payload: { error },
});

export const verifyUserBegin = () => ({
  type: VERIFY_USER_BEGIN,
});

export const verifyUserSuccess = () => ({
  type: VERIFY_USER_SUCCESS,
});

export const verifyUserFailure = (error) => ({
  type: VERIFY_USER_FAILURE,
  payload: { error },
});

export const updateUserRoleBegin = () => ({
  type: UPDATE_USER_ROLE_BEGIN,
});

export const updateUserRoleSuccess = () => ({
  type: UPDATE_USER_ROLE_SUCCESS,
});

export const updateUserRoleFailure = (error) => ({
  type: UPDATE_USER_ROLE_FAILURE,
  payload: { error },
});

export const updateUserRole = (id, role) => (dispatch) => {
  dispatch(updateUserRoleBegin());
  return axios
    .put('/api/v1/users/role', {
      id,
      role,
    })
    .then((res) => {
      dispatch(updateUserRoleSuccess());
      // dispatch(fetchUsers());
      return res;
    })
    .catch((error) => dispatch(updateUserRoleFailure(error)));
};

export const toggleDeleteUserModal = () => (dispatch, getState) => {
  const { user } = getState();
  if (!user.deleteUserModalIsOpen) {
    dispatch(toggleDeleteUserModalAction(true));
  } else {
    dispatch(toggleDeleteUserModalAction(false));
  }
};

export const changePassword = (password, oldpassword) => (dispatch) => {
  dispatch(changePasswordBegin());
  return axios
    .put('/api/v1/users/password', {
      password,
      oldpassword,
    })
    .then((res) => {
      dispatch(changePasswordSuccess());
      dispatch(
        enqueueSnackbar({
          message: 'Passwort erfolgreich geändert.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'success',
            autoHideDuration: 3000,
          },
        }),
      );
      return res;
    })
    .catch((error) => {
      dispatch(changePasswordFailure(error));
      dispatch(
        enqueueSnackbar({
          message: 'FEHLER: Passwort konnte nicht geändert werden.',
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
            autoHideDuration: 3000,
          },
        }),
      );
    });
};

export const resetPassword = (email) => (dispatch) => {
  dispatch(resetPasswordBegin());
  return axios
    .put('/api/v1/users/resetpassword', {
      email,
    })
    .then((res) => {
      dispatch(resetPasswordSuccess());
      return res;
    })
    .catch((error) => dispatch(resetPasswordFailure(error)));
};

export const deleteUser = (userId) => (dispatch) => {
  dispatch(deleteUserBegin());
  return axios
    .delete(`/api/v1/users/${userId}`)
    .then((res) => {
      dispatch(deleteUserSuccess());
      // dispatch(fetchUsers());
      return res;
    })
    .catch((error) => dispatch(deleteUserFailure(error)));
};

export const changeAdmin = (id) => (dispatch) => {
  dispatch(changeAdminBegin());
  return axios
    .put('/api/v1/users/admin', {
      id,
    })
    .then((res) => {
      dispatch(changeAdminSuccess());
      // dispatch(fetchUsers());
      return res;
    })
    .catch((error) => dispatch(changeAdminFailure(error)));
};

export const changeActive = (id) => (dispatch) => {
  dispatch(changeActiveBegin());
  return axios
    .put('/api/v1/users/active', {
      id,
    })
    .then((res) => {
      dispatch(changeActiveSuccess());
      // dispatch(fetchUsers());
      return res;
    })
    .catch((error) => dispatch(changeActiveFailure(error)));
};

export const verifyUser = (token, history) => (dispatch) => {
  dispatch(verifyUserBegin());
  return axios(`/api/v1/users/verify/${token}`)
    .then((res) => {
      const { success } = res.data;
      if (success) {
        dispatch(verifyUserSuccess());
        history.push('/login');
      } else {
        dispatch(verifyUserFailure());
      }
    })
    .catch((error) => dispatch(verifyUserFailure(error)));
};
