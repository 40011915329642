import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_SERVICELEVELS, GET_PROJECT } from '../../graphql/queries';
import {
  ADD_SERVICELEVEL,
  UPDATE_SERVICELEVEL,
  DELETE_SERVICELEVEL,
} from '../../graphql/mutations';

import {
  // fetchSlas,
  // addSla,
  // updateSla,
  // deleteSla,
  selectProject,
  selectTemplate,
  selectSla,
} from '../../redux/actionCreators/databaseAction';
import DatabaseSlasTable from '../../components/DatabaseManagement/DatabaseSlasTable';
import Loading from '../../components/Elements/Loading';

function DatabaseSlasContainer(props) {
  const {
    // slas,
    // projects,
    // templates,
    // fetchSlas,
    // addSla,
    // updateSla,
    // deleteSla,
    selectProject,
    selectTemplate,
    selectedProject,
    selectSla,
    // loading,
  } = props;
  // useEffect(() => {
  //   fetchSlas(selectedProject);
  // }, [fetchSlas, selectedProject]);

  // slas.forEach((element) => {
  //   element.templateName = templates.filter((template) => template._id === element.template).map((template) => template.name).toString();
  // });

  const { loading, error, data } = useQuery(GET_SERVICELEVELS, {
    variables: { projectId: selectedProject },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [addServiceLevel] = useMutation(ADD_SERVICELEVEL, {
    refetchQueries: [{
      query: GET_SERVICELEVELS,
      variables: { projectId: selectedProject },
    }],
  });
  const [updateServiceLevel] = useMutation(UPDATE_SERVICELEVEL, {
    refetchQueries: [{
      query: GET_SERVICELEVELS,
      variables: { projectId: selectedProject },
    }],
  });
  const [deleteServiceLevel] = useMutation(DELETE_SERVICELEVEL, {
    refetchQueries: [{
      query: GET_SERVICELEVELS,
      variables: { projectId: selectedProject },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  return (
    <DatabaseSlasTable
      slas={data.servicelevels}
      projects={data1.project}
      // templates={templates}
      addSla={addServiceLevel}
      updateSla={updateServiceLevel}
      deleteSla={deleteServiceLevel}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectSla={selectSla}
      loading={loading}
    />
  );
}

const mapStateToProps = (state) => ({
  slas: state.database.slas,
  projects: state.database.projects,
  templates: state.database.templates,
  selectedProject: state.database.selectedProject,
  loading: state.database.loading,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchSlas: (id) => dispatch(fetchSlas(id)),
  // addSla: (id, moduleId) => dispatch(addSla(id, moduleId)),
  // updateSla: (
  //   slaId,
  //   priority,
  //   servicetime,
  //   availability,
  //   reactiontime,
  //   solutiontime,
  //   penalty,
  //   penaltyreference,
  //   project,
  // ) =>
  //   dispatch(
  //     updateSla(
  //       slaId,
  //       priority,
  //       servicetime,
  //       availability,
  //       reactiontime,
  //       solutiontime,
  //       penalty,
  //       penaltyreference,
  //       project,
  //     ),
  //   ),
  // deleteSla: (id, projectId) => dispatch(deleteSla(id, projectId)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectSla: (id) => dispatch(selectSla(id)),
});

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(DatabaseSlasContainer),
);

DatabaseSlasContainer.propTypes = {
  // fetchSlas: PropTypes.func.isRequired,
  // addSla: PropTypes.func.isRequired,
  // updateSla: PropTypes.func.isRequired,
  // deleteSla: PropTypes.func.isRequired,
  selectSla: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // slas: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  // templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
};
