import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import EditDialog from '../Dialogs/EditDialog';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function OfferingReferencesTable(props) {
  const {
    selectReference,
    selectProject,
    selectTemplate,
    selectOfferingReferenceCompany,
    references,
    projects,
    // selectedProject,
    selectedReferenceCompany,
    updateOfferingReference,
    // user,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'number',
      label: '#',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { width: '50px' } }),
      },
    },
    {
      name: 'question',
      label: 'Frage',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'answer',
      label: 'Antwort',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        display: !projects.published && projects.authorization !== 'read',
        viewColumns: !projects.published && projects.authorization !== 'read',
        customBodyRender: (value, tableMeta) => (
          <EditDialog
            informationId={tableMeta.rowData[0]}
            projectId={projects._id}
            oldData={tableMeta.rowData[3]}
            header="Antwort bearbeiten"
            text={tableMeta.rowData[2]}
            field="answer"
            onClick={updateOfferingReference}
          />
        ),
      },
    },
  ];

  const data = [];

  references
    .filter((reference) => reference.cluster === selectedReferenceCompany)
    .forEach((reference) => {
      data.push({
        _id: reference._id,
        number: reference.number,
        question: reference.question,
        answer: reference.answer,
        company: reference.cluster,
      });
    });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button
              size="small"
              onClick={() => {
                selectOfferingReferenceCompany('');
              }}
            >
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectReference('');
                  selectOfferingReferenceCompany('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => {
                  selectReference('');
                  selectOfferingReferenceCompany('');
                }}
              >
                {projects.name}
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => {
                  selectOfferingReferenceCompany('');
                }}
              >
                Referenzen
              </Link>
              <Typography color="textPrimary">
                {selectedReferenceCompany}
              </Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({ tableBodyMaxHeight: 'calc(100vh - 129px)' })}
      />
    </div>
  );
}

export default OfferingReferencesTable;

OfferingReferencesTable.propTypes = {
  selectReference: PropTypes.func.isRequired,
  selectOfferingReferenceCompany: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  updateOfferingReference: PropTypes.func.isRequired,
  references: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
  // selectedProject: PropTypes.string.isRequired,
  selectedReferenceCompany: PropTypes.string.isRequired,
  // user: PropTypes.shape(Object).isRequired,
};
