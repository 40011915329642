import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_PRICES, GET_PROJECT } from '../../graphql/queries';
import {
  ADD_PRICE,
  UPDATE_PRICE,
  DELETE_PRICE,
} from '../../graphql/mutations';

import {
  selectPiecePrice,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/negotiationAction';
import NegotiationPiecePricesTable from '../../components/NegotiationManagement/NegotiationPiecePricesTable';
import Loading from '../../components/Elements/Loading';

function NegotiationPiecePricesContainer(props) {
  const {
    selectPiecePrice,
    selectProject,
    selectTemplate,
    selectedProject,
  } = props;

  const priceType = 'Stückpreis';
  const { loading, error, data } = useQuery(GET_PRICES, {
    variables: { projectId: selectedProject, type: priceType },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [addPrice] = useMutation(ADD_PRICE, {
    refetchQueries: [{
      query: GET_PRICES,
      variables: { projectId: selectedProject, type: priceType },
    }],
  });
  const [updatePrice] = useMutation(UPDATE_PRICE, {
    refetchQueries: [{
      query: GET_PRICES,
      variables: { projectId: selectedProject, type: priceType },
    }],
  });
  const [deletePrice] = useMutation(DELETE_PRICE, {
    refetchQueries: [{
      query: GET_PRICES,
      variables: { projectId: selectedProject, type: priceType },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  return (
    <NegotiationPiecePricesTable
      piecePrices={data.pricesByType}
      projects={data1.project}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectPiecePrice={selectPiecePrice}
      priceType={priceType}
      addPiecePrice={addPrice}
      updatePiecePrice={updatePrice}
      deletePiecePrice={deletePrice}
    />
  );
}

const mapStateToProps = (state) => ({
  piecePrices: state.negotiation.piecePrices,
  projects: state.negotiation.projects,
  templates: state.negotiation.templates,
  selectedProject: state.negotiation.selectedProject,
  loading: state.negotiation.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectPiecePrice: (id) => dispatch(selectPiecePrice(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NegotiationPiecePricesContainer),
);

NegotiationPiecePricesContainer.propTypes = {
  selectPiecePrice: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
};
