import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import AddDialog from '../Dialogs/AddDialog';
import DateStamp from '../Elements/DateStamp';
import TableCheckbox from '../Buttons/TableCheckbox';

import UploadHandler from '../Elements/UploadHandler';
// import EditDialog from '../Dialogs/EditDialog';
// import DeleteDialog from '../Dialogs/DeleteDialog';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function OfferingQAndAsTable(props) {
  const {
    qandas,
    projects,
    selectProject,
    selectTemplate,
    selectQAndA,
    selectedProject,
    addOfferingQAndA,
    // updateOfferingQAndA,
    // deleteOfferingQAndA,
    // user,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'number',
      label: '#',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { width: '50px' } }),
      },
    },
    {
      name: 'question',
      label: 'Frage',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'answer',
      label: 'Antwort',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'attachment',
      label: 'Anhänge',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <UploadHandler
            projectId={projects._id}
            referenceId={tableMeta.rowData[0]}
            url="/api/v1/upload/"
            referenceType="qanda"
            attachments={value}
            disabled
          />
        ),
      },
    },
    {
      name: 'updated',
      label: 'Geändert',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <><DateStamp date={value} /></>
        ),
      },
    },
    {
      name: 'ownQuestion',
      label: 'Eigene Frage',
      options: {
        display: true,
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <TableCheckbox
            id={tableMeta.rowData[0]}
            value={value}
            disabled
          />
        ),
      },
    },
    // {
    //   name: 'options',
    //   label: 'Optionen',
    //   options: {
    //     filter: false,
    //     sort: false,
    //     customBodyRender: (value, tableMeta) => (
    //       <>
    //         {tableMeta.rowData[2] === '' && (
    //           <>
    //             <EditDialog
    //               id={tableMeta.rowData[0]}
    //               oldData={tableMeta.rowData[1]}
    //               header="Q&A bearbeiten"
    //               text="Frage"
    //               onClick={updateOfferingQAndA}
    //             />
    //             <DeleteDialog
    //               header="Q&A löschen"
    //               text="Möchten Sie das Q&A wirklich löschen?"
    //               onClick={() => deleteOfferingQAndA(tableMeta.rowData[0])}
    //             />
    //           </>
    //         )}
    //       </>
    //     ),
    //   },
    // },
  ];

  const data = [];

  qandas.forEach((qanda) => {
    data.push({
      _id: qanda._id,
      question: qanda.question,
      number: qanda.number,
      answer: qanda.answer,
      attachment: qanda.attachments,
      updated: Math.max(...qanda.changes.map((o) => o.date)),
      ownQuestion: qanda.ownQuestion,
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectQAndA('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectQAndA('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => selectQAndA('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Q&A</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({ download: true, downloadOptions: { filename: 'Q_A_export.csv', filterOptions: { useDisplayedColumnsOnly: true } } })}
      />
      {!projects.published && projects.authorization !== 'read' && (
        <AddDialog
          projectId={selectedProject}
          header="Frage zur Ausschreibung hinzufügen"
          text="Frage"
          field="question"
          onClick={addOfferingQAndA}
          description="Nach dem Hinzufügen einer Frage ist keine Bearbeitung mehr möglich. Alle Anbieter können die Fragen aller Anbieter direkt einsehen. Bitte formulieren Sie Ihre Frage, sodass kein Rückschluss auf Ihr Unternehmen möglich ist."
        />
      )}
    </div>
  );
}

export default OfferingQAndAsTable;

OfferingQAndAsTable.propTypes = {
  qandas: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectQAndA: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // user: PropTypes.shape(Object).isRequired,
  addOfferingQAndA: PropTypes.func.isRequired,
  // updateOfferingQAndA: PropTypes.func.isRequired,
  // deleteOfferingQAndA: PropTypes.func.isRequired,
};
