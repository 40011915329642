import React from 'react';
import PropTypes from 'prop-types';

import NegotiationProjectsContainer from '../../container/NegotiationManagement/NegotiationProjectsContainer';
import NegotiationTemplatesContainer from '../../container/NegotiationManagement/NegotiationTemplatesContainer';
import NegotiationModulesContainer from '../../container/NegotiationManagement/NegotiationModulesContainer';
import NegotiationSlasContainer from '../../container/NegotiationManagement/NegotiationSlasContainer';
import NegotiationRatecardContainer from '../../container/NegotiationManagement/NegotiationRatecardContainer';
import NegotiationTAndMsContainer from '../../container/NegotiationManagement/NegotiationTAndMsContainer';
import NegotiationFixedPricesContainer from '../../container/NegotiationManagement/NegotiationFixedPricesContainer';
import NegotiationPiecePricesContainer from '../../container/NegotiationManagement/NegotiationPiecePricesContainer';
import NegotiationInformationContainer from '../../container/NegotiationManagement/NegotiationInformationContainer';
import NegotiationReferencesCompaniesContainer from '../../container/NegotiationManagement/NegotiationReferencesCompaniesContainer';
import NegotiationSubcontractorsCompaniesContainer from '../../container/NegotiationManagement/NegotiationSubcontractorsCompaniesContainer';

import ResponsiveDiv from '../Elements/ResponsiveDiv';

function NegotiationPage(props) {
  const {
    selectedProject,
    selectedTemplate,
    selectedSla,
    selectedRatecard,
    selectedTAndM,
    selectedFixedPrice,
    selectedPiecePrice,
    selectedInformation,
    selectedReference,
    selectedSubcontractor,
  } = props;

  if (selectedSla) {
    return (
      <ResponsiveDiv>
        <NegotiationSlasContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedRatecard) {
    return (
      <ResponsiveDiv>
        <NegotiationRatecardContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedTAndM) {
    return (
      <ResponsiveDiv>
        <NegotiationTAndMsContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedFixedPrice) {
    return (
      <ResponsiveDiv>
        <NegotiationFixedPricesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedPiecePrice) {
    return (
      <ResponsiveDiv>
        <NegotiationPiecePricesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedInformation) {
    return (
      <ResponsiveDiv>
        <NegotiationInformationContainer />
      </ResponsiveDiv>
    );
  }

  // if (selectedReferenceCompany) {
  //   return (
  //     <ResponsiveDiv>
  //       <NegotiationReferencesContainer />
  //     </ResponsiveDiv>
  //   );
  // }

  if (selectedReference) {
    return (
      <ResponsiveDiv>
        <NegotiationReferencesCompaniesContainer />
      </ResponsiveDiv>
    );
  }

  // if (selectedSubcontractorCompany) {
  //   return (
  //     <ResponsiveDiv>
  //       <NegotiationSubcontractorsContainer />
  //     </ResponsiveDiv>
  //   );
  // }

  if (selectedSubcontractor) {
    return (
      <ResponsiveDiv>
        <NegotiationSubcontractorsCompaniesContainer />
      </ResponsiveDiv>
    );
  }

  // if (selectedModule) {
  //   return (
  //     <ResponsiveDiv>
  //       <NegotiationStatementsContainer />
  //     </ResponsiveDiv>
  //   );
  // }

  if (selectedTemplate) {
    return (
      <ResponsiveDiv>
        <NegotiationModulesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedProject) {
    return (
      <ResponsiveDiv>
        <NegotiationTemplatesContainer />
      </ResponsiveDiv>
    );
  }

  return (
    <ResponsiveDiv>
      <NegotiationProjectsContainer />
    </ResponsiveDiv>
  );
}

export default NegotiationPage;

NegotiationPage.propTypes = {
  selectedProject: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  selectedSla: PropTypes.string.isRequired,
  selectedRatecard: PropTypes.string.isRequired,
  selectedTAndM: PropTypes.string.isRequired,
  selectedFixedPrice: PropTypes.string.isRequired,
  selectedPiecePrice: PropTypes.string.isRequired,
  selectedInformation: PropTypes.string.isRequired,
  selectedReference: PropTypes.string.isRequired,
  selectedSubcontractor: PropTypes.string.isRequired,
};
