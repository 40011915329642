import React from 'react';
import { Switch, Route } from 'react-router-dom';

import HomePage from './components/Home/HomePage';
import SignUpContainer from './container/SignUp/SignUpContainer';
import SignInContainer from './container/SignIn/SignInContainer';
import ResetPasswordPage from './components/SignInUp/ResetPasswordPage';
import DatabaseContainer from './container/DatabaseManagement/DatabaseContainer';
import CreateProjectStepperContainer from './container/ProjectManagement/CreateProjectStepperContainer';
import ProjectContainer from './container/ProjectManagement/ProjectContainer';
import OfferingContainer from './container/OfferingManagement/OfferingContainer';
import ViewOfferingContainer from './container/ViewOffering/ViewOfferingContainer';
import NegotiationContainer from './container/NegotiationManagement/NegotiationContainer';
import UserContainer from './container/UserManagement/UserContainer';
import ProfileContainer from './container/Profile/ProfileContainer';
import ImprintPage from './components/ImprintPrivacy/ImprintPage';
import PrivacyPage from './components/ImprintPrivacy/PrivacyPage';
import InstructionsPage from './components/Instructions/InstructionsPage';

function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={HomePage} />
      <Route exact path="/signup" component={SignUpContainer} />
      <Route exact path="/signin" component={SignInContainer} />
      <Route exact path="/resetpassword" component={ResetPasswordPage} />
      <Route exact path="/database" component={DatabaseContainer} />
      <Route
        exact
        path="/createproject"
        component={CreateProjectStepperContainer}
      />
      <Route exact path="/manageproject" component={ProjectContainer} />
      <Route exact path="/manageoffering" component={OfferingContainer} />
      <Route exact path="/viewoffering" component={ViewOfferingContainer} />
      <Route exact path="/managenegotiation" component={NegotiationContainer} />
      <Route exact path="/users" component={UserContainer} />
      <Route exact path="/profile" component={ProfileContainer} />
      <Route exact path="/imprint" component={ImprintPage} />
      <Route exact path="/privacy" component={PrivacyPage} />
      <Route exact path="/instructions" component={InstructionsPage} />
      {/* <Route
        path='/templateoverview/:token'
        component={TemplateOverviewContainer}
      /> */}
    </Switch>

  );
}
export default Routes;
