import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import UserPage from '../../components/UserManagement/UserPage';

function UserContainer(props) {
  const { admin, history } = props;
  useEffect(() => {
    if (!admin) {
      history.push('/main');
    }
  }, [admin, history]);

  return <UserPage />;
}

const mapStateToProps = (state) => ({
  admin: state.auth.user.admin,
});

export default withRouter(connect(mapStateToProps)(UserContainer));

UserContainer.propTypes = {
  admin: PropTypes.bool.isRequired,
  history: PropTypes.shape(Object).isRequired,
};
