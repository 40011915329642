import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_INFORMATIONS, GET_PROJECT } from '../../graphql/queries';

import {
  selectInformation,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/negotiationAction';
import NegotiationInformationTable from '../../components/NegotiationManagement/NegotiationInformationTable';
import Loading from '../../components/Elements/Loading';

function NegotiationInformationContainer(props) {
  const {
    selectInformation,
    selectProject,
    selectTemplate,
    selectedProject,
  } = props;

  const informationType = 'Information';
  const cluster = '';
  const { loading, error, data } = useQuery(GET_INFORMATIONS, {
    variables: { projectId: selectedProject, type: informationType, cluster },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }

  return (
    <NegotiationInformationTable
      information={data.informations}
      projects={data1.project}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectInformation={selectInformation}
    />
  );
}

const mapStateToProps = (state) => ({
  information: state.negotiation.information,
  projects: state.negotiation.projects,
  selectedProject: state.negotiation.selectedProject,
  loading: state.negotiation.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectInformation: (id) => dispatch(selectInformation(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NegotiationInformationContainer),
);

NegotiationInformationContainer.propTypes = {
  selectInformation: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
};
