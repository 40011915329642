import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Fab, Badge } from '@material-ui/core';
import { Message } from '@material-ui/icons';
import { orange } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  tableCommentIconButton: {
    backgroundColor: orange[300],
    color: theme.palette.primary.addFab,
    position: 'fixed',
    bottom: theme.spacing(2),
    right: 96,
  },
  badge: {
    position: 'fixed',
    bottom: theme.spacing(7),
    right: 104,
  },
}));

function TableCommentsButton(props) {
  const classes = useStyles();
  const { count, onClick } = props;
  return (
    <Badge color="secondary" badgeContent={count} className={classes.badge}>
      <Fab
        size="medium"
        className={classes.tableCommentIconButton}
        onClick={onClick}
      >
        <Message />
      </Fab>
    </Badge>
  );
}

export default TableCommentsButton;

TableCommentsButton.propTypes = {
  count: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};
