import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Checkbox,
  FormControlLabel,
  Link,
} from '@material-ui/core';
import { blue, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import ReactGA from 'react-ga';

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: grey[300],
  },
  submitButton: {
    color: blue[300],
  },
}));

function CookieBanner() {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [trackingConsent, settrackingConsent] = useState(true);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleAccept = () => {
    ReactGA.initialize('UA-190139973-1');
    ReactGA.set({ anonymizeIp: true });
  };
  const handleClose = () => {
    setOpen(false);
    if (trackingConsent) {
      handleAccept();
    }
  };
  const handleChange = (event) => {
    settrackingConsent(event.target.checked);
    Cookies.set('CookieConsent', event.target.checked);
  };

  if (Cookies.get('CookieConsent')) {
    handleAccept();
  }
  return (
    <div>
      <CookieConsent
        setDeclineCookie={false}
        enableDeclineButton
        buttonText="OK"
        declineButtonText="anpassen"
        flipButtons
        style={{
          background: '#2829A8',
          justifyContent: 'center',
          padding: '10px 20px 10px 20px',
          alignItems: 'center',
        }}
        contentStyle={{
          maxWidth: 550,
          margin: 0,
        }}
        buttonStyle={{
          color: '#000',
          fontSize: '13px',
          background: '#fff',
          padding: '10px 20px 10px 20px',

        }}
        declineButtonStyle={{
          color: '#fff',
          fontSize: '13px',
          background: '#000',
          padding: '10px 20px 10px 20px',
        }}
        onDecline={handleClickOpen}
        onAccept={handleAccept}
      >
        <Typography variant="body2">
          Wir nutzen Cookies auf unserer Website. Einige sind essentiell, während andere uns helfen unsere Webseite und das damit verbundene Nutzerverhalten zu optimieren. Diese Einstellungen können über den Datenschutzbereich geändert werden.
        </Typography>
      </CookieConsent>

      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Wir verwenden Cookies</DialogTitle>
        <DialogContent>
          <Typography>
            Wir nutzen Cookies auf unserer Website. Einige sind essentiell, während andere uns helfen unsere Webseite und das damit verbundene Nutzerverhalten zu optimieren.
          </Typography>

          <FormControlLabel
            control={<Checkbox checked disabled name="essentialCookies" />}
            label="Essentiell"
          />
          <Typography variant="body2">Diese Cookies sind für die Funktionalität unserer Website erforderlich und können nicht deaktiviert werden (z.B. SessionCookies).</Typography>
          <FormControlLabel
            control={<Checkbox checked={trackingConsent} onChange={handleChange} name="trackingCookies" />}
            label="Tracking"
          />
          <Typography variant="body2">Diese Cookies helfen uns zu verstehen, wie Besucher mit unserer Webseite interagieren, indem Informationen anonym gesammelt und analysiert werden. Je nach Tool werden ein oder mehrere Cookies gleichen Anbieters gesetzt.</Typography>
          <Typography variant="body2">
            Name:
            <Link href="https://policies.google.com/privacy" target="_blank" rel="noreferrer" color="textSecondary">Google Analytics</Link>
            , Zweck: Tracking / Analyse
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button
            className={classes.submitButton}
            onClick={handleClose}
            color="primary"
          >
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default CookieBanner;
