import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// GraphQL
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PROJECTS } from '../../graphql/queries';
import { PUBLISH_NEGOTIATION, UPDATE_PROJECT, DELETE_PROJECT } from '../../graphql/mutations';

import {
  fetchProjects,
  selectProject,
  uploadNegotiationProjectFile,
  removeNegotiationProjectUpload,
} from '../../redux/actionCreators/negotiationAction';
import NegotiationProjectsTable from '../../components/NegotiationManagement/NegotiationProjectsTable';
import Loading from '../../components/Elements/Loading';

function NegotiationProjectsContainer(props) {
  const {
    selectProject,
    uploadNegotiationProjectFile,
    selectedProjectOrigin,
    removeNegotiationProjectUpload,
  } = props;

  const projectType = 'negotiation';
  const { loading, error, data } = useQuery(GET_PROJECTS, {
    variables: { type: projectType },
    pollInterval: 500,
  });
  const [publishNegotiation] = useMutation(PUBLISH_NEGOTIATION, {
    refetchQueries: [{
      query: GET_PROJECTS,
      variables: { type: projectType },
    }],
  });
  const [updateProject] = useMutation(UPDATE_PROJECT, {
    refetchQueries: [{
      query: GET_PROJECTS,
      variables: { type: projectType },
    }],
  });
  const [deleteProject] = useMutation(DELETE_PROJECT, {
    refetchQueries: [{
      query: GET_PROJECTS,
      variables: { type: projectType },
    }],
  });
  if (loading) return <Loading />;
  if (selectedProjectOrigin === '') {
    return (
      <p>
        Kein Projekt ausgewählt.
      </p>
    );
  }
  if (error) {
    return (
      <p>
        Error :$
        {error.message}
      </p>
    );
  }

  return (
    <NegotiationProjectsTable
      projects={data.projects.filter((project) => project.originProject && project.originProject._id === selectedProjectOrigin)}
      deleteProject={deleteProject}
      selectProject={selectProject}
      publishNegotiation={publishNegotiation}
      uploadNegotiationProjectFile={uploadNegotiationProjectFile}
      removeNegotiationProjectUpload={removeNegotiationProjectUpload}
      loading={loading}
      updateProject={updateProject}
    />
  );
}

const mapStateToProps = (state) => ({
  projects: state.negotiation.projects,
  loading: state.negotiation.loading,
  selectedProjectOrigin: state.project.selectedProject,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  fetchProjects: () => dispatch(fetchProjects()),
  selectProject: (id) => dispatch(selectProject(id)),
  uploadNegotiationProjectFile: (formData, config) =>
    dispatch(uploadNegotiationProjectFile(formData, config)),
  removeNegotiationProjectUpload: (id) =>
    dispatch(removeNegotiationProjectUpload(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NegotiationProjectsContainer),
);

NegotiationProjectsContainer.propTypes = {
  selectProject: PropTypes.func.isRequired,
  uploadNegotiationProjectFile: PropTypes.func.isRequired,
  removeNegotiationProjectUpload: PropTypes.func.isRequired,
  selectedProjectOrigin: PropTypes.string.isRequired,
};
