import React from 'react';

import UserTableContainer from '../../container/UserManagement/UserTableContainer';

import ResponsiveDiv from '../Elements/ResponsiveDiv';

function UserPage() {
  return (
    <ResponsiveDiv>
      <UserTableContainer />
    </ResponsiveDiv>
  );
}

export default UserPage;
