import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import useStyles from '../../styles';
import CurrencyFormat from '../Elements/CurrencyFormat';
import LocalNumberFormat from '../Elements/LocalNumberFormat';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function ViewOfferingPiecePricesTable(props) {
  const {
    piecePrices,
    projects,
    selectProject,
    selectTemplate,
    selectPiecePrice,
    // user,
  } = props;

  const getSum = (total, num) => total + num;

  const classes = useStyles();
  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'templateName',
      label: 'Dokument',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'moduleName',
      label: 'Modul',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'unit',
      label: 'Einheit',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'amount',
      label: 'Menge',
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.rightAlignedHeader }),
        setCellProps: () => ({ className: classes.rightAlignedCell }),
        customBodyRender: (value) => <LocalNumberFormat value={value} />,
      },
    },
    {
      name: 'pricePerPiece',
      label: 'Stückpreis in €',
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.rightAlignedHeader }),
        setCellProps: () => ({ className: classes.rightAlignedCell }),
        customBodyRender: (value) => <CurrencyFormat value={value} />,
      },
    },
    {
      name: 'calculation',
      label: 'Verrechnung',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'annualCost',
      label: 'Kosten in € p.a.',
      options: {
        filter: false,
        sort: true,
        setCellHeaderProps: () => ({ className: classes.rightAlignedHeader }),
        setCellProps: () => ({ className: classes.rightAlignedCell }),
        customBodyRender: (value) => <CurrencyFormat value={value} />,
      },
    },
    {
      name: 'template',
      label: 'TemplateId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
  ];

  const data = [];

  piecePrices.forEach((piecePrice) => {
    data.push({
      _id: piecePrice._id,
      moduleName: piecePrice.moduleName,
      templateName: piecePrice.templateName,
      unit: piecePrice.unit,
      amount: piecePrice.amount,
      pricePerPiece: piecePrice.pricePerPiece,
      calculation: piecePrice.calculation,
      annualCost: piecePrice.price,
      template: piecePrice.template,
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectPiecePrice('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/viewoffering"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectPiecePrice('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/viewoffering"
                onClick={() => selectPiecePrice('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">Stückpreise</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({
          sortOrder: { name: 'templateName', direction: 'asc' },
        })}
      />
      <div style={{ textAlign: 'center', marginTop: 16 }}>
        <Typography variant="h6">
          Summe:
          {' '}
          <CurrencyFormat value={piecePrices
            .map((piecePrices) => piecePrices.price)
            .reduce(getSum, 0)}
          />
          {' '}
          p.a.
        </Typography>
      </div>
    </div>
  );
}

export default ViewOfferingPiecePricesTable;

ViewOfferingPiecePricesTable.propTypes = {
  projects: PropTypes.shape(Object).isRequired,
  piecePrices: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectPiecePrice: PropTypes.func.isRequired,
};
