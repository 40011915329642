import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import UploadHandler from '../Elements/UploadHandler';
import AddDialog from '../Dialogs/AddDialog';
import EditTwoFieldsDialog from '../Dialogs/EditTwoFieldsDialog';
import TableSwitch from '../Buttons/TableSwitch';
import DateStamp from '../Elements/DateStamp';
import DeleteDialog from '../Dialogs/DeleteDialog';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function ProjectQAndAsTable(props) {
  const {
    qandas,
    projects,
    selectQAndA,
    // selectedProject,
    addProjectQAndA,
    updateProjectQAndA,
    // uploadProjectQAndAFile,
    // removeProjectQAndAUpload,
    toggleQAndAPublished,
    deleteProjectQAndA,
    // user,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'number',
      label: '#',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { width: '50px' } }),
      },
    },
    {
      name: 'question',
      label: 'Frage',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'answer',
      label: 'Antwort',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'company',
      label: 'Unternehmen',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'attachment',
      label: 'Anhänge',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <UploadHandler
            projectId={projects._id}
            referenceId={tableMeta.rowData[0]}
            url="/api/v1/upload/"
            referenceType="qanda"
            attachments={value}
            disabled={false}
          />
        ),
      },
    },
    {
      name: 'published',
      label: 'Antwort veröffentlichen',
      options: {
        filter: true,
        sort: true,
        display: true,
        viewColumns: true,
        customBodyRender: (value, tableMeta) => (
          <>
            <TableSwitch
              value={value}
              disabled={false}
              onChange={() => {
                toggleQAndAPublished({
                  variables: { projectId: projects._id, qandaId: tableMeta.rowData[0] },
                });
              }}
            />
          </>
        ),
      },
    },
    {
      name: 'updated',
      label: 'Geändert',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value) => (
          <><DateStamp date={value} /></>
        ),
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        display: true,
        viewColumns: true,
        customBodyRender: (value, tableMeta) => (
          // to do: disable for reading users
          <>
            <EditTwoFieldsDialog
              qandaId={tableMeta.rowData[0]}
              oldData={[tableMeta.rowData[2], tableMeta.rowData[3]]}
              header="Q&A bearbeiten"
              fields={{ fieldOne: 'Frage', fieldTwo: 'Antwort' }}
              projectId={projects._id}
              onClick={updateProjectQAndA}
            />
            <DeleteDialog
              header="Q&A löschen"
              text="Möchten Sie das Q&A wirklich löschen?"
              onClick={() => deleteProjectQAndA({
                variables: { projectId: projects._id, qandaId: tableMeta.rowData[0] },
              })}
            />
          </>
        ),
      },
    },
  ];

  const data = [];

  qandas.forEach((qanda) => {
    if (qanda.offer) {
      data.push({
        _id: qanda._id,
        question: qanda.question,
        answer: qanda.answer,
        company: qanda.offer.projectCompany,
        attachment: qanda.attachments,
        published: qanda.published,
        updated: Math.max(...qanda.changes.map((o) => o.date)),
        number: qanda.number,
      });
    } else {
      data.push({
        _id: qanda._id,
        question: qanda.question,
        answer: qanda.answer,
        company: '',
        attachment: qanda.attachments,
        published: qanda.published,
        updated: Math.max(...qanda.changes.map((o) => o.date)),
        number: qanda.number,
      });
    }
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectQAndA('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageproject"
                onClick={() => selectQAndA('')}
              >
                Übersicht
              </Link>
              <Typography color="textPrimary">Q&A</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({ download: true, downloadOptions: { filename: 'Q_A_export.csv', filterOptions: { useDisplayedColumnsOnly: true } } })}
      />
      {projects.authorization !== 'read' && (
      <AddDialog
        projectId={projects._id}
        header="Frage zur Ausschreibung hinzufügen"
        text="Frage"
        field="question"
        onClick={addProjectQAndA}
      />
      )}
    </div>
  );
}

export default ProjectQAndAsTable;

ProjectQAndAsTable.propTypes = {
  qandas: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
  selectQAndA: PropTypes.func.isRequired,
  // selectedProject: PropTypes.string.isRequired,
  // user: PropTypes.shape(Object).isRequired,
  addProjectQAndA: PropTypes.func.isRequired,
  updateProjectQAndA: PropTypes.func.isRequired,
  // uploadProjectQAndAFile: PropTypes.func.isRequired,
  // removeProjectQAndAUpload: PropTypes.func.isRequired,
  toggleQAndAPublished: PropTypes.func.isRequired,
  deleteProjectQAndA: PropTypes.func.isRequired,
};
