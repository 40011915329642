import axios from 'axios';
import {
  PROJECT_FETCH_PROJECTS_BEGIN,
  PROJECT_FETCH_PROJECTS_SUCCESS,
  PROJECT_FETCH_PROJECTS_FAILURE,
  PROJECT_FETCH_TEMPLATES_BEGIN,
  PROJECT_FETCH_TEMPLATES_SUCCESS,
  PROJECT_FETCH_TEMPLATES_FAILURE,
  PROJECT_FETCH_MODULES_BEGIN,
  PROJECT_FETCH_MODULES_SUCCESS,
  PROJECT_FETCH_MODULES_FAILURE,
  PROJECT_FETCH_STATEMENTS_BEGIN,
  PROJECT_FETCH_STATEMENTS_SUCCESS,
  PROJECT_FETCH_STATEMENTS_FAILURE,
  PROJECT_SELECT_PROJECT,
  PROJECT_SELECT_TEMPLATE,
  PROJECT_SELECT_MODULE,
  PROJECT_SELECT_STATEMENT,
  PROJECT_CLEAR_PROJECTS_ARRAY,
  PROJECT_CLEAR_STATEMENTS_ARRAY,
  PROJECT_CLEAR_MODULES_ARRAY,
  PROJECT_CLEAR_TEMPLATES_ARRAY,
  SELECT_PROJECT_TEMPLATE,
  SELECT_PROJECT_MODULE,
  REMOVE_PROJECT_TEMPLATE,
  REMOVE_PROJECT_MODULE,
  CREATE_PROJECT_BEGIN,
  CREATE_PROJECT_SUCCESS,
  CREATE_PROJECT_FAILURE,
  CLEAR_PROJECT_TEMPLATES,
  CLEAR_PROJECT_MODULES,
  FETCH_PROJECT_EDITORS_BEGIN,
  FETCH_PROJECT_EDITORS_SUCCESS,
  FETCH_PROJECT_EDITORS_FAILURE,
  UPDATE_EDITORS_BEGIN,
  UPDATE_EDITORS_SUCCESS,
  UPDATE_EDITORS_FAILURE,
  FETCH_PROJECT_VIEWERS_BEGIN,
  FETCH_PROJECT_VIEWERS_SUCCESS,
  FETCH_PROJECT_VIEWERS_FAILURE,
  UPDATE_VIEWERS_BEGIN,
  UPDATE_VIEWERS_SUCCESS,
  UPDATE_VIEWERS_FAILURE,
  FETCH_PROJECT_CONTRACTORS_BEGIN,
  FETCH_PROJECT_CONTRACTORS_SUCCESS,
  FETCH_PROJECT_CONTRACTORS_FAILURE,
  UPDATE_CONTRACTORS_BEGIN,
  UPDATE_CONTRACTORS_SUCCESS,
  UPDATE_CONTRACTORS_FAILURE,
  CHANGE_PUBLISHED_PROJECT_BEGIN,
  CHANGE_PUBLISHED_PROJECT_SUCCESS,
  CHANGE_PUBLISHED_PROJECT_FAILURE,
  COPY_PROJECT_TO_DATABASE_BEGIN,
  COPY_PROJECT_TO_DATABASE_SUCCESS,
  COPY_PROJECT_TO_DATABASE_FAILURE,
  PROJECT_DELETE_PROJECT_BEGIN,
  PROJECT_DELETE_PROJECT_SUCCESS,
  PROJECT_DELETE_PROJECT_FAILURE,
  PROJECT_UPDATE_PROJECT_BEGIN,
  PROJECT_UPDATE_PROJECT_SUCCESS,
  PROJECT_UPDATE_PROJECT_FAILURE,
  ADD_PROJECT_TEMPLATE_BEGIN,
  ADD_PROJECT_TEMPLATE_SUCCESS,
  ADD_PROJECT_TEMPLATE_FAILURE,
  ADD_PROJECT_MODULE_BEGIN,
  ADD_PROJECT_MODULE_SUCCESS,
  ADD_PROJECT_MODULE_FAILURE,
  ADD_PROJECT_STATEMENT_BEGIN,
  ADD_PROJECT_STATEMENT_SUCCESS,
  ADD_PROJECT_STATEMENT_FAILURE,
  UPDATE_PROJECT_TEMPLATE_BEGIN,
  UPDATE_PROJECT_TEMPLATE_SUCCESS,
  UPDATE_PROJECT_TEMPLATE_FAILURE,
  UPDATE_PROJECT_MODULE_BEGIN,
  UPDATE_PROJECT_MODULE_SUCCESS,
  UPDATE_PROJECT_MODULE_FAILURE,
  UPDATE_PROJECT_STATEMENT_BEGIN,
  UPDATE_PROJECT_STATEMENT_SUCCESS,
  UPDATE_PROJECT_STATEMENT_FAILURE,
  DELETE_PROJECT_TEMPLATE_BEGIN,
  DELETE_PROJECT_TEMPLATE_SUCCESS,
  DELETE_PROJECT_TEMPLATE_FAILURE,
  DELETE_PROJECT_MODULE_BEGIN,
  DELETE_PROJECT_MODULE_SUCCESS,
  DELETE_PROJECT_MODULE_FAILURE,
  DELETE_PROJECT_STATEMENT_BEGIN,
  DELETE_PROJECT_STATEMENT_SUCCESS,
  DELETE_PROJECT_STATEMENT_FAILURE,
  PROJECT_STATEMENT_NUMBER_DOWN_BEGIN,
  PROJECT_STATEMENT_NUMBER_DOWN_SUCCESS,
  PROJECT_STATEMENT_NUMBER_DOWN_FAILURE,
  PROJECT_STATEMENT_NUMBER_UP_BEGIN,
  PROJECT_STATEMENT_NUMBER_UP_SUCCESS,
  PROJECT_STATEMENT_NUMBER_UP_FAILURE,
  PROJECT_MODULE_NUMBER_DOWN_BEGIN,
  PROJECT_MODULE_NUMBER_DOWN_SUCCESS,
  PROJECT_MODULE_NUMBER_DOWN_FAILURE,
  PROJECT_MODULE_NUMBER_UP_BEGIN,
  PROJECT_MODULE_NUMBER_UP_SUCCESS,
  PROJECT_MODULE_NUMBER_UP_FAILURE,
  PROJECT_TEMPLATE_NUMBER_DOWN_BEGIN,
  PROJECT_TEMPLATE_NUMBER_DOWN_SUCCESS,
  PROJECT_TEMPLATE_NUMBER_DOWN_FAILURE,
  PROJECT_TEMPLATE_NUMBER_UP_BEGIN,
  PROJECT_TEMPLATE_NUMBER_UP_SUCCESS,
  PROJECT_TEMPLATE_NUMBER_UP_FAILURE,
  FETCH_OVERVIEW_TEMPLATE_BEGIN,
  FETCH_OVERVIEW_TEMPLATE_SUCCESS,
  FETCH_OVERVIEW_TEMPLATE_FAILURE,
  FETCH_OVERVIEW_MODULES_BEGIN,
  FETCH_OVERVIEW_MODULES_SUCCESS,
  FETCH_OVERVIEW_MODULES_FAILURE,
  FETCH_OVERVIEW_STATEMENTS_BEGIN,
  FETCH_OVERVIEW_STATEMENTS_SUCCESS,
  FETCH_OVERVIEW_STATEMENTS_FAILURE,
  UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_BEGIN,
  UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_SUCCESS,
  UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_FAILURE,
  UPDATE_PROJECT_MODULE_PRICING_BEGIN,
  UPDATE_PROJECT_MODULE_PRICING_SUCCESS,
  UPDATE_PROJECT_MODULE_PRICING_FAILURE,
  UPDATE_PROJECT_MODULE_SLA_BEGIN,
  UPDATE_PROJECT_MODULE_SLA_SUCCESS,
  UPDATE_PROJECT_MODULE_SLA_FAILURE,
  FETCH_PROJECT_SLAS_BEGIN,
  FETCH_PROJECT_SLAS_SUCCESS,
  FETCH_PROJECT_SLAS_FAILURE,
  CLEAR_PROJECT_SLAS_ARRAY,
  ADD_PROJECT_SLA_BEGIN,
  ADD_PROJECT_SLA_SUCCESS,
  ADD_PROJECT_SLA_FAILURE,
  UPDATE_PROJECT_SLA_BEGIN,
  UPDATE_PROJECT_SLA_SUCCESS,
  UPDATE_PROJECT_SLA_FAILURE,
  DELETE_PROJECT_SLA_BEGIN,
  DELETE_PROJECT_SLA_SUCCESS,
  DELETE_PROJECT_SLA_FAILURE,
  SELECT_PROJECT_RATECARD,
  SELECT_PROJECT_TANDM,
  SELECT_PROJECT_FIXEDPRICE,
  SELECT_PROJECT_PIECEPRICE,
  SELECT_PROJECT_SLA,
  UPLOAD_PROJECT_PROJECT_FILE_BEGIN,
  UPLOAD_PROJECT_PROJECT_FILE_SUCCESS,
  UPLOAD_PROJECT_PROJECT_FILE_FAILURE,
  REMOVE_PROJECT_PROJECT_UPLOADS_BEGIN,
  REMOVE_PROJECT_PROJECT_UPLOADS_SUCCESS,
  REMOVE_PROJECT_PROJECT_UPLOADS_FAILURE,
  UPLOAD_PROJECT_TEMPLATE_FILE_BEGIN,
  UPLOAD_PROJECT_TEMPLATE_FILE_SUCCESS,
  UPLOAD_PROJECT_TEMPLATE_FILE_FAILURE,
  REMOVE_PROJECT_TEMPLATE_UPLOADS_BEGIN,
  REMOVE_PROJECT_TEMPLATE_UPLOADS_SUCCESS,
  REMOVE_PROJECT_TEMPLATE_UPLOADS_FAILURE,
  UPLOAD_PROJECT_MODULE_FILE_BEGIN,
  UPLOAD_PROJECT_MODULE_FILE_SUCCESS,
  UPLOAD_PROJECT_MODULE_FILE_FAILURE,
  REMOVE_PROJECT_MODULE_UPLOADS_BEGIN,
  REMOVE_PROJECT_MODULE_UPLOADS_SUCCESS,
  REMOVE_PROJECT_MODULE_UPLOADS_FAILURE,
  UPLOAD_PROJECT_STATEMENT_FILE_BEGIN,
  UPLOAD_PROJECT_STATEMENT_FILE_SUCCESS,
  UPLOAD_PROJECT_STATEMENT_FILE_FAILURE,
  REMOVE_PROJECT_STATEMENT_UPLOADS_BEGIN,
  REMOVE_PROJECT_STATEMENT_UPLOADS_SUCCESS,
  REMOVE_PROJECT_STATEMENT_UPLOADS_FAILURE,
  UPLOAD_PROJECT_QANDA_FILE_BEGIN,
  UPLOAD_PROJECT_QANDA_FILE_SUCCESS,
  UPLOAD_PROJECT_QANDA_FILE_FAILURE,
  REMOVE_PROJECT_QANDA_UPLOADS_BEGIN,
  REMOVE_PROJECT_QANDA_UPLOADS_SUCCESS,
  REMOVE_PROJECT_QANDA_UPLOADS_FAILURE,
  FETCH_PROJECT_RATECARD_BEGIN,
  FETCH_PROJECT_RATECARD_SUCCESS,
  FETCH_PROJECT_RATECARD_FAILURE,
  FETCH_PROJECT_FIXEDPRICES_BEGIN,
  FETCH_PROJECT_FIXEDPRICES_SUCCESS,
  FETCH_PROJECT_FIXEDPRICES_FAILURE,
  FETCH_PROJECT_PIECEPRICES_BEGIN,
  FETCH_PROJECT_PIECEPRICES_SUCCESS,
  FETCH_PROJECT_PIECEPRICES_FAILURE,
  FETCH_PROJECT_TANDMS_BEGIN,
  FETCH_PROJECT_TANDMS_SUCCESS,
  FETCH_PROJECT_TANDMS_FAILURE,
  ADD_PROJECT_RATECARD_BEGIN,
  ADD_PROJECT_RATECARD_SUCCESS,
  ADD_PROJECT_RATECARD_FAILURE,
  UPDATE_PROJECT_RATECARD_BEGIN,
  UPDATE_PROJECT_RATECARD_SUCCESS,
  UPDATE_PROJECT_RATECARD_FAILURE,
  DELETE_PROJECT_RATECARD_BEGIN,
  DELETE_PROJECT_RATECARD_SUCCESS,
  DELETE_PROJECT_RATECARD_FAILURE,
  ADD_PROJECT_PIECEPRICE_BEGIN,
  ADD_PROJECT_PIECEPRICE_SUCCESS,
  ADD_PROJECT_PIECEPRICE_FAILURE,
  DELETE_PROJECT_PIECEPRICE_BEGIN,
  DELETE_PROJECT_PIECEPRICE_SUCCESS,
  DELETE_PROJECT_PIECEPRICE_FAILURE,
  UPDATE_PROJECT_PIECEPRICE_BEGIN,
  UPDATE_PROJECT_PIECEPRICE_SUCCESS,
  UPDATE_PROJECT_PIECEPRICE_FAILURE,
  SELECT_PROJECT_INFORMATION,
  FETCH_PROJECT_INFORMATION_BEGIN,
  FETCH_PROJECT_INFORMATION_SUCCESS,
  FETCH_PROJECT_INFORMATION_FAILURE,
  ADD_PROJECT_INFORMATION_BEGIN,
  ADD_PROJECT_INFORMATION_SUCCESS,
  ADD_PROJECT_INFORMATION_FAILURE,
  UPDATE_PROJECT_INFORMATION_BEGIN,
  UPDATE_PROJECT_INFORMATION_SUCCESS,
  UPDATE_PROJECT_INFORMATION_FAILURE,
  DELETE_PROJECT_INFORMATION_BEGIN,
  DELETE_PROJECT_INFORMATION_SUCCESS,
  DELETE_PROJECT_INFORMATION_FAILURE,
  PROJECT_INFORMATION_NUMBER_DOWN_BEGIN,
  PROJECT_INFORMATION_NUMBER_DOWN_SUCCESS,
  PROJECT_INFORMATION_NUMBER_DOWN_FAILURE,
  PROJECT_INFORMATION_NUMBER_UP_BEGIN,
  PROJECT_INFORMATION_NUMBER_UP_SUCCESS,
  PROJECT_INFORMATION_NUMBER_UP_FAILURE,
  SELECT_PROJECT_REFERENCE,
  FETCH_PROJECT_REFERENCES_BEGIN,
  FETCH_PROJECT_REFERENCES_SUCCESS,
  FETCH_PROJECT_REFERENCES_FAILURE,
  ADD_PROJECT_REFERENCE_BEGIN,
  ADD_PROJECT_REFERENCE_SUCCESS,
  ADD_PROJECT_REFERENCE_FAILURE,
  DELETE_PROJECT_REFERENCE_BEGIN,
  DELETE_PROJECT_REFERENCE_SUCCESS,
  DELETE_PROJECT_REFERENCE_FAILURE,
  UPDATE_PROJECT_REFERENCE_BEGIN,
  UPDATE_PROJECT_REFERENCE_SUCCESS,
  UPDATE_PROJECT_REFERENCE_FAILURE,
  SELECT_PROJECT_SUBCONTRACTOR,
  FETCH_PROJECT_SUBCONTRACTORS_BEGIN,
  FETCH_PROJECT_SUBCONTRACTORS_SUCCESS,
  FETCH_PROJECT_SUBCONTRACTORS_FAILURE,
  ADD_PROJECT_SUBCONTRACTOR_BEGIN,
  ADD_PROJECT_SUBCONTRACTOR_SUCCESS,
  ADD_PROJECT_SUBCONTRACTOR_FAILURE,
  DELETE_PROJECT_SUBCONTRACTOR_BEGIN,
  DELETE_PROJECT_SUBCONTRACTOR_SUCCESS,
  DELETE_PROJECT_SUBCONTRACTOR_FAILURE,
  UPDATE_PROJECT_SUBCONTRACTOR_BEGIN,
  UPDATE_PROJECT_SUBCONTRACTOR_SUCCESS,
  UPDATE_PROJECT_SUBCONTRACTOR_FAILURE,
  FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_BEGIN,
  FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_SUCCESS,
  FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_FAILURE,
  SELECT_PROJECT_QANDA,
  FETCH_PROJECT_QANDAS_BEGIN,
  FETCH_PROJECT_QANDAS_SUCCESS,
  FETCH_PROJECT_QANDAS_FAILURE,
  ADD_PROJECT_QANDA_BEGIN,
  ADD_PROJECT_QANDA_SUCCESS,
  ADD_PROJECT_QANDA_FAILURE,
  UPDATE_PROJECT_QANDA_BEGIN,
  UPDATE_PROJECT_QANDA_SUCCESS,
  UPDATE_PROJECT_QANDA_FAILURE,
  DELETE_PROJECT_QANDA_BEGIN,
  DELETE_PROJECT_QANDA_SUCCESS,
  DELETE_PROJECT_QANDA_FAILURE,
  TOGGLE_QANDA_PUBLISHED_BEGIN,
  TOGGLE_QANDA_PUBLISHED_SUCCESS,
  TOGGLE_QANDA_PUBLISHED_FAILURE,
  MOVE_UP_PROJECT_REFERENCE_BEGIN,
  MOVE_UP_PROJECT_REFERENCE_SUCCESS,
  MOVE_UP_PROJECT_REFERENCE_FAILURE,
  MOVE_DOWN_PROJECT_REFERENCE_BEGIN,
  MOVE_DOWN_PROJECT_REFERENCE_SUCCESS,
  MOVE_DOWN_PROJECT_REFERENCE_FAILURE,
  MOVE_UP_PROJECT_SUBCONTRACTOR_BEGIN,
  MOVE_UP_PROJECT_SUBCONTRACTOR_SUCCESS,
  MOVE_UP_PROJECT_SUBCONTRACTOR_FAILURE,
  MOVE_DOWN_PROJECT_SUBCONTRACTOR_BEGIN,
  MOVE_DOWN_PROJECT_SUBCONTRACTOR_SUCCESS,
  MOVE_DOWN_PROJECT_SUBCONTRACTOR_FAILURE,
  MOVE_UP_PROJECT_INFORMATION_BEGIN,
  MOVE_UP_PROJECT_INFORMATION_SUCCESS,
  MOVE_UP_PROJECT_INFORMATION_FAILURE,
  MOVE_DOWN_PROJECT_INFORMATION_BEGIN,
  MOVE_DOWN_PROJECT_INFORMATION_SUCCESS,
  MOVE_DOWN_PROJECT_INFORMATION_FAILURE,
} from '../actionTypes/actionTypes';
import { enqueueSnackbar } from './notifierAction';

export const fetchProjectQAndAsBegin = () => ({
  type: FETCH_PROJECT_QANDAS_BEGIN,
});

export const fetchProjectQAndAsSuccess = (qandas) => ({
  type: FETCH_PROJECT_QANDAS_SUCCESS,
  payload: { qandas },
});

export const fetchProjectQAndAsFailure = (error) => ({
  type: FETCH_PROJECT_QANDAS_FAILURE,
  payload: { error },
});

export const fetchQAndABegin = () => ({
  type: FETCH_PROJECT_QANDAS_BEGIN,
});

export const fetchQAndASuccess = (qandas) => ({
  type: FETCH_PROJECT_QANDAS_SUCCESS,
  payload: { qandas },
});

export const fetchQAndAFailure = (error) => ({
  type: FETCH_PROJECT_QANDAS_FAILURE,
  payload: { error },
});

export const addProjectQAndABegin = () => ({
  type: ADD_PROJECT_QANDA_BEGIN,
});

export const addProjectQAndASuccess = () => ({
  type: ADD_PROJECT_QANDA_SUCCESS,
});

export const addProjectQAndAFailure = (error) => ({
  type: ADD_PROJECT_QANDA_FAILURE,
  payload: { error },
});

export const updateProjectQAndABegin = () => ({
  type: UPDATE_PROJECT_QANDA_BEGIN,
});

export const updateProjectQAndASuccess = () => ({
  type: UPDATE_PROJECT_QANDA_SUCCESS,
});

export const updateProjectQAndAFailure = (error) => ({
  type: UPDATE_PROJECT_QANDA_FAILURE,
  payload: { error },
});

export const deleteProjectQAndABegin = () => ({
  type: DELETE_PROJECT_QANDA_BEGIN,
});

export const deleteProjectQAndASuccess = (qanda) => ({
  type: DELETE_PROJECT_QANDA_SUCCESS,
  payload: { qanda },
});

export const deleteProjectQAndAFailure = (error) => ({
  type: DELETE_PROJECT_QANDA_FAILURE,
  payload: { error },
});

export const toggleQAndAPublishedBegin = () => ({
  type: TOGGLE_QANDA_PUBLISHED_BEGIN,
});

export const toggleQAndAPublishedSuccess = () => ({
  type: TOGGLE_QANDA_PUBLISHED_SUCCESS,
});

export const toggleQAndAPublishedFailure = (error) => ({
  type: TOGGLE_QANDA_PUBLISHED_FAILURE,
  payload: { error },
});

export const fetchAdditionalFilesIsShowingBegin = () => ({
  type: FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_BEGIN,
});

export const fetchAdditionalFilesIsShowingSuccess = (isShowing) => ({
  type: FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_SUCCESS,
  payload: { isShowing },
});

export const fetchAdditionalFilesIsShowingFailure = (error) => ({
  type: FETCH_PROJECT_ADDITIONAL_FILES_IS_SHOWING_FAILURE,
  payload: { error },
});

export const updateProjectSubcontractorBegin = () => ({
  type: UPDATE_PROJECT_SUBCONTRACTOR_BEGIN,
});

export const updateProjectSubcontractorSuccess = (subcontractor) => ({
  type: UPDATE_PROJECT_SUBCONTRACTOR_SUCCESS,
  payload: { subcontractor },
});

export const updateProjectSubcontractorFailure = (error) => ({
  type: UPDATE_PROJECT_SUBCONTRACTOR_FAILURE,
  payload: { error },
});

export const deleteProjectSubcontractorBegin = () => ({
  type: DELETE_PROJECT_SUBCONTRACTOR_BEGIN,
});

export const deleteProjectSubcontractorSuccess = (subcontractor) => ({
  type: DELETE_PROJECT_SUBCONTRACTOR_SUCCESS,
  payload: { subcontractor },
});

export const deleteProjectSubcontractorFailure = (error) => ({
  type: DELETE_PROJECT_SUBCONTRACTOR_FAILURE,
  payload: { error },
});

export const addProjectSubcontractorBegin = () => ({
  type: ADD_PROJECT_SUBCONTRACTOR_BEGIN,
});

export const addProjectSubcontractorSuccess = (subcontractor) => ({
  type: ADD_PROJECT_SUBCONTRACTOR_SUCCESS,
  payload: { subcontractor },
});

export const addProjectSubcontractorFailure = (error) => ({
  type: ADD_PROJECT_SUBCONTRACTOR_FAILURE,
  payload: { error },
});

export const selectSubcontractor = (subcontractor) => ({
  type: SELECT_PROJECT_SUBCONTRACTOR,
  payload: { subcontractor },
});

export const fetchProjectSubcontractorsBegin = () => ({
  type: FETCH_PROJECT_SUBCONTRACTORS_BEGIN,
});

export const fetchProjectSubcontractorsSuccess = (subcontractors) => ({
  type: FETCH_PROJECT_SUBCONTRACTORS_SUCCESS,
  payload: { subcontractors },
});

export const fetchProjectSubcontractorsFailure = (error) => ({
  type: FETCH_PROJECT_SUBCONTRACTORS_FAILURE,
  payload: { error },
});

export const updateProjectReferenceBegin = () => ({
  type: UPDATE_PROJECT_REFERENCE_BEGIN,
});

export const updateProjectReferenceSuccess = (reference) => ({
  type: UPDATE_PROJECT_REFERENCE_SUCCESS,
  payload: { reference },
});

export const updateProjectReferenceFailure = (error) => ({
  type: UPDATE_PROJECT_REFERENCE_FAILURE,
  payload: { error },
});

export const deleteProjectReferenceBegin = () => ({
  type: DELETE_PROJECT_REFERENCE_BEGIN,
});

export const deleteProjectReferenceSuccess = (reference) => ({
  type: DELETE_PROJECT_REFERENCE_SUCCESS,
  payload: { reference },
});

export const deleteProjectReferenceFailure = (error) => ({
  type: DELETE_PROJECT_REFERENCE_FAILURE,
  payload: { error },
});

export const addProjectReferenceBegin = () => ({
  type: ADD_PROJECT_REFERENCE_BEGIN,
});

export const addProjectReferenceSuccess = (reference) => ({
  type: ADD_PROJECT_REFERENCE_SUCCESS,
  payload: { reference },
});

export const addProjectReferenceFailure = (error) => ({
  type: ADD_PROJECT_REFERENCE_FAILURE,
  payload: { error },
});

export const selectReference = (reference) => ({
  type: SELECT_PROJECT_REFERENCE,
  payload: { reference },
});

export const fetchProjectReferencesBegin = () => ({
  type: FETCH_PROJECT_REFERENCES_BEGIN,
});

export const fetchProjectReferencesSuccess = (references) => ({
  type: FETCH_PROJECT_REFERENCES_SUCCESS,
  payload: { references },
});

export const fetchProjectReferencesFailure = (error) => ({
  type: FETCH_PROJECT_REFERENCES_FAILURE,
  payload: { error },
});

export const updateProjectInformationBegin = () => ({
  type: UPDATE_PROJECT_INFORMATION_BEGIN,
});

export const updateProjectInformationSuccess = () => ({
  type: UPDATE_PROJECT_INFORMATION_SUCCESS,
});

export const updateProjectInformationFailure = (error) => ({
  type: UPDATE_PROJECT_INFORMATION_FAILURE,
  payload: { error },
});

export const deleteProjectInformationBegin = () => ({
  type: DELETE_PROJECT_INFORMATION_BEGIN,
});

export const deleteProjectInformationSuccess = () => ({
  type: DELETE_PROJECT_INFORMATION_SUCCESS,
});

export const deleteProjectInformationFailure = (error) => ({
  type: DELETE_PROJECT_INFORMATION_FAILURE,
  payload: { error },
});

export const fetchProjectInformationBegin = () => ({
  type: FETCH_PROJECT_INFORMATION_BEGIN,
});

export const fetchProjectInformationSuccess = (information) => ({
  type: FETCH_PROJECT_INFORMATION_SUCCESS,
  payload: { information },
});

export const fetchProjectInformationFailure = (error) => ({
  type: FETCH_PROJECT_INFORMATION_FAILURE,
  payload: { error },
});

export const addProjectInformationBegin = () => ({
  type: ADD_PROJECT_INFORMATION_BEGIN,
});

export const addProjectInformationSuccess = () => ({
  type: ADD_PROJECT_INFORMATION_SUCCESS,
});

export const addProjectInformationFailure = (error) => ({
  type: ADD_PROJECT_INFORMATION_FAILURE,
  payload: { error },
});

export const selectInformation = (id) => ({
  type: SELECT_PROJECT_INFORMATION,
  payload: { id },
});

export const projectInformationNumberDownBegin = () => ({
  type: PROJECT_INFORMATION_NUMBER_DOWN_BEGIN,
});

export const projectInformationNumberDownSuccess = () => ({
  type: PROJECT_INFORMATION_NUMBER_DOWN_SUCCESS,
});

export const projectInformationNumberDownFailure = (error) => ({
  type: PROJECT_INFORMATION_NUMBER_DOWN_FAILURE,
  payload: { error },
});

export const projectInformationNumberUpBegin = () => ({
  type: PROJECT_INFORMATION_NUMBER_UP_BEGIN,
});

export const projectInformationNumberUpSuccess = () => ({
  type: PROJECT_INFORMATION_NUMBER_UP_SUCCESS,
});

export const projectInformationNumberUpFailure = (error) => ({
  type: PROJECT_INFORMATION_NUMBER_UP_FAILURE,
  payload: { error },
});
export const addPiecePriceBegin = () => ({
  type: ADD_PROJECT_PIECEPRICE_BEGIN,
});

export const addPiecePriceSuccess = () => ({
  type: ADD_PROJECT_PIECEPRICE_SUCCESS,
});

export const addPiecePriceFailure = (error) => ({
  type: ADD_PROJECT_PIECEPRICE_FAILURE,
  payload: { error },
});
export const deletePiecePriceBegin = () => ({
  type: DELETE_PROJECT_PIECEPRICE_BEGIN,
});

export const deletePiecePriceSuccess = () => ({
  type: DELETE_PROJECT_PIECEPRICE_SUCCESS,
});

export const deletePiecePriceFailure = (error) => ({
  type: DELETE_PROJECT_PIECEPRICE_FAILURE,
  payload: { error },
});

export const updatePiecePriceBegin = () => ({
  type: UPDATE_PROJECT_PIECEPRICE_BEGIN,
});

export const updatePiecePriceSuccess = () => ({
  type: UPDATE_PROJECT_PIECEPRICE_SUCCESS,
});

export const updatePiecePriceFailure = (error) => ({
  type: UPDATE_PROJECT_PIECEPRICE_FAILURE,
  payload: { error },
});

export const deleteRatecardBegin = () => ({
  type: DELETE_PROJECT_RATECARD_BEGIN,
});

export const deleteRatecardSuccess = () => ({
  type: DELETE_PROJECT_RATECARD_SUCCESS,
});

export const deleteRatecardFailure = (error) => ({
  type: DELETE_PROJECT_RATECARD_FAILURE,
  payload: { error },
});

export const updateRatecardBegin = () => ({
  type: UPDATE_PROJECT_RATECARD_BEGIN,
});

export const updateRatecardSuccess = () => ({
  type: UPDATE_PROJECT_RATECARD_SUCCESS,
});

export const updateRatecardFailure = (error) => ({
  type: UPDATE_PROJECT_RATECARD_FAILURE,
  payload: { error },
});

export const addRatecardBegin = () => ({
  type: ADD_PROJECT_RATECARD_BEGIN,
});

export const addRatecardSuccess = () => ({
  type: ADD_PROJECT_RATECARD_SUCCESS,
});

export const addRatecardFailure = (error) => ({
  type: ADD_PROJECT_RATECARD_FAILURE,
  payload: { error },
});

export const addSlaBegin = () => ({
  type: ADD_PROJECT_SLA_BEGIN,
});

export const addSlaSuccess = () => ({
  type: ADD_PROJECT_SLA_SUCCESS,
});

export const addSlaFailure = (error) => ({
  type: ADD_PROJECT_SLA_FAILURE,
  payload: { error },
});

export const deleteSlaBegin = () => ({
  type: DELETE_PROJECT_SLA_BEGIN,
});

export const deleteSlaSuccess = () => ({
  type: DELETE_PROJECT_SLA_SUCCESS,
});

export const deleteSlaFailure = (error) => ({
  type: DELETE_PROJECT_SLA_FAILURE,
  payload: { error },
});

export const fetchProjectRatecardBegin = () => ({
  type: FETCH_PROJECT_RATECARD_BEGIN,
});

export const fetchProjectRatecardSuccess = (ratecards) => ({
  type: FETCH_PROJECT_RATECARD_SUCCESS,
  payload: { ratecards },
});

export const fetchProjectRatecardFailure = (error) => ({
  type: FETCH_PROJECT_RATECARD_FAILURE,
  payload: { error },
});

export const selectRatecard = (id) => ({
  type: SELECT_PROJECT_RATECARD,
  payload: { id },
});

export const selectPiecePrice = (piecePrice) => ({
  type: SELECT_PROJECT_PIECEPRICE,
  payload: { piecePrice },
});

export const selectTAndM = (tandm) => ({
  type: SELECT_PROJECT_TANDM,
  payload: { tandm },
});

export const selectFixedPrice = (fixedPrice) => ({
  type: SELECT_PROJECT_FIXEDPRICE,
  payload: { fixedPrice },
});

export const selectSla = (sla) => ({
  type: SELECT_PROJECT_SLA,
  payload: { sla },
});

export const removeProjectProjectUploadBegin = () => ({
  type: REMOVE_PROJECT_PROJECT_UPLOADS_BEGIN,
});

export const removeProjectProjectUploadSuccess = () => ({
  type: REMOVE_PROJECT_PROJECT_UPLOADS_SUCCESS,
});

export const removeProjectProjectUploadFailure = (error) => ({
  type: REMOVE_PROJECT_PROJECT_UPLOADS_FAILURE,
  payload: { error },
});

export const uploadProjectProjectFileBegin = () => ({
  type: UPLOAD_PROJECT_PROJECT_FILE_BEGIN,
});

export const uploadProjectProjectFileSuccess = () => ({
  type: UPLOAD_PROJECT_PROJECT_FILE_SUCCESS,
});

export const uploadProjectProjectFileFailure = (error) => ({
  type: UPLOAD_PROJECT_PROJECT_FILE_FAILURE,
  payload: { error },
});

export const removeProjectTemplateUploadBegin = () => ({
  type: REMOVE_PROJECT_TEMPLATE_UPLOADS_BEGIN,
});

export const removeProjectTemplateUploadSuccess = () => ({
  type: REMOVE_PROJECT_TEMPLATE_UPLOADS_SUCCESS,
});

export const removeProjectTemplateUploadFailure = (error) => ({
  type: REMOVE_PROJECT_TEMPLATE_UPLOADS_FAILURE,
  payload: { error },
});

export const uploadProjectTemplateFileBegin = () => ({
  type: UPLOAD_PROJECT_TEMPLATE_FILE_BEGIN,
});

export const uploadProjectTemplateFileSuccess = () => ({
  type: UPLOAD_PROJECT_TEMPLATE_FILE_SUCCESS,
});

export const uploadProjectTemplateFileFailure = (error) => ({
  type: UPLOAD_PROJECT_TEMPLATE_FILE_FAILURE,
  payload: { error },
});

export const removeProjectModuleUploadBegin = () => ({
  type: REMOVE_PROJECT_MODULE_UPLOADS_BEGIN,
});

export const removeProjectModuleUploadSuccess = () => ({
  type: REMOVE_PROJECT_MODULE_UPLOADS_SUCCESS,
});

export const removeProjectModuleUploadFailure = (error) => ({
  type: REMOVE_PROJECT_MODULE_UPLOADS_FAILURE,
  payload: { error },
});

export const uploadProjectModuleFileBegin = () => ({
  type: UPLOAD_PROJECT_MODULE_FILE_BEGIN,
});

export const uploadProjectModuleFileSuccess = () => ({
  type: UPLOAD_PROJECT_MODULE_FILE_SUCCESS,
});

export const uploadProjectModuleFileFailure = (error) => ({
  type: UPLOAD_PROJECT_MODULE_FILE_FAILURE,
  payload: { error },
});

export const removeProjectStatementUploadBegin = () => ({
  type: REMOVE_PROJECT_STATEMENT_UPLOADS_BEGIN,
});

export const removeProjectStatementUploadSuccess = () => ({
  type: REMOVE_PROJECT_STATEMENT_UPLOADS_SUCCESS,
});

export const removeProjectStatementUploadFailure = (error) => ({
  type: REMOVE_PROJECT_STATEMENT_UPLOADS_FAILURE,
  payload: { error },
});

export const uploadProjectStatementFileBegin = () => ({
  type: UPLOAD_PROJECT_STATEMENT_FILE_BEGIN,
});

export const uploadProjectStatementFileSuccess = () => ({
  type: UPLOAD_PROJECT_STATEMENT_FILE_SUCCESS,
});

export const uploadProjectStatementFileFailure = (error) => ({
  type: UPLOAD_PROJECT_STATEMENT_FILE_FAILURE,
  payload: { error },
});

export const removeProjectQAndAUploadBegin = () => ({
  type: REMOVE_PROJECT_QANDA_UPLOADS_BEGIN,
});

export const removeProjectQAndAUploadSuccess = () => ({
  type: REMOVE_PROJECT_QANDA_UPLOADS_SUCCESS,
});

export const removeProjectQAndAUploadFailure = (error) => ({
  type: REMOVE_PROJECT_QANDA_UPLOADS_FAILURE,
  payload: { error },
});

export const uploadProjectQAndAFileBegin = () => ({
  type: UPLOAD_PROJECT_QANDA_FILE_BEGIN,
});

export const uploadProjectQAndAFileSuccess = () => ({
  type: UPLOAD_PROJECT_QANDA_FILE_SUCCESS,
});

export const uploadProjectQAndAFileFailure = (error) => ({
  type: UPLOAD_PROJECT_QANDA_FILE_FAILURE,
  payload: { error },
});

export const updateSlaBegin = () => ({
  type: UPDATE_PROJECT_SLA_BEGIN,
});

export const updateSlaSuccess = () => ({
  type: UPDATE_PROJECT_SLA_SUCCESS,
});

export const updateSlaFailure = (error) => ({
  type: UPDATE_PROJECT_SLA_FAILURE,
  payload: { error },
});

export const clearSlas = () => ({
  type: CLEAR_PROJECT_SLAS_ARRAY,
});

export const fetchSlasBegin = () => ({
  type: FETCH_PROJECT_SLAS_BEGIN,
});

export const fetchSlasSuccess = (slas) => ({
  type: FETCH_PROJECT_SLAS_SUCCESS,
  payload: { slas },
});

export const fetchSlasFailure = (error) => ({
  type: FETCH_PROJECT_SLAS_FAILURE,
  payload: { error },
});

export const updateModuleSlaBegin = () => ({
  type: UPDATE_PROJECT_MODULE_SLA_BEGIN,
});

export const updateModuleSlaSuccess = () => ({
  type: UPDATE_PROJECT_MODULE_SLA_SUCCESS,
});

export const updateModuleSlaFailure = (error) => ({
  type: UPDATE_PROJECT_MODULE_SLA_FAILURE,
  payload: { error },
});

export const updateModulePricingBegin = () => ({
  type: UPDATE_PROJECT_MODULE_PRICING_BEGIN,
});

export const updateModulePricingSuccess = () => ({
  type: UPDATE_PROJECT_MODULE_PRICING_SUCCESS,
});

export const updateModulePricingFailure = (error) => ({
  type: UPDATE_PROJECT_MODULE_PRICING_FAILURE,
  payload: { error },
});

export const updateStatementResponsibilitiesBegin = () => ({
  type: UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_BEGIN,
});

export const updateStatementResponsibilitiesSuccess = () => ({
  type: UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_SUCCESS,
});

export const updateStatementResponsibilitiesFailure = (error) => ({
  type: UPDATE_PROJECT_STATEMENT_RESPONSIBILITIES_FAILURE,
  payload: { error },
});

export const fetchOverviewTemplateBegin = () => ({
  type: FETCH_OVERVIEW_TEMPLATE_BEGIN,
});

export const fetchOverviewTemplateSuccess = (template) => ({
  type: FETCH_OVERVIEW_TEMPLATE_SUCCESS,
  payload: { template },
});

export const fetchOverviewTemplateFailure = (error) => ({
  type: FETCH_OVERVIEW_TEMPLATE_FAILURE,
  payload: { error },
});

export const fetchOverviewModulesBegin = () => ({
  type: FETCH_OVERVIEW_MODULES_BEGIN,
});

export const fetchOverviewModulesSuccess = (modules) => ({
  type: FETCH_OVERVIEW_MODULES_SUCCESS,
  payload: { modules },
});

export const fetchOverviewModulesFailure = (error) => ({
  type: FETCH_OVERVIEW_MODULES_FAILURE,
  payload: { error },
});

export const fetchOverviewStatementsBegin = () => ({
  type: FETCH_OVERVIEW_STATEMENTS_BEGIN,
});

export const fetchOverviewStatementsSuccess = (statements) => ({
  type: FETCH_OVERVIEW_STATEMENTS_SUCCESS,
  payload: { statements },
});

export const fetchOverviewStatementsFailure = (error) => ({
  type: FETCH_OVERVIEW_STATEMENTS_FAILURE,
  payload: { error },
});

export const statementNumberDownBegin = () => ({
  type: PROJECT_STATEMENT_NUMBER_DOWN_BEGIN,
});

export const statementNumberDownSuccess = () => ({
  type: PROJECT_STATEMENT_NUMBER_DOWN_SUCCESS,
});

export const statementNumberDownFailure = (error) => ({
  type: PROJECT_STATEMENT_NUMBER_DOWN_FAILURE,
  payload: { error },
});

export const statementNumberUpBegin = () => ({
  type: PROJECT_STATEMENT_NUMBER_UP_BEGIN,
});

export const statementNumberUpSuccess = () => ({
  type: PROJECT_STATEMENT_NUMBER_UP_SUCCESS,
});

export const statementNumberUpFailure = (error) => ({
  type: PROJECT_STATEMENT_NUMBER_UP_FAILURE,
  payload: { error },
});

export const moduleNumberDownBegin = () => ({
  type: PROJECT_MODULE_NUMBER_DOWN_BEGIN,
});

export const moduleNumberDownSuccess = () => ({
  type: PROJECT_MODULE_NUMBER_DOWN_SUCCESS,
});

export const moduleNumberDownFailure = (error) => ({
  type: PROJECT_MODULE_NUMBER_DOWN_FAILURE,
  payload: { error },
});

export const moduleNumberUpBegin = () => ({
  type: PROJECT_MODULE_NUMBER_UP_BEGIN,
});

export const moduleNumberUpSuccess = () => ({
  type: PROJECT_MODULE_NUMBER_UP_SUCCESS,
});

export const moduleNumberUpFailure = (error) => ({
  type: PROJECT_MODULE_NUMBER_UP_FAILURE,
  payload: { error },
});

export const templateNumberDownBegin = () => ({
  type: PROJECT_TEMPLATE_NUMBER_DOWN_BEGIN,
});

export const templateNumberDownSuccess = () => ({
  type: PROJECT_TEMPLATE_NUMBER_DOWN_SUCCESS,
});

export const templateNumberDownFailure = (error) => ({
  type: PROJECT_TEMPLATE_NUMBER_DOWN_FAILURE,
  payload: { error },
});

export const templateNumberUpBegin = () => ({
  type: PROJECT_TEMPLATE_NUMBER_UP_BEGIN,
});

export const templateNumberUpSuccess = () => ({
  type: PROJECT_TEMPLATE_NUMBER_UP_SUCCESS,
});

export const templateNumberUpFailure = (error) => ({
  type: PROJECT_TEMPLATE_NUMBER_UP_FAILURE,
  payload: { error },
});

export const addTemplateBegin = () => ({
  type: ADD_PROJECT_TEMPLATE_BEGIN,
});

export const addTemplateSuccess = () => ({
  type: ADD_PROJECT_TEMPLATE_SUCCESS,
});

export const addTemplateFailure = (error) => ({
  type: ADD_PROJECT_TEMPLATE_FAILURE,
  payload: { error },
});

export const addModuleBegin = () => ({
  type: ADD_PROJECT_MODULE_BEGIN,
});

export const addModuleSuccess = () => ({
  type: ADD_PROJECT_MODULE_SUCCESS,
});

export const addModuleFailure = (error) => ({
  type: ADD_PROJECT_MODULE_FAILURE,
  payload: { error },
});

export const addStatementBegin = () => ({
  type: ADD_PROJECT_STATEMENT_BEGIN,
});

export const addStatementSuccess = () => ({
  type: ADD_PROJECT_STATEMENT_SUCCESS,
});

export const addStatementFailure = (error) => ({
  type: ADD_PROJECT_STATEMENT_FAILURE,
  payload: { error },
});

export const updateTemplateBegin = () => ({
  type: UPDATE_PROJECT_TEMPLATE_BEGIN,
});

export const updateTemplateSuccess = () => ({
  type: UPDATE_PROJECT_TEMPLATE_SUCCESS,
});

export const updateTemplateFailure = (error) => ({
  type: UPDATE_PROJECT_TEMPLATE_FAILURE,
  payload: { error },
});

export const updateModuleBegin = () => ({
  type: UPDATE_PROJECT_MODULE_BEGIN,
});

export const updateModuleSuccess = () => ({
  type: UPDATE_PROJECT_MODULE_SUCCESS,
});

export const updateModuleFailure = (error) => ({
  type: UPDATE_PROJECT_MODULE_FAILURE,
  payload: { error },
});

export const updateStatementBegin = () => ({
  type: UPDATE_PROJECT_STATEMENT_BEGIN,
});

export const updateStatementSuccess = (statement) => ({
  type: UPDATE_PROJECT_STATEMENT_SUCCESS,
  payload: { statement },
});

export const updateStatementFailure = (error) => ({
  type: UPDATE_PROJECT_STATEMENT_FAILURE,
  payload: { error },
});

export const deleteTemplateBegin = () => ({
  type: DELETE_PROJECT_TEMPLATE_BEGIN,
});

export const deleteTemplateSuccess = () => ({
  type: DELETE_PROJECT_TEMPLATE_SUCCESS,
});

export const deleteTemplateFailure = (error) => ({
  type: DELETE_PROJECT_TEMPLATE_FAILURE,
  payload: { error },
});

export const deleteModuleBegin = () => ({
  type: DELETE_PROJECT_MODULE_BEGIN,
});

export const deleteModuleSuccess = () => ({
  type: DELETE_PROJECT_MODULE_SUCCESS,
});

export const deleteModuleFailure = (error) => ({
  type: DELETE_PROJECT_MODULE_FAILURE,
  payload: { error },
});

export const deleteStatementBegin = () => ({
  type: DELETE_PROJECT_STATEMENT_BEGIN,
});

export const deleteStatementSuccess = () => ({
  type: DELETE_PROJECT_STATEMENT_SUCCESS,
});

export const deleteStatementFailure = (error) => ({
  type: DELETE_PROJECT_STATEMENT_FAILURE,
  payload: { error },
});

export const updateViewersBegin = () => ({
  type: UPDATE_VIEWERS_BEGIN,
});

export const updateViewersSuccess = () => ({
  type: UPDATE_VIEWERS_SUCCESS,
});

export const updateViewersFailure = (error) => ({
  type: UPDATE_VIEWERS_FAILURE,
  payload: { error },
});

export const fetchProjectViewersBegin = () => ({
  type: FETCH_PROJECT_VIEWERS_BEGIN,
});

export const fetchProjectViewersSuccess = (viewers) => ({
  type: FETCH_PROJECT_VIEWERS_SUCCESS,
  payload: { viewers },
});

export const fetchProjectViewersFailure = (error) => ({
  type: FETCH_PROJECT_VIEWERS_FAILURE,
  payload: { error },
});

export const updateContractorsBegin = () => ({
  type: UPDATE_CONTRACTORS_BEGIN,
});

export const updateContractorsSuccess = () => ({
  type: UPDATE_CONTRACTORS_SUCCESS,
});

export const updateContractorsFailure = (error) => ({
  type: UPDATE_CONTRACTORS_FAILURE,
  payload: { error },
});

export const fetchProjectContractorsBegin = () => ({
  type: FETCH_PROJECT_CONTRACTORS_BEGIN,
});

export const fetchProjectContractorsSuccess = (contractors) => ({
  type: FETCH_PROJECT_CONTRACTORS_SUCCESS,
  payload: { contractors },
});

export const fetchProjectContractorsFailure = (error) => ({
  type: FETCH_PROJECT_CONTRACTORS_FAILURE,
  payload: { error },
});

export const updateProjectBegin = () => ({
  type: PROJECT_UPDATE_PROJECT_BEGIN,
});

export const updateProjectSuccess = () => ({
  type: PROJECT_UPDATE_PROJECT_SUCCESS,
});

export const updateProjectFailure = (error) => ({
  type: PROJECT_UPDATE_PROJECT_FAILURE,
  payload: { error },
});

export const fetchProjectsBegin = () => ({
  type: PROJECT_FETCH_PROJECTS_BEGIN,
});

export const fetchProjectsSuccess = (projects) => ({
  type: PROJECT_FETCH_PROJECTS_SUCCESS,
  payload: { projects },
});

export const fetchProjectsFailure = (error) => ({
  type: PROJECT_FETCH_PROJECTS_FAILURE,
  payload: { error },
});

export const fetchTemplatesBegin = () => ({
  type: PROJECT_FETCH_TEMPLATES_BEGIN,
});

export const fetchTemplatesSuccess = (templates) => ({
  type: PROJECT_FETCH_TEMPLATES_SUCCESS,
  payload: { templates },
});

export const fetchTemplatesFailure = (error) => ({
  type: PROJECT_FETCH_TEMPLATES_FAILURE,
  payload: { error },
});

export const fetchModulesBegin = () => ({
  type: PROJECT_FETCH_MODULES_BEGIN,
});

export const fetchModulesSuccess = (modules) => ({
  type: PROJECT_FETCH_MODULES_SUCCESS,
  payload: { modules },
});

export const fetchModulesFailure = (error) => ({
  type: PROJECT_FETCH_MODULES_FAILURE,
  payload: { error },
});

export const fetchStatementsBegin = () => ({
  type: PROJECT_FETCH_STATEMENTS_BEGIN,
});

export const fetchStatementsSuccess = (statements) => ({
  type: PROJECT_FETCH_STATEMENTS_SUCCESS,
  payload: { statements },
});

export const fetchStatementsFailure = (error) => ({
  type: PROJECT_FETCH_STATEMENTS_FAILURE,
  payload: { error },
});

export const selectProject = (project) => ({
  type: PROJECT_SELECT_PROJECT,
  payload: { project },
});

export const selectTemplate = (template) => ({
  type: PROJECT_SELECT_TEMPLATE,
  payload: { template },
});

export const selectModule = (module) => ({
  type: PROJECT_SELECT_MODULE,
  payload: { module },
});

export const selectStatement = (statement) => ({
  type: PROJECT_SELECT_STATEMENT,
  payload: { statement },
});

export const clearProjects = () => ({
  type: PROJECT_CLEAR_PROJECTS_ARRAY,
});

export const clearTemplates = () => ({
  type: PROJECT_CLEAR_TEMPLATES_ARRAY,
});

export const clearModules = () => ({
  type: PROJECT_CLEAR_MODULES_ARRAY,
});

export const clearStatements = () => ({
  type: PROJECT_CLEAR_STATEMENTS_ARRAY,
});

export const clearProjectTemplates = () => ({
  type: CLEAR_PROJECT_TEMPLATES,
});

export const clearProjectModules = () => ({
  type: CLEAR_PROJECT_MODULES,
});

export const selectProjectTemplateAction = (payload) => ({
  type: SELECT_PROJECT_TEMPLATE,
  payload,
});

export const selectProjectModuleAction = (payload) => ({
  type: SELECT_PROJECT_MODULE,
  payload,
});

export const removeProjectTemplateAction = (payload) => ({
  type: REMOVE_PROJECT_TEMPLATE,
  payload,
});

export const removeProjectModuleAction = (payload) => ({
  type: REMOVE_PROJECT_MODULE,
  payload,
});

export const createProjectBegin = () => ({
  type: CREATE_PROJECT_BEGIN,
});

export const createProjectSuccess = () => ({
  type: CREATE_PROJECT_SUCCESS,
});

export const createProjectFailure = (error) => ({
  type: CREATE_PROJECT_FAILURE,
  payload: { error },
});

export const fetchProjectEditorsBegin = () => ({
  type: FETCH_PROJECT_EDITORS_BEGIN,
});

export const fetchProjectEditorsSuccess = (editors) => ({
  type: FETCH_PROJECT_EDITORS_SUCCESS,
  payload: { editors },
});

export const fetchProjectEditorsFailure = (error) => ({
  type: FETCH_PROJECT_EDITORS_FAILURE,
  payload: { error },
});

export const updateEditorsBegin = () => ({
  type: UPDATE_EDITORS_BEGIN,
});

export const updateEditorsSuccess = () => ({
  type: UPDATE_EDITORS_SUCCESS,
});

export const updateEditorsFailure = (error) => ({
  type: UPDATE_EDITORS_FAILURE,
  payload: { error },
});

export const changePublishedProjectBegin = () => ({
  type: CHANGE_PUBLISHED_PROJECT_BEGIN,
});

export const changePublishedProjectSuccess = () => ({
  type: CHANGE_PUBLISHED_PROJECT_SUCCESS,
});

export const changePublishedProjectFailure = () => ({
  type: CHANGE_PUBLISHED_PROJECT_FAILURE,
});

export const copyProjectToDatabaseBegin = () => ({
  type: COPY_PROJECT_TO_DATABASE_BEGIN,
});

export const copyProjectToDatabaseSuccess = () => ({
  type: COPY_PROJECT_TO_DATABASE_SUCCESS,
});

export const copyProjectToDatabaseFailure = () => ({
  type: COPY_PROJECT_TO_DATABASE_FAILURE,
});

export const deleteProjectBegin = () => ({
  type: PROJECT_DELETE_PROJECT_BEGIN,
});

export const deleteProjectSuccess = () => ({
  type: PROJECT_DELETE_PROJECT_SUCCESS,
});

export const deleteProjectFailure = (error) => ({
  type: PROJECT_DELETE_PROJECT_FAILURE,
  payload: { error },
});

export const fetchFixedPricesBegin = () => ({
  type: FETCH_PROJECT_FIXEDPRICES_BEGIN,
});

export const fetchFixedPricesSuccess = (fixedPrices) => ({
  type: FETCH_PROJECT_FIXEDPRICES_SUCCESS,
  payload: { fixedPrices },
});

export const fetchFixedPricesFailure = (error) => ({
  type: FETCH_PROJECT_FIXEDPRICES_FAILURE,
  payload: { error },
});

export const fetchPiecePricesBegin = () => ({
  type: FETCH_PROJECT_PIECEPRICES_BEGIN,
});

export const fetchPiecePricesSuccess = (piecePrices) => ({
  type: FETCH_PROJECT_PIECEPRICES_SUCCESS,
  payload: { piecePrices },
});

export const fetchPiecePricesFailure = (error) => ({
  type: FETCH_PROJECT_PIECEPRICES_FAILURE,
  payload: { error },
});

export const fetchTAndMsBegin = () => ({
  type: FETCH_PROJECT_TANDMS_BEGIN,
});

export const fetchTAndMsSuccess = (tandms) => ({
  type: FETCH_PROJECT_TANDMS_SUCCESS,
  payload: { tandms },
});

export const fetchTAndMsFailure = (error) => ({
  type: FETCH_PROJECT_TANDMS_FAILURE,
  payload: { error },
});

export const selectQAndA = (qanda) => ({
  type: SELECT_PROJECT_QANDA,
  payload: { qanda },
});

export const referenceNumberUpBegin = () => ({
  type: MOVE_UP_PROJECT_REFERENCE_BEGIN,
});

export const referenceNumberUpSuccess = () => ({
  type: MOVE_UP_PROJECT_REFERENCE_SUCCESS,
});

export const referenceNumberUpFailure = (error) => ({
  type: MOVE_UP_PROJECT_REFERENCE_FAILURE,
  payload: { error },
});

export const referenceNumberDownBegin = () => ({
  type: MOVE_DOWN_PROJECT_REFERENCE_BEGIN,
});

export const referenceNumberDownSuccess = () => ({
  type: MOVE_DOWN_PROJECT_REFERENCE_SUCCESS,
});

export const referenceNumberDownFailure = (error) => ({
  type: MOVE_DOWN_PROJECT_REFERENCE_FAILURE,
  payload: { error },
});

export const subcontractorNumberUpBegin = () => ({
  type: MOVE_UP_PROJECT_SUBCONTRACTOR_BEGIN,
});

export const subcontractorNumberUpSuccess = () => ({
  type: MOVE_UP_PROJECT_SUBCONTRACTOR_SUCCESS,
});

export const subcontractorNumberUpFailure = (error) => ({
  type: MOVE_UP_PROJECT_SUBCONTRACTOR_FAILURE,
  payload: { error },
});

export const subcontractorNumberDownBegin = () => ({
  type: MOVE_DOWN_PROJECT_SUBCONTRACTOR_BEGIN,
});

export const subcontractorNumberDownSuccess = () => ({
  type: MOVE_DOWN_PROJECT_SUBCONTRACTOR_SUCCESS,
});

export const subcontractorNumberDownFailure = (error) => ({
  type: MOVE_DOWN_PROJECT_SUBCONTRACTOR_FAILURE,
  payload: { error },
});

export const informationNumberDownBegin = () => ({
  type: MOVE_DOWN_PROJECT_INFORMATION_BEGIN,
});

export const informationNumberDownSuccess = () => ({
  type: MOVE_DOWN_PROJECT_INFORMATION_SUCCESS,
});

export const informationNumberDownFailure = (error) => ({
  type: MOVE_DOWN_PROJECT_INFORMATION_FAILURE,
  payload: { error },
});

export const informationNumberUpBegin = () => ({
  type: MOVE_UP_PROJECT_INFORMATION_BEGIN,
});

export const informationNumberUpSuccess = () => ({
  type: MOVE_UP_PROJECT_INFORMATION_SUCCESS,
});

export const informationNumberUpFailure = (error) => ({
  type: MOVE_UP_PROJECT_INFORMATION_FAILURE,
  payload: { error },
});

export const fetchProjectQAndAs = (projectId) => (dispatch) => {
  dispatch(fetchProjectQAndAsBegin());
  return axios
    .post('/graphql', {
      query: `{
          qandas(project: "${projectId}") {
            _id,
            question, 
            answer, 
            company,
            attachment,
            offer { projectCompany },
            published,
            updated
          }
        }`,
    })
    .then((res) => {
      const decodedQAndAs = res.data.data.qandas;
      decodedQAndAs.forEach((qanda) => {
        qanda.answer = decodeURIComponent(qanda.answer);
        qanda.question = decodeURIComponent(qanda.question);
      });
      dispatch(fetchProjectQAndAsSuccess(decodedQAndAs));
      return decodedQAndAs;
    })
    .catch((error) => dispatch(fetchProjectQAndAsFailure(error)));
};

export const fetchQAndA = (qandaId) => (dispatch) => {
  dispatch(fetchQAndABegin());
  return axios
    .post('/graphql', {
      query: `{
          qanda(_id: "${qandaId}") {
            _id,
            question, 
            answer, 
            company,
            project,
            attachment,
            offer { projectCompany },
            published,
            updated
          }
        }`,
    })
    .then((res) => {
      const decodedQAndAs = res.data.data.qanda;
      decodedQAndAs.answer = decodeURIComponent(res.data.data.qanda.answer);
      decodedQAndAs.question = decodeURIComponent(res.data.data.qanda.question);
      dispatch(fetchQAndASuccess(decodedQAndAs));
      return decodedQAndAs;
    })
    .catch((error) => dispatch(fetchQAndAFailure(error)));
};

export const addProjectQAndA = (projectId, question) => (dispatch) => {
  dispatch(addProjectQAndABegin());
  const encodedQuestion = encodeURIComponent(question);
  return axios
    .post('/graphql', {
      query: `
        mutation {
          addQAndA(projectId: "${projectId}", question: "${encodedQuestion}") {
            _id,
            question, 
            answer, 
            company
          }
        }
      `,
    })
    .then(() => {
      dispatch(addProjectQAndASuccess());
      // fetching Templates after success
      dispatch(fetchProjectQAndAs(projectId));
    })
    .catch((error) => dispatch(addProjectQAndAFailure(error)));
};

export const updateProjectQAndA = (quandaId, question, answer, projectId) => (
  dispatch,
) => {
  dispatch(updateProjectQAndABegin());
  const encodedQuestion = encodeURIComponent(question);
  const encodedAnswer = encodeURIComponent(answer);
  return axios
    .post('/graphql', {
      query: `
        mutation {
          updateQAndA(_id: "${quandaId}", question: "${encodedQuestion}", answer: "${encodedAnswer}") {
            _id,
            question, 
            answer, 
            company
          }
        }
      `,
    })
    .then(() => {
      dispatch(updateProjectQAndASuccess());
      // fetching Templates after success
      dispatch(fetchProjectQAndAs(projectId));
    })
    .catch((error) => dispatch(updateProjectQAndAFailure(error)));
};

export const deleteProjectQAndA = (qandaId) => (dispatch) => {
  dispatch(deleteProjectQAndABegin());
  return axios
    .post('/graphql', {
      query: `
          mutation {
            deleteQAndA(_id: "${qandaId}") {
              _id
            }
          }
        `,
    })
    .then(() => {
      dispatch(deleteProjectQAndASuccess(qandaId));
    })
    .catch((error) => {
      dispatch(deleteProjectQAndAFailure(error));
    });
};

export const toggleQAndAPublished = (qandaId) => (dispatch) => {
  dispatch(toggleQAndAPublishedBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation {
            toggleQAndAPublished(_id: "${qandaId}") {
              _id,
              project { _id }
            }
          }
        `,
    })
    .then((res) => {
      dispatch(fetchProjectQAndAs(res.data.data.toggleQAndAPublished.project._id));
      dispatch(toggleQAndAPublishedSuccess());
    })
    .catch((error) => {
      dispatch(toggleQAndAPublishedFailure(error));
    });
};

// fetching projects from database
export const fetchProjects = () => (dispatch) => {
  dispatch(fetchProjectsBegin());
  return axios('/api/v1/project/projects')
    .then((res) => {
      const projects = res.data;
      dispatch(fetchProjectsSuccess(projects));
      return projects;
    })
    .catch((error) => dispatch(fetchProjectsFailure(error)));
};

// fetching templates from database
export const fetchTemplates = (projectId) => (dispatch) => {
  dispatch(fetchTemplatesBegin());
  return axios(`/api/v1/project/${projectId}/templates`)
    .then((res) => {
      const templates = res.data;
      dispatch(fetchTemplatesSuccess(templates));
      return templates;
    })
    .catch((error) => dispatch(fetchTemplatesFailure(error)));
};

// fetching modules from datatbase
export const fetchModules = (templateId) => (dispatch) => {
  dispatch(fetchModulesBegin());
  return axios(`/api/v1/project/template/${templateId}/modules`)
    .then((res) => {
      const modules = res.data;
      dispatch(fetchModulesSuccess(modules));
      return modules;
    })
    .catch((error) => dispatch(fetchModulesFailure(error)));
};

export const fetchStatements = (moduleId) => (dispatch) => {
  dispatch(fetchStatementsBegin());
  return axios(`/api/v1/project/module/${moduleId}/statements`)
    .then((res) => {
      dispatch(fetchStatementsSuccess(res.data));
      return res.data;
    })
    .catch((error) => dispatch(fetchStatementsFailure(error)));
};

// fetching templates from database
export const fetchOverviewTemplate = (templateId) => (dispatch) => {
  dispatch(fetchOverviewTemplateBegin());
  return axios(`/api/v1/project/templates/${templateId}`)
    .then((res) => {
      const templates = res.data;
      dispatch(fetchOverviewTemplateSuccess(templates));
      return templates;
    })
    .catch((error) => dispatch(fetchOverviewTemplateFailure(error)));
};

export const addTemplate = (projectId, name) => (dispatch) => {
  dispatch(addTemplateBegin());
  return axios
    .post('/api/v1/project/templates', {
      name,
      projectId,
    })
    .then(() => {
      dispatch(addTemplateSuccess());
      // fetching Templates after success
      dispatch(fetchTemplates(projectId));
    })
    .catch((error) => dispatch(addTemplateFailure(error)));
};

export const addModule = (templateId, moduleName, moduleHeading, projectId) => (
  dispatch,
) => {
  dispatch(addModuleBegin());
  return axios
    .post('/api/v1/project/modules', {
      name: moduleName,
      heading: moduleHeading,
      templateId,
      projectId,
    })
    .then(() => {
      dispatch(addModuleSuccess());
      // fetching Modules after success
      dispatch(fetchModules(templateId));
    })
    .catch((error) => dispatch(addModuleFailure(error)));
};

export const addStatement = (
  moduleId,
  statementDescription,
  projectId,
  templateId,
) => (dispatch) => {
  dispatch(addStatementBegin());
  return axios
    .post('/api/v1/project/statements', {
      description: statementDescription,
      projectId,
      templateId,
      moduleId,
    })
    .then(() => {
      dispatch(addStatementSuccess());
      // fetching Statements after success
      dispatch(fetchStatements(moduleId));
    })
    .catch((error) => dispatch(addStatementFailure(error)));
};

export const fetchProjectEditors = (projectId) => (dispatch) => {
  dispatch(fetchProjectEditorsBegin());
  return axios(`api/v1/project/editors/${projectId}`)
    .then((res) => {
      const editors = res.data;
      dispatch(fetchProjectEditorsSuccess(editors));
      return editors;
    })
    .catch((error) => dispatch(fetchProjectEditorsFailure(error)));
};

export const fetchProjectViewers = (projectId) => (dispatch) => {
  dispatch(fetchProjectViewersBegin());
  return axios(`api/v1/project/viewers/${projectId}`)
    .then((res) => {
      const viewers = res.data;
      dispatch(fetchProjectViewersSuccess(viewers));
      return viewers;
    })
    .catch((error) => dispatch(fetchProjectViewersFailure(error)));
};

export const fetchProjectContractors = (projectId) => (dispatch) => {
  dispatch(fetchProjectContractorsBegin());
  return axios(`api/v1/project/contractors/${projectId}`)
    .then((res) => {
      const contractors = res.data;
      dispatch(fetchProjectContractorsSuccess(contractors));
      return contractors;
    })
    .catch((error) => dispatch(fetchProjectContractorsFailure(error)));
};

export const updateEditors = (projectId, email, option) => (dispatch) => {
  dispatch(updateEditorsBegin());
  return axios
    .put(`/api/v1/project/editors/${projectId}`, {
      email,
      option,
    })
    .then(() => {
      dispatch(updateEditorsSuccess());
      dispatch(fetchProjectEditors(projectId));
    })
    .catch((error) => dispatch(updateEditorsFailure(error)));
};

export const updateViewers = (projectId, email, option) => (dispatch) => {
  dispatch(updateViewersBegin());
  return axios
    .put(`/api/v1/project/viewers/${projectId}`, {
      email,
      option,
    })
    .then(() => {
      dispatch(updateViewersSuccess());
      dispatch(fetchProjectViewers(projectId));
    })
    .catch((error) => dispatch(updateViewersFailure(error)));
};

export const updateContractors = (projectId, email, option) => (dispatch) => {
  dispatch(updateContractorsBegin());
  return axios
    .put(`/api/v1/project/contractors/${projectId}`, {
      email,
      option,
    })
    .then(() => {
      dispatch(updateContractorsSuccess());
      dispatch(fetchProjectContractors(projectId));
    })
    .catch((error) => dispatch(updateContractorsFailure(error)));
};

export const updateProject = (projectId, name, description) => (dispatch) => {
  dispatch(updateProjectBegin());
  return axios
    .put(`/api/v1/project/projects/${projectId}`, {
      name,
      description,
    })
    .then(() => {
      dispatch(updateProjectSuccess());
      // fetching Templates after success
      dispatch(fetchProjects());
    })
    .catch((error) => dispatch(updateProjectFailure(error)));
};

export const updateTemplate = (templateId, name, projectId) => (dispatch) => {
  dispatch(updateTemplateBegin());
  return axios
    .put(`/api/v1/project/templates/${templateId}`, {
      name,
      projectId,
    })
    .then((res) => {
      dispatch(updateTemplateSuccess());
      dispatch(fetchTemplates(res.data.project));
    })
    .catch((error) => dispatch(updateTemplateFailure(error)));
};

export const updateModule = (moduleId, name, heading, templateId) => (
  dispatch,
) => {
  dispatch(updateModuleBegin());
  return axios
    .put(`/api/v1/project/modules/${moduleId}`, {
      name,
      heading,
      templateId,
    })
    .then((res) => {
      dispatch(updateModuleSuccess());
      // fetching Modules after success
      dispatch(fetchModules(res.data.template));
    })
    .catch((error) => dispatch(updateModuleFailure(error)));
};

export const updateStatement = (statementId, description, moduleId) => (
  dispatch,
) => {
  dispatch(updateStatementBegin());
  return axios
    .put(`/api/v1/project/statements/${statementId}`, {
      description,
      moduleId,
    })
    .then((res) => {
      dispatch(updateStatementSuccess(res.data));
    })
    .catch((error) => dispatch(updateStatementFailure(error)));
};

export const createProject = (
  name,
  description,
  templates,
  modules,
  history,
  duration,
) => (dispatch) => {
  dispatch(createProjectBegin());
  return axios
    .post('/api/v1/project/projects', {
      name,
      description,
      templates,
      modules,
      duration,
    })
    .then(() => {
      history.push('/manageproject');
      dispatch(createProjectSuccess());
    })
    .catch((error) => dispatch(createProjectFailure(error)));
};

export const changePublishedProject = (id, date) => (dispatch) => {
  dispatch(changePublishedProjectBegin());
  return axios
    .put(`/api/v1/project/projects/${id}/published`, {
      deadline: date,
    })
    .then((res) => {
      dispatch(changePublishedProjectSuccess());
      dispatch(fetchProjects());
      return res;
    })
    .catch((error) => dispatch(changePublishedProjectFailure(error)));
};

export const copyProjectToDatabase = (id) => (dispatch) => {
  dispatch(copyProjectToDatabaseBegin());
  return axios
    .put(`/api/v1/project/projects/${id}/todatabase`)
    .then((res) => {
      dispatch(copyProjectToDatabaseSuccess());
      dispatch(fetchProjects());
      return res;
    })
    .catch((error) => dispatch(copyProjectToDatabaseFailure(error)));
};

export const deleteProject = (projectId) => (dispatch) => {
  dispatch(deleteProjectBegin());
  return axios
    .delete(`/api/v1/project/projects/${projectId}`)
    .then(() => {
      dispatch(deleteProjectSuccess());
      // fetching Projects after success
      dispatch(fetchProjects());
    })
    .catch((error) => dispatch(deleteProjectFailure(error)));
};

export const deleteTemplate = (templateId) => (dispatch) => {
  dispatch(deleteTemplateBegin());
  return axios
    .delete(`/api/v1/project/templates/${templateId}`)
    .then((res) => {
      dispatch(deleteTemplateSuccess());
      // fetching Templates after success
      dispatch(fetchTemplates(res.data[1].projectId));
    })
    .catch((error) => dispatch(deleteTemplateFailure(error)));
};

export const deleteModule = (moduleId) => (dispatch) => {
  dispatch(deleteModuleBegin());
  return axios
    .delete(`/api/v1/project/modules/${moduleId}`)
    .then((res) => {
      dispatch(deleteModuleSuccess());
      // fetching Modules after success
      dispatch(fetchModules(res.data[1].templateId));
    })
    .catch((error) => dispatch(deleteModuleFailure(error)));
};

export const deleteStatement = (statementId, moduleId) => (dispatch) => {
  dispatch(deleteStatementBegin());
  return axios
    .delete(`/api/v1/project/statements/${statementId}`)
    .then(() => {
      dispatch(deleteStatementSuccess());
      // fetching Statements after success
      dispatch(fetchStatements(moduleId));
    })
    .catch((error) => dispatch(deleteStatementFailure(error)));
};

export const statementNumberUp = (id) => (dispatch) => {
  dispatch(statementNumberUpBegin());
  return axios
    .put(`/api/v1/project/statements/${id}/numberup`, {})
    .then((res) => {
      dispatch(statementNumberUpSuccess());
      dispatch(fetchStatements(res.data.module));
      return res;
    })
    .catch((error) => dispatch(statementNumberUpFailure(error)));
};

export const statementNumberDown = (id) => (dispatch) => {
  dispatch(statementNumberDownBegin());
  return axios
    .put(`/api/v1/project/statements/${id}/numberdown`, {})
    .then((res) => {
      dispatch(statementNumberDownSuccess());
      dispatch(fetchStatements(res.data.module));
      return res;
    })
    .catch((error) => dispatch(statementNumberDownFailure(error)));
};

export const moduleNumberUp = (id) => (dispatch) => {
  dispatch(moduleNumberUpBegin());
  return axios
    .put(`/api/v1/project/modules/${id}/numberup`, {})
    .then((res) => {
      dispatch(moduleNumberUpSuccess());
      dispatch(fetchModules(res.data.template));
      return res;
    })
    .catch((error) => dispatch(moduleNumberUpFailure(error)));
};

export const moduleNumberDown = (id) => (dispatch) => {
  dispatch(moduleNumberDownBegin());
  return axios
    .put(`/api/v1/project/modules/${id}/numberdown`, {})
    .then((res) => {
      dispatch(moduleNumberDownSuccess());
      dispatch(fetchModules(res.data.template));
      return res;
    })
    .catch((error) => dispatch(moduleNumberDownFailure(error)));
};

export const templateNumberUp = (id) => (dispatch) => {
  dispatch(templateNumberUpBegin());
  return axios
    .put(`/api/v1/project/templates/${id}/numberup`, {})
    .then((res) => {
      dispatch(templateNumberUpSuccess());
      dispatch(fetchTemplates(res.data[1].projectId));
      return res;
    })
    .catch((error) => dispatch(templateNumberUpFailure(error)));
};

export const templateNumberDown = (id) => (dispatch) => {
  dispatch(templateNumberDownBegin());
  return axios
    .put(`/api/v1/project/templates/${id}/numberdown`, {})
    .then((res) => {
      dispatch(templateNumberDownSuccess());
      dispatch(fetchTemplates(res.data[1].projectId));
      return res;
    })
    .catch((error) => dispatch(templateNumberDownFailure(error)));
};

export const updateStatementResponsibilities = (id) => (dispatch) => {
  dispatch(updateStatementResponsibilitiesBegin());
  return axios
    .put(`/api/v1/project/statements/${id}/responsibilities`, {})
    .then((res) => {
      dispatch(updateStatementResponsibilitiesSuccess());
      dispatch(fetchStatements(res.data.module));
      return res;
    })
    .catch((error) => dispatch(updateStatementResponsibilitiesFailure(error)));
};

export const updateModulePricing = (id, pricing) => (dispatch) => {
  dispatch(updateModulePricingBegin());
  return axios
    .put(`/api/v1/project/modules/${id}/pricing`, {
      pricing,
    })
    .then((res) => {
      dispatch(updateModulePricingSuccess());
      dispatch(fetchModules(res.data.template));
      return res;
    })
    .catch((error) => dispatch(updateModulePricingFailure(error)));
};

export const fetchSlas = (projectId) => (dispatch) => {
  dispatch(fetchSlasBegin());
  return axios
    .get(`/api/v1/project/projects/${projectId}/slas`, {})
    .then((res) => {
      dispatch(fetchSlasSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchSlasFailure(error)));
};

export const updateModuleSla = (id, projectId) => (dispatch) => {
  dispatch(updateModuleSlaBegin());
  return axios
    .put(`/api/v1/project/modules/${id}/slas`, {
      projectId,
    })
    .then((res) => {
      dispatch(updateModuleSlaSuccess());
      dispatch(fetchModules(res.data.template));
      return res;
    })
    .catch((error) => dispatch(updateModuleSlaFailure(error)));
};

export const updateSla = (
  slaId,
  priority,
  servicetime,
  availability,
  reactiontime,
  solutiontime,
  penalty,
  penaltyreference,
  projectId,
) => (dispatch) => {
  dispatch(updateSlaBegin());
  return axios
    .put(`/api/v1/project/slas/${slaId}`, {
      priority,
      servicetime,
      availability,
      reactiontime,
      solutiontime,
      penalty,
      penaltyreference,
    })
    .then((res) => {
      dispatch(updateSlaSuccess());
      dispatch(fetchSlas(projectId));
      return res;
    })
    .catch((error) => dispatch(updateSlaFailure(error)));
};

export const addSla = (projectId, moduleId) => (dispatch) => {
  dispatch(addSlaBegin());
  return axios
    .post('/api/v1/project/slas', {
      projectId,
      moduleId,
    })
    .then((res) => {
      dispatch(addSlaSuccess());
      dispatch(fetchSlas(projectId));
      return res;
    })
    .catch((error) => dispatch(addSlaFailure(error)));
};

export const deleteSla = (slaId, projectId) => (dispatch) => {
  dispatch(deleteSlaBegin());
  return axios
    .delete(`/api/v1/project/slas/${slaId}`, {})
    .then((res) => {
      dispatch(deleteSlaSuccess());
      dispatch(fetchSlas(projectId));
      return res;
    })
    .catch((error) => dispatch(deleteSlaFailure(error)));
};

export const uploadProjectProjectFile = (formData, config) => (dispatch) => {
  dispatch(uploadProjectProjectFileBegin());
  return axios
    .post('/api/v1/upload/project/', formData, config)
    .then((res) => {
      dispatch(uploadProjectProjectFileSuccess());
      dispatch(fetchProjects());
      return res;
    })
    .catch((error) => dispatch(uploadProjectProjectFileFailure(error)));
};

export const removeProjectProjectUpload = (id) => (dispatch) => {
  dispatch(removeProjectProjectUploadBegin());
  return axios
    .delete(`/api/v1/upload/project/project/${id}`)
    .then((res) => {
      dispatch(removeProjectProjectUploadSuccess());
      dispatch(fetchProjects());
      return res;
    })
    .catch((error) => dispatch(removeProjectProjectUploadFailure(error)));
};

export const uploadProjectTemplateFile = (formData, config) => (dispatch) => {
  dispatch(uploadProjectTemplateFileBegin());
  return axios
    .post('/api/v1/upload/project/', formData, config)
    .then((res) => {
      dispatch(uploadProjectTemplateFileSuccess());
      dispatch(fetchTemplates(res.data.project));
      return res;
    })
    .catch((error) => dispatch(uploadProjectTemplateFileFailure(error)));
};

export const removeProjectTemplateUpload = (id) => (dispatch) => {
  dispatch(removeProjectTemplateUploadBegin());
  return axios
    .delete(`/api/v1/upload/project/template/${id}`)
    .then((res) => {
      dispatch(removeProjectTemplateUploadSuccess());
      dispatch(fetchTemplates(res.data.project));
      return res;
    })
    .catch((error) => dispatch(removeProjectTemplateUploadFailure(error)));
};

export const uploadProjectModuleFile = (formData, config) => (dispatch) => {
  dispatch(uploadProjectModuleFileBegin());
  return axios
    .post('/api/v1/upload/project/', formData, config)
    .then((res) => {
      dispatch(uploadProjectModuleFileSuccess());
      dispatch(fetchModules(res.data.template));
      return res;
    })
    .catch((error) => dispatch(uploadProjectModuleFileFailure(error)));
};

export const removeProjectModuleUpload = (id) => (dispatch) => {
  dispatch(removeProjectModuleUploadBegin());
  return axios
    .delete(`/api/v1/upload/project/module/${id}`)
    .then((res) => {
      dispatch(removeProjectModuleUploadSuccess());
      dispatch(fetchModules(res.data.template));
      return res;
    })
    .catch((error) => dispatch(removeProjectModuleUploadFailure(error)));
};

export const uploadProjectStatementFile = (formData, config, id) => (
  dispatch,
) => {
  dispatch(uploadProjectStatementFileBegin());
  return axios
    .post('/api/v1/upload/project/', formData, config)
    .then((res) => {
      dispatch(uploadProjectStatementFileSuccess());
      dispatch(fetchStatements(id));
      return res;
    })
    .catch((error) => dispatch(uploadProjectStatementFileFailure(error)));
};

export const removeProjectStatementUpload = (id) => (dispatch) => {
  dispatch(removeProjectStatementUploadBegin());
  return axios
    .delete(`/api/v1/upload/project/statement/${id}`)
    .then((res) => {
      dispatch(removeProjectStatementUploadSuccess());
      dispatch(fetchStatements(res.data.module));
      return res;
    })
    .catch((error) => dispatch(removeProjectModuleUploadFailure(error)));
};

export const uploadProjectQAndAFile = (formData, config) => (
  dispatch,
) => {
  dispatch(uploadProjectQAndAFileBegin());
  return axios
    .post('/api/v1/upload/project/', formData, config)
    .then((res) => {
      dispatch(uploadProjectQAndAFileSuccess());
      dispatch(fetchProjectQAndAs(res.data.project));
      return res;
    })
    .catch((error) => dispatch(uploadProjectQAndAFileFailure(error)));
};

export const removeProjectQAndAUpload = (id) => (dispatch) => {
  dispatch(removeProjectQAndAUploadBegin());
  return axios
    .delete(`/api/v1/upload/project/qanda/${id}`)
    .then((res) => {
      dispatch(removeProjectQAndAUploadSuccess());
      dispatch(fetchProjectQAndAs(res.data.project));
      return res;
    })
    .catch((error) => dispatch(removeProjectQAndAUploadFailure(error)));
};

export const fetchProjectRatecard = (id) => (dispatch) => {
  dispatch(fetchProjectRatecardBegin());
  return axios
    .get(`/api/v1/project/ratecards/projects/${id}`, {})
    .then((res) => {
      dispatch(fetchProjectRatecardSuccess(res.data));
      return res;
    })
    .catch((error) => dispatch(fetchProjectRatecardFailure(error)));
};

export const fetchFixedPrices = (projectId) => (dispatch) => {
  dispatch(fetchFixedPricesBegin());
  return axios(`/api/v1/project/projects/${projectId}/fixedprices`)
    .then((res) => {
      const fixedPrices = res.data;
      dispatch(fetchFixedPricesSuccess(fixedPrices));
      return fixedPrices;
    })
    .catch((error) => dispatch(fetchFixedPricesFailure(error)));
};

export const fetchPiecePrices = (projectId) => (dispatch) => {
  dispatch(fetchPiecePricesBegin());
  return axios(`/api/v1/project/projects/${projectId}/pieceprices`)
    .then((res) => {
      const piecePrices = res.data;
      dispatch(fetchPiecePricesSuccess(piecePrices));
      return piecePrices;
    })
    .catch((error) => dispatch(fetchPiecePricesFailure(error)));
};

export const fetchTAndMs = (projectId) => (dispatch) => {
  dispatch(fetchTAndMsBegin());
  return axios(`/api/v1/project/projects/${projectId}/tandms`)
    .then((res) => {
      const tandms = res.data;
      dispatch(fetchTAndMsSuccess(tandms));
      return tandms;
    })
    .catch((error) => dispatch(fetchTAndMsFailure(error)));
};

export const addRatecard = (projectId, profile, description) => (dispatch) => {
  dispatch(addRatecardBegin());
  return axios
    .post('/api/v1/project/ratecards', {
      projectId,
      profile,
      description,
    })
    .then((res) => {
      dispatch(addRatecardSuccess());
      dispatch(fetchProjectRatecard(projectId));
      return res;
    })
    .catch((error) => dispatch(addRatecardFailure(error)));
};

export const updateRatecard = (ratecardId, profile, description, projectId) => (
  dispatch,
) => {
  dispatch(updateRatecardBegin());
  return axios
    .put(`/api/v1/project/ratecards/${ratecardId}`, {
      profile,
      description,
      projectId,
    })
    .then((res) => {
      dispatch(updateRatecardSuccess());
      dispatch(fetchProjectRatecard(res.data.project));
      return res;
    })
    .catch((error) => dispatch(updateRatecardFailure(error)));
};

export const deleteRatecard = (ratecardId) => (dispatch) => {
  dispatch(deleteRatecardBegin());
  return axios
    .delete(`/api/v1/project/ratecards/${ratecardId}`)
    .then((res) => {
      dispatch(deleteRatecardSuccess());
      dispatch(fetchProjectRatecard(res.data.project));
      return res;
    })
    .catch((error) => dispatch(deleteRatecardFailure(error)));
};

export const addPiecePrice = (projectId, moduleId) => (
  dispatch,
) => {
  dispatch(addPiecePriceBegin());
  return axios
    .post(`/api/v1/project/module/${moduleId}/pieceprice/`)
    .then((res) => {
      dispatch(addPiecePriceSuccess());
      dispatch(fetchPiecePrices(res.data.project));
      return res;
    })
    .catch((error) => dispatch(addPiecePriceFailure(error)));
};

export const deletePiecePrice = (priceId, projectId) => (dispatch) => {
  dispatch(deletePiecePriceBegin());
  return axios
    .delete(`/api/v1/project/pieceprice/${priceId}`, {})
    .then((res) => {
      dispatch(deletePiecePriceSuccess());
      dispatch(fetchPiecePrices(projectId));
      return res;
    })
    .catch((error) => {
      dispatch(deletePiecePriceFailure(error));
      dispatch(enqueueSnackbar({
        message: error.response.data,
        options: {
          key: new Date().getTime() + Math.random(),
          variant: 'error',
          autoHideDuration: 3000,
        },
      }));
    });
};

export const updatePiecePrice = (piecePriceId, unit, amount, projectId) => (
  dispatch,
) => {
  dispatch(updatePiecePriceBegin());
  return axios
    .put(`/api/v1/project/pieceprices/${piecePriceId}`, {
      unit,
      amount,
    })
    .then((res) => {
      dispatch(updatePiecePriceSuccess());
      dispatch(fetchPiecePrices(projectId));
      return res;
    })
    .catch((error) => dispatch(updatePiecePriceFailure(error)));
};

export const fetchProjectInformation = (projectId) => (dispatch) => {
  dispatch(fetchProjectInformationBegin());
  return axios(`/api/v1/project/projects/${projectId}/information`)
    .then((res) => {
      const information = res.data;
      dispatch(fetchProjectInformationSuccess(information));
      return information;
    })
    .catch((error) => dispatch(fetchProjectInformationFailure(error)));
};

export const addProjectInformation = (projectId, question) => (dispatch) => {
  dispatch(addProjectInformationBegin());
  return axios
    .post('/api/v1/project/information', {
      projectId,
      question,
    })
    .then(() => {
      dispatch(addProjectInformationSuccess());
      dispatch(fetchProjectInformation(projectId));
    })
    .catch((error) => dispatch(addProjectInformationFailure(error)));
};

export const projectInformationNumberUp = (id, projectId) => (dispatch) => {
  dispatch(projectInformationNumberUpBegin());
  return axios
    .put(`/api/v1/project/information/${id}/up`, {})
    .then((res) => {
      dispatch(projectInformationNumberUpSuccess());
      dispatch(fetchProjectInformation(projectId));
      return res;
    })
    .catch((error) => dispatch(projectInformationNumberUpFailure(error)));
};

export const projectInformationNumberDown = (id, projectId) => (dispatch) => {
  dispatch(projectInformationNumberDownBegin());
  return axios
    .put(`/api/v1/project/information/${id}/down`, {})
    .then((res) => {
      dispatch(projectInformationNumberDownSuccess());
      dispatch(fetchProjectInformation(projectId));
      return res;
    })
    .catch((error) => dispatch(projectInformationNumberDownFailure(error)));
};

export const deleteProjectInformation = (id) => (dispatch) => {
  dispatch(deleteProjectInformationBegin());
  return axios
    .delete(`/api/v1/project/information/${id}`)
    .then((res) => {
      dispatch(deleteProjectInformationSuccess());
      // fetching project information after success
      dispatch(fetchProjectInformation(res.data.project));
    })
    .catch((error) => dispatch(deleteProjectInformationFailure(error)));
};

export const updateProjectInformation = (id, question) => (dispatch) => {
  dispatch(updateProjectInformationBegin());
  return axios
    .put(`/api/v1/project/information/${id}`, {
      question,
    })
    .then((res) => {
      dispatch(updateProjectInformationSuccess());
      // fetching project information after success
      dispatch(fetchProjectInformation(res.data.project));
    })
    .catch((error) => dispatch(updateProjectInformationFailure(error)));
};

export const fetchProjectReferences = (projectId) => (dispatch) => {
  dispatch(fetchProjectReferencesBegin());
  return axios
    .post('/graphql', {
      query: `{
          projectReferences(projectId: "${projectId}") {
            _id,
            number,
            question,
            answer,
            project {
              _id
            }
          }
        }`,
    })
    .then((res) => {
      const references = res.data.data.projectReferences;
      dispatch(fetchProjectReferencesSuccess(references));
      return references;
    })
    .catch((error) => dispatch(fetchProjectReferencesFailure(error)));
};

export const addProjectReference = (projectId, question) => (dispatch) => {
  dispatch(addProjectReferenceBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation {
            addProjectReference(question: "${question}" projectId: "${projectId}") {
              _id,
              number,
              question,
              answer,
              project {
                _id
              }
            }
          }`,
    })
    .then((res) => {
      const reference = res.data.data.addProjectReference;
      dispatch(addProjectReferenceSuccess(reference));
      return reference;
    })
    .catch((error) => dispatch(addProjectReferenceFailure(error)));
};

export const deleteProjectReference = (referenceId) => (dispatch) => {
  dispatch(deleteProjectReferenceBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation {
            deleteProjectReference(_id: "${referenceId}") {
              _id,
              number
            }
          }
        `,
    })
    .then((res) => {
      const reference = res.data.data.deleteProjectReference;
      dispatch(deleteProjectReferenceSuccess(reference));
    })
    .catch((error) => dispatch(deleteProjectReferenceFailure(error)));
};

export const updateProjectReference = (id, question) => (dispatch) => {
  dispatch(updateProjectReferenceBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation {
            updateProjectReference(question: "${question}" _id: "${id}") {
              _id,
              number,
              question,
              answer,
              project {
                _id
              }
            }
          }`,
    })
    .then((res) => {
      const reference = res.data.data.updateProjectReference;
      dispatch(updateProjectReferenceSuccess(reference));
      return reference;
    })
    .catch((error) => dispatch(updateProjectReferenceFailure(error)));
};

export const fetchProjectSubcontractors = (projectId) => (dispatch) => {
  dispatch(fetchProjectSubcontractorsBegin());
  return axios
    .post('/graphql', {
      query: `{
          projectSubcontractors(projectId: "${projectId}") {
            _id,
            number,
            question,
            answer,
            project {
              _id
            }
          }
        }`,
    })
    .then((res) => {
      const subcontractors = res.data.data.projectSubcontractors;
      dispatch(fetchProjectSubcontractorsSuccess(subcontractors));
      return subcontractors;
    })
    .catch((error) => dispatch(fetchProjectSubcontractorsFailure(error)));
};

export const addProjectSubcontractor = (projectId, question) => (dispatch) => {
  dispatch(addProjectSubcontractorBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation {
            addProjectSubcontractor(question: "${question}" projectId: "${projectId}") {
              _id,
              number,
              question,
              answer,
              project {
                _id
              }
            }
          }`,
    })
    .then((res) => {
      const subcontractor = res.data.data.addProjectSubcontractor;
      dispatch(addProjectSubcontractorSuccess(subcontractor));
      return subcontractor;
    })
    .catch((error) => dispatch(addProjectSubcontractorFailure(error)));
};

export const deleteProjectSubcontractor = (subcontractorId) => (dispatch) => {
  dispatch(deleteProjectSubcontractorBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation {
            deleteProjectSubcontractor(_id: "${subcontractorId}") {
              _id,
              number
            }
          }
        `,
    })
    .then((res) => {
      const subcontractor = res.data.data.deleteProjectSubcontractor;
      dispatch(deleteProjectSubcontractorSuccess(subcontractor));
    })
    .catch((error) => dispatch(deleteProjectSubcontractorFailure(error)));
};

export const updateProjectSubcontractor = (id, question) => (dispatch) => {
  dispatch(updateProjectSubcontractorBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation {
            updateProjectSubcontractor(question: "${question}" _id: "${id}") {
              _id,
              number,
              question,
              answer,
              project {
                _id
              }
            }
          }`,
    })
    .then((res) => {
      const subcontractor = res.data.data.updateProjectSubcontractor;
      dispatch(updateProjectSubcontractorSuccess(subcontractor));
      return subcontractor;
    })
    .catch((error) => dispatch(updateProjectSubcontractorFailure(error)));
};

export const fetchAdditionalFilesIsShowing = (projectId) => (dispatch) => {
  dispatch(fetchAdditionalFilesIsShowingBegin());
  return axios(`/api/v1/project/projects/${projectId}/additionalfiles`)
    .then((res) => {
      const isShowing = res.data;
      dispatch(fetchAdditionalFilesIsShowingSuccess(isShowing));
    })
    .catch((error) => {
      dispatch(fetchAdditionalFilesIsShowingFailure(error));
    });
};

export const referenceNumberUp = (id) => (dispatch) => {
  dispatch(referenceNumberUpBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation {
            moveUpProjectReference(_id: "${id}") {
              _id,
              number,
              question,
              answer,
              project {
                _id
              }
            }
          }`,
    })
    .then((res) => {
      const reference = res.data.data.moveUpProjectReference;
      dispatch(referenceNumberUpSuccess(reference));
      dispatch(fetchProjectReferences(reference.project._id));
      return reference;
    })
    .catch((error) => dispatch(referenceNumberUpFailure(error)));
};

export const referenceNumberDown = (id) => (dispatch) => {
  dispatch(referenceNumberDownBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation {
            moveDownProjectReference(_id: "${id}") {
              _id,
              number,
              question,
              answer,
              project {
                _id
              }
            }
          }`,
    })
    .then((res) => {
      const reference = res.data.data.moveDownProjectReference;
      dispatch(referenceNumberDownSuccess(reference));
      dispatch(fetchProjectReferences(reference.project._id));
      return reference;
    })
    .catch((error) => dispatch(referenceNumberDownFailure(error)));
};

export const subcontractorNumberUp = (id) => (dispatch) => {
  dispatch(subcontractorNumberUpBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation {
            moveUpProjectSubcontractor(_id: "${id}") {
              _id,
              number,
              question,
              answer,
              project {
                _id
              }
            }
          }`,
    })
    .then((res) => {
      const subcontractor = res.data.data.moveUpProjectSubcontractor;
      dispatch(subcontractorNumberUpSuccess(subcontractor));
      dispatch(fetchProjectSubcontractors(subcontractor.project._id));
      return subcontractor;
    })
    .catch((error) => dispatch(subcontractorNumberUpFailure(error)));
};

export const subcontractorNumberDown = (id) => (dispatch) => {
  dispatch(subcontractorNumberDownBegin());
  return axios
    .post('/graphql', {
      query: `
          mutation {
            moveDownProjectSubcontractor(_id: "${id}") {
              _id,
              number,
              question,
              answer,
              project {
                _id
              }
            }
          }`,
    })
    .then((res) => {
      const subcontractor = res.data.data.moveDownProjectSubcontractor;
      dispatch(subcontractorNumberDownSuccess(subcontractor));
      dispatch(fetchProjectSubcontractors(subcontractor.project._id));
      return subcontractor;
    })
    .catch((error) => dispatch(subcontractorNumberDownFailure(error)));
};

export const informationNumberUp = (id) => (dispatch) => {
  dispatch(informationNumberUpBegin());
  return axios
    .put(`/api/v1/project/information/${id}/numberup`, {})
    .then((res) => {
      const information = res.data;
      dispatch(informationNumberUpSuccess(information));
      dispatch(fetchProjectInformation(information.project));
      return information;
    })
    .catch((error) => dispatch(informationNumberUpFailure(error)));
};

export const informationNumberDown = (id) => (dispatch) => {
  dispatch(informationNumberDownBegin());
  return axios
    .put(`/api/v1/project/information/${id}/numberdown`, {})
    .then((res) => {
      const information = res.data;
      dispatch(informationNumberDownSuccess(information));
      dispatch(fetchProjectInformation(information.project));
      return information;
    })
    .catch((error) => dispatch(informationNumberDownFailure(error)));
};
