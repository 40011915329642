import React from 'react';
import PropTypes from 'prop-types';

// import ProjectProjectsContainer from '../../container/ProjectManagement/ProjectProjectsContainer';
import ProjectTemplatesContainer from '../../container/ProjectManagement/ProjectTemplatesContainer';
import ProjectModulesContainer from '../../container/ProjectManagement/ProjectModulesContainer';
// import ProjectStatementsContainer from '../../container/ProjectManagement/ProjectStatementsContainer';
import ProjectQAndAsContainer from '../../container/ProjectManagement/ProjectQAndAsContainer';
import ProjectSlasContainer from '../../container/ProjectManagement/ProjectSlasContainer';
import ProjectRatecardContainer from '../../container/ProjectManagement/ProjectRatecardContainer';
import ProjectTAndMsContainer from '../../container/ProjectManagement/ProjectTAndMsContainer';
import ProjectFixedPricesContainer from '../../container/ProjectManagement/ProjectFixedPricesContainer';
import ProjectPiecePricesContainer from '../../container/ProjectManagement/ProjectPiecePricesContainer';
import ProjectInformationContainer from '../../container/ProjectManagement/ProjectInformationContainer';
import ProjectReferencesContainer from '../../container/ProjectManagement/ProjectReferencesContainer';
import ProjectSubcontractorsContainer from '../../container/ProjectManagement/ProjectSubcontractorsContainer';

import ResponsiveDiv from '../Elements/ResponsiveDiv';

function ProjectPage(props) {
  const {
    selectedProject,
    selectedTemplate,
    // selectedModule,
    selectedSla,
    selectedRatecard,
    selectedTAndM,
    selectedFixedPrice,
    selectedPiecePrice,
    selectedInformation,
    selectedReference,
    selectedSubcontractor,
    selectedQAndA,
  } = props;

  if (selectedQAndA) {
    return (
      <ResponsiveDiv>
        <ProjectQAndAsContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedSla) {
    return (
      <ResponsiveDiv>
        <ProjectSlasContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedRatecard) {
    return (
      <ResponsiveDiv>
        <ProjectRatecardContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedTAndM) {
    return (
      <ResponsiveDiv>
        <ProjectTAndMsContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedFixedPrice) {
    return (
      <ResponsiveDiv>
        <ProjectFixedPricesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedPiecePrice) {
    return (
      <ResponsiveDiv>
        <ProjectPiecePricesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedInformation) {
    return (
      <ResponsiveDiv>
        <ProjectInformationContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedReference) {
    return (
      <ResponsiveDiv>
        <ProjectReferencesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedSubcontractor) {
    return (
      <ResponsiveDiv>
        <ProjectSubcontractorsContainer />
      </ResponsiveDiv>
    );
  }

  // if (selectedModule) {
  //   return (
  //     <ResponsiveDiv>
  //       <ProjectStatementsContainer />
  //     </ResponsiveDiv>
  //   );
  // }

  if (selectedTemplate) {
    return (
      <ResponsiveDiv>
        <ProjectModulesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedProject) {
    return (
      <ResponsiveDiv>
        <ProjectTemplatesContainer />
      </ResponsiveDiv>
    );
  }
  return (
    <ResponsiveDiv>
      <p>
        Kein Projekt ausgewählt.
      </p>
    </ResponsiveDiv>
  );
}

export default ProjectPage;

ProjectPage.propTypes = {
  selectedProject: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  // selectedModule: PropTypes.string.isRequired,
  selectedSla: PropTypes.string.isRequired,
  selectedRatecard: PropTypes.string.isRequired,
  selectedTAndM: PropTypes.string.isRequired,
  selectedFixedPrice: PropTypes.string.isRequired,
  selectedPiecePrice: PropTypes.string.isRequired,
  selectedInformation: PropTypes.string.isRequired,
  selectedReference: PropTypes.string.isRequired,
  selectedSubcontractor: PropTypes.string.isRequired,
  selectedQAndA: PropTypes.string.isRequired,
};
