import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { registerUser } from '../../redux/actionCreators/authenticationAction';

import SignUpForm from '../../components/SignInUp/SignUpForm';

const mapStateToProps = (state) => ({
  errors: state.errors,
});

const mapDispatchToProps = {
  registerUser,
};

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SignUpForm),
);
