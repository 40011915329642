import {
  ADD_DATABASE_INFORMATION_BEGIN,
  ADD_DATABASE_INFORMATION_FAILURE,
  ADD_DATABASE_INFORMATION_SUCCESS,
  ADD_EXISTING_MODULE_BEGIN,
  ADD_EXISTING_MODULE_FAILURE,
  ADD_EXISTING_MODULE_SUCCESS,
  ADD_EXISTING_TEMPLATE_BEGIN,
  ADD_EXISTING_TEMPLATE_FAILURE,
  ADD_EXISTING_TEMPLATE_SUCCESS,
  ADD_MODULE_BEGIN,
  ADD_MODULE_FAILURE,
  ADD_MODULE_SUCCESS,
  ADD_PROJECT_BEGIN,
  ADD_PROJECT_FAILURE,
  ADD_PROJECT_SUCCESS,
  ADD_RATECARD_BEGIN,
  ADD_RATECARD_FAILURE,
  ADD_RATECARD_SUCCESS,
  ADD_SLA_BEGIN,
  ADD_SLA_FAILURE,
  ADD_SLA_SUCCESS,
  ADD_STATEMENT_BEGIN,
  ADD_STATEMENT_FAILURE,
  ADD_STATEMENT_SUCCESS,
  ADD_TEMPLATE_BEGIN,
  ADD_TEMPLATE_FAILURE,
  ADD_TEMPLATE_SUCCESS,
  CHANGE_ACTIVE_MODULE_BEGIN,
  CHANGE_ACTIVE_MODULE_FAILURE,
  CHANGE_ACTIVE_MODULE_SUCCESS,
  CHANGE_ACTIVE_PROJECT_BEGIN,
  CHANGE_ACTIVE_PROJECT_FAILURE,
  CHANGE_ACTIVE_PROJECT_SUCCESS,
  CHANGE_ACTIVE_STATEMENT_BEGIN,
  CHANGE_ACTIVE_STATEMENT_FAILURE,
  CHANGE_ACTIVE_STATEMENT_SUCCESS,
  CHANGE_ACTIVE_TEMPLATE_BEGIN,
  CHANGE_ACTIVE_TEMPLATE_FAILURE,
  CHANGE_ACTIVE_TEMPLATE_SUCCESS,
  CLEAR_MODULES_ARRAY,
  CLEAR_PROJECTS_ARRAY,
  CLEAR_SLAS_ARRAY,
  CLEAR_STATEMENTS_ARRAY,
  CLEAR_TEMPLATES_ARRAY,
  DATABASE_EXPLORER_TOGGLE,
  DATABASE_INFORMATION_NUMBER_DOWN_BEGIN,
  DATABASE_INFORMATION_NUMBER_DOWN_FAILURE,
  DATABASE_INFORMATION_NUMBER_DOWN_SUCCESS,
  DATABASE_INFORMATION_NUMBER_UP_BEGIN,
  DATABASE_INFORMATION_NUMBER_UP_FAILURE,
  DATABASE_INFORMATION_NUMBER_UP_SUCCESS,
  DELETE_DATABASE_INFORMATION_BEGIN,
  DELETE_DATABASE_INFORMATION_FAILURE,
  DELETE_DATABASE_INFORMATION_SUCCESS,
  DELETE_MODULE_BEGIN,
  DELETE_MODULE_FAILURE,
  DELETE_MODULE_SUCCESS,
  DELETE_PROJECT_BEGIN,
  DELETE_PROJECT_FAILURE,
  DELETE_PROJECT_SUCCESS,
  DELETE_RATECARD_BEGIN,
  DELETE_RATECARD_FAILURE,
  DELETE_RATECARD_SUCCESS,
  DELETE_SLA_BEGIN,
  DELETE_SLA_FAILURE,
  DELETE_SLA_SUCCESS,
  DELETE_STATEMENT_BEGIN,
  DELETE_STATEMENT_FAILURE,
  DELETE_STATEMENT_SUCCESS,
  DELETE_TEMPLATE_BEGIN,
  DELETE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_SUCCESS,
  FETCH_DATABASE_FIXEDPRICES_BEGIN,
  FETCH_DATABASE_FIXEDPRICES_FAILURE,
  FETCH_DATABASE_FIXEDPRICES_SUCCESS,
  FETCH_DATABASE_INFORMATION_BEGIN,
  FETCH_DATABASE_INFORMATION_FAILURE,
  FETCH_DATABASE_INFORMATION_SUCCESS,
  FETCH_DATABASE_REFERENCES_BEGIN,
  FETCH_DATABASE_REFERENCES_FAILURE,
  FETCH_DATABASE_REFERENCES_SUCCESS,
  FETCH_DATABASE_PIECEPRICES_BEGIN,
  FETCH_DATABASE_PIECEPRICES_FAILURE,
  FETCH_DATABASE_PIECEPRICES_SUCCESS,
  FETCH_DATABASE_RATECARD_BEGIN,
  FETCH_DATABASE_RATECARD_FAILURE,
  FETCH_DATABASE_RATECARD_SUCCESS,
  FETCH_DATABASE_TANDMS_BEGIN,
  FETCH_DATABASE_TANDMS_FAILURE,
  FETCH_DATABASE_TANDMS_SUCCESS,
  FETCH_MODULES_BEGIN,
  FETCH_MODULES_FAILURE,
  FETCH_MODULES_SUCCESS,
  FETCH_PROJECTS_BEGIN,
  FETCH_PROJECTS_FAILURE,
  FETCH_PROJECTS_SUCCESS,
  FETCH_SLAS_BEGIN,
  FETCH_SLAS_FAILURE,
  FETCH_SLAS_SUCCESS,
  FETCH_STATEMENTS_BEGIN,
  FETCH_STATEMENTS_FAILURE,
  FETCH_STATEMENTS_SUCCESS,
  FETCH_TEMPLATES_BEGIN,
  FETCH_TEMPLATES_FAILURE,
  FETCH_TEMPLATES_SUCCESS,
  MODULE_NUMBER_DOWN_BEGIN,
  MODULE_NUMBER_DOWN_FAILURE,
  MODULE_NUMBER_DOWN_SUCCESS,
  MODULE_NUMBER_UP_BEGIN,
  MODULE_NUMBER_UP_FAILURE,
  MODULE_NUMBER_UP_SUCCESS,
  REMOVE_DATABASE_MODULE_UPLOADS_BEGIN,
  REMOVE_DATABASE_MODULE_UPLOADS_FAILURE,
  REMOVE_DATABASE_MODULE_UPLOADS_SUCCESS,
  REMOVE_DATABASE_PROJECT_UPLOADS_BEGIN,
  REMOVE_DATABASE_PROJECT_UPLOADS_FAILURE,
  REMOVE_DATABASE_PROJECT_UPLOADS_SUCCESS,
  REMOVE_DATABASE_STATEMENT_UPLOADS_BEGIN,
  REMOVE_DATABASE_STATEMENT_UPLOADS_FAILURE,
  REMOVE_DATABASE_STATEMENT_UPLOADS_SUCCESS,
  REMOVE_DATABASE_TEMPLATE_UPLOADS_BEGIN,
  REMOVE_DATABASE_TEMPLATE_UPLOADS_FAILURE,
  REMOVE_DATABASE_TEMPLATE_UPLOADS_SUCCESS,
  SELECT_DATABASE_INFORMATION,
  SELECT_DATABASE_REFERENCE,
  SELECT_FIXEDPRICE,
  SELECT_MODULE,
  SELECT_PIECEPRICE,
  SELECT_PROJECT,
  SELECT_RATECARD,
  SELECT_SLA,
  SELECT_STATEMENT,
  SELECT_TANDM,
  SELECT_TEMPLATE,
  STATEMENT_NUMBER_DOWN_BEGIN,
  STATEMENT_NUMBER_DOWN_FAILURE,
  STATEMENT_NUMBER_DOWN_SUCCESS,
  STATEMENT_NUMBER_UP_BEGIN,
  STATEMENT_NUMBER_UP_FAILURE,
  STATEMENT_NUMBER_UP_SUCCESS,
  TEMPLATE_NUMBER_DOWN_BEGIN,
  TEMPLATE_NUMBER_DOWN_FAILURE,
  TEMPLATE_NUMBER_DOWN_SUCCESS,
  TEMPLATE_NUMBER_UP_BEGIN,
  TEMPLATE_NUMBER_UP_FAILURE,
  TEMPLATE_NUMBER_UP_SUCCESS,
  UPDATE_DATABASE_INFORMATION_BEGIN,
  UPDATE_DATABASE_INFORMATION_FAILURE,
  UPDATE_DATABASE_INFORMATION_SUCCESS,
  UPDATE_MODULE_BEGIN,
  UPDATE_MODULE_FAILURE,
  UPDATE_MODULE_SLA_BEGIN,
  UPDATE_MODULE_SLA_FAILURE,
  UPDATE_MODULE_SLA_SUCCESS,
  UPDATE_MODULE_SUCCESS,
  UPDATE_PIECEPRICE_BEGIN,
  UPDATE_PIECEPRICE_FAILURE,
  UPDATE_PIECEPRICE_SUCCESS,
  UPDATE_PROJECT_BEGIN,
  UPDATE_PROJECT_FAILURE,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_RATECARD_BEGIN,
  UPDATE_RATECARD_FAILURE,
  UPDATE_RATECARD_SUCCESS,
  UPDATE_SLA_BEGIN,
  UPDATE_SLA_FAILURE,
  UPDATE_SLA_SUCCESS,
  UPDATE_STATEMENT_BEGIN,
  UPDATE_STATEMENT_FAILURE,
  UPDATE_MODULE_PRICING_BEGIN,
  UPDATE_MODULE_PRICING_SUCCESS,
  UPDATE_MODULE_PRICING_FAILURE,
  UPDATE_STATEMENT_RESPONSIBILITIES_BEGIN,
  UPDATE_STATEMENT_RESPONSIBILITIES_FAILURE,
  UPDATE_STATEMENT_RESPONSIBILITIES_SUCCESS,
  UPDATE_STATEMENT_SUCCESS,
  UPDATE_TEMPLATE_BEGIN,
  UPDATE_TEMPLATE_FAILURE,
  UPDATE_TEMPLATE_SUCCESS,
  UPLOAD_DATABASE_MODULE_FILE_BEGIN,
  UPLOAD_DATABASE_MODULE_FILE_FAILURE,
  UPLOAD_DATABASE_MODULE_FILE_SUCCESS,
  UPLOAD_DATABASE_PROJECT_FILE_BEGIN,
  UPLOAD_DATABASE_PROJECT_FILE_FAILURE,
  UPLOAD_DATABASE_PROJECT_FILE_SUCCESS,
  UPLOAD_DATABASE_STATEMENT_FILE_BEGIN,
  UPLOAD_DATABASE_STATEMENT_FILE_FAILURE,
  UPLOAD_DATABASE_STATEMENT_FILE_SUCCESS,
  UPLOAD_DATABASE_TEMPLATE_FILE_BEGIN,
  UPLOAD_DATABASE_TEMPLATE_FILE_FAILURE,
  UPLOAD_DATABASE_TEMPLATE_FILE_SUCCESS,
  ADD_DATABASE_REFERENCE_BEGIN,
  ADD_DATABASE_REFERENCE_SUCCESS,
  ADD_DATABASE_REFERENCE_FAILURE,
  UPDATE_DATABASE_REFERENCE_BEGIN,
  UPDATE_DATABASE_REFERENCE_SUCCESS,
  UPDATE_DATABASE_REFERENCE_FAILURE,
  DELETE_DATABASE_REFERENCE_BEGIN,
  DELETE_DATABASE_REFERENCE_SUCCESS,
  DELETE_DATABASE_REFERENCE_FAILURE,
  SELECT_DATABASE_SUBCONTRACTOR,
  FETCH_DATABASE_SUBCONTRACTORS_BEGIN,
  FETCH_DATABASE_SUBCONTRACTORS_SUCCESS,
  FETCH_DATABASE_SUBCONTRACTORS_FAILURE,
  ADD_DATABASE_SUBCONTRACTOR_BEGIN,
  ADD_DATABASE_SUBCONTRACTOR_SUCCESS,
  ADD_DATABASE_SUBCONTRACTOR_FAILURE,
  UPDATE_DATABASE_SUBCONTRACTOR_BEGIN,
  UPDATE_DATABASE_SUBCONTRACTOR_SUCCESS,
  UPDATE_DATABASE_SUBCONTRACTOR_FAILURE,
  DELETE_DATABASE_SUBCONTRACTOR_BEGIN,
  DELETE_DATABASE_SUBCONTRACTOR_SUCCESS,
  DELETE_DATABASE_SUBCONTRACTOR_FAILURE,
  FETCH_CREATION_PROJECTS_BEGIN,
  FETCH_CREATION_PROJECTS_SUCCESS,
  FETCH_CREATION_PROJECTS_FAILURE,
  FETCH_CREATION_TEMPLATES_BEGIN,
  FETCH_CREATION_TEMPLATES_SUCCESS,
  FETCH_CREATION_TEMPLATES_FAILURE,
  FETCH_CREATION_MODULES_BEGIN,
  FETCH_CREATION_MODULES_SUCCESS,
  FETCH_CREATION_MODULES_FAILURE,
  FETCH_DATABASE_ADDITIONAL_FILES_IS_SHOWING_BEGIN,
  FETCH_DATABASE_ADDITIONAL_FILES_IS_SHOWING_SUCCESS,
  FETCH_DATABASE_ADDITIONAL_FILES_IS_SHOWING_FAILURE,
} from '../actionTypes/actionTypes';

const initialstate = {
  databaseExplorerToggleisOpen: false,
  error: null,
  fixedPrices: [],
  information: [],
  loading: false,
  modules: [],
  piecePrices: [],
  projects: [],
  ratecards: [],
  references: [],
  selectedFixedPrice: '',
  selectedInformation: '',
  selectedModule: '',
  selectedPiecePrice: '',
  selectedProject: '',
  selectedRatecard: '',
  selectedReference: '',
  selectedSla: '',
  selectedStatement: '',
  selectedSubcontractor: '',
  selectedTAndM: '',
  selectedTemplate: '',
  slas: [],
  statements: [],
  subcontractors: [],
  tandms: [],
  templates: [],
  creationprojects: [],
  creationtemplates: [],
  creationmodules: [],
  isShowing: [],
};

export default function databaseReducer(state = initialstate, action) {
  switch (action.type) {
    case ADD_DATABASE_SUBCONTRACTOR_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_DATABASE_SUBCONTRACTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        subcontractors: [
          ...state.subcontractors,
          {
            _id: action.payload.subcontractor._id,
            number: action.payload.subcontractor.number,
            question: action.payload.subcontractor.question,
            answer: action.payload.subcontractor.answer,
            project: action.payload.subcontractor.project,
          },
        ],
      };
    case ADD_DATABASE_SUBCONTRACTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_DATABASE_SUBCONTRACTOR_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_DATABASE_SUBCONTRACTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        subcontractors: [
          ...state.subcontractors.map((subcontractor) =>
            subcontractor._id === action.payload.subcontractor._id
              ? {
                _id: action.payload.subcontractor._id,
                number: action.payload.subcontractor.number,
                question: action.payload.subcontractor.question,
                answer: action.payload.subcontractor.answer,
                project: action.payload.subcontractor.project,
              }
              : subcontractor),
        ],
      };
    case UPDATE_DATABASE_SUBCONTRACTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_DATABASE_SUBCONTRACTOR_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_DATABASE_SUBCONTRACTOR_SUCCESS:
      return {
        ...state,
        loading: false,
        subcontractors: [
          ...state.subcontractors
            .filter(
              (subcontractor) =>
                subcontractor._id !== action.payload.subcontractor._id,
            )
            .map((subcontractor) =>
              subcontractor.number > action.payload.subcontractor.number
                ? {
                  _id: subcontractor._id,
                  number: subcontractor.number - 1,
                  question: subcontractor.question,
                  answer: subcontractor.answer,
                  project: subcontractor.project,
                }
                : subcontractor),
        ],
      };
    case DELETE_DATABASE_SUBCONTRACTOR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_DATABASE_SUBCONTRACTORS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DATABASE_SUBCONTRACTORS_SUCCESS:
      return {
        ...state,
        loading: false,
        subcontractors: action.payload.subcontractors,
      };
    case FETCH_DATABASE_SUBCONTRACTORS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        subcontractors: [],
      };
    case SELECT_DATABASE_SUBCONTRACTOR:
      return {
        ...state,
        selectedSubcontractor: action.payload.subcontractor,
      };
    case DELETE_DATABASE_REFERENCE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_DATABASE_REFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        references: [
          ...state.references
            .filter(
              (reference) => reference._id !== action.payload.reference._id,
            )
            .map((reference) =>
              reference.number > action.payload.reference.number
                ? {
                  _id: reference._id,
                  number: reference.number - 1,
                  question: reference.question,
                  answer: reference.answer,
                  project: reference.project,
                }
                : reference),
        ],
      };
    case DELETE_DATABASE_REFERENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: [
          ...state.projects.filter(
            (project) => project._id !== action.payload.data._id,
          ),
          {
            _id: action.payload.data._id,
            active: action.payload.data.active,
            attachment: action.payload.data.attachment,
            created: action.payload.data.created,
            createdBy: action.payload.data.createdBy,
            name: action.payload.data.name,
            updated: action.payload.data.updated,
            updatedBy: action.payload.data.updatedBy,
          },
        ],
      };
    case ADD_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_DATABASE_REFERENCE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_DATABASE_REFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        references: [
          ...state.references,
          {
            _id: action.payload.reference._id,
            number: action.payload.reference.number,
            question: action.payload.reference.question,
            answer: action.payload.reference.answer,
            project: action.payload.reference.project,
          },
        ],
      };
    case ADD_DATABASE_REFERENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_DATABASE_REFERENCE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_DATABASE_REFERENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        references: [
          ...state.references.map((reference) =>
            reference._id === action.payload.reference._id
              ? {
                _id: action.payload.reference._id,
                number: action.payload.reference.number,
                question: action.payload.reference.question,
                answer: action.payload.reference.answer,
                project: action.payload.reference.project,
              }
              : reference),
        ],
      };
    case UPDATE_DATABASE_REFERENCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_DATABASE_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_DATABASE_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        information: [
          ...state.information,
          {
            _id: action.payload.information._id,
            number: action.payload.information.number,
            question: action.payload.information.question,
            answer: action.payload.information.answer,
            project: action.payload.information.project,
          },
        ],
      };
    case ADD_DATABASE_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_DATABASE_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_DATABASE_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        information: [
          ...state.information.map((info) =>
            info._id === action.payload.information._id
              ? {
                _id: action.payload.information._id,
                number: action.payload.information.number,
                question: action.payload.information.question,
                answer: action.payload.information.answer,
                project: action.payload.information.project,
              }
              : info),
        ],
      };
    case UPDATE_DATABASE_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_DATABASE_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_DATABASE_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        information: [
          ...state.information
            .filter((info) => info._id !== action.payload.information._id)
            .map((info) =>
              info.number > action.payload.information.number
                ? {
                  _id: info._id,
                  number: info.number - 1,
                  question: info.question,
                  answer: info.answer,
                  project: info.project,
                }
                : info),
        ],
      };
    case DELETE_DATABASE_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DATABASE_INFORMATION_NUMBER_DOWN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DATABASE_INFORMATION_NUMBER_DOWN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DATABASE_INFORMATION_NUMBER_DOWN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DATABASE_INFORMATION_NUMBER_UP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DATABASE_INFORMATION_NUMBER_UP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DATABASE_INFORMATION_NUMBER_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_DATABASE_INFORMATION_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DATABASE_INFORMATION_SUCCESS:
      return {
        ...state,
        loading: false,
        information: action.payload.information,
      };
    case FETCH_DATABASE_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        information: [],
      };
    case SELECT_DATABASE_INFORMATION:
      return {
        ...state,
        selectedInformation: action.payload.id,
      };
    case FETCH_DATABASE_REFERENCES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DATABASE_REFERENCES_SUCCESS:
      return {
        ...state,
        loading: false,
        references: action.payload.references,
      };
    case FETCH_DATABASE_REFERENCES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        references: [],
      };
    case SELECT_DATABASE_REFERENCE:
      return {
        ...state,
        selectedReference: action.payload.reference,
      };
    case UPDATE_PIECEPRICE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PIECEPRICE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PIECEPRICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_SLA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
        slas: [...state.slas.filter((sla) => sla._id !== action.payload.id)],
      };
    case DELETE_SLA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_SLA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
        slas: [
          ...state.slas.filter((sla) => sla._id !== action.payload.data._id),
          {
            _id: action.payload.data._id,
            extra: action.payload.data.extra,
            priority: action.payload.data.priority,
            servicetime: action.payload.data.servicetime,
            availability: action.payload.data.availability,
            reactiontime: action.payload.data.reactiontime,
            solutiontime: action.payload.data.solutiontime,
            penalty: action.payload.data.penalty,
            penaltyreference: action.payload.data.penaltyreference,
            moduleName: action.payload.data.moduleName,
            module: action.payload.data.module,
            template: action.payload.data.template,
            project: action.payload.data.project,
          },
        ],
      };
    case ADD_SLA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_DATABASE_FIXEDPRICES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DATABASE_FIXEDPRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        fixedPrices: action.payload.fixedPrices,
      };
    case FETCH_DATABASE_FIXEDPRICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        fixedPrices: [],
      };
    case FETCH_DATABASE_PIECEPRICES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DATABASE_PIECEPRICES_SUCCESS:
      return {
        ...state,
        loading: false,
        piecePrices: action.payload.piecePrices,
      };
    case FETCH_DATABASE_PIECEPRICES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        piecePrices: [],
      };
    case FETCH_DATABASE_TANDMS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DATABASE_TANDMS_SUCCESS:
      return {
        ...state,
        loading: false,
        tandms: action.payload.tandms,
      };
    case FETCH_DATABASE_TANDMS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        tandms: [],
      };
    case UPLOAD_DATABASE_PROJECT_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_DATABASE_PROJECT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_DATABASE_PROJECT_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_DATABASE_PROJECT_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_DATABASE_PROJECT_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_DATABASE_PROJECT_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPLOAD_DATABASE_TEMPLATE_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_DATABASE_TEMPLATE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_DATABASE_TEMPLATE_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_DATABASE_TEMPLATE_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_DATABASE_TEMPLATE_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_DATABASE_TEMPLATE_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPLOAD_DATABASE_MODULE_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_DATABASE_MODULE_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_DATABASE_MODULE_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_DATABASE_MODULE_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_DATABASE_MODULE_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_DATABASE_MODULE_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPLOAD_DATABASE_STATEMENT_FILE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPLOAD_DATABASE_STATEMENT_FILE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPLOAD_DATABASE_STATEMENT_FILE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case REMOVE_DATABASE_STATEMENT_UPLOADS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case REMOVE_DATABASE_STATEMENT_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case REMOVE_DATABASE_STATEMENT_UPLOADS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case SELECT_TANDM:
      return {
        ...state,
        selectedTAndM: action.payload.tandm,
      };
    case SELECT_PIECEPRICE:
      return {
        ...state,
        selectedPiecePrice: action.payload.piecePrice,
      };
    case SELECT_FIXEDPRICE:
      return {
        ...state,
        selectedFixedPrice: action.payload.fixedPrice,
      };
    case SELECT_SLA:
      return {
        ...state,
        selectedSla: action.payload.sla,
      };
    case DELETE_RATECARD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_RATECARD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case DELETE_RATECARD_FAILURE:
      return {
        ...state,
        lodaing: false,
        error: action.payload.error,
      };
    case UPDATE_RATECARD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_RATECARD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_RATECARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_RATECARD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_RATECARD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case ADD_RATECARD_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }
    case SELECT_RATECARD:
      return {
        ...state,
        selectedRatecard: action.payload.id,
      };
    case FETCH_DATABASE_RATECARD_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DATABASE_RATECARD_SUCCESS:
      return {
        ...state,
        loading: false,
        ratecards: action.payload.ratecards,
      };
    case FETCH_DATABASE_RATECARD_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        ratecards: [],
      };
    case DATABASE_EXPLORER_TOGGLE:
      return {
        ...state,
        databaseExplorerToggleisOpen: action.payload,
      };
    case UPDATE_SLA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
        slas: [
          ...state.slas.map((sla) =>
            sla._id === action.payload.data._id
              ? {
                _id: action.payload.data._id,
                extra: action.payload.data.extra,
                priority: action.payload.data.priority,
                servicetime: action.payload.data.servicetime,
                availability: action.payload.data.availability,
                reactiontime: action.payload.data.reactiontime,
                solutiontime: action.payload.data.solutiontime,
                penalty: action.payload.data.penalty,
                penaltyreference: action.payload.data.penaltyreference,
                moduleName: action.payload.data.moduleName,
                module: action.payload.data.module,
                template: action.payload.data.template,
                project: action.payload.data.project,
              }
              : sla),
        ],
      };
    case UPDATE_SLA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CLEAR_SLAS_ARRAY:
      return {
        ...state,
        slas: [],
      };
    case FETCH_SLAS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SLAS_SUCCESS:
      return {
        ...state,
        loading: false,
        slas: action.payload.slas,
      };
    case FETCH_SLAS_FAILURE:
      return {
        ...state,
        loading: false,
        slas: [],
        error: action.payload.error,
      };
    case UPDATE_MODULE_SLA_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_MODULE_SLA_SUCCESS:
      return {
        ...state,
        loading: false,
        modules: [
          ...state.modules.map((module) =>
            module._id === action.payload.data._id
              ? {
                _id: action.payload.data._id,
                active: action.payload.data.active,
                attachment: action.payload.data.attachment,
                created: action.payload.data.created,
                createdBy: action.payload.data.createdBy,
                name: action.payload.data.name,
                heading: action.payload.data.heading,
                number: action.payload.data.number,
                project: action.payload.data.project,
                template: action.payload.data.template,
                sla: action.payload.data.sla,
                pricing: action.payload.data.pricing,
                updated: action.payload.data.updated,
                updatedBy: action.payload.data.updatedBy,
              }
              : module),
        ],
      };
    case UPDATE_MODULE_SLA_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_MODULE_PRICING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_MODULE_PRICING_SUCCESS:
      return {
        ...state,
        loading: false,
        modules: [
          ...state.modules.map((module) =>
            module._id === action.payload.data._id
              ? {
                _id: action.payload.data._id,
                active: action.payload.data.active,
                attachment: action.payload.data.attachment,
                created: action.payload.data.created,
                createdBy: action.payload.data.createdBy,
                name: action.payload.data.name,
                heading: action.payload.data.heading,
                number: action.payload.data.number,
                project: action.payload.data.project,
                template: action.payload.data.template,
                sla: action.payload.data.sla,
                pricing: action.payload.data.pricing,
                updated: action.payload.data.updated,
                updatedBy: action.payload.data.updatedBy,
              }
              : module),
        ],
      };
    case UPDATE_MODULE_PRICING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_STATEMENT_RESPONSIBILITIES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_STATEMENT_RESPONSIBILITIES_SUCCESS:
      return {
        ...state,
        loading: false,
        statements: [
          ...state.statements.map((statement) =>
            statement._id === action.payload.data._id
              ? {
                _id: action.payload.data._id,
                active: action.payload.data.active,
                attachment: action.payload.data.attachment,
                created: action.payload.data.created,
                createdBy: action.payload.data.createdBy,
                description: action.payload.data.description,
                number: action.payload.data.number,
                project: action.payload.data.project,
                template: action.payload.data.template,
                module: action.payload.data.module,
                customer: action.payload.data.customer,
                contractor: action.payload.data.contractor,
                updated: action.payload.data.updated,
                updatedBy: action.payload.data.updatedBy,
              }
              : statement),
        ],
      };
    case UPDATE_STATEMENT_RESPONSIBILITIES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case STATEMENT_NUMBER_DOWN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case STATEMENT_NUMBER_DOWN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case STATEMENT_NUMBER_DOWN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case STATEMENT_NUMBER_UP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case STATEMENT_NUMBER_UP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case STATEMENT_NUMBER_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case MODULE_NUMBER_DOWN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MODULE_NUMBER_DOWN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MODULE_NUMBER_DOWN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case MODULE_NUMBER_UP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case MODULE_NUMBER_UP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case MODULE_NUMBER_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case TEMPLATE_NUMBER_DOWN_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TEMPLATE_NUMBER_DOWN_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TEMPLATE_NUMBER_DOWN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case TEMPLATE_NUMBER_UP_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TEMPLATE_NUMBER_UP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case TEMPLATE_NUMBER_UP_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CHANGE_ACTIVE_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHANGE_ACTIVE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: [
          ...state.projects.map((project) =>
            project._id === action.payload.data._id
              ? {
                _id: action.payload.data._id,
                active: action.payload.data.active,
                attachment: action.payload.data.attachment,
                created: action.payload.data.created,
                createdBy: action.payload.data.createdBy,
                name: action.payload.data.name,
                updated: action.payload.data.updated,
                updatedBy: action.payload.data.updatedBy,
              }
              : project),
        ],
      };
    case CHANGE_ACTIVE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CHANGE_ACTIVE_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHANGE_ACTIVE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: [
          ...state.templates.map((template) =>
            template._id === action.payload.data._id
              ? {
                _id: action.payload.data._id,
                active: action.payload.data.active,
                attachment: action.payload.data.attachment,
                created: action.payload.data.created,
                createdBy: action.payload.data.createdBy,
                name: action.payload.data.name,
                number: action.payload.data.number,
                project: { _id: action.payload.data.project },
                updated: action.payload.data.updated,
                updatedBy: action.payload.data.updatedBy,
              }
              : template),
        ],
      };
    case CHANGE_ACTIVE_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CHANGE_ACTIVE_MODULE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHANGE_ACTIVE_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        modules: [
          ...state.modules.map((module) =>
            module._id === action.payload.data._id
              ? {
                _id: action.payload.data._id,
                active: action.payload.data.active,
                attachment: action.payload.data.attachment,
                created: action.payload.data.created,
                createdBy: action.payload.data.createdBy,
                name: action.payload.data.name,
                heading: action.payload.data.heading,
                number: action.payload.data.number,
                project: action.payload.data.project,
                template: action.payload.data.template,
                sla: action.payload.data.sla,
                pricing: action.payload.data.pricing,
                updated: action.payload.data.updated,
                updatedBy: action.payload.data.updatedBy,
              }
              : module),
        ],
      };
    case CHANGE_ACTIVE_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CHANGE_ACTIVE_STATEMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CHANGE_ACTIVE_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        statements: [
          ...state.statements.map((statement) =>
            statement._id === action.payload.data._id
              ? {
                _id: action.payload.data._id,
                active: action.payload.data.active,
                attachment: action.payload.data.attachment,
                created: action.payload.data.created,
                createdBy: action.payload.data.createdBy,
                description: action.payload.data.description,
                number: action.payload.data.number,
                project: action.payload.data.project,
                template: action.payload.data.template,
                module: action.payload.data.module,
                customer: action.payload.data.customer,
                contractor: action.payload.data.contractor,
                updated: action.payload.data.updated,
                updatedBy: action.payload.data.updatedBy,
              }
              : statement),
        ],
      };
    case CHANGE_ACTIVE_STATEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case CLEAR_STATEMENTS_ARRAY:
      return {
        ...state,
        statements: [],
      };
    case CLEAR_MODULES_ARRAY:
      return {
        ...state,
        modules: [],
      };
    case CLEAR_TEMPLATES_ARRAY:
      return {
        ...state,
        templates: [],
      };
    case CLEAR_PROJECTS_ARRAY:
      return {
        ...state,
        projects: [],
      };
    case SELECT_PROJECT:
      return {
        ...state,
        selectedProject: action.payload.project,
      };
    case SELECT_TEMPLATE:
      return {
        ...state,
        selectedTemplate: action.payload.template,
      };
    case SELECT_MODULE:
      return {
        ...state,
        selectedModule: action.payload.module,
      };
    case SELECT_STATEMENT:
      return {
        ...state,
        selectedStatement: action.payload.statement,
      };
    case DELETE_STATEMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        statements: [
          ...state.statements
            .filter((statement) => statement._id !== action.payload.data._id)
            .map((statement) =>
              statement.number > action.payload.data.number
                ? {
                  _id: statement._id,
                  active: statement.active,
                  attachment: statement.attachment,
                  created: statement.created,
                  createdBy: statement.createdBy,
                  description: statement.description,
                  number: statement.number - 1,
                  project: statement.project,
                  template: statement.template,
                  module: statement.module,
                  customer: statement.customer,
                  contractor: statement.contractor,
                  updated: statement.updated,
                  updatedBy: statement.updatedBy,
                }
                : statement),
        ],
      };
    case DELETE_STATEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_MODULE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        modules: [
          ...state.modules
            .filter((module) => module._id !== action.payload.data._id)
            .map((module) =>
              module.number > action.payload.data.number
                ? {
                  _id: module._id,
                  active: module.active,
                  attachment: module.attachment,
                  name: module.name,
                  heading: module.heading,
                  sla: module.sla,
                  pricing: module.pricing,
                  number: module.number - 1,
                  project: action.payload.data.project,
                  template: action.payload.data.template,
                  updated: module.updated,
                }
                : module),
        ],
      };
    case DELETE_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: [
          ...state.templates
            .filter((template) => template._id !== action.payload.data._id)
            .map((template) =>
              template.number > action.payload.data.number
                ? {
                  _id: template._id,
                  active: template.active,
                  attachment: template.attachment,
                  name: template.name,
                  number: template.number - 1,
                  project: { _id: action.payload.data.project },
                  updated: template.updated,
                }
                : template),
        ],
      };
    case DELETE_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case DELETE_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: [
          ...state.projects.filter(
            (project) => project._id !== action.payload.projectId,
          ),
        ],
      };
    case DELETE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_PROJECT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: [
          ...state.projects.map((project) =>
            project._id === action.payload.data._id
              ? {
                _id: action.payload.data._id,
                active: action.payload.data.active,
                attachment: action.payload.data.attachment,
                created: action.payload.data.created,
                createdBy: action.payload.data.createdBy,
                name: action.payload.data.name,
                updated: action.payload.data.updated,
                updatedBy: action.payload.data.updatedBy,
              }
              : project),
        ],
      };
    case UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: [
          ...state.templates.map((template) =>
            template._id === action.payload.data._id
              ? {
                _id: action.payload.data._id,
                active: action.payload.data.active,
                attachment: action.payload.data.attachment,
                created: action.payload.data.created,
                createdBy: action.payload.data.createdBy,
                name: action.payload.data.name,
                number: action.payload.data.number,
                project: { _id: action.payload.data.project },
                updated: action.payload.data.updated,
                updatedBy: action.payload.data.updatedBy,
              }
              : template),
        ],
      };
    case UPDATE_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_STATEMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        statements: [
          ...state.statements.map((statement) =>
            statement._id === action.payload.data._id
              ? {
                _id: action.payload.data._id,
                active: action.payload.data.active,
                attachment: action.payload.data.attachment,
                created: action.payload.data.created,
                createdBy: action.payload.data.createdBy,
                description: action.payload.data.description,
                number: action.payload.data.number,
                project: action.payload.data.project,
                template: action.payload.data.template,
                module: action.payload.data.module,
                customer: action.payload.data.customer,
                contractor: action.payload.data.contractor,
                updated: action.payload.data.updated,
                updatedBy: action.payload.data.updatedBy,
              }
              : statement),
        ],
      };
    case UPDATE_STATEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case UPDATE_MODULE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case UPDATE_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        modules: [
          ...state.modules.map((module) =>
            module._id === action.payload.data._id
              ? {
                _id: action.payload.data._id,
                active: action.payload.data.active,
                attachment: action.payload.data.attachment,
                created: action.payload.data.created,
                createdBy: action.payload.data.createdBy,
                name: action.payload.data.name,
                heading: action.payload.data.heading,
                number: action.payload.data.number,
                project: action.payload.data.project,
                template: action.payload.data.template,
                sla: action.payload.data.sla,
                pricing: action.payload.data.pricing,
                updated: action.payload.data.updated,
                updatedBy: action.payload.data.updatedBy,
              }
              : module),
        ],
      };
    case UPDATE_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_STATEMENT_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_STATEMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        statements: [
          ...state.statements.filter(
            (statement) => statement._id !== action.payload.data._id,
          ),
          {
            _id: action.payload.data._id,
            active: action.payload.data.active,
            attachment: action.payload.data.attachment,
            created: action.payload.data.created,
            createdBy: action.payload.data.createdBy,
            description: action.payload.data.description,
            number: action.payload.data.number,
            project: action.payload.data.project,
            template: action.payload.data.template,
            module: action.payload.data.module,
            customer: action.payload.data.customer,
            contractor: action.payload.data.contractor,
            updated: action.payload.data.updated,
            updatedBy: action.payload.data.updatedBy,
          },
        ],
      };
    case ADD_STATEMENT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_MODULE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        modules: [
          ...state.modules.filter(
            (module) => module._id !== action.payload.data._id,
          ),
          {
            _id: action.payload.data._id,
            active: action.payload.data.active,
            attachment: action.payload.data.attachment,
            created: action.payload.data.created,
            createdBy: action.payload.data.createdBy,
            name: action.payload.data.name,
            heading: action.payload.data.heading,
            number: action.payload.data.number,
            project: action.payload.data.project,
            template: action.payload.data.template,
            sla: action.payload.data.sla,
            pricing: action.payload.data.pricing,
            updated: action.payload.data.updated,
            updatedBy: action.payload.data.updatedBy,
          },
        ],
      };
    case ADD_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: [
          ...state.templates.filter(
            (template) => template._id !== action.payload.data._id,
          ),
          {
            _id: action.payload.data._id,
            active: action.payload.data.active,
            attachment: action.payload.data.attachment,
            created: action.payload.data.created,
            createdBy: action.payload.data.createdBy,
            name: action.payload.data.name,
            number: action.payload.data.number,
            project: { _id: action.payload.data.project._id },
            updated: action.payload.data.updated,
            updatedBy: action.payload.data.updatedBy,
          },
        ],
      };
    case ADD_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_EXISTING_TEMPLATE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_EXISTING_TEMPLATE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_EXISTING_TEMPLATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case ADD_EXISTING_MODULE_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_EXISTING_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case ADD_EXISTING_MODULE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    case FETCH_PROJECTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        projects: action.payload.projects,
      };
    case FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        projects: [],
      };
    case FETCH_CREATION_PROJECTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CREATION_PROJECTS_SUCCESS:
      return {
        ...state,
        loading: false,
        creationprojects: action.payload.projects,
      };
    case FETCH_CREATION_PROJECTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        creationprojects: [],
      };
    case FETCH_TEMPLATES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        templates: action.payload.templates,
      };
    case FETCH_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        templates: [],
      };
    case FETCH_CREATION_TEMPLATES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CREATION_TEMPLATES_SUCCESS:
      return {
        ...state,
        loading: false,
        creationtemplates: action.payload.templates,
      };
    case FETCH_CREATION_TEMPLATES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        creationtemplates: [],
      };
    case FETCH_MODULES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        modules: action.payload.modules,
      };
    case FETCH_MODULES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        modules: [],
      };
    case FETCH_CREATION_MODULES_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_CREATION_MODULES_SUCCESS:
      return {
        ...state,
        loading: false,
        creationmodules: action.payload.modules,
      };
    case FETCH_CREATION_MODULES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        creationmodules: [],
      };
    case FETCH_STATEMENTS_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_STATEMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        statements: action.payload.statements,
      };
    case FETCH_STATEMENTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        statements: [],
      };
    case FETCH_DATABASE_ADDITIONAL_FILES_IS_SHOWING_BEGIN:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_DATABASE_ADDITIONAL_FILES_IS_SHOWING_SUCCESS:
      return {
        ...state,
        loading: false,
        isShowing: action.payload.isShowing,
      };
    case FETCH_DATABASE_ADDITIONAL_FILES_IS_SHOWING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        isShowing: [true, true, true, true, true, true, true, true],
      };
    default:
      return state;
  }
}
