import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link, IconButton, Tooltip } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import UnfoldLessIcon from '@material-ui/icons/UnfoldLess';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import useStyles from '../../styles';
// import TableModuleListButton from '../Buttons/TableModuleListButton';
import DateStamp from '../Elements/DateStamp';
// import UploadHandler from '../Elements/UploadHandler';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';
import EditOfferingSuggestionDialog from '../Dialogs/EditOfferingSuggestionDialog';
import TableSwitch from '../Buttons/TableSwitch';

function OfferingModulesTable(props) {
  const {
    projects,
    templates,
    modules,
    selectProject,
    // selectedProject,
    selectTemplate,
    selectedTemplate,
    updateStatement,
    // selectModule,
    // user,
  } = props;
  const [expanded, setExpansion] = React.useState(true);

  const classes = useStyles();
  const columns = [
    {
      name: 'moduleId',
      label: 'ModuleID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'statementId',
      label: 'StatementID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'heading',
      label: 'Überschrift',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'number',
      label: '#',
      options: {
        filter: false,
        sort: true,
        setCellProps: () => ({ style: { width: '50px' } }),
        customBodyRender: (value, tableMeta) => {
          if (!tableMeta.rowData[1]) {
            return <Typography variant="body2" style={{ fontWeight: 600 }}>{value}</Typography>;
          }
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: 'description',
      label: 'Beschreibung',
      options: {
        filter: true,
        sort: false,
        customHeadLabelRender: (columnMeta) => (
          <>
            {columnMeta.label}
            <Tooltip title="Beschreibung ein-/ausklappen">
              <IconButton aria-label="Beschreibung ein-/ausklappen" onClick={() => setExpansion(!expanded)}>
                {expanded ? <UnfoldLessIcon /> : <UnfoldMoreIcon />}
              </IconButton>
            </Tooltip>
          </>
        ),
        customBodyRender: (value, tableMeta) => {
          if (!tableMeta.rowData[1]) {
            return (
              <div>
                <Typography variant="body2" style={{ fontWeight: 600 }}>{value}</Typography>
              </div>);
          }
          return <Typography variant="body2">{value}</Typography>;
        },
      },
    },
    {
      name: 'suggestion',
      label: 'Vorschlag',
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: 'name',
    //   label: 'Name',
    //   options: {
    //     filter: true,
    //     sort: true,
    //     customBodyRender: (value, tableMeta) => (
    //       <TableModuleListButton
    //         name={value}
    //         heading={tableMeta.rowData[1]}
    //         onClick={() => {
    //           selectModule(tableMeta.rowData[0]);
    //         }}
    //       />
    //     ),
    //   },
    // },
    {
      name: 'pricing',
      label: 'Verrechnungsart',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'sla',
      label: 'Service Level',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'dissenses',
      label: 'Dissense',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'updated',
      label: 'Geändert',
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => <DateStamp date={value} />,
      },
    },
    {
      name: 'compliance',
      label: 'Compliance',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (tableMeta.rowData[1]) {
            return <TableSwitch
              value={value}
              onChange={() =>
                updateStatement({
                  variables: { projectId: projects._id, templateId: selectedTemplate, moduleId: tableMeta.rowData[0], statementId: tableMeta.rowData[1], compliance: !value },
                })}
              disabled={projects.published || projects.authorization === 'read'}
            />;
          }
          return (
            <Typography variant="body2">
              Preisbezug:
              {' '}
              { tableMeta.rowData[6] || 'Nein' }
              <br />
              Service Level:
              {' '}
              { tableMeta.rowData[7] ? 'Ja' : 'Nein' }
            </Typography>
          );
        },
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        display: !projects.published,
        customBodyRender: (value, tableMeta) => {
          if (tableMeta.rowData[1] && !tableMeta.rowData[10] && !projects.published && projects.authorization !== 'read') {
            return (
              <EditOfferingSuggestionDialog
                projectId={projects._id}
                statementId={tableMeta.rowData[1]}
                header="Vorschlag formulieren"
                text="Vorschlag"
                opened={
                  !tableMeta.rowData[10] && !tableMeta.rowData[5].length > 0
                }
                onClick={updateStatement}
                originalData={tableMeta.rowData[4]}
                oldData={tableMeta.rowData[5]}
              />
            );
          }
          if (!tableMeta.rowData[1]) {
            return (
              <>
              </>
            );
          }
          return <></>;
        },
      },
    },
  ];

  const data = [];

  modules
    .forEach((module) => {
      data.push({
        moduleId: module._id,
        number: module.number,
        description: module.name,
        heading: module.heading,
        dissenses: module.dissenses,
        pricing: module.prices[0] ? module.prices[0].type : '',
        sla: !!module.servicelevels[0],
        updated: Math.max(...module.changes.map((o) => o.date)),
      });
      module.statements.sort((a, b) => a.number - b.number).forEach((statement) => {
        data.push({
          statementId: statement._id,
          moduleId: module._id,
          number: `${module.number}.${statement.number}`,
          description: statement.description,
          suggestion: statement.suggestion,
          compliance: statement.compliance,
          updated: Math.max(...statement.changes.map((o) => o.date)),
        });
      });
    });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectTemplate('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => selectTemplate('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">
                {templates
                  .filter((template) => template._id === selectedTemplate)
                  .map((template) => template.name)}
              </Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({
          setRowProps: (row) => ({
            className: row[1] && !expanded ? classes.rowHidden : classes.rowVisible,
          }),
          tableBodyMaxHeight: 'calc(100vh - 129px)',
        })}
      />
    </div>
  );
}

export default OfferingModulesTable;

OfferingModulesTable.propTypes = {
  modules: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  projects: PropTypes.shape(Object).isRequired,
  // selectedProject: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  // selectModule: PropTypes.func.isRequired,
  // user: PropTypes.shape(Object).isRequired,
  updateStatement: PropTypes.func.isRequired,
};
