import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';

import { Typography } from '@material-ui/core';
import TableButton from '../Buttons/TableButton';
import DeleteDialog from '../Dialogs/DeleteDialog';
import EditDialog from '../Dialogs/EditDialog';
import PublishProjectDialog from '../Dialogs/PublishProjectDialog';
import DateStamp from '../Elements/DateStamp';
import ExportButton from '../Elements/ExportButton';
import UploadHandler from '../Elements/UploadHandler';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function NegotiationProjectsTable(props) {
  const {
    projects,
    deleteProject,
    selectProject,
    publishNegotiation,
    updateProject,
    // uploadNegotiationProjectFile,
    // removeNegotiationProjectUpload,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta) => (
          <TableButton
            text={value}
            onClick={() => selectProject(tableMeta.rowData[0])}
          />
        ),
      },
    },
    {
      name: 'company',
      label: 'Anbieter',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'phase',
      label: 'Phase',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => `${value + 1}. Verhandlung`,
      },
    },
    {
      name: 'export',
      label: 'Export',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <ExportButton
            id={tableMeta.rowData[0]}
            url="/api/v1/export/word/"
          />
        ),
      },
    },
    {
      name: 'attachment',
      label: 'Anhänge',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <UploadHandler
            projectId={tableMeta.rowData[0]}
            referenceId={tableMeta.rowData[0]}
            url="/api/v1/upload/"
            referenceType="project"
            attachments={value}
            disabled={tableMeta.rowData[13] === 'read'}
          />
        ),
      },
    },
    {
      name: 'dissenses',
      label: 'offene Dissense',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'updated',
      label: 'Geändert',
      options: {
        filter: false,
        sort: true,
        display: false,
        customBodyRender: (value) => (
          <div>
            <DateStamp date={value} />
          </div>
        ),
      },
    },
    {
      name: 'deadline',
      label: 'Status',
      options: {
        filter: false,
        sort: true,
        customBodyRender: (value, tableMeta) => {
          if (tableMeta.rowData[12]) {
            return (
              <>
                Veröffentlicht / Deadline:
                <DateStamp date={value} />
                <PublishProjectDialog
                  id={tableMeta.rowData[0]}
                  onClick={publishNegotiation}
                  type="project"
                />
              </>
            );
          }
          return (
            <PublishProjectDialog
              id={tableMeta.rowData[0]}
              onClick={publishNegotiation}
              type="project"
            />
          );
        },
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <div>
            {tableMeta.rowData[13] === 'admin' && (
              <DeleteDialog
                header="Verhandlung löschen"
                text={`Möchten Sie die Verhandlung "${tableMeta.rowData[2]} - ${
                  tableMeta.rowData[1]
                } (${
                  tableMeta.rowData[3] + 1
                }. Verhandlung)" wirklich löschen?`}
                onClick={() => deleteProject({
                  variables: { projectId: tableMeta.rowData[0] },
                })}
              />
            )}
          </div>
        ),
      },
    },
    {
      name: 'viewers',
      label: 'Viewers',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'editors',
      label: 'Editors',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'published',
      label: 'Published',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'authorization',
      label: 'Berechtigung',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'duration',
      label: 'Laufzeit',
      options: {
        display: false,
        viewColumns: true,
        filter: false,
        sort: false,
        customBodyRender: (value, tableMeta) => (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2">
              Laufzeit:
              {' '}
              {value}
              {' '}
              Jahre
            </Typography>
            {tableMeta.rowData[13] !== 'read' && !tableMeta.rowData[12] && (
            <EditDialog
              projectId={tableMeta.rowData[0]}
              oldData={value}
              header="Laufzeit bearbeiten"
              text="Laufzeit"
              field="duration"
              onClick={updateProject}
            />
            )}
          </div>
        ),
      },
    },
  ];

  const data = [];

  projects.forEach((project) => {
    data.push({
      _id: project._id,
      name: project.name,
      company: project.company,
      phase: project.phase,
      dissenses: `SOWs: ${project.dissenses} SL: ${project.dissensesSla}`,
      description: project.description,
      updated: Math.max(...project.changes.map((o) => o.date)),
      attachment: project.attachments,
      duration: project.duration,
      editors: project.editors,
      viewers: project.viewers,
      deadline: project.deadline,
      published: project.published,
      authorization: project.authorization,
    });
  });

  return (
    <div>
      <MUIDataTable
        title="Verhandlungen"
        data={data}
        columns={columns}
        options={FullsizeTableOptions({ tableBodyMaxHeight: 'calc(100vh - 129px)' })}
      />
    </div>
  );
}

export default NegotiationProjectsTable;

NegotiationProjectsTable.propTypes = {
  projects: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  deleteProject: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  publishNegotiation: PropTypes.func.isRequired,
  updateProject: PropTypes.func.isRequired,
  // uploadNegotiationProjectFile: PropTypes.func.isRequired,
  // removeNegotiationProjectUpload: PropTypes.func.isRequired,
};
