import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
} from '@material-ui/core';
import {
  TextField,
} from 'formik-material-ui';
import { blue, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyInput from '../Elements/CurrencyInput';
import TableEditIconButton from '../Buttons/TableEditIconButton';

const useStyles = makeStyles(() => ({
  cancelButton: {
    color: grey[300],
  },
  submitButton: {
    color: blue[300],
  },
}));

export default function EditOfferingRatecardDialog(props) {
  const { projectId, ratecardId, onClick, oldData } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const inputRef = React.useRef();

  const handleClickOpen = () => {
    setOpen(true);
    // timeout to select textfield
    const timeout = setTimeout(() => {
      inputRef.current.focus();
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <TableEditIconButton onClick={handleClickOpen} />
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Ratecard-Eintrag spezifizieren
        </DialogTitle>
        <DialogContent>
          <Formik
            initialValues={{
              profile: oldData[0],
              description: oldData[1],
              dailyrate: oldData[2],
              travelallowance: oldData[3],
            }}
            validationSchema={Yup.object({
              dailyrate: Yup.mixed().required('Darf nicht leer sein'),
              travelallowance: Yup.mixed().required('Darf nicht leer sein'),
            })}
            onSubmit={(values) => {
              onClick({ variables: {
                projectId,
                ratecardId,
                dailyrate: values.dailyrate,
                travelallowance: values.travelallowance,
              } });
              setOpen(false);
            }}

          >
            {({
              handleSubmit,
              setFieldValue,
            }) => (
              <Form>
                <FormControl margin="normal" fullWidth>
                  <Field
                    component={TextField}
                    label="Profilname"
                    name="profile"
                    disabled
                  />
                  <Field
                    component={TextField}
                    label="Profilbeschreibung"
                    name="description"
                    disabled
                  />
                </FormControl>
                <FormControl margin="normal" fullWidth>
                  <Field
                    component={CurrencyInput}
                    inputRef={inputRef}
                    label="Tagessatz"
                    name="dailyrate"
                    onChange={(e) => {
                      setFieldValue('dailyrate', e.target.value);
                    }}
                  />
                </FormControl>
                <FormControl margin="normal" fullWidth>
                  <Field
                    component={CurrencyInput}
                    label="Kostenpauschale pro Reisetag"
                    name="travelallowance"
                    onChange={(e) => {
                      setFieldValue('travelallowance', e.target.value);
                    }}
                  />
                </FormControl>

                <DialogActions>
                  <Button
                    className={classes.cancelButton}
                    onClick={handleClose}
                    color="primary"
                  >
                    Abbrechen
                  </Button>
                  <Button
                    className={classes.submitButton}
                    onClick={handleSubmit}
                    color="primary"
                    autoFocus
                  >
                    Ändern
                  </Button>

                </DialogActions>

              </Form>
            )}
          </Formik>

        </DialogContent>
      </Dialog>
    </div>
  );
}

EditOfferingRatecardDialog.propTypes = {
  onClick: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  ratecardId: PropTypes.string.isRequired,
  oldData: PropTypes.arrayOf(PropTypes.any).isRequired,
};
