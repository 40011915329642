import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_PRICES, GET_PROJECT } from '../../graphql/queries';
import { ANSWER_PRICE } from '../../graphql/mutations';
import {
  // fetchPiecePrices,
  // updatePiecePrice,
  selectPiecePrice,
  selectProject,
  selectTemplate,
} from '../../redux/actionCreators/offeringAction';
import OfferingPiecePricesTable from '../../components/OfferingManagement/OfferingPiecePricesTable';
import Loading from '../../components/Elements/Loading';

function OfferingPiecePricesContainer(props) {
  const {
    // piecePrices,
    // projects,
    // templates,
    // fetchPiecePrices,
    // updatePiecePrice,
    selectPiecePrice,
    selectProject,
    selectTemplate,
    selectedProject,
    // loading,
    // user,
  } = props;
  // useEffect(() => {
  //   fetchPiecePrices(selectedProject);
  // }, [fetchPiecePrices, selectedProject]);

  // piecePrices.forEach((element) => {
  //   element.templateName = templates.filter((template) => template._id === element.template).map((template) => template.name).toString();
  // });

  const priceType = 'Stückpreis';
  const { loading, error, data } = useQuery(GET_PRICES, {
    variables: { projectId: selectedProject, type: priceType },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });
  const [answerPrice] = useMutation(ANSWER_PRICE, {
    refetchQueries: [{
      query: GET_PRICES,
      variables: { projectId: selectedProject, type: priceType },
    }],
  });
  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  return (
    <OfferingPiecePricesTable
      piecePrices={data.pricesByType}
      projects={data1.project}
      // templates={templates}
      updatePiecePrice={answerPrice}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      selectPiecePrice={selectPiecePrice}
      // loading={loading}
      // user={user}
    />
  );
}

const mapStateToProps = (state) => ({
  piecePrices: state.offering.piecePrices,
  projects: state.offering.projects,
  templates: state.offering.templates,
  selectedProject: state.offering.selectedProject,
  loading: state.offering.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchPiecePrices: (id) => dispatch(fetchPiecePrices(id)),
  // updatePiecePrice: (piecePriceId, pricePerPiece) =>
  //   dispatch(updatePiecePrice(piecePriceId, pricePerPiece)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectPiecePrice: (id) => dispatch(selectPiecePrice(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferingPiecePricesContainer),
);

OfferingPiecePricesContainer.propTypes = {
  // fetchPiecePrices: PropTypes.func.isRequired,
  // updatePiecePrice: PropTypes.func.isRequired,
  selectPiecePrice: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // piecePrices: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  // templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // user: PropTypes.shape(Object).isRequired,
};
