import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Tooltip,
} from '@material-ui/core';

function ExportInformationButton(props) {
  const { projectId, phase, type, typeText, icon } = props;
  const [open, setOpen] = useState(false);

  const exportHandle = (filename) => {
    setOpen(true);
    axios({
      url: `/api/v1/export/viewoffering/${projectId}/${phase}/${type}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        `${typeText}_${filename}_Angebot${phase + 1}.xlsx`,
      );
      document.body.appendChild(link);
      link.click();
      setOpen(false);
    });
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Export wird erstellt...
        </DialogTitle>
        <DialogContent>
          Ihr Excel-Dokument wird erstellt. Bitte haben Sie einen Augenblick
          Geduld. Sie weden zum Download aufgefordert, sobald das Dokument
          erstellt wurde.
        </DialogContent>
      </Dialog>
      <Tooltip title={`Export - ${typeText}`}>
        <IconButton onClick={() => exportHandle(projectId)}>
          {icon}
        </IconButton>
      </Tooltip>
    </>
  );
}

export default ExportInformationButton;

ExportInformationButton.propTypes = {
  projectId: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  typeText: PropTypes.string.isRequired,
  phase: PropTypes.number.isRequired,
  icon: PropTypes.shape(Object).isRequired,
};
