import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

function CurrencyFormat(props) {
  const { decimalScale, ...other } = props;

  return (
    <NumberFormat
      {...other}
      displayType="text"
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={decimalScale === 0 ? 0 : 2}
      fixedDecimalScale
      suffix=" €"
    />
  );
}

export default CurrencyFormat;

CurrencyFormat.propTypes = {
  decimalScale: PropTypes.number,
};
