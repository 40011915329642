import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  selectProject,
  selectTemplate,
  selectModule,
  selectSla,
  selectRatecard,
  selectTAndM,
  selectFixedPrice,
  selectPiecePrice,
  selectInformation,
  selectReference,
  selectNegotiationReferenceCompany,
  selectSubcontractor,
  selectNegotiationSubcontractorCompany,
} from '../../redux/actionCreators/negotiationAction';
import NegotiationPage from '../../components/NegotiationManagement/NegotiationPage';
import CommentsContainer from '../Comment/CommentsContainer';

function NegotiationContainer(props) {
  const {
    history,
    selectedProject,
    selectedTemplate,
    selectedModule,
    selectedSla,
    selectedRatecard,
    selectedTAndM,
    selectedFixedPrice,
    selectedPiecePrice,
    selectedInformation,
    selectedReference,
    selectedReferenceCompany,
    selectedSubcontractor,
    selectedSubcontractorCompany,
    user,
    selectProject,
    selectTemplate,
    selectModule,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectNegotiationReferenceCompany,
    selectSubcontractor,
    selectNegotiationSubcontractorCompany,
  } = props;
  useEffect(() => {
    if (!(user.role === 'AG' || user.role === 'ITAG')) {
      history.push('/main');
    }
    return () => {
      selectProject('');
      selectTemplate('');
      selectModule('');
      selectSla('');
      selectRatecard('');
      selectTAndM('');
      selectFixedPrice('');
      selectPiecePrice('');
      selectInformation('');
      selectReference('');
      selectNegotiationReferenceCompany('');
      selectSubcontractor('');
      selectNegotiationSubcontractorCompany('');
    };
  }, [
    user,
    history,
    selectProject,
    selectTemplate,
    selectModule,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectNegotiationReferenceCompany,
    selectSubcontractor,
    selectNegotiationSubcontractorCompany,
  ]);

  return (
    <>
      <NegotiationPage
        selectedProject={selectedProject}
        selectedTemplate={selectedTemplate}
        selectedModule={selectedModule}
        selectedSla={selectedSla}
        selectedRatecard={selectedRatecard}
        selectedTAndM={selectedTAndM}
        selectedFixedPrice={selectedFixedPrice}
        selectedPiecePrice={selectedPiecePrice}
        selectedInformation={selectedInformation}
        selectedReference={selectedReference}
        selectedReferenceCompany={selectedReferenceCompany}
        selectedSubcontractor={selectedSubcontractor}
        selectedSubcontractorCompany={selectedSubcontractorCompany}
      />
      {selectedProject ? (
        <CommentsContainer selectedProject={selectedProject} />
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  selectedProject: state.negotiation.selectedProject,
  selectedTemplate: state.negotiation.selectedTemplate,
  selectedModule: state.negotiation.selectedModule,
  selectedSla: state.negotiation.selectedSla,
  selectedRatecard: state.negotiation.selectedRatecard,
  selectedTAndM: state.negotiation.selectedTAndM,
  selectedFixedPrice: state.negotiation.selectedFixedPrice,
  selectedPiecePrice: state.negotiation.selectedPiecePrice,
  selectedInformation: state.negotiation.selectedInformation,
  selectedReference: state.negotiation.selectedReference,
  selectedReferenceCompany: state.negotiation.selectedReferenceCompany,
  selectedSubcontractor: state.negotiation.selectedSubcontractor,
  selectedSubcontractorCompany: state.negotiation.selectedSubcontractorCompany,
  loading: state.negotiation.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectModule: (id) => dispatch(selectModule(id)),
  selectSla: (id) => dispatch(selectSla(id)),
  selectRatecard: (id) => dispatch(selectRatecard(id)),
  selectTAndM: (id) => dispatch(selectTAndM(id)),
  selectFixedPrice: (id) => dispatch(selectFixedPrice(id)),
  selectPiecePrice: (id) => dispatch(selectPiecePrice(id)),
  selectInformation: (id) => dispatch(selectInformation(id)),
  selectReference: (id) => dispatch(selectReference(id)),
  selectNegotiationReferenceCompany: (id) =>
    dispatch(selectNegotiationReferenceCompany(id)),
  selectSubcontractor: (id) => dispatch(selectSubcontractor(id)),
  selectNegotiationSubcontractorCompany: (id) =>
    dispatch(selectNegotiationSubcontractorCompany(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NegotiationContainer),
);

NegotiationContainer.propTypes = {
  history: PropTypes.shape(Object).isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  selectedModule: PropTypes.string.isRequired,
  selectedSla: PropTypes.string.isRequired,
  selectedRatecard: PropTypes.string.isRequired,
  selectedTAndM: PropTypes.string.isRequired,
  selectedFixedPrice: PropTypes.string.isRequired,
  selectedPiecePrice: PropTypes.string.isRequired,
  selectedInformation: PropTypes.string.isRequired,
  selectedReference: PropTypes.string.isRequired,
  selectedReferenceCompany: PropTypes.string.isRequired,
  selectedSubcontractor: PropTypes.string.isRequired,
  selectedSubcontractorCompany: PropTypes.string.isRequired,
  user: PropTypes.shape(Object).isRequired,
  selectProject: PropTypes.func,
  selectTemplate: PropTypes.func,
  selectModule: PropTypes.func,
  selectSla: PropTypes.func,
  selectRatecard: PropTypes.func,
  selectTAndM: PropTypes.func,
  selectFixedPrice: PropTypes.func,
  selectPiecePrice: PropTypes.func,
  selectInformation: PropTypes.func,
  selectReference: PropTypes.func,
  selectNegotiationReferenceCompany: PropTypes.func,
  selectSubcontractor: PropTypes.func,
  selectNegotiationSubcontractorCompany: PropTypes.func,
};
