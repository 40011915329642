import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import AddSlaDialog from '../Dialogs/AddSlaDialog';
import EditSlaDialog from '../Dialogs/EditSlaDialog';
import DeleteDialog from '../Dialogs/DeleteDialog';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function ProjectSlasTable(props) {
  const {
    slas,
    projects,
    // templates,
    addSla,
    updateSla,
    deleteSla,
    selectedProject,
    selectSla,
    // user,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'extra',
      label: 'Extra',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'module',
      label: 'ModulId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'template',
      label: 'TemplateId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'project',
      label: 'projectId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'templateName',
      label: 'Dokument',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'moduleName',
      label: 'Modul',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'servicelevel',
      label: 'Service Level',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'value',
      label: 'Wert',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'measure',
      label: 'Messpunkt',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'description',
      label: 'Erläuterung',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'penalty',
      label: 'Pönale in €',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'penaltyreference',
      label: 'Pönalenbezug',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        display: !projects.published && projects.authorization !== 'read',
        viewColumns: !projects.published && projects.authorization !== 'read',
        customBodyRender: (value, tableMeta) => (
          <div>
            <EditSlaDialog
              servicelevelId={tableMeta.rowData[0]}
              projectId={selectedProject}
              oldData={tableMeta}
              header="Service Level bearbeiten"
              // templates={templates}
              onClick={updateSla}
            />
            {tableMeta.rowData[1] && (
              <DeleteDialog
                header="Service Level löschen"
                text="Möchten Sie den Service Level wirklich löschen?"
                onClick={() => deleteSla({
                  variables: { projectId: projects._id, servicelevelId: tableMeta.rowData[0], moduleId: tableMeta.rowData[2] },
                })}
              />
            )}
          </div>
        ),
      },
    },
  ];

  const data = [];

  slas.forEach((sla) => {
    data.push({
      _id: sla._id,
      name: sla.name,
      value: sla.value,
      servicelevel: sla.servicelevel,
      description: sla.description,
      penalty: sla.penalty,
      penaltyreference: sla.penaltyreference,
      measure: sla.measure,
      module: sla.moduleId,
      template: sla.templateId,
      project: selectedProject,
      moduleName: sla.moduleName,
      templateName: sla.templateName,
      extra: sla.extra,
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectSla('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageproject"
                onClick={() => selectSla('')}
              >
                Übersicht
              </Link>
              <Typography color="textPrimary">Service Level</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({ download: true,
          downloadOptions: { filename: 'export.csv', filterOptions: { useDisplayedColumnsOnly: true } },
          sortOrder: { name: 'templateName', direction: 'asc' },
        })}
      />
      {!projects.published && projects.authorization !== 'read' && (
        <AddSlaDialog
          projectId={selectedProject}
          header="Service Level für Modul hinzufügen"
          slas={slas}
          // templates={templates}
          onClick={addSla}
        />
      )}
    </div>
  );
}

export default ProjectSlasTable;

ProjectSlasTable.propTypes = {
  projects: PropTypes.shape(Object).isRequired,
  // templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  slas: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  addSla: PropTypes.func.isRequired,
  updateSla: PropTypes.func.isRequired,
  deleteSla: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectSla: PropTypes.func.isRequired,
  // user: PropTypes.shape(Object).isRequired,
};
