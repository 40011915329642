import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  selectProject,
  selectTemplate,
  selectModule,
  selectSla,
  selectRatecard,
  selectTAndM,
  selectFixedPrice,
  selectPiecePrice,
  selectInformation,
  selectReference,
  selectOfferingReferenceCompany,
  selectSubcontractor,
  selectOfferingSubcontractorCompany,
  selectQAndA,
} from '../../redux/actionCreators/offeringAction';
import OfferingPage from '../../components/OfferingManagement/OfferingPage';

function OfferingContainer(props) {
  const {
    history,
    selectedProject,
    selectedTemplate,
    selectedModule,
    selectedSla,
    selectedRatecard,
    selectedTAndM,
    selectedFixedPrice,
    selectedPiecePrice,
    selectedInformation,
    selectedReference,
    selectedReferenceCompany,
    selectedSubcontractor,
    selectedSubcontractorCompany,
    user,
    selectProject,
    selectTemplate,
    selectModule,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectOfferingReferenceCompany,
    selectSubcontractor,
    selectOfferingSubcontractorCompany,
    selectQAndA,
    selectedQAndA,
  } = props;
  useEffect(() => {
    if (!(user.role === 'AN' || user.role === 'ITAG')) {
      history.push('/main');
    }
    return () => {
      selectProject('');
      selectTemplate('');
      selectModule('');
      selectSla('');
      selectRatecard('');
      selectTAndM('');
      selectFixedPrice('');
      selectPiecePrice('');
      selectInformation('');
      selectReference('');
      selectOfferingReferenceCompany('');
      selectSubcontractor('');
      selectOfferingSubcontractorCompany('');
      selectQAndA('');
    };
  }, [
    user,
    history,
    selectProject,
    selectTemplate,
    selectModule,
    selectSla,
    selectRatecard,
    selectTAndM,
    selectFixedPrice,
    selectPiecePrice,
    selectInformation,
    selectReference,
    selectOfferingReferenceCompany,
    selectSubcontractor,
    selectOfferingSubcontractorCompany,
    selectQAndA,
  ]);

  return (
    <OfferingPage
      selectedProject={selectedProject}
      selectedTemplate={selectedTemplate}
      selectedModule={selectedModule}
      selectedSla={selectedSla}
      selectedRatecard={selectedRatecard}
      selectedTAndM={selectedTAndM}
      selectedFixedPrice={selectedFixedPrice}
      selectedPiecePrice={selectedPiecePrice}
      selectedInformation={selectedInformation}
      selectedReference={selectedReference}
      selectedReferenceCompany={selectedReferenceCompany}
      selectedSubcontractor={selectedSubcontractor}
      selectedSubcontractorCompany={selectedSubcontractorCompany}
      selectedQAndA={selectedQAndA}
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  selectedProject: state.offering.selectedProject,
  selectedTemplate: state.offering.selectedTemplate,
  selectedModule: state.offering.selectedModule,
  selectedSla: state.offering.selectedSla,
  selectedRatecard: state.offering.selectedRatecard,
  selectedTAndM: state.offering.selectedTAndM,
  selectedFixedPrice: state.offering.selectedFixedPrice,
  selectedPiecePrice: state.offering.selectedPiecePrice,
  selectedInformation: state.offering.selectedInformation,
  selectedReference: state.offering.selectedReference,
  selectedReferenceCompany: state.offering.selectedReferenceCompany,
  selectedSubcontractor: state.offering.selectedSubcontractor,
  selectedSubcontractorCompany: state.offering.selectedSubcontractorCompany,
  selectedQAndA: state.offering.selectedQAndA,
  loading: state.offering.loading,
});

const mapDispatchToProps = (dispatch) => ({
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectModule: (id) => dispatch(selectModule(id)),
  selectSla: (id) => dispatch(selectSla(id)),
  selectRatecard: (id) => dispatch(selectRatecard(id)),
  selectTAndM: (id) => dispatch(selectTAndM(id)),
  selectFixedPrice: (id) => dispatch(selectFixedPrice(id)),
  selectPiecePrice: (id) => dispatch(selectPiecePrice(id)),
  selectInformation: (id) => dispatch(selectInformation(id)),
  selectReference: (id) => dispatch(selectReference(id)),
  selectOfferingReferenceCompany: (id) =>
    dispatch(selectOfferingReferenceCompany(id)),
  selectSubcontractor: (id) => dispatch(selectSubcontractor(id)),
  selectOfferingSubcontractorCompany: (id) =>
    dispatch(selectOfferingSubcontractorCompany(id)),
  selectQAndA: (id) => dispatch(selectQAndA(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(OfferingContainer),
);

OfferingContainer.propTypes = {
  user: PropTypes.shape(Object).isRequired,
  history: PropTypes.shape(Object).isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  selectedModule: PropTypes.string.isRequired,
  selectedSla: PropTypes.string.isRequired,
  selectedRatecard: PropTypes.string.isRequired,
  selectedTAndM: PropTypes.string.isRequired,
  selectedFixedPrice: PropTypes.string.isRequired,
  selectedPiecePrice: PropTypes.string.isRequired,
  selectedInformation: PropTypes.string.isRequired,
  selectedReference: PropTypes.string.isRequired,
  selectedReferenceCompany: PropTypes.string.isRequired,
  selectedSubcontractor: PropTypes.string.isRequired,
  selectedSubcontractorCompany: PropTypes.string.isRequired,
  selectedQAndA: PropTypes.string.isRequired,
  selectProject: PropTypes.func,
  selectTemplate: PropTypes.func,
  selectModule: PropTypes.func,
  selectSla: PropTypes.func,
  selectRatecard: PropTypes.func,
  selectTAndM: PropTypes.func,
  selectFixedPrice: PropTypes.func,
  selectPiecePrice: PropTypes.func,
  selectInformation: PropTypes.func,
  selectReference: PropTypes.func,
  selectOfferingReferenceCompany: PropTypes.func,
  selectSubcontractor: PropTypes.func,
  selectOfferingSubcontractorCompany: PropTypes.func,
  selectQAndA: PropTypes.func,
};
