import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// GraphQL
import { useQuery,
  useMutation,
  // useSubscription,
} from '@apollo/react-hooks';
import { GET_INFORMATIONS, GET_PROJECT } from '../../graphql/queries';
import {
  ADD_INFORMATION,
  UPDATE_INFORMATION,
  DELETE_INFORMATION,
  UPDATE_INFORMATION_NUMBER,
} from '../../graphql/mutations';

import {
  // fetchProjectReferences,
  // addProjectReference,
  // updateProjectReference,
  // deleteProjectReference,
  selectReference,
  selectProject,
  selectTemplate,
  // referenceNumberUp,
  // referenceNumberDown,
} from '../../redux/actionCreators/projectAction';
import ProjectReferencesTable from '../../components/ProjectManagement/ProjectReferencesTable';
import Loading from '../../components/Elements/Loading';

function ProjectReferencesContainer(props) {
  const {
    // references,
    // projects,
    // fetchProjectReferences,
    // addProjectReference,
    // updateProjectReference,
    // deleteProjectReference,
    // referenceNumberUp,
    // referenceNumberDown,
    selectReference,
    selectProject,
    selectTemplate,
    selectedProject,
    // loading,
    // user,
  } = props;
  // useEffect(() => {
  //   fetchProjectReferences(selectedProject);
  // }, [fetchProjectReferences, selectedProject]);

  const informationType = 'Referenz';
  const cluster = '';
  const { loading, error, data } = useQuery(GET_INFORMATIONS, {
    variables: { projectId: selectedProject, type: informationType, cluster },
    pollInterval: 500,
  });
  const { loading: loading1, error: error1, data: data1 } = useQuery(GET_PROJECT, {
    variables: { projectId: selectedProject },
  });

  const [addInformation] = useMutation(ADD_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [updateInformation] = useMutation(UPDATE_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [deleteInformation] = useMutation(DELETE_INFORMATION, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });
  const [updateInformationNumber] = useMutation(UPDATE_INFORMATION_NUMBER, {
    refetchQueries: [{
      query: GET_INFORMATIONS,
      variables: { projectId: selectedProject, type: informationType, cluster },
    }],
  });

  if (loading || loading1) return <Loading />;
  if (error || error1) {
    return (
      <p>
        Error :$
        {error + error1}
      </p>
    );
  }
  return (
    <ProjectReferencesTable
      references={data.informations}
      projects={data1.project}
      addProjectReference={addInformation}
      updateProjectReference={updateInformation}
      deleteProjectReference={deleteInformation}
      updateInformationNumber={updateInformationNumber}
      selectReference={selectReference}
      selectProject={selectProject}
      selectedProject={selectedProject}
      selectTemplate={selectTemplate}
      loading={loading}
      type={informationType}
      cluster={cluster}
    />
  );
}

const mapStateToProps = (state) => ({
  references: state.project.references,
  projects: state.project.projects,
  selectedProject: state.project.selectedProject,
  loading: state.project.loading,
  user: state.auth.user,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchProjectReferences: (id) => dispatch(fetchProjectReferences(id)),
  // addProjectReference: (projectId, question) =>
  //   dispatch(addProjectReference(projectId, question)),
  // updateProjectReference: (id, question) =>
  //   dispatch(updateProjectReference(id, question)),
  // deleteProjectReference: (id) => dispatch(deleteProjectReference(id)),
  // referenceNumberUp: (id) => dispatch(referenceNumberUp(id)),
  // referenceNumberDown: (id) => dispatch(referenceNumberDown(id)),
  selectProject: (id) => dispatch(selectProject(id)),
  selectTemplate: (id) => dispatch(selectTemplate(id)),
  selectReference: (id) => dispatch(selectReference(id)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProjectReferencesContainer),
);

ProjectReferencesContainer.propTypes = {
  // fetchProjectReferences: PropTypes.func.isRequired,
  // addProjectReference: PropTypes.func.isRequired,
  // updateProjectReference: PropTypes.func.isRequired,
  // deleteProjectReference: PropTypes.func.isRequired,
  // referenceNumberUp: PropTypes.func.isRequired,
  // referenceNumberDown: PropTypes.func.isRequired,
  selectReference: PropTypes.func.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  // loading: PropTypes.bool.isRequired,
  // references: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // projects: PropTypes.shape(Object).isRequired,
  // user: PropTypes.shape(Object).isRequired,
};
