import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import MUIDataTable from 'mui-datatables';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';

function ProjectTAndMsTable(props) {
  const {
    tandms,
    selectTAndM,
  } = props;

  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'template',
      label: 'TemplateId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'templateName',
      label: 'Dokument',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'moduleName',
      label: 'Modul',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'profile',
      label: 'Profil',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'days',
      label: 'Anzahl PT',
      options: {
        filter: false,
        sort: false,
      },
    },
    {
      name: 'price',
      label: 'Preis in €',
      options: {
        filter: false,
        sort: false,
      },
    },
  ];

  const data = [];

  tandms.forEach((tandm) => {
    data.push({
      _id: tandm._id,
      template: tandm.template,
      moduleName: tandm.moduleName,
      templateName: tandm.templateName,
      profile: 'Wird vom Anbieter ausgefüllt.',
      days: 'Wird vom Anbieter ausgefüllt.',
      price: 'Wird automatisch berechnet.',
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectTAndM('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageproject"
                onClick={() => selectTAndM('')}
              >
                Übersicht
              </Link>
              <Typography color="textPrimary">T&M</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({ download: true,
          downloadOptions: { filename: 'export.csv', filterOptions: { useDisplayedColumnsOnly: true } },
          sortOrder: { name: 'templateName', direction: 'asc' },
        })}
      />
    </div>
  );
}

export default ProjectTAndMsTable;

ProjectTAndMsTable.propTypes = {
  tandms: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  selectTAndM: PropTypes.func.isRequired,
};
