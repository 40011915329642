import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import useStyles from '../../styles';
import AddSlaDialog from '../Dialogs/AddSlaDialog';
import EditOfferingTAndMDialog from '../Dialogs/EditOfferingTAndMDialog';
import DeleteDialog from '../Dialogs/DeleteDialog';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';
import CurrencyFormat from '../Elements/CurrencyFormat';
import LocalNumberFormat from '../Elements/LocalNumberFormat';

function OfferingTAndMsTable(props) {
  const {
    tandms,
    ratecards,
    projects,
    templates,
    selectProject,
    selectedProject,
    selectTemplate,
    selectTAndM,
    addTAndM,
    updateTAndM,
    deleteTAndM,
    // user,
  } = props;

  const getSum = (total, num) => total + num;

  const classes = useStyles();
  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'template',
      label: 'TemplateId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'templateName',
      label: 'Dokument',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'moduleName',
      label: 'Modul',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'profileName',
      label: 'Profilname',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'days',
      label: 'Anzahl PT',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ className: classes.rightAlignedHeader }),
        setCellProps: () => ({ className: classes.rightAlignedCell }),
        customBodyRender: (value) => <LocalNumberFormat value={value} />,
      },
    },
    {
      name: 'price',
      label: 'Preis in €',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ className: classes.rightAlignedHeader }),
        setCellProps: () => ({ className: classes.rightAlignedCell }),
        customBodyRender: (value) => <CurrencyFormat value={value} />,
      },
    },
    {
      name: 'options',
      label: 'Optionen',
      options: {
        filter: false,
        sort: false,
        display: !projects.published && projects.authorization !== 'read',
        viewColumns: !projects.published && projects.authorization !== 'read',
        customBodyRender: (value, tableMeta) => (
          <>
            <EditOfferingTAndMDialog
              header="T&M Eintrag bearbeiten"
              priceId={tableMeta.rowData[0]}
              projectId={projects._id}
              ratecards={ratecards}
              onClick={updateTAndM}
              oldData={[
                tableMeta.rowData[4],
                tableMeta.rowData[9],
                tableMeta.rowData[5],
              ]}
            />
            {tableMeta.rowData[8] && (
              <DeleteDialog
                header="T&M Eintrag entfernen"
                text="Wollen Sie den T&M Eintrag entfernen?"
                onClick={() => deleteTAndM({ variables: { projectId: projects._id, priceId: tableMeta.rowData[0] } })}
              />
            )}
          </>
        ),
      },
    },
    {
      name: 'extra',
      label: 'Extra',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'profile',
      label: 'Profile',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
  ];

  const data = [];

  tandms.forEach((tandm) => {
    data.push({
      _id: tandm._id,
      template: tandm.template,
      moduleName: tandm.moduleName,
      templateName: tandm.templateName,
      profile: tandm.profile ? tandm.profile._id : '',
      profileName: tandm.profile ? tandm.profile.profile : '',
      days: tandm.days,
      price: tandm.price,
      extra: tandm.extra,
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectTAndM('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectTAndM('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/manageoffering"
                onClick={() => selectTAndM('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">T&M</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({
          sortOrder: { name: 'templateName', direction: 'asc' },
        })}
      />
      {!projects.published && projects.authorization !== 'read' && (
      <AddSlaDialog
        projectId={selectedProject}
        header="T&M Eintrag hinzufügen"
        slas={tandms}
        templates={templates}
        onClick={addTAndM}
        type="T&M"
      />
      )}
      <div style={{ textAlign: 'center', marginTop: 16 }}>
        <Typography variant="h6">
          Summe:
          {' '}
          <CurrencyFormat
            value={tandms
              .map((tandm) => tandm.price)
              .reduce(getSum, 0)}
            renderText={(value) => value}
          />
        </Typography>
      </div>
    </div>
  );
}

export default OfferingTAndMsTable;

OfferingTAndMsTable.propTypes = {
  projects: PropTypes.shape(Object).isRequired,
  templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  tandms: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  ratecards: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  selectedProject: PropTypes.string.isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectTAndM: PropTypes.func.isRequired,
  addTAndM: PropTypes.func.isRequired,
  updateTAndM: PropTypes.func.isRequired,
  deleteTAndM: PropTypes.func.isRequired,
  // user: PropTypes.shape(Object).isRequired,
};
