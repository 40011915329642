import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { GET_PROJECTS_POPULATED_MODULES } from '../../graphql/queries';
import { ADD_PROJECT_WITH_CONTENT } from '../../graphql/mutations';

// import {
//   fetchCreationProjects,
//   fetchCreationTemplates,
//   fetchCreationModules,
// } from '../../redux/actionCreators/databaseAction';
import { createProject } from '../../redux/actionCreators/projectAction';
import Loading from '../../components/Elements/Loading';
import CreateProjectStepper from '../../components/ProjectManagement/CreateProjectStepper';

function CreateProjectStepperContainer(props) {
  const {
    // fetchCreationProjects,
    // fetchCreationTemplates,
    // fetchCreationModules,
    // createProject,
    // projects,
    // templates,
    // modules,
    history,
  } = props;
  // useEffect(() => {
  //   fetchCreationProjects();
  //   fetchCreationTemplates();
  //   fetchCreationModules();
  // }, [fetchCreationProjects, fetchCreationTemplates, fetchCreationModules]);

  const { loading, error, data } = useQuery(GET_PROJECTS_POPULATED_MODULES, {
    variables: { type: 'database' },
  });

  const [addProject] = useMutation(ADD_PROJECT_WITH_CONTENT);
  if (loading) return <Loading />;
  if (error) {
    return (
      <p>
        Error :$
        {error}
      </p>
    );
  }

  return (
    <CreateProjectStepper
      projects={data.projects}
      createProject={addProject}
      history={history}
    />
  );
}

const mapStateToProps = (state) => ({
  projects: state.database.creationprojects,
  templates: state.database.creationtemplates,
  modules: state.database.creationmodules,
});

const mapDispatchToProps = (dispatch) => ({
  // fetchCreationProjects: () => dispatch(fetchCreationProjects()),
  // fetchCreationTemplates: () => dispatch(fetchCreationTemplates()),
  // fetchCreationModules: () => dispatch(fetchCreationModules()),
  createProject: (name, description, templates, modules, history, duration) =>
    dispatch(createProject(name, description, templates, modules, history, duration)),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreateProjectStepperContainer),
);

CreateProjectStepperContainer.propTypes = {
  // projects: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // templates: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // modules: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  // fetchCreationProjects: PropTypes.func.isRequired,
  // fetchCreationTemplates: PropTypes.func.isRequired,
  // fetchCreationModules: PropTypes.func.isRequired,
  // createProject: PropTypes.func.isRequired,
  history: PropTypes.shape(Object).isRequired,
};
