import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation, useSubscription } from '@apollo/react-hooks';
import { connect } from 'react-redux';
// GraphQL
import { GET_PROJECT_COMMENTS } from '../../graphql/queries';
import { POST_COMMENT, DELETE_COMMENT } from '../../graphql/mutations';
import { COMMENT_ADDED, COMMENT_DELETED } from '../../graphql/subscriptions';

import CommentsDialog from '../../components/Dialogs/CommentsDialog';
import Loading from '../../components/Elements/Loading';

function CommentsContainer(props) {
  const { selectedProject, user } = props;

  const [open, setOpen] = useState(false);
  const [count, setCount] = useState(0);

  useSubscription(COMMENT_ADDED, {
    variables: { projectId: selectedProject },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const { comments } = client.cache.readQuery({
        query: GET_PROJECT_COMMENTS,
        variables: { projectId: selectedProject },
      });
      const update = comments.concat([subscriptionData.data.commentAdded]);
      client.writeQuery({
        query: GET_PROJECT_COMMENTS,
        variables: { projectId: selectedProject },
        data: { comments: update },
      });
      if (!open) {
        setCount(count + 1);
      }
    },
  });

  useSubscription(COMMENT_DELETED, {
    variables: { projectId: selectedProject },
    onSubscriptionData: ({ client, subscriptionData }) => {
      const { comments } = client.cache.readQuery({
        query: GET_PROJECT_COMMENTS,
        variables: { projectId: selectedProject },
      });
      const update = comments.filter(
        (comment) => comment._id !== subscriptionData.data.commentDeleted._id,
      );
      client.writeQuery({
        query: GET_PROJECT_COMMENTS,
        variables: { projectId: selectedProject },
        data: { comments: update },
      });
    },
  });

  const { loading, error, data } = useQuery(GET_PROJECT_COMMENTS, {
    variables: { projectId: selectedProject },
    fetchPolicy: 'network-only',
  });

  const [postComment] = useMutation(POST_COMMENT);
  const [deleteComment] = useMutation(DELETE_COMMENT);

  if (loading) return <Loading />;
  if (error) {
    return (
      <p>
        Error :$
        {error.message}
      </p>
    );
  }

  return (
    <CommentsDialog
      comments={data.comments}
      postComment={postComment}
      deleteComment={deleteComment}
      open={open}
      setOpen={setOpen}
      selectedProject={selectedProject}
      count={count}
      setCount={setCount}
      user={user}
    />
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CommentsContainer);

CommentsContainer.propTypes = {
  selectedProject: PropTypes.string.isRequired,
  user: PropTypes.shape(Object).isRequired,
};
