import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import {
  Button,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { CloudUpload, DeleteOutline, Check, Close } from '@material-ui/icons';
import { green, grey, red } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  checkIcon: {
    color: green[300],
  },
  closeIcon: {
    color: red[300],
  },
  attachmentButton: {
    textTransform: 'none',
    display: 'block',
  },
  cancelButton: {
    color: grey[300],
  },
  submitButton: {
    color: red[300],
  },
}));

function UploadHandler(props) {
  const {
    referenceId,
    url,
    referenceType,
    // uploadFunction,
    // deleteFunction,
    attachments,
    disabled,
    projectId,
  } = props;

  const classes = useStyles();

  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(false);

  const onChange = (e) => {
    setFile(e.target.files[0]);
  };

  const onFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file', file);
    formData.append('referenceType', referenceType);
    formData.append('referenceId', referenceId);
    formData.append('projectId', projectId);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios.post(`/api/v1/upload/${projectId}/${referenceType}/${referenceId}`, formData, config);
    setFile(null);
  };

  const downloadHandle = (projectId, fileId, fileName) => {
    axios({
      url: `${`${url + projectId}/${fileId}`}`,
      method: 'GET',
      responseType: 'blob', // important
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
  };

  const handleDeleteClose = () => {
    setOpen(false);
  };

  const handleDeleteSubmit = (projectId, referenceType, referenceId) => {
    axios.delete(`/api/v1/upload/${projectId}/${referenceType}/${referenceId}`);
    setOpen(false);
  };

  return (
    <div>
      {attachments
        .filter((temp) => temp._id !== '')
        .map((temp) => (
          <Button
            key={temp._id}
            size="small"
            className={classes.attachmentButton}
            onClick={() => downloadHandle(projectId, temp._id, temp.filename)}
          >
            {temp.filename.substring(19)}
          </Button>
        ))}
      {!disabled && (
        <form onSubmit={onFormSubmit}>
          {file === null && !disabled ? (
            <>
              <label htmlFor={referenceId}>
                <input
                  id={referenceId}
                  type="file"
                  name="file"
                  onChange={onChange}
                  style={{ display: 'none' }}
                />

                <IconButton
                  size={attachments.length !== 0 ? 'small' : 'medium'}
                  variant="contained"
                  component="span"
                  htmlFor="file"
                >
                  <CloudUpload />
                </IconButton>
              </label>
            </>
          ) : (
            <div />
          )}
          {attachments.length !== 0 && file === null && !disabled ? (
            <IconButton
              size="small"
              variant="contained"
              component="span"
              onClick={() => setOpen(true)}
            >
              <DeleteOutline />
            </IconButton>
          ) : (
            <div />
          )}
          {file !== null ? (
            <>
              <IconButton size="small" type="submit">
                <Check className={classes.checkIcon} />
              </IconButton>
              <IconButton
                size="small"
                type="button"
                onClick={() => setFile(null)}
              >
                <Close className={classes.closeIcon} />
              </IconButton>
            </>
          ) : (
            <div />
          )}
        </form>
      )}
      <Dialog
        maxWidth="sm"
        fullWidth
        open={open}
        onClose={handleDeleteClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Anhänge wirklich löschen?
        </DialogTitle>
        <DialogContent>
          Wollen Sie alle Anhänge wirklich löschen? Dies kann nicht rückgängig
          gemacht werden. Laden Sie die Anhänge zur Sicherheit runter, um diese
          ggf. erneut hochladen zu können.
        </DialogContent>
        <DialogActions>
          <Button
            className={classes.cancelButton}
            onClick={handleDeleteClose}
            color="primary"
          >
            Abbrechen
          </Button>
          <Button
            className={classes.submitButton}
            onClick={() => handleDeleteSubmit(projectId, referenceType, referenceId)}
            color="primary"
            autoFocus
          >
            Löschen
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default UploadHandler;

UploadHandler.propTypes = {
  referenceId: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  referenceType: PropTypes.string.isRequired,
  // uploadFunction: PropTypes.func.isRequired,
  // deleteFunction: PropTypes.func.isRequired,
  attachments: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  disabled: PropTypes.bool,
  projectId: PropTypes.string,
};
