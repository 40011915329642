import React from 'react';
import PropTypes from 'prop-types';

import DatabaseProjectsContainer from '../../container/DatabaseManagement/DatabaseProjectsContainer';
import DatabaseTemplatesContainer from '../../container/DatabaseManagement/DatabaseTemplatesContainer';
import DatabaseModulesContainer from '../../container/DatabaseManagement/DatabaseModulesContainer';
// import DatabaseStatementsContainer from '../../container/DatabaseManagement/DatabaseStatementsContainer';
import DatabaseSlasContainer from '../../container/DatabaseManagement/DatabaseSlasContainer';
import DatabaseRatecardContainer from '../../container/DatabaseManagement/DatabaseRatecardContainer';
import DatabaseTAndMsContainer from '../../container/DatabaseManagement/DatabaseTAndMsContainer';
import DatabaseFixedPricesContainer from '../../container/DatabaseManagement/DatabaseFixedPricesContainer';
import DatabasePiecePricesContainer from '../../container/DatabaseManagement/DatabasePiecePricesContainer';
import DatabaseInformationContainer from '../../container/DatabaseManagement/DatabaseInformationContainer';
import DatabaseReferencesContainer from '../../container/DatabaseManagement/DatabaseReferencesContainer';
import DatabaseSubcontractorsContainer from '../../container/DatabaseManagement/DatabaseSubcontractorsContainer';

import ResponsiveDiv from '../Elements/ResponsiveDiv';

function DatabasePage(props) {
  const {
    selectedProject,
    selectedTemplate,
    // selectedModule,
    selectedSla,
    selectedRatecard,
    selectedTAndM,
    selectedFixedPrice,
    selectedPiecePrice,
    selectedInformation,
    selectedReference,
    selectedSubcontractor,
  } = props;

  if (selectedSla) {
    return (
      <ResponsiveDiv>
        <DatabaseSlasContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedRatecard) {
    return (
      <ResponsiveDiv>
        <DatabaseRatecardContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedTAndM) {
    return (
      <ResponsiveDiv>
        <DatabaseTAndMsContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedFixedPrice) {
    return (
      <ResponsiveDiv>
        <DatabaseFixedPricesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedPiecePrice) {
    return (
      <ResponsiveDiv>
        <DatabasePiecePricesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedInformation) {
    return (
      <ResponsiveDiv>
        <DatabaseInformationContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedReference) {
    return (
      <ResponsiveDiv>
        <DatabaseReferencesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedSubcontractor) {
    return (
      <ResponsiveDiv>
        <DatabaseSubcontractorsContainer />
      </ResponsiveDiv>
    );
  }

  // if (selectedModule) {
  //   return (
  //     <ResponsiveDiv>
  //       <DatabaseStatementsContainer />
  //     </ResponsiveDiv>
  //   );
  // }

  if (selectedTemplate) {
    return (
      <ResponsiveDiv>
        <DatabaseModulesContainer />
      </ResponsiveDiv>
    );
  }

  if (selectedProject) {
    return (
      <ResponsiveDiv>
        <DatabaseTemplatesContainer />
      </ResponsiveDiv>
    );
  }
  return (
    <ResponsiveDiv>
      <DatabaseProjectsContainer />
    </ResponsiveDiv>
  );
}

export default DatabasePage;

DatabasePage.propTypes = {
  selectedProject: PropTypes.string.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  // selectedModule: PropTypes.string.isRequired,
  selectedSla: PropTypes.string.isRequired,
  selectedRatecard: PropTypes.string.isRequired,
  selectedTAndM: PropTypes.string.isRequired,
  selectedFixedPrice: PropTypes.string.isRequired,
  selectedPiecePrice: PropTypes.string.isRequired,
  selectedInformation: PropTypes.string.isRequired,
  selectedReference: PropTypes.string.isRequired,
  selectedSubcontractor: PropTypes.string.isRequired,
};
