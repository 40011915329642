import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Button, Breadcrumbs, Typography, Link } from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

import MUIDataTable from 'mui-datatables';
import useStyles from '../../styles';
import FullsizeTableOptions from '../Elements/FullsizeTableOptions';
import CurrencyFormat from '../Elements/CurrencyFormat';
import LocalNumberFormat from '../Elements/LocalNumberFormat';

function Negotiation(props) {
  const {
    tandms,
    projects,
    selectProject,
    selectTemplate,
    selectTAndM,
  } = props;

  const getSum = (total, num) => total + num;

  const classes = useStyles();
  const columns = [
    {
      name: '_id',
      label: 'ID',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'template',
      label: 'TemplateId',
      options: {
        display: false,
        viewColumns: false,
        filter: false,
        sort: false,
      },
    },
    {
      name: 'templateName',
      label: 'Dokument',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'moduleName',
      label: 'Modul',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'profile',
      label: 'Profil',
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          if (value !== undefined) return value;
          return 'Wird vom Anbieter ausgewählt.';
        },
      },
    },
    {
      name: 'days',
      label: 'Anzahl PT',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ className: classes.rightAlignedHeader }),
        setCellProps: () => ({ className: classes.rightAlignedCell }),
        customBodyRender: (value) => {
          if (value !== 0) return <LocalNumberFormat value={value} />;
          return 'Wird vom Anbieter ausgefüllt.';
        },
      },
    },
    {
      name: 'price',
      label: 'Preis in €',
      options: {
        filter: false,
        sort: false,
        setCellHeaderProps: () => ({ className: classes.rightAlignedHeader }),
        setCellProps: () => ({ className: classes.rightAlignedCell }),
        customBodyRender: (value) => {
          if (value !== 0) return <CurrencyFormat value={value} />;
          return 'Wird automatisch berechnet.';
        },
      },
    },
    {
      name: 'extra',
      label: 'Extra',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
    {
      name: 'profile',
      label: 'Profile',
      options: {
        filter: false,
        sort: false,
        display: false,
        viewColumns: false,
      },
    },
  ];

  const data = [];

  tandms.forEach((tandm) => {
    data.push({
      _id: tandm._id,
      template: tandm.template,
      templateName: tandm.templateName,
      moduleName: tandm.moduleName,
      profile: tandm.profile ? tandm.profile._id : 'Wird vom Anbieter ausgefüllt.',
      profileName: tandm.profile ? tandm.profile.profile : 'Wird vom Anbieter ausgefüllt.',
      days: tandm.days,
      price: tandm.price,
      extra: tandm.extra,
    });
  });

  return (
    <div>
      <MUIDataTable
        title={
          <div style={{ display: 'flex' }}>
            <Button size="small" onClick={() => selectTAndM('')}>
              <KeyboardReturnIcon fontSize="small" />
            </Button>
            <Breadcrumbs>
              <Link
                color="inherit"
                component={RouterLink}
                to="/managenegotiation"
                onClick={() => {
                  selectProject('');
                  selectTemplate('');
                  selectTAndM('');
                }}
              >
                Übersicht
              </Link>
              <Link
                color="inherit"
                component={RouterLink}
                to="/managenegotiation"
                onClick={() => selectTAndM('')}
              >
                {projects.name}
              </Link>
              <Typography color="textPrimary">T&M</Typography>
            </Breadcrumbs>
          </div>
        }
        data={data}
        columns={columns}
        options={FullsizeTableOptions({
          sortOrder: { name: 'templateName', direction: 'asc' },
        })}
      />
      <div style={{ textAlign: 'center', marginTop: 16 }}>
        <Typography variant="h6">
          Summe:
          {' '}
          {tandms
            .map((tandm) => tandm.price)
            .reduce(getSum, 0)
            .toLocaleString()}
          {' '}
          €
        </Typography>
      </div>
    </div>
  );
}

export default Negotiation;

Negotiation.propTypes = {
  projects: PropTypes.shape(Object).isRequired,
  tandms: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  selectProject: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  selectTAndM: PropTypes.func.isRequired,
};
