import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  IconButton,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core/';
import { Close } from '@material-ui/icons/';

import TableCommentsButton from '../Buttons/TableCommentsButton';
import CommentsList from '../Comments/CommentsList';
import CommentInput from '../Comments/CommentInput';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

export default function CommentsDialog(props) {
  const {
    open,
    setOpen,
    comments,
    postComment,
    deleteComment,
    selectedProject,
    count,
    setCount,
    user,
  } = props;
  const classes = useStyles();

  const handleClickOpen = () => {
    setOpen(true);
    setCount(0);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <TableCommentsButton count={count} onClick={handleClickOpen} />
      <Dialog
        fullWidth
        maxWidth="lg"
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Kommentare
          <IconButton
            aria-label="Close"
            className={classes.closeButton}
            onClick={handleClose}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs>
              <CommentsList comments={comments} user={user} deleteComment={deleteComment} />
              <CommentInput
                selectedProject={selectedProject}
                postComment={postComment}
                deleteComment={deleteComment}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}
CommentsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  comments: PropTypes.arrayOf(PropTypes.shape(Object)).isRequired,
  postComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  selectedProject: PropTypes.string.isRequired,
  count: PropTypes.number.isRequired,
  setCount: PropTypes.func.isRequired,
  user: PropTypes.shape(Object).isRequired,
};
