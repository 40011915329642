import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { FiberManualRecord, FiberManualRecordOutlined } from '@material-ui/icons';

const useStyles = makeStyles(() => ({
  customCheck: {
    color: grey[600],
    '&$checked': {
      color: grey[600],
    },
  },
  checked: {},
}));

function TableCheckbox(props) {
  const { id, value, disabled, onChangeHandler } = props;
  const classes = useStyles();
  return (
    <Checkbox
      color="default"
      classes={{
        root: classes.customCheck,
        checked: classes.checked,
      }}
      checked={value}
      disabled={disabled}
      icon={<FiberManualRecordOutlined />}
      checkedIcon={<FiberManualRecord />}
      onChange={() => {
        onChangeHandler(id);
      }}
    />
  );
}

export default TableCheckbox;

TableCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
  onChangeHandler: PropTypes.func,
};
