import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

function DateStamp(props) {
  const { date } = props;
  return <div>{moment.unix(date / 1000).format('DD.MM.YYYY HH:mm')}</div>;
}

export default DateStamp;

DateStamp.propTypes = {
  date: PropTypes.number.isRequired,
};
