import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid, Container, Avatar, Box } from '@material-ui/core/';
import { Person } from '@material-ui/icons/';

import ResponsiveDiv from '../Elements/ResponsiveDiv';
import EditPasswordDialog from '../Dialogs/EditPasswordDialog';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    width: 60,
    height: 60,
    margin: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

function ProfilePage(props) {
  const { user, changePassword } = props;
  const classes = useStyles();
  return (
    <ResponsiveDiv>
      <Container component="main" maxWidth="xs">
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <Person fontSize="large" />
          </Avatar>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box textAlign="left" m={1}>
                <Typography variant="h5">
                  {`${user.firstname} ${user.lastname}`}
                </Typography>
              </Box>
              <Box textAlign="left" m={1}>
                <Typography variant="caption">
                  {`${user.position} bei ${user.company}`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box textAlign="left" m={1}>
                <Typography variant="body1">
                  {`E-Mail: ${user.email}`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box textAlign="left" m={1}>
                <Typography variant="body1">Passwort ändern:</Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <EditPasswordDialog
                id=""
                text=""
                header="Passwort ändern"
                oldData=""
                onClick={changePassword}
              />
            </Grid>
          </Grid>
        </div>
      </Container>
    </ResponsiveDiv>
  );
}

export default ProfilePage;

ProfilePage.propTypes = {
  user: PropTypes.shape(Object).isRequired,
  changePassword: PropTypes.func.isRequired,
};
